import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateVwFacturas, getVwFacturas } from '../../actions/VwFacturasAction'; 
import { Validations } from '../../utils/validations'; 

let FIELD_VALIDATION={};
FIELD_VALIDATION['Facturacion']={check:true, chkType:'long', message:'incorrect value'};
FIELD_VALIDATION['Factura_Fiscal']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['Cliente']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['Vendedor']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['Fecha']={check:true, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['Tasa_Imp']={check:true, chkType:'double', message:'incorrect value'};
FIELD_VALIDATION['Subtotal']={check:true, chkType:'double', message:'incorrect value'};
FIELD_VALIDATION['Impuesto']={check:true, chkType:'double', message:'incorrect value'};
FIELD_VALIDATION['Total']={check:true, chkType:'double', message:'incorrect value'};
FIELD_VALIDATION['Tipo']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['Estado']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['Fecha_Sistema']={check:true, chkType:'Timestamp', message:'incorrect value'};
FIELD_VALIDATION['head']={check:true, chkType:'long', message:'incorrect value'};
FIELD_VALIDATION['id']={check:true, chkType:'long', message:'incorrect value'};

class VwFacturasForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		Facturacion:'', e_Facturacion: false,
		Factura_Fiscal:'', e_Factura_Fiscal: false,
		Cliente:'', e_Cliente: false,
		Vendedor:'', e_Vendedor: false,
		Fecha:'', e_Fecha: false,
		Tasa_Imp:'', e_Tasa_Imp: false,
		Subtotal:'', e_Subtotal: false,
		Impuesto:'', e_Impuesto: false,
		Total:'', e_Total: false,
		Tipo:'', e_Tipo: false,
		Estado:'', e_Estado: false,
		Fecha_Sistema:'', e_Fecha_Sistema: false,
		head:'', e_head: false,
		id:'', e_id: false,
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	if(this.actionMode==='edit'){ 
		console.log('edit  test  '+this.props.VwFacturas.id);
		if(this.props.VwFacturas.id===undefined){ 
			//means some refresh happens  id
			const idx = this.props.match.params.id; 
			//getVwFacturas(idx);
			Promise.resolve(this.props.getVwFacturas(idx))
			.then((response)=>{
				this.setState(response.VwFacturasOne);
				return response;
			});
		}	
	}	
  }

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const Facturacion = this.state.Facturacion;
    const Factura_Fiscal = this.state.Factura_Fiscal;
    const Cliente = this.state.Cliente;
    const Vendedor = this.state.Vendedor;
    const Fecha = this.state.Fecha;
    const Tasa_Imp = this.state.Tasa_Imp;
    const Subtotal = this.state.Subtotal;
    const Impuesto = this.state.Impuesto;
    const Total = this.state.Total;
    const Tipo = this.state.Tipo;
    const Estado = this.state.Estado;
    const Fecha_Sistema = this.state.Fecha_Sistema;
    const head = this.state.head;
    const id = this.state.id;
    const VwFacturas = {Facturacion: Facturacion, Factura_Fiscal: Factura_Fiscal, Cliente: Cliente, Vendedor: Vendedor, Fecha: Fecha, Tasa_Imp: Tasa_Imp, Subtotal: Subtotal, Impuesto: Impuesto, Total: Total, Tipo: Tipo, Estado: Estado, Fecha_Sistema: Fecha_Sistema, head: head, id: id, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.VwFacturas[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('errorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateVwFacturas(VwFacturas, 1); 
		//this.props.addVwFacturas(VwFacturas); 
	}else{ 
		this.props.addOrUpdateVwFacturas(VwFacturas, 2);
		//this.props.updateVwFacturas(VwFacturas);
		
	}
  };

  handleCancel = () => { 
	if(this.actionMode==='new'){ 
		this.props.history.push(`./VwFacturas`);
	}else{ 
		const idx = this.props.match.params.id; 
		this.props.history.push(`./VwFacturas/${idx}`);
		//this.props.history.push(`./VwFacturas/${this.props.VwFacturas.id}`);
	}
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <h3>{this.actionMode==='new'? t('acAddVwFacturas_x'): t('acEditVwFacturas_x')}</h3>
        <form onSubmit={this.handleSubmit}>
          		  
		  
		  <div className="form-group">
			<label htmlFor="Facturacion">{t('Facturacion_x')}</label>
            <input type="text" name="Facturacion" id="Facturacion" defaultValue={this.state.Facturacion} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_Facturacion ? 'form-control fieldErr' : 'form-control'} placeholder={t('Facturacion_x')} /> 
			{this.state.e_Facturacion && (<span className="text-danger">{FIELD_VALIDATION['Facturacion'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="Factura_Fiscal">{t('Factura_Fiscal_x')}</label>
            <input type="text" name="Factura_Fiscal" id="Factura_Fiscal" defaultValue={this.state.Factura_Fiscal} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_Factura_Fiscal ? 'form-control fieldErr' : 'form-control'} placeholder={t('Factura_Fiscal_x')} /> 
			{this.state.e_Factura_Fiscal && (<span className="text-danger">{FIELD_VALIDATION['Factura_Fiscal'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="Cliente">{t('Cliente_x')}</label>
            <input type="text" name="Cliente" id="Cliente" defaultValue={this.state.Cliente} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_Cliente ? 'form-control fieldErr' : 'form-control'} placeholder={t('Cliente_x')} /> 
			{this.state.e_Cliente && (<span className="text-danger">{FIELD_VALIDATION['Cliente'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="Vendedor">{t('Vendedor_x')}</label>
            <input type="text" name="Vendedor" id="Vendedor" defaultValue={this.state.Vendedor} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_Vendedor ? 'form-control fieldErr' : 'form-control'} placeholder={t('Vendedor_x')} /> 
			{this.state.e_Vendedor && (<span className="text-danger">{FIELD_VALIDATION['Vendedor'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="Fecha">{t('Fecha_x')}</label>
            <input type="text" name="Fecha" id="Fecha" defaultValue={this.state.Fecha} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_Fecha ? 'form-control fieldErr' : 'form-control'} placeholder={t('Fecha_x')} /> 
			{this.state.e_Fecha && (<span className="text-danger">{FIELD_VALIDATION['Fecha'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="Tasa_Imp">{t('Tasa_Imp_x')}</label>
            <input type="text" name="Tasa_Imp" id="Tasa_Imp" defaultValue={this.state.Tasa_Imp} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_Tasa_Imp ? 'form-control fieldErr' : 'form-control'} placeholder={t('Tasa_Imp_x')} /> 
			{this.state.e_Tasa_Imp && (<span className="text-danger">{FIELD_VALIDATION['Tasa_Imp'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="Subtotal">{t('Subtotal_x')}</label>
            <input type="text" name="Subtotal" id="Subtotal" defaultValue={this.state.Subtotal} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_Subtotal ? 'form-control fieldErr' : 'form-control'} placeholder={t('Subtotal_x')} /> 
			{this.state.e_Subtotal && (<span className="text-danger">{FIELD_VALIDATION['Subtotal'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="Impuesto">{t('Impuesto_x')}</label>
            <input type="text" name="Impuesto" id="Impuesto" defaultValue={this.state.Impuesto} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_Impuesto ? 'form-control fieldErr' : 'form-control'} placeholder={t('Impuesto_x')} /> 
			{this.state.e_Impuesto && (<span className="text-danger">{FIELD_VALIDATION['Impuesto'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="Total">{t('Total_x')}</label>
            <input type="text" name="Total" id="Total" defaultValue={this.state.Total} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_Total ? 'form-control fieldErr' : 'form-control'} placeholder={t('Total_x')} /> 
			{this.state.e_Total && (<span className="text-danger">{FIELD_VALIDATION['Total'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="Tipo">{t('Tipo_x')}</label>
            <select name="Tipo" id="Tipo" defaultValue={this.state.Tipo} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_Tipo ? 'form-control fieldErr' : 'form-control'} placeholder={t('Tipo_x')}> 
                        <option value="FA">{t('FA_x')}</option>
                        <option value="NC">{t('NC_x')}</option>
                    </select>
			{this.state.e_Tipo && (<span className="text-danger">{FIELD_VALIDATION['Tipo'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="Estado">{t('Estado_x')}</label>
            <select name="Estado" id="Estado" defaultValue={this.state.Estado} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_Estado ? 'form-control fieldErr' : 'form-control'} placeholder={t('Estado_x')}> 
                        <option value="A">{t('A_x')}</option>
                        <option value="P">{t('P_x')}</option>
                        <option value="C">{t('C_x')}</option>
                        <option value="N">{t('N_x')}</option>
                    </select>
			{this.state.e_Estado && (<span className="text-danger">{FIELD_VALIDATION['Estado'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="Fecha_Sistema">{t('Fecha_Sistema_x')}</label>
            <input type="text" name="Fecha_Sistema" id="Fecha_Sistema" defaultValue={this.state.Fecha_Sistema} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_Fecha_Sistema ? 'form-control fieldErr' : 'form-control'} placeholder={t('Fecha_Sistema_x')} /> 
			{this.state.e_Fecha_Sistema && (<span className="text-danger">{FIELD_VALIDATION['Fecha_Sistema'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="head">{t('head_x')}</label>
            <input type="text" name="head" id="head" defaultValue={this.state.head} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_head ? 'form-control fieldErr' : 'form-control'} placeholder={t('head_x')} /> 
			{this.state.e_head && (<span className="text-danger">{FIELD_VALIDATION['head'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="id">{t('id_x')}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('id_x')} /> 
			{this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')}</button> &nbsp; 
			<button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button>
          </div>
        </form>
      </div>
    );
  }
}

//const TransVwFacturasForm = withTranslation()(VwFacturasForm); 
const mapStateToProps = (state) => ({ VwFacturas: state.VwFacturas });

const mapDispatchToProps = { addOrUpdateVwFacturas, getVwFacturas };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(VwFacturasForm));
