import React from "react";
import Select from 'react-select';
import {
    Row,
    Col,
    Label,
    Button
    // Table
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEraser,
} from '@fortawesome/free-solid-svg-icons';
import { ThreeSixtySharp } from "@material-ui/icons";
import axios from "axios";
const BACKEND1 = process.env.REACT_APP_API_URLX;

export default class ActionBar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            status: "",
            agent: "",
            id_user: "",
            thermo: "",
            domaine: ""
        }
    }

    post = (obj) => {
        axios.post(
            BACKEND1+'/leads/leads/lead',
            obj,
           {
                params: {},
                }
            )
    }

    handleSelectChange= (e, field) => {
        console.log(e)
        if (field === "agent") {
            try {
                this.setState({agent: e.label, id_user: e.agentId}, () => console.log(this.state))
            } catch {
                this.setState({agent: "", id_user: ""}, () => console.log(this.state))
            }
        } else if (field === "domaine") {
            try {
                this.setState({domaine: e.name}, () => console.log(this.state))
            } catch {
                this.setState({domaine: ""}, () => console.log(this.state))
            }
        } else {
            try {
                this.setState({[field]: e.value}, () => console.log(this.state));
            } catch {
                this.setState({[field]: ""}, () => console.log(this.state));
            }
        }
    }

    handleSubmit = async () => {
        await this.props.selectedCheckboxes.forEach(i => {
            const obj = {id: i}
            if (this.state.status) {
                if (this.state.status === "5" || this.state.status === "0"){
                    obj.id_user = "-1"
                    obj.agent = "-1"
                    obj.status = this.state.status
                } else {
                    obj.status = this.state.status;
                }
            }
            if (this.state.domaine) obj.domaine = this.state.domaine;
            if (this.state.thermo) {
                obj.thermo = this.state.thermo;
                obj.setthermo = "yes"
            }
            if (this.state.agent && this.state.id_user && this.state.status !== "0" && this.state.status !== "5") {
                obj.id_user = this.state.id_user;
                obj.agent= this.state.agent
            }
            this.post(obj)
            console.log(obj)
        });
        this.props.fetchFilterData();
        this.props.handleClearCheckboxes();
    }

    render(){
        const {genLang, lang, trad, agentOptions, domaineOptions, selectedCheckboxes} = this.props
        const actionOptions = [
            { label: genLang("attribute"), value: "1" },
            { label: genLang("cancelLead"), value: "5" },
            { label: genLang("automaticDistrubution"), value: "0" },
            { label: genLang("closeLead"), value: "3" },
            {label: genLang('ecommercesms'), value: '8'}, 
          ];
          const thermoOptions = [
            { label: genLang("cold"), value: "1" },
            { label: genLang("warm"), value: "2" },
            { label: genLang("hot"), value: "3" },
          ];
        return (
        <>
            <h6 style={{fontWeight: '700', color: "#0052bb"}}>Modifications en Masse</h6>
            <Row style={{marginBottom: '1rem'}}>
                {/* <Label>Action Bar</Label> */}
                <Col md={2}>
                    <Label>Action</Label>
                    <Select 
                        options={actionOptions} 
                        isClearable
                        value={actionOptions.find(i => i.value === this.state.status) || ""}
                        onChange={(e) => this.handleSelectChange(e, 'status')}
                        isDisabled={!selectedCheckboxes.length}
                    />
                </Col>

                <Col md={2}>
                    <Label>Agent</Label>
                    <Select 
                        options={agentOptions}
                        isClearable
                        value={agentOptions.find(i => i.agentId === this.state.id_user) || ""}
                        onChange={(e) => this.handleSelectChange(e, 'agent')}
                        isDisabled={!selectedCheckboxes.length || this.state.status !== '1'}
                    />
                </Col>

                <Col md={2}>
                    <Label>{genLang("domain")}</Label>
                    <Select 
                        options={domaineOptions}  
                        isClearable
                        onChange={(e) => this.handleSelectChange(e, 'domaine')}
                        value={domaineOptions.find(i => i.name === this.state.domaine) || ""}
                        isDisabled={!selectedCheckboxes.length}  
                    />
                </Col>

                <Col md={2}>
                    <Label>{genLang("priority")}</Label>
                    <Select 
                        options={thermoOptions}
                        isClearable
                        onChange={(e) => this.handleSelectChange(e, 'thermo')}
                        value={thermoOptions.find(i => i.value === this.state.thermo) || ""}
                        isDisabled={!selectedCheckboxes.length}
                    />
                </Col>

                <Col md={2}>
                    <Button
                        color="primary"
                        outline
                        // disabled={!selectedCheckboxes.length && !this.state.status && !this.state.agent && !this.state.id_user && !this.state.domaine && !this.state.thermo}
                        disabled={!this.state.status && !this.state.agent && !this.state.id_user && !this.state.domaine && !this.state.thermo || !selectedCheckboxes.length}
                        style={{marginTop: '1.6rem', height: '37px'}}
                        block
                        onClick={this.handleSubmit}
                    >
                        {selectedCheckboxes.length ? `${genLang("edit")} ${selectedCheckboxes.length} Leads` : genLang('edit')}
                    </Button>
                </Col>
                <Col md={2}>
                    <Button
                        color="primary"
                        outline
                        // disabled={this.state.status === "" && this.state.agent === "" && this.state.id_user === "" && this.state.domaine === "" && this.state.thermo === "" || !selectedCheckboxes.length}
                        style={{marginTop: '1.6rem', height: '37px'}}
                        block
                        onClick={() => {
                            this.setState({
                                agent: "",
                                id_user: "",
                                status: "",
                                domaine: "",
                                thermo: ""
                            });
                            this.props.handleClearCheckboxes();
                        }         
                    }
                    >
                        {`${genLang("empty")} `}<FontAwesomeIcon icon={faEraser}/>
                    </Button>
                </Col>
            </Row>
        </>
        )
    }
}