import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateVwFacturas, getVwFacturas, getVwFacturasAll, getVwFacturasGoto, deleteVwFacturas } from '../../actions/VwFacturasAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "../CustomToolbarSelect";

class VwFacturasList extends Component {
	constructor(props) {
		super(props); 
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 
	}
  
	componentDidMount() { 
		this.props.getVwFacturasAll();
	}
	
	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.VwFacturas;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteVwFacturas(gridIds[0]);

	}

  updateXRecordsMain(selectedRows){  
    const { t } = this.props;
	const objList  = this.props.VwFacturas;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
      gridIndexes.push(row.index); 
      idIndex=row.index; 
	return null;
    });
 
    if(gridIndexes.length>1){
      alert(`{t('gridIndexesfound_x')}`);
      return false;
    }
 
    let obj=objList[idIndex]; 
	this.props.getVwFacturasGoto(obj.id); 
	//history.push(`./vwfacturas/${obj.id}/edit`); 
  }
  

  render() { 
    const { t } = this.props;
	const columns = [
	 /*{
	  name: "Facturacion", label: t('Facturacion_x'),
	  options: { filter: true, sort: true,}
	 },*/
	 {
	  name: "Factura_Fiscal", label: t('Factura_Fiscal_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "Cliente", label: t('Cliente_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "Vendedor", label: t('Vendedor_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "Fecha", label: t('Fecha_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "Tasa_Imp", label: t('Tasa_Imp_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "Subtotal", label: t('Subtotal_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "Impuesto", label: t('Impuesto_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "Total", label: t('Total_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "Tipo", label: t('Tipo_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "Estado", label: t('Estado_x'),
	  options: { filter: true, sort: true,}
	 },
	 /*{
	  name: "Fecha_Sistema", label: t('Fecha_Sistema_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "head", label: t('head_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "id", label: t('id_x'),
	  options: { filter: true, sort: true,}
	 },*/
	];
const textLabelOptions= {
    body: {
      noMatch: t('gridnoMatch_x'),
      toolTip: t('gridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('gridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('gridnext_x'),
      previous: t('gridprevious_x'),
      rowsPerPage: t('gridrowsPerPage_x'),
      displayRows: t('griddisplayRows_x'),
    },
    toolbar: {
      search: t('gridsearch_x'),
      downloadCsv: t('griddownloadCsv_x'),
      print: t('gridprint_x'),
      viewColumns: t('gridviewColumns_x'),
      filterTable: t('gridfilterTable_x'),
    },
    filter: {
      all: t('gridall_x'),
      title: t('gridftitle_x'),
      reset: t('gridreset_x'),
    },
    viewColumns: {
      title: t('gridvctitle_x'),
      titleAria: t('gridvctitleAria_x'),
    }, 
  };	
	/*const customHeadRenderx=function(columnMeta: {
	  customHeadRender: func,
	  display: true,
	  filter: true,
	  sort: true,
	  download: false,
	  empty: true,
	  index: number,
	  label: string,
	  name: string,
	  print: false,
	  searchable: true,
	  viewColumns: true
	}, handleToggleColumn: function(columnIndex));*/
	
	//}, handleToggleColumn: function(columnIndex));
		
	const options = {
	  filterType: 'checkbox',
	  textLabels: textLabelOptions,
	  selectableRows: false,
	  //customHeadRender: customHeadRenderx,
	  download: false,
      print: false,
	  
	  /*onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		//console.log(currentRowsSelected, allRowsSelected);
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log(allRowsSelected);
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} isEdit={true} isDelete={true} />
      ),*/
	};  
	  
    if(this.props.VwFacturas.length) {                                
      return (
        <div>
          <h4>{t('VwFacturas_x')}</h4>		  
		  <MUIDataTable
			  title={""}
			  data={this.props.VwFacturas}
			  columns={columns}
			  options={options}
			/>
        </div>
      )    
    } else {
      return (<div>{t('gridNoRecordfound_x')}</div>)
    }  }
}


const mapStateToProps = (state) => ({ VwFacturas: state.VwFacturas });  
const mapDispatchToProps = { addOrUpdateVwFacturas, getVwFacturas, getVwFacturasAll, getVwFacturasGoto, deleteVwFacturas };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(VwFacturasList));
