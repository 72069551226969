import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateZendLeads, getZendLeads, getZendLeadsAll, getZendLeadsGoto, deleteZendLeads } from '../../actions/ZendLeadsAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "../CustomToolbarSelect";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 
import history from '../../history';
import Toastr from "../../messages";
import  Loader from "../../loader";

import Done from "@material-ui/icons/Done";
import Error from "@material-ui/icons/Error";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from '@material-ui/core/styles';


import NeoLabExample from "../../NeoLabExample";
import { LaptopWindows } from '@material-ui/icons';

const LightTooltip = withStyles((theme) => ({
	tooltip: {
	  backgroundColor: theme.palette.common.white,
	  color: 'rgba(0, 0, 0, 0.87)',
	  boxShadow: theme.shadows[1],
	  fontSize: 11,
	},
  }))(Tooltip); 
  
  const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
	  backgroundColor: '#FFFFFF',
	  color: 'rgba(0, 0, 0, 0.87)',
	  margin:"10px",
	//   maxWidth: 220,
	  width: 400,
	  fontSize: theme.typography.pxToRem(12),
	  border: '1px solid #dadde9',
	},
  }))(Tooltip);
  
  
class ZendMedList extends Component {
	actionMode='all';
	constructor(props) {
		super(props); 
		this.state={isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000,
			statusChargeDesk: 0, chargeDeskIdStr:"", invoiceId:0, recordsList:[],
			openPossibleCDStatusInfo:false, possibleCDStatusInfo:{}, possibleListOfCDStatusInfo:[],  
			pendingList:[], groupbyprovince:"", 
		};
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);

		const locurl=window.location.href;
		// const patt=/([0-9]+)(\/edit)$/;
		const patt=/(\/my[zZ]end[lL]eads)/;
		if(patt.test(locurl)){ 
			this.actionMode='my'; 
		}else{ 
			this.actionMode='all'; 
		} 
		
		const provLead=localStorage.getItem("provLead");
		if(provLead!==undefined && provLead!==null){
			this.setState({ ["groupbyprovince"]: provLead });
		}

		console.log("heeeeeeeeeeeeeeeeeeeeeeeeeeeelloooooo LEADS LIST...");
		 
	}

	interval=null;

	componentDidMount() {
		const provLead=localStorage.getItem("provLead");
		if(provLead!==undefined && provLead!==null){
			this.setState({ ["groupbyprovince"]: provLead });
		}
		 
		this.gotocomponentAction();

	}
  
	gotocomponentAction = () => {
		// this.props.getZendLeadsAll();
		const { t } = this.props; 
		//this.props.getNeoVentesAllPending(); //getNeoVentesAll();
		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		// const groupbyprovince=this.state.groupbyprovince;
		let groupbyprovince=this.state.groupbyprovince;
		const provLead=localStorage.getItem("provLead");
		if(provLead!==undefined && provLead!==null && (groupbyprovince===null || groupbyprovince==="")){
			groupbyprovince= provLead;
		}
		const xtrapar={status:"1", ticket_type:"smed", prov:groupbyprovince,};
		if(this.actionMode==='my'){ xtrapar.status="2"; }
		console.log("xtrapar=xtrapar=", xtrapar);
		Promise.resolve(this.props.getZendLeadsAll(xtrapar)) 
        .then((response)=>{
			const recordList=response.ZendLeads; 
		  this.setState({ ["isLoaderOpen"]: false, ["recordsList"]: recordList});
		  this.showToast(t("zqwCViewEl11_x"), "success"); 
		  //console.log("PEEEEEEENDING", response); this.props.ZendLeads recordsList 
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          alert(error);
        });


		this.interval= setInterval(() => {
			console.log('goooooooooooing to reaload leads ');
			this2.handleLeadsReload(null);
		}, 480000);
	  
		//   return () => clearInterval(interval);

	}

	componentWillUnmount() {
		console.log('The component is going to be unmounted, let us clear ');
		clearInterval(this.interval);
	}

	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info
		const toastrDuration=this.state.toastrDuration; //5500	 
		this.setState({  
			toastrMessage: arg, 
		  toastrType: toastType
		  });
		setTimeout(() => { 
		  this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		  }); 
		}, toastrDuration);
	}

	handleLeadsReload = (arg) => {
		const { t } = this.props;   
		// const this2=this;// Promise.resolve(this.props.getZendLeadsAll()) 
		let groupbyprovince=this.state.groupbyprovince;
		const provLead=localStorage.getItem("provLead");
		if(provLead!==undefined && provLead!==null && (groupbyprovince===null || groupbyprovince==="")){
			groupbyprovince= provLead;
		}
		const xtrapar={status:"1", ticket_type:"smed", prov:groupbyprovince,};
		// const xtrapar={status:"1", ticket_type:"lead"};
		if(this.actionMode==='my'){ xtrapar.status="2"; }   
		console.log("xtrapar=xtrapar=", xtrapar);
		Promise.resolve(this.props.getZendLeadsAll(xtrapar)) 
        .then((response)=>{
			const recordList=response.ZendLeads; 
		  this.setState({ ["isLoaderOpen"]: false, ["recordsList"]: recordList});
		   this.showToast(t("zqwCViewEl11_x"), "success"); 
		  //console.log("PEEEEEEENDING", response);  
          return response;
        }).catch(function(error){ 
        //   this2.setState({ ["isLoaderOpen"]: false});  
          console.log(error);
        });
	}
	
	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.ZendLeads;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  	objList.splice(index, 1);
		} 
		this.props.deleteZendLeads(gridIds[0]);

	}

  updateXRecordsMain(selectedRows){  
    const { t } = this.props;
	const objList  = this.props.ZendLeads; 
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
    //   gridIndexes.push(row.index); 
    //   idIndex=row.index; 
	//gridIndexes.push(row.index); //current in filter
	gridIndexes.push(row.dataIndex); //global 
	//idIndex=row.index; 
	idIndex=row.dataIndex; 
	return null;
    }); 
    
    // let gridIndexes=[];  let idIndex=-1;   
    // selectedRows.data.map(row => {  
    //   gridIndexes.push(row.index); 
    //   idIndex=row.index; 
	// return null;
    // });
 
    if(gridIndexes.length>1){
      alert(`{t('zqwgridIndexesfound_x')}`);
      return false;
    }
 
    let obj=objList[idIndex]; 
	this.props.getZendLeadsGoto(obj.id); 
	//history.push(`/zendmeds/${obj.id}/edit`); 
  }

   
  handleOpenMyLeads = (event) => {  
	this.actionMode='my'; 	 
    history.push(`/myzendleads`);
	this.gotocomponentAction(); 
  };

  handleOpenLeads = (event) => { 
	this.actionMode='all';      
    history.push(`/zendleads`); 
	this.gotocomponentAction();
  };

  handleReloadLeads = (event) => { 
	// this.actionMode='all';      
    // history.push(`/zendleads`); 
	this.gotocomponentAction();
  };

   
  handleChangeProv = (event) => {
    const cval=event.target.value;
    // this.setState({ ["country"]: event.target.value });
	this.setState({ [event.target.name]: event.target.value });
    localStorage.setItem("provLead", cval);
  };
  

  render() { 
    const { t } = this.props;
	
	let specCol=null;
	if(this.actionMode==='my'){
		specCol={
			name: "ticket_id", label: t('zqwticket_id_x'),
			options: { filter: true, sort: true,}
		};
	}

	
	const columns = [
		// {
		//  name: "id", label: t('zqwid_x'),
		//  options: { filter: true, sort: true,}
		// },  name: "ticket_id", label: t('zqwticket_id_x'),
		{
			name: "ticket_id", label: "Ticket",
			options: { filter: true, sort: true,}
		},

		// {
		// 	name: "subject", label: t('zqwZendtx1_x'),
		// 	// options: { filter: true, sort: true,}
		// 	options: { filter: true, sort: true, 
		// 	// customBodyRenderLite: (dataIndex, rowIndex) => {
		// 	// 	// let val = this.state.data[dataIndex].salary;
		// 	// 	let val = "he....."+dataIndex;   
		// 	// 	return val;
		// 	//   },
		// 	}
		// },
		{
			name: "subject", label: t('zqwZendtx1_x'),
			// options: { filter: true, sort: true,}
			options: { filter: true, sort: true, 
				customBodyRender: (value, tableMeta, updateValue) => {
					console.log("tableMeta", tableMeta);
					const rowIndex=tableMeta.rowIndex;
					let valCmp = this.state.recordsList[rowIndex].description; //dataIndex
					let ticket = this.state.recordsList[rowIndex].ticket_id;
					let subject = this.state.recordsList[rowIndex].subject;
					const zx=valCmp.split("http://send.neolegal.ca");
					// http://send.neolegal.ca  
					let description=zx[0];
					description=description.replaceAll("\n\n", "<br/>");
					description=description.replaceAll("\n", "<br/>");
					return (
						<HtmlTooltip
							title={
							<React.Fragment> 
								Ticket  #{ticket}<br/><br/>
								<b>{subject}</b><br/><br/>
								<div dangerouslySetInnerHTML={{ __html: description }}></div>
								{/* {description}<br/> */}
							</React.Fragment>
							}
						>
							<span>{value}</span>
						</HtmlTooltip>
					  ); 
				}
			}
		},
		// {
		// 	name: "XVal", 
		// 	options: { 
		// 	customBodyRenderLite: (dataIndex, rowIndex) => {
		// 		// let val = this.state.data[dataIndex].salary;
		// 		let val = "he....."+dataIndex;   
		// 		// return val;
		// 		return (
		// 			<Tooltip title="Failing">
		// 			  <Error color="error" />
		// 			</Tooltip>
		// 		  );
		// 	  },
		// 	}
		// },
		{
			name: "inViewby",label: "...",
			options: {filter: false, sort: false,  
			  customBodyRender: (value, tableMeta, updateValue) => {
				console.log("customBodyRender ", value);				
				if (value !== null && value !== "")
				  return (
					<Tooltip title={value}>
					  <VisibilityIcon color="error" />
					</Tooltip>
				  );
				else
				  return (
					null
				  );

				if (value === "OK")
				  return (
					<Tooltip title="OK">
					  <Done color="primary" />
					</Tooltip>
				  );
				else
				  return (
					<Tooltip title="Failing">
					  <Error color="error" />
					</Tooltip>
				  );
			  }
			}
		 },
		{
			name: "requester", label: t('zqwZendtx2_x'),
			options: { filter: true, sort: true,}
		},
		{
			name: "update", label: t('zqwZendtx3_x'),
			options: { filter: true, sort: true,}
		},
		// {
		// 	name: "assigned", label: t('zqwZendtx4_x'),
		// 	options: { filter: true, sort: true,}
		// },
		{
			name: "created", label: t('zqwZendtx5_x'),
			options: { filter: true, sort: true,}
		},
		{
			name: "province", label: t('zqwprovince_x'),
			options: { filter: true, sort: true,}
		},


		// {
		//  name: "reason", label: t('zqwreason_x'),
		//  options: { filter: true, sort: true, display: false,}
		// },
		{
			name: "assigned", label: t('zqwZendtx4_x'),
			options: { filter: true, sort: true,display: false,}
		},
		{
		 name: "assig_by", label: t('zqwassig_by_x'),
		 options: { filter: true, sort: true, display: false,}
		},
		{
		 name: "assig_date", label: t('zqwassig_date_x'),
		 options: { filter: true, sort: true, display: false,}
		},
		{
		 name: "assig_to", label: t('zqwassig_to_x'),
		 options: { filter: true, sort: true, display: false,}
		},
		{
		 name: "recall_date", label: t('zqwrecall_date_x'),
		 options: { filter: true, sort: true, display: false,}
		},
		{
		 name: "update_by", label: t('zqwupdate_by_x'),
		 options: { filter: true, sort: true, display: false,}
		},
		{
		 name: "last_update", label: t('zqwlast_update_x'),
		 options: { filter: true, sort: true, display: false,}
		},
		{
		 name: "viewing_by", label: t('zqwviewing_by_x'),
		 options: { filter: true, sort: true, display: false,}
		},
		{
		 name: "viewing_date", label: t('zqwviewing_date_x'),
		 options: { filter: true, sort: true, display: false,}
		},
		{
		 name: "status", label: t('zqwstatus_x'),
		 options: { filter: true, sort: true, display: false,}
		},
		// {
		// 	name: "ticket_id", label: t('zqwticket_id_x'),
		// 	options: { filter: true, sort: true,}
		// },
		{
			name: "client_id", label: t('zqwclient_id_x'),
			options: { filter: true, sort: true, display: false,}
		},
		{
			name: "email", label: t('zqwemail_x'),
			options: { filter: true, sort: true, display: true,}
		},

		/*{
		 name: "p_id", label: t('zqwp_id_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "definition", label: t('zqwdefinition_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "ticket_id", label: t('zqwticket_id_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "client_id", label: t('zqwclient_id_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "email", label: t('zqwemail_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "date_created", label: t('zqwdate_created_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "delay", label: t('zqwdelay_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "done", label: t('zqwdone_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "date_executed", label: t('zqwdate_executed_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "result", label: t('zqwresult_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "ticket_type", label: t('zqwticket_type_x'),
		 options: { filter: true, sort: true,}
		},*/

	   ];
const textLabelOptions= {
    body: {
      noMatch: t('zqwgridnoMatch_x'),
      toolTip: t('zqwgridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('zqwgridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('zqwgridnext_x'),
      previous: t('zqwgridprevious_x'),
      rowsPerPage: t('zqwgridrowsPerPage_x'),
      displayRows: t('zqwgriddisplayRows_x'),
    },
    toolbar: {
      search: t('zqwgridsearch_x'),
      downloadCsv: t('zqwgriddownloadCsv_x'),
      print: t('zqwgridprint_x'),
      viewColumns: t('zqwgridviewColumns_x'),
      filterTable: t('zqwgridfilterTable_x'),
    },
    filter: {
      all: t('zqwgridall_x'),
      title: t('zqwgridftitle_x'),
      reset: t('zqwgridreset_x'),
    },
    viewColumns: {
      title: t('zqwgridvctitle_x'),
      titleAria: t('zqwgridvctitleAria_x'),
    }, 
  };	
			
	const options = {
	  filterType: 'checkbox',
	  textLabels: textLabelOptions,
	  onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		//console.log(currentRowsSelected, allRowsSelected);
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log(allRowsSelected);
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
		const dataIndex=rowState.dataIndex;
		// const recordsList=this.state.recordsList;
		let ticket = this.state.recordsList[dataIndex].ticket_id;
		let rId = this.state.recordsList[dataIndex].id;
		// history.push(`./zendmeds/${rowData[0]}/edit`);  
		// history.push(`./zendmeds/${rId}/edit`); 
		if(this.actionMode==='my'){
			history.push(`./zendmeds/${rId}/edit?my=1`); 
		}else{ 
			history.push(`./zendmeds/${rId}/edit`); 
		} 
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} isEdit={true} isDelete={true} />
      ), 

	//   customRowRender: data => {
	// 	const [ name, cardNumber, cvc, expiry ] = data;
	// 	const [ id, subject, requester, update,assigned,created,ticket_id ] = data;
		
	// 	return (
	// 	  <tr key={id}>
	// 		{/* <td colSpan={4} style={{ paddingTop: "10px"}}>
	// 		  <YourCustomRowComponent
	// 			name={name}
	// 			cardNumber={cardNumber}
	// 			cvc={cvc}
	// 			expiry={expiry}
	// 		  />
	// 		</td> */}

	// 		{/* <td colSpan={4} style={{ paddingTop: "10px"}}>{name}</td> */}
	// 		<td style={{ paddingTop: "10px"}}> </td>
	// 		<td style={{ paddingTop: "10px"}}>{id}</td>
	// 		<td style={{ paddingTop: "10px"}}>{subject}</td>
	// 		<td  style={{ paddingTop: "10px"}}>{requester}</td>
	// 		<td style={{ paddingTop: "10px"}}>{update}</td>
	// 		<td style={{ paddingTop: "10px"}}>{assigned}</td>
	// 		<td style={{ paddingTop: "10px"}}>{created}</td>
	// 		<td style={{ paddingTop: "10px"}}>{ticket_id}..</td>
    
	// 	  </tr>
	// 	);
	//   },
	};
	
	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType; 
	const recordsList=this.state.recordsList;
	// const groupbyprovince=""+this.state.groupbyprovince;
	let groupbyprovince=this.state.groupbyprovince;
	const provLead=localStorage.getItem("provLead");
	if(provLead!==undefined && provLead!==null && (groupbyprovince===null || groupbyprovince==="")){
		groupbyprovince= provLead;
	}
	  
	console.log(">>>>>>> LEADS LIST...", this.props.ZendLeads); //this.props.ZendLeads recordsList 
	// if(this.props.ZendLeads!==undefined && this.props.ZendLeads.length) {    
    if(recordsList!==undefined && recordsList.length) {                                
      return (
        <div>
          {/* <h4>{t('zqwZendLeads_x')}</h4> */}
		  {this.actionMode==='my'? <h4>{t('zqwZendLeads200_x')}</h4>:<h4>{t('zqwZendLeads00_x')}</h4>}
		  <div className="form-group"> 
		   <div className="input-group"> 
		  {/* {this.actionMode!=='my'? <>
			  <button type="button" onClick={this.handleOpenMyLeads} data-action="close" className="btn btn-primary">{t('zqwZendLeads3_x')}</button>
		  </>:<button type="button" onClick={this.handleOpenLeads} data-action="close" className="btn btn-primary">{t('zqwCViewEl56_x')}</button>
		  }	 
		  
            &nbsp; &nbsp; &nbsp;  */}
			<div className="md-4">
		     <select name="groupbyprovince" className={'form-control'} 
              	defaultValue={groupbyprovince}  onChange={this.handleChangeProv}> 
                <option value=""> {t("zqwCViewEl39_x")} </option>  
				<option value="QC"> Québec </option>
                <option value="ON"> Ontario </option> 
             </select> </div>
             &nbsp; &nbsp; &nbsp; 
		     <button type="button" onClick={this.handleReloadLeads} className="btn btn-success">{t('zqwCViewEl42_x')}</button>
           </div>    
          </div>	  
		  <MUIDataTable
			  title={""}
			  data={recordsList}
			  columns={columns}
			  options={options}
			/>
			 
			{/* <NeoLabExample  />    */}

			<Loader  openx={this.state.isLoaderOpen}  />
			<Toastr messagex={myToastMessage} open={true} toastType={toastrType} /> 
        </div>
      )    
    } else {
      return (<div>
		  {this.actionMode==='my'? <h4>{t('zqwZendLeads200_x')}</h4>:<h4>{t('zqwZendLeads00_x')}</h4>}
		  {t('zqwgridNoRecordfound_x')} <Loader  openx={this.state.isLoaderOpen}  />
	   
		  <div className="form-group"> 
		   <div className="input-group"> 
		  {/* {this.actionMode!=='my'? <>
			  <button type="button" onClick={this.handleOpenMyLeads} data-action="close" className="btn btn-primary">{t('zqwZendLeads3_x')}</button>
		  </>:<button type="button" onClick={this.handleOpenLeads} data-action="close" className="btn btn-primary">{t('zqwCViewEl56_x')}</button>
		  }	 
		  
            &nbsp; &nbsp; &nbsp;  */}
			<div className="md-4">
		     <select name="groupbyprovince" className={'form-control'} 
              	defaultValue={groupbyprovince}  onChange={this.handleChangeProv}> 
                <option value=""> {t("zqwCViewEl39_x")} </option>  
				<option value="QC"> Québec </option>
                <option value="ON"> Ontario </option> 
             </select> </div>
             &nbsp; &nbsp; &nbsp; 
		     <button type="button" onClick={this.handleReloadLeads} className="btn btn-success">{t('zqwCViewEl42_x')}</button>
           </div>    
          </div>
	  </div>)
    }  }
}


const mapStateToProps = (state) => ({ ZendLeads: state.ZendLeads });  
const mapDispatchToProps = { addOrUpdateZendLeads, getZendLeads, getZendLeadsAll, getZendLeadsGoto, deleteZendLeads };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(ZendMedList));
