const messagesEn={
"curlang": "en",
"welcome_x": "Welcome",
"hi_x": "Hi",
"Create_x": "Create",
"New_x": "New",
"Edit_x": "Edit",
"Update_x": "Update",
"Delete_x": "Delete",
"Cancel_x": "Cancel",
"Close_x": "Close", 
"view_x": "View",
"send_x": "Send",
"viewlist_x": "View List",
"viewstat_x": "View Status",
  
"Yes_x": "Yes",
"No_x": "No",
"Yes": "Yes",
"No": "No",
"orr_x": "Or",
"orr2_x": "or",
"Cancellead": "Cancel lead",
"Closel_x": "Finalize lead", 


"uname_x": "Email",
"upass_x": "Password",
"tipoUsuario_x": "User Type",
"Seleccionar_x": "Select",
"pleaseSel_x": "Please select",
"Cliente_x": "Client",
"Operador_x": "Operator",
"Administrativo_x": "Administrative",
"idioma_x": "Language",
"Recuerdame_x": "Remember me",
"login_x": "Log in",
"recoverpass_x": "I forgot my password",
"resetpass_x": "Reset User Password",
"qemail_x": "Email",
"btnresetpass_x": "Reset Password",
"btnCancelar_x": "Cancel",


"cliente_x": "Client",
"nombre_x": "Name",
"Ruc_Cedula_x": "ID",
"dv_x": "Dv",
"Encargado_CxC_x": "Receivable Ac. Person In charge",
"telefono_x": "Telephone",
"direccion_x": "Address",
"habilitado_x": "Enabled",
"Si_x": "Yes",
"Email_x": "Email",
"Retencion_Impuesto_x": "Tax Retention",
"registry_date_x": "Registry Date",
"errorFoundonform_x": "You have some errors in the form.",
"acAddClientes_x": "Add Clients",
"acEditClientes_x": "Edit Clients",
 


"gridIndexesfound_x": "Sorry, you have to check only one record to be able to edit it.",
"gridnoMatch_x": "Sorry, no matching records found",
"gridtoolTiph_x": "Sort",
"gridSortfor_x": "Sort for",
"gridnext_x": "Next Page",
"gridprevious_x": "Previous Page",
"gridrowsPerPage_x": "Rows per page:",
"griddisplayRows_x": "of",
"gridsearch_x": "Search",
"griddownloadCsv_x": "Download CSV",
"gridprint_x": "Print",
"gridviewColumns_x": "View Columns",
"gridfilterTable_x": "Filter Table",
"gridall_x": "All",
"gridftitle_x": "FILTERS",
"gridreset_x": "RESET",
"gridvctitle_x": "Show Columns",
"gridvctitleAria_x": "Show/Hide Table Columns",
"AssetTypes_x": "Assettypes",
"gridNoRecordfound_x": "No record found to display",




"menuhome_x": "Home",
"menudashboard_x": "Board",
"menuzonlineusuarios_x": "User profile",
"menuvwdespachosalerts_x": "Dispatches",
"menuvwfacturas_x": "All Invoices",
"menuvwfacturasnopagadas_x": "Unpaid Invoices",
"menuvwfacturaspagadas_x": "Paid Invoices",
"menuvwpagos_x": "Payments Made",
"menuid_x": "Id",
"ZxCompanies_x": "List of Companies/Branches",
"togoutcnfti_x": "Dear user",
"logoutconfir_x": "Are you sure that you want to logout the system",
"changeLang_x": "Switch Language",






"zqwid_x": "Id",
"zqwkey_x": "Key",
"zqwall_access_x": "All Access",
"zqwcontroller_x": "Controller",
"zqwdate_created_x": "Date Created",
"zqwdate_modified_x": "Date Modified",
"zqwerrorFoundonform_x": "you have some errors on the form.",
"zqwacAddAccess_x": "Add Access",
"zqwacEditAccess_x": "Edit Access",
"zqwgridIndexesfound_x": "Sorry, you have to check only one record to be able to edit it.",
"zqwgridnoMatch_x": "Sorry, no matching records found",
"zqwgridtoolTiph_x": "Sort",
"zqwgridSortfor_x": "Sort for",
"zqwgridnext_x": "Next Page",
"zqwgridprevious_x": "Previous Page",
"zqwgridrowsPerPage_x": "Rows per page:",
"zqwgriddisplayRows_x": "of",
"zqwgridsearch_x": "Search",
"zqwgriddownloadCsv_x": "Download CSV",
"zqwgridprint_x": "Print",
"zqwgridviewColumns_x": "View Columns",
"zqwgridfilterTable_x": "Filter Table",
"zqwgridall_x": "All",
"zqwgridftitle_x": "FILTERS",
"zqwgridreset_x": "RESET",
"zqwgridvctitle_x": "Show Columns",
"zqwgridvctitleAria_x": "Show/Hide Table Columns",
"zqwAccess_x": "Access",
"zqwgridNoRecordfound_x": "No record found to display",
"zqwuser_id_x": "User Id",
"zqwlevel_x": "Level",
"zqwignore_limits_x": "Ignore Limits",
"zqwis_private_key_x": "Is Private Key",
"zqwip_addresses_x": "Ip Addresses",
"zqwacAddKeys_x": "Add Keys",
"zqwacEditKeys_x": "Edit Keys",
"zqwKeys_x": "Keys",
"zqwuri_x": "Uri",
"zqwmethod_x": "Method",
"zqwparams_x": "Params",
"zqwapi_key_x": "Api Key",
"zqwip_address_x": "Ip Address",
"zqwtime_x": "Time",
"zqwrtime_x": "Rtime",
"zqwauthorized_x": "Authorized",
"zqwresponse_code_x": "Response Code",
"zqwacAddLogs_x": "Add Logs",
"zqwacEditLogs_x": "Edit Logs",
"zqwLogs_x": "Logs",
"zqwid_clients_x": "Client",
"zqwaddress_x": "Address",
"zqwcity_x": "City",
"zqwprovince_x": "Province",
"zqwcp_x": "Cp",
"zqwcountry_x": "Country",
"zqwcreated_x": "Created",
"zqwactive_x": "Active",
"zqwtel_x": "Tel",
"zqwext_x": "Ext",
"zqwcompany_x": "Company",
"zqwname_x": "Name",
"zqwtype_x": "Type",
"zqwinvoicing_x": "Invoicing",
"zqwdelivery_x": "Delivery",
"zqwacAddNeoAddress_x": "Add NeoAddress",
"zqwacEditNeoAddress_x": "Edit NeoAddress",
"zqwNeoAddress_x": "Neoaddress",
"zqwid_campaign_x": "Id Campaign",
"zqwraw_data_x": "Raw Data",
"zqwacAddNeoCampaign_x": "Add NeoCampaign",
"zqwacEditNeoCampaign_x": "Edit NeoCampaign",
"zqwNeoCampaign_x": "Neocampaign",
"zqwid_folders_x": "Id Folders",
"zqwfirstname_x": "First Name",
"zqwlastname_x": "Last Name",
"zqwcell_x": "Cell",
"zqwemail_x": "Email",
"zqwns_x": "Ns",
"zqwcase_x": "Case",
"zqwstatus_x": "Status",
"zqwclosed_x": "Closed",
"zqwpending_x": "Pending",
"zqwinvalid_x": "Invalid",
"zqwopened_x": "Opened",
"zqwid_transactions_x": "Id Transactions",
"zqwlanguage_x": "Language",
"zqwacAddNeoCases_x": "Add NeoCases",
"zqwacEditNeoCases_x": "Edit NeoCases",
"zqwNeoCases_x": "Neocases",
"zqwterms_x": "Terms",
"zqwid_users_x": "Id Users",
"zqwpush_x": "Push",
"zqwid_zendesk_x": "Id Zendesk",
"zqwsms_x": "Sms",
"zqwstripe_id_x": "Stripe Id",
"zqwacAddNeoClients_x": "Add NeoClients",
"zqwacEditNeoClients_x": "Edit NeoClients",
"zqwNeoClients_x": "Neoclients",
"zqwdefinition_x": "Definition",
"zqwticket_id_x": "Ticket Id",
"zqwclient_id_x": "Client Id",
"zqwdelay_x": "Delay",
"zqwdone_x": "Done",
"zqwdate_executed_x": "Date Executed",
"zqwresult_x": "Result",
"zqwticket_type_x": "Ticket Type",
"zqwdeskapp_x": "Deskapp",
"zqwdesk_id_x": "Desk Id",
"zqwguid_x": "Guid",
"zqwacAddNeoDesk_x": "Add NeoDesk",
"zqwacEditNeoDesk_x": "Edit NeoDesk",
"zqwNeoDesk_x": "Neodesk",
"zqwid_notes_x": "Id Notes",
"zqwfile_location_x": "File Location",
"zqwfile_name_x": "File Name",
"zqwfile_extension_x": "File Extension",
"zqwfile_html_x": "File Html",
"zqwis_file_x": "Is File",
"zqwmodified_x": "Modified",
"zqwmetas_x": "Metas",
"zqworg_name_x": "Org Name",
"zqwuniqid_x": "Uniqid",
"zqwacAddNeoDocuments_x": "Add NeoDocuments",
"zqwacEditNeoDocuments_x": "Edit NeoDocuments",
"zqwNeoDocuments_x": "Neodocuments",
"zqwacAddNeoDocuments1_x": "Add NeoDocuments1",
"zqwacEditNeoDocuments1_x": "Edit NeoDocuments1",
"zqwNeoDocuments1_x": "Neodocuments1",
"zqwid_event_x": "Id Event",
"zqwevent_start_x": "Event Start",
"zqwevent_end_x": "Event End",
"zqwduration_x": "Duration",
"zqwevent_tz_x": "Event Tz",
"zqwuser_x": "User",
"zqwservice_id_x": "Service Id",
"zqwacAddNeoEvents_x": "Add NeoEvents",
"zqwacEditNeoEvents_x": "Edit NeoEvents",
"zqwNeoEvents_x": "Neoevents",
"zqwacAddNeoFolders_x": "Add NeoFolders",
"zqwacEditNeoFolders_x": "Edit NeoFolders",
"zqwNeoFolders_x": "Neofolders",
"zqwpayed_x": "Payed",
"zqwsent_x": "Sent",
"zqwoverdue_x": "Overdue",
"zqwview_x": "View",
"zqwdeclined_x": "Declined",
"zqwrefund_x": "Refund",
"zqwdue_date_x": "Due Date",
"zqwsub_total_x": "Sub Total",
"zqwtax1_x": "Tax1",
"zqwtax2_x": "Tax2",
"zqwtoken_x": "Token",
"zqwcode_x": "Code",
"zqwdeclined_code_x": "Declined Code",
"zqwtransact_x": "Transact",
"zqwtax_total_x": "Tax Total",
"zqwtotal_x": "Total",
"zqwid_address_x": "Id Address",
"zqwacAddNeoInvoices_x": "Add NeoInvoices",
"zqwacEditNeoInvoices_x": "Edit NeoInvoices",
"zqwNeoInvoices_x": "Neoinvoices",
"zqwacAddNeoLawyer_x": "Add NeoLawyer",
"zqwacEditNeoLawyer_x": "Edit NeoLawyer",
"zqwNeoLawyer_x": "Neolawyer",
"zqwacAddNeoMailHook_x": "Add NeoMailHook",
"zqwacEditNeoMailHook_x": "Edit NeoMailHook",
"zqwNeoMailHook_x": "Neomailhook",
"zqwid_transaction_x": "Id Transaction",
"zqwid_user_x": "Id User",
"zqwacAddNeoMed_x": "Add NeoMed",
"zqwacEditNeoMed_x": "Edit NeoMed",
"zqwNeoMed_x": "Neomed",
"zqwnote_x": "Note",
"zqwpublic_x": "Public",
"zqwid_lawyer_x": "Id Lawyer",
"zqwread_x": "Read",
"zqwacAddNeoNotes_x": "Add NeoNotes",
"zqwacEditNeoNotes_x": "Edit NeoNotes",
"zqwNeoNotes_x": "Neonotes",
"zqwid_invoices_x": "Id Invoices",
"zqwamount_x": "Amount",
"zqwacAddNeoPayments_x": "Add NeoPayments",
"zqwacEditNeoPayments_x": "Edit NeoPayments",
"zqwNeoPayments_x": "Neopayments",
"zqwtransdata_x": "Transdata",
"zqwsignature_x": "Signature",
"zqwidTrans_x": "Idtrans",
"zqwsource_x": "Source",
"zqwsource2_x": "Problem",
"zqwacAddNeoPaymentsHook_x": "Add NeoPaymentsHook",
"zqwacEditNeoPaymentsHook_x": "Edit NeoPaymentsHook",
"zqwNeoPaymentsHook_x": "Neopaymentshook",
"zqwdescription_x": "Description",
"zqwdata_json_x": "Data Json",
"zqwacAddNeoProducts_x": "Add NeoProducts",
"zqwacEditNeoProducts_x": "Edit NeoProducts",
"zqwNeoProducts_x": "Neoproducts",
"zqwiso_x": "Iso",
"zqwtax1_name_x": "Tax1 Name",
"zqwtax2_name_x": "Tax2 Name",
"zqwacAddNeoProvinces_x": "Add NeoProvinces",
"zqwacEditNeoProvinces_x": "Edit NeoProvinces",
"zqwNeoProvinces_x": "Neoprovinces",
"zqwclientemail_x": "Clientemail",
"zqwreferalname_x": "Referalname",
"zqwreferalemail_x": "Referalemail",
"zqwadw_id_x": "Adw Id",
"zqwclientvoucher_x": "Clientvoucher",
"zqwreferalvoucher_x": "Referalvoucher",
"zqwclientused_x": "Clientused",
"zqwreferalused_x": "Referalused",
"zqwclientdateused_x": "Clientdateused",
"zqwreferaldateused_x": "Referaldateused",
"zqwclientidtrans_x": "Clientidtrans",
"zqwreferalidtrans_x": "Referalidtrans",
"zqwacAddNeoReferal_x": "Add NeoReferal",
"zqwacEditNeoReferal_x": "Edit NeoReferal",
"zqwNeoReferal_x": "Neoreferal",
"zqwdate_x": "Date",
"zqwcommission_x": "Commission",
"zqwidstripe_x": "Idstripe",
"zqwapplication_x": "Application",
"zqwcustomer_id_x": "Customer Id",
"zqwtaxable_x": "Taxable",
"zqworphelin_x": "Orphelin",
"zqwnom_x": "Nom",
"zqwprenom_x": "Prenom",
"zqwidclient_x": "Idclient",
"zqwid_produit_x": "Id Produit",
"zqwproduit_x": "Produit",
"zqwis_business_x": "Is Business",
"zqwclient_created_x": "Client Created",
"zqwacAddNeoRevenus_x": "Add NeoRevenus",
"zqwacEditNeoRevenus_x": "Edit NeoRevenus",
"zqwNeoRevenus_x": "Neorevenus",
"zqwcreated_campaign_x": "Created Campaign",
"zqwcreated_clients_x": "Created Clients",
"zqwacAddNeoSalesCampaign_x": "Add NeoSalesCampaign",
"zqwacEditNeoSalesCampaign_x": "Edit NeoSalesCampaign",
"zqwNeoSalesCampaign_x": "Neosalescampaign",
"zqwcategory_x": "Category",
"zqwid_product_x": "Id Product",
"zqwcat_id_x": "Cat Id",
"zqwacAddNeoServices_x": "Add NeoServices",
"zqwacEditNeoServices_x": "Edit NeoServices",
"zqwNeoServices_x": "Neoservices",
"zqwacAddNeoSrfaxHook_x": "Add NeoSrfaxHook",
"zqwacEditNeoSrfaxHook_x": "Edit NeoSrfaxHook",
"zqwNeoSrfaxHook_x": "Neosrfaxhook",
"zqwcurrency_x": "Currency",
"zqwtype_id_x": "Type Id",
"zqwacAddNeoTarifs_x": "Add NeoTarifs",
"zqwacEditNeoTarifs_x": "Edit NeoTarifs",
"zqwNeoTarifs_x": "Neotarifs",
"zqwacAddNeoTarifsUsers_x": "Add NeoTarifsUsers",
"zqwacEditNeoTarifsUsers_x": "Edit NeoTarifsUsers",
"zqwNeoTarifsUsers_x": "Neotarifsusers",
"zqwacAddNeoTaskdef_x": "Add NeoTaskdef",
"zqwacEditNeoTaskdef_x": "Edit NeoTaskdef",
"zqwNeoTaskdef_x": "Neotaskdef",
"zqwacAddNeoTrackingHook_x": "Add NeoTrackingHook",
"zqwacEditNeoTrackingHook_x": "Edit NeoTrackingHook",
"zqwNeoTrackingHook_x": "Neotrackinghook",
"zqwid_products_x": "Id Products",
"zqwid_vouchers_x": "Id Vouchers",
"zqwpreachat_x": "Preachat",
"zqwacAddNeoTransactions_x": "Add NeoTransactions",
"zqwacEditNeoTransactions_x": "Edit NeoTransactions",
"zqwNeoTransactions_x": "Neotransactions",
"zqwusername_x": "Username",
"zqwpassword_x": "Password",
"zqwadmin_x": "Admin",
"zqwsupervisor_x": "Supervisor",
"zqwlawyer_x": "Lawyer",
"zqwagent_x": "Agent",
"zqwlsupervisor_x": "Lsupervisor",
"zqwcustomer_x": "Customer",
"zqwmarketing_x": "Marketing",
"zqwsales_x": "Sales",
"zqwlegalintern_x": "Legalintern",
"zqwelawyer_x": "Elawyer",
"zqwlast_login_x": "Last Login",
"zqwacAddNeoUsers_x": "Add NeoUsers",
"zqwacEditNeoUsers_x": "Edit NeoUsers",
"zqwNeoUsers_x": "Neousers",
"zqwacAddNeoVentes_x": "Sales",
"zqwacEditNeoVentes_x": "Sales",
"zqwNeoVentes_x": "Sales",
"zqwpercentage_x": "Percentage",
"zqwvalid_start_x": "Valid Start",
"zqwvalid_end_x": "Valid End",
"zqwusage_x": "Usage",
"zqwacAddNeoVouchers_x": "Add NeoVouchers",
"zqwacEditNeoVouchers_x": "Edit NeoVouchers",
"zqwNeoVouchers_x": "Neovouchers",
"zqwCreation_Date_x": "Creation Date",
"zqwDescription_x": "Description",
"zqwAmount_x": "Amount",
"zqwComission_x": "Comission",
"zqwID_x": "Id",
"zqwacAddStripeReport_x": "Add StripeRe port",
"zqwacEditStripeReport_x": "Edit Stripe Report",
"zqwStripeReport_x": "Stripe report",




"zqwweek_plan_id_x": "Week Plan Id",
"zqwwork_date_x": "Work Date",
"zqwexpected_quantity_x": "Expected Quantity",
"zqwexpected_amount_x": "Expected Amount",
"zqwinvoice_quantity_x": "Invoice Quantity",
"zqwinvoice_amount_x": "Invoice Amount",
"zqwpayment_quantity_x": "Payment Quantity",
"zqwpayment_amount_x": "Payment Amount",
"zqwacAddNeoSalesPlanningDaily_x": "Add Sales Planning Daily",
"zqwacEditNeoSalesPlanningDaily_x": "Edit Sales Planning Daily",
"zqwNeoSalesPlanningDaily_x": "Sales planning daily",
"zqwweek_x": "Week",
"zqwweek_start_x": "Week Start",
"zqwweek_end_x": "Week End",
"zqwacAddNeoSalesPlanningWeekly_x": "Add NeoSales Planning Weekly",
"zqwacEditNeoSalesPlanningWeekly_x": "Edit NeoSales Planning Weekly",
"zqwNeoSalesPlanningWeekly_x": "Sales planning weekly",

"zqwtotal_recurrent_x": "Total Recurrent",
"zqwround_recurrent_x": "Round Recurrent",
"zqwday_recurrent_x": "Day Recurrent",
"zqwfrom_date_x": "From Date",
"zqwpayment_type_x": "Payment Type",
"zqwamount_x": "Amount",
"zqwnotes_x": "Notes",
"zqwlast_update_x": "Last Update",
"zqwacAddNeoInvoicesRecurrent_x": "Add NeoInvoicesRecurrent",
"zqwacEditNeoInvoicesRecurrent_x": "Edit NeoInvoicesRecurrent",
"zqwNeoInvoicesRecurrent_x": "Neoinvoicesrecurrent",
"zqwuser_creation_x": "User Creation",
"zqwuser_update_x": "User Update",
"zqwcreation_date_x": "Creation Date",
"zqwupdate_date_x": "Update Date",
"zqwtotalPayment_x": "Total Payment",

"zqwoccupation_x": "Occupation",
"zqwmenuel1_x": "Make a Sale",
"zqwmenuel2_x": "List of Sale",
"zqwmenuel3_x": "Upsale Sales List",
"zqwmenuel4_x": "Pending Sale List",
"zqwmenuel5_x": "B2B Sales List",
"zqwmenuel6_x": "All Transactions",
"zqwmenuel7_x": "Sales planning",
"zqwmenuel8_x": "Sales Report",
"zqwmenuel9_x": "Exit",
"zqwmenuel10_x": "Profile",
"zqwmenuel11_x": "List of Zendesk Lead",
"zqwmenuel12_x": "xweek",
"zqwBHElem1_x": "Average cart",
"zqwBHElem2_x": "Waiting in quantity",
"zqwBHElem3_x": "Realized sales quantity",
"zqwBHElem4_x": "Quantity in percentage",
"zqwBHElem5_x": "Objective of the day to date",
"zqwBHElem6_x": "Today's Sale Objective",
"zqwBHElem7_x": "Weekly sales total",
"zqwBHElem8_x": "Total sale today",
"zqwBHElem9_x": "weekly Obectif dated",
"zqwBHElem10_x": "Waiting amount",
"zqwBHElem11_x": "Amount of sale made",
"zqwBHElem12_x": "Please login to change your password",
"zqwBHElem13_x": "You have been successfully logged out of the system!",
"zqwBHElem14_x": "We're going out ...",
"zqwBHElem15_x": "interac/chargedesk/flexiti",
"zqwBHElem16_x": "wwwwwwww",
"zqwBHElem17_x": "wwwwwwww",
"zqwBHElem18_x": "wwwwwwww",
"zqwBHElem19_x": "wwwwwwww",

"zqwNVFrmEl1_x": "Warning!!! You are changing data for an existing customer",
"zqwNVFrmEl2_x": "The email {{emailneo}} of this client does not exist, you can continue.",
"zqwNVFrmEl3_x": "Error !!! We cannot have an amount greater than",
"zqwNVFrmEl4_x": "You just need to close the sale and create the ticket if that's the case.",
"zqwNVFrmEl5_x": "Warning!!! by changing the province we apply the taxes of this province or territory.",
"zqwNVFrmEl6_x": "United States",
"zqwNVFrmEl7_x": "Error! You must choose at least one product",
"zqwNVFrmEl8_x": "Error! To continue with the transaction, the amount must be greater than 0!",
"zqwNVFrmEl9_x": "Error! To continue you must have at least one valid product!",
"zqwNVFrmEl10_x": "You have errors in the form, please check before sending.",
"zqwNVFrmEl11_x": "Error! For upsell you must choose an existing ticket to attach it!",
"zqwNVFrmEl12_x": "Error! For Native American status you must insert the American card number!",
"zqwNVFrmEl13_x": "Congratulations !!! We have successfully completed your transaction!",
"zqwNVFrmEl13b_x": "Congratulations !!! We have successfully sent your payment request!",
"zqwNVFrmEl14_x": "An error occurred in the expected data from the server. Please try again later.",
"zqwNVFrmEl15_x": "Error! To use Charge Desk, the Way to Pay must be 'Pay Full Amount'!",
"zqwNVFrmEl16_x": "Sorry, we don't do more than once in less than a minute. Please try again in {{timerest}} seconds",
"zqwNVFrmEl17_x": "Error! To use Interac, the Way to Pay must be 'Pay Full Amount'!",
"zqwNVFrmEl18_x": "Error! Sorry this customer has no existing card!",
"zqwNVFrmEl19_x": "Error! To use existing card, the Way to Pay must be 'Pay Full Amount'!",
"zqwNVFrmEl20_x": "You must choose at least one product, there is no product in your basket.",
"zqwNVFrmEl21_x": "Some fields are required, please fill them in",
"zqwNVFrmEl22_x": "Warning! You have already applied a payment, so there is no reason to go back.",
"zqwNVFrmEl23_x": "Error! To complete the transaction you must pay the full amount!",
"zqwNVFrmEl24_x": "Error! To finalize the transaction you must have an existing invoice first!",
"zqwNVFrmEl25_x": "Congratulations !!! We have successfully closed your transaction!",
"zqwNVFrmEl26_x": "Sorry !!! We couldn't close your transaction unfortunately!",
"zqwNVFrmEl27_x": "Before closing make sure the customer has already paid and it's just a desk chage error. \ nAre you sure to continue?",
"zqwNVFrmEl28_x": "Product added",
"zqwNVFrmEl29_x": "Error sorry the maximum page quantity is {{maximum}} not {{selPage}}",
"zqwNVFrmEl30_x": "Please insert the Promo Code if you want to apply a discount.",
"zqwNVFrmEl31_x": "Sorry, we don't process a card more than once in less than a minute. Please try again.",
"zqwNVFrmEl32_x": "First step, please wait a moment",
"zqwNVFrmEl33_x": "We cannot find the Charge Desk load identifier",
"zqwNVFrmEl34_x": "customer email",
"zqwNVFrmEl35_x": "Amount",
"zqwNVFrmEl36_x": "Description",
"zqwNVFrmEl37_x": "Already Paid",
"zqwNVFrmEl38_x": "Status",
"zqwNVFrmEl39_x": "Refunded Amount",
"zqwNVFrmEl40_x": "Gateway",
"zqwoui_x": "Yes",
"zqwnon_x": "No",
"zqwNVFrmEl41_x": "Product Name",
"zqwNVFrmEl42_x": "New Customer",
"zqwNVFrmEl43_x": "Customer name",
"zqwNVFrmEl44_x": "Check ChargeDesk Payment Status",
"zqwNVFrmEl45_x": "Clean and Start Over",
"zqwNVFrmEl46_x": "Neolegal Zero Stress Guarantee",
"zqwNVFrmEl47_x": "Client's Payment Method",
"zqwNVFrmEl48_x": "Summary of your order",
"zqwNVFrmEl49_x": "Promo code",
"zqwNVFrmEl50_x": "optional",
"zqwNVFrmEl51_x": "Validate Code",
"zqwNVFrmEl52_x": "Promos",
"zqwNVFrmEl52b_x": "Promo/reference",
"zqwNVFrmEl53_x": "Choose a promotion ...",
"zqwNVFrmEl54_x": "Native American Status",
"zqwNVFrmEl55_x": "Add to an Existing ticket",
"zqwNVFrmEl56_x": "Choose",
"zqwNVFrmEl57_x": "Subtotal",
"zqwNVFrmEl58_x": "Discount",
"zqwNVFrmEl59_x": "Total before Payment",
"zqwNVFrmEl60_x": "Payments Completed",
"zqwNVFrmEl61_x": "Total",
"zqwNVFrmEl62_x": "Organization of the Payment",
"zqwNVFrmEl63_x": "Way to Pay",
"zqwNVFrmEl64_x": "Pay Full Amount",
"zqwNVFrmEl65_x": "Pay a part (or multiple card)",
"zqwNVFrmEl66_x": "Amount to Pay Now",
"zqwNVFrmEl67_x": "Recurring payments",
"zqwNVFrmEl68_x": "Subsequent payment every ... of the month",
"zqwNVFrmEl69_x": "From",
"zqwNVFrmEl70_x": "date example 2000-12-22",
"zqwNVFrmEl71_x": "Congratulations, payment received successfully !!",
"zqwNVFrmEl71b_x": "Congratulations, payment request successfully sent !!",
"zqwNVFrmEl72_x": "Payment",
"zqwNVFrmEl73_x": "Pay with",
"zqwNVFrmEl74_x": "Paypal by",
"zqwNVFrmEl75_x": "Upsell with CardOnFile",
"zqwNVFrmEl76_x": "Previous Step",
"zqwNVFrmEl77_x": "This is an interac sale, make sure the customer has paid (funds deposited) before clicking finalize. This will create the purchase ticket and email it to the customer.",
"zqwNVFrmEl77b_x": "This is a sale that will be paid by a company to Neolegal, so you must not finalize it here the system will take care of it.",
"zqwNVFrmEl78_x": "FINALIZE THE TRANSACTION",
"zqwNVFrmEl79_x": "CLOSE WITHOUT FINALIZING THE TRANSACTION",
"zqwNVFrmEl80_x": "Find a Client",
"zqwNVFrmEl81_x": "Please indicate the number of pages to proofread",
"zqwNVFrmEl82_x": "Pages",
"zqwNVFrmEl83_x": "Product Price",
"zqwNVFrmEl84_x": "Additional Service",
"zqwNVFrmEl85_x": "Total Taxes",
"zqwNVFrmEl86_x": "Additional Service Options",
"zqwNVFrmEl87_x": "Create a zendesk folder and ticket",
"zqwNVFrmEl88_x": "Remove",
"zqwNVFrmEl89_x": "THE LIST OF PRODUCTS IN THE INVOICE",
"zqwNVFrmEl90_x": "Tax of",
"zqwNVFrmEl91_x": "Total to pay",
"zqwNVFrmEl92_x": "Previous Step",
"zqwNVFrmEl93_x": "Next Step",
"zqwNVFrmEl94_x": "Start Over Completely",
"zqwNVFrmEl95_x": "Customer Info",
"zqwNVFrmEl96_x": "Select a Language",
"zqwNVFrmEl97_x": "French",
"zqwNVFrmEl98_x": "English",
"zqwNVFrmEl99_x": "I agree to receive notifications by SMS concerning my file (recommended)",
"zqwNVFrmEl100_x": "Google find address ...",
"zqwNVFrmEl101_x": "Filtered Map for Canada",
"zqwNVFrmEl102_x": "Filtered Map for United States",
"zqwNVFrmEl103_x": "country or zone to sintoniser",
"zqwNVFrmEl104_x": "Filtered Map for France",
"zqwNVFrmEl105_x": "Filtered Map for The whole world",
"zqwNVFrmEl106_x": "click to see last search with postal code",
"zqwNVFrmEl107_x": "Apt/Suite/Unit",
"zqwNVFrmEl108_x": "#appartement or suite or unit",
"zqwNVFrmEl109_x": "Choose State",
"zqwNVFrmEl110_x": "Postal code addresses",
"zqwNVFrmEl111_x": "The list of possible Address with the same postal code",
"zqwNVFrmEl112_x": "Choose Address option list (optional)",
"zqwNVFrmEl113_x": "Your registration",
"zqwNVFrmEl114_x": "Details",
"zqwNVFrmEl115_x": "Your legal portal",
"zqwNVFrmEl116_x": "Consult a lawyer immediately",
"zqwNVFrmEl117_x": "Your electronic signature",
"zqwNVFrmEl118_x": "Neolegal Zero Stress Guarantee",
"zqwNVFrmEl119_x": "If we realize after your purchase that this product does not suit you, you will of course be fully refunded or we will suggest a more suitable product.",
"zqwNVFrmEl120_x": "Congratulations !!!",
"zqwNVFrmEl121_x": "We have successfully closed your sales transaction",
"zqwNVFrmEl122_x": "you can close that's all",
"zqwNVFrmbtn_x": "Close",
"zqwNVFrmEl123_x": "Product removed",
"zqwNVFrmEl124_x": "wwwwwwww",
"zqwNVFrmEl125_x": "wwwwwwww",
"zqwNVFrmEl126_x": "wwwwwwww",
"zqwNVFrmEl127_x": "wwwwwwww",
"zqwNVFrmEl128_x": "wwwwwwww",
"zqwNVFrmEl129_x": "wwwwwwww",

"zqwCViewEl11_x": "We loaded the data successfully!",
"zqwCViewEl12_x": "Sorry, you have to check only one register to continue",
"zqwCViewEl13_x": "Product Qty",
"zqwCViewEl14_x": "Vendor",
"zqwCViewEl15_x": "List of Sales",
"zqwCViewEl16_x": "Action on Invoice",
"zqwCViewEl17_x": "INVOICE",
"zqwCViewEl18_x": "List of Pending Sales",
"zqwCViewEl19_x": "List of All Sales Transactions",
"zqwCViewEl20_x": "B2B sales list",
"zqwCViewEl21_x": "Upsale Sales List",
"zqwCViewEl22_x": "Error, Please insert the 2 dates.",
"zqwCViewEl23_x": "Date of Sale",
"zqwCViewEl24_x": "New Qty",
"zqwCViewEl25_x": "New Cl.$",
"zqwCViewEl26_x": "FollowUp Qty",
"zqwCViewEl27_x": "FollowUp",
"zqwCViewEl28_x": "Average Cart",
"zqwCViewEl29_x": "Sale By",
"zqwCViewEl30_x": "New Customers",
"zqwCViewEl31_x": "Total tax",
"zqwCViewEl32_x": "SALES PER PERSON",
"zqwCViewEl33_x": "Summary of Sales Objectives",
"zqwCViewEl34_x": "Since yyyy-mm-dd",
"zqwCViewEl35_x": "until yyyy-mm-dd",
"zqwCViewEl36_x": "Per Day",
"zqwCViewEl37_x": "Per Week",
"zqwCViewEl38_x": "Per Month",
"zqwCViewEl39_x": "Without Provinces",
"zqwCViewEl40_x": "By the Provinces",
"zqwCViewEl41_x": "By",
"zqwCViewEl42_x": "Reload Data",
"zqwCViewEl43_x": "Invoice",
"zqwCViewEl44_x": "Send Invoice again",
"zqwCViewEl44b_x": "View the client Invoice as on the email",
"zqwCViewEl45_x": "Send Welcome Email",
"zqwCViewEl46_x": "Ticket Status",
"zqwCViewEl47_x": "Ticket",
"zqwCViewEl48_x": "Welcome",
"zqwCViewEl49_x": "Deselect ALL",
"zqwCViewEl50_x": "Inverse selection",
"zqwCViewEl51_x": "Sent Interac Email once more",
"zqwCViewEl52_x": "Eliminated Interac/pending ChargeDesk",
"zqwCViewEl53_x": "Resend email",
"zqwCViewEl54_x": "Delete",
"zqwCViewEl55_x": "Post Sell Operations",
"zqwCViewEl56_x": "Close & Return",
"zqwCViewEl57_x": "Load Next Page (server)",
"zqwCViewEl58_x": "Back to the lead",
"zqwCViewEl59_x": "Open client file",
"zqwCViewEl60_x": "Sales without Operations",
"zqwCViewEl61_x": "List of sales without Operations",
"zqwCViewEl62_x": "List of sales to finish",
"zqwCViewEl63_x": "wwwwwwww",
"zqwCViewEl64_x": "wwwwwwww",
"zqwCViewEl65_x": "wwwwwwww",
"zqwCViewEl66_x": "wwwwwwww",
"zqwCViewEl67_x": "wwwwwwww",
"zqwCViewEl68_x": "wwwwwwww",
"zqwCViewEl69_x": "wwwwwwww",
"zqwquantity_x": "Quantity",


"zqwacAddNeoB2bleadProspects_x": "B2b Lead Prospects",
"zqwacEditNeoB2bleadProspects_x": "Edit B2b Lead Prospects", 
"partner": "Partner",
"zqwgender_x": "Sex", 
"zqwcurrent_insurances_x": "Do you have an enssurance?",
"zqwsent_x": "Sent",
"zqwdob_x": "Date of birth",
"zqwage_x": "Age",
"zqwsend_date_x": "Sent Date",
"zqwobservation_x": "Observation (precision)", 



"zqwNeoB2bleadProspectsrep_x": "B2B Lead Report", 
"zqwCViewEl29Q_x": "Lead Send By", 
"zqwsend_x": "Send",
"zqwsendleadcny_x": "Do you accept that Neolegal transmits your details to our partner: ...?", 
"zqwsendleadx2_x": "example: will, incorporation, family or other",
"zqwsendleadx3_x": "example: life, disability, other",

"zqwNDICItxl_x": "Conflicts of Interest",
"zqwNDICItx2_x": "Search for conflict of interests",
"zqwNDICItx3_x": "Search",
"zqwNDICItx4_x": "Ignore",
"zqwNDICItx5_x": "Not Applied",
"zqwNDICItx6_x": "No Info Available",
"zqwNDICItx7_x": "No Conflict",
"zqwNDICItx8_x": "Conflict",
"zqwNDICItx9_x": "No search record",
"zqwNDICItxl0_x": "Auther",
"zqwNDICItxl1_x": "Pending",
"zqwNDICItxl2_x": "Conflict List",
"zqwmenuel2x2_x": "Sales List For Conflict Research", 
"zqwNDICItx2x2_x": "Search for conflict",
"zqwNDICItx2x3_x": "Qty Search Record",
"zqwNDICItxl3_x": "Lead Prospect List",
"zqwNDICItxl4_x": "Sent List of Lead Prospect",
"zqwNDICItxl5_x": "Sent By", 
"zqwNDICItxl6_x": "Click here for a customer who does not have an email",
"zqwNDICItxl7_x": "To Generate a random email to this client, therefore he can use it to login his portal",
"zqwNDICItxl6a2_x": "Click", 
"zqwNDICItxl6a3_x": "for a customer who does not have an email",  
"zqwNDICItxl8_x": "You cannot use this email ", 
"zqwfrmdobage_x": "Fill in the date of birth or the age of the person.",
"zqwNDICItbook2_x": "Make an appointment for the client",



"zqwZendtx1_x": "Subject",
"zqwZendtx2_x": "Requestor",
"zqwZendtx3_x": "Updated",
"zqwZendtx4_x": "Assigned",
"zqwZendtx5_x": "Created",
"zqwZendtx6_x": "Processing of Lead",
"zqwZendtx7_x": "qqqqqqqqqq",
"zqwZendtx8_x": "We are loading the Data, please wait a moment ...",


"zqwNeoLeadsReasons_x": "Neo leads reasons",
"zqwacAddSalesBaseFlow_x": "Add Sales Base Flow",
"zqwacEditSalesBaseFlow_x": "Edit Sales Base Flow",
"zqwSalesBaseFlow_x": "Sales base flow", 
"zqwinvoice_x": "Invoice",
"zqwflow_x": "Flow",
"zqwdescription_x": "Description",
"zqwacAddSalesFlow_x": "Add Sales Flow",
"zqwacEditSalesFlow_x": "Edit Sales Flow",
"zqwacEditSalesFlow23_x": "Sales Flow done",
"zqwSalesFlow_x": "Sales flow",
"zqwobject_type_x": "Object Type",
"zqwmacro_x": "Macro",
"zqwtrigger_x": "Trigger",
"zqwautomation_x": "Automation",
"zqwdata_raw_x": "Data Raw",
"zqwstatus_x": "Status",
"zqwlast_update_x": "Last Update",
"zqwacAddZendAutomats_x": "Add Zend Automats",
"zqwacEditZendAutomats_x": "Edit Zend Automats",
"zqwZendAutomats_x": "Zend automats",
"zqwticket_x": "Ticket",
"zqwacAddZendComments_x": "Add Zend Comments",
"zqwacEditZendComments_x": "Edit Zend Comments",
"zqwZendComments_x": "Zend comments",
"zqwacAddZendGroups_x": "Add Zend Groups",
"zqwacEditZendGroups_x": "Edit Zend Groups",
"zqwZendGroups_x": "Zend groups",
"zqwreason_x": "Reason",
"zqwreason2_x": "Lead Classification",
"zqwassig_by_x": "Assigned By",
"zqwassig_date_x": "Assigned Date",
"zqwassig_to_x": "Assigned To",
"zqwrecall_date_x": "Recall Date",
"zqwupdate_by_x": "Update By",
"zqwviewing_by_x": "Viewing By",
"zqwviewing_date_x": "Viewing Date",
"zqwp_id_x": "P Id",
"zqwdefinition_x": "Definition",
"zqwticket_id_x": "Ticket Id",
"zqwclient_id_x": "Client Id",
"zqwemail_x": "Email",
"zqwdate_created_x": "Date Created",
"zqwdelay_x": "Delay",
"zqwdone_x": "Done",
"zqwdate_executed_x": "Date Executed",
"zqwresult_x": "Result",
"zqwticket_type_x": "Ticket Type",
"zqwacAddZendLeads_x": "Add ZendLeads",
"zqwacEditZendLeads_x": "Edit ZendLeads",
"zqwZendLeads_x": "The leads of Zendesk",
"zqwZendLeads2_x": "My leads of Zendesk",
"zqwZendLeads3_x": "Open my leads",
"zqwacAddZendMacros_x": "Add Zend Macros",
"zqwacEditZendMacros_x": "Edit Zend Macros",
"zqwZendMacros_x": "Zend macros",
"zqwacAddZendTickets_x": "Add Zend Tickets",
"zqwacEditZendTickets_x": "Edit Zend Tickets",
"zqwZendTickets_x": "Zend tickets",
"zqwacAddZendUsers_x": "Add Zend Users",
"zqwacEditZendUsers_x": "Edit Zend Users",
"zqwZendUsers_x": "Zend users",
"zqwZendtx9_x": "Read only mode",

"zqwZendtx600_x": "Processing of MED",
"zqwZendLeads00_x": "The MEDs of Zendesk",
"zqwZendLeads200_x": "My MEDs of Zendesk",
"zqwZendLeads300_x": "Open my MEDs",
"zqwmenuel1100_x": "List of Zendesk MED",  
"zqwFlowvtx1_x": "Merge and add Note to Ticket",
"zqwFlowvtx2_x": "Send macro to client", 
"zqwacAddZendMacros002_x": "Send messsage to the client with macro", 
"zqwFlowvtx3_x": "List of macros",
"zqwNDICItx21_x": "Conflict search and customer file",
"zqwFlowvtx4_x": "Add Note to Ticket and Merge",
"zqwFlowvtx5_x": "Merge",
"zqwFlowvtx6_x": "to",
"zqwFlowvtx7_x": "Note to Ticket",
"zqwNDIabr_x": "NDI",
"zqwsalreason_x": "Sales Classification",
"btnSave_x": "Save",
"btnSave2_x": "Save Raison",
"pleaseSelsr_x": "Please select the sale reason",
"zqwsalreason3_x": "The sale reason has been set",
"zqwCDpendsaledel_x": "You are at the point of eliminating a pending sale. Please confirm.",
"zqwphone_x": "Phone", 
"zqwsendsms_x": "Sending SMS",
"zqwFlowvtsmsx7_x": "SMS Note",
"zqwFlowvtsmsx8_x": "SMS message sent successfully!",
"zqwNeoProspectsSms_x": "SMS Prospects",
"smsgridmu_x": "View sent SMS List",
"zqwsendsmsr_x": "Sending SMS Again", 
"zqwTNVFtxt33_x": "Search a Partner Client",
"pleaseSelsr2_x": "Please select ",

"zqwNFFrmZn09_x": "Send form link",
"zqwNFFrmZn010_x": "Form list",
"zqwNFFrmZn010b_x": "The file name to send",
"zqwNFFrmZn011_x": "Send form",
"zqwNFFrmZn012_x": "The form link was sent to ",
"zqwNFFrmZn013_x": "Get the form link",
"zqwNFFrmZn014_x": "Send by email",
"zqwNFFrmZn015_x": "Please choose an email or insert one",
"zqwNFFrmZn016_x": "Choose or insert email",
"zqwNFFrmZn017_x": "Click here to choose me",
"zqwNFFrmZn018_x": "the format of the email is incorrect",
"zqwNVFrmEl78d1_x": "FINALIZE THE SALE TRANSACTION",
"zqwNVFrmEl78d2_x": "DELETE THE SALE TRANSACTION",


"zqwMesas01_x": "Assignee Id  && group Id cannot be both empty",
"zqwMesas02_x": "Assign to",
"zqwMesas03_x": "Group on Zendesk",
"zqwMesas04_x": "Assigning group and/or person to Ticket",
"zqwMesas05_x": "Group {{group}} and user {{assignee}} are assigned to ticket {{ticket}}",
"zqwMesas06_x": "Group {{group}} is assigned to ticket {{ticket}}",
"zqwMesas07_x": "User {{assignee}} is assigned to ticket {{ticket}}",
"zqwMesas08_x": "Assign Ticket to Group/Utilisateur",
"zqwMesas09_x": "MergeMerge and Add Note",
"zqwMesas010_x": "Add Note",
"zqwMesas011_x": "Note type",
"zqwMesas012_x": "Private note",
"zqwMesas013_x": "Public note",
"zqwMesas014_x": "Add Note to ticket",
"notes2_x": "Private notes",
"zqwMesas015_x": "Search of conflict done",
"zqwMesas016_x": "To continue, please make the Search of conflict",
"zqwMesas017_x": "Ticket priority: urgent",
"zqwMesas018_x": "My Leads",
"zqwMesas019_x": "Leads - admin",
"zqwMesas020_x": "Users - admin",
"zqwMesas021_x": "Contact",
"zqwMesas022_x": "Unlikely finalization",
"zqwMesas023_x": "Finalized sales {{sales}} ( pending sales : {{pendings}} ) --- {{salePercentage}} finalized sales",
"zqwMesas024_x": "Finalized sales {{sales}} ( pending sales : {{pendings}} ) --- {{salePercentage}} finalized sales",
"zqwMesas025_x": "one of the following 3 actions is not done: « send form », « set appointment » or « assign »",
"zqwMesas026_x": "to close",
"zqwMesas027_x": "au",
"zqwMesas028_x": "au",
"zqwMesas029_x": "au",
"zqwCViewEl13c_x": "Caution, if you don't chose an email or a phone the form is not sent to the customer, please click on the button send",





}

export default messagesEn;
