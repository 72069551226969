import { RECEIVE_NEOPROSPECTSSMS, UPDATE_NEOPROSPECTSSMS } from '../actions/NeoProspectsSmsAction';

export default function singleNeoProspectsSmsReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NEOPROSPECTSSMS:
      return action.NeoProspectsSms;
    case UPDATE_NEOPROSPECTSSMS:
      return {
        id: action.id,
message: action.payload.message,
ticket_id: action.payload.ticket_id,
client_id: action.payload.client_id,
email: action.payload.email,
user_creation: action.payload.user_creation,
user_update: action.payload.user_update,
creation_date: action.payload.creation_date,
update_date: action.payload.update_date,
status: action.payload.status,
      }
    default:
      return state;
  }
};