import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Label,
  Input,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import Toast from "../Toast/Toast";
import "./MergeTickets.css";
import Notes from "../../UPDATED_testament/inputComponents/sales/component/Notes/Notes";
import axios from "axios";

const URL = "https://neobackendstaging-kagyonahja-nn.a.run.app";
const AUTH_ID = localStorage.getItem("user_id");

function MergeTickets({ trad, history }) {
  const [toast, setToast] = useState({ type: "", message: "" });
  const [searchText, setSearchText] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [clientOptions, setClientOptions] = useState([]);
  const [folderOptions, setFolderOptions] = useState([]);
  const [searchType, setSearchType] = useState("text");
  const [fromTicketId, setFromTicketId] = useState("");
  const [toTicketId, setToTicketId] = useState("");
  const [fromTicketNotes, setFromTicketNotes] = useState([]);
  const [toTicketNotes, setToTicketNotes] = useState([]);
  const [clientSearchLoading, setClientSearchLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (searchText) {
      setClientSearchLoading(true);
      const timeout = setTimeout(async () => {
        getSearchResults(searchText, searchType)
          .then(({ data }) => {
            const clientOptions = data.data.contacts.map((i) => {
              return {
                label: `${i?.firstname} ${i?.lastname}`,
                value: i?.id.toString(),
                cell: i?.cell,
              };
            });
            setClientOptions(clientOptions);
            setClientSearchLoading(false);
          })
          .catch((e) => {
            setClientSearchLoading(false);
          });
      }, 1500);

      return () => clearTimeout(timeout);
    }
  }, [searchText]);

  useEffect(() => {
    if (toTicketId && fromTicketId) {
      mergeTicketsPost("get", fromTicketId, toTicketId)
        .then(({ data }) => {
          if (data.statusCode === 200) {
            setFromTicketNotes(data.data.from);
            setToTicketNotes(data.data.to);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [toTicketId, fromTicketId]);

  const lang = "fr";

  const genLang = (text) => {
    try {
      return trad["neoleads"][text][lang];
    } catch (e) {
      console.error(text);
      return "";
    }
  };

  const handleSubmit = () => {
    setPostLoading(true);
    setShowModal(false);
    mergeTicketsPost("save", fromTicketId, toTicketId)
      .then(({ data }) => {
        if (data.statusCode === 200) {
          setPostLoading(false);
          setToast({ type: "success", message: genLang("postSuccess") });
        }
      })
      .catch((e) => {
        setPostLoading(false);
        setToast({ type: "error", message: genLang("postError") });
        console.error(e);
      });
  };

  const handleClientSelectChange = (e) => {
    if (!e) {
      setSelectedClient("");
      setFromTicketId("");
      setToTicketId("");
      setFromTicketNotes([]);
      setToTicketNotes([]);
    } else {
      setSelectedClient(e.value);
      fetchFolders(e.value);
      setFromTicketNotes([]);
      setToTicketNotes([]);
      setFromTicketId("");
      setToTicketId("");
    }
  };

  const fetchFolders = async (client_id) => {
    const { data } = await getFolders(client_id);
    const folderOptions = data.data.map((i) => {
      return { ...i, label: i.label, value: i.ticket_id };
    });
    setFolderOptions(folderOptions);
  };

  //   const consoleLogState = () => {
  //     console.log("From Ticket: ", fromTicketId);
  //     console.log("To Ticket: ", toTicketId);
  //     console.log("From Notes: ", fromTicketNotes);
  //     console.log("To Notes: ", toTicketNotes);
  //   };

  return (
    <Row className="hasNavBar">
      <Col className="merge-tickets-container">
        <Toast
          type={toast.type}
          message={toast.message}
          clear={() => setToast({ type: "", message: "" })}
        />
        <h5 className="jsontable-title" style={{color: "#0076cf"}}>{genLang("mergeFiles")}</h5>
        <Row style={{ marginBottom: "0.5rem" }}>
          <Col>
            <Label style={{ fontWeight: "bold" }}>Clients</Label>
            <Select
              placeholder={
                !selectedClient ? (
                  genLang("typeToSearch")
                ) : (
                  <span>{`${genLang("select")} ${
                    clientOptions.length
                      ? `${clientOptions.length} ${genLang("results")}`
                      : ""
                  }`}</span>
                )
              }
              className="select-modal"
              isClearable
              isLoading={clientSearchLoading}
              // isDisabled={!clientOptions.length}
              onChange={handleClientSelectChange}
              value={
                !selectedClient
                  ? searchText
                  : clientOptions.find((i) => i.value === selectedClient)
              }
              options={clientOptions}
              onInputChange={(e) => setSearchText(e)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Label style={{ fontWeight: "bold" }} className="notes-label">
              {genLang("originFolder")}
            </Label>
            <Select
              isDisabled={!folderOptions.length || !selectedClient}
              isClearable
              placeholder={
                <span>{`${genLang("select")} ${
                  folderOptions.length
                    ? `${folderOptions.length} ${genLang("results")}`
                    : ""
                }`}</span>
              }
              value={folderOptions?.find((i) => i.value === fromTicketId) || ""}
              onChange={(e) => {
                if (!e) {
                  setFromTicketId("");
                  setFromTicketNotes([]);
                } else setFromTicketId(e.value);
              }}
              options={folderOptions}
            />
            {fromTicketNotes.length ? (
              <>
                <h6
                  style={{ marginTop: "1rem" }}
                >{`Notes du dossier ${fromTicketId}`}</h6>
                <div className="merge-tickets-notes-container scrollbar-visible">
                  <Notes notes={fromTicketNotes} genLang={genLang} />
                </div>
              </>
            ) : (
              ""
            )}
          </Col>

          <Col sm={6}>
            <Label style={{ fontWeight: "bold" }} className="notes-label">
              {genLang("destinationFolder")}
            </Label>
            <Select
              isDisabled={!folderOptions.length || !selectedClient}
              isClearable
              placeholder={
                <span>{`${genLang("select")} ${
                  folderOptions.length
                    ? `${folderOptions.length} ${genLang("results")}`
                    : ""
                }`}</span>
              }
              value={folderOptions?.find((i) => i.value === toTicketId) || ""}
              onChange={(e) => {
                if (!e) {
                  setToTicketId("");
                  setToTicketNotes([]);
                } else setToTicketId(e.value);
              }}
              options={folderOptions}
            />

            {toTicketNotes.length ? (
              <>
                <h6
                  style={{ marginTop: "1rem" }}
                >{`Notes du dossier ${toTicketId}`}</h6>
                <div className="merge-tickets-notes-container scrollbar-visible">
                  <Notes notes={toTicketNotes} genLang={genLang} />
                </div>
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "auto" }}>
          <Col>
            <Button
              color="primary"
              block
              onClick={() => setShowModal(true)}
              disabled={!selectedClient || !toTicketId || !fromTicketId}
            >
              {genLang("register")}
            </Button>
          </Col>
        </Row>
      </Col>
      <MergeTicketsConfirmationModal
        isOpen={showModal}
        genLang={genLang}
        handleSubmit={handleSubmit}
        toggle={() => setShowModal(false)}
        postLoading={postLoading}
        message={genLang("confirmMergeTickets")
          .replace("{{fromTicketId}}", fromTicketId)
          .replace("{{toTicketId}}", toTicketId)}
      />
    </Row>
  );
}

function MergeTicketsConfirmationModal({
  genLang,
  handleSubmit,
  isOpen,
  toggle,
  postLoading,
  message,
}) {
  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader toggle={toggle}>{genLang("confirm")}</ModalHeader>
      <ModalBody>
        <b>{message}</b>
        <div style={{ fontWeight: "600", color: "red" }}>
          {genLang("actionIsIrreversible")}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" block onClick={handleSubmit}>
          {postLoading ? <Spinner size="sm" /> : genLang("register")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default withRouter(MergeTickets);

const mergeTicketsPost = (action, fromTicketId, toTicketId) => {
  return axios.post(
    URL + "/mergeTickets",
    {
      auth_id: AUTH_ID,
      fromTicketId,
      toTicketId,
      action,
    },
    {
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("admin_token"),
      },
    }
  );
};

const getFolders = (client_id) => {
  return axios.get(URL + "/getAllFoldersByClient", {
    params: {
      auth_id: localStorage.getItem("user_id"),
      client_id,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin_token"),
    },
  });
};

const getSearchResults = (query, type) => {
  const path = [
    "firstname",
    "lastname",
    "cell",
    "email",
    "address",
    "city",
    "cp",
    "tel",
    "company",
    "id_external",
  ];
  const phrase = {
    query: query,
    path: path,
  };
  const text = {
    query: query,
    path: path,
    fuzzy: {
      maxEdits: 1,
    },
  };
  const obj = {
    auth_id: localStorage.getItem("user_id"),
    search: {
      [type]: type === "text" ? text : phrase,
    },
  };
  return axios.post(URL + "/search/find", obj, {
    params: {},
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin_token"),
      "x-jwt-token": localStorage.getItem("admin_token"),
    },
  });
};
