import React from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTags,
  faCheck,
  faTimes,
  faBackward,
  faForward,
  faCalendarAlt,
  faQuestion
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

import _ from "lodash";

class ClientPurchases extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: [],
    };
  }

  genLang = (text) => {
    const {trad, lang} = this.props;
    return trad['neoleads'][text][lang]
  }

  renderPurchases(arr = []) {
    if (arr.length === 0){
      return <div
      style={{
        wordBreak: "break-all",
        wordWrap: "break-space",
        width: "100%",
      }}
    >
      <div style={{ color: "#0052bb", marginBottom: '0.5rem'}}>{this.genLang('noPurchases')}</div>
    </div>
    } 
    return arr.map((p, i) => {
      return (
        <div
          key={i}
          style={{
            wordBreak: "break-all",
            wordWrap: "break-space",
            width: "100%",
            // whiteSpace:'nowrap'
          }}
        >
          <div style={{ color: "#0052bb" }}><b>{`${i + 1}) ${p}`}</b></div>
        </div>
      );
    })
  }

  render() {
    return (
        <>
        {this.props.customer.type === 'client' && <div className="p-15 grayish">
        <div className="p-l-10 p-r-10 p-t-10 p-b-10 element-wrapper"> 
          {this.renderPurchases(this.props.customer.transactions)} 
        </div>
      </div>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
});

export default connect(mapStateToProps)(withRouter(ClientPurchases));