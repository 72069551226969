import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NEOB2BLEADPROSPECTS = 'GET_NEOB2BLEADPROSPECTS';
export const ADD_NEOB2BLEADPROSPECTS = 'ADD_NEOB2BLEADPROSPECTS';
export const RECEIVE_NEOB2BLEADPROSPECTSX = 'RECEIVE_NEOB2BLEADPROSPECTSX';
export const REMOVE_NEOB2BLEADPROSPECTS = 'REMOVE_NEOB2BLEADPROSPECTS';
export const UPDATE_NEOB2BLEADPROSPECTS = 'UPDATE_NEOB2BLEADPROSPECTS';
export const REPLACE_NEOB2BLEADPROSPECTS = 'REPLACE_NEOB2BLEADPROSPECTS';
const apiUrl = API_DOMAIN + '/neob2bleadprospects'; 
const apiUrlBase = API_DOMAIN; 

axiosDefaults(null);

export const getNeoB2bleadProspectsAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrlBase}/b2b/lead/external/prospects`)
      .then(response => {
		// let data = response.data.data;  
		// console.log('0000000 RESPONSE getNeoB2bleadProspectsAll  ');  
		// console.log(data.neob2bleadprospects);    
        return dispatch({type: RECEIVE_NEOB2BLEADPROSPECTS, NeoB2bleadProspects: response.data.data.neob2bleadprospects})
      })
      .catch(error => { console.error(error); });
  };
};


export const getNeoB2bleadProspectsAllx = (obj) => {
	//const user=(localStorage.getItem("user")).split(':');
	const date=obj.date; const date2=obj.date2; const groupByDayWeekMonth=obj.dayWeekMonth;
	const province=obj.province;
	const params=`date=${date}&date2=${date2}&grpby=${groupByDayWeekMonth}&report=1`; 
	// return (dispatch) => {
	//   return axios.get(`${apiUrlPeoplesales}?${params}`)

	return (dispatch) => {
	  return axios.get(`${apiUrlBase}/b2b/lead/external/prospects?${params}`)
		.then(response => {
		  // let data = response.data.data;  
		  // console.log('0000000 RESPONSE getNeoB2bleadProspectsAll  ');  
		  // console.log(data.neob2bleadprospects);    
		  return dispatch({type: RECEIVE_NEOB2BLEADPROSPECTS, NeoB2bleadProspects: response.data.data.neob2bleadprospects})
		})
		.catch(error => { console.error(error); });
	};
  };


//apiUrlBase 
export const addNeoB2bleadProspects0000 = (NeoB2bleadProspects) => { 
  return (dispatch) => {
    return axios.post(`${apiUrlBase}/b2b/external/lead/prospects`, NeoB2bleadProspects)
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NEOB2BLEADPROSPECTS, payload: {
			id: data.id,
			firstname: data.firstname,
			lastname: data.lastname,
			cell: data.cell,
			email: data.email,
			gender: data.gender,
			language: data.language,
			dob: data.dob,
			source: data.source,
			current_insurances: data.current_insurances,
			sent: data.sent,
			id_invoices: data.id_invoices,
			user_creation: data.user_creation,
			created: data.created,
		}})
      })
      .then(() => {
        history.push("/NeoB2bleadProspects")
      })
      .catch(error => { console.error(error) });
  };
};

export const addNeoB2bleadProspects = (NeoB2bleadProspects) => { 
	const auth_id=38;
	//return axios.post(`${apiUrl}/sales?auth_id=${auth_id}`, NeoVentes)
	return (dispatch) => {
	  return axios.post(`${apiUrlBase}/b2b/lead/external/prospects`, NeoB2bleadProspects)
		.then(response => {
		  let data = response.data.data;  
		  console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT  ');  
		  console.log(response); 
		  console.log(data);    
		  //dispatch({type: ADD_NEOVENTES, payload: { ...mydataAr } });
		  //return dispatch({type: ADD_NEOVENTES, NeoVentes: data});
		  //return dispatch({type: ADD_NEOB2BLEADPROSPECTS, NeoB2bleadProspects: data});
		  return dispatch({type: ADD_NEOB2BLEADPROSPECTS, NeoB2bleadProspects: data});
		})
		.then(response => {
		  //history.push("/NeoVentes")
		  return response;
		})
		//.catch(error => { console.error(error) });
		.catch(err => {
			throw err
		//   if (err.response) {
		// 	// client received an error response (5xx, 4xx)
		// 	console.log("error falls here #1"); console.log(err.response);
		// 	//console.log(err);
		// 	/*if(err.response.data.status===500){
		// 		let mesx=err.response.data.error.description;
		// 		console.error(mesx);
		// 	}else{
		// 		console.error(err.response.data);
		// 	}*/
		// 	console.error(err.response.data);
		//   } else if (err.request) {
		// 	// client never received a response, or request never left
		// 	console.log("error falls here #2");
		// 	const errMessage="client never received a response, or request never left";
		// 	console.error(errMessage);
		//   } else {
		// 	// anything else
		// 	console.log("error falls here #3"); console.log(err);   
		// 	console.error(err); 
		//   }
	  });
	};
  };
  

export const addNeoB2bleadProspects00 = (NeoB2bleadProspects) => { 
	return (dispatch) => {
	  return axios.post(`${apiUrl}/create`, {		id: NeoB2bleadProspects.id,
		  firstname: NeoB2bleadProspects.firstname,
		  lastname: NeoB2bleadProspects.lastname,
		  cell: NeoB2bleadProspects.cell,
		  email: NeoB2bleadProspects.email,
		  gender: NeoB2bleadProspects.gender,
		  language: NeoB2bleadProspects.language,
		  dob: NeoB2bleadProspects.dob,
		  source: NeoB2bleadProspects.source,
		  current_insurances: NeoB2bleadProspects.current_insurances,
		  sent: NeoB2bleadProspects.sent,
		  id_invoices: NeoB2bleadProspects.id_invoices,
		  user_creation: NeoB2bleadProspects.user_creation,
		  created: NeoB2bleadProspects.created,
	  })
		.then(response => {
		  let data = response.data;
		  dispatch({type: ADD_NEOB2BLEADPROSPECTS, payload: {
			  id: data.id,
			  firstname: data.firstname,
			  lastname: data.lastname,
			  cell: data.cell,
			  email: data.email,
			  gender: data.gender,
			  language: data.language,
			  dob: data.dob,
			  source: data.source,
			  current_insurances: data.current_insurances,
			  sent: data.sent,
			  id_invoices: data.id_invoices,
			  user_creation: data.user_creation,
			  created: data.created,
		  }})
		})
		.then(() => {
		  history.push("/NeoB2bleadProspects")
		})
		.catch(error => { console.error(error) });
	};
  };

export const getNeoB2bleadProspects = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.neob2bleadprospects);		  
        return dispatch({type: RECEIVE_NEOB2BLEADPROSPECTSX, NeoB2bleadProspectsOne: response.data.neob2bleadprospects});
      })
      .catch(error => { 
        console.error(error); 
      });
  };
};

export const getNeoB2bleadExistedProspects = (invoiceId, cnyId) => {
	console.log("BEFORE response from server .... "+invoiceId); 
	//return axios.get(`${apiUrl}/${id}`)
	//return axios.get(`${apiUrlBase}/b2b/lead/external/prospects?${params}`)
 return (dispatch) => {
   return axios.get(`${apiUrlBase}/b2b/lead/external/prospects?id_invoice=${invoiceId}&cnyId=${cnyId}`)
	 .then(response => {
		 console.log("response from server .... ");  console.log(response.data.data);
		 console.log("response from server .... ");  console.log(response);		  
	   return dispatch({type: RECEIVE_NEOB2BLEADPROSPECTSX, NeoB2bleadProspectsOne: response.data.data});
	 })
	 .catch(error => { 
	   console.error(error); 
	// throw error
	 });
 };
};

export const getNeoB2bleadProspectsGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NeoB2bleadProspects/${id}/edit`)
  };
};
export const deleteNeoB2bleadProspects = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NEOB2BLEADPROSPECTS, payload: {id}})
      })
      .then(() => {
        history.push("/NeoB2bleadProspects")
      })
      .catch(error => {
        console.error(error);
      });
  };
};
export const updateNeoB2bleadProspects = (NeoB2bleadProspects) => {
  const id = NeoB2bleadProspects.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NeoB2bleadProspects.id}`, {
		firstname: NeoB2bleadProspects.firstname,
		lastname: NeoB2bleadProspects.lastname,
		cell: NeoB2bleadProspects.cell,
		email: NeoB2bleadProspects.email,
		gender: NeoB2bleadProspects.gender,
		language: NeoB2bleadProspects.language,
		dob: NeoB2bleadProspects.dob,
		source: NeoB2bleadProspects.source,
		current_insurances: NeoB2bleadProspects.current_insurances,
		sent: NeoB2bleadProspects.sent,
		id_invoices: NeoB2bleadProspects.id_invoices,
		user_creation: NeoB2bleadProspects.user_creation,
		created: NeoB2bleadProspects.created,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NEOB2BLEADPROSPECTS, payload: {
			id: data.id,
			firstname: data.firstname,
			lastname: data.lastname,
			cell: data.cell,
			email: data.email,
			gender: data.gender,
			language: data.language,
			dob: data.dob,
			source: data.source,
			current_insurances: data.current_insurances,
			sent: data.sent,
			id_invoices: data.id_invoices,
			user_creation: data.user_creation,
			created: data.created,
		}})
        dispatch({type: REPLACE_NEOB2BLEADPROSPECTS, payload: {
			id: data.id,
			firstname: data.firstname,
			lastname: data.lastname,
			cell: data.cell,
			email: data.email,
			gender: data.gender,
			language: data.language,
			dob: data.dob,
			source: data.source,
			current_insurances: data.current_insurances,
			sent: data.sent,
			id_invoices: data.id_invoices,
			user_creation: data.user_creation,
			created: data.created,
		}})
      })
      .then(() => {
        history.push(`/NeoB2bleadProspects/${id}`)
      })
      .catch(error => { throw error });
  };
};
export const addOrUpdateNeoB2bleadProspects = (NeoB2bleadProspects, actionMode) => {
	if(actionMode===1){
        return addNeoB2bleadProspects(NeoB2bleadProspects);
      }else {
        return updateNeoB2bleadProspects(NeoB2bleadProspects);
      }
};