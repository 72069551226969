import { RECEIVE_NEOPROSPECTSSMS, ADD_NEOPROSPECTSSMS, REMOVE_NEOPROSPECTSSMS, REPLACE_NEOPROSPECTSSMS } from '../actions/NeoProspectsSmsAction';

const initialState = { NeoProspectsSms: [] }
export default function NeoProspectsSmsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NEOPROSPECTSSMS:
      return action.NeoProspectsSms;
    case ADD_NEOPROSPECTSSMS:
      return [action.payload, ...state];
    case REMOVE_NEOPROSPECTSSMS:
      return state.filter(NeoProspectsSms => NeoProspectsSms.id !== action.payload.id);
    case REPLACE_NEOPROSPECTSSMS:
      return state.map((NeoProspectsSms) => {
        if (NeoProspectsSms.id === action.payload.id) {
          return {
            ...NeoProspectsSms,
message: action.payload.message,
ticket_id: action.payload.ticket_id,
client_id: action.payload.client_id,
email: action.payload.email,
user_creation: action.payload.user_creation,
user_update: action.payload.user_update,
creation_date: action.payload.creation_date,
update_date: action.payload.update_date,
status: action.payload.status,
          }
        } else return NeoProspectsSms;
      })
    default:
      return state;
  }
}
