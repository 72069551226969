import React, {
    Component
} from 'react';
import {
    Row,
    Badge,
    Col,
} from 'reactstrap';
import TopMenu from './component/TopMenu';
import AppBar from './component/AppBar'

import TimelineLeadTicketRO from './component/TimelineLeadTicketRO'
const profile = require('./thonsid.png');
class LeadDetailsTicket extends Component {
    leadId=0;
    constructor(props) {
        super(props);
        this.state = {
            lang: 'fr'
        }

        const locurl=window.location.href;
        //const patt=/([0-9]+)(\/edit)$/;
        const patt=/(\/agent\/tickets\/)([0-9]+)$/g;
        if(patt.test(locurl)){ 
            const foundText = locurl.match(patt);
            const cleanId=(""+foundText[0]).replace("/agent/tickets/", "");
            this.leadId=parseInt(cleanId);
        }
    }

    toggleLang = () => {
        const lang = this.state.lang === 'fr' ? 'en' : 'fr'
        this.setState({lang: lang});
    }
    
    render() {
        let leadId=0;  //this.props.id; 
        //const leadIdx = this.props.match.params.id;  
        if(this.props.id!==undefined){ leadId=this.props.id; }
        else if(this.props.match.params.id!==undefined){ leadId=this.props.match.params.id; }
        else if(this.leadId>0){ leadId=this.leadId; }
        // return(<>hallo</>)
        return (
            <div
                className='hasSideBar'
            >
                <Row>
                    <TopMenu
                        active={"leads"}
                        lang={this.state.lang}
                    />
                </Row>
     
                <Row>
                    {/* <Badge 
                        onClick={this.toggleLang}
                        className="top-right"
                        style={{position:'fixed',top:'40px',width:'45px',right:0}}
                        >{this.state.lang === 'fr' ? 'EN' : 'FR'}
                    </Badge> */}
 
                    <Col md={8} sm={8} xs={12} className="centerApp">
                        <TimelineLeadTicketRO id={leadId} trad={this.props.trad} lang={this.state.lang} showCalendar={this.state.showCalendar} toggleCalendar={this.toggleCalendar}/>
                    </Col>
                    <Col 
                        md={4} 
                        sm={4} 
                        xs={12}
                    >
                        <AppBar trad={this.props.trad} lang={this.state.lang}/>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default LeadDetailsTicket;