import React from 'react'
import { useTable, usePagination,useSortBy  } from 'react-table'
import {
  Button,
  ButtonGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table as RTable,
  Label,
  ButtonToolbar
} from 'reactstrap'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSortAmountDown,
  faSortAmountUp, } from '@fortawesome/free-solid-svg-icons';

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, 
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    manualSortBy,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 },
  }, useSortBy, usePagination )


  // Render the UI for your table
  return (
    <div className="table-responsive">
      <RTable {...getTableProps()} responsive striped>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th 
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span 
                    // className="m-l-5"
                    style={{
                      display:'inline',
                      marginLeft:'1px'
                    }}>
                    {column.isSorted ? (column.isSortedDesc ?<FontAwesomeIcon icon={faSortAmountDown} />:<FontAwesomeIcon icon={faSortAmountUp} />) : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(page&&page.length)?page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          }):<tr>
              <td>
                Aucun résultat
              </td>
            </tr>
          }
        </tbody>
      </RTable>
     {/*  <ButtonToolbar className="pagination p-t-10 p-l-10 p-r-10 p-b-10">

        <Button 
        className="m-r-5"
          type={'button'}
          color='primary'
          onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </Button>
        <Button 
        className="m-r-5"
          type={'button'}
          color='primary'
          onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </Button>
        <Button 
        className="m-r-5"
          type={'button'}
          color='primary'
          onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </Button>
        <Button 
        className="m-r-5"
          type={'button'}
          color='primary'
          onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </Button>
        
        <span style={{padding:'0 10px'}}>
          <strong style={{verticalAlign:'middle'}}>
          Page{' '}{pageIndex + 1} de {pageOptions.length}
          </strong>{'   '}
        </span>{' '}
        <InputGroup >
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Aller à la page #</InputGroupText>
          </InputGroupAddon>
          <Input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            // style={{ width: '100px' }}
          />
        </InputGroup>
        
        <InputGroup >
          <Input
            className="m-l-10"
            type="select"
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Afficher {pageSize}
              </option>
            ))}
          </Input>
          </InputGroup>
      </ButtonToolbar>
       */}
      <ButtonToolbar className="pagination p-t-10 p-l-10 p-r-10 p-b-10">

        <Button 
        className="m-r-5"
          type={'button'}
          color='light'
          onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </Button>
        <Button 
        className="m-r-5"
          type={'button'}
          color='light'
          onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </Button>
        <Button 
        className="m-r-5"
          type={'button'}
          color='light'
          onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </Button>
        <Button 
        className="m-r-5"
          type={'button'}
          color='light'
          onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </Button>
        
        <span style={{padding:'0 10px'}}>
          <strong style={{verticalAlign:'middle'}}>
          Page{' '}{pageIndex + 1} de {pageOptions.length}
          </strong>{'   '}
        </span>{' '}
        {/* <Label>
            pages {pageIndex + 1} de {pageOptions.length}
        </Label> */}
        <InputGroup >
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Aller à la page #</InputGroupText>
          </InputGroupAddon>
          <Input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            // style={{ width: '100px' }}
          />
        </InputGroup>
        
        <InputGroup >
          <Input
            className="m-l-10"
            type="select"
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Afficher {pageSize}
              </option>
            ))}
          </Input>
          </InputGroup>
      </ButtonToolbar>
    </div>
  )
}

function App({columns,data}) {
  const columnsCache = React.useMemo(
    () => columns,[columns]
  )
// const data = [];
//   const data = React.useMemo(() => makeData(20), [])

  return (
      <Table columns={columnsCache} data={data} />
  )
}

export default App
