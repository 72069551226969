import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNeoVentes, getNeoVentes, getNeoVentesAll,getNeoVentesAllXStaus, getNeoVentesGoto, deleteNeoVentes, getOrSetMultiActionStatus } from '../../actions/NeoVentesAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelectvente from "../CustomToolbarSelectvente";
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 
import Toastr from "../../messages";
import  Loader from "../../loader";

class NeoVentesb2bList extends Component {
	constructor(props) {
		super(props); 
		this.state={isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000,
		statusChargeDesk: 0, chargeDeskIdStr:"", invoiceId:0, 
		openPossibleCDStatusInfo:false, possibleCDStatusInfo:{}, possibleListOfCDStatusInfo:[],  
	};
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 
	}
  
	componentDidMount() {
		const { t } = this.props; 
		//this.props.getNeoVentesAll();
		//this.props.getNeoVentesAllXStaus("payed");
		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; //payed
		Promise.resolve(this.props.getNeoVentesAllXStaus("paid-b2b"))
        .then((response)=>{ 
		  this.setState({ ["isLoaderOpen"]: false});
		  this.showToast(t("zqwCViewEl11_x"), "success");  
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          alert(error);
        });
	}

	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info
		const toastrDuration=this.state.toastrDuration; //5500	 
		this.setState({  
			toastrMessage: arg, 
		  toastrType: toastType
		  });
		setTimeout(() => { 
		  this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		  }); 
		}, toastrDuration);
	}
	
	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.NeoVentes;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteNeoVentes(gridIds[0]);

	}

  updateXRecordsMain(selectedRows, indexName){  
    const { t } = this.props;
	const objList  = this.props.NeoVentes;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
    //   gridIndexes.push(row.index); 
    //   idIndex=row.index; 
	//gridIndexes.push(row.index); //current in filter
	gridIndexes.push(row.dataIndex); //global 
	//idIndex=row.index; 
	idIndex=row.dataIndex; 
	return null;
    });
 
    if(gridIndexes.length>1){
      alert(t("zqwCViewEl12_x")); //alert(`{t('zqwgridIndexesfound_x')}`);
      return false;
    }
 
	let obj=objList[idIndex]; 
	const invoiceId=obj.id;
	//this.props.getNeoVentesGoto(obj.id); 
	console.log("oooooooooooooooooooooooh "+obj.id+"   indexName="+indexName);  
	//history.push(`./neoventes/${obj.id}/edit`);
	 
	const objAction={invoiceId:obj.id,  actiongo: indexName, };
	this.setState({ ["isLoaderOpen"]: true});  
	const this2=this;  
	Promise.resolve(this.props.getOrSetMultiActionStatus(objAction))
    .then((response)=>{
      const info=response.NeoVentesOne; //JSON.parse(response); // response.NeoVentesOne; 
      
		//console.log("reeeeeeeeeeeeeeeeeeeeeeeesp info"); console.log(info);  
		this.setState({ ["isLoaderOpen"]: false, ["chargeDeskIdStr"]: info.messageAction, 
		["openPossibleCDStatusInfo"]: true, ["invoiceId"]: invoiceId});
		 this.showToast(t("zqwCViewEl11_x"), "success"); //chargeDeskIdStr 
      return response;
    }).catch(function(error){ 
		this2.setState({ ["isLoaderOpen"]: false});  
		alert(error);
	});
  }
  

  render() { 
    const { t } = this.props;
	const columns0000 = [
	 {
	  name: "id", label: t('zqwid_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "name", label: t('zqwname_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "description", label: t('zqwdescription_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "amount", label: t('zqwamount_x'),
	  options: { filter: true, sort: true,}
	 },
	];

	const columns00 = [
		{
		 name: "id", label: t('zqwid_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "fullName", label: t('zqwid_clients_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "qtyProducts", label: 'Qté Produit',
		 options: { filter: true, sort: true,}
		},
		{
			name: "status", label: t('zqwstatus_x'),
			options: { filter: true, sort: true,}
		},
		{
		 name: "due_date", label: t('zqwdue_date_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "sub_total", label: t('zqwsub_total_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "tax1", label: t('zqwtax1_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "tax2", label: t('zqwtax2_x'),
		 options: { filter: true, sort: true,}
		},
		// {
		//  name: "email", label: t('zqwemail_x'),
		//  options: { filter: true, sort: true,}
		// }, 
		{
		 name: "code", label: t('zqwcode_x'),
		 options: { filter: true, sort: true,}
		},  
		// {
		//  name: "created", label: t('zqwcreated_x'),
		//  options: { filter: true, sort: true,}
		// },
		{
			name: "vendor", label: "Vente",
			options: { filter: true, sort: true,}
		},
		{
		 name: "tax_total", label: t('zqwtax_total_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "total", label: t('zqwtotal_x'),
		 options: { filter: true, sort: true,}
		},
		// {
		//  name: "id_address", label: t('zqwid_address_x'),
		//  options: { filter: true, sort: true,}
		// },
		{
		 name: "province", label: t('zqwprovince_x'),
		 options: { filter: true, sort: true,}
		},
	   ];

	   const columns = [
		{
		 name: "id", label: t('zqwid_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "fullName", label: t('zqwid_clients_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "qtyProducts", label: t('zqwCViewEl13_x'),
		 options: { filter: true, sort: true,}
		},
		{
			name: "status", label: t('zqwstatus_x'),
			options: { filter: true, sort: true,}
		},
		{
		 name: "due_date", label: t('zqwdue_date_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "sub_total", label: t('zqwsub_total_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "tax1", label: t('zqwtax1_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "tax2", label: t('zqwtax2_x'),
		 options: { filter: true, sort: true,}
		},
		// {
		//  name: "email", label: t('zqwemail_x'),
		//  options: { filter: true, sort: true,}
		// }, 
		{
		 name: "code", label: t('zqwcode_x'),
		 options: { filter: true, sort: true,}
		},  
		// {
		//  name: "created", label: t('zqwcreated_x'),
		//  options: { filter: true, sort: true,}
		// },
		{
			name: "vendor", label: t('zqwCViewEl14_x'),
			options: { filter: true, sort: true,}
		},
		{
		 name: "tax_total", label: t('zqwtax_total_x'),
		 options: { filter: true, sort: true,}
		},
		{
		 name: "total", label: t('zqwtotal_x'),
		 options: { filter: true, sort: true,}
		},
		// {
		//  name: "id_address", label: t('zqwid_address_x'),
		//  options: { filter: true, sort: true,}
		// },
		{
		 name: "province", label: t('zqwprovince_x'),
		 options: { filter: true, sort: true,}
		},
	   ];

const textLabelOptions= {
    body: {
      noMatch: t('zqwgridnoMatch_x'),
      toolTip: t('zqwgridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('zqwgridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('zqwgridnext_x'),
      previous: t('zqwgridprevious_x'),
      rowsPerPage: t('zqwgridrowsPerPage_x'),
      displayRows: t('zqwgriddisplayRows_x'),
    },
    toolbar: {
      search: t('zqwgridsearch_x'),
      downloadCsv: t('zqwgriddownloadCsv_x'),
      print: t('zqwgridprint_x'),
      viewColumns: t('zqwgridviewColumns_x'),
      filterTable: t('zqwgridfilterTable_x'),
    },
    filter: {
      all: t('zqwgridall_x'),
      title: t('zqwgridftitle_x'),
      reset: t('zqwgridreset_x'),
    },
    viewColumns: {
      title: t('zqwgridvctitle_x'),
      titleAria: t('zqwgridvctitleAria_x'),
    }, 
  };	
			
	const options = {
	  filterType: 'checkbox',
	  textLabels: textLabelOptions,
	  onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		//console.log(currentRowsSelected, allRowsSelected);
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log(allRowsSelected);
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelectvente selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} 
		isEdit={true} isDelete={true}  t={this.props.t} />
      ),
	}; 
	
	const myToastMessage=this.state.toastrMessage;
	  const toastrType=this.state.toastrType;
	  const CheckOrSetActionsOnInvoice=this.CheckOrSetActionsOnInvoice;
	  
    if(this.props.NeoVentes.length) {                                
      return (
        <div>
          <h4>{t('zqwCViewEl20_x')}</h4>		  
		  <MUIDataTable
			  title={""}
			  data={this.props.NeoVentes}
			  columns={columns}
			  options={options}
			/>
			<Loader  openx={this.state.isLoaderOpen}  />
			<Toastr messagex={myToastMessage} open={true} toastType={toastrType} /> 
			<CheckOrSetActionsOnInvoice  openx={this.state.isLoaderOpen}  />
			
        </div>
      )    
    } else {
      return (<div>{t('zqwgridNoRecordfound_x')} <Loader  openx={this.state.isLoaderOpen}  /></div>)
	}  }
	

	CheckOrSetActionsOnInvoice = (event) => {  
		const open = this.state.openPossibleCDStatusInfo; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
		const { t } = this.props;
		//const { t, i18n } = useTranslation("common");
		const listObj=this.state.possibleListOfCDStatusInfo;
	  
		return (
		  <div>
			<Dialog maxWidth={"sm"} open={open} onClose={this.handleCloseListOfCDStatusInfo} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirmclient4">
			  <DialogContent id="form-dialog-confirmclient4">
				
			  <div className="text-center"><h2>{t("zqwCViewEl16_x")}</h2></div> 
				<p>{t("zqwCViewEl17_x")} # {this.state.invoiceId} </p> 
				<p> &nbsp; </p> 
				<p>{this.state.chargeDeskIdStr} </p> 
				{/* <p> &nbsp; </p> */}
				<div className="form-row neoxtbl">
	
				  <div className="form-group col-md-9">
				  <br/>
				  <input type="button" onClick={this.handleCloseListOfCDStatusInfo} className="btn btn-default" value={t("zqwNVFrmbtn_x")} /> &nbsp; &nbsp;
				   {/* {((paymentRound >0 && parseFloat(this.state.nowPayment)<=0 && this.state.pMethod===1) || (this.state.partialCanClose>0 && this.state.status_invoice!=="payed"))? */}
			  {/* <button type="button" className="btn btn-danger" onClick={this.handleFinalizeCD}>FINALISER LA TRANSACTION</button>  */}
				
			  {/* } */}
				  </div> 
				</div>
				  
			  </DialogContent>
			  
			</Dialog>
		  </div>
		);
	  }

	  handleCloseListOfCDStatusInfo  = (anyx) => {   
		this.setState({["openPossibleCDStatusInfo"]: false }); 
		//this.showToast("Premiere etape, veuillez patieter un instant", "success");   
	  }
}


const mapStateToProps = (state) => ({ NeoVentes: state.NeoVentes });  
const mapDispatchToProps = { addOrUpdateNeoVentes, getNeoVentes, getNeoVentesAll,getNeoVentesAllXStaus, getNeoVentesGoto, deleteNeoVentes, getOrSetMultiActionStatus };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoVentesb2bList));
