import React from "react";
import Select from 'react-select';
import InputMask from 'react-input-mask';
import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Navbar,
    CardHeader,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    Button,
    Form,
    Table,
    Spinner,
    Dropdown
    // Table
} from 'reactstrap';
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    BrowserRouter as Router,
    Link,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";
import {
    faSearch,
    faExternalLink,
    faDownload,
    faEnvelope,
    faPhone,
    faUserCircle,
    faGlobeAmericas,
    faEraser,
    faEdit,
    faLanguage,
    faMale,
    faPlus,
    faInfoCircle,
    faUserPlus,
    faPlusCircle,
    faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import LeadHistoryContact from "./LeadHistoryContact";
import DashboardElement from '../../../../utils/DashboardElement'

import axios from 'axios';
const BACKEND1 = process.env.REACT_APP_API_URLX;

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalFunction: "create",
      firstname: "",
      lastname: "",
      cell: "",
      email: "",
      lead_id: "",
      ticket_id: "",
      conflicts: [],
      searched: false,
      createLeadMsg: false,
      showCreateLead: false,
      showCreateContact: false,
      modalLoading: false,
      searchLoading: false,
      postError: false,
      postErrorMsg: "",
      domaineOptions: [],
      provinceOptions: [],
      languageOptions: [],
      submitted: false,
      showNotesHistory: false,
      searchedNotes: [],
      formData: {
        id: "",
        firstname: "",
        lastname: "",
        cell: "",
        email: "",
        language: "",
        province: "",
        subject: "",
        domaine: "",
      },
    };

    this.searchConflict = this.searchConflict.bind(this);
    this.renderConflict = this.renderConflict.bind(this);
  }

  componentDidMount() {
    axios
      .get(BACKEND1 + "/leadscattoperson/domains", {
        params: {
          //src: "desk",
          showtrans: 3,
        },
        // headers: {
        //     'Content-Type': 'application/json',
        //     // 'x-jwt-token': localStorage.getItem('token'),
        //     'auth-id': localStorage.getItem('user_id'),
        //     'Authorization': "Bearer " + localStorage.getItem('token')
        // }
      })
      .then((res) => {
        const { data } = res.data;
        const provinceOptions = [
          { label: "Québec", value: "QC" },
          { label: "Ontario", value: "ON" },
        ];
        const languageOptions = [
          { label: "Français", value: "fr" },
          { label: "English", value: "en" },
        ];
        const domaineOptions = data.domainList.map((d) => {
          return { label: d.label_fr, value: d.name };
        });
        console.log(domaineOptions, languageOptions, provinceOptions);
        this.setState({
          domaineOptions: domaineOptions,
          provinceOptions: provinceOptions,
          languageOptions: languageOptions,
        });
      });
  }

  handleSelectChange = (e, field) => {
    this.setState((prevState) => ({
      ...prevState,
      formData: { ...prevState.formData, [field]: e.value },
    }));
  };

  handleChange = (e) => {
    e.persist();
    (async () => {
      await this.setState((prevState) => {
        return {
          ...prevState,
          formData: { ...prevState.formData, [e.target.name]: e.target.value },
        };
      });
    })();
  };

  toggleCreateContactFromSearch = (clickedContact) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showCreateContact: true,
        formData: {
          ...prevState.formData,
          firstname: clickedContact.firstname,
          lastname: clickedContact.lastname,
          cell: clickedContact.cell,
          email: clickedContact.email,
          language: clickedContact.language,
          province: clickedContact.province,
        },
      };
    });
  };

  toggleEditContact = (clickedContact) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        modalFunction: "edit",
        showCreateContact: true,
        formData: {
          ...prevState.formData,
          firstname: clickedContact.firstname,
          lastname: clickedContact.lastname,
          cell: clickedContact.cell,
          email: clickedContact.email,
          language: clickedContact.language,
          id: clickedContact.id,
          province: clickedContact.province,
        },
      };
    });
  };

  toggleCreateContact = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        modalFunction: "create",
        showCreateContact: true,
        formData: {
          ...prevState.formData,
          firstname: prevState.firstname,
          lastname: prevState.lastname,
          cell: prevState.cell,
          email: prevState.email,
          province: "QC",
        },
      };
    });
  };

  toggleCreateLead = (clickedContact) => {
    console.log(clickedContact);
    this.setState((prevState) => {
      return {
        ...prevState,
        showCreateLead: true,
        formData: {
          ...prevState.formData,
          firstname: clickedContact.firstname,
          lastname: clickedContact.lastname,
          cell: clickedContact.cell,
          email: clickedContact.email,
          id: clickedContact.id,
          language: clickedContact.language,
          province: clickedContact.province,
        },
      };
    });
  };

  closeModal = (modal) => {
    this.setState({
      [modal]: false,
      submitted: false,
      postError: false,
      postErrorMsg: "",
      modalLoading: false,
      formData: {
        id: "",
        firstname: "",
        lastname: "",
        cell: "",
        email: "",
        language: "",
        province: "",
        subject: "",
        domaine: "",
      },
    });
  };

  closeNotesHistory = () => {
    this.setState({ showNotesHistory: false, searchedNotes: [] });
  };

  openNotesHistory = async (id) => {
    this.setState({ showNotesHistory: true });
    const { data } = await axios.get(BACKEND1 + "/leads", {
      params: {
        // src: "desk",
        id: id,
        leadreadonly: 3,
      },
      //   headers: {
      //     "Content-Type": "application/json",
      //     // 'x-jwt-token': localStorage.getItem('token'),
      //     "auth-id": localStorage.getItem("user_id"),
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
    });
    console.log(data.data);
    this.setState({ searchedNotes: data.data.notes });
  };

  handleCreateOrEditContact = () => {
    const { firstname, lastname, cell, language, province, domaine, subject } =
      this.state.formData;
    var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    this.setState({ submitted: true });
    let isValid = false;
    if (this.state.modalFunction === "edit") {
      if (firstname && phoneRegex.test(cell) === true) isValid = true;
    }
    if (this.state.modalFunction === "create") {
      if (firstname && phoneRegex.test(cell) === true) isValid = true;
    }
    // console.log(isValid)
    if (!isValid)
      this.setState({ postErrorMsg: this.genLang("validationError") });
    if (isValid) {
      this.setState({ modalLoading: true });
      const id =
        this.state.modalFunction === "create" ? "0" : this.state.formData.id;
      const obj = {
        id: id,
        firstname: this.state.formData.firstname,
        cell: this.state.formData.cell,
      };
      if (this.state.formData.email) obj.email = this.state.formData.email;
      if (this.state.formData.lastname)
        obj.lastname = this.state.formData.lastname;
      if (this.state.formData.domaine) obj.domain = this.state.formData.domaine;
      if (this.state.formData.subject)
        obj.subject = this.state.formData.subject;
      if (this.state.formData.language)
        obj.language = this.state.formData.language;
      if (this.state.formData.province)
        obj.province = this.state.formData.province;
      console.log(obj);
      const this2 = this;
      axios
        .post(BACKEND1 + "/contacts", obj, {
          params: {
            //src:"desk"
          },
          // headers: {
          //     'Content-Type': 'application/json',
          //     // 'x-jwt-token': localStorage.getItem('token'),
          //     'auth-id': localStorage.getItem('user_id'),
          //     'Authorization':"Bearer "+localStorage.getItem('token')
          // }
        })
        .then((res) => {
          if (res.data.statusCode === 200) {
            console.log(res.data);
            if (this.state.modalFunction === "edit") {
              console.log(res.data.data.record);
              const updatedConflicts = this.state.conflicts.map((i) => {
                if (i.id === res.data.data.record.id)
                  return { ...res.data.data.record, display: "block" };
                return { ...i };
              });
              this.setState({ conflicts: updatedConflicts });
              this.closeModal("showCreateContact");
            }
            if (this.state.modalFunction === "create")
              this.setState({ redirect: `/leads/${res.data.data.lead.id}` });
          }
        })
        .catch(function (error) {
          console.log("error object:", error.response.data);
          this2.setState({
            postErrorMsg: error.response.data,
            modalLoading: false,
          });
        });
    }
  };

  handleCreateLead = () => {
    const this2 = this;
    this.setState({ submitted: true });
    const { domaine, subject } = this.state.formData;
    const isValid = subject && domaine ? true : false;
    if (!isValid) this.setState({ postError: true });
    if (isValid) {
      this.setState({ postError: false });
      console.log("POST");
      axios
        .get(BACKEND1 + "/contacts", {
          params: {
            //src:"desk",
            lead: "1",
            id: this.state.formData.id,
            subject: this.state.formData.subject,
            domain: this.state.formData.domaine,
          },
          // headers: {
          //     'Content-Type': 'application/json',
          //     // 'x-jwt-token': localStorage.getItem('token'),
          //     'auth-id': localStorage.getItem('user_id'),
          //     'Authorization':"Bearer "+localStorage.getItem('token')
          // }
        })
        .then((res) => {
          if (res.data.statusCode === 200) {
            console.log(res.data.data);
            this.setState({ redirect: `/leads/${res.data.data.leads[0].id}` });
          }
        })
        .catch(function (error) {
          console.log("error object:", error.response.data);
          this2.setState({
            postErrorMsg: error.response.data,
            modalLoading: false,
          });
        });
    }
  };

  renderContactInfo = () => {
    const { firstname, lastname, cell, email, province, language } =
      this.state.formData;
    const lang =
      language === "fr" ? this.genLang("french") : this.genLang("english");
    return (
      <div>
        <div>
          <FontAwesomeIcon icon={faUserCircle} color={"#A9A9A9"} />
          {` ${firstname} ${lastname}`}
        </div>
        <div style={{ marginTop: "0.3rem" }}>
          <FontAwesomeIcon icon={faPhone} color={"#A9A9A9"} />
          {` ${cell}`}
        </div>
        <div style={{ marginTop: "0.3rem" }}>
          <FontAwesomeIcon icon={faEnvelope} color={"#A9A9A9"} />
          {` ${email}`}
        </div>
        <div style={{ marginTop: "0.3rem" }}>
          <FontAwesomeIcon icon={faLanguage} color={"#A9A9A9"} />
          {` ${lang}`}
        </div>
        <div style={{ marginTop: "0.3rem" }}>
          <FontAwesomeIcon icon={faGlobeAmericas} color={"#A9A9A9"} />
          {` ${!province ? "" : province}`}
        </div>
      </div>
    );
  };

  renderLeads = (searchedContact) => {
    const columns = [
      { name: "Lead #", value: "id" },
      { name: this.genLang("subject"), value: "subject" },
      { name: "Agent", value: "agent" },
      { name: this.genLang("status"), value: "tableStatus" },
      { name: this.genLang("domain"), value: "domain" },
      { name: "Province", value: "province" },
      { name: this.genLang("language"), value: "language" },
      { name: this.genLang("created"), value: "created" },
      { name: this.genLang("leadHistoryNotes"), value: "notes" },
    ];
    const handleStatus = (status = "") => {
      switch (status) {
        case "0":
          return this.genLang("automaticDistrubution");
        case "1":
          return this.genLang("attributed");
        case "2":
          return this.genLang("supported");
        case "3":
          return this.genLang("closed");
        case "4":
          return this.genLang("notAutomaticDistribution");
        case "5":
          return this.genLang("closed");
      }
    };
    if (!searchedContact.leads) return "";
    else if (searchedContact.leads.length === 0)
      return (
        <div style={{ marginTop: "0.5rem" }}>{this.genLang("noLeads")}</div>
      );
    else
      return (
        <Table style={{ marginTop: "0.5rem" }} hover={true} size="sm">
          <thead>
            <tr>
              {columns.map((i) => {
                return <th key={i.value}>{i.name}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {searchedContact.leads.map((i) => {
              const { language, province, domain, ticket_subject } =
                i.definition;
              i.language = language;
              i.province = province;
              i.domain = domain;
              i.subject = ticket_subject;
              i.tableStatus = handleStatus(i.status);
              i.notes = this.genLang("leadHistoryNotes");
              return (
                <tr key={i.id}>
                  {columns.map((x) => {
                    if (x.value === "id") {
                      return (
                        <td style={{ width: "250px" }} key={x.value}>
                          <Link to={"/leads/" + i.id}>{i[x.value]}</Link>
                        </td>
                      );
                    } else if (x.value === "notes") {
                      return (
                        <td
                          onClick={() => this.openNotesHistory(i.id)}
                          style={{ width: "250px", cursor: "pointer" }}
                          key={x.value}
                        >
                          {`${i[x.value]} `}
                          <FontAwesomeIcon icon={faExternalLink} />
                        </td>
                      );
                    } else {
                      return (
                        <td style={{ width: "250px" }} key={x.value}>
                          {i[x.value]}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
  };

  renderConflict({ conflicts = [], conflictsZendesk = [] }) {
    if (!this.state.conflicts.length) return "";
    const data = this.state.conflicts.map((j, i) => {
      return { ...j, index: i };
    });
    let arr = _.map(data, (e, i) => {
      return (
        <Card onClick={() => console.log(e)} key={i} className="m-t-15">
          <CardHeader
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState((state) => {
                let found = _.findIndex(data, (f) => {
                  return f.index === e.index;
                });

                if (found >= 0) {
                  state.conflicts[found].display =
                    !state.conflicts[found].display ||
                    state.conflicts[found].display === "none"
                      ? "block"
                      : "none";
                }
                return state;
              });
            }}
          >
            <h6 style={{ textAlign: "center" }}>
              {e.firstname} {e.lastname}
            </h6>
          </CardHeader>
          <CardBody style={{ display: e.display || "none" }}>
            <b style={{ color: "#0052bb" }}>
              <FontAwesomeIcon
                style={{
                  display: "inline",
                  alignItems: "baseline",
                  fontSize: "10px",
                }}
                icon={faEnvelope}
                color={"#ccc"}
              />{" "}
              {e.email}
            </b>
            <br />
            <b style={{ color: "#0052bb" }}>
              <FontAwesomeIcon
                style={{
                  display: "inline",
                  alignItems: "baseline",
                  fontSize: "10px",
                }}
                icon={faMale}
                color={"#ccc"}
              />{" "}
              {e.cell}
            </b>
            <br />
            {this.renderLeads(e)}
            {e.id === "0" ? (
              <Button
                color="primary"
                style={{ marginTop: "0.5rem" }}
                outline
                onClick={() => this.toggleCreateContactFromSearch(e)}
              >
                {this.genLang("createContactWithClient")}
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            ) : (
              <div>
                <Button
                  color="primary"
                  style={{ marginTop: "0.5rem" }}
                  outline
                  type="button"
                  onClick={() => {
                    axios
                      .get(BACKEND1 + "/contacts", {
                        params: {
                          //src: "desk",
                          id: e.id,
                          lead: 1,
                        },
                        // headers: {
                        //   "Content-Type": "application/json",
                        //   "auth-id": localStorage.getItem("user_id"),
                        //   Authorization:
                        //     "Bearer " + localStorage.getItem("token"),
                        // },
                      })
                      .then((status) => {
                        let { data } = status.data;
                        if (data) {
                          if (data.leads) {
                            this.setState((state) => {
                              let found = _.findIndex(state.conflicts, (f) => {
                                return f.id === e.id;
                              });
                              if (found >= 0) {
                                state.conflicts[found].leads = data.leads;
                              }
                              return state;
                            });
                          }
                        }
                      });
                  }}
                >
                  {this.genLang("getLeads")}{" "}
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
                <Button
                  style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}
                  onClick={() => this.toggleCreateLead(e)}
                  color="primary"
                  outline
                >
                  {this.genLang("createLead")} <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button
                  style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}
                  onClick={() => this.toggleEditContact(e)}
                  color="primary"
                  outline
                >
                  {this.genLang("editContact") + " "}
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </div>
            )}
          </CardBody>
        </Card>
      );
    });
    return (
      <div key={"container"}>
        <h6 className="element-header" style={{ textAlign: "center" }}>
          {this.genLang("existingContacts")}
        </h6>
        {arr}
      </div>
    );
  }

  searchConflict() {
    const { firstname, lastname, cell, email, lead_id, ticket_id } = this.state;
    if (firstname || lastname || cell || email || lead_id || ticket_id) {
      this.setState({ createLeadMsg: false, searchLoading: true });
      axios
        .get(BACKEND1 + "/contacts/search", {
          params: {
            //src:"desk",
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            cell: this.state.cell,
            email: this.state.email,
            lead_id: this.state.lead_id,
            ticket_id: this.state.ticket_id
          },
          // headers: {
          //     'Content-Type': 'application/json',
          //     // 'x-jwt-token': localStorage.getItem('token'),
          //     'auth-id': localStorage.getItem('user_id'),
          //     'Authorization':"Bearer "+localStorage.getItem('token')
          // }
        })
        .then((status) => {
          let { data } = status.data;
          if (data) {
            if (!data.contacts.length) this.setState({ createLeadMsg: true });
            let arr = [];
            for (let n in data.contacts) {
              // console.log('je n\'ai plus d\'énergie',data[n])
              if (data.contacts[n]) arr.push(data.contacts[n]);
            }
            // let arr2 = [];
            // // debugger
            // for(let z in data.zendesk){
            //     // console.log('je n\'ai plus d\'énergie',data[n])
            //     if(data.zendesk[z])
            //         arr2.push(data.zendesk[z])
            // }
            this.setState({
              conflicts: data.contacts,
              //   conflictsZendesk:data.zendesk,
              searched: true,
              searchLoading: false,
            });
          } else if (!data) {
            this.setState({ searchLoading: false });
          }
        });
    }
  }

  genLang = (text) => {
    const { trad, lang } = this.props;
    return trad["neoleads"][text][lang];
  };

  render() {
    let state = this.state;
    const inValidSelectStyle = {
      control: (provided, state) => ({
        ...provided,
        borderColor: "#dc3545",
        focusBorderColor: "#dc3545",
        focusBoxShadow: "0 0 0 .2rem rgba(220, 53, 69, .25)",
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: "#dc3545",
        focusBorderColor: "#dc3545",
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        color: "#dc3545",
        focusBorderColor: "#dc3545",
      }),
    };

    var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
          }}
        />
      );
    }
    return (
      <div>
        <h4 style={{ marginTop: "1rem" }}>{this.genLang("contactCreation")}</h4>
        <div className="contactCreation">
          {/* ajouter abunai pour fond rouge */}
          <Form className="contactForm">
            <Row>
              <Col md={4}></Col>
              <Col md={4}></Col>
              <Col md={4}>
                <Button
                  outline
                  color="primary"
                  style={{ float: "right" }}
                  onClick={() => this.toggleCreateContact()}
                >
                  {`${this.genLang("createContact")} `}
                  <FontAwesomeIcon icon={faUserPlus} />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>{this.genLang("firstName")}</Label>
                  <Input
                    type="text"
                    value={this.state.firstname}
                    onChange={(e) =>
                      this.setState({ firstname: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>{this.genLang("lastName")}</Label>
                  <Input
                    type="text"
                    value={this.state.lastname}
                    onChange={(e) =>
                      this.setState({ lastname: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>{this.genLang("phone")}</Label>
                  <Input
                    type="text"
                    value={this.state.cell}
                    onChange={(e) => this.setState({ cell: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>{this.genLang("email")}</Label>
                  <Input
                    type="text"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>{"Ticket #"}</Label>
                  <Input
                    type="text"
                    value={this.state.ticket_id}
                    onChange={(e) => this.setState({ ticket_id: e.target.value })}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>{"Lead ID"}</Label>
                  <Input
                    type="text"
                    value={this.state.lead_id}
                    onChange={(e) => this.setState({ lead_id: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* <div className="conflict">
                            <div className="conflictIcon">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                            </div>
                            <div className="conflictText">
                                Conflit d'intérêt potentiel<br></br>
                                (Basé sur le nom ou courriel)
                            </div>
                        </div> */}
            {/* <div style={{textAlign: 'center'}}> */}
            <Button
              outline
              color="primary"
              type="button"
              style={{ width: "200px" }}
              onClick={(e) => {
                this.searchConflict();
              }}
            >
              {this.state.searchLoading ? (
                <div>
                  {`${this.genLang("loading")}`}
                  <Spinner color="primary" size="sm" />
                </div>
              ) : (
                <div>
                  {`${this.genLang("search")}  `}{" "}
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              )}
            </Button>
            <Button
              color="primary"
              outline
              style={{ marginLeft: "0.5rem", width: "150px" }}
              onClick={() =>
                this.setState({
                  firstname: "",
                  lastname: "",
                  cell: "",
                  email: "",
                  lead_id: "",
                  ticket_id: "",
                  conflicts: [],
                  createLeadMsg: false,
                })
              }
            >
              {`${this.genLang("erase")} `} <FontAwesomeIcon icon={faEraser} />
            </Button>
            {/* </div>  */}
          </Form>
          <div>
            {this.renderConflict({
              conflicts: this.state.conflicts,
              conflictsZendesk: this.state.conflictsZendesk,
            })}
            {this.state.createLeadMsg && (
              <h6
                className="element-header"
                style={{ textAlign: "center", marginTop: "1rem" }}
              >
                {this.genLang("noContactResults")}{" "}
                <div style={{ marginTop: "1rem" }}>
                  <Button
                    outline
                    color="primary"
                    onClick={() => this.toggleCreateContact()}
                  >
                    {this.genLang("createContact")}
                    <FontAwesomeIcon icon={faUserPlus} />
                  </Button>
                </div>
              </h6>
            )}
          </div>
        </div>
        {this.state.showCreateContact && (
          <Modal centered={true} isOpen={this.state.showCreateContact}>
            <ModalHeader
              onClick={() => console.log(this.state)}
              toggle={() => this.closeModal("showCreateContact")}
            >
              {this.state.modalFunction === "create"
                ? this.genLang("createContact")
                : this.genLang("editContact")}
            </ModalHeader>

            <ModalBody>
              {this.state.postErrorMsg && this.state.submitted && (
                <Alert color="danger">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  {` ${this.state.postErrorMsg}`}
                </Alert>
              )}

              <Label>
                {this.genLang("firstName")}
                {" *"}
              </Label>
              <Input
                name="firstname"
                value={this.state.formData.firstname}
                invalid={this.state.submitted && !this.state.formData.firstname}
                type="text"
                onChange={(e) => this.handleChange(e)}
              />

              <Label style={{ marginTop: "0.5rem" }}>
                {this.genLang("lastName")}
              </Label>
              <Input
                name="lastname"
                value={this.state.formData.lastname}
                type="text"
                onChange={(e) => this.handleChange(e)}
              />

              <Label style={{ marginTop: "0.5rem" }}>
                {this.genLang("phone")}
                {" *"}
              </Label>
              <InputMask
                mask="(999) 999-9999"
                name="cell"
                value={this.state.formData.cell}
                onChange={(e) => {
                  this.handleChange(e);
                  console.log(phoneRegex.test(this.state.formData.cell));
                }}
                className={`${
                  this.state.submitted &&
                  phoneRegex.test(this.state.formData.cell) === false
                    ? "form-control is-invalid"
                    : "form-control"
                }`}
              />

              <Label style={{ marginTop: "0.5rem" }}>
                {this.genLang("email")}
              </Label>
              <Input
                name="email"
                value={this.state.formData.email}
                type="text"
                onChange={(e) => this.handleChange(e)}
              />

              {this.state.modalFunction === "create" && (
                <>
                  <Label style={{ marginTop: "0.5rem" }}>
                    {this.genLang("subject")}
                  </Label>
                  <Input
                    name="subject"
                    value={this.state.formData.subject}
                    type="text"
                    onChange={(e) => this.handleChange(e)}
                  />
                </>
              )}

              {this.state.modalFunction === "create" && (
                <>
                  <Label style={{ marginTop: "0.5rem" }}>
                    {this.genLang("domain")}
                  </Label>
                  <Select
                    options={this.state.domaineOptions}
                    onChange={(e) => this.handleSelectChange(e, "domaine")}
                  />
                </>
              )}

              <Label style={{ marginTop: "0.5rem" }}>Province</Label>
              <Select
                options={this.state.provinceOptions}
                value={this.state.provinceOptions.find(
                  (i) => i.value === this.state.formData.province
                )}
                onChange={(e) => this.handleSelectChange(e, "province")}
              />

              <Label style={{ marginTop: "0.5rem" }}>
                {this.genLang("language")}
              </Label>
              <Select
                options={this.state.languageOptions}
                value={this.state.languageOptions.find(
                  (i) => i.value === this.state.formData.language
                )}
                onChange={(e) => this.handleSelectChange(e, "language")}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => this.closeModal("showCreateContact")}
                outline
                color="danger"
              >
                {this.genLang("cancel")}
              </Button>
              <Button
                onClick={this.handleCreateOrEditContact}
                outline
                color="primary"
              >
                {this.state.modalLoading ? (
                  <div>
                    {this.genLang("loading")}
                    <Spinner color="primary" size="sm" />
                  </div>
                ) : (
                  <div>{this.genLang("register")}</div>
                )}
              </Button>
            </ModalFooter>
          </Modal>
        )}

        {this.state.showCreateLead && (
          <Modal centered={true} isOpen={this.state.showCreateLead}>
            <ModalHeader
              onClick={() => console.log(this.state)}
              toggle={() => this.closeModal("showCreateLead")}
            >
              {this.genLang("createLead")}
            </ModalHeader>

            <ModalBody>
              {this.state.postErrorMsg && this.state.submitted && (
                <Alert color="danger">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  {` ${this.state.postErrorMsg}`}
                </Alert>
              )}

              {this.renderContactInfo()}
              <Label style={{ marginTop: "0.5rem" }}>
                {this.genLang("subject")}
              </Label>
              <Input
                name="subject"
                value={this.state.formData.subject}
                type="text"
                onChange={(e) => this.handleChange(e)}
                invalid={this.state.submitted && !this.state.formData.subject}
              />

              <Label style={{ marginTop: "0.5rem" }}>
                {this.genLang("domain")}
              </Label>
              <Select
                options={this.state.domaineOptions}
                styles={
                  this.state.submitted && !this.state.formData.domaine
                    ? inValidSelectStyle
                    : {}
                }
                onChange={(e) => this.handleSelectChange(e, "domaine")}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => this.closeModal("showCreateLead")}
                outline
                color="danger"
              >
                {this.genLang("cancel")}
              </Button>
              <Button onClick={this.handleCreateLead} outline color="primary">
                {this.genLang("register")}
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <LeadHistoryContact
          isOpen={this.state.showNotesHistory}
          notes={this.state.searchedNotes}
          close={this.closeNotesHistory}
          trad={this.props.trad}
          lang={this.props.lang}
          genLang={this.genLang}
        />
      </div>
    );
  }
}