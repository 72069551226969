import React from "react";
import ReactDOM from "react-dom";

//Change to react dom.render
export default class ShadowView extends React.Component {
  attachShadow = (host) => {
    const { children } = this.props;
    const shadowRoot = host.attachShadow({ mode: "open" });
    ReactDOM.render(children, shadowRoot);
  }
  render() {
    return <div ref={this.attachShadow}></div>;
  }
}

/*import React from "react";
import ReactDOM from "react-dom";

//The implementation of using react dom. Create portal
export function ShadowContent({ root, children }) {
  return ReactDOM.createPortal(children, root);
}

export default class ShadowView extends React.Component {
  state = { root: null };
  setRoot = eleemnt => {
    const root = eleemnt.attachShadow({ mode: "open" });
    this.setState({ root });
  };
  render() {
    const { children } = this.props;
    const { root } = this.state;
    return <div ref={this.setRoot}>
      {root && <ShadowContent root={root} >
        {children}
      </ShadowContent>}
    </div>;
  }
}*/


