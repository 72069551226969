import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getZonlineUsuarios, deleteZonlineUsuarios } from '../../actions/ZonlineUsuariosAction';

class ZonlineUsuariosInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
		id_usuario:0,
		fecha:'', e_fecha: false,
		nombre:'', e_nombre: false,
		apellido:'', e_apellido: false,
		correo:'', e_correo: false,
		usuario:'', e_usuario: false,
		contrasena:'', e_contrasena: false,
		id_rol_usuario:'', e_id_rol_usuario: false,
		habilitado:'', e_habilitado: false,
		logintoken:'', e_logintoken: false,
		logintime:'', e_logintime: false,
		zona:'', e_zona: false,
		funcion:'', e_funcion: false,
		sucursales:'', e_sucursales: false,
    }
  }
  componentDidMount() {                                                         
    //this.props.getZonlineUsuarios(this.props.match.params.id);
	const idx = this.props.match.params.id;  
	Promise.resolve(this.props.getZonlineUsuarios(idx))
	.then((response)=>{
		//console.log("0000********INI**********"); console.log(response.ZonlineUsuariosOne);  console.log("0000********FIN**********");
		this.setState(response.ZonlineUsuariosOne); 
		return response;
	});
  }

  render() {
    const { t } = this.props;
    //const ZonlineUsuarios = this.props.ZonlineUsuarios;
    const ZonlineUsuarios = this.state; 
    return (
      <div>
        <h2>ZonlineUsuarios</h2>
        <hr/>
        <p><label>{t('id_usuario_x')}</label> &nbsp; &nbsp; #{ZonlineUsuarios.id_usuario}</p>
        <p><label>{t('fecha_x')}</label><br/>{ZonlineUsuarios.fecha}</p>
        <p><label>{t('nombre_x')}</label><br/>{ZonlineUsuarios.nombre}</p>
        <p><label>{t('apellido_x')}</label><br/>{ZonlineUsuarios.apellido}</p>
        <p><label>{t('correo_x')}</label><br/>{ZonlineUsuarios.correo}</p>
        <p><label>{t('usuario_x')}</label><br/>{ZonlineUsuarios.usuario}</p>
        <p><label>{t('contrasena_x')}</label><br/>{ZonlineUsuarios.contrasena}</p>
        <p><label>{t('id_rol_usuario_x')}</label><br/>{ZonlineUsuarios.id_rol_usuario}</p>
        <p><label>{t('habilitado_x')}</label><br/>{ZonlineUsuarios.habilitado}</p>
        <p><label>{t('logintoken_x')}</label><br/>{ZonlineUsuarios.logintoken}</p>
        <p><label>{t('logintime_x')}</label><br/>{ZonlineUsuarios.logintime}</p>
        <p><label>{t('zona_x')}</label><br/>{ZonlineUsuarios.zona}</p>
        <p><label>{t('funcion_x')}</label><br/>{ZonlineUsuarios.funcion}</p>
        <p><label>{t('sucursales_x')}</label><br/>{ZonlineUsuarios.sucursales}</p>
        <hr/>
        <div className="btn-group">
          <Link to={{ pathname: `/ZonlineUsuarios/${ZonlineUsuarios.id}/edit`, state: { ZonlineUsuarios: ZonlineUsuarios } }} className='btn btn-info'>  
            {t('Edit')}
          </Link>
          <button className="btn btn-danger" type="button" onClick={() => this.props.deleteZonlineUsuarios(ZonlineUsuarios.id_usuario)}>          
            {t('Delete')}
          </button>
          <Link to="./ZonlineUsuarios" className="btn btn-secondary">{t('Close')}</Link>                                                 
        </div>
        <hr/>
      </div>
    )
  }
}

//const TransZonlineUsuariosInfo = withTranslation()(LegacyComponentClass);
const mapStateToProps = (state) => ({ ZonlineUsuarios: state.ZonlineUsuarios });                 

const mapDispatchToProps = { getZonlineUsuarios, deleteZonlineUsuarios };                        

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(ZonlineUsuariosInfo));   

