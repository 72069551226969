import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateNeoB2bleadProspects, getNeoB2bleadProspects, getNeoB2bleadExistedProspects } from '../../actions/NeoB2bleadProspectsAction'; 
import { addOrUpdateNeoVentes, getNeoVentes, getClientSearch, getB2BClientSearch, getClientAddress, getCodePromo, getNeoVentesPromos, getProductInfo, getPartialInvoicePayments, getAllProducts, getChargeDeskStatus, getClientTransactionsFullInfo, getClientAndAddress } from '../../actions/NeoVentesAction'; 
import { Validations } from '../../utils/validations'; 

import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 
import Toastr from "../../messages";
import  Loader from "../../loader";
import DatePicker from "react-datepicker";
import moment from 'moment';

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['firstname']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['lastname']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['cell']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['email']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['gender']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['language']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['dob']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['source']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['current_insurances']={check:false, chkType:'String', message:'incorrect value'};
//FIELD_VALIDATION['sent']={check:false, chkType:'boolean', message:'incorrect value'};
FIELD_VALIDATION['id_invoices']={check:true, chkType:'int', message:'incorrect value'};
//FIELD_VALIDATION['user_creation']={check:false, chkType:'int', message:'incorrect value'};
//FIELD_VALIDATION['created']={check:false, chkType:'Date', message:'incorrect value'};

class NeoB2bleadProsInnovaForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
	//stateInit this.state = {
    this.stateInit = { 
		isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000,
		id:0,
		firstname:'', e_firstname: false,
		lastname:'', e_lastname: false,
		cell:'', e_cell: false,
		email:'', e_email: false,
		gender:'', e_gender: false,
		language:'', e_language: false,
		dob:'', e_dob: false,
		source:'', e_source: false,
		current_insurances:'', e_current_insurances: false,
		sent:'', e_sent: false,
		id_invoices:'0', e_id_invoices: false,
		user_creation:'', e_user_creation: false, 
		age:'', e_age: false,
		send_date:'', e_send_date: false,
		send_date2:'',
		observation:'', e_observation: false,
		created:'', e_created: false,
		rndidpros:'', openTransactionPopup: false, 
    }

	this.state = JSON.parse(JSON.stringify(this.stateInit)); // Object.assign(this.stateInit);  
	
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
	this.navigationTopRefOnTopx=React.createRef();

  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	if(this.actionMode==='edit'){ 
		console.log('edit  test  '+this.props.NeoB2bleadProspects.id);
		if(this.props.NeoB2bleadProspects.id===undefined){ 
			//means some refresh happens  id
			const idx = this.props.match.params.id; 
			//getNeoB2bleadProspects(idx);
			Promise.resolve(this.props.getNeoB2bleadProspects(idx))
			.then((response)=>{
				this.setState(response.NeoB2bleadProspectsOne);
				return response;
			});
		}	
	}
	
	if(this.props.frminput.rndidpros !== undefined ){
		this.setState(this.props.frminput);
		//this.setState({ [event.target.name]: onOffval }); 
		this.navigationTopRefOnTopx.current.focus();
		console.log("componentDidMount rndidpros= "+this.props.frminput.rndidpros+" firstname="+this.props.frminput.firstname+" lang="+this.props.frminput.language, " invoiceId ", this.props.invoiceId);   
	}
	if(this.props.invoiceId !== undefined && this.props.invoiceId>0){
		console.log("componentDidMount  PUUUUUUUUUUUUT ACTION-HEEEEEEEEEERE invoiceId ", this.props.invoiceId);  
		console.log("componentDidMount ticket_id "+this.props.ticket_id+" client_id "+this.props.client_id, " invoiceId ", this.props.invoiceId);  

		const client_id=this.props.client_id;
		const ticket_id=this.props.ticket_id;
		const invoiceId=this.props.invoiceId; 
		const frminput={rndidpros:undefined };
	
		console.log("000000NeoB2bleadProspectsFormx ", invoiceId, client_id, ticket_id);
		if(invoiceId >0){
			this.setState({ ["id_invoices"]: invoiceId});

			const this2=this; 
			this.setState({ ["isLoaderOpen"]: true});   
			Promise.resolve(this.props.getNeoB2bleadExistedProspects(invoiceId, "4"))
			.then((response)=>{ 
				//productsList=response.NeoVentes;
				const obj=response.NeoB2bleadProspectsOne; 
				//obj.id=0;
				if(obj!==undefined && obj.id!==undefined && obj.existedRecInvoiceCny===undefined){
					obj.id=0;
				}
				//this.setState(obj); 
				if(obj!==undefined && obj.id!==undefined){
					this.setState(obj); 
				}
				console.log("getNeoB2bleadExistedProspects");
				console.log(obj);
				console.log(response);
				//this.setState({ ["clientInfo"]: newValue}); 
				//this.setState(clientInfo ); //xaza  this.setState({ clientInfo });
				//this.setState( clientAddress ); //this.setState({ clientAddress }); 
				this.setState({["isLoaderOpen"]: false }); 		
	
			return response;
			}).catch(function(error){ 
				// this2.setState({ ["isLoaderOpen"]: false});   
				// this2.showToast(error, "error");  
				const errObj=error;
				this2.setState({ ["isLoaderOpen"]: false});  
				///let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
				let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
				//let errMessage="Error/Erreur ";
				const statusCode=errObj.statusCode;
				if(errObj.status===undefined && statusCode===undefined){
					errMessage +="\n There was some error from the expected data from the server. Please try again later.";
					//this2.saveUpdateTicketDataToZendesks(zendeskList); 
					if (typeof errObj === 'string' || errObj instanceof String){
						console.log("inside undefined");  
						this2.showToast(errObj, "error");  
					}else{
						console.log("inside else undefined");  
						this2.showToast(errMessage, "error");  
					} 
					//this2.showToast(errMessage, "error");  
					console.log("FAKE ERRORS SOLVED MINIMIZED");  
					console.log(errObj);
				}
				else if(statusCode===500){   //else if(errObj.status===500){
					//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
					//errMessage +="\n 9999999 ".errObj.data.error.description;
					//errMessage +="\n 9999999 ".errObj.error.description;
					let mesx=errObj.error.description;
					if(mesx===undefined){ mesx=""; }
					errMessage ="SERVER ERROR INTERNAL  "+mesx;
					this2.showToast(errMessage, "error");  
					console.log("500 ERROR");  
					console.log(errObj);  
				}else{
					//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
					//this00.showToastError(errMessage);
					//alert(errMessage); //error
					this2.showToast(errMessage, "error");
					console.log("UNDEFINED ERROR");  
					console.log(errObj);  
				}
			});
		}
	}

	if(this.props.frminputGetDataFrmSrv !== undefined ){
		// frminputGetDataFrmSrv={777} 
		//   client_id={client_id} ticket_id={ticket_id} invoiceId={invoiceId}
		/*
		this.setState(this.props.frminput);
		//this.setState({ [event.target.name]: onOffval }); 
		this.navigationTopRefOnTopx.current.focus();
		console.log("componentDidMount rndidpros= "+this.props.frminput.rndidpros+" firstname="+this.props.frminput.firstname+" lang="+this.props.frminput.language);  
		*/

		const client_id=this.props.client_id;
		const ticket_id=this.props.ticket_id;
		const invoiceId=this.props.invoiceId; 
		//const frminput={rndidpros:undefined };
	
		console.log("000000NeoB2bleadProspectsFormx ", invoiceId, client_id, ticket_id);
		if(invoiceId >0){
			this.setState({ ["id_invoices"]: invoiceId});

			
		}

		if(client_id >0){
			const this2=this; 
			this.setState({ ["isLoaderOpen"]: true});  
         
			// this.workflowProductList=false; this.workflowClientInfo=false; 
			// this.setState({ ["globalStepWorkflow"]: 4 });
			// this.setState(payInfoCli.client);
			// this.getClientFullAddress(id_clients);
			Promise.resolve(this.props.getClientAndAddress(client_id))
			.then((response)=>{ 
				//productsList=response.NeoVentes;
				const obj=response.NeoAddressOne; 
				console.log("getClientAndAddress");
				console.log(obj);
	
				//this.setState({ ["clientInfo"]: newValue}); 
				const newValue=obj.client; 
				const address=obj.address;  
				const clientInfo={  
				  id_clients:newValue.id, 
				  idClient:newValue.id, 
				  firstname:newValue.firstname, 
				  lastname:newValue.lastname, 
				  cell:newValue.cell, 
				  terms:newValue.terms, 
				  active:newValue.active,  
				  email:newValue.email, 
				  id_users:parseInt(newValue.id_users), 
				  language:newValue.language, 
				  push:newValue.push, 
				  id_zendesk:newValue.id_zendesk, 
				  sms:newValue.sms,
				  stripe_id:newValue.stripe_id,  
				  occupation:newValue.occupation, 
		
				};
				const clientAddress={
				  idAddress:address.id, 
				  address:address.address, 
				  city:address.city, 
				  province:address.province, 
				  cp:address.cp, 
				  country:address.country, 
				  company:address.company,  
				}; 
				this.setState(clientInfo ); //xaza  this.setState({ clientInfo });
				this.setState( clientAddress ); //this.setState({ clientAddress }); 
				this.setState({["isLoaderOpen"]: false });  
				/// this.clientFullInfoValidate();
				///if(propstep==1){ this.setState({ ["globalStepWorkflow"]: 3, ["workflowType"]:3 }); }
				
				//this.setState({ ["globalStepWorkflow"]: 2, ["workflowType"]:2 });
	
				//this.setState({ ["existedClientRO"]: true, ["emailDoesNotExist"]: 1, ["openPossibleListOfClient"]: false});
				
				// this.clientFullInfoValidate();
				// setTimeout(() => {
				//   this.clientFullInfoValidate(); 
				// }, 100); 
		  
	
			  return response;
			}).catch(function(error){ 
				// this2.setState({ ["isLoaderOpen"]: false});   
				// this2.showToast(error, "error");  
				const errObj=error;
				this2.setState({ ["isLoaderOpen"]: false});  
				///let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
				let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
				//let errMessage="Error/Erreur ";
				const statusCode=errObj.statusCode;
				if(errObj.status===undefined && statusCode===undefined){
					errMessage +="\n There was some error from the expected data from the server. Please try again later.";
					//this2.saveUpdateTicketDataToZendesks(zendeskList); 
					if (typeof errObj === 'string' || errObj instanceof String){
						console.log("inside undefined");  
						this2.showToast(errObj, "error");  
					}else{
						console.log("inside else undefined");  
						this2.showToast(errMessage, "error");  
					} 
					//this2.showToast(errMessage, "error");  
					console.log("FAKE ERRORS SOLVED MINIMIZED");  
					console.log(errObj);
				}
				else if(statusCode===500){   //else if(errObj.status===500){
					//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
					//errMessage +="\n 9999999 ".errObj.data.error.description;
					//errMessage +="\n 9999999 ".errObj.error.description;
					let mesx=errObj.error.description;
					if(mesx===undefined){ mesx=""; }
					errMessage ="SERVER ERROR INTERNAL  "+mesx;
					this2.showToast(errMessage, "error");  
					console.log("500 ERROR");  
					console.log(errObj);  
				}else{
					//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
					//this00.showToastError(errMessage);
					//alert(errMessage); //error
					this2.showToast(errMessage, "error");
					console.log("UNDEFINED ERROR");  
					console.log(errObj);  
				}
			});
	
		  }
	}

	
	 
  }
  

  componentDidUpdate(prevProps) {
		// if (prevProps.ticket_id !== this.props.ticket_id || prevProps.client_id !== this.props.client_id) {
		// 	if (prevProps.ticket_id !== this.props.ticket_id){
		// 		this.setState({ ["ticket_id"]: this.props.ticket_id, });  
		// 	}
		// 	if (prevProps.client_id !== this.props.client_id){
		// 		this.setState({ ["client_id"]: this.props.client_id,  });  
		// 	}
		 	console.log("componentDidUpdate ticket_id "+this.props.ticket_id+" client_id "+this.props.client_id, " invoiceId ", this.props.invoiceId);  
		// }

		console.log("componentDidUpdate PREV VS CURRENT invoiceId ", prevProps.invoiceId, "POS", this.props.invoiceId);  
		if(prevProps.invoiceId !== this.props.invoiceId && this.props.invoiceId !== undefined && this.props.invoiceId>0){
			console.log("componentDidUpdate  PUUUUUUUUUUUUT ACTION-HEEEEEEEEEERE invoiceId ", this.props.invoiceId);  
			console.log("componentDidUpdate ticket_id "+this.props.ticket_id+" client_id "+this.props.client_id, " invoiceId ", this.props.invoiceId);  
		}

		if(prevProps.frminput !== this.props.frminput && this.props.frminput.rndidpros !== undefined ){
			this.setState(this.props.frminput);
			this.navigationTopRefOnTopx.current.focus();
			//this.setState({ [event.target.name]: onOffval }); 
			console.log("componentDidUpdate rndidpros= "+this.props.frminput.rndidpros+" firstname="+this.props.frminput.firstname); 
			
			// const client_id=this.props.client_id;
			// const ticket_id=this.props.ticket_id;
			// const invoiceId=this.props.invoiceId; 
			// const frminput={rndidpros:undefined };
		
			// console.log("000000NeoB2bleadProspectsFormx ", invoiceId, client_id, ticket_id);
			// if(invoiceId >0){
			// 	this.setState({ ["id_invoices"]: invoiceId});

			// 	const this2=this; 
			// 	this.setState({ ["isLoaderOpen"]: true});  
			
			// 	// this.workflowProductList=false; this.workflowClientInfo=false; 
			// 	// this.setState({ ["globalStepWorkflow"]: 4 });
			// 	// this.setState(payInfoCli.client);
			// 	// this.getClientFullAddress(id_clients);
			// 	Promise.resolve(this.props.getNeoB2bleadExistedProspects(invoiceId, "4"))
			// 	.then((response)=>{ 
			// 		//productsList=response.NeoVentes;
			// 		const obj=response.NeoB2bleadProspectsOne; 
			// 		console.log("getNeoB2bleadExistedProspects");
			// 		console.log(obj);
			// 		//this.setState({ ["clientInfo"]: newValue}); 
			// 		//this.setState(clientInfo ); //xaza  this.setState({ clientInfo });
			// 		//this.setState( clientAddress ); //this.setState({ clientAddress }); 
			// 		this.setState({["isLoaderOpen"]: false }); 
					

			// 		/// this.clientFullInfoValidate();
			// 		///if(propstep==1){ this.setState({ ["globalStepWorkflow"]: 3, ["workflowType"]:3 }); }
					
			// 		//this.setState({ ["globalStepWorkflow"]: 2, ["workflowType"]:2 });
		
			// 		//this.setState({ ["existedClientRO"]: true, ["emailDoesNotExist"]: 1, ["openPossibleListOfClient"]: false});
					
			// 		// this.clientFullInfoValidate();
			// 		// setTimeout(() => {
			// 		//   this.clientFullInfoValidate(); 
			// 		// }, 100); 
			
		
			// 	return response;
			// 	}).catch(function(error){ 
			// 		// this2.setState({ ["isLoaderOpen"]: false});   
			// 		// this2.showToast(error, "error");  
			// 		const errObj=error;
			// 		this2.setState({ ["isLoaderOpen"]: false});  
			// 		///let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
			// 		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
			// 		//let errMessage="Error/Erreur ";
			// 		const statusCode=errObj.statusCode;
			// 		if(errObj.status===undefined && statusCode===undefined){
			// 			errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			// 			//this2.saveUpdateTicketDataToZendesks(zendeskList); 
			// 			if (typeof errObj === 'string' || errObj instanceof String){
			// 				console.log("inside undefined");  
			// 				this2.showToast(errObj, "error");  
			// 			}else{
			// 				console.log("inside else undefined");  
			// 				this2.showToast(errMessage, "error");  
			// 			} 
			// 			//this2.showToast(errMessage, "error");  
			// 			console.log("FAKE ERRORS SOLVED MINIMIZED");  
			// 			console.log(errObj);
			// 		}
			// 		else if(statusCode===500){   //else if(errObj.status===500){
			// 			//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			// 			//errMessage +="\n 9999999 ".errObj.data.error.description;
			// 			//errMessage +="\n 9999999 ".errObj.error.description;
			// 			let mesx=errObj.error.description;
			// 			if(mesx===undefined){ mesx=""; }
			// 			errMessage ="SERVER ERROR INTERNAL  "+mesx;
			// 			this2.showToast(errMessage, "error");  
			// 			console.log("500 ERROR");  
			// 			console.log(errObj);  
			// 		}else{
			// 			//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
			// 			//this00.showToastError(errMessage);
			// 			//alert(errMessage); //error
			// 			this2.showToast(errMessage, "error");
			// 			console.log("UNDEFINED ERROR");  
			// 			console.log(errObj);  
			// 		}
			// 	});
			// }
		}
		
  }

  showToast = (arg, toastType) => {
		//toastType: success or error or warning or info
		const toastrDuration=this.state.toastrDuration; //5500	 
		this.setState({  
			toastrMessage: arg, 
		toastrType: toastType
		});
		setTimeout(() => { 
		this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		}); 
		}, toastrDuration);
	}

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const email = this.state.email;
    const gender = this.state.gender;
    const language = this.state.language;
    const dob = this.state.dob;
    const source = this.state.source;
    const current_insurances = this.state.current_insurances;
    const sent = this.state.sent;
    const id_invoices = this.state.id_invoices;
	//const id_invoices = "4713";   
    const user_creation = this.state.user_creation;
    const created = this.state.created;
	const age = this.state.age;
	const send_date = this.state.send_date;
	const observation = this.state.observation;
	//age, send_date, observation

    //const NeoB2bleadProspects = {id: id, firstname: firstname, lastname: lastname, cell: cell, email: email, gender: gender, language: language, dob: dob, source: source, current_insurances: current_insurances, sent: sent, id_invoices: id_invoices, user_creation: user_creation, created: created, }
    //const NeoB2bleadProspects = {id: id, firstname: firstname, lastname: lastname, cell: cell, email: email, gender: gender, language: language, dob: dob, source: source, current_insurances: current_insurances, id_invoices: id_invoices  };
	const NeoB2bleadProspects = {id: id, firstname: firstname, lastname: lastname, cell: cell, email: email, gender: gender, language: language, dob: dob, source: source, current_insurances: current_insurances, id_invoices: id_invoices, age:age, send_date:send_date, observation:observation  };
	NeoB2bleadProspects.comapnyB2BPros="4";  
	

	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NeoB2bleadProspects[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  console.log(`${errorFound} -- ${fieldName}: ${fieldValue}`);
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`${t('zqwerrorFoundonform_x')}`); return false; }
	// if(dob==="" && age===""){
	// 	this.showToast(t('zqwfrmdobage_x'), "error"); 
	// 	return false; 
	// }
	let actId=0;
	if(this.actionMode==='new'){ 
		//this.props.addOrUpdateNeoB2bleadProspects(NeoB2bleadProspects, 1); 
		actId=1;
		////this.props.addNeoB2bleadProspects(NeoB2bleadProspects); 
	}else{ 
		//this.props.addOrUpdateNeoB2bleadProspects(NeoB2bleadProspects, 2);
		actId=2;
		////this.props.updateNeoB2bleadProspects(NeoB2bleadProspects);	
	}

	console.log("NeoB2bleadProspects", NeoB2bleadProspects); //return false; 

	const this2=this; 
	this.setState({ ["isLoaderOpen"]: true});  
	Promise.resolve(this.props.addOrUpdateNeoB2bleadProspects(NeoB2bleadProspects, 1))
	.then((response)=>{
		//this.setState(response.NeoB2bleadProspectsOne);
		const dataResp = response.NeoB2bleadProspects; 
		this.setState({["openTransactionPopup"]: true, ["isLoaderOpen"]: false }); 
		if(this.props.paStateFuncAction!==undefined){
			//console.log('caaaaaaaaaaaalling  paStateFuncAction ', ticket_id, assigneeId, groupId);
			const locMess="Lead Prospect: "+firstname;
			//const locMess=t("zqwMesas015_x");
			const locObj={stateName: "lead2actionFormtxt3",  stateVal:locMess, };
			this.props.paStateFuncAction(locObj);
		}
		// if(this.props.frminputClose !== undefined ){ 
		// 	//this.setState({ [event.target.name]: onOffval }); 
		// 	//console.log("componentDidMount rndidpros= "+this.props.frminput.rndidpros+" firstname="+this.props.frminput.firstname);  
		// 	this.props.frminputClose(); 
		// }
		console.log("laaaaaaaaaaast", dataResp); 
		return response;
	}).catch(function(error){ 
		// this2.setState({ ["isLoaderOpen"]: false});   
		// this2.showToast(error, "error");  
		const errObj=error;
		this2.setState({ ["isLoaderOpen"]: false});  
		///let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		//let errMessage="Error/Erreur ";
		const statusCode=errObj.statusCode;
		if(errObj.status===undefined && statusCode===undefined){
			errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//this2.saveUpdateTicketDataToZendesks(zendeskList); 
			if (typeof errObj === 'string' || errObj instanceof String){
				console.log("inside undefined");  
				this2.showToast(errObj, "error");  
			}else{
				console.log("inside else undefined");  
				this2.showToast(errMessage, "error");  
			} 
			//this2.showToast(errMessage, "error");  
			console.log("FAKE ERRORS SOLVED MINIMIZED");  
			console.log(errObj);
		}
		else if(statusCode===500){   //else if(errObj.status===500){
			//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//errMessage +="\n 9999999 ".errObj.data.error.description;
			//errMessage +="\n 9999999 ".errObj.error.description;
			let mesx=errObj.error.description;
			if(mesx===undefined){ mesx=""; }
			errMessage ="SERVER ERROR INTERNAL  "+mesx;
			this2.showToast(errMessage, "error");  
			console.log("500 ERROR");  
			console.log(errObj);  
		}else{
			//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
			//this00.showToastError(errMessage);
			//alert(errMessage); //error
			this2.showToast(errMessage, "error");
			console.log("UNDEFINED ERROR");  
			console.log(errObj);  
		}
	});


  };

  handleCancel = () => { 
	if(this.props.frminputClose !== undefined && this.props.frminputClose !== null){ 
		this.props.frminputClose(); 
	}else{
		/*if(this.actionMode==='new'){ 
			this.props.history.push(`/NeoB2bleadProspects`);
		}else{ 
			const idx = this.props.match.params.id; 
			this.props.history.push(`/NeoB2bleadProspects/${idx}`);
			//this.props.history.push(`/NeoB2bleadProspects/${this.props.NeoB2bleadProspects.id}`);
		}*/
	}
  }

   
  setStartDate = (mydate) => { 
	const dd = new Date(mydate);
	//const dd=new Date();
	//const ddIso=new Date(dd.getFullYear(), dd.getMonth(), dd.getDate());
	const ddStr=""+dd.getFullYear()+"-"+dd.getMonth()+"-"+dd.getDate(); 
	const chosen_date = moment(mydate); 
	let ddStr2 =chosen_date.format('YYYY-MM-DD');
	if(mydate===null || mydate===""){ ddStr2=""; }
	this.setState({ ["send_date2"]: mydate, ["send_date"]: ddStr2});  

  }

  Alert  = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  render() {
    const { t } = this.props;
	const FinishTransactionPopup=this.FinishTransactionPopup;
	const rnd="zx"+this.state.rndidpros; 
	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType;
	//const [startDate, setStartDate] = useState(null);
	//const startDate=
	const setStartDate=this.setStartDate;
	const max_date = moment();
	max_date.add(15, 'days');
	//const max_dateTxt =max_date.format('YYYY-MM-DD');

	const ms = new Date().getTime() + (86400000*14);
	const max_dateTxt  = new Date(ms);
	const Alert=this.Alert;
	const existedRICny=this.state.existedRecInvoiceCny;

    return (
      <div key={rnd}>
		{/* <h3 style={{textAlign:'center',color:'blue'}}>Lead2Action</h3>  */}
		<Alert severity="info"><span style={{textAlign:'center',fontSize:'1.25rem'}}>Solutions INnova</span></Alert> 
        <h5>{this.actionMode==='new'? t('zqwacAddNeoB2bleadProspects_x'): t('zqwacEditNeoB2bleadProspects_x')}</h5>
        <form onSubmit={this.handleSubmit}>

		  <div className="form-group" style={{display:'none'}} >
			<label htmlFor="id">{t('zqwid_x')}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
			{this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="firstname">{t('zqwfirstname_x')}</label>
            <input type="text" name="firstname" id="firstname" defaultValue={this.state.firstname} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_firstname ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwfirstname_x')} /> 
			{this.state.e_firstname && (<span className="text-danger">{FIELD_VALIDATION['firstname'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="lastname">{t('zqwlastname_x')}</label>
			<input type="text" ref={this.navigationTopRefOnTopx} style={{position:'absolute', zIndex:-99}} />
            <input type="text" name="lastname" id="lastname"  defaultValue={this.state.lastname} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_lastname ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwlastname_x')} /> 
			{this.state.e_lastname && (<span className="text-danger">{FIELD_VALIDATION['lastname'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="cell">{t('zqwcell_x')}</label>
            <input type="text" name="cell" id="cell" defaultValue={this.state.cell} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_cell ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcell_x')} /> 
			{this.state.e_cell && (<span className="text-danger">{FIELD_VALIDATION['cell'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="email">{t('zqwemail_x')}</label>
            <input type="text" name="email" id="email" defaultValue={this.state.email} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_email ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwemail_x')} /> 
			{this.state.e_email && (<span className="text-danger">{FIELD_VALIDATION['email'].message}</span>)} 
          </div>

		  {/* <div className="form-group">
			<label htmlFor="gender">{t('zqwgender_x')}</label>
			<select name="gender" id="gender" autoFocus defaultValue={this.state.gender} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_sent ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwgender_x')}> 
                        <option value="">Please Select</option>
                        <option value="f">Femme/Female</option>
                        <option value="m">Homme/Male</option>
                    </select>  
			{this.state.e_gender && (<span className="text-danger">{FIELD_VALIDATION['gender'].message}</span>)} 
          </div> */}

		  <div className="form-group">
			<label htmlFor="language">{t('zqwlanguage_x')}</label>
            {/* <input type="text" name="language" id="language" defaultValue={this.state.language} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_language ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwlanguage_x')} />  */}

			<select  name="language" id="language" defaultValue={this.state.language} onChange={this.handleChange} onBlur={this.handleFocusout}
                      className={this.state.e_language ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwlanguage_x')} >
                        <option value="">{t("zqwNVFrmEl96_x")}...</option> 
                        <option value="fr">{t("zqwNVFrmEl97_x")}</option>
                        <option value="en">{t("zqwNVFrmEl98_x")}</option> 
                    </select>   
			{this.state.e_language && (<span className="text-danger">{FIELD_VALIDATION['language'].message}</span>)} 
          </div>

		  {/* <div className="form-group">
			<label htmlFor="dob">{t('zqwdob_x')}</label>
            <input type="date" name="dob" id="dob" defaultValue={this.state.dob} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_dob ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwdob_x')} /> 
			{this.state.e_dob && (<span className="text-danger">{FIELD_VALIDATION['dob'].message}</span>)} 
          </div> */}

		  {/* age, send_date, observation */}

		  {/* <div className="form-group">
			<label htmlFor="age">{t('zqwage_x')}</label>
            <input type="number" name="age" id="age" defaultValue={this.state.age} 
			step={1} min={18} max={120} 
			onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_age ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwage_x')} /> 
			{this.state.e_age && (<span className="text-danger">{FIELD_VALIDATION['age'].message}</span>)} 
          </div> */}

		  
		  <div className="form-group">
			<label htmlFor="source">{t('zqwsource2_x')}</label>
			{/* <label htmlFor="source">Problématique</label> */}
			{/* <br/>({t('zqwsendleadx2_x')})<br/>  */}
			<br/>(Problématique du client/de la cliente)<br/>
            <input type="text" name="source" id="source" defaultValue={this.state.source} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_source ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwsource2_x')} /> 
			{this.state.e_source && (<span className="text-danger">{FIELD_VALIDATION['source'].message}</span>)} 
          </div>

		  {/* <div className="form-group">
			<label htmlFor="current_insurances">{t('zqwcurrent_insurances_x')}</label>
			<br/>({t('zqwsendleadx3_x')})<br/>
            <input type="text" name="current_insurances" id="current_insurances" defaultValue={this.state.current_insurances} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_current_insurances ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcurrent_insurances_x')} /> 
			{this.state.e_current_insurances && (<span className="text-danger">{FIELD_VALIDATION['current_insurances'].message}</span>)} 
          </div> */}

		  {/* age, send_date, observation */}

		  {/* <div className="form-group"> 
			<label>{t('zqwsend_date_x')}</label><br/> 
			<DatePicker
      selected={this.state.send_date2}
      onChange={(date) => setStartDate(date)}
	  dateFormat="yyyy-MM-dd"
      minDate={new Date()}
      maxDate={max_dateTxt}
	  className="form-control"
	  isClearable={true} 
      showDisabledMonthNavigation 
	  name="send_date" id="send_date" 
    />
            
			{this.state.e_send_date && (<span className="text-danger">{FIELD_VALIDATION['send_date'].message}</span>)} 
          </div> */}

		  <div className="form-group">
			<label htmlFor="observation">{t('zqwobservation_x')}</label> 
            {/* <input type="text" name="observation" id="observation" defaultValue={this.state.observation} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_observation ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwobservation_x')} />  */}
			<textarea id="observation" name="observation" rows="3" cols="50" 
			defaultValue={this.state.observation} onChange={this.handleChange} onBlur={this.handleFocusout} 
			className={this.state.e_observation ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwobservation_x')} >
 			</textarea>  
			{this.state.e_observation && (<span className="text-danger">{FIELD_VALIDATION['observation'].message}</span>)}  
          </div>

		  {existedRICny!==undefined?		  
          <div className="form-group">
            <button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('zqwCViewEl56_x')}</button>
			{existedRICny!==undefined?
			<Alert severity="error">Registre de Lead déjà existé/ Lead Record already existed</Alert>:"" 
			}
          </div>:
		  <div className="form-group">
		  {/* <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')}</button> &nbsp;  */}
		  <button type="submit" className="btn btn-primary">{t('zqwsend_x')} - Solutions INnova</button> &nbsp; 
		  <button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('zqwCViewEl56_x')}</button>
		  
		</div>
		  }
		  <Loader  openx={this.state.isLoaderOpen}  />
		  <Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
        </form>
		<FinishTransactionPopup /> 
      </div>
    );
  }

  FinishTransactionPopup = (props) => {  
    const open = this.state.openTransactionPopup; //lg sm md  
    const { t } = this.props;
    //const { t, i18n } = useTranslation("common"); 
  
    return (
      <div>
        <Dialog maxWidth={"sm"} open={open} onClose={this.handleCloseTransactionPopup} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirm">
          <DialogContent id="form-dialog-transactionPopup">
          <div className="text-center"> 
            <p>Lead a étét sauvegardé et envoyé par courriel.</p> 
			{/* <p>{t("zqwNVFrmEl120_x")}</p> 
            <p>{t("zqwNVFrmEl121_x")}</p>  
            <p>{t("zqwNVFrmEl122_x")}</p>   */}
          </div>   
            
          <div className="form-row neoxtbl">
            <div className="form-group col-md-9">
            <br/>
            <input type="button" onClick={this.handleCloseTransactionPopup} className="btn btn-success" value={t("zqwNVFrmbtn_x")} /> &nbsp; &nbsp; 
            </div> 
          </div>
              
          </DialogContent>
          
        </Dialog>
      </div>
    );
  }

  handleCloseTransactionPopup  = (anyx) => {  
    //console.log("OLD STYLE SUCCESS handleClosePostalCode"); 
    this.actionMode='new';
    this.requestTimer = null;
    this.backInvoiceId=0; 
    this.requestTimer = null; 
    this.stripeAvoidManyTokens=0;
    this.stripeTryTimes=0;
    this.stripeNeoTokenTrack=0;
    this.paymentBtnTryTimes2=0;
    this.paymentBtnTryTimes3=0;
    this.setState({["openTransactionPopup"]: false });
	this.setState(JSON.parse(JSON.stringify(this.stateInit)));
	if(this.props.frminputClose !== undefined  && this.props.frminputClose !== null){ 
		this.props.frminputClose(); 
	}

    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
    /*let codepromoOptions=[]; 
    const productsList=JSON.parse(JSON.stringify(this.state.productsList.products));
    const listPromotionCodes=JSON.parse(JSON.stringify(this.state.listPromotionCodes)); //this.state.listPromotionCodes;
    this.setState(JSON.parse(JSON.stringify(this.stateInit)));
    this.setState({["listPromotionCodes"]: listPromotionCodes, ["productsList"]: {products:productsList}});
    const taxInfo=getProvinceTaxInfo("QC");
    this.setState({ ["taxInfo"]: taxInfo });
    this.actionMode='new';
    this.requestTimer = null;
    this.backInvoiceId=0;
    this.stripeAvoidManyTokens=0;
    this.stripeTryTimes=0;
    this.stripeNeoTokenTrack=0;
    this.paymentBtnTryTimes2=0;
    this.paymentBtnTryTimes3=0; 
    let promosList = listPromotionCodes.length > 0
    	&& listPromotionCodes.map((item, i) => { 
        if(item.percentage >0 && item.active==1){ 
          codepromoOptions.push({ value: item.id + '-'+item.percentage+ '-'+item.name, label: item.description + ' ' + item.percentage + '% ('+item.name+')' });
        } 
      return (
        null
      );
    }, this);

    this.setState({["codepromoOptions"]: codepromoOptions });*/

    //this.props.history.push(`/NeoVentes`);
  }
  handleCloseProdInfoPopup  = (anyx) => {   
    this.setState({["openInfoStepsPopup"]: false,  });  
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
     
  }


}

//const TransNeoB2bleadProspectsForm = withTranslation()(NeoB2bleadProspectsForm); 
const mapStateToProps = (state) => ({ NeoB2bleadProspects: state.NeoB2bleadProspects });

const mapDispatchToProps = { addOrUpdateNeoB2bleadProspects, getNeoB2bleadProspects, getClientAndAddress, getNeoB2bleadExistedProspects };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoB2bleadProsInnovaForm));
