import React from "react";
import { Link } from "react-router-dom";
import Select from 'react-select'
import {
    Row,
    Col,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    UncontrolledAlert,
    Spinner,
    Button
    // Table
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faUserPlus,
    faEdit
} from '@fortawesome/free-solid-svg-icons';
import {
    BrowserRouter as Router,
    Redirect,
  } from "react-router-dom";
import moment from "moment";
import _ from 'lodash';
import Table from '../../../utils/MaterialTable';
import DashboardElement from '../../../../utils/DashboardElement';
import EditAgentModal from "./EditAgentModal";
import CreateAgentModal from "./CreateAgentModal";

import axios from 'axios';
import { API_DOMAIN } from '../../../../../appConfig'; 
import { axiosDefaults } from '../../../../../appConfigaxios'; 

//import { Font } from "@react-pdf/renderer";
const BACKEND1 = process.env.REACT_APP_API_URLX;

export default class CenterSectionAdminUsers extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            data:[],
            selectedUser: {
                user: '',
                domaine: [],
                languages: [],
                provinces: [],
                thermos: [],
                thermo_order:'',
            },
            neoleadscatperson: [],
            domaineOptions: [],
            provinceOptions: [],
            languageOptions: [],
            thermoOptions: [],
            thermoOrderOptions: [], 
            showChangeUser: false,
            showCreateUser: false,
            loading: false,
            error: false
        };
        this.toggleEditUser = this.toggleEditUser.bind(this);
        this.setUser = this.setUser.bind(this)
    }

    componentDidMount(){
        axiosDefaults(null);
        axios.get(
            BACKEND1+'/leadscattoperson',
            {
                params: {
                    ////src: "desk",
                    showtrans: 3
                    // page:2,
                    // limit:6,
                    // newlead:1 pour obtenir des un nouveau lead
                },
                // headers: {
                //     'Content-Type': 'application/json',
                //     // 'x-jwt-token': localStorage.getItem('token'),
                //     'auth-id': localStorage.getItem('user_id'),
                //     'Authorization': "Bearer " + localStorage.getItem('token')
                // }
            }
        )
        .then(res => {
            const {data} = res.data 
            const provinceOptions = [{label: 'Québec', value: 'QC'}, {label: 'Ontario', value: 'ON'}];
            const languageOptions = [{label: 'Français', value: 'fr'}, {label: 'English', value: 'en'}];
            const thermoOptions = [
                {label: this.genLang('cold'), value: '1'},
                {label: this.genLang('warm'), value: '2'},
                {label: this.genLang('hot'), value: '3'},
              ];
            const thermOrderOptions = [{label: 'Oldest to newest', value: '1'}, {label: 'Newest to oldest', value: '2'}]
            const neoleadscatperson = data.neoleadscatperson;
            const domaineOptions = data.domainList.map(d => {
            return {label: d.label_fr, value: d.name, id: d.id}
            });
            const users = data.users.map(u => {
                //console.log("-thermo_order-thermo_order=", u); 
            return {
                user: u.agent,
                domaine: domaineOptions.filter(x => u.catList.some(i => x.id === i.domainId)),
                languages: languageOptions.filter(x => u.languages.some(i => x.value === i)), 
                provinces: provinceOptions.filter(x => u.provinces.some(i => x.value === i)),
                thermos: thermoOptions.filter(x => u.thermos.some(i => x.value === i)),
                //thermo_order: thermOrderOptions.filter(x => u.thermo_order.some(i => x.value === i)), 
                thermo_order: u.thermo_order, 
                id: u.users_id,
            }});
            this.setState(
                {
                data: users, 
                domaineOptions: domaineOptions,
                provinceOptions: provinceOptions,
                languageOptions: languageOptions,
                thermoOrderOptions:thermOrderOptions, 
                neoleadscatperson: neoleadscatperson
            
            })
        // })
        // },(error) =>{
        //     this.setState({data: fakeLeads()})
        //     // CHANGE BACK IN PROD
        //     // this.setState({
        //     //     redirect:'/login'
        //     // })
        })
    }

    setUser(clickedUser){
        this.setState((prevState) => {
            return {...prevState, selectedUser: {...clickedUser}}
        })
    }

    toggleEditUser(){
        this.setState((prevState) => {
            return {...prevState, showChangeUser: !prevState.showChangeUser, error: false, loading: false}
        })
    }

    toggleCreateUser = () => {
        this.setState((prevState) => {
            return {...prevState, showCreateUser: !prevState.showCreateUser}
        })
    }

    handleSelectChange = (e, field) => {
        const arr = e => {
            try {
                return e.map(i => i)
            } catch {return []}
        } 
        this.setState(prevState => ({
            ...prevState, selectedUser: {...prevState.selectedUser, [field]: arr(e)}
        }))
    }

    handleSelectChangex = (e, field) => {
        const eventx=e;
        const val=e.value;
        //console.log("woooooooooooooooooooooy", field, val, e);
        
        this.setState(prevState => ({
            ...prevState, selectedUser: {...prevState.selectedUser, [field]: val}
        }))
    }

    handlePost = () => {
        this.setState((prevState) => {
            return {...prevState, loading: true}
        })
        const selectedUser = this.state.selectedUser;
        const userData = this.state.data.find(u => u.id === selectedUser.id);
        const arr = selectedUser.domaine.map(i => {
            // map over SelectedUser domaines to find ones that are being added.
            // if already attributed, return an object with the neoleadscat id
            // if being added, return an object with leads_cat_id: domain_id
            const neolead = this.state.neoleadscatperson.find(n => n.users_id === this.state.selectedUser.id && n.leads_cat_id === i.id);
            if (userData.domaine.some(j => i.id === j.id)) return {id: neolead.id};
            else return {leads_cat_id: i.id};
        });
        const obj ={
            users_id: this.state.selectedUser.id,
            massLeadsCatPerson: arr,
            thermo_order:this.state.selectedUser.thermo_order,
            languages: this.state.selectedUser.languages.map(i => i.value),
            thermos: this.state.selectedUser.thermos.map(i => i.value),
            provinces: this.state.selectedUser.provinces.map(i => i.value)
        }
        axios.post(
                BACKEND1+'/leadscattoperson',
                obj,
                {
                  params:{
                    //src:"desk",
                  },
                //   headers: { 
                //     'Content-Type': 'application/json',
                //     'auth-id': localStorage.getItem('user_id'),
                //     'Authorization':"Bearer "+localStorage.getItem('token')
                //   }
                }
            ).then(res => {
                console.log(res.status)
                if (res.status === 200){
                this.setState((prevState) => {
                return {...prevState, 
                    data: this.state.data.map(i => i.id === this.state.selectedUser.id ? {...this.state.selectedUser}: {...i}),
                    neoleadscatperson: res.data.data.neoleadscatperson, 
                    loading: false
                }})
                this.toggleEditUser();
            } else if (res.status === 400){
                this.setState((prevState) => {
                    return {...prevState, error: true, loading: false}
                })
            }
        })
        console.log(selectedUser.domaine, userData.domaine)
        console.log(obj)
    }

    genLang = (text) => {
        const {trad, lang} = this.props;
        return trad['neoleads'][text][lang]
    }

    render(){

        if(this.state.redirect){
            return(
                <Redirect
                to={{
                    pathname: this.state.redirect,
                    // search: window.location.search+"?return="+window.location.pathname,
                    // state: { referrer: props.location.pathname }
                }}
            />
            )
        }

        const columns = [
            {
                Header: 'Agent',
                accessor: 'user',
            },
            {
                Header: this.genLang('domains'),
                accessor: 'domaine',
            },
            {
                Header: this.genLang('languages'),
                accessor: 'languages',
            },
            {
                Header: 'Province(s)',
                accessor: 'provinces',
            },
            {
                Header: this.genLang('edit'),
                accessor: 'editIcon'
            }
        ];

        return (
        <>
        <Row onClick={() => console.log(this.state)}>
            <Col md={12} style={{paddingBottom: '1rem'}}>
                <h1 style={{ color: "#0052BB" }} className='text-align-center m-t-15 element-header'>Admin Agents</h1>
            </Col>
        </Row>
        <Row>
            <Col md={12} >

                <DashboardElement
                    col={12}
                    title={<>Agents <FontAwesomeIcon icon={faUser} /></>}
                >
                    <Row>
                        <Col md={12}>
                            <Nav
                                style={{
                                    paddingTop: "0",
                                    marginBottom: "10px"
                                }}
                            >
                                {/* <Button onClick={this.toggleCreateUser} className="ml-auto" outline color='primary'>Ajouter un User <FontAwesomeIcon icon={faUserPlus} /></Button> */}
                            </Nav>
                        </Col>
                    </Row>
                    <Table
                        genLang={this.genLang}
                        data={
                            _.map(this.state.data, (elt) => {
                                elt.editIcon = 
                                <Button color='primary' outline size ='sm' 
                                style={{whiteSpace: "nowrap"}} onClick={() => {
                                    this.setUser(elt)
                                    this.toggleEditUser()
                                }}>{`${this.genLang('edit')} `}<FontAwesomeIcon 
                                id={elt} 
                                icon={faEdit}
                                style={{cursor: 'pointer', color: "#0052BB"}} 
                                /></Button>
                                return elt;
                            })
                        }
                        columns={columns}
                    />
                </DashboardElement>
            </Col>
        </Row>
        {/* <CreateAgentModal 
            isOpen={this.state.showCreateUser} 
            toggle={this.toggleCreateUser} 
            domaineOptions={this.state.domaineOptions} 
            languageOptions={this.state.languageOptions}
            provinceOptions={this.state.provinceOptions}
        /> */}

        <EditAgentModal 
            toggleEditUser={this.toggleEditUser}
            showChangeUser={this.state.showChangeUser}
            error={this.state.error}
            selectedUser={this.state.selectedUser}
            domaineOptions={this.state.domaineOptions}
            handleSelectChange={this.handleSelectChange}
            handleSelectChangex={this.handleSelectChangex} 
            languageOptions={this.state.languageOptions}
            provinceOptions={this.state.provinceOptions}
            handlePost={this.handlePost}
            loading={this.state.loading}
            genLang={this.genLang}
            trad={this.props.trad}
            lang={this.props.lang}
        />
        </>
        )
    }
}