export const SET_COSTUMER = 'customers:set';
export const SET_CONFLICT = 'conflict:set';

export  function setUser(customer){
    return {
        type:SET_COSTUMER,
        params:{
            ...customer
        }
    }
}

export  function setConflict(conflict){
    return {
        type:SET_COSTUMER,
        params:{
            conflict:conflict
        }
    }
}