import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import { Provider } from 'react-redux';


import 'bootstrap/dist/css/bootstrap.min.css';  
import './adminltelibs/plugins/fontawesome-free/css/all.min.css';
import './adminltelibs/dist/css/adminlte.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import 'bootstrap/dist/css/bootstrap.css';
import './stylesheets/index.css';
import './stylesheets/bug.css';
import "react-datepicker/dist/react-datepicker.css";
// import './stylesheets/softphone.css'; 

// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
// import "@fullcalendar/timegrid/main.css";
// import "@fullcalendar/timeline/main.css";
// import "@fullcalendar/resource-timeline/main.css";




import App from './App';
import rootReducer from './reducers';
/////import { getArticles } from './actions/articleAction';
import { getZxTeamsAll } from './actions/ZxTeamsAction';

// import i18n (needs to be bundled ;)) 
import './i18n'; 
import { withTranslation, I18nextProvider } from 'react-i18next';


//const store = createStore(rootReducer, applyMiddleware(thunk, logger));  
const store = createStore(rootReducer, applyMiddleware(thunk));

/////store.dispatch(getArticles());
//store.dispatch(getZxTeamsAll());
const MyAppTranlated = withTranslation('common')(App); 

/*ReactDOM.render(
  <Provider store={store}>                                               
    <App />
	<Suspense fallback="loading">
      <MyAppTranlated />
    </Suspense>
  </Provider>, 
  document.getElementById('root')
); */
//<I18nextProvider i18n={i18next}><MyAppTranlated /></I18nextProvider>

ReactDOM.render(
  <Provider store={store}>  
	<Suspense fallback="loading">
      <I18nextProvider><MyAppTranlated /></I18nextProvider>
      <ToastContainer 
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Suspense>
  </Provider>, 
  document.getElementById('root')
);
