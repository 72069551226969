import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNeoSalesPlanningWeekly, getNeoSalesPlanningWeekly, getNeoSalesPlanningWeeklyAll, getNeoSalesPlanningWeeklyGoto, deleteNeoSalesPlanningWeekly } from '../../actions/NeoSalesPlanningWeeklyAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
//import CustomToolbarSelect from "../CustomToolbarSelect";
import CustomToolbarSelect from "../CustomToolbarBasicActions";
import NeoSalesPlanningDailyList  from "../neosalesplanningdaily/NeoSalesPlanningDailyList"; 
import Toastr from "../../messages";
import  Loader from "../../loader"; 
import history from '../../history';

class NeoSalesPlanningWeeklyList extends Component {
	constructor(props) {
		super(props); 
		this.state={weekId:0, showWeekDays:true, week:0, isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000, };
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 
	}
  
	componentDidMount() { 
		//this.props.getNeoSalesPlanningWeeklyAll();
		this.setState({ ["isLoaderOpen"]: true});
		const this2=this;   
		Promise.resolve(this.props.getNeoSalesPlanningWeeklyAll())
        .then((response)=>{ 
		  this.setState({ ["isLoaderOpen"]: false});
		  this.showToast("On a chargé les données avec succès!", "success");  
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          alert(error);
        });
	}

	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info
		const toastrDuration=this.state.toastrDuration; //5500	 
		this.setState({  
			toastrMessage: arg, 
		  toastrType: toastType
		  });
		setTimeout(() => { 
		  this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		  }); 
		}, toastrDuration);
	}
	
	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.NeoSalesPlanningWeekly;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteNeoSalesPlanningWeekly(gridIds[0]);

	}

  updateXRecordsMain(selectedRows){  
    const { t } = this.props;
	const objList  = this.props.NeoSalesPlanningWeekly;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
      gridIndexes.push(row.index); 
      idIndex=row.index; 
	return null;
    });
 
    if(gridIndexes.length>1){
      alert(`{t('zqwgridIndexesfound_x')}`);
      return false;
    }
 
    let obj=objList[idIndex]; 
	//this.props.getNeoSalesPlanningWeeklyGoto(obj.id); 
	history.push(`./salesplanningweekly/${obj.id}/edit`); 
  }
  

  render() { 
    const { t } = this.props;
	const columns = [
	 {
	  name: "id", label: t('zqwid_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "week", label: t('zqwweek_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "week_start", label: t('zqwweek_start_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "week_end", label: t('zqwweek_end_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "expected_quantity", label: t('zqwexpected_quantity_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "expected_amount", label: t('zqwexpected_amount_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
		name: "panier", label: t("zqwBHElem1_x"),
		options: { filter: true, sort: true,}
	 },
	 {
	  name: "invoice_quantity", label: t('zqwinvoice_quantity_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "invoice_amount", label: t('zqwinvoice_amount_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "payment_quantity", label: t('zqwpayment_quantity_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "payment_amount", label: t('zqwpayment_amount_x'),
	  options: { filter: true, sort: true,}
	 },
	 
	 /*{
	  name: "creation_date", label: t('zqwcreation_date_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "last_update", label: t('zqwlast_update_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "user_creation", label: t('zqwuser_creation_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "user_update", label: t('zqwuser_update_x'),
	  options: { filter: true, sort: true,}
	 },*/

	];
const textLabelOptions= {
    body: {
      noMatch: t('zqwgridnoMatch_x'),
      toolTip: t('zqwgridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('zqwgridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('zqwgridnext_x'),
      previous: t('zqwgridprevious_x'),
      rowsPerPage: t('zqwgridrowsPerPage_x'),
      displayRows: t('zqwgriddisplayRows_x'),
    },
    toolbar: {
      search: t('zqwgridsearch_x'),
      downloadCsv: t('zqwgriddownloadCsv_x'),
      print: t('zqwgridprint_x'),
      viewColumns: t('zqwgridviewColumns_x'),
      filterTable: t('zqwgridfilterTable_x'),
    },
    filter: {
      all: t('zqwgridall_x'),
      title: t('zqwgridftitle_x'),
      reset: t('zqwgridreset_x'),
    },
    viewColumns: {
      title: t('zqwgridvctitle_x'),
      titleAria: t('zqwgridvctitleAria_x'),
    }, 
  };	
			
	const options = {
	  filterType: 'checkbox',
	  textLabels: textLabelOptions,
	  onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		//console.log(currentRowsSelected, allRowsSelected);
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log(allRowsSelected);
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
		console.log("weeeeek id "+rowData[0]); 
		this.setState({["weekId"]: rowData[0], ["showWeekDays"]: true, ["week"]: rowData[1],  });
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} isEdit={true} isDelete={true} />
      ),
	};  

	const myToastMessage=this.state.toastrMessage;
  	const toastrType=this.state.toastrType;
	  
    if(this.props.NeoSalesPlanningWeekly.length) {                                
      return (
        <div>
          <h4>{t('zqwNeoSalesPlanningWeekly_x')}</h4>		  
		  <MUIDataTable
			  title={""}
			  data={this.props.NeoSalesPlanningWeekly}
			  columns={columns}
			  options={options}
			/>
			<Loader  openx={this.state.isLoaderOpen}  />
			<Toastr messagex={myToastMessage} open={true} toastType={toastrType} /> 
			<br/>	
			{this.state.weekId >0?
			<>{t("zqwweek_x")} # {this.state.week} 
		  <NeoSalesPlanningDailyList weekId={this.state.weekId} showWeekDays={this.state.showWeekDays} /></>
		  :''}
        </div>
      )    
    } else {
      return (<div>{t('zqwgridNoRecordfound_x')} <Loader  openx={this.state.isLoaderOpen}  /></div>)
    }  }
}


const mapStateToProps = (state) => ({ NeoSalesPlanningWeekly: state.NeoSalesPlanningWeekly });  
const mapDispatchToProps = { addOrUpdateNeoSalesPlanningWeekly, getNeoSalesPlanningWeekly, getNeoSalesPlanningWeeklyAll, getNeoSalesPlanningWeeklyGoto, deleteNeoSalesPlanningWeekly };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoSalesPlanningWeeklyList));
