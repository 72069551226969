import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faCoins,
    faTachometerAlt,
    faTags,
    faFilter,
    faGlobeAmericas,
    faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import React, {
    Component
} from 'react';
import {
    Alert,
    Badge,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    NavLink,
    ButtonGroup
    // Table
} from 'reactstrap';
import DashboardElement from '../../../utils/DashboardElement'
// import Dashbo
import Container from 'reactstrap/lib/Container';
import Button from 'reactstrap/lib/Button';
import Table from '../../utils/MaterialTable';
// import Booking from './Booking'
import TableIcon from '../../../utils/TableIcon';
import Tasks, { CalendarIconButton } from './component/Tasks'
import Widget from './component/Widget'
import AppBar from './component/AppBar'
import UserIcon from './component/UserIcon'
import CenterSection from './component/CenterSection';
import TopMenu from './component/TopMenu';
import NeoVentesMySWABasicPostActions from '../../../../components/neoventes/NeoVentesMySWABasicPostActions';
  
//import { axiosDefaults } from '../../../../../appConfigaxios'; 

import '../../../index.css';
import '../../../main.css'; 

class Lead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'fr'
        }
        // axios.get(
        //     'http://50.100.168.99:8055/api/leads?id=1234',
        //     {
        //       params:{
        //         src:"desk",
        //         // page:2,
        //         // limit:6,
        //         // newlead:1 pour obtenir des un nouveau lead
        //       },
        //       headers: { 
        //         'Content-Type': 'application/json',
        //         // 'x-jwt-token': localStorage.getItem('token'),
        //         'auth-id': localStorage.getItem('user_id'),
        //         'Authorization':"Bearer "+localStorage.getItem('token')
        //       }
        //     }
        // )

        // axios.post(
        //     'http://50.100.168.99:8055/api/leads',
        //     {
        //       params:{
        //         src:"desk",
        //         // page:2,
        //         // limit:6,
        //         // newlead:1 pour obtenir des un nouveau lead
        //       },
        //       headers: { 
        //         'Content-Type': 'application/json',
        //         // 'x-jwt-token': localStorage.getItem('token'),
        //         'auth-id': localStorage.getItem('user_id'),
        //         'Authorization':"Bearer "+localStorage.getItem('token')
        //       }
        //     }
        // )
    }

    toggleLang = () => {
        const lang = this.state.lang === 'fr' ? 'en' : 'fr'
        this.setState({lang: lang});
    }

    genLang = (text) => {
        const {trad} = this.props;
        const {lang} = this.state
        return trad['neoleads'][text][lang]
    }

    // cox
    render() {
        // return(<>hallo</>)
        return (
            <div

                className='hasSideBar'
                style={{
                    // color:"#ededed",
                    // background: 'repeating-linear-gradient(-45deg, #454545, #454545 10px, #242424 10px, #242424 20px)'
                    //  background: 'repeating-linear-gradient(-45deg, #efefef, #efefef 20px, #fefefe 20px, #fefefe 30px)'
                }}

            // className="menu-w color-style-bright menu-position-side menu-side-left menu-layout-mini sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link"
            >
                <Row>
                    <TopMenu
                        active={"leads"}
                        lang={this.state.lang}
                    />
                </Row>

                {/* <Badge 
                    onClick={this.toggleLang}
                    className="top-right"
                    style={{position:'fixed',top:'40px',width:'45px',right:0}}
                    >{this.state.lang === 'fr' ? 'EN' : 'FR'}
                </Badge> */}
                    
                {/* <Row className="topRow">
                    <Col className="centeredNavigation" md={6} sm={12} xs={12} style={{}}>
                        <ButtonGroup>
                            <Button color="primary">
                            Tableau de bord
                            </Button>
                            
                            <Button color="primary">
                                Leads
                            </Button>
                            
                            <Button color="primary">
                                Clients
                            </Button>
                            
                            <Button color="primary">
                                Tâches
                            </Button>
                            
                            <Button color="primary">
                                Calendrier
                            </Button>

                        </ButtonGroup>
                    </Col>
                    <Col sm={12} xs={12} md={6}>
                        <UserIcon />
                    </Col>
                </Row> */}
                {/* test222 */}
                <Row>
                    {/* <Col md={2} sm={2} xs={12} style={{}}>
                       <Widget />
                    </Col> */}
                    <Col md={9} sm={6} xs={12} className="centerApp">
                        <CenterSection lang={this.state.lang} trad={this.props.trad} {...this.props}/>
                    </Col> 
                    <Col 
                        md={3} 
                        sm={4} 
                        xs={12}
                    >
                        <DashboardElement
                            // classNameBody="p-0"
                            // className="p-0 m-b-15"
                            col={12}
                            // height={'400px'}
                            maxHeight={'400px'}
                            // title={'Tâche'}
                            title={this.genLang('tasks')}
                            headerButton={<CalendarIconButton />}
                        >
                            <Tasks title={this.genLang('tasks')}/>
                        </DashboardElement>
                        {/* <Widget /> */}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Lead;