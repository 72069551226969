import React from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import {
  Alert,
  Badge,
  Row,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavLink,
  NavItem,
  FormGroup,
  Input,
  UncontrolledTooltip,
  Button,
  Spinner,
  TabContent,
  TabPane,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faBackward,
  faForward,
  faCalendarAlt,
  faQuestion
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

import moment from "moment";
import "react-quill/dist/quill.snow.css";
import _ from "lodash";
import axios from "axios";
import { API_DOMAIN } from '../../../../../appConfig'; 
import { axiosDefaults } from '../../../../../appConfigaxios'; 

import rootsActions from "../../../../_actions/root-actions";
const QL_CURSOR = /\<span class=["']ql-cursor["']>\s*<\/span>/gm;
const DEFAULT_STYLE = {};
const BACKEND1 = process.env.REACT_APP_API_URLX;

class TimelineLeadTicketRO extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'fr',
      loading: false,
      notes: [],
    };
  }

  componentDidMount() {
    axiosDefaults(null);
    console.log("____RO heeeeeeeeeeeeeee", this.props);
    this.setState({loading: true});
    axios
      .get(BACKEND1+"/leads", {
        params: {
          //src: "desk",
          ticket_id: this.props.id,
          id: "0",
          showtrans: 3,
          leadreadonly: 3
        },
        // headers: {
        //   "Content-Type": "application/json",
        //   // 'x-jwt-token': localStorage.getItem('token'),
        //   "auth-id": localStorage.getItem("user_id"),
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // },
      })
      .then((status) => {
        let { data } = status.data;
        // debugger;
        if (data) {
          console.log("data", data);
          if (data.lead && data.lead.extraction) {
            let obj = {
              ...data.lead.extraction,
              ticket: data.lead.ticket_id,
              leadinfo: {...data.lead},
              leadHistory: data.leadhistory
            };
            if (data.clientinfo !== null) {
              obj.type = 'client'
              obj.transactions = data.clientinfo.transactions;
              obj.clientId = data.clientinfo.client_id;
              obj.zendesk_id = data.clientinfo.client.id_zendesk;
              if (data.clientinfo.address){
                let str = ''
                const {address, city, province} = data.clientinfo.address
                if (address !== null) {str += address};
                if (city !== null) {str += `, ${city}`};
                if (province !== null) {str += `, ${province}`}
                obj.clientAddress = str
              }
            }
            if (data.clientinfo === null && data.contactinfo !== null) {
              obj.type = 'contact'
              obj = {...obj, ...data.contactinfo}
            }  
            else if (!data.clientinfo) obj.transactions = [];

            this.props.setCustomer(obj);
          }
          this.setState({
            ...data,
            loading: false
            // ...JSON.parse(data.definition)
          });
        }
      });
  }

  genLang = (text) => {
    const {trad, lang} = this.props;
    return trad['neoleads'][text][lang]
  }

  render() {
    let notes = this.state.notes || [];
    // console.log(this.props)
    notes.sort(function compare(a, b) {
      var dateA = new Date(a.created_at);
      var dateB = new Date(b.created_at);
      return dateA - dateB;
    });
    if(this.state.redirect){
      return(
          <Redirect to={{pathname: this.state.redirect}} />
      )
    }

    return (
      <>

      <Row>
        <Col md={3}></Col>
          <Col md={6}>
            <h1
              onClick={() => console.log(this.state)}
              style={{ color: "#0052BB", marginTop: '2rem'}}
              className="text-align-center element-header"
            >
              Lead Info
            </h1>
          </Col>
          <Col md={3}></Col>
        </Row>
        <Row>
          <Col md="12">
            {_.map(notes, (e) => {
              return (
                <div
                  className="messageArchiveContainer"
                  key={e.id}
                >
                  <h6>
                    {e.user} -{" "}
                    {moment(e.date_created).format("LLL")}
                  </h6>
                  <div
                    className="messageArchive"
                    dangerouslySetInnerHTML={{ __html: e.note }}
                  />
                </div>
              );
            })}
          </Col>
          {this.state.loading && <><span>{`${this.genLang('loading')} `}</span><Spinner style={{ color: "#0052BB", marginLeft: "5px"}}/></>}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  rootsActions: rootsActions,
  customer: state.customer,
});

const mapActionsToProps = {
  setCustomer: rootsActions.customersActions.setUser,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(TimelineLeadTicketRO));