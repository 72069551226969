import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNeoProspectsSms, getNeoProspectsSms, getNeoProspectsSmsAll, getNeoProspectsSmsGoto, deleteNeoProspectsSms } from '../../actions/NeoProspectsSmsAction'; 
import { sendSMSNote } from '../../actions/ZendLeadsAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "../CustomToolbarSelect";
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 
import Toastr from "../../messages";
import  Loader from "../../loader"; 
import NeoProspectsSmsInfo from "./NeoProspectsSmsInfo"; 
import DialogConfirm from "../../basicdialogconfirm";

class NeoProspectsSmsList extends Component {
	constructor(props) {
		super(props); 
		this.state={
			isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000, 
			openPossibleCDStatusInfo:false, possibleCDStatusInfo:{}, possibleListOfCDStatusInfo:[], 
			dialogConfirmStatus:false,  
		}; 
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 
	}
  
	componentDidMount() { 
		//this.props.getNeoProspectsSmsAll();
		// const { t } = this.props;
		// //this.props.getNeoProspectsSmsAll();
		// this.setState({ ["isLoaderOpen"]: true});  
		// const this2=this; 
		// Promise.resolve(this.props.getNeoProspectsSmsAll())
        // .then((response)=>{ 
		//   this.setState({ ["isLoaderOpen"]: false});
		//   this.showToast(t("zqwCViewEl11_x"), "success");  
        //   return response;
        // }).catch(function(error){ 
        //   this2.setState({ ["isLoaderOpen"]: false});  
        //   window.alert(error);
        // });

		this.loadSMS(); 
	}

	loadSMS = () => {
		//this.props.getNeoProspectsSmsAll();
		const { t } = this.props;
		//this.props.getNeoProspectsSmsAll();
		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		Promise.resolve(this.props.getNeoProspectsSmsAll())
        .then((response)=>{ 
		  this.setState({ ["isLoaderOpen"]: false});
		  this.showToast(t("zqwCViewEl11_x"), "success");  
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          window.alert(error);
        }); 
	}
	
	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info
		const toastrDuration=this.state.toastrDuration; //5500	 
		this.setState({  
			toastrMessage: arg, 
		  toastrType: toastType
		  });
		setTimeout(() => { 
		  this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		  }); 
		}, toastrDuration);
	}
	

	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.NeoProspectsSms;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteNeoProspectsSms(gridIds[0]);

	}

  updateXRecordsMain(selectedRows){  
    const { t } = this.props;
	const objList  = this.props.NeoProspectsSms;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
      //gridIndexes.push(row.index); 
      //idIndex=row.index; 
	  gridIndexes.push(row.dataIndex); //global  
	  idIndex=row.dataIndex; 
	return null;
    });
 
    if(gridIndexes.length>1){
      window.alert(t('zqwgridIndexesfound_x'));
      return false;
    }
 
    let obj=objList[idIndex]; 
	this.props.getNeoProspectsSmsGoto(obj.id); 
	//history.push(`/neoprospectssms/${obj.id}/edit`); 
  }

  handleSubmitProspect = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    // const id = this.state.id;    
	const record=this.state.possibleCDStatusInfo;
	const prospect_id=parseInt(record.id);
	const noticket=record.ticket_id;
	 
	let errorFound=0; 

	if(noticket===null || noticket===""){
		this.showToast("# ticket", "error");  
		errorFound++;
	}

	if(parseInt(noticket)>0){ }else{ 
		this.showToast("# ticket", "error");  
		errorFound++;
	}

	if(prospect_id>0){ }else{ 
		this.showToast("# Id of prospect. Please check one record", "error");  
		errorFound++;
	}

	// if(macroMessage===null || macroMessage==="" || macroMessage==="<p></p>" || macroMessage==="<p></p>\n"){
	// 	this.showToast("Note SMS", "error");  
	// 	errorFound++;
	// }

	// if(phone===null || phone==="" || phone.length <10){
	// 	this.showToast("# telephone", "error");  
	// 	errorFound++;
	// }



	//if(errorFound > 0){ window.alert(t('zqwerrorFoundonform_x')); return false; }

	console.log("SUUUUUBMIT", record);
	if(errorFound > 0){ window.alert(t('zqwerrorFoundonform_x')); return false; }
	 
	//dialogConfirmStatus
	// this.setState({ ["dialogConfirmStatus"]: true,});  
	// this.showToast("untill heeeeeeeeeeeere......", "error");   
	// window.alert("untill heeeeeeeeeeeere......"); return false; 

	// const ZendNoteSMS = {smsNoteTrack: 77, ticket: noticket, email:email, prospect_id:"0", 
	// 		note: macroMessage, phone:phone, external_id:external_id,};

	const ZendNoteSMS = {smsNoteTrack: 77, ticket: noticket, email:"", prospect_id:prospect_id, 
			note: "", phone:"", external_id:0,};

		console.log("SUUUUUBMIT2222 ", ZendNoteSMS); 
    
	 
	const this2=this; 
	this.setState({ ["isLoaderOpen"]: true, ["showSubmitBtn"]: false});  
	Promise.resolve(this.props.sendSMSNote(ZendNoteSMS))
	.then((response)=>{
		//this.setState(response.NeoB2bleadProspectsOne);
		const dataResp = response.data; 
		// this.setState({["openTransactionPopup"]: true, ["isLoaderOpen"]: false }); 
		//this.setState({["isLoaderOpen"]: false, ["showSubmitBtn"]: false, ["showSuccessResp"]: true,}); 
		this.setState({["isLoaderOpen"]: false, ["dialogConfirmStatus"]: true,}); 
		 
		console.log("laaaaaaaaaaast", response, dataResp); 
		return response;
	}).catch(function(error){ 
		// this2.setState({ ["isLoaderOpen"]: false});   
		// this2.showToast(error, "error");  
		const errObj=error;
		this2.setState({ ["isLoaderOpen"]: false, ["showSubmitBtn"]: true});  
		///let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		//let errMessage="Error/Erreur ";
		const statusCode=errObj.statusCode;
		if(errObj.status===undefined && statusCode===undefined){
			errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//this2.saveUpdateTicketDataToZendesks(zendeskList); 
			if (typeof errObj === 'string' || errObj instanceof String){
				console.log("inside undefined");  
				this2.showToast(errObj, "error");  
			}else{
				console.log("inside else undefined");  
				this2.showToast(errMessage, "error");  
			} 
			//this2.showToast(errMessage, "error");  
			console.log("FAKE ERRORS SOLVED MINIMIZED");  
			console.log(errObj);
		}
		else if(statusCode===400){   //else if(errObj.status===500){
			//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//errMessage +="\n 9999999 ".errObj.data.error.description;
			//errMessage +="\n 9999999 ".errObj.error.description;
			let mesx=errObj.error.description;
			if(mesx===undefined){ mesx=""; }
			errMessage ="SERVER ERROR INTERNAL  "+mesx;
			this2.showToast(errMessage, "error");  
			console.log("400 ERROR");  
			console.log(errObj);  
		}else if(statusCode===500){   //else if(errObj.status===500){
			//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//errMessage +="\n 9999999 ".errObj.data.error.description;
			//errMessage +="\n 9999999 ".errObj.error.description;
			let mesx=errObj.error.description;
			if(mesx===undefined){ mesx=""; }
			errMessage ="SERVER ERROR INTERNAL  "+mesx;
			this2.showToast(errMessage, "error");  
			console.log("500 ERROR");  
			console.log(errObj);  
		}else{
			//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
			//this00.showToastError(errMessage);
			//alert(errMessage); //error
			// let mesx=errObj.error.description;
			// if(mesx!==undefined && mesx!==null){ errMessage ="SERVER ERROR  "+mesx; }
			let mesx=""; //errObj.error.description;
			if(errObj.error!==undefined && errObj.error.description!==undefined){ mesx=errObj.error.description; }
			else if(errObj.data.data.errorMessage!==undefined){ mesx=errObj.data.data.errorMessage; }
			if(mesx!==null && mesx!==""){ errMessage ="SERVER ERROR  "+mesx; }
			//errMessage ="SERVER ERROR INTERNAL  "+mesx;
			this2.showToast(errMessage, "error");
			console.log("UNDEFINED ERROR");  
			console.log(errObj);  
		}
	});


  };

  handleChildCmpResponse  = (obj) => { 
	// const obj={dialogConfirmStatus:false, response:true,};   
    ////this.setState({["openPossibleCDStatusInfo"]: false }); 
	if(obj!==undefined && obj!==null && obj.dialogConfirmStatus!==undefined){
		//this.setState({["dialogConfirmStatus"]: obj.dialogConfirmStatus });
		this.setState({["dialogConfirmStatus"]: obj.dialogConfirmStatus,
		["openPossibleCDStatusInfo"]:false, ["possibleCDStatusInfo"]:{} });
		 
		this.loadSMS();  
	}

    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
	// if(obj!==undefined && obj!==null && obj.response!==undefined){
	// 	//this.setState({["response"]: obj.response });
	// 	if(obj.response===true){
	// 		console.log("helloooooooooooooooooooooooo handleChildCmpResponse ");
	// 		const selectedRows=this.gridSelectedRow;
	// 		const indexName=this.gridSelectedAction; 
	// 		this.updateXRecordsMainsub(selectedRows, indexName);
	// 	} 
	// }

  }
  

  render() { 
    const { t } = this.props;
	const columns = [
	 {
	  name: "id", label: t('zqwid_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
		name: "cell", label: t('zqwcell_x'),
		options: { filter: true, sort: true,}
	},
	//  {
	//   name: "message", label: t('zqwmessage_x'),
	//   options: { filter: true, sort: true,}
	//  },
	 {
	  name: "ticket_id", label: t('zqwticket_id_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "client_idtxt", label: t('zqwclient_id_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "email", label: t('zqwemail_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "user_creationtxt", label: t('zqwuser_creation_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "user_updatetxt", label: t('zqwuser_update_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "creation_date", label: t('zqwcreation_date_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "update_date", label: t('zqwupdate_date_x'),
	  options: { filter: true, sort: true,}
	 },
	//  {
	//   name: "status", label: t('zqwstatus_x'),
	//   options: { filter: true, sort: true,}
	//  },
	];
const textLabelOptions= {
    body: {
      noMatch: t('zqwgridnoMatch_x'),
      toolTip: t('zqwgridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('zqwgridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('zqwgridnext_x'),
      previous: t('zqwgridprevious_x'),
      rowsPerPage: t('zqwgridrowsPerPage_x'),
      displayRows: t('zqwgriddisplayRows_x'),
    },
    toolbar: {
      search: t('zqwgridsearch_x'),
      downloadCsv: t('zqwgriddownloadCsv_x'),
      print: t('zqwgridprint_x'),
      viewColumns: t('zqwgridviewColumns_x'),
      filterTable: t('zqwgridfilterTable_x'),
    },
    filter: {
      all: t('zqwgridall_x'),
      title: t('zqwgridftitle_x'),
      reset: t('zqwgridreset_x'),
    },
    viewColumns: {
      title: t('zqwgridvctitle_x'),
      titleAria: t('zqwgridvctitleAria_x'),
    }, 
  };	
			
	const options = {
	  filterType: 'checkbox',
	  textLabels: textLabelOptions,
	  onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		//console.log(currentRowsSelected, allRowsSelected);
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log(allRowsSelected);
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
		//openPossibleCDStatusInfo:false, possibleCDStatusInfo:{}
		const record=this.props.NeoProspectsSms[rowState.dataIndex]; //let obj=objList[idIndex]; 
		this.setState({["openPossibleCDStatusInfo"]: true, ["possibleCDStatusInfo"]: record,});
		console.log("cououuuuuuuuuuuuu", record);
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} isEdit={true} isDelete={true} />
      ),
	};  
	
	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType;
	const CheckOrSetActionsOnInvoice=this.CheckOrSetActionsOnInvoice;

	// if(){
	// 	const prospectsSms=
	// 	NeoProspectsSmsInfo
	// 	return (
	// 		<NeoProspectsSmsInfo  prospectsSms={prospectsSms}  />
	// 	);
	// }
	const confirmStatus=this.state.dialogConfirmStatus;
	const dialMessage=t('zqwFlowvtsmsx8_x');
	  
	  
    if(this.props.NeoProspectsSms.length) {                                
      return (
        <div>
          <h4>{t('zqwNeoProspectsSms_x')}</h4>		  
		  <MUIDataTable
			  title={""}
			  data={this.props.NeoProspectsSms}
			  columns={columns}
			  options={options}
			/>
			<CheckOrSetActionsOnInvoice  />
			<Loader  openx={this.state.isLoaderOpen}  />
			<Toastr messagex={myToastMessage} open={true} toastType={toastrType} /> 
			<DialogConfirm message={dialMessage} dialogAlertOnly={true} dialogConfirmStatus={confirmStatus} parentFuncStatus={this.handleChildCmpResponse} /> 
        </div>
      )    
    } else {
      return (<div> 
		  <h4>{t('zqwNeoProspectsSms_x')}</h4><br/>		  
		  {t('zqwgridNoRecordfound_x')} 
	  	  <Loader  openx={this.state.isLoaderOpen}  />
	  </div>)
    }
  }


  CheckOrSetActionsOnInvoice = (props) => {  
	const open = this.state.openPossibleCDStatusInfo; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
	const { t } = this.props;
	//const { t, i18n } = useTranslation("common");
	const listObj=this.state.possibleListOfCDStatusInfo;
	const record=this.state.possibleCDStatusInfo;
	//this.setState({["openPossibleCDStatusInfo"]: true, ["possibleCDStatusInfo"]: record,});
	const info_otheresp=this.state.info_otheresp; 
	let maxWidth="sm"; //"sm";
	let isfullScreen=false;
	// if(info_otheresp!==null && info_otheresp!=="" && this.actiongo==="viewemailinvoice"){
	// 	maxWidth="lg";
	// 	isfullScreen=true;
	// 	//sessionStorage.setItem("tmpcontent", info_otheresp);
	// }
	maxWidth="lg";
	//isfullScreen=true;
   
	return (
	  <div>
		<Dialog maxWidth={maxWidth} open={open} onClose={this.handleCloseListOfCDStatusInfo} disableBackdropClick disableEscapeKeyDown fullScreen={isfullScreen} aria-labelledby="form-dialog-confirmclient4">
		  <DialogContent id="form-dialog-confirmclient4">
			
		  {/* <div className="text-center"><h2>{t("zqwCViewEl16_x")}</h2></div> 
			<p>{t("zqwCViewEl17_x")} # zzzzzzzzzzzzz </p> 
			<p> &nbsp; </p>  */}

			{/* <p>{this.state.chargeDeskIdStr} </p>  */}
			
			<NeoProspectsSmsInfo  prospectsSms={record}  />

			{/* <p> &nbsp; </p> */}
			<div className="form-row neoxtbl">

			  <div className="form-group col-md-9">
			  <br/>
			  <input type="button" onClick={this.handleCloseListOfCDStatusInfo} className="btn btn-default" value={t("zqwNVFrmbtn_x")} /> &nbsp; &nbsp; 
			  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
			  <input type="button" onClick={this.handleSubmitProspect} className="btn btn-danger" value={t("zqwsendsmsr_x")} /> &nbsp; &nbsp; 
		   	  </div> 
			</div>
			  
		  </DialogContent>
		  
		</Dialog>
	  </div>
	);
  }

  handleCloseListOfCDStatusInfo  = (anyx) => {   
	this.setState({["openPossibleCDStatusInfo"]: false }); 
	//this.showToast("Premiere etape, veuillez patieter un instant", "success");   
  }
}


const mapStateToProps = (state) => ({ NeoProspectsSms: state.NeoProspectsSms });  
const mapDispatchToProps = { addOrUpdateNeoProspectsSms, getNeoProspectsSms, getNeoProspectsSmsAll, getNeoProspectsSmsGoto, deleteNeoProspectsSms, sendSMSNote };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoProspectsSmsList));
