const messagesFr={
"curlang": "fr",
"welcome_x": "Bienvenue",
"hi_x": "Salut",
"Create_x": "Créer",
"New_x": "Nouveau",
"Edit_x": "Modifier",
"Update_x": "Mettre à jour",
"Delete_x": "Supprimer",
"Cancel_x": "Annuler",
"Cancel": "Annuler",
"Close_x": "Fermer", 
"Create": "Créer",
"view_x": "Voir",
"send_x": "Envoyer",
"viewlist_x": "Voir Liste",
"viewstat_x": "Voir Statut",

"Yes_x": "Oui",
"No_x": "Non",
"Yes": "Oui",
"No": "Non",
"orr_x": "Ou",
"orr2_x": "ou",
"Cancellead": "Annuler lead",
"Closel_x": "Finaliser lead", 

"uname_x": "E-mail",
"upass_x": "Mot de passe",
"tipoUsuario_x": "Type d'utilisateur",
"Seleccionar_x": "Sélectionner",
"pleaseSel_x": "Veuillez sélectionner",
"Cliente_x": "Client",
"Operador_x": "Opérateur",
"Administrativo_x": "Administratif",
"idioma_x": "Langue",
"Recuerdame_x": "Souviens-toi de moi",
"login_x": "Connexion",
"recoverpass_x": "J'ai oublié mon mot de passe",
"resetpass_x": "Réinitialiser le mot de passe utilisateur",
"qemail_x": "E-mail",
"btnresetpass_x": "Réinitialiser le mot de passe",
"btnCancelar_x": "Annuler",

"id_x": "Id",
"name_x": "Nom",
"notes_x": "Notes",
"errorFoundonform_x": "vous avez des erreurs sur le formulaire.",
"acAddAssetTypes_x": "Ajouter des types d'actifs",
"acEditAssetTypes_x": "Modifier les AssetTypes",

"gridIndexesfound_x": "Désolé, vous devez cocher un seul enregistrement pour pouvoir le modifier.",
"gridnoMatch_x": "Désolé, aucun enregistrement correspondant trouvé",
"gridtoolTiph_x": "Trier",
"gridSortfor_x": "Trier pour",
"gridnext_x": "Page suivante",
"gridprevious_x": "Page précédente",
"gridrowsPerPage_x": "Lignes par page:",
"griddisplayRows_x": "de",
"gridsearch_x": "Rechercher",
"griddownloadCsv_x": "Télécharger CSV",
"gridprint_x": "Imprimer",
"gridviewColumns_x": "Afficher les colonnes",
"gridfilterTable_x": "Table des filtres",
"gridall_x": "Tous",
"gridftitle_x": "FILTRES",
"gridreset_x": "RESET",
"gridvctitle_x": "Afficher les colonnes",
"gridvctitleAria_x": "Afficher / masquer les colonnes du tableau",
"AssetTypes_x": "Assettypes",
"gridNoRecordfound_x": "Aucun enregistrement à afficher",
 

"menuhome_x": "Accueil",
"menudashboard_x": "Tableau",
"menuzonlineusuarios_x": "Profil utilisateur",
"menuvwdespachosalerts_x": "Expéditions",
"menuvwfacturas_x": "Toutes les factures",
"menuvwfacturasnopagadas_x": "Factures impayées",
"menuvwfacturaspagadas_x": "Factures payées",
"menuvwpagos_x": "Paiements effectués",
"menuid_x": "Id",
"ZxCompanies_x": "Liste des sociétés / succursales",
"togoutcnfti_x": "Cher utilisateur",
"logoutconfir_x": "Êtes-vous sûr de vouloir déconnecter le système",
"changeLang_x": "Changer de langue",














"zqwid_x": "Id",
"zqwkey_x": "Clé",
"zqwall_access_x": "Tous les accès",
"zqwcontroller_x": "Contrôleur",
"zqwdate_created_x": "Date de création",
"zqwdate_modified_x": "Date de modification",
"zqwerrorFoundonform_x": "vous avez des erreurs sur le formulaire.",
"zqwacAddAccess_x": "Ajouter un accès",
"zqwacEditAccess_x": "Modifier l'accès",
"zqwgridIndexesfound_x": "Désolé, vous ne devez cocher qu'un seul enregistrement pour pouvoir le modifier.",
"zqwgridnoMatch_x": "Désolé, aucun enregistrement correspondant n'a été trouvé",
"zqwgridtoolTiph_x": "Trier",
"zqwgridSortfor_x": "Trier pour",
"zqwgridnext_x": "Page suivante",
"zqwgridprevious_x": "Page précédente",
"zqwgridrowsPerPage_x": "Lignes par page:",
"zqwgriddisplayRows_x": "sur",
"zqwgridsearch_x": "Rechercher",
"zqwgriddownloadCsv_x": "Télécharger CSV",
"zqwgridprint_x": "Imprimer",
"zqwgridviewColumns_x": "Afficher les colonnes",
"zqwgridfilterTable_x": "Filtre de la table",
"zqwgridall_x": "Tout",
"zqwgridftitle_x": "FILTRES",
"zqwgridreset_x": "RESET",
"zqwgridvctitle_x": "Afficher les colonnes",
"zqwgridvctitleAria_x": "Afficher / Masquer les colonnes du tableau",
"zqwAccess_x": "Accès",
"zqwgridNoRecordfound_x": "Aucun enregistrement trouvé à afficher",
"zqwuser_id_x": "ID utilisateur",
"zqwlevel_x": "Niveau",
"zqwignore_limits_x": "Ignorer les limites",
"zqwis_private_key_x": "Est une clé privée",
"zqwip_addresses_x": "Adresses IP",
"zqwacAddKeys_x": "Ajouter des clés",
"zqwacEditKeys_x": "Modifier les clés",
"zqwKeys_x": "Clés",
"zqwuri_x": "Uri",
"zqwmethod_x": "Méthode",
"zqwparams_x": "Paramètres",
"zqwapi_key_x": "Clé Api",
"zqwip_address_x": "Adresse IP",
"zqwtime_x": "Heure",
"zqwrtime_x": "Rtime",
"zqwauthorized_x": "Autorisé",
"zqwresponse_code_x": "Code de réponse",
"zqwacAddLogs_x": "Ajouter des journaux",
"zqwacEditLogs_x": "Modifier les journaux",
"zqwLogs_x": "Journaux",
"zqwid_clients_x": "Clients Id",
"zqwaddress_x": "Adresse",
"zqwcity_x": "Ville",
"zqwprovince_x": "Province",
"zqwcp_x": "Cp",
"zqwcountry_x": "Pays",
"zqwcreated_x": "Créé",
"zqwactive_x": "Actif",
"zqwtel_x": "Tel",
"zqwext_x": "Ext",
"zqwcompany_x": "Entreprise",
"zqwname_x": "Nom",
"zqwtype_x": "Type",
"zqwinvoicing_x": "Facturation",
"zqwdelivery_x": "Livraison",
"zqwacAddNeoAddress_x": "Ajouter NeoAddress",
"zqwacEditNeoAddress_x": "Modifier NeoAddress",
"zqwNeoAddress_x": "Neoaddress",
"zqwid_campaign_x": "Campagne d'identification",
"zqwraw_data_x": "Données brutes",
"zqwacAddNeoCampaign_x": "Ajouter NeoCampaign",
"zqwacEditNeoCampaign_x": "Modifier NeoCampaign",
"zqwNeoCampaign_x": "Neocampaign",
"zqwid_folders_x": "Dossiers d'ID",
"zqwfirstname_x": "Prénom",
"zqwlastname_x": "Nom",
"zqwcell_x": "Cellulaire",
"zqwemail_x": "E-mail",
"zqwns_x": "Ns",
"zqwcase_x": "Cas",
"zqwstatus_x": "Statut",
"zqwclosed_x": "Fermé",
"zqwpending_x": "En attente",
"zqwinvalid_x": "Invalide",
"zqwopened_x": "Ouvert",
"zqwid_transactions_x": "Id transactions",
"zqwlanguage_x": "Langue",
"zqwacAddNeoCases_x": "Ajouter des NeoCases",
"zqwacEditNeoCases_x": "Modifier les NeoCases",
"zqwNeoCases_x": "Neocases",
"zqwterms_x": "Conditions",
"zqwid_users_x": "Identifiant des utilisateurs",
"zqwpush_x": "Pousser",
"zqwid_zendesk_x": "Id Zendesk",
"zqwsms_x": "Sms",
"zqwstripe_id_x": "Identifiant de bande",
"zqwacAddNeoClients_x": "Ajouter des NeoClients",
"zqwacEditNeoClients_x": "Modifier les NeoClients",
"zqwNeoClients_x": "Néoclients",
"zqwdefinition_x": "Définition",
"zqwticket_id_x": "ID du ticket",
"zqwclient_id_x": "ID client",
"zqwdelay_x": "Retard",
"zqwdone_x": "Terminé",
"zqwdate_executed_x": "Date d'exécution",
"zqwresult_x": "Résultat",
"zqwticket_type_x": "Type de ticket",
"zqwdeskapp_x": "Deskapp",
"zqwdesk_id_x": "Id bureau",
"zqwguid_x": "Guid",



"zqwacAddNeoDesk_x": "Ajouter NeoDesk",
"zqwacEditNeoDesk_x": "Modifier NeoDesk",
"zqwNeoDesk_x": "Neodesk",
"zqwid_notes_x": "Notes d'identification",
"zqwfile_location_x": "Emplacement du fichier",
"zqwfile_name_x": "Nom du fichier",
"zqwfile_extension_x": "Extension de fichier",
"zqwfile_html_x": "Fichier Html",
"zqwis_file_x": "Est un fichier",
"zqwmodified_x": "Modifié",
"zqwmetas_x": "Métas",
"zqworg_name_x": "Nom de l'organisation",
"zqwuniqid_x": "Uniqid",
"zqwacAddNeoDocuments_x": "Ajouter des NeoDocuments",
"zqwacEditNeoDocuments_x": "Modifier les NeoDocuments",
"zqwNeoDocuments_x": "Neodocuments",
"zqwacAddNeoDocuments1_x": "Ajouter NeoDocuments1",
"zqwacEditNeoDocuments1_x": "Modifier NeoDocuments1",
"zqwNeoDocuments1_x": "Neodocuments1",
"zqwid_event_x": "Id événement",
"zqwevent_start_x": "Début de l'événement",
"zqwevent_end_x": "Fin de l'événement",
"zqwduration_x": "Durée",
"zqwevent_tz_x": "Event Tz",
"zqwuser_x": "Utilisateur",
"zqwservice_id_x": "ID de service",
"zqwacAddNeoEvents_x": "Ajouter des NeoEvents",
"zqwacEditNeoEvents_x": "Modifier les NeoEvents",
"zqwNeoEvents_x": "Neoevents",
"zqwacAddNeoFolders_x": "Ajouter des NeoFolders",
"zqwacEditNeoFolders_x": "Modifier les NeoFolders",
"zqwNeoFolders_x": "Neofolders",
"zqwpayed_x": "Payé",
"zqwsent_x": "Envoyé",
"zqwoverdue_x": "En retard",
"zqwview_x": "Afficher",
"zqwdeclined_x": "Refusé",
"zqwrefund_x": "Remboursement",
"zqwdue_date_x": "Date de Vente",
"zqwsub_total_x": "Sous-total",
"zqwtax1_x": "Tax1",
"zqwtax2_x": "Taxe2",
"zqwtoken_x": "Jeton",
"zqwcode_x": "Code",
"zqwdeclined_code_x": "Code refusé",
"zqwtransact_x": "Transact",
"zqwtax_total_x": "Total des taxes",
"zqwtotal_x": "Total",
"zqwid_address_x": "Adresse Id",
"zqwacAddNeoInvoices_x": "Ajouter des NeoInvoices",
"zqwacEditNeoInvoices_x": "Modifier les NeoInvoices",
"zqwNeoInvoices_x": "Neoinvoices",
"zqwacAddNeoLawyer_x": "Ajouter NeoLawyer",
"zqwacEditNeoLawyer_x": "Modifier NeoLawyer",
"zqwNeoLawyer_x": "Neolawyer",
"zqwacAddNeoMailHook_x": "Ajouter NeoMailHook",
"zqwacEditNeoMailHook_x": "Modifier NeoMailHook",
"zqwNeoMailHook_x": "Neomailhook",
"zqwid_transaction_x": "Transaction d'ID",
"zqwid_user_x": "ID utilisateur",
"zqwacAddNeoMed_x": "Ajouter NeoMed",
"zqwacEditNeoMed_x": "Modifier NeoMed",
"zqwNeoMed_x": "Neomed",
"zqwnote_x": "Remarque",
"zqwpublic_x": "Public",
"zqwid_lawyer_x": "Id Lawyer",
"zqwread_x": "Lire",
"zqwacAddNeoNotes_x": "Ajouter des NeoNotes",
"zqwacEditNeoNotes_x": "Modifier les NeoNotes",
"zqwNeoNotes_x": "Néonotes",
"zqwid_invoices_x": "Id Invoices",
"zqwamount_x": "Montant",
"zqwacAddNeoPayments_x": "Ajouter des NeoPayments",
"zqwacEditNeoPayments_x": "Modifier NeoPayments",
"zqwNeoPayments_x": "Neopayments",
"zqwtransdata_x": "Transdata",
"zqwsignature_x": "Signature",
"zqwidTrans_x": "Idtrans",
"zqwsource_x": "Source",
"zqwsource2_x": "Problématique",
"zqwacAddNeoPaymentsHook_x": "Ajouter NeoPaymentsHook",
"zqwacEditNeoPaymentsHook_x": "Modifier NeoPaymentsHook",
"zqwNeoPaymentsHook_x": "Neopaymentshook",
"zqwdescription_x": "Description",
"zqwdata_json_x": "Data Json",
"zqwacAddNeoProducts_x": "Ajouter des NeoProducts",
"zqwacEditNeoProducts_x": "Modifier NeoProducts",
"zqwNeoProducts_x": "Néoproduits",
"zqwiso_x": "Iso",
"zqwtax1_name_x": "Nom Tax1",
"zqwtax2_name_x": "Nom Tax2",
"zqwacAddNeoProvinces_x": "Ajouter des NeoProvinces",
"zqwacEditNeoProvinces_x": "Modifier les NeoProvinces",
"zqwNeoProvinces_x": "Neoprovinces",
"zqwclientemail_x": "Clientemail",
"zqwreferalname_x": "Nom de référence",
"zqwreferalemail_x": "Referalemail",
"zqwadw_id_x": "Adw Id",
"zqwclientvoucher_x": "Clientvoucher",
"zqwreferalvoucher_x": "Referalvoucher",
"zqwclientused_x": "Clientused",
"zqwreferalused_x": "Referalused",
"zqwclientdateused_x": "Clientdateused",
"zqwreferaldateused_x": "Referaldateused",
"zqwclientidtrans_x": "Clientidtrans",
"zqwreferalidtrans_x": "Referalidtrans",
"zqwacAddNeoReferal_x": "Ajouter NeoReferal",
"zqwacEditNeoReferal_x": "Modifier NeoReferal",
"zqwNeoReferal_x": "Neoreferal",
"zqwdate_x": "Date",
"zqwcommission_x": "Commission",
"zqwidstripe_x": "Idstripe",
"zqwapplication_x": "Application",
"zqwcustomer_id_x": "ID client",
"zqwtaxable_x": "Taxable",
"zqworphelin_x": "Orphelin",
"zqwnom_x": "Nom",
"zqwprenom_x": "Prénom",
"zqwidclient_x": "Id Client",
"zqwid_produit_x": "Id Produit",
"zqwproduit_x": "Produit",
"zqwis_business_x": "C'est une affaire",
"zqwclient_created_x": "Client créé",
"zqwacAddNeoRevenus_x": "Ajouter des NeoRevenus",
"zqwacEditNeoRevenus_x": "Modifier les NeoRevenus",
"zqwNeoRevenus_x": "Neorevenus",
"zqwcreated_campaign_x": "Campagne créée",
"zqwcreated_clients_x": "Clients créés",
"zqwacAddNeoSalesCampaign_x": "Ajouter NeoSalesCampaign",
"zqwacEditNeoSalesCampaign_x": "Modifier NeoSalesCampaign",
"zqwNeoSalesCampaign_x": "Neosalescampaign",
"zqwcategory_x": "Catégorie",
"zqwid_product_x": "Id produit",
"zqwcat_id_x": "Cat Id",
"zqwacAddNeoServices_x": "Ajouter des NeoServices",
"zqwacEditNeoServices_x": "Modifier les NeoServices",
"zqwNeoServices_x": "Neoservices",
"zqwacAddNeoSrfaxHook_x": "Ajouter NeoSrfaxHook",
"zqwacEditNeoSrfaxHook_x": "Modifier NeoSrfaxHook",
"zqwNeoSrfaxHook_x": "Neosrfaxhook",
"zqwcurrency_x": "Devise",
"zqwtype_id_x": "Type Id",
"zqwacAddNeoTarifs_x": "Ajouter des NeoTarifs",
"zqwacEditNeoTarifs_x": "Modifier les NeoTarifs",
"zqwNeoTarifs_x": "Neotarifs",




"zqwacAddNeoTarifsUsers_x": "Ajouter des NeoTarifsUsers",
"zqwacEditNeoTarifsUsers_x": "Modifier NeoTarifsUsers",
"zqwNeoTarifsUsers_x": "Neotarifsusers",
"zqwacAddNeoTaskdef_x": "Ajouter NeoTaskdef",
"zqwacEditNeoTaskdef_x": "Modifier NeoTaskdef",
"zqwNeoTaskdef_x": "Neotaskdef",
"zqwacAddNeoTrackingHook_x": "Ajouter NeoTrackingHook",
"zqwacEditNeoTrackingHook_x": "Modifier NeoTrackingHook",
"zqwNeoTrackingHook_x": "Neotrackinghook",
"zqwid_products_x": "Id produits",
"zqwid_vouchers_x": "Bons d'identité",
"zqwpreachat_x": "Preachat",
"zqwacAddNeoTransactions_x": "Ajouter NeoTransactions",
"zqwacEditNeoTransactions_x": "Editer NeoTransactions",
"zqwNeoTransactions_x": "Neotransactions",
"zqwusername_x": "Nom d'utilisateur",
"zqwpassword_x": "Mot de passe",
"zqwadmin_x": "Admin",
"zqwsupervisor_x": "Superviseur",
"zqwlawyer_x": "Avocat",
"zqwagent_x": "Agent",
"zqwlsupervisor_x": "Lsupervisor",
"zqwcustomer_x": "Client",
"zqwmarketing_x": "Marketing",
"zqwsales_x": "Ventes",
"zqwlegalintern_x": "Legalintern",
"zqwelawyer_x": "Elawyer",
"zqwlast_login_x": "Dernière connexion",
"zqwacAddNeoUsers_x": "Ajouter des NeoUsers",
"zqwacEditNeoUsers_x": "Modifier les NeoUsers",
"zqwNeoUsers_x": "Neousers",
"zqwacAddNeoVentes_x": "Ventes",
"zqwacEditNeoVentes_x": "Modifier NeoVentes",
"zqwNeoVentes_x": "Ventes",
"zqwpercentage_x": "Pourcentage",
"zqwvalid_start_x": "Début valide",
"zqwvalid_end_x": "Fin valide",
"zqwusage_x": "Utilisation",
"zqwacAddNeoVouchers_x": "Ajouter des NeoVouchers",
"zqwacEditNeoVouchers_x": "Modifier les NeoVouchers",
"zqwNeoVouchers_x": "Neovouchers",
"zqwCreation_Date_x": "Date de création",
"zqwDescription_x": "Description",
"zqwAmount_x": "Montant",
"zqwComission_x": "Commission",
"zqwID_x": "Id",
"zqwacAddStripeReport_x": "Ajouter un rapport Stripe",
"zqwacEditStripeReport_x": "Modifier le rapport Stripe",
"zqwStripeReport_x": "Rapport Stripe",



"zqwweek_plan_id_x": "Id du plan hebdomadaire",
"zqwwork_date_x": "Date de travail",
"zqwexpected_quantity_x": "Quantité attendue",
"zqwexpected_amount_x": "Montant attendu",
"zqwinvoice_quantity_x": "Quantité de facture",
"zqwinvoice_amount_x": "Montant de la facture",
"zqwpayment_quantity_x": "Quantité de paiement",
"zqwpayment_amount_x": "Montant du paiement",
"zqwacAddNeoSalesPlanningDaily_x": "Ajouter NeoSalesPlanningDaily",
"zqwacEditNeoSalesPlanningDaily_x": "Modifier NeoSalesPlanningDaily",
"zqwNeoSalesPlanningDaily_x": "Neosalesplanningdaily",
"zqwweek_x": "Semaine",
"zqwweek_start_x": "Début de la semaine",
"zqwweek_end_x": "Week-end",
"zqwacAddNeoSalesPlanningWeekly_x": "Ajouter Planification de Vente Hebdomadaire",
"zqwacEditNeoSalesPlanningWeekly_x": "Modifier Planification  de Vente Hebdomadaire",
"zqwNeoSalesPlanningWeekly_x": "Planifications Hebdomadaires",

"zqwtotal_recurrent_x": "Total récurrent",
"zqwround_recurrent_x": "Cycle récurrent",
"zqwday_recurrent_x": "Récurrent de jour",
"zqwfrom_date_x": "Date de début",
"zqwpayment_type_x": "Type de paiement",
"zqwamount_x": "Montant",
"zqwnotes_x": "Notes",
"zqwlast_update_x": "Dernière mise à jour",
"zqwacAddNeoInvoicesRecurrent_x": "Ajouter NeoInvoices Recurrent",
"zqwacEditNeoInvoicesRecurrent_x": "Modifier NeoInvoices Recurrent",
"zqwNeoInvoicesRecurrent_x": "Neoinvoices recurrent",
"zqwuser_creation_x": "Création d'utilisateurs",
"zqwuser_update_x": "Mise à jour de l'utilisateur",
"zqwcreation_date_x": "Date de création",
"zqwupdate_date_x": "Date de mise à jour",
"zqwtotalPayment_x": "Total du paiement",

"zqwoccupation_x": "Occupation",
"zqwmenuel1_x": "Faire une Vente", 
"zqwmenuel2_x": "Liste de Vente", 
"zqwmenuel3_x": "Liste de Vente Upsale", 
"zqwmenuel4_x": "Liste de Vente en Attente", 
"zqwmenuel5_x": "Liste de Vente B2B", 
"zqwmenuel6_x": "Toutes les Transactions", 
"zqwmenuel7_x": "Planification de Vente", 
"zqwmenuel8_x": "Rapport de Vente", 
"zqwmenuel9_x": "Sortir", 
"zqwmenuel10_x": "Profil", 
"zqwmenuel11_x": "Liste de Lead Zendesk", 
"zqwmenuel12_x": "semaine", 
"zqwBHElem1_x": "Panier moyen",
"zqwBHElem2_x": "Attente en quantité",
"zqwBHElem3_x": "Quantité de vente réalisée",
"zqwBHElem4_x": "Quantité en pourcentage",
"zqwBHElem5_x": "Obectif du jour en date",
"zqwBHElem6_x": "Objectif de Vente du jour",
"zqwBHElem7_x": "Total de vente hebdomadaire",
"zqwBHElem8_x": "Totale vente d'aujourd'hui",
"zqwBHElem9_x": "Obectif hebdomadaire en date",
"zqwBHElem10_x": "Attente en montant",
"zqwBHElem11_x": "Montant de vente réalisée",
"zqwBHElem12_x": "Veuillez vous authentifier pour changer votre mot de passe",
"zqwBHElem13_x": "Vous avez été déconnecté du système avec succès!",
"zqwBHElem14_x": "On sort...",
"zqwBHElem15_x": "interac/chargedesk/flexiti",
"zqwBHElem16_x": "wwwwwwww",
"zqwBHElem17_x": "wwwwwwww",
"zqwBHElem18_x": "wwwwwwww",
"zqwBHElem19_x": "wwwwwwww",

"zqwNVFrmEl1_x": "Attention!!! Vous êtes entrain de changer des données pour un client existant",
"zqwNVFrmEl2_x": "L'email {{emailneo}} de ce client n'existe pas tu peux continuer.",
"zqwNVFrmEl3_x": "Erreur!!! On ne peut pas avoir un montant supérieur a ",
"zqwNVFrmEl4_x": "Vous devez juste fermer la vente et créer le ticket si c'est le cas. ",
"zqwNVFrmEl5_x": "Attention!!! en changeant la province on applique les taxes de cette province ou territoire.",
"zqwNVFrmEl6_x": "États-Unis",
"zqwNVFrmEl7_x": "Erreur! Vous devez choisir un produit au moins",
"zqwNVFrmEl8_x": "Erreur! Pour continuer avec la transaction, le montant doit être superieur à 0 !",
"zqwNVFrmEl9_x": "Erreur! Pour continuer il faut avoir au moins un produit valide !",
"zqwNVFrmEl10_x": "Vous avez des erreurs dans le formulaire, veuillez de vérifier avant d'envoyer.",
"zqwNVFrmEl11_x": "Erreur! Pour upsell tu dois choisir un ticket éxistant pour l'attacher!",
"zqwNVFrmEl12_x": "Erreur! Pour le statut d'amérindien tu dois insérer le numéro de la carte ameridienne!",
"zqwNVFrmEl13_x": "Félicitations!!! On a finit votre transaction avec succès!",
"zqwNVFrmEl13b_x": "Félicitations!!! On a envoyé votre demande de paiement avec succès!",
"zqwNVFrmEl14_x": "Une erreur s'est produite dans les données attendues du serveur. Veuillez réessayer plus tard.",
"zqwNVFrmEl15_x": "Erreur! Pour utiliser Charge Desk, la Façon de Payer doit être 'Payer Tout le Montant' !",
"zqwNVFrmEl16_x": "Désolé, on ne processe pas plus qu'une fois en moins d'une minute. Svp essayez encore dans {{timerest}} secondes ",
"zqwNVFrmEl17_x": "Erreur! Pour utiliser Interac, la Façon de Payer doit être 'Payer Tout le Montant' !",
"zqwNVFrmEl18_x": "Erreur! Désolé ce client a aucune une carte éxistante!",
"zqwNVFrmEl19_x": "Erreur! Pour utiliser carte éxistante, la Façon de Payer doit être 'Payer Tout le Montant' !",
"zqwNVFrmEl20_x": "Vous devez choisir au moins un produit, il n'y a aucun produit dans votre panier.",
"zqwNVFrmEl21_x": "Quelques champs sont obligatoires, veuillez les remplir",
"zqwNVFrmEl22_x": "Attention! Vous avez déjà appliqué un paiement, donc il n'y a pas de raison d'aller en arrière.",
"zqwNVFrmEl23_x": "Erreur! Pour finaliser la transaction vous devez payer tout le montant!",
"zqwNVFrmEl24_x": "Erreur! Pour finaliser la transaction vous devez avoir une facture éxistante au préalable!",
"zqwNVFrmEl25_x": "Félicitations!!! On a fermé votre transaction avec succès!",
"zqwNVFrmEl26_x": "Désolé!!! On n'a pu fermer votre transaction malheureusement!",
"zqwNVFrmEl27_x": "Avant de fermer assurez-vous que le client à déjà payé et que c'est juste une erruer de chage Desk. \nÊtes-vous sur de continuer?",
"zqwNVFrmEl28_x": "Produit ajouté",
"zqwNVFrmEl29_x": "Erreur désolé la quantide de page maximum est {{maximum}} pas {{selPage}}",
"zqwNVFrmEl30_x": "Veuillez d'inserer le Code Promo si vou voulez appliquer un rabais.",
"zqwNVFrmEl31_x": "Désolé, on ne processe pas une carte plus qu'une fois en moins d'une minute. Svp essayez encore.",
"zqwNVFrmEl32_x": "Premiere etape, veuillez patienter un instant",
"zqwNVFrmEl33_x": "Nous ne trouvons pas l'identifiant de charge de Charge Desk",
"zqwNVFrmEl34_x": "email client",
"zqwNVFrmEl35_x": "Montant",
"zqwNVFrmEl36_x": "Description",
"zqwNVFrmEl37_x": "Déjà Payé",
"zqwNVFrmEl38_x": "Statuts",
"zqwNVFrmEl39_x": "Montant Remboursé",
"zqwNVFrmEl40_x": "Passerelle",
"zqwoui_x": "Oui",
"zqwnon_x": "Non",
"zqwNVFrmEl41_x": "Nom du Produit",
"zqwNVFrmEl42_x": "Nouveau Client",
"zqwNVFrmEl43_x": "Nom du Client",
"zqwNVFrmEl44_x": "Vérifier Statut de Paiement ChargeDesk",
"zqwNVFrmEl45_x": "Nettoyer et Recommencer tout",
"zqwNVFrmEl46_x": "Garantie Zéro Stress de Neolegal",
"zqwNVFrmEl47_x": "Methode de Paiement du Client",
"zqwNVFrmEl48_x": "Résumé de votre commande",
"zqwNVFrmEl49_x": "Code Promo",
"zqwNVFrmEl50_x": "facultatif",
"zqwNVFrmEl51_x": "Valider Code",
"zqwNVFrmEl52_x": "Promos",
"zqwNVFrmEl52b_x": "Promo/reference",
"zqwNVFrmEl53_x": "Choisir une promotion...",
"zqwNVFrmEl54_x": "Statut Amérindien",
"zqwNVFrmEl55_x": "Ajouter à un ticket Existant",
"zqwNVFrmEl56_x": "Choisir",
"zqwNVFrmEl57_x": "Sous-total",
"zqwNVFrmEl58_x": "Rabais",
"zqwNVFrmEl59_x": "Total avant Paiement",
"zqwNVFrmEl60_x": "Paiements Réalisés",
"zqwNVFrmEl61_x": "Total",
"zqwNVFrmEl62_x": "Organisation du Paiement",
"zqwNVFrmEl63_x": "Façon de Payer",
"zqwNVFrmEl64_x": "Payer Tout le montant",
"zqwNVFrmEl65_x": "Payer une partie (ou multiple carte)",
"zqwNVFrmEl66_x": "Montant à Payer maintenant",
"zqwNVFrmEl67_x": "Paiements récurrents",
"zqwNVFrmEl68_x": "Paiement postérieur chaque ... du mois",
"zqwNVFrmEl69_x": "Dès",
"zqwNVFrmEl70_x": "date example 2000-12-22",
"zqwNVFrmEl71_x": "Félicitations, paiement reçu avec succès!!",
"zqwNVFrmEl71b_x": "Félicitations, demande de paiement envoyé avec succès!!",
"zqwNVFrmEl72_x": "Paiement",
"zqwNVFrmEl73_x": "Payer avec",
"zqwNVFrmEl74_x": "Paypal par",
"zqwNVFrmEl75_x": "Upsell avec CardOnFile",
"zqwNVFrmEl76_x": "Etape Antérieure",
"zqwNVFrmEl77_x": "C'est une vente interac, assurez-vous que le client a bien payé (fonds déposés) avant de clicker sur finaliser. Ceci va créer le ticket d'achat et l'envoie du courriel au client.",
"zqwNVFrmEl77b_x": "C'est une vente qui sera payée par une compagnie à Neolegal, tu ne dois pas le finaliser ici le système s'en charge.",
"zqwNVFrmEl78_x": "FINALISER LA TRANSACTION",
"zqwNVFrmEl79_x": "FERMER SANS FINALISER LA TRANSACTION",
"zqwNVFrmEl80_x": "Recherchez un Client",
"zqwNVFrmEl81_x": "Veuillez indiquer le nombre de pages à relire",
"zqwNVFrmEl82_x": "Pages",
"zqwNVFrmEl83_x": "Prix du Produit",
"zqwNVFrmEl84_x": "Service Aditionnel",
"zqwNVFrmEl85_x": "Totales Taxes",
"zqwNVFrmEl86_x": "Les Options de Service Aditionnel",
"zqwNVFrmEl87_x": "Créer un dossier et un ticket zendesk",
"zqwNVFrmEl88_x": "Enlever",
"zqwNVFrmEl89_x": "LA LISTE DE PRODUITS DANS LA FACTURE",
"zqwNVFrmEl90_x": "Taxe de",
"zqwNVFrmEl91_x": "Total à payer",
"zqwNVFrmEl92_x": "Étape Antérieure",
"zqwNVFrmEl93_x": "Étape Suivante",
"zqwNVFrmEl94_x": "Recommencer Complètement",
"zqwNVFrmEl95_x": "Info du Client",
"zqwNVFrmEl96_x": "Selectionner une Langue",
"zqwNVFrmEl97_x": "Français",
"zqwNVFrmEl98_x": "Anglais",
"zqwNVFrmEl99_x": "J'accepte de recevoir des notifications par SMS concernant mon dossier (recommandé)",
"zqwNVFrmEl100_x": "Google chercher adresse...",
"zqwNVFrmEl101_x": "Filtré Map pour Canada",
"zqwNVFrmEl102_x": "Filtré Map pour Etats-Unis",
"zqwNVFrmEl103_x": "pays ou zone pour sintoniser",
"zqwNVFrmEl104_x": "Filtré Map pour France",
"zqwNVFrmEl105_x": "Filtré Map pour Le monde entier",
"zqwNVFrmEl106_x": "click pour voir dernière recher avec code postal",
"zqwNVFrmEl107_x": "App/Suite/Unité",
"zqwNVFrmEl108_x": "#appartement ou suite ou unité",
"zqwNVFrmEl109_x": "Choisir État",
"zqwNVFrmEl110_x": "Adresses du code postal",
"zqwNVFrmEl111_x": "La liste de possible Adresse avec le même code postal",
"zqwNVFrmEl112_x": "Liste d'option d'Addresse à Choisir (facultatif)",
"zqwNVFrmEl113_x": "Votre inscription",
"zqwNVFrmEl114_x": "Détails",
"zqwNVFrmEl115_x": "Votre portail juridique",
"zqwNVFrmEl116_x": "Consulter un avocat immédiatement",
"zqwNVFrmEl117_x": "Votre signature électronique",
"zqwNVFrmEl118_x": "Garantie Zéro Stress de Neolegal",
"zqwNVFrmEl119_x": "Si nous nous rendons compte après votre achat que ce produit ne vous convient pas, vous serez bien sûr intégralement remboursé ou nous vous proposerons un produit plus adapté.",
"zqwNVFrmEl120_x": "Toutes nos Félicitations!!!",
"zqwNVFrmEl121_x": "On a fermé avec succès votre transaction de vente",
"zqwNVFrmEl122_x": "vous pouvez fermer c'est tout",
"zqwNVFrmbtn_x": "Fermer",
"zqwNVFrmEl123_x": "Produit retiré",
"zqwNVFrmEl124_x": "wwwwwwww",
"zqwNVFrmEl125_x": "wwwwwwww",
"zqwNVFrmEl126_x": "wwwwwwww",
"zqwNVFrmEl127_x": "wwwwwwww",
"zqwNVFrmEl128_x": "wwwwwwww",
"zqwNVFrmEl129_x": "wwwwwwww", 

"zqwCViewEl11_x": "On a chargé les données avec succès!",
"zqwCViewEl12_x": "Désolé, tu dois cochez un seul registre pour continuer",
"zqwCViewEl13_x": "Qté Produit",
"zqwCViewEl14_x": "Vente",
"zqwCViewEl15_x": "Liste de Ventes",
"zqwCViewEl16_x": "Action sur Facture",
"zqwCViewEl17_x": "FACTURE",
"zqwCViewEl18_x": "Liste de Ventes en Attente",
"zqwCViewEl19_x": "Liste de Toutes les Transactions de Ventes",
"zqwCViewEl20_x": "Liste de ventes Partenaire",
"zqwCViewEl21_x": "Liste de Ventes Upsale",
"zqwCViewEl22_x": "Erreur, Veuillez insérer les 2 dates.",
"zqwCViewEl23_x": "Date de Vente",
"zqwCViewEl24_x": "Qté Nouv.",
"zqwCViewEl25_x": "Nouveaux Cl.$",
"zqwCViewEl26_x": "Qté Suivi",
"zqwCViewEl27_x": "Suivi",
"zqwCViewEl28_x": "Panier Moyen",
"zqwCViewEl29_x": "Vente Par",
"zqwCViewEl30_x": "Nouveaux Clients",
"zqwCViewEl31_x": "Taxe totale",
"zqwCViewEl32_x": "VENTES RÉALISÉES PAR PERSONNE",
"zqwCViewEl33_x": "Resumé des Objectifs de Ventes",
"zqwCViewEl34_x": "Depuis aaaa-mm-jj",
"zqwCViewEl35_x": "jusqu'à aaaa-mm-jj",
"zqwCViewEl36_x": "Par Jour",
"zqwCViewEl37_x": "Par Semaine",
"zqwCViewEl38_x": "Par Mois",
"zqwCViewEl39_x": "Sans Provinces",
"zqwCViewEl40_x": "Par les Provinces",
"zqwCViewEl41_x": "Par",
"zqwCViewEl42_x": "Recharger Données",
"zqwCViewEl43_x": "Facture",
"zqwCViewEl44_x": "Envoyé Facture encore",
"zqwCViewEl44b_x": "Voir Facture du client comme dans l'email",
"zqwCViewEl45_x": "Envoyé Welcome Email",
"zqwCViewEl46_x": "Statut Ticket",
"zqwCViewEl47_x": "Ticket",
"zqwCViewEl48_x": "Welcome",
"zqwCViewEl49_x": "Deselect ALL",
"zqwCViewEl50_x": "Inverse selection",
"zqwCViewEl51_x": "Envoyé Email Interac une fois de plus",
"zqwCViewEl52_x": "Éliminé  Interac/ChargeDesk en attente",
"zqwCViewEl53_x": "Renvoyer courriel",
"zqwCViewEl54_x": "Supprimer",
"zqwCViewEl55_x": "Operations Après Vente",
"zqwCViewEl56_x": "Fermer et Retourner",
"zqwCViewEl57_x": "Charger Page Suivante (serveur)",
"zqwCViewEl58_x": "Retourner au lead",
"zqwCViewEl59_x": "Ouvrir dossier client",
"zqwCViewEl60_x": "Vente sans operations",
"zqwCViewEl61_x": "Liste de Ventes Sans Operation",
"zqwCViewEl62_x": "Liste de Ventes à Finaliser",
"zqwCViewEl63_x": "wwwwwwww",
"zqwCViewEl64_x": "wwwwwwww",
"zqwCViewEl65_x": "wwwwwwww",
"zqwCViewEl66_x": "wwwwwwww",
"zqwCViewEl67_x": "wwwwwwww",
"zqwCViewEl68_x": "wwwwwwww",
"zqwCViewEl69_x": "wwwwwwww",

"zqwacAddNeoB2bleadProspects_x": "B2b Lead Prospects",
"zqwacEditNeoB2bleadProspects_x": "Edit B2b Lead Prospects", 
"partner": "Partenaire", 
"zqwgender_x": "Sexe",  
"zqwcurrent_insurances_x": "Avez-vous une assurance?",
"zqwsent_x": "Envoyé",
"zqwdob_x": "Date de naissance",
"zqwage_x": "Age",
"zqwsend_date_x": "Date d'envoi",
"zqwobservation_x": "Observation (précision)",
"zqwquantity_x": "Quantité",
"zqwNeoB2bleadProspectsrep_x": "Rapport de Lead B2B", 
"zqwCViewEl29Q_x": "Lead Envoyé Par", 
"zqwsend_x": "Envoyer",
"zqwsendleadcny_x": "Acceptez-vous que Neolegal transmette vos coordonnées à notre partenaire: ...?", 
"zqwsendleadx2_x": "exemple : testament, incorporation, familiale ou autre",
"zqwsendleadx3_x": "exemple : vie, invalidité, autre",

"zqwNDICItxl_x": "Conflits d'Intérêts",
"zqwNDICItx2_x": "Recherche de conflit d'interêts",
"zqwNDICItx3_x": "Recherche",
"zqwNDICItx4_x": "Ignorer",
"zqwNDICItx5_x": "Non appliqué",
"zqwNDICItx6_x": "Aucune information disponible",
"zqwNDICItx7_x": "Aucun conflit",
"zqwNDICItx8_x": "Conflit",
"zqwNDICItx9_x": "Pas de registre de recherche",
"zqwNDICItxl0_x": "Autre",
"zqwNDICItxl1_x": "En attente",
"zqwNDICItxl2_x": "Liste de Conflits",
"zqwmenuel2x2_x": "Liste de Vente Pour Recherche de Conflit", 
"zqwNDICItx2x2_x": "Recherche de conflit",
"zqwNDICItx2x3_x": "Qté Régistre de Recherche",
"zqwNDICItxl3_x": "Liste de Lead Prospect",
"zqwNDICItxl4_x": "Liste de Lead Prospect Envoyé",
"zqwNDICItxl5_x": "Envoyé Par", 
"zqwNDICItxl6_x": "Cliquez ici pour un client qui n'a pas de courriel", 
"zqwNDICItxl7_x": "Pour Générer un courriel aléatoire à ce client, car il pourra l'utiliser pour entrer à son portail",
"zqwNDICItxl6a2_x": "Cliquez", 
"zqwNDICItxl6a3_x": "pour un client qui n'a pas de courriel",  
"zqwNDICItxl8_x": "Tu ne peux pas utiliser ce couriel ", 
"zqwfrmdobage_x": "Remplissez la date de naissance ou l'age de la personne.",
"zqwNDICItbook2_x": "Prendre un rendez-vous pour le client",


"zqwZendtx1_x": "Sujet",
"zqwZendtx2_x": "Demandeur",
"zqwZendtx3_x": "Mis à jour",
"zqwZendtx4_x": "Assigné",
"zqwZendtx5_x": "Créé",
"zqwZendtx6_x": "Traitement de Lead",
"zqwZendtx7_x": "qqqqqqqqqq",
"zqwZendtx8_x": "On charge les Données, patientez un instant s'il vous plaît...",



"zqwNeoLeadsReasons_x": "Neo mène des raisons",
"zqwacAddSalesBaseFlow_x": "Ajouter un flux de base de vente",
"zqwacEditSalesBaseFlow_x": "Modifier le flux de base des ventes",
"zqwSalesBaseFlow_x": "Flux de base des ventes",
"zqwinvoice_x": "Facture",
"zqwflow_x": "Flux",
"zqwdescription_x": "Description",
"zqwacAddSalesFlow_x": "Ajouter un flux de vente",
"zqwacEditSalesFlow_x": "Modifier le flux de vente",
"zqwacEditSalesFlow23_x": "Flux de vente réalisés",
"zqwSalesFlow_x": "Flux de vente",
"zqwobject_type_x": "Type d'objet",
"zqwmacro_x": "Macro",
"zqwtrigger_x": "Déclencheur",
"zqwautomation_x": "Automatisation",
"zqwdata_raw_x": "Données brutes",
"zqwstatus_x": "Statut",
"zqwlast_update_x": "Dernière mise à jour",
"zqwacAddZendAutomats_x": "Ajouter des automates Zend",
"zqwacEditZendAutomats_x": "Modifier les automates Zend",
"zqwZendAutomats_x": " Automates Zend ",
"zqwticket_x": "Billet",
"zqwacAddZendComments_x": "Ajouter des commentaires Zend",
"zqwacEditZendComments_x": "Modifier les commentaires Zend",
"zqwZendComments_x": "Commentaires Zend",
"zqwacAddZendGroups_x": "Ajouter des groupes Zend",
"zqwacEditZendGroups_x": "Modifier les groupes Zend",
"zqwZendGroups_x": "Groupes Zend",
"zqwreason_x": "Raison",
"zqwreason2_x": "Classification de Lead",
"zqwassig_by_x": "Assigné par",
"zqwassig_date_x": "Date Assignée",
"zqwassig_to_x": "Assigné à",
"zqwrecall_date_x": "Date de rappel",
"zqwupdate_by_x": "Mettre à jour par",
"zqwviewing_by_x": "Affichage par",
"zqwviewing_date_x": "Date de visualisation",
"zqwp_id_x": "P Id",
"zqwdefinition_x": "Définition",
"zqwticket_id_x": "Identifiant du ticket",
"zqwclient_id_x": "Identifiant client",
"zqwemail_x": "E-mail",
"zqwdate_created_x": "Date de création",
"zqwdelay_x": "Délai",
"zqwdone_x": "Terminé",
"zqwdate_executed_x": "Date d'exécution",
"zqwresult_x": "Résultat",
"zqwticket_type_x": "Type de ticket",
"zqwacAddZendLeads_x": "Ajouter ZendLeads",
"zqwacEditZendLeads_x": "Modifier ZendLeads",
"zqwZendLeads_x": "Les leads de Zendesk",
"zqwZendLeads2_x": "Mes leads de Zendesk",
"zqwZendLeads3_x": "Ouvrir mes leads",
"zqwacAddZendMacros_x": "Ajouter des macros Zend",
"zqwacEditZendMacros_x": "Modifier les macros Zend",
"zqwZendMacros_x": "Macro Zend",
"zqwacAddZendTickets_x": "Ajouter des tickets Zend",
"zqwacEditZendTickets_x": "Modifier les tickets Zend",
"zqwZendTickets_x": "Billets Zend",
"zqwacAddZendUsers_x": "Ajouter des utilisateurs Zend",
"zqwacEditZendUsers_x": "Modifier les utilisateurs Zend",
"zqwZendUsers_x": "Utilisateurs Zend",
"zqwZendtx9_x": "Lecture seulement",
"zqwZendLeads00_x": "Les MEDs de Zendesk",
"zqwZendLeads200_x": "Mes MEDs de Zendesk",
"zqwZendLeads300_x": "Ouvrir mes MEDs",
"zqwZendtx600_x": "Traitement de MED",
"zqwmenuel1100_x": "Liste de MED Zendesk", 
"zqwFlowvtx1_x": "Fusion et ajout de Note au Ticket",
"zqwFlowvtx2_x": "Envoi de macro au client", 
"zqwacAddZendMacros002_x": "Envoi de message au client avec macro",
"zqwFlowvtx3_x": "Liste de macros",
"zqwNDICItx21_x": "Recherche de conflit et dossier client",
"zqwFlowvtx4_x": "Ajout de Note au Ticket et Fusion",
"zqwFlowvtx5_x": "Fusionner",
"zqwFlowvtx6_x": "au",
"zqwFlowvtx7_x": "Note au Ticket",
"zqwNDIabr_x": "IDN",
"zqwsalreason_x": "Classification de Vente",
"btnSave_x": "Sauvegarder",
"btnSave2_x": "Sauvegarder Raison",
"pleaseSelsr_x": "Veuillez sélectionner une raison de Vente",
"zqwsalreason3_x": "La raison de Vente a été ajoutée",
"zqwCDpendsaledel_x": "Vous êtes au point d'éliminer  une vente en attente. Veuillez confirmer.",
"zqwphone_x": "Téléphone", 
"zqwsendsms_x": "Envoyer SMS", 
"zqwFlowvtsmsx7_x": "Note du SMS",
"zqwFlowvtsmsx8_x": "Message SMS envoyé avec succès!",
"zqwmessage_x": "Message",
"zqwNeoProspectsSms_x": "Prospects SMS",
"smsgridmu_x": "Voir la liste de SMS envoyé",
"zqwsendsmsr_x": "Renvoyer SMS", 
"zqwTNVFtxt33_x": "Recherchez un Client partenaire",
"pleaseSelsr2_x": "Veuillez sélectionner ",

"zqwNFFrmZn09_x": "Envoi de lien de formulaire",
"zqwNFFrmZn010_x": "Liste de formulaire",
"zqwNFFrmZn010b_x": "Le nom du fichier a envoyer",
"zqwNFFrmZn011_x": "Envoyez formulaire",
"zqwNFFrmZn012_x": "Lien de formulaire envoyé à ",
"zqwNFFrmZn013_x": "Obtenir le lien du formulaire",
"zqwNFFrmZn014_x": "Envoyez par email",
"zqwNFFrmZn015_x": "Veuillez choisir un email ou inserrez un",
"zqwNFFrmZn016_x": "Choisir ou inserrez email",
"zqwNFFrmZn017_x": "Cliquez ici pour me choisir",
"zqwNFFrmZn018_x": "le format de l'e-mail est incorrect",

"zqwMesas01_x": "Utilisateur assigné  et groupe ne peuvent pas etre vide les deux à la fois",
"zqwMesas02_x": "Assigné à",
"zqwMesas03_x": "Groupe dans Zendesk",
"zqwMesas04_x": "Assignant groupe et/ou personne au Ticket",
"zqwMesas05_x": "Groupe {{group}} et utilisateur {{assignee}} sont assignés au ticket {{ticket}}",
"zqwMesas06_x": "Groupe {{group}} est assigné au ticket {{ticket}}",
"zqwMesas07_x": "Utilisateur {{assignee}} est assigné au ticket {{ticket}}",
"zqwMesas08_x": "Assigner Ticket au Groupe/Utilisateur",
"zqwMesas09_x": "Fusionner et ajouter Note",
"zqwMesas010_x": "Ajouter Note",
"zqwMesas011_x": "Type note",
"zqwMesas012_x": "Note privée",
"zqwMesas013_x": "Note publique",
"zqwMesas014_x": "Ajouter Note au ticket",
"notes2_x": "Notes privées",
"zqwMesas015_x": "Recherche de conflit fait",
"zqwMesas016_x": "Pour continuer svp faites la Recherche de conflit",
"zqwMesas017_x": "Priorité du Ticket: urgent",
"zqwMesas018_x": "Mes Leads",
"zqwMesas019_x": "Leads - admin",
"zqwMesas020_x": "Agents - admin",
"zqwMesas021_x": "Contact",
"zqwMesas022_x": "Finalisation peu probable",
"zqwMesas023_x": "Nbr Ventes finalisé {{sales}} ( Nbr de ventes en attente : {{pendings}} ) --- {{salePercentage}} finalisées",
"zqwMesas024_x": "Mnt Ventes finalisé {{sales}} ( Mnt de ventes en attente : {{pendings}} ) --- {{salePercentage}} finalisées",
"zqwMesas025_x": "une des 3 actions suivantes n’est pas faite : « envoyer formulaire », « fixer RDV » ou « assigner »",
"zqwMesas026_x": "pour fermer",
"zqwMesas027_x": "au",
"zqwMesas028_x": "au",
"zqwMesas029_x": "au",
"zqwCViewEl13c_x": "Attention, si vous ne choisissez pas de courriel ni téléphone le formulaire n'est pas envoyé, Merci de cliquer sur le bouton envoyer",
 




 




};

export default messagesFr;