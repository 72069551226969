import history from "../history";
import { API_DOMAIN } from "../appConfig";
import { encryptText, decryptText } from "../appEncryption";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const RECEIVE_LOGGEDUSER = "RECEIVE_LOGGEDUSER";
export const CHANGE_USERPASS = "CHANGE_USERPASS";

const apiUrl = API_DOMAIN; // + '/zxteams';

export const userLoginFetch = (user) => {
  //console.log("//////START ");  //console.log(user);  //console.log("//////////END OF USER");
  //response.status
  return (dispatch) => {
    return (
      fetch(`${apiUrl}/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(user),
      })
        //.then(resp => resp.json())
        .then(function (response) {
          if (!response.ok) {
            const error = `HTTP error! status: ${response.status}`;
            return Promise.reject(new Error(error));
          }
          return response.json();
        })
        .then((datax) => {
          const data = datax.data;
          localStorage.setItem("token", encryptText(data.token));
          const tokadm = localStorage.tokadm;
          if (tokadm === undefined || tokadm === null) {
          } else {
            localStorage.removeItem("tokadm");
          }
          let neoState = {
            currentUser: data.profile,
            id: data.profile.id,
            name: data.profile.firstname,
            role: data.profile.user_type,
            isAuthenticated: true,
          };
          const userStr =
            "true:" +
            data.profile.id +
            ":" +
            data.profile.firstname +
            " " +
            data.profile.lastname +
            ":" +
            data.profile.user_type +
            ":" +
            data.id;
          const userId = data.profile.id_users;

          localStorage.setItem("user", encryptText(userStr)); //JSON.stringify(neoState)
          localStorage.setItem(
            "profile",
            encryptText(JSON.stringify(data.profile))
          ); //JSON.stringify(neoState)

          setTimeout(() => {
            console.log("saaaaaving profile data and ....");
            const actionPage = "NdiFieldsAction.php";
            const appName = "NDI"; //const pageName=""+actionPage; // ...Action.php
            const pageName = "NdiFieldsAction.php";
            const tokenRid = localStorage.rid;
            console.log("0000###******* PROFILE GO FOR checkisadmin  ");
            return fetch(
              `${apiUrl}/users/checkisadmin?appName=${appName}&pageName=${pageName}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${data.token}`,
                  "auth-id": `${userId}`,
                },
              }
            )
              .then((resp) => resp.json())
              .then((data) => {
                console.log("0000//////*******IS ADMIN CHECKED ", data);
                if (data.isadmin >= 0) {
                  //console.log("//////******* #2 SUCCESS "+data.user.id);
                  localStorage.setItem("tokadmndi", data.isadmin);
                  console.log("0000//////******* #2 SUCCESS/error #1 ");
                  if (neoState !== null) {
                    return dispatch(loginUser(neoState));
                  }
                } else {
                  localStorage.setItem("tokadmndi", 0);
                  console.log("0000//////******* #2 SUCCESS/error #2 ");
                  if (neoState !== null) {
                    return dispatch(loginUser(neoState));
                  }
                }
              });
          }, 500);
        })
        .catch((error) => {
          console.error(error);
        })
    );
  };
};

export const getProfileFetch = () => {
  return (dispatch) => {
    const token = decryptText(localStorage.token);
    const tokenRid = localStorage.rid;
    const profile = decryptText(localStorage.profile);
    if (profile === undefined || profile === null || profile == "") {
      return dispatch(loginUser(null));
    }
    const profileLoc = JSON.parse(profile);

    if (profileLoc.id > 0) {
      let neoState = {
        currentUser: profileLoc,
        id: profileLoc.id,
        name: profileLoc.firstname + " " + profileLoc.lastname,
        role: profileLoc.user_type,
        isAuthenticated: true,
      };
      return dispatch(loginUser(neoState));
    }

    if (token) {
      return fetch(`${apiUrl}/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          rid: `${tokenRid}`,
        },
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success == 1 || data.success === true) {
            let neoState = {
              currentUser: data.user,
              id: data.user.id,
              name: data.user.name,
              role: data.user.type,
              isAuthenticated: true,
            };
            return dispatch(loginUser(neoState));
          } else {
            alert("Error ... " + data.message);
          }
        });
    }
  };
};

export const userPostFetch = (user) => {
  return (dispatch) => {
    return fetch(`${apiUrl}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success == 1 || data.success === true) {
          //console.log("//////******* SUCCESS "+data.user.id);
          localStorage.setItem("token", data.token);
          //return dispatch(loginUser(data.user));
          let neoState = {
            currentUser: data.user,
            id: data.user.id,
            name: data.user.name,
            role: data.user.type,
            isAuthenticated: true,
          };
          return dispatch(loginUser(neoState));
        } else {
          alert("Error ... " + data.message);
        }
      });
  };
};

export const getLoggedUser = () => {
  return (dispatch, getState) => {
    const state = getState();
    return dispatch({
      type: RECEIVE_LOGGEDUSER,
      currentUser: state.currentUser,
    });
  };
};

export const setLoggedUser = (neoState) => {
  //console.log("SET LOGGED USE TO NOT GO ON SERVER RIGHT IN loading getLoggedUser .... ");

  return (dispatch) => {
    dispatch(loginUser(neoState));
  };
};

export const getLoggedUserGoto = (id) => {
  return (dispatch) => {
    history.push(`./protected`);
  };
};

const loginUser = (userObj) => ({
  type: "LOGIN_USER",
  payload: userObj,
});

export const logoutUser = () => ({
  type: "LOGOUT_USER",
});

export const changePassProfile = (passFieldsObj, showError) => {
  const token = localStorage.token;
  const tokenRid = localStorage.rid;

  return (dispatch) => {
    return fetch(`${apiUrl}/changepassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        rid: `${tokenRid}`,
      },
      body: JSON.stringify(passFieldsObj),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success == 1 || data.success === true) {
          return dispatch({ type: CHANGE_USERPASS, User: data });
        } else {
          showError(data.message);
          return dispatch({ type: CHANGE_USERPASS, User: null });
        }
      });
  };
};

//userLogoutProfile
export const userLogoutProfile = (someFieldsObj, showError) => {
  const token = decryptText(localStorage.token);
  const tokenRid = localStorage.rid;

  return (dispatch) => {
    return fetch(`${apiUrl}/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        rid: `${tokenRid}`,
      },
      body: JSON.stringify(someFieldsObj),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success == 1 || data.success === true) {
          return dispatch({ type: LOGOUT_USER, User: data });
        } else {
          showError(data.message);
          return dispatch({ type: LOGOUT_USER, User: null });
        }
      });
  };
};

export const userPassResetFetch = (user) => {
  return (dispatch) => {
    return fetch(`${apiUrl}/loginpassreset`, {
      //return fetch(`${apiUrl}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.success == 1 || data.success === true) {
          let neoState = {
            currentUser: data.user,
            id: data.user.id,
            name: data.user.name,
            role: data.user.type,
            isAuthenticated: true,
          };
          const userStr =
            "true:" +
            data.user.id +
            ":" +
            data.user.name +
            ":" +
            data.user.type;
          return dispatch(loginUser(neoState));
        } else {
          // Here you should have logic to handle invalid login credentials.
          // This assumes your Rails API will return a JSON object with a key of
          // 'message' if there is an error
          alert("Error Reseteando tu contraseña ... " + data.message);
        }
      });
  };
};

export const checkIfUserIsAdminNDI = (actionPage) => {
  console.log("checkIfUserIsAdminNDI BEFORE response from server .... ");
  return (dispatch) => {
    const token = decryptText(localStorage.token);
    ////console.log("###******* TOKEN SAVED "+token);
    const tokenRid = localStorage.rid;
    const profile = decryptText(localStorage.profile);
    console.log("###******* PROFILE DEC  " + profile);
    if (profile === undefined || profile === null || profile == "") {
      return dispatch(loginUser(null));
    }
    const appName = "NDI";
    const pageName = "" + actionPage; // ...Action.php
    console.log("###******* PROFILE GO FOR checkisadmin  ");

    return fetch(
      `${apiUrl}/users/checkisadmin?appName=${appName}&pageName=${pageName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          rid: `${tokenRid}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        console.log("//////*******IS ADMIN CHECKED ", data);

        if (data.isadmin >= 0) {
          localStorage.setItem("tokadmndi", data.isadmin);
          console.log("0000//////******* #2 SUCCESS/error #1 ");
        } else {
          localStorage.setItem("tokadmndi", 0);
        }
      });
  };
};
