import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addOrUpdateNeoVentes,
  getNeoVentes,
  getNeoVentesAll,
  getNeoVentesAllXStaus,
  getNeoVentesGoto,
  deleteNeoVentes,
  getOrSetMultiActionStatus,
  getNeoVentesAllNew,
} from "../../actions/NeoVentesAction";
import { withTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import CustomToolbarSelectvente from "../CustomToolbarSelectvente";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Toastr from "../../messages";
import Loader from "../../loader";
import { NeoVentesListFilterBar } from "./NeoVentesListFilterBar";
class NeoVentesList extends Component {
  actiongo = "";
  constructor(props) {
    super(props);
    this.state = {
      isLoaderOpen: false,
      toastrMessage: "",
      toastrType: "",
      toastrDuration: 6000,
      statusChargeDesk: 0,
      chargeDeskIdStr: "",
      invoiceId: 0,
      info_otheresp: "",
      openPossibleCDStatusInfo: false,
      possibleCDStatusInfo: {},
      possibleListOfCDStatusInfo: [],
      filterData: {
        firstname: "",
        lastname: "",
        email: "",
        cell: "",
      },
    };
    this.deleteXRecordsMain = this.deleteXRecordsMain.bind(this);
    this.updateXRecordsMain = this.updateXRecordsMain.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  createFilterArray = () => {
    const { filterData } = this.state;
    const mappings = {
      firstname: "t2.firstname",
      lastname: "t2.lastname",
      email: "t1.email",
      cell: "t2.cell",
    };

    let arr = Object.entries(filterData)
      .filter(([key, value]) => value)
      .map(([key, value]) => ({
        field: mappings[key],
        value: `'${value}'`,
        opt: "=",
        optNext: "and",
      }));
    return arr;
  };
  fetchData = () => {
    const { t } = this.props;
    const this2 = this;
    const filterArr = this.createFilterArray();
    Promise.resolve(this.props.getNeoVentesAllNew("payed", filterArr))
      .then((response) => {
        this.setState({ ["isLoaderOpen"]: false });
        return response;
      })
      .catch(function (error) {
        this2.setState({ ["isLoaderOpen"]: false });
        this.showToast(error?.response?.message, "error");
      });
  };

  showToast = (arg, toastType) => {
    const toastrDuration = this.state.toastrDuration; //5500
    this.setState({
      toastrMessage: arg,
      toastrType: toastType,
    });
    setTimeout(() => {
      this.setState({
        toastrMessage: "",
        toastrType: "success",
      });
    }, toastrDuration);
  };

  deleteXRecordsMain(selectedRows) {
    const objList = this.props.NeoVentes;
    let gridIndexes = [];
    let gridIds = [];
    selectedRows.data.map((row) => {
      gridIndexes.push(row.index);
      return null;
    });

    gridIndexes.reverse();
    for (const index of gridIndexes) {
      const obj = objList[index];
      gridIds.push(obj.id);
      objList.splice(index, 1);
    }
    this.props.deleteNeoVentes(gridIds[0]);
  }

  updateXRecordsMain(selectedRows, indexName) {
    const { t } = this.props;
    const objList = this.props.NeoVentes;
    let gridIndexes = [];
    let idIndex = -1;
    selectedRows.data.map((row) => {
      gridIndexes.push(row.dataIndex); //global
      idIndex = row.dataIndex;
      return null;
    });

    if (gridIndexes.length > 1) {
      alert(t("zqwCViewEl12_x"));
      return false;
    }

    let obj = objList[idIndex];
    const invoiceId = obj.id;
    console.log(
      "oooooooooooooooooooooooh " + obj.id + "   indexName=" + indexName
    );
    this.actiongo = indexName;
    const objAction = { invoiceId: obj.id, actiongo: indexName };
    this.setState({ ["isLoaderOpen"]: true });
    const this2 = this;
    Promise.resolve(this.props.getOrSetMultiActionStatus(objAction))
      .then((response) => {
        const info = response.NeoVentesOne;
        this.setState({
          ["isLoaderOpen"]: false,
          ["chargeDeskIdStr"]: info.messageAction,
          ["openPossibleCDStatusInfo"]: true,
          ["invoiceId"]: invoiceId,
          ["info_otheresp"]: info.otheresp,
        });
        this.showToast(t("zqwCViewEl11_x"), "success"); //chargeDeskIdStr
        return response;
      })
      .catch(function (error) {
        this2.setState({ ["isLoaderOpen"]: false });
        alert(error);
      });
  }

  handleChangeFilterData = (e) => {
    const newData = {
      ...this.state.filterData,
      [e.target.name]: e.target.value,
    };
    this.setState({ filterData: newData });
  };

  clearFilterData = () => {
    this.setState(
      {
        filterData: {
          firstname: "",
          lastname: "",
          email: "",
          cell: "",
        },
      },
      () => this.fetchData()
    );
  };

  render() {
    const { t } = this.props;

    const columns = [
      {
        name: "id",
        label: t("zqwid_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "fullName",
        label: t("zqwid_clients_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "qtyProducts",
        label: t("zqwCViewEl13_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "status",
        label: t("zqwstatus_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "due_date",
        label: t("zqwdue_date_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "sub_total",
        label: t("zqwsub_total_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "tax1",
        label: t("zqwtax1_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "tax2",
        label: t("zqwtax2_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "code",
        label: t("zqwcode_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "vendor",
        label: t("zqwCViewEl14_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "tax_total",
        label: t("zqwtax_total_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "total",
        label: t("zqwtotal_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "province",
        label: t("zqwprovince_x"),
        options: { filter: true, sort: true },
      },
    ];

    const textLabelOptions = {
      body: {
        noMatch: t("zqwgridnoMatch_x"),
        toolTip: t("zqwgridtoolTiph_x"),
        columnHeaderTooltip: (column) =>
          `${t("zqwgridSortfor_x")} ${column.label}`,
      },
      pagination: {
        next: t("zqwgridnext_x"),
        previous: t("zqwgridprevious_x"),
        rowsPerPage: t("zqwgridrowsPerPage_x"),
        displayRows: t("zqwgriddisplayRows_x"),
      },
      toolbar: {
        search: t("zqwgridsearch_x"),
        downloadCsv: t("zqwgriddownloadCsv_x"),
        print: t("zqwgridprint_x"),
        viewColumns: t("zqwgridviewColumns_x"),
        filterTable: t("zqwgridfilterTable_x"),
      },
      filter: {
        all: t("zqwgridall_x"),
        title: t("zqwgridftitle_x"),
        reset: t("zqwgridreset_x"),
      },
      viewColumns: {
        title: t("zqwgridvctitle_x"),
        titleAria: t("zqwgridvctitleAria_x"),
      },
    };

    const options = {
      filterType: "checkbox",
      textLabels: textLabelOptions,
      customToolbar: () => (
        <NeoVentesListFilterBar
          data={this.state.filterData}
          handleChange={this.handleChangeFilterData}
          clear={this.clearFilterData}
          fetchData={this.fetchData}
        />
      ),
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelectvente
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          deleteXRecords={this.deleteXRecordsMain}
          updateXRecords={this.updateXRecordsMain}
          isEdit={true}
          isDelete={true}
          t={this.props.t}
        />
      ),
    };

    const myToastMessage = this.state.toastrMessage;
    const toastrType = this.state.toastrType;
    const CheckOrSetActionsOnInvoice = this.CheckOrSetActionsOnInvoice;
    const data = Array.isArray(this?.props?.NeoVentes)
      ? this.props.NeoVentes
      : [];

    return (
      <div>
        <h4>{t("zqwCViewEl15_x")}</h4>
        <MUIDataTable
          title={""}
          justify={"flex-start"}
          data={data}
          columns={columns ?? []}
          options={options}
        />
        <Loader openx={this.state.isLoaderOpen} />
        <Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
        <CheckOrSetActionsOnInvoice openx={this.state.isLoaderOpen} />
      </div>
    );
  }

  CheckOrSetActionsOnInvoice = (event) => {
    const open = this.state.openPossibleCDStatusInfo;
    const { t } = this.props;
    const listObj = this.state.possibleListOfCDStatusInfo;
    const info_otheresp = this.state.info_otheresp;
    let maxWidth = "sm"; //"sm";
    let isfullScreen = false;
    if (
      info_otheresp !== null &&
      info_otheresp !== "" &&
      this.actiongo === "viewemailinvoice"
    ) {
      maxWidth = "lg";
      isfullScreen = true;
    }

    return (
      <div>
        <Dialog
          maxWidth={maxWidth}
          open={open}
          onClose={this.handleCloseListOfCDStatusInfo}
          disableBackdropClick
          disableEscapeKeyDown
          fullScreen={isfullScreen}
          aria-labelledby="form-dialog-confirmclient4"
        >
          <DialogContent id="form-dialog-confirmclient4">
            <div className="text-center">
              <h2>{t("zqwCViewEl16_x")}</h2>
            </div>
            <p>
              {t("zqwCViewEl17_x")} # {this.state.invoiceId}{" "}
            </p>
            <p> &nbsp; </p>

            {info_otheresp !== null &&
            info_otheresp !== "" &&
            this.actiongo === "viewemailinvoice" ? (
              <div className="form-group col-11">
                <iframe
                  srcDoc={info_otheresp}
                  sandbox="allow-same-origin"
                  allowFullScreen={true}
                  style={{
                    minHeight: "400px",
                    minWidth: "700px",
                    border: "none",
                  }}
                  name="iframe_emac"
                  title="Email Content"
                ></iframe>
                <input
                  type="button"
                  onClick={this.handleCloseListOfCDStatusInfo}
                  className="btn btn-success float-right"
                  value={t("zqwNVFrmbtn_x")}
                />
              </div>
            ) : (
              <p>{this.state.chargeDeskIdStr} </p>
            )}

            <div className="form-row neoxtbl">
              <div className="form-group col-md-9">
                <br />
                <input
                  type="button"
                  onClick={this.handleCloseListOfCDStatusInfo}
                  className="btn btn-default"
                  value={t("zqwNVFrmbtn_x")}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  handleCloseListOfCDStatusInfo = (anyx) => {
    this.setState({ ["openPossibleCDStatusInfo"]: false });
  };
}

const mapStateToProps = (state) => ({ NeoVentes: state.NeoVentes });
const mapDispatchToProps = {
  addOrUpdateNeoVentes,
  getNeoVentes,
  getNeoVentesAll,
  getNeoVentesAllXStaus,
  getNeoVentesAllNew,
  getNeoVentesGoto,
  deleteNeoVentes,
  getOrSetMultiActionStatus,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(NeoVentesList));
