import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateNeoSalesPlanningWeekly, getNeoSalesPlanningWeekly } from '../../actions/NeoSalesPlanningWeeklyAction'; 
import { Validations } from '../../utils/validations'; 

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['week']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['week_start']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['week_end']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['expected_quantity']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['expected_amount']={check:true, chkType:'double', message:'incorrect value'};
FIELD_VALIDATION['panier']={check:true, chkType:'double', message:'incorrect value'};
FIELD_VALIDATION['invoice_quantity']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['invoice_amount']={check:false, chkType:'double', message:'incorrect value'};
FIELD_VALIDATION['payment_quantity']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['payment_amount']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['creation_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['last_update']={check:false, chkType:'Timestamp', message:'incorrect value'};
FIELD_VALIDATION['user_creation']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['user_update']={check:false, chkType:'int', message:'incorrect value'};

class NeoSalesPlanningWeeklyForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		week:'', e_week: false,
		week_start:'', e_week_start: false,
		week_end:'', e_week_end: false,
		expected_quantity:'', e_expected_quantity: false,
		expected_amount:'', e_expected_amount: false,
		panier:'', e_panier: false,
		invoice_quantity:'', e_invoice_quantity: false,
		invoice_amount:'', e_invoice_amount: false,
		payment_quantity:'', e_payment_quantity: false,
		payment_amount:'', e_payment_amount: false,
		creation_date:'', e_creation_date: false,
		last_update:'', e_last_update: false,
		user_creation:'', e_user_creation: false,
		user_update:'', e_user_update: false,
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	if(this.actionMode==='edit'){ 
		console.log('edit  test  '+this.props.NeoSalesPlanningWeekly.id); 
		if(this.props.NeoSalesPlanningWeekly.id===undefined){ 
			//means some refresh happens  id
			const idx = this.props.match.params.id; 
			//getNeoSalesPlanningWeekly(idx);  
			console.log('iiiiiiiiiiinnside edit  test  '+idx);
			Promise.resolve(this.props.getNeoSalesPlanningWeekly(idx))
			.then((response)=>{
				console.log('yooooooooooupi edit  test  ', response);
				this.setState(response.NeoSalesPlanningWeeklyOne);
				return response;
			});
		}	
	}	
  }

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const week = this.state.week;
    const week_start = this.state.week_start;
    const week_end = this.state.week_end;
    const expected_quantity = this.state.expected_quantity;
    const expected_amount = this.state.expected_amount;
    const invoice_quantity = this.state.invoice_quantity;
    const invoice_amount = this.state.invoice_amount;
    const payment_quantity = this.state.payment_quantity;
    const payment_amount = this.state.payment_amount;
    const creation_date = this.state.creation_date;
    const last_update = this.state.last_update;
    const user_creation = this.state.user_creation;
	const user_update = this.state.user_update;
	const panier=this.state.panier;
	const NeoSalesPlanningWeekly = {id: id, week: week, week_start: week_start, week_end: week_end, 
		expected_quantity: expected_quantity, expected_amount: expected_amount, 
		invoice_quantity: invoice_quantity, invoice_amount: invoice_amount, 
		payment_quantity: payment_quantity, payment_amount: payment_amount, 
		creation_date: creation_date, last_update: last_update, user_creation: user_creation, 
		user_update: user_update, panier:panier}
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NeoSalesPlanningWeekly[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(t('zqwerrorFoundonform_x')); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateNeoSalesPlanningWeekly(NeoSalesPlanningWeekly, 1); 
		//this.props.addNeoSalesPlanningWeekly(NeoSalesPlanningWeekly); 
	}else{ 
		this.props.addOrUpdateNeoSalesPlanningWeekly(NeoSalesPlanningWeekly, 2);
		//this.props.updateNeoSalesPlanningWeekly(NeoSalesPlanningWeekly);
		
	}
  };

  handleCancel = () => { 
	this.props.history.push(`/salesplanningweekly`);   
	
	/*if(this.actionMode==='new'){ 
		this.props.history.push(`./NeoSalesPlanningWeekly`);
	}else{ 
		const idx = this.props.match.params.id; 
		this.props.history.push(`./NeoSalesPlanningWeekly/${idx}`);
		//this.props.history.push(`./NeoSalesPlanningWeekly/${this.props.NeoSalesPlanningWeekly.id}`);
	}*/
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <h3>{this.actionMode==='new'? t('zqwacAddNeoSalesPlanningWeekly_x'): t('zqwacEditNeoSalesPlanningWeekly_x')}</h3>
        <form onSubmit={this.handleSubmit}>
          		  
		  
		  <div className="form-group" style={{display:'none'}} >
			<label htmlFor="id">{t('zqwid_x')}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} readOnly={true} /> 
			{this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="week">{t('zqwweek_x')}</label>
            <input type="text" name="week" id="week" defaultValue={this.state.week} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_week ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwweek_x')} readOnly={true} /> 
			{this.state.e_week && (<span className="text-danger">{FIELD_VALIDATION['week'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="week_start">{t('zqwweek_start_x')}</label>
            <input type="text" name="week_start" id="week_start" defaultValue={this.state.week_start} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_week_start ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwweek_start_x')}  readOnly={true}/> 
			{this.state.e_week_start && (<span className="text-danger">{FIELD_VALIDATION['week_start'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="week_end">{t('zqwweek_end_x')}</label>
            <input type="text" name="week_end" id="week_end" defaultValue={this.state.week_end} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_week_end ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwweek_end_x')}  readOnly={true} /> 
			{this.state.e_week_end && (<span className="text-danger">{FIELD_VALIDATION['week_end'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="expected_quantity">{t('zqwexpected_quantity_x')}</label>
            <input type="text" name="expected_quantity" id="expected_quantity" defaultValue={this.state.expected_quantity} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_expected_quantity ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwexpected_quantity_x')} /> 
			{this.state.e_expected_quantity && (<span className="text-danger">{FIELD_VALIDATION['expected_quantity'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="expected_amount">{t('zqwexpected_amount_x')}</label>
            <input type="text" name="expected_amount" id="expected_amount" defaultValue={this.state.expected_amount} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_expected_amount ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwexpected_amount_x')} /> 
			{this.state.e_expected_amount && (<span className="text-danger">{FIELD_VALIDATION['expected_amount'].message}</span>)} 
          </div>

		   
		  <div className="form-group">
			<label htmlFor="panier">{t('panier')}</label>
            <input type="text" name="panier" id="panier" defaultValue={this.state.panier} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_panier ? 'form-control fieldErr' : 'form-control'} placeholder={t('panier')} /> 
			{this.state.e_panier && (<span className="text-danger">{FIELD_VALIDATION['panier'].message}</span>)} 
          </div>


		  {/* <div className="form-group">
			<label htmlFor="invoice_quantity">{t('zqwinvoice_quantity_x')}</label>
            <input type="text" name="invoice_quantity" id="invoice_quantity" defaultValue={this.state.invoice_quantity} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_invoice_quantity ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwinvoice_quantity_x')} /> 
			{this.state.e_invoice_quantity && (<span className="text-danger">{FIELD_VALIDATION['invoice_quantity'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="invoice_amount">{t('zqwinvoice_amount_x')}</label>
            <input type="text" name="invoice_amount" id="invoice_amount" defaultValue={this.state.invoice_amount} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_invoice_amount ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwinvoice_amount_x')} /> 
			{this.state.e_invoice_amount && (<span className="text-danger">{FIELD_VALIDATION['invoice_amount'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="payment_quantity">{t('zqwpayment_quantity_x')}</label>
            <input type="text" name="payment_quantity" id="payment_quantity" defaultValue={this.state.payment_quantity} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_payment_quantity ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwpayment_quantity_x')} /> 
			{this.state.e_payment_quantity && (<span className="text-danger">{FIELD_VALIDATION['payment_quantity'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="payment_amount">{t('zqwpayment_amount_x')}</label>
            <input type="text" name="payment_amount" id="payment_amount" defaultValue={this.state.payment_amount} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_payment_amount ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwpayment_amount_x')} /> 
			{this.state.e_payment_amount && (<span className="text-danger">{FIELD_VALIDATION['payment_amount'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="creation_date">{t('zqwcreation_date_x')}</label>
            <input type="text" name="creation_date" id="creation_date" defaultValue={this.state.creation_date} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_creation_date ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwcreation_date_x')} /> 
			{this.state.e_creation_date && (<span className="text-danger">{FIELD_VALIDATION['creation_date'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="last_update">{t('zqwlast_update_x')}</label>
            <input type="text" name="last_update" id="last_update" defaultValue={this.state.last_update} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_last_update ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwlast_update_x')} /> 
			{this.state.e_last_update && (<span className="text-danger">{FIELD_VALIDATION['last_update'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="user_creation">{t('zqwuser_creation_x')}</label>
            <input type="text" name="user_creation" id="user_creation" defaultValue={this.state.user_creation} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_user_creation ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwuser_creation_x')} /> 
			{this.state.e_user_creation && (<span className="text-danger">{FIELD_VALIDATION['user_creation'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="user_update">{t('zqwuser_update_x')}</label>
            <input type="text" name="user_update" id="user_update" defaultValue={this.state.user_update} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_user_update ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwuser_update_x')} /> 
			{this.state.e_user_update && (<span className="text-danger">{FIELD_VALIDATION['user_update'].message}</span>)} 
          </div> */}

          <div className="form-group">
            <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')}</button> &nbsp; 
			<button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button>
          </div>
        </form>
      </div>
    );
  }
}

//const TransNeoSalesPlanningWeeklyForm = withTranslation()(NeoSalesPlanningWeeklyForm); 
const mapStateToProps = (state) => ({ NeoSalesPlanningWeekly: state.NeoSalesPlanningWeekly });

const mapDispatchToProps = { addOrUpdateNeoSalesPlanningWeekly, getNeoSalesPlanningWeekly };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoSalesPlanningWeeklyForm));
