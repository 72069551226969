import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
//import { addOrUpdateNeoSearch, getNeoSearch } from '../../actions/NeoSearchAction'; 
import { Validations } from './utils/validations'; 
   
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

let FIELD_VALIDATION={};
 

class DialogConfirm extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		id:0, 
		status:'', e_status: false,

    openPossibleCDStatusInfo:false, 
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	// if(this.actionMode==='edit'){ 
	// 	console.log('edit  test  '+this.props.NeoSearch.id);
	// 	if(this.props.NeoSearch.id===undefined){ 
	// 		//means some refresh happens  id
	// 		const idx = this.props.match.params.id; 
	// 		//getNeoSearch(idx);
	// 		Promise.resolve(this.props.getNeoSearch(idx))
	// 		.then((response)=>{
	// 			this.setState(response.NeoSearchOne);
	// 			return response;
	// 		});
	// 	}	
	// }	
  }

  

  handleCancel = () => { 
  }

  handleCloseListOfCDStatusInfo  = (anyx) => {   
    this.setState({["openPossibleCDStatusInfo"]: false }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");  
    if(this.props.parentFuncStatus!==undefined){
      const obj={dialogConfirmStatus:false, response:false,};
      this.props.parentFuncStatus(obj);
    }  
  }

  handleYes  = (event) => {   
    console.log("handleYes... ", this.props.parentFuncStatus); 
    if(this.props.parentFuncStatus!==undefined){
      const obj={dialogConfirmStatus:false, response:true,};
      this.props.parentFuncStatus(obj);
    } 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");   
  }

  handleNo  = (event) => {  
    console.log("handleNo... ", this.props.parentFuncStatus); 
    if(this.props.parentFuncStatus!==undefined){
      const obj={dialogConfirmStatus:false, response:false,};
      this.props.parentFuncStatus(obj);
    } 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");   
  }

  handleNoYesNo  = (event) => {  
    console.log("handleNoYesNo... ", this.props.parentFuncStatus); 
    if(this.props.parentFuncStatus!==undefined){
      const obj={dialogConfirmStatus:false, response:false,};
      this.props.parentFuncStatus(obj);
    } 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");   
  }


  render() {
    const { t } = this.props;
    //const open = this.state.openPossibleCDStatusInfo; 
    //lg sm md  fullWidth={"sm"} maxWidth={"lg"} 
    let message = this.props.message;
    const open = this.props.dialogConfirmStatus;
    const alertOnly = this.props.dialogAlertOnly;
    if(open===undefined || open===false){
      console.log("no window to show... ", open);
      return "";
    }

    if(message===undefined || message===null){
      console.log("no window to show... ", open);
      message="pas de texte a montrer/No text to show";
    }

    console.log("popup window to show...");
    const yesbtnTxt=t("Yes_x");
    const nobtnTxt=t("No_x");
    const noyesnobtnTxt=t("Close_x");
    

    return (
      <div>
      <Dialog maxWidth={"md"} open={open} onClose={this.handleCloseListOfCDStatusInfo} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirmclient4">
        <DialogContent id="form-dialog-confirmclient4">
        
        {/* <div className="text-center"><h2>{t("zqwCViewEl16_x")}</h2></div> 
        <p>{t("zqwCViewEl17_x")} # {this.state.invoiceId} </p> 
        <p> &nbsp; </p> 
        <p>{this.state.chargeDeskIdStr} </p>  */}
        {/* <p> &nbsp; </p> */}

        <p> &nbsp; </p> 
        {/* <p>Ooooooooooohlala oooooooolala mama mia </p> */}
        <p>{message} </p>

        <div className="form-row neoxtbl">
  
          <div className="form-group col-md-9">
          <br/>
          {/* <input type="button" onClick={this.handleCloseListOfCDStatusInfo} className="btn btn-default" value={'Fermer/Close'} /> &nbsp; &nbsp; */}
            
        
        {/* <button type="button" className="btn btn-primary" onClick={this.handleYes}> {yesbtnTxt} </button>  &nbsp; &nbsp;
        <button type="button" className="btn btn-default" onClick={this.handleNo}> {nobtnTxt} </button> */}

        {(alertOnly===undefined || alertOnly===false)?
          <> 
            <button type="button" className="btn btn-primary" onClick={this.handleYes}> {yesbtnTxt} </button>  &nbsp; &nbsp;
            <button type="button" className="btn btn-default" onClick={this.handleNo}> {nobtnTxt} </button>
          </>: <button type="button" className="btn btn-default" onClick={this.handleNoYesNo}> {noyesnobtnTxt} </button>
        }

        {/* } */}
          </div> 
        </div>
          
        </DialogContent>
        
      </Dialog>
      </div>
    );
  }
}

//const TransNeoSearchForm = withTranslation()(DialogConfirm); 
const mapStateToProps = (state) => ({ NeoSearch: state.NeoSearch });

const mapDispatchToProps = {  }; //{ addOrUpdateNeoSearch, getNeoSearch };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(DialogConfirm));
