import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NEOSALESPLANNINGWEEKLY = 'GET_NEOSALESPLANNINGWEEKLY';
export const ADD_NEOSALESPLANNINGWEEKLY = 'ADD_NEOSALESPLANNINGWEEKLY';
export const RECEIVE_NEOSALESPLANNINGWEEKLYX = 'RECEIVE_NEOSALESPLANNINGWEEKLYX';
export const REMOVE_NEOSALESPLANNINGWEEKLY = 'REMOVE_NEOSALESPLANNINGWEEKLY';
export const UPDATE_NEOSALESPLANNINGWEEKLY = 'UPDATE_NEOSALESPLANNINGWEEKLY';
export const REPLACE_NEOSALESPLANNINGWEEKLY = 'REPLACE_NEOSALESPLANNINGWEEKLY';
const apiUrl = API_DOMAIN + '/salesplanning/weeks'; //'/neosalesplanningweekly'; 
const apiUrl2 = API_DOMAIN + '/salesplanning/weeks/weekplans';
 
axiosDefaults(null);

export const getNeoSalesPlanningWeeklyAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/weekplans`)
      .then(response => { 
        return dispatch({type: RECEIVE_NEOSALESPLANNINGWEEKLY, NeoSalesPlanningWeekly: response.data.data.planningweekly});
      })
      .catch(error => { console.error(error); });
  };
};

export const addNeoSalesPlanningWeekly = (NeoSalesPlanningWeekly) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/weekplans`, NeoSalesPlanningWeekly)
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NEOSALESPLANNINGWEEKLY, payload: {
			id: data.id,
			week: data.week,
			week_start: data.week_start,
			week_end: data.week_end,
			expected_quantity: data.expected_quantity,
			expected_amount: data.expected_amount,
			invoice_quantity: data.invoice_quantity,
			invoice_amount: data.invoice_amount,
			payment_quantity: data.payment_quantity,
			payment_amount: data.payment_amount,
			creation_date: data.creation_date,
			last_update: data.last_update,
			user_creation: data.user_creation,
			user_update: data.user_update,
		}})
      })
      .then(() => {
        history.push("/NeoSalesPlanningWeekly")
      })
      .catch(error => { console.error(error) });
  };
};
export const getNeoSalesPlanningWeekly = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl2}?id=${id}`)
      .then(response => {
		  //console.log("response from server .... ");  console.log(response.data.data);		  
        return dispatch({type: RECEIVE_NEOSALESPLANNINGWEEKLYX, NeoSalesPlanningWeeklyOne: response.data.data});
      })
      .catch(error => { 
        console.error(error); 
      });
  };
};
export const getNeoSalesPlanningWeeklyGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`./NeoSalesPlanningWeekly/${id}/edit`)
  };
};
export const deleteNeoSalesPlanningWeekly = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NEOSALESPLANNINGWEEKLY, payload: {id}})
      })
      .then(() => {
        history.push("/NeoSalesPlanningWeekly")
      })
      .catch(error => {
        console.error(error);
      });
  };
};
export const updateNeoSalesPlanningWeekly = (NeoSalesPlanningWeekly) => {
  const id = NeoSalesPlanningWeekly.id;
  return (dispatch) => {
    return axios.post(`${apiUrl}/weekplans`, NeoSalesPlanningWeekly)
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NEOSALESPLANNINGWEEKLY, payload: {
			id: data.id,
			week: data.week,
			week_start: data.week_start,
			week_end: data.week_end,
			expected_quantity: data.expected_quantity,
			expected_amount: data.expected_amount,
			invoice_quantity: data.invoice_quantity,
			invoice_amount: data.invoice_amount,
			payment_quantity: data.payment_quantity,
			payment_amount: data.payment_amount,
			creation_date: data.creation_date,
			last_update: data.last_update,
			user_creation: data.user_creation,
			user_update: data.user_update,
		}})
        dispatch({type: REPLACE_NEOSALESPLANNINGWEEKLY, payload: {
			id: data.id,
			week: data.week,
			week_start: data.week_start,
			week_end: data.week_end,
			expected_quantity: data.expected_quantity,
			expected_amount: data.expected_amount,
			invoice_quantity: data.invoice_quantity,
			invoice_amount: data.invoice_amount,
			payment_quantity: data.payment_quantity,
			payment_amount: data.payment_amount,
			creation_date: data.creation_date,
			last_update: data.last_update,
			user_creation: data.user_creation,
			user_update: data.user_update,
		}})
      })
      .then(() => {
        history.push(`./NeoSalesPlanningWeekly/${id}`)
      })
      .catch(error => { console.error(error) });
  };
};
export const addOrUpdateNeoSalesPlanningWeekly = (NeoSalesPlanningWeekly, actionMode) => {
	if(actionMode===1){
        return addNeoSalesPlanningWeekly(NeoSalesPlanningWeekly);
      }else {
        return updateNeoSalesPlanningWeekly(NeoSalesPlanningWeekly);
      }
};