import { RECEIVE_NEOB2BLEADPROSPECTS, ADD_NEOB2BLEADPROSPECTS, REMOVE_NEOB2BLEADPROSPECTS, REPLACE_NEOB2BLEADPROSPECTS } from '../actions/NeoB2bleadProspectsAction';

const initialState = { NeoB2bleadProspects: [] }
export default function NeoB2bleadProspectsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NEOB2BLEADPROSPECTS:
      return action.NeoB2bleadProspects;
    case ADD_NEOB2BLEADPROSPECTS:
      return action.NeoB2bleadProspects;
      //return [action.payload, ...state];
    case REMOVE_NEOB2BLEADPROSPECTS:
      return state.filter(NeoB2bleadProspects => NeoB2bleadProspects.id !== action.payload.id);
    case REPLACE_NEOB2BLEADPROSPECTS:
      return state.map((NeoB2bleadProspects) => {
        if (NeoB2bleadProspects.id === action.payload.id) {
          return {
            ...NeoB2bleadProspects,
firstname: action.payload.firstname,
lastname: action.payload.lastname,
cell: action.payload.cell,
email: action.payload.email,
gender: action.payload.gender,
language: action.payload.language,
dob: action.payload.dob,
source: action.payload.source,
current_insurances: action.payload.current_insurances,
sent: action.payload.sent,
id_invoices: action.payload.id_invoices,
user_creation: action.payload.user_creation,
created: action.payload.created,
          }
        } else return NeoB2bleadProspects;
      })
    default:
      return state;
  }
}
