import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getZxCompanies, deleteZxCompanies } from '../../actions/ZxCompaniesAction';

class ZxCompaniesInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		name:'', e_name: false,
		joining_date:'', e_joining_date: false,
		address:'', e_address: false,
		phone:'', e_phone: false,
		dob:'', e_dob: false,
		zipcode:'', e_zipcode: false,
		suite:'', e_suite: false,
		city:'', e_city: false,
		state:'', e_state: false,
		country:'', e_country: false,
		company_code:'', e_company_code: false,
    }
  }
  componentDidMount() {                                                         
    //this.props.getZxCompanies(this.props.match.params.id);
	const idx = this.props.match.params.id;  
	Promise.resolve(this.props.getZxCompanies(idx))
	.then((response)=>{
		//console.log("0000********INI**********"); console.log(response.ZxCompaniesOne);  console.log("0000********FIN**********");
		this.setState(response.ZxCompaniesOne); 
		return response;
	});
  }

  render() {
    const { t } = this.props;
    //const ZxCompanies = this.props.ZxCompanies;
    const ZxCompanies = this.state; 
    return (
      <div>
        <h2>ZxCompanies</h2>
        <hr/>
        <p><label>{t('id_x')}</label> &nbsp; &nbsp; #{ZxCompanies.id}</p>
        <p><label>{t('name_x')}</label><br/>{ZxCompanies.name}</p>
        <p><label>{t('joining_date_x')}</label><br/>{ZxCompanies.joining_date}</p>
        <p><label>{t('address_x')}</label><br/>{ZxCompanies.address}</p>
        <p><label>{t('phone_x')}</label><br/>{ZxCompanies.phone}</p>
        <p><label>{t('dob_x')}</label><br/>{ZxCompanies.dob}</p>
        <p><label>{t('zipcode_x')}</label><br/>{ZxCompanies.zipcode}</p>
        <p><label>{t('suite_x')}</label><br/>{ZxCompanies.suite}</p>
        <p><label>{t('city_x')}</label><br/>{ZxCompanies.city}</p>
        <p><label>{t('state_x')}</label><br/>{ZxCompanies.state}</p>
        <p><label>{t('country_x')}</label><br/>{ZxCompanies.country}</p>
        <p><label>{t('company_code_x')}</label><br/>{ZxCompanies.company_code}</p>
        <hr/>
        <div className="btn-group">
          <Link to={{ pathname: `/ZxCompanies/${ZxCompanies.id}/edit`, state: { ZxCompanies: ZxCompanies } }} className='btn btn-info'>  
            {t('Edit')}
          </Link>
          <button className="btn btn-danger" type="button" onClick={() => this.props.deleteZxCompanies(ZxCompanies.id)}>          
            {t('Delete')}
          </button>
          <Link to="./ZxCompanies" className="btn btn-secondary">{t('Close')}</Link>                                                 
        </div>
        <hr/>
      </div>
    )
  }
}

//const TransZxCompaniesInfo = withTranslation()(LegacyComponentClass);
const mapStateToProps = (state) => ({ ZxCompanies: state.ZxCompanies });                 

const mapDispatchToProps = { getZxCompanies, deleteZxCompanies };                        

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(ZxCompaniesInfo));   

