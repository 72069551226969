import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNeoSalesPlanningDaily, getNeoSalesPlanningDaily, getNeoSalesPlanningDailyAll, getNeoSalesPlanningDailyGoto, deleteNeoSalesPlanningDaily, getNeoSalesPlanningDailyWeek } from '../../actions/NeoSalesPlanningDailyAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "../CustomToolbarSelect";

class NeoSalesPlanningDailyList extends Component {
	constructor(props) {
		super(props); 
		this.state={weekId:0, showWeekDays:false };
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 //weekId={this.state.weekId} showWeekDays
	}
  
	componentDidMount() { 
		if((this.props.weekId && this.props.weekId >0) && (this.props.showWeekDays && this.props.showWeekDays===true)){
			this.setState({["weekId"]: this.props.weekId, ["showWeekDays"]: true });
			this.props.getNeoSalesPlanningDailyWeek(this.props.weekId); 
		}else if((this.props.showWeekDays && this.props.showWeekDays===true)){
			this.setState({["weekId"]: this.props.weekId, ["showWeekDays"]: true });
			//this.props.getNeoSalesPlanningDailyWeek(this.props.weekId); 
		}else{
			this.props.getNeoSalesPlanningDailyAll();
		}
		//this.props.getNeoSalesPlanningDailyWeek(1); //
		//this.props.getNeoSalesPlanningDailyAll();
	}
	
	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.NeoSalesPlanningDaily;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteNeoSalesPlanningDaily(gridIds[0]);

	}

  updateXRecordsMain(selectedRows){  
    const { t } = this.props;
	const objList  = this.props.NeoSalesPlanningDaily;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
      gridIndexes.push(row.index); 
      idIndex=row.index; 
	return null;
    });
 
    if(gridIndexes.length>1){
      alert(`{t('zqwgridIndexesfound_x')}`);
      return false;
    }
 
    let obj=objList[idIndex]; 
	this.props.getNeoSalesPlanningDailyGoto(obj.id); 
	//history.push(`./neosalesplanningdaily/${obj.id}/edit`); 
  }
  

  render() { 
    const { t } = this.props;
	const columns = [
	 {
	  name: "id", label: t('zqwid_x'),
	  options: { filter: true, sort: true,}
	 },
	//  {
	//   name: "week_plan_id", label: t('zqwweek_plan_id_x'),
	//   options: { filter: true, sort: true,}
	//  },
	 {
	  name: "work_date", label: t('zqwwork_date_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "expected_quantity", label: t('zqwexpected_quantity_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "expected_amount", label: t('zqwexpected_amount_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "invoice_quantity", label: t('zqwinvoice_quantity_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "invoice_amount", label: t('zqwinvoice_amount_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "payment_quantity", label: t('zqwpayment_quantity_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "payment_amount", label: t('zqwpayment_amount_x'),
	  options: { filter: true, sort: true,}
	 },

	 /*
	 {
	  name: "creation_date", label: t('zqwcreation_date_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "last_update", label: t('zqwlast_update_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "user_creation", label: t('zqwuser_creation_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "user_update", label: t('zqwuser_update_x'),
	  options: { filter: true, sort: true,}
	 },*/

	];
const textLabelOptions= {
    body: {
      noMatch: t('zqwgridnoMatch_x'),
      toolTip: t('zqwgridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('zqwgridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('zqwgridnext_x'),
      previous: t('zqwgridprevious_x'),
      rowsPerPage: t('zqwgridrowsPerPage_x'),
      displayRows: t('zqwgriddisplayRows_x'),
    },
    toolbar: {
      search: t('zqwgridsearch_x'),
      downloadCsv: t('zqwgriddownloadCsv_x'),
      print: t('zqwgridprint_x'),
      viewColumns: t('zqwgridviewColumns_x'),
      filterTable: t('zqwgridfilterTable_x'),
    },
    filter: {
      all: t('zqwgridall_x'),
      title: t('zqwgridftitle_x'),
      reset: t('zqwgridreset_x'),
    },
    viewColumns: {
      title: t('zqwgridvctitle_x'),
      titleAria: t('zqwgridvctitleAria_x'),
    }, 
  };	
			
	const options = {
	  filterType: 'checkbox',
	  textLabels: textLabelOptions,
	  onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		//console.log(currentRowsSelected, allRowsSelected);
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log(allRowsSelected);
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} isEdit={true} isDelete={true} />
      ),
	};  
	//["weekId"]: this.props.weekId, ["showWeekDays"
    if(this.props.NeoSalesPlanningDaily.length) {                                
      return (
        <div>
		{this.state.weekId==0? <h4>{t('zqwNeoSalesPlanningDaily_x')}</h4>:''}		  
		  <MUIDataTable
			  title={""}
			  data={this.props.NeoSalesPlanningDaily}
			  columns={columns}
			  options={options}
			/>
        </div>
      )    
    } else {
      return (<div>{t('zqwgridNoRecordfound_x')}</div>)
    }  }
}


const mapStateToProps = (state) => ({ NeoSalesPlanningDaily: state.NeoSalesPlanningDaily });  
const mapDispatchToProps = { addOrUpdateNeoSalesPlanningDaily, getNeoSalesPlanningDaily, getNeoSalesPlanningDailyAll, getNeoSalesPlanningDailyGoto, deleteNeoSalesPlanningDaily, getNeoSalesPlanningDailyWeek };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoSalesPlanningDailyList));
