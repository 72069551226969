import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateSalesFlow, getSalesFlow, getSaleFlow } from '../../actions/SalesFlowAction'; 
import { Validations } from '../../utils/validations'; 

import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 
import Toastr from "../../messages";
import  Loader from "../../loader";
import CheckIcon from '@material-ui/icons/Check';

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['invoice']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['flow']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['description']={check:false, chkType:'String', message:'incorrect value'};

class SalesFlowForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000, 
		id:0,
		invoice:'', e_invoice: false,
		flow:'', e_flow: false,
		description:'', e_description: false,
		saleFlows:{}, 
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	const { t } = this.props;
	// if(this.actionMode==='edit'){ 
	// 	console.log('edit  test  '+this.props.SalesFlow.id);
	// 	if(this.props.SalesFlow.id===undefined){ 
	// 		//means some refresh happens  id
	// 		const idx = this.props.match.params.id; 
	// 		//getSalesFlow(idx);
	// 		Promise.resolve(this.props.getSalesFlow(idx))
	// 		.then((response)=>{
	// 			this.setState(response.SalesFlowOne);
	// 			return response;
	// 		});
	// 	}	
	// }
	
	// const client_id=this.state.client_id;
	// const ticket_id=this.state.ticket_id;
	// const invoiceId=this.state.invoiceId;
	const lang=t("curlang");
	const invoiceId=this.props.invoiceId;
	const SalesFlow={invoiceId:invoiceId, lang:lang};
	// getSaleFlow = (SalesFlow)
	// client_id={client_id} ticket_id={ticket_id} invoiceId={invoiceId}
	
	const this2=this; 
	this.setState({ ["isLoaderOpen"]: true, });  
	Promise.resolve(this.props.getSaleFlow(SalesFlow))
	.then((response)=>{
		//this.setState(response.NeoB2bleadProspectsOne);
		const dataResp = response.SalesFlowOne; 
		// this.setState({["openTransactionPopup"]: true, ["isLoaderOpen"]: false }); 
		this.setState({["isLoaderOpen"]: false, ["saleFlows"]: dataResp.data, }); 
		 
		// console.log("SalesFlowOne laaaaaaaaaaast", response, dataResp); 
		return response;
	}).catch(function(error){ 
		// this2.setState({ ["isLoaderOpen"]: false});   
		// this2.showToast(error, "error");  
		const errObj=error;
		this2.setState({ ["isLoaderOpen"]: false, });  
		///let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		//let errMessage="Error/Erreur ";
		const statusCode=errObj.statusCode;
		if(errObj.status===undefined && statusCode===undefined){
			errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//this2.saveUpdateTicketDataToZendesks(zendeskList); 
			if (typeof errObj === 'string' || errObj instanceof String){
				console.log("inside undefined");  
				this2.showToast(errObj, "error");  
			}else{
				console.log("inside else undefined");  
				this2.showToast(errMessage, "error");  
			} 
			//this2.showToast(errMessage, "error");  
			console.log("FAKE ERRORS SOLVED MINIMIZED");  
			console.log(errObj);
		}
		else if(statusCode===500){   //else if(errObj.status===500){
			//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//errMessage +="\n 9999999 ".errObj.data.error.description;
			//errMessage +="\n 9999999 ".errObj.error.description;
			let mesx=errObj.error.description;
			if(mesx===undefined){ mesx=""; }
			errMessage ="SERVER ERROR INTERNAL  "+mesx;
			this2.showToast(errMessage, "error");  
			console.log("500 ERROR");  
			console.log(errObj);  
		}else{
			//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
			//this00.showToastError(errMessage);
			//alert(errMessage); //error
			this2.showToast(errMessage, "error");
			console.log("UNDEFINED ERROR");  
			console.log(errObj);  
		}
	});

  }

  showToast = (arg, toastType) => {
	//toastType: success or error or warning or info
	const toastrDuration=this.state.toastrDuration; //5500	 
	this.setState({  
		toastrMessage: arg, 
	toastrType: toastType
	});
	setTimeout(() => { 
	this.setState({ 
		toastrMessage: "", 
		toastrType: 'success'
	}); 
	}, toastrDuration);
}

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const invoice = this.state.invoice;
    const flow = this.state.flow;
    const description = this.state.description;
    const SalesFlow = {id: id, invoice: invoice, flow: flow, description: description, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.SalesFlow[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateSalesFlow(SalesFlow, 1); 
		//this.props.addSalesFlow(SalesFlow); 
	}else{ 
		this.props.addOrUpdateSalesFlow(SalesFlow, 2);
		//this.props.updateSalesFlow(SalesFlow);
		
	}
  };

  handleCancel = () => { 
	// if(this.actionMode==='new'){ 
	// 	this.props.history.push(`/SalesFlow`);
	// }else{ 
	// 	const idx = this.props.match.params.id; 
	// 	this.props.history.push(`/SalesFlow/${idx}`);
	// 	//this.props.history.push(`/SalesFlow/${this.props.SalesFlow.id}`);
	// }
  }

  getQuanityOfFlow = (ActionFlows, flowId) => {
	  let qty=0; 
	for(let actionf of ActionFlows){
		if(actionf.flowid===flowId){
			qty++;
		}
	}

	return qty;
  }

  render() {
    const { t } = this.props;
	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType;
	const saleFlows=this.state.saleFlows;
	const lang=t("curlang");

	let flowsx =null; 
	let baseflowsx =null; 

	if(saleFlows.flows!==undefined && saleFlows.flows!==null){
		flowsx = saleFlows.flows.length > 0
			&& saleFlows.flows.map((item, i) => { 
			//console.log(item);
			if(item!==null){
			return(
				<tr key={"rfl"+item.id}>
				<td>{item.id}</td>
				<td>{item.flow}</td>
				<td>{item.description}</td>
				<td>{item.creation_date}</td>
				</tr>
			); 

			
			}
		return ( null  );
		}, this);
	}


	if(saleFlows.baseFlows!==undefined && saleFlows.baseFlows!==null){
		baseflowsx = saleFlows.baseFlows.length > 0
			&& saleFlows.baseFlows.map((item, i) => { 
			//console.log(item);
			if(item!==null){
				const desName=lang==="fr"?item.name:item.name_en;
				const qtyFlow=this.getQuanityOfFlow(saleFlows.flows, item.id);
			return(
				<tr key={"bfl"+item.id}>
				<td>{item.id}</td>
				<td>{desName}</td>
				<td> {qtyFlow > 0?<CheckIcon  style={{ color: "green", fontWeight:"bolder" }} />:"" } </td> 
				</tr>
			); 

			
			}
		return ( null  );
		}, this);
	}

    return (
      <div>
        <h3>{t('zqwSalesFlow_x')}</h3>
        		 
		<div className="neoTablecollapse">
			<table className="neoTabcol">
			  <thead>
				  <tr>
					<th>Id</th>
					<th>Flow</th>
					<th>Action</th>
				  </tr>
			  </thead>
			  <tbody> 
				{baseflowsx}
			  </tbody>
			</table>
		</div>
		 
		<br/>
		<h3>{t('zqwacEditSalesFlow23_x')}</h3>
		<table className="table table-striped">
			<thead>
				<tr>
				<th>Id</th>
				<th>Flow</th>
				<th>Description</th>
				<th>Date</th>
				</tr>
			</thead>
			<tbody> 
				{flowsx}
			</tbody>
    	</table>
	
		<Loader  openx={this.state.isLoaderOpen}  />
		<Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
      </div>
    );
  }
}

//const TransSalesFlowForm = withTranslation()(SalesFlowForm); 
const mapStateToProps = (state) => ({ SalesFlow: state.SalesFlow });

const mapDispatchToProps = { addOrUpdateSalesFlow, getSalesFlow, getSaleFlow };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(SalesFlowForm));
