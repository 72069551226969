import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getZendLeads, deleteZendLeads } from '../../actions/ZendLeadsAction';

class ZendLeadsInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		reason:'', e_reason: false,
		assig_by:'', e_assig_by: false,
		assig_date:'', e_assig_date: false,
		assig_to:'', e_assig_to: false,
		recall_date:'', e_recall_date: false,
		update_by:'', e_update_by: false,
		last_update:'', e_last_update: false,
		viewing_by:'', e_viewing_by: false,
		viewing_date:'', e_viewing_date: false,
		status:'', e_status: false,
		p_id:'', e_p_id: false,
		definition:'', e_definition: false,
		ticket_id:'', e_ticket_id: false,
		client_id:'', e_client_id: false,
		email:'', e_email: false,
		date_created:'', e_date_created: false,
		delay:'', e_delay: false,
		done:'', e_done: false,
		date_executed:'', e_date_executed: false,
		result:'', e_result: false,
		ticket_type:'', e_ticket_type: false,
    }
  }
  componentDidMount() {                                                         
    //this.props.getZendLeads(this.props.match.params.id);
	const idx = this.props.match.params.id;  
	Promise.resolve(this.props.getZendLeads(idx))
	.then((response)=>{
		//console.log("0000********INI**********"); console.log(response.ZendLeadsOne);  console.log("0000********FIN**********");
		this.setState(response.ZendLeadsOne); 
		return response;
	});
  }

  render() {
    const { t } = this.props;
    //const ZendLeads = this.props.ZendLeads;
    const ZendLeads = this.state; 
    return (
      <div>
        <h2>ZendLeads</h2>
        <hr/>
        <p><label>{t('zqwid_x')}</label> &nbsp; &nbsp; #{ZendLeads.id}</p>
        <p><label>{t('zqwreason_x')}</label><br/>{ZendLeads.reason}</p>
        <p><label>{t('zqwassig_by_x')}</label><br/>{ZendLeads.assig_by}</p>
        <p><label>{t('zqwassig_date_x')}</label><br/>{ZendLeads.assig_date}</p>
        <p><label>{t('zqwassig_to_x')}</label><br/>{ZendLeads.assig_to}</p>
        <p><label>{t('zqwrecall_date_x')}</label><br/>{ZendLeads.recall_date}</p>
        <p><label>{t('zqwupdate_by_x')}</label><br/>{ZendLeads.update_by}</p>
        <p><label>{t('zqwlast_update_x')}</label><br/>{ZendLeads.last_update}</p>
        <p><label>{t('zqwviewing_by_x')}</label><br/>{ZendLeads.viewing_by}</p>
        <p><label>{t('zqwviewing_date_x')}</label><br/>{ZendLeads.viewing_date}</p>
        <p><label>{t('zqwstatus_x')}</label><br/>{ZendLeads.status}</p>
        <p><label>{t('zqwp_id_x')}</label><br/>{ZendLeads.p_id}</p>
        <p><label>{t('zqwdefinition_x')}</label><br/>{ZendLeads.definition}</p>
        <p><label>{t('zqwticket_id_x')}</label><br/>{ZendLeads.ticket_id}</p>
        <p><label>{t('zqwclient_id_x')}</label><br/>{ZendLeads.client_id}</p>
        <p><label>{t('zqwemail_x')}</label><br/>{ZendLeads.email}</p>
        <p><label>{t('zqwdate_created_x')}</label><br/>{ZendLeads.date_created}</p>
        <p><label>{t('zqwdelay_x')}</label><br/>{ZendLeads.delay}</p>
        <p><label>{t('zqwdone_x')}</label><br/>{ZendLeads.done}</p>
        <p><label>{t('zqwdate_executed_x')}</label><br/>{ZendLeads.date_executed}</p>
        <p><label>{t('zqwresult_x')}</label><br/>{ZendLeads.result}</p>
        <p><label>{t('zqwticket_type_x')}</label><br/>{ZendLeads.ticket_type}</p>
        <hr/>
        <div className="btn-group">
          <Link to={{ pathname: `/ZendLeads/${ZendLeads.id}/edit`, state: { ZendLeads: ZendLeads } }} className='btn btn-info'>  
            {t('Edit')}
          </Link>
          <button className="btn btn-danger" type="button" onClick={() => this.props.deleteZendLeads(ZendLeads.id)}>          
            {t('Delete')}
          </button>
          <Link to="/ZendLeads" className="btn btn-secondary">{t('Close')}</Link>                                                 
        </div>
        <hr/>
      </div>
    )
  }
}

//const TransZendLeadsInfo = withTranslation()(LegacyComponentClass);
const mapStateToProps = (state) => ({ ZendLeads: state.ZendLeads });                 

const mapDispatchToProps = { getZendLeads, deleteZendLeads };                        

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(ZendLeadsInfo));   

