import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./IconButton.css";
import { Spinner } from "reactstrap";
import { Tooltip } from "@mui/material";
export default class IconButton extends Component {
  render() {
    const {
      onClick,
      icon,
      style,
      size,
      id,
      tooltip,
      buttonStyle,
      disabled,
      loading,
    } = this.props;
    const realStyle = disabled
      ? { ...buttonStyle, cursor: "not-allowed" }
      : { ...buttonStyle };
    return (
      <Tooltip title={tooltip && !disabled ? tooltip : ""}>
        <button
          style={realStyle}
          className="icon-button"
          onClick={onClick}
          disabled={disabled}
        >
          {!loading ? (
            icon ? (
              <FontAwesomeIcon style={style} icon={icon} size={size} />
            ) : (
              ""
            )
          ) : (
            <Spinner
              size="sm"
              style={{ marginBottom: "2px", color: "#0076CF" }}
            />
          )}
        </button>
      </Tooltip>
    );
  }
}
