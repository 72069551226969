import React from "react";

import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    NavLink,
    ButtonGroup
    // Table
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faCoins,
    faTachometerAlt,
    faTags,
    faFilter,
    faGlobeAmericas,
    faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import DashboardElement from '../../../../utils/DashboardElement'
import DateTimePicker from "react-datetime-picker";
export default class Reminder extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return (
            <div style={{margin:"0 0 20px 0"}}>
                <h4>Fixer un rappel pour ce lead</h4>
                <DateTimePicker />
            </div>
        )
    }
}