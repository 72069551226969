import React from "react";
import Select from "react-select";
import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledAlert,
  Spinner,
  Button,
} from "reactstrap";

export default class EditAgentModal extends React.Component {
  render() {
    const {
      toggleEditUser,
      showChangeUser,
      error,
      selectedUser,
      domaineOptions,
      handleSelectChange,
      handleSelectChangex, 
      languageOptions,
      provinceOptions,
      handlePost,
      loading,
      lang,
      trad,
      genLang
    } = this.props;

    const thermo_orderAr=[{label:"", value:selectedUser.thermo_order}];

    //console.log("heeeeeeeeeeeeeeeooooooooooo", this.props);

    const thermoOptions = [
      {label: genLang('cold'), value: '1'},
      {label: genLang('warm'), value: '2'},
      {label: genLang('hot'), value: '3'},
    ];
    const thermOrderOptions = [{label: 'Oldest to newest', value: '1'}, {label: 'Newest to oldest', value: '2'}];
    //console.log("heeeeeeeeeeeeeeeooooooooooo####", selectedUser.thermo_order, thermOrderOptions);      
    return (
      <Modal isOpen={showChangeUser} centered={true}>
        <ModalHeader toggle={toggleEditUser}>{genLang('editUser')}</ModalHeader>
        <ModalBody>
          {error && (
            <UncontrolledAlert color="danger">
              {genLang('postError')}
            </UncontrolledAlert>
          )}
          <h4 style={{ textAlign: "center", color: "#0052BB" }}>
            {selectedUser && selectedUser.user}
          </h4>
          <Label>{genLang('domain')}</Label>
          <Select
            options={domaineOptions}
            value={domaineOptions.filter((x) =>
              selectedUser.domaine.some((i) => x.id === i.id)
            )}
            onChange={(e) => {
              handleSelectChange(e, "domaine");
              console.log(e);
              console.log(selectedUser);
            }}
            isMulti
          />

          <Label style={{ marginTop: "1rem" }}>{genLang('language')}</Label>
          <Select
            options={languageOptions}
            value={languageOptions.filter((x) =>
              selectedUser.languages.some((i) => x.value === i.value)
            )}
            onChange={(e) => handleSelectChange(e, "languages")}
            isMulti
          />

          <Label style={{ marginTop: "1rem" }}>Province</Label>
          <Select
            options={provinceOptions}
            value={provinceOptions.filter((x) =>
              selectedUser.provinces.some((i) => x.value === i.value)
            )}
            onChange={(e) => handleSelectChange(e, "provinces")}
            isMulti
          />

          <Label style={{ marginTop: "1rem" }}>{genLang('priority')}</Label>
          <Select
            options={thermoOptions}
            value={thermoOptions.filter((x) =>
              selectedUser.thermos.some((i) => x.value === i.value)
            )}
            onChange={(e) => handleSelectChange(e, "thermos")}
            isMulti
          />

          <Label style={{ marginTop: "1rem" }}>Ordre/Order</Label>
          <Select
            options={thermOrderOptions}
            //value={selectedUser.thermo_order}
            value={thermOrderOptions.filter((x) =>  
              thermo_orderAr.some((i) => x.value === i.value)
            )}
            // value={thermOrderOptions.filter((x) => x.value === selectedUser.thermo_order 
            //   //selectedUser.provinces.some((i) => x.value === i.value)
            // )}
            onChange={(e) => handleSelectChangex(e, "thermo_order")} 
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleEditUser} outline color="danger">
            {genLang('close')}
          </Button>
          <Button onClick={() => handlePost()} outline color="primary">
            {loading ? (
              <div>
                {`${genLang('loading')}`}
                <Spinner color="primary" size="sm" />
              </div>
            ) : (
              `${genLang('register')}`
            )}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
