import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateZendMacros, getZendMacros, getZendMacrosAll, sendZendMacros, sendZendNoteMerge } from '../../actions/ZendMacrosAction'; 
import { addOrUpdateZendLeads, getZendLeads, getTicketZendLeads, sendSMSNote } from '../../actions/ZendLeadsAction'; 
import { Validations } from '../../utils/validations'; 

import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 
import Toastr from "../../messages";
import  Loader from "../../loader";
import DatePicker from "react-datepicker";
// import moment from 'moment'; 
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';  
import { HighlightSharp } from '@material-ui/icons';
import TableRowsIcon from '@material-ui/icons/List';
import { encryptText, decryptText, testEncryptionText } from '../../appEncryption';

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['name']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['phone']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['language']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['data_raw']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['status']={check:true, chkType:'boolean', message:'incorrect value'};
FIELD_VALIDATION['last_update']={check:true, chkType:'Timestamp', message:'incorrect value'};

class SendSMSNoteForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000, 
		id:'0', e_id: false,
		name:'', e_name: false,
		data_raw:'', e_data_raw: false,
		status:'', e_status: false,
		last_update:'', e_last_update: false,
		listmacro:[], listmacroid:"", macronameid:"", 
		message:"", messagetype:"", 
		editorState: EditorState.createEmpty(),
		showSubmitBtn: true, 
		noticket:"", leadTicket:null,  
		//phone:"", language:"",
		phone:'', e_phone: false,
		language:'', e_language: false, 
		languagefr:"",
		languageen:"", showRemainForm:0, external_id:"", showSuccessResp:false, 
		email:"", prospect_id:"0",
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	this.lastTicketSearch=''; 
	 
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
	if(event.target.name==="language"){
		//const macroMessage=draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
		//this.setState({ ["message"]: actItem.value });
		this.setSMSTextByLang(event.target.value); 
		/*const textRaw=this.getSMSTextByLang(event.target.value);
		const contentBlock = htmlToDraft(textRaw); 
		console.log("aaaaaaaaaading content ", event.target.value, textRaw); 
		// const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
		// const editorState = EditorState.createWithContent(contentState);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorState = EditorState.createWithContent(contentState);
			this.setState({ ["editorState"]: editorState, });
			console.log("goood aaaaaaaaaading content ", event.target.value,); 
		}*/
	}
	
	if(event.target.name==="noticket"){
		if(""+this.lastTicketSearch!==event.target.value){
			this.setState({ ["showRemainForm"]: 0 });
		}else if(""+this.lastTicketSearch===event.target.value){
			this.setState({ ["showRemainForm"]: 1 });
		}
		//this.setState({ ["showRemainForm"]: 0 });
	}
  };

//   https://github.com/jpuri/react-draft-wysiwyg

  handleChangex = (event) => {
	const id=event.target.value;
    this.setState({ [event.target.name]: event.target.value });
	const listmacro=this.state.listmacro;
	let listmacros = listmacro.length > 0
          && listmacro.map((item, i) => { 
			const raw=JSON.parse(item.data_raw);
			// const isPublic=raw.public===undefined?false:raw.public;
			// const pvcomment=isPublic===true?"":" pvcomment";  
			if(id!==item.id){ return null; }
			if(raw.active!==true){
				return null;
			}

			if(id===item.id){ 
				// this.setState({ ["message"]: raw.active }); 
				const actions=raw.actions;
				for (let actItem of actions) {
					console.log(actItem);  
					if(actItem.field==="comment_value_html"){
						this.setState({ ["message"]: actItem.value }); 
						const contentBlock = htmlToDraft(actItem.value); 
						// const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
						// const editorState = EditorState.createWithContent(contentState);
						if (contentBlock) {
							const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
							const editorState = EditorState.createWithContent(contentState);
							this.setState({ ["editorState"]: editorState, ["macronameid"]:item.name, });
							console.log("aaaaaaaaaading content ", item.id, item.name, actItem.value); 
						}

						// this.setState({ ["editorState"]: EditorState.createWithContent(actItem.value) }); 
						// editorState: EditorState.createEmpty(),
					}
					if(actItem.field==="comment_mode_is_public"){
						this.setState({ ["messagetype"]: actItem.value }); 
					}
				}
			} 

			// return (<option value={item.id} key={"us"+i}>{raw.title}</option>); 
			return null;   
          }, this); 
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	// if(this.actionMode==='edit'){ 
	// 	console.log('edit  test  '+this.props.ZendMacros.id);
	// 	if(this.props.ZendMacros.id===undefined){ 
	// 		//means some refresh happens  id
	// 		const idx = this.props.match.params.id; 
	// 		//getZendMacros(idx);
	// 		Promise.resolve(this.props.getZendMacros(idx))
	// 		.then((response)=>{
	// 			this.setState(response.ZendMacrosOne);
	// 			return response;
	// 		});
	// 	}	
	// }
		// backTicketId={backTicketId} neoemail={neoemail} 
		// client_id={""+client_id} client_name={""+client_name}

		// Promise.resolve(this.props.getZendMacrosAll())
		// .then((response)=>{
		// 	const macros=response.ZendMacros; 
		// 	console.log("Gooooooooooood macroooooooooooooos"); console.log(response); 
		// 	// this.setState(response.ZendMacrosOne);
		// 	this.setState({["listmacro"]:macros, ["isLoaderOpen"]: false, });
		// 	return response;
		// });
  }

  showToast = (arg, toastType) => {
	//toastType: success or error or warning or info
	const toastrDuration=this.state.toastrDuration; //5500	 
	this.setState({  
		toastrMessage: arg, 
	toastrType: toastType
	});
	setTimeout(() => { 
	this.setState({ 
		toastrMessage: "", 
		toastrType: 'success'
	}); 
	}, toastrDuration);
}

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const data_raw = this.state.data_raw;
    const status = this.state.status;
    const last_update = this.state.last_update;
    // const ZendMacros = {id: id, name: name, data_raw: data_raw, status: status, last_update: last_update, }
    const listmacroid=this.state.listmacroid;
	const messagetype=this.state.messagetype;
	const macronameid=this.state.macronameid;
	const macroMessage=draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
	const ticket=this.props.backTicketId===undefined?0:this.props.backTicketId;
	const client_id=this.props.client_id===undefined?0:this.props.client_id;
	const invoiceId=this.props.invoiceId===undefined?0:this.props.invoiceId;

	const leadClientId=this.props.leadClientId;
	const leadId=this.props.leadId;
	const leadTicketId=this.props.leadTicketId;
	// const backTicketId=this.props.backTicketId;
	const noticket=this.state.noticket;
	const phone=this.state.phone;
	const external_id=this.state.external_id;
	const email=this.state.email;
	 
	let errorFound=0;
	if(listmacroid===null || listmacroid==="" || macronameid===null || macronameid==="" 
	|| macroMessage===null || macroMessage==="" || macroMessage==="<p></p>"){
		// errorFound++;
	}

	if(noticket===null || noticket===""){
		this.showToast("# ticket", "error");  
		errorFound++;
	}

	if(parseInt(noticket)>0){ }else{ 
		this.showToast("# ticket", "error");  
		errorFound++;
	}

	if(macroMessage===null || macroMessage==="" || macroMessage==="<p></p>" || macroMessage==="<p></p>\n"){
		this.showToast("Note SMS", "error");  
		errorFound++;
	}

	if(phone===null || phone==="" || phone.length <10){
		this.showToast("# telephone", "error");  
		errorFound++;
	}


	/*for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.ZendMacros[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateZendMacros(ZendMacros, 1); 
		//this.props.addZendMacros(ZendMacros); 
	}else{ 
		this.props.addOrUpdateZendMacros(ZendMacros, 2);
		//this.props.updateZendMacros(ZendMacros);
		
	}*/

	//if(errorFound > 0){ window.alert(t('zqwerrorFoundonform_x')); return false; }

	console.log("SUUUUUBMIT",listmacroid,messagetype,macronameid,macroMessage);
	if(errorFound > 0){ window.alert(t('zqwerrorFoundonform_x')); return false; }
	// return false;
	const ZendMacros = {id: listmacroid, nameid: macronameid, invoiceid: invoiceId, ticket: ticket,  
		macroMessage: macroMessage, client_id:client_id, leadid:leadId, leadticket:leadTicketId,};

	const ZendNoteSMS = {smsNoteTrack: 77, ticket: noticket, email:email, prospect_id:"0", 
			note: macroMessage, phone:phone, external_id:external_id,};

		console.log("SUUUUUBMIT2222 ", ZendNoteSMS, ZendMacros); 
    
	 
	const this2=this; 
	this.setState({ ["isLoaderOpen"]: true, ["showSubmitBtn"]: false});  
	Promise.resolve(this.props.sendSMSNote(ZendNoteSMS))
	.then((response)=>{
		//this.setState(response.NeoB2bleadProspectsOne);
		const dataResp = response.data; 
		// this.setState({["openTransactionPopup"]: true, ["isLoaderOpen"]: false }); 
		this.setState({["isLoaderOpen"]: false, ["showSubmitBtn"]: false, ["showSuccessResp"]: true,}); 
		// if(this.props.frminputClose !== undefined ){ 
		// 	//this.setState({ [event.target.name]: onOffval }); 
		// 	//console.log("componentDidMount rndidpros= "+this.props.frminput.rndidpros+" firstname="+this.props.frminput.firstname);  
		// 	this.props.frminputClose(); 
		// }
		console.log("laaaaaaaaaaast", response, dataResp); 
		return response;
	}).catch(function(error){ 
		// this2.setState({ ["isLoaderOpen"]: false});   
		// this2.showToast(error, "error");  
		const errObj=error;
		this2.setState({ ["isLoaderOpen"]: false, ["showSubmitBtn"]: true});  
		///let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		//let errMessage="Error/Erreur ";
		const statusCode=errObj.statusCode;
		if(errObj.status===undefined && statusCode===undefined){
			errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//this2.saveUpdateTicketDataToZendesks(zendeskList); 
			if (typeof errObj === 'string' || errObj instanceof String){
				console.log("inside undefined");  
				this2.showToast(errObj, "error");  
			}else{
				console.log("inside else undefined");  
				this2.showToast(errMessage, "error");  
			} 
			//this2.showToast(errMessage, "error");  
			console.log("FAKE ERRORS SOLVED MINIMIZED");  
			console.log(errObj);
		}
		else if(statusCode===500){   //else if(errObj.status===500){
			//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//errMessage +="\n 9999999 ".errObj.data.error.description;
			//errMessage +="\n 9999999 ".errObj.error.description;
			let mesx=errObj.error.description;
			if(mesx===undefined){ mesx=""; }
			errMessage ="SERVER ERROR INTERNAL  "+mesx;
			this2.showToast(errMessage, "error");  
			console.log("500 ERROR");  
			console.log(errObj);  
		}else{
			//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
			//this00.showToastError(errMessage);
			//alert(errMessage); //error
			let mesx=""; //errObj.error.description;
			if(errObj.error!==undefined && errObj.error.description!==undefined){ mesx=errObj.error.description; }
			else if(errObj.data.data.errorMessage!==undefined){ mesx=errObj.data.data.errorMessage; }
			if(mesx!==null && mesx!==""){ errMessage ="SERVER ERROR  "+mesx; }
			this2.showToast(errMessage, "error");
			console.log("UNDEFINED ERROR");  
			console.log(errObj);  
		}
	});


  };

   
  handleTicketSearch = (event) => { 
	event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
	const noticket=this.state.noticket;
	console.log("...SOLVED handleTicketSearch ", noticket); 
	const zendedObj= {id:0, ticket:noticket, zendeskyes:1 };
	const this2=this; 
	if(parseInt(noticket) >0){}else{
		this.showToast(t("zqwerrorFoundonform_x")+" # ticket", "error"); 
		return false;
	}

	this.setState({ ["isLoaderOpen"]: true});  
	// const this2=this; getTicketZendLeads   Promise.resolve(this.props.getZendLeads(idx))
	Promise.resolve(this.props.getTicketZendLeads(zendedObj))
	.then((response)=>{
		this.lastTicketSearch=noticket; 
		const record=response.ZendLeadsOne; 
		//   this.setState({ ["isLoaderOpen"]: false, ["leadTicket"]: pendingList});
		let readonlyMode=false; let personTime="";
		let clientPhone="";  let email=""; let clientName="";
		let eId="0";
		if(record!==undefined && record.clientPhone!==undefined){
			clientPhone=record.clientPhone;
		}
		if(record!==undefined && record.external_id!==undefined && record.external_id!==null){
			eId=record.external_id;
		}
		if(record!==undefined && record.clientName!==undefined && record.clientName!==null){
			clientName=record.clientName;
		}
		if(record!==undefined && record.clientEmail!==undefined && record.clientEmail!==null){
			email=record.clientEmail;
		}

		
		// if(record.readonly===true){ 
		// 	readonlyMode=true; 
		// 	personTime=record.viewing_bytxt+" "+record.remainMinutes+" minutes";
		// }
		// if(record.id>0){ 
		// 	// id, reason, assig_by, assig_date, assig_to, recall_date, update_by, 
		// 	// last_update, viewing_by, viewing_date, status, 
		// 	// p_id, definition, ticket_id, client_id, email, date_created, delay, done, date_executed, result, ticket_type	
		// 	this.setState(record);
		// 	console.log("aaaaaaaaaaaasigne to all states");
		// }
		this.setState({["isLoaderOpen"]: false, ["leadTicket"]: record, 
		["phone"]: clientPhone, ["showRemainForm"]: 1, ["external_id"]:eId,
		["email"]:email, ["name"]:clientName,});
		//this.setState({ ["leadTicket"]: record, ["id"]:idx,});
		// this.setState({ ["isLoaderOpen"]: false, ["leadTicket"]: record, 
		// ["readonlyMode"]: readonlyMode, ["personTime"]:personTime, ["id"]:idx,});
		this.showToast(t("zqwCViewEl11_x"), "success"); 
		console.log("PEEEEEEENDING handleTicketSearch", response, record); 
		return response;
	}).catch(function(error){ 
		this2.setState({ ["isLoaderOpen"]: false});  
		window.alert(error);
	});

  }

  handleCancel = () => { 
	if(this.actionMode==='new'){ 
		this.props.history.push(`/ZendMacros`);
	}else{ 
		const idx = this.props.match.params.id; 
		this.props.history.push(`/ZendMacros/${idx}`);
		//this.props.history.push(`/ZendMacros/${this.props.ZendMacros.id}`);
	}
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  Alert  = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  
  handleGoToSMSList = () => { 
	// if(this.actionMode==='new'){ 
	// 	this.props.history.push(`/ZendMacros`);
	// }else{ 
	// 	const idx = this.props.match.params.id; 
	// 	this.props.history.push(`/ZendMacros/${idx}`);
	// 	//this.props.history.push(`/ZendMacros/${this.props.ZendMacros.id}`);
	// }
	this.props.history.push(`/prospectssmslist`);
  }

  handleReset = () => {  
	//this.props.history.push(`/prospectssmslist`);
	const mystates = {
		isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000, 
		id:'0', e_id: false,
		name:'', e_name: false,
		data_raw:'', e_data_raw: false,
		status:'', e_status: false,
		last_update:'', e_last_update: false,
		listmacro:[], listmacroid:"", macronameid:"", 
		message:"", messagetype:"", 
		editorState: EditorState.createEmpty(),
		showSubmitBtn: true, 
		noticket:"", leadTicket:null,  
		//phone:"", language:"",
		phone:'', e_phone: false,
		language:'', e_language: false, 
		languagefr:"",
		languageen:"", showRemainForm:0, external_id:"", showSuccessResp:false, 
		email:"", prospect_id:"0",
    };
	this.setState(mystates);  
  }

  render() {
    const { t } = this.props;
	const listmacro=this.state.listmacro;
	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType;

	let listmacros = listmacro.length > 0
          && listmacro.map((item, i) => { 
			const raw=JSON.parse(item.data_raw);
			// const isPublic=raw.public===undefined?false:raw.public;
			// const pvcomment=isPublic===true?"":" pvcomment"; 
			// const firstname=item.firstname;
			// const lastname=item.lastname;
			// const email=item.email;
			//const addObj=item.addFormatted;
			if(raw.active!==true){
				return null;
			}

			return (<option value={item.id} key={"us"+i}>{raw.title}</option>);   

               
          }, this);

		  const message=this.state.message;
		  const editorState= this.state.editorState;
		  //const clientName=this.props.client_name;
		  let clientName=""; let clientPhone="";
		  // backTicketId={backTicketId} neoemail={neoemail} 
		// client_id={""+client_id} client_name={""+client_name}
		const Alert=this.Alert;
		const showSubmitBtn=this.state.showSubmitBtn;

		// <ZendFusionNoteForm backTicketId={backTicketId} neoemail={neoemail} 
        //   client_id={""+client_id} client_name={""+client_name} invoiceId={invoiceId} 
        //   leadClientId={backLeadClId} leadId={backLeadReId} leadTicketId={backLeadTiId} /> 
		const leadClientId=this.props.leadClientId;
		const leadId=this.props.leadId;
		const leadTicketId=this.props.leadTicketId;
		const backTicketId=this.props.backTicketId;


		const leadTicket=this.state.leadTicket;
		let description=""; let subject ="";
		if(leadTicket!==null && leadTicket.description!==undefined){   
			let valCmp = leadTicket.description; //dataIndex
			let ticket = leadTicket.ticket_id;
			subject = leadTicket.subject;
			const zx=valCmp.split("http://send.neolegal.ca"); 
			description=leadTicket.description; //zx[0];
			description=description.replaceAll("\n\n", "<br/>");
			description=description.replaceAll("\n", "<br/>");
			////const comments=leadTicket.comments;
			//moment.locale('en'); 
			//// moment.locale('fr', [frLocale]); 
			//if(t("curlang")==="fr"){ moment.locale('fr', [frLocale]); }
			if(leadTicket.clientName!==undefined){
				clientName=leadTicket.clientName;
			}
			// if(leadTicket.clientPhone!==undefined){
			// 	clientPhone=leadTicket.clientPhone;
			// }
			//console.log(moment.locale());
			//console.log("WeeeeeeeeeeeeSMS==SMS", description);
		}

		clientPhone=this.state.phone;
		const showRemainForm=this.state.showRemainForm;
		const successResp=this.state.showSuccessResp;
		const clientEmail=this.state.email;


		//const phonePatt="([(]?[0-9]*[\)]?[\s]*[\(]?[0-9]+[\)]?[\s]*[\-]?[0-9]+[\-][0-9]+";
		const phonePatt="([\(]?[\+]?[0-9]*[\)]?[\s]*[\(]?[0-9]+[\)]?[\s]*[\-]?[0-9]+[\-][0-9]+)";

    return (
      <div>
        <h3>{t('zqwsendsms_x')}</h3>
        <form onSubmit={this.handleSubmit}> 
		<button type="button" className="btn btn-primary pull-right" onClick={this.handleGoToSMSList} >
		<TableRowsIcon /> {t('smsgridmu_x')}</button><br/>
          <div className="form-group">
			<label htmlFor="noticket"># Ticket</label>
            <input type="number" name="noticket" id="noticket" defaultValue={this.state.noticket} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={'form-control'} placeholder={"# ticket"} />  
			<button type="button" className="btn btn-primary" onClick={this.handleTicketSearch} >{t('gridsearch_x')}</button> &nbsp; 
          </div>

		  {showRemainForm===1?
		  <>
		  <div className="form-group">
			<label htmlFor="data_raw">Client: </label>
			<Alert severity="info">{t("zqwNVFrmEl43_x")}: <b>{clientName}</b></Alert> 
          </div>

		  {/* <div className="form-group"> 
			<Alert severity="warning">{t("zqwFlowvtx5_x")}: <b>#{leadTicketId}</b> {t("zqwFlowvtx6_x")} <b>#{backTicketId}</b> </Alert> 
          </div> */}

		  <div className="form-group">
			<label htmlFor="phone">{t('zqwphone_x')}</label>
            <input type="tel" name="phone" id="phone" defaultValue={clientPhone} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={'form-control'}  placeholder={t('zqwphone_x')} /> &nbsp; 
			  {/* className={'form-control'} pattern={phonePatt} placeholder={t('zqwphone_x')} /> */}
          </div>  

		  <div className="form-group">
			<label htmlFor="email">{t('zqwemail_x')}</label>
            <input type="tel" name="email" id="email" defaultValue={clientEmail} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={'form-control'}  placeholder={t('zqwemail_x')} /> &nbsp;  
          </div>  

		  <div className="form-group neoStantoutTxt"> 
			<b>{subject}</b><br/><br/>
			<div dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>

		  {/* <div className="form-group">
			<label htmlFor="id">{t('zqwid_x')}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
			{this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
          </div> */}

		  <div className="form-group">
			<label htmlFor="language">{t('zqwlanguage_x')}</label>
            <select name="language" id="language" defaultValue={this.state.language} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_language ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwlanguage_x')}> 
                        <option value="">Please Select</option>
						<option value="fr">Francais</option>
						<option value="en">English</option> 
                    </select>
			{this.state.e_language && (<span className="text-danger">{FIELD_VALIDATION['language'].message}</span>)} 
          </div>



		  <div className="form-group">
		  <label>{t('zqwFlowvtsmsx7_x')}</label>
			{/* <label htmlFor="message">{t('zqwname_x')}</label>
			<textarea id="message" name="message" rows="4" cols="50"  defaultValue={message}></textarea> */}
			<Editor
				editorState={editorState}
				// toolbarClassName="toolbarClassName"
				// wrapperClassName="wrapperClassName"
				// editorClassName="editorClassName"
				wrapperClassName="wrapper-class"
				editorClassName="editor-class"
				toolbarClassName="toolbar-class"
				onEditorStateChange={this.onEditorStateChange}
				/>
            {/* <input type="text" name="name" id="name" defaultValue={this.state.name} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_name ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwname_x')} /> 
			{this.state.e_name && (<span className="text-danger">{FIELD_VALIDATION['name'].message}</span>)}  */}
          </div>

          {showSubmitBtn===true?
			<div className="form-group">
            <button type="submit" className="btn btn-primary">{t('send_x')}</button> &nbsp; 
			{/* <button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button> */}
          </div>:<div className="form-group">
              &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  
          </div>
		  }

		{successResp===true?
		<div className="row">
		  <div className="form-group">
			<label htmlFor="data_raw">SMS</label>
			<Alert severity="success">{t("zqwFlowvtsmsx8_x")}</Alert> 
          </div>
		  <div className="form-group"> 
			<button type="button" className="btn btn-warning" onClick={this.handleReset}>{t('zqwNVFrmEl45_x')}</button>
		  </div>
		  </div> :""}

		  </>:<div className="form-group" style={{minHeight:"400px"}}>
              &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  
          </div>
		  }

		  {/* {successResp===true?
		  <div className="form-group">
			<label htmlFor="data_raw">SMS</label>
			<Alert severity="success">{t("zqwNVFrmEl43_x")}</Alert> 
          </div>:""} */}

		  <Loader  openx={this.state.isLoaderOpen}  />
		  <Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
        </form>
      </div>
    );
  }

  setSMSTextByLang = (lang) => {
	let textRaw="";
	let name="";
	if(this.state.name!==null && this.state.name!==""){
		name=" "+this.state.name;
	}
    if(lang===""){
		//return "";
		textRaw="";
	}

	let user=null;  let agentName="N/A";
    if(localStorage.getItem("user")!==undefined  && localStorage.getItem("user")!==null){
		user=(decryptText(localStorage.getItem("user"))).split(':');
		//agentName= user[2];
		const names=user[2].split(' ');
		//agentName= names[0];
		if(names[0]!==undefined && names[0]!==null){
			agentName= names[0];
		}else{ agentName= user[2]; }
		//console.log("QQQQQQQQQQQQQ user[2] ", agentName,  user);
    }



	if(lang==="fr"){
		textRaw= (`<p>Bonjour${name},<br/>
Nous vous remercions d'avoir contacté Neolegal. Nous avons tenté de vous joindre par téléphone sans succès.
Vous pouvez nous contacter au <b>1-855-996-9695</b> du lundi au vendredi de 9h à 17h et il nous fera plaisir de répondre à vos questions.

Vous pouvez aussi nous laisser vos disponibilités en répondant à ce message.
<br/>
Merci beaucoup et bonne journée !
<br/>
https://www.neolegal.ca/
<br/>${agentName},<br/>Équipe Neolegal
</p>`);
	}else if(lang==="en"){
		textRaw= (`<p>Hello${name},<br/>
Thank you for contacting Neolegal. We have tried to reach you by phone without success.
You can contact us at <b>1-855-996-9695</b> Monday to Friday 9 am to 5 pm and we will be happy to answer your questions.

You can also let us know your availability by replying to this message.
<br/>
Thank you very much and good day!
<br/>
https://www.neolegal.ca/
<br/>${agentName},<br/>Neolegal Team
</p>`);
	}    

	//const textRaw=this.getSMSTextByLang(event.target.value);
	const contentBlock = htmlToDraft(textRaw); 
	//console.log("aaaaaaaaaading content ", lang, textRaw); 
	// const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
	// const editorState = EditorState.createWithContent(contentState);
	if (contentBlock) {
		const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
		const editorState = EditorState.createWithContent(contentState);
		this.setState({ ["editorState"]: editorState, });
		console.log("goood aaaaaaaaaading content ", lang,); 
	}

	//return "";
  };

  getSMSTextByLang = (lang) => {
	let textRaw="";
  if(lang===""){
	  return "";
  }

  if(lang==="fr"){
	  return (<p>Bonjour,
	  <br/><br/>
	  Nous vous remercions d'avoir contacté Neolegal. Nous avons tenté de vous joindre par téléphone sans succès.<br/>
	  Vous pouvez nous contacter au 1-855-996-9695 du lundi au vendredi de 9h à 17h et il nous fera plaisir de répondre à vos questions.
	  <br/><br/>
	  Vous pouvez aussi nous laisser vos disponibilités en répondant à ce message.
	  <br/><br/>
	  <br/><br/>
	  Merci beaucoup et bonne journée !</p>);
  }else if(lang==="en"){
	  return (<p>Hello,
		  <br/><br/>
		  Thank you for contacting Neolegal. We have tried to reach you by phone without success.<br/>
		  You can contact us at 1-855-996-9695 Monday to Friday 9 am to 5 pm and we will be happy to answer your questions.
		  <br/><br/>
		  You can also let us know your availability by replying to this message.
		  <br/><br/>
		  <br/><br/>
		  Thank you very much and good day!
	  </p>);
  }

  
  return "";
};


}

//const TransZendMacrosForm = withTranslation()(ZendMacrosForm); 
const mapStateToProps = (state) => ({ ZendMacros: state.ZendMacros });

const mapDispatchToProps = { addOrUpdateZendMacros, getZendMacros, getZendMacrosAll, sendZendMacros, sendZendNoteMerge, getTicketZendLeads, sendSMSNote };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(SendSMSNoteForm));
