const messagesEn={
"curlang": "en",    
"welcome_x": "Welcome",
"hi_x": "Hi",
"Create_x": "Create",
"New_x": "New",
"Edit_x": "Edit",
"Update_x": "Update",
"Delete_x": "Delete",
"Delete": "Delete",
"Cancel_x": "Cancel",
"Close_x": "Close", 
  
"Yes_x": "Yes",
"No_x": "No",
"Yes": "Yes",
"No": "No",


"uname_x": "Email",
"upass_x": "Password",
"tipoUsuario_x": "User Type",
"Seleccionar_x": "Select",
"Cliente_x": "Client",
"Operador_x": "Operator",
"Administrativo_x": "Administrative",
"idioma_x": "Language",
"Recuerdame_x": "Remember me",
"login_x": "Log in",
"recoverpass_x": "I forgot my password",
"resetpass_x": "Reset User Password",
"qemail_x": "Email",
"btnresetpass_x": "Reset Password",
"btnCancelar_x": "Cancel",


"cliente_x": "Client",
"nombre_x": "Name",
"Ruc_Cedula_x": "ID",
"dv_x": "Dv",
"Encargado_CxC_x": "Receivable Ac. Person In charge",
"telefono_x": "Telephone",
"direccion_x": "Address",
"habilitado_x": "Enabled",
"Si_x": "Yes",
"Email_x": "Email",
"Retencion_Impuesto_x": "Tax Retention",
"registry_date_x": "Registry Date",
"errorFoundonform_x": "You have some errors in the form.",
"acAddClientes_x": "Add Clients",
"acEditClientes_x": "Edit Clients",

"zqwid_clients_x": "Client Id",
"idzqw_x": "Id",
"namezqw_x": "Name",
"id_clientszqw_x": "Client",
"activezqw_x": "Active",
"createdzqw_x": "Created",
"ticket_idzqw_x": "Ticket",
"gridrowsPerPagezqw_x": "Active",
"NeoFolderszqw_x": "Neo Folders",
 


"gridIndexesfound_x": "Sorry, you have to check only one record to be able to edit it.",
"gridnoMatch_x": "Sorry, no matching records found",
"gridtoolTiph_x": "Sort",
"gridSortfor_x": "Sort for",
"gridnext_x": "Next Page",
"gridprevious_x": "Previous Page",
"gridrowsPerPage_x": "Rows per page:",
"griddisplayRows_x": "of",
"gridsearch_x": "Search",
"griddownloadCsv_x": "Download CSV",
"gridprint_x": "Print",
"gridviewColumns_x": "View Columns",
"gridfilterTable_x": "Filter Table",
"gridall_x": "All",
"gridftitle_x": "FILTERS",
"gridreset_x": "RESET",
"gridvctitle_x": "Show Columns",
"gridvctitleAria_x": "Show/Hide Table Columns",
"AssetTypes_x": "Assettypes",
"gridNoRecordfound_x": "No record found to display",




"menuhome_x": "Home",
"menudashboard_x": "Board",
"menuzonlineusuarios_x": "User profile",
"menuvwdespachosalerts_x": "Dispatches",
"menuvwfacturas_x": "All Invoices",
"menuvwfacturasnopagadas_x": "Unpaid Invoices",
"menuvwfacturaspagadas_x": "Paid Invoices",
"menuvwpagos_x": "Payments Made",
"menuid_x": "Id",
"ZxCompanies_x": "List of Companies/Branches",
"togoutcnfti_x": "Dear user",
"logoutconfir_x": "Are you sure that you want to logout the system",
"changeLang_x": "Switch Language",






"zqwid_x": "Id",
"zqwkey_x": "Key",
"zqwall_access_x": "All Access",
"zqwcontroller_x": "Controller",
"zqwdate_created_x": "Date Created",
"zqwdate_modified_x": "Date Modified",
"zqwerrorFoundonform_x": "you have some errors on the form.",
"zqwacAddAccess_x": "Add Access",
"zqwacEditAccess_x": "Edit Access",
"zqwgridIndexesfound_x": "Sorry, you have to check only one record to be able to edit it.",
"zqwgridnoMatch_x": "Sorry, no matching records found",
"zqwgridtoolTiph_x": "Sort",
"zqwgridSortfor_x": "Sort for",
"zqwgridnext_x": "Next Page",
"zqwgridprevious_x": "Previous Page",
"zqwgridrowsPerPage_x": "Rows per page:",
"zqwgriddisplayRows_x": "of",
"zqwgridsearch_x": "Search",
"zqwgriddownloadCsv_x": "Download CSV",
"zqwgridprint_x": "Print",
"zqwgridviewColumns_x": "View Columns",
"zqwgridfilterTable_x": "Filter Table",
"zqwgridall_x": "All",
"zqwgridftitle_x": "FILTERS",
"zqwgridreset_x": "RESET",
"zqwgridvctitle_x": "Show Columns",
"zqwgridvctitleAria_x": "Show/Hide Table Columns",
"zqwAccess_x": "Access",
"zqwgridNoRecordfound_x": "No record found to display",
"zqwuser_id_x": "User Id",
"zqwlevel_x": "Level",
"zqwignore_limits_x": "Ignore Limits",
"zqwis_private_key_x": "Is Private Key",
"zqwip_addresses_x": "Ip Addresses",
"zqwacAddKeys_x": "Add Keys",
"zqwacEditKeys_x": "Edit Keys",
"zqwKeys_x": "Keys",
"zqwuri_x": "Uri",
"zqwmethod_x": "Method",
"zqwparams_x": "Params",
"zqwapi_key_x": "Api Key",
"zqwip_address_x": "Ip Address",
"zqwtime_x": "Time",
"zqwrtime_x": "Rtime",
"zqwauthorized_x": "Authorized",
"zqwresponse_code_x": "Response Code",
"zqwacAddLogs_x": "Add Logs",
"zqwacEditLogs_x": "Edit Logs",
"zqwLogs_x": "Logs",
"zqwid_clients_x": "Id Clients",
"zqwaddress_x": "Address",
"zqwcity_x": "City",
"zqwprovince_x": "Province",
"zqwcp_x": "Cp",
"zqwcountry_x": "Country",
"zqwcreated_x": "Created",
"zqwactive_x": "Active",
"zqwtel_x": "Phone",
"zqwext_x": "Ext",
"zqwcompany_x": "Company",
"zqwname_x": "Name",
"zqwtype_x": "Type",
"zqwinvoicing_x": "Invoicing",
"zqwdelivery_x": "Delivery",
"zqwacAddNeoAddress_x": "Add NeoAddress",
"zqwacEditNeoAddress_x": "Edit NeoAddress",
"zqwNeoAddress_x": "Neoaddress",
"zqwid_campaign_x": "Id Campaign",
"zqwraw_data_x": "Raw Data",
"zqwacAddNeoCampaign_x": "Add NeoCampaign",
"zqwacEditNeoCampaign_x": "Edit NeoCampaign",
"zqwNeoCampaign_x": "Neocampaign",
"zqwid_folders_x": "Id Folders",
"zqwfirstname_x": "First Name",
"zqwlastname_x": "Last Name",
"zqwcell_x": "Cell",
"zqwemail_x": "Email",
"zqwns_x": "Ns",
"zqwcase_x": "Case",
"zqwstatus_x": "Status",
"zqwclosed_x": "Closed",
"zqwpending_x": "Pending",
"zqwinvalid_x": "Invalid",
"zqwopened_x": "Opened",
"zqwid_transactions_x": "Id Transactions",
"zqwlanguage_x": "Language",
"zqwacAddNeoCases_x": "Add NeoCases",
"zqwacEditNeoCases_x": "Edit NeoCases",
"zqwNeoCases_x": "Neocases",
"zqwterms_x": "Terms",
"zqwid_users_x": "Id Users",
"zqwpush_x": "Push",
"zqwid_zendesk_x": "Id Zendesk",
"zqwsms_x": "Sms",
"zqwstripe_id_x": "Stripe Id",
"zqwacAddNeoClients_x": "Add NeoClients",
"zqwacEditNeoClients_x": "Edit NeoClients",
"zqwNeoClients_x": "Neoclients",
"zqwdefinition_x": "Definition",
"zqwticket_id_x": "Ticket Id",
"zqwclient_id_x": "Client Id",
"zqwdelay_x": "Delay",
"zqwdone_x": "Done",
"zqwdate_executed_x": "Date Executed",
"zqwresult_x": "Result",
"zqwticket_type_x": "Ticket Type",
"zqwdeskapp_x": "Deskapp",
"zqwdesk_id_x": "Desk Id",
"zqwguid_x": "Guid",
"zqwacAddNeoDesk_x": "Add NeoDesk",
"zqwacEditNeoDesk_x": "Edit NeoDesk",
"zqwNeoDesk_x": "Neodesk",
"zqwid_notes_x": "Id Notes",
"zqwfile_location_x": "File Location",
"zqwfile_name_x": "File Name",
"zqwfile_extension_x": "File Extension",
"zqwfile_html_x": "File Html",
"zqwis_file_x": "Is File",
"zqwmodified_x": "Modified",
"zqwmetas_x": "Metas",
"zqworg_name_x": "Org Name",
"zqwuniqid_x": "Uniqid",
"zqwacAddNeoDocuments_x": "Add NeoDocuments",
"zqwacEditNeoDocuments_x": "Edit NeoDocuments",
"zqwNeoDocuments_x": "Neodocuments",
"zqwacAddNeoDocuments1_x": "Add NeoDocuments1",
"zqwacEditNeoDocuments1_x": "Edit NeoDocuments1",
"zqwNeoDocuments1_x": "Neodocuments1",
"zqwid_event_x": "Id Event",
"zqwevent_start_x": "Event Start",
"zqwevent_end_x": "Event End",
"zqwduration_x": "Duration",
"zqwevent_tz_x": "Event Tz",
"zqwuser_x": "User",
"zqwservice_id_x": "Service Id",
"zqwacAddNeoEvents_x": "Add NeoEvents",
"zqwacEditNeoEvents_x": "Edit NeoEvents",
"zqwNeoEvents_x": "Neoevents",
"zqwacAddNeoFolders_x": "Add NeoFolders",
"zqwacEditNeoFolders_x": "Edit NeoFolders",
"zqwNeoFolders_x": "Neofolders",
"zqwpayed_x": "Payed",
"zqwsent_x": "Sent",
"zqwoverdue_x": "Overdue",
"zqwview_x": "View",
"zqwdeclined_x": "Declined",
"zqwrefund_x": "Refund",
"zqwdue_date_x": "Due Date",
"zqwsub_total_x": "Sub Total",
"zqwtax1_x": "Tax1",
"zqwtax2_x": "Tax2",
"zqwtoken_x": "Token",
"zqwcode_x": "Code",
"zqwdeclined_code_x": "Declined Code",
"zqwtransact_x": "Transact",
"zqwtax_total_x": "Tax Total",
"zqwtotal_x": "Total",
"zqwid_address_x": "Id Address",
"zqwacAddNeoInvoices_x": "Add NeoInvoices",
"zqwacEditNeoInvoices_x": "Edit NeoInvoices",
"zqwNeoInvoices_x": "Neoinvoices",
"zqwacAddNeoLawyer_x": "Add NeoLawyer",
"zqwacEditNeoLawyer_x": "Edit NeoLawyer",
"zqwNeoLawyer_x": "Neolawyer",
"zqwacAddNeoMailHook_x": "Add NeoMailHook",
"zqwacEditNeoMailHook_x": "Edit NeoMailHook",
"zqwNeoMailHook_x": "Neomailhook",
"zqwid_transaction_x": "Id Transaction",
"zqwid_user_x": "Id User",
"zqwacAddNeoMed_x": "Add NeoMed",
"zqwacEditNeoMed_x": "Edit NeoMed",
"zqwNeoMed_x": "Neomed",
"zqwnote_x": "Note",
"zqwpublic_x": "Public",
"zqwid_lawyer_x": "Id Lawyer",
"zqwread_x": "Read",
"zqwacAddNeoNotes_x": "Add NeoNotes",
"zqwacEditNeoNotes_x": "Edit NeoNotes",
"zqwNeoNotes_x": "Neonotes",
"zqwid_invoices_x": "Id Invoices",
"zqwamount_x": "Amount",
"zqwacAddNeoPayments_x": "Add NeoPayments",
"zqwacEditNeoPayments_x": "Edit NeoPayments",
"zqwNeoPayments_x": "Neopayments",
"zqwtransdata_x": "Transdata",
"zqwsignature_x": "Signature",
"zqwidTrans_x": "Idtrans",
"zqwsource_x": "Source",
"zqwacAddNeoPaymentsHook_x": "Add NeoPaymentsHook",
"zqwacEditNeoPaymentsHook_x": "Edit NeoPaymentsHook",
"zqwNeoPaymentsHook_x": "Neopaymentshook",
"zqwdescription_x": "Description",
"zqwdata_json_x": "Data Json",
"zqwacAddNeoProducts_x": "Add NeoProducts",
"zqwacEditNeoProducts_x": "Edit NeoProducts",
"zqwNeoProducts_x": "Neoproducts",
"zqwiso_x": "Iso",
"zqwtax1_name_x": "Tax1 Name",
"zqwtax2_name_x": "Tax2 Name",
"zqwacAddNeoProvinces_x": "Add NeoProvinces",
"zqwacEditNeoProvinces_x": "Edit NeoProvinces",
"zqwNeoProvinces_x": "Neoprovinces",
"zqwclientemail_x": "Clientemail",
"zqwreferalname_x": "Referalname",
"zqwreferalemail_x": "Referalemail",
"zqwadw_id_x": "Adw Id",
"zqwclientvoucher_x": "Clientvoucher",
"zqwreferalvoucher_x": "Referalvoucher",
"zqwclientused_x": "Clientused",
"zqwreferalused_x": "Referalused",
"zqwclientdateused_x": "Clientdateused",
"zqwreferaldateused_x": "Referaldateused",
"zqwclientidtrans_x": "Clientidtrans",
"zqwreferalidtrans_x": "Referalidtrans",
"zqwacAddNeoReferal_x": "Add NeoReferal",
"zqwacEditNeoReferal_x": "Edit NeoReferal",
"zqwNeoReferal_x": "Neoreferal",
"zqwdate_x": "Date",
"zqwcommission_x": "Commission",
"zqwidstripe_x": "Idstripe",
"zqwapplication_x": "Application",
"zqwcustomer_id_x": "Customer Id",
"zqwtaxable_x": "Taxable",
"zqworphelin_x": "Orphelin",
"zqwnom_x": "Nom",
"zqwprenom_x": "Prenom",
"zqwidclient_x": "Idclient",
"zqwid_produit_x": "Id Produit",
"zqwproduit_x": "Produit",
"zqwis_business_x": "Is Business",
"zqwclient_created_x": "Client Created",
"zqwacAddNeoRevenus_x": "Add NeoRevenus",
"zqwacEditNeoRevenus_x": "Edit NeoRevenus",
"zqwNeoRevenus_x": "Neorevenus",
"zqwcreated_campaign_x": "Created Campaign",
"zqwcreated_clients_x": "Created Clients",
"zqwacAddNeoSalesCampaign_x": "Add NeoSalesCampaign",
"zqwacEditNeoSalesCampaign_x": "Edit NeoSalesCampaign",
"zqwNeoSalesCampaign_x": "Neosalescampaign",
"zqwcategory_x": "Category",
"zqwid_product_x": "Id Product",
"zqwcat_id_x": "Cat Id",
"zqwacAddNeoServices_x": "Add NeoServices",
"zqwacEditNeoServices_x": "Edit NeoServices",
"zqwNeoServices_x": "Neoservices",
"zqwacAddNeoSrfaxHook_x": "Add NeoSrfaxHook",
"zqwacEditNeoSrfaxHook_x": "Edit NeoSrfaxHook",
"zqwNeoSrfaxHook_x": "Neosrfaxhook",
"zqwcurrency_x": "Currency",
"zqwtype_id_x": "Type Id",
"zqwacAddNeoTarifs_x": "Add NeoTarifs",
"zqwacEditNeoTarifs_x": "Edit NeoTarifs",
"zqwNeoTarifs_x": "Neotarifs",
"zqwacAddNeoTarifsUsers_x": "Add NeoTarifsUsers",
"zqwacEditNeoTarifsUsers_x": "Edit NeoTarifsUsers",
"zqwNeoTarifsUsers_x": "Neotarifsusers",
"zqwacAddNeoTaskdef_x": "Add NeoTaskdef",
"zqwacEditNeoTaskdef_x": "Edit NeoTaskdef",
"zqwNeoTaskdef_x": "Neotaskdef",
"zqwacAddNeoTrackingHook_x": "Add NeoTrackingHook",
"zqwacEditNeoTrackingHook_x": "Edit NeoTrackingHook",
"zqwNeoTrackingHook_x": "Neotrackinghook",
"zqwid_products_x": "Id Products",
"zqwid_vouchers_x": "Id Vouchers",
"zqwpreachat_x": "Preachat",
"zqwacAddNeoTransactions_x": "Add NeoTransactions",
"zqwacEditNeoTransactions_x": "Edit NeoTransactions",
"zqwNeoTransactions_x": "Neotransactions",
"zqwusername_x": "Username",
"zqwpassword_x": "Password",
"zqwadmin_x": "Admin",
"zqwsupervisor_x": "Supervisor",
"zqwlawyer_x": "Lawyer",
"zqwagent_x": "Agent",
"zqwlsupervisor_x": "Lsupervisor",
"zqwcustomer_x": "Customer",
"zqwmarketing_x": "Marketing",
"zqwsales_x": "Sales",
"zqwlegalintern_x": "Legalintern",
"zqwelawyer_x": "Elawyer",
"zqwlast_login_x": "Last Login",
"zqwacAddNeoUsers_x": "Add NeoUsers",
"zqwacEditNeoUsers_x": "Edit NeoUsers",
"zqwNeoUsers_x": "Neousers",
"zqwacAddNeoVentes_x": "Partnership Contracts",
"zqwacEditNeoVentes_x": "Partnership Contracts",
"zqwNeoVentes_x": "Neoventes",
"zqwpercentage_x": "Percentage",
"zqwvalid_start_x": "Valid Start",
"zqwvalid_end_x": "Valid End",
"zqwusage_x": "Usage",
"zqwacAddNeoVouchers_x": "Add NeoVouchers",
"zqwacEditNeoVouchers_x": "Edit NeoVouchers",
"zqwNeoVouchers_x": "Neovouchers",
"zqwCreation_Date_x": "Creation Date",
"zqwDescription_x": "Description",
"zqwAmount_x": "Amount",
"zqwComission_x": "Comission",
"zqwID_x": "Id",
"zqwacAddStripeReport_x": "Add StripeReport",
"zqwacEditStripeReport_x": "Edit StripeReport",
"zqwStripeReport_x": "Stripereport",




"zqwweek_plan_id_x": "Week Plan Id",
"zqwwork_date_x": "Work Date",
"zqwexpected_quantity_x": "Expected Quantity",
"zqwexpected_amount_x": "Expected Amount",
"zqwinvoice_quantity_x": "Invoice Quantity",
"zqwinvoice_amount_x": "Invoice Amount",
"zqwpayment_quantity_x": "Payment Quantity",
"zqwpayment_amount_x": "Payment Amount",
"zqwacAddNeoSalesPlanningDaily_x": "Add NeoSalesPlanningDaily",
"zqwacEditNeoSalesPlanningDaily_x": "Edit NeoSalesPlanningDaily",
"zqwNeoSalesPlanningDaily_x": "Neosalesplanningdaily",
"zqwweek_x": "Week",
"zqwweek_start_x": "Week Start",
"zqwweek_end_x": "Week End",
"zqwacAddNeoSalesPlanningWeekly_x": "Add NeoSalesPlanningWeekly",
"zqwacEditNeoSalesPlanningWeekly_x": "Edit NeoSalesPlanningWeekly",
"zqwNeoSalesPlanningWeekly_x": "Neosalesplanningweekly",

"zqwtotal_recurrent_x": "Total Recurrent",
"zqwround_recurrent_x": "Round Recurrent",
"zqwday_recurrent_x": "Day Recurrent",
"zqwfrom_date_x": "From Date",
"zqwpayment_type_x": "Payment Type",
"zqwamount_x": "Amount",
"zqwnotes_x": "Notes",
"zqwlast_update_x": "Last Update",
"zqwacAddNeoInvoicesRecurrent_x": "Add NeoInvoicesRecurrent",
"zqwacEditNeoInvoicesRecurrent_x": "Edit NeoInvoicesRecurrent",
"zqwNeoInvoicesRecurrent_x": "Neoinvoicesrecurrent",
"zqwuser_creation_x": "User Creation",
"zqwuser_update_x": "User Update",
"zqwcreation_date_x": "Creation Date",
"zqwupdate_date_x": "Update Date",
"zqwtotalPayment_x": "Total Payment",




"zqwid_b2b_list_x": "Contract List",
"zqwid_external_x": "Id External",
"zqwname_id_external_x": "Name Id External",
"zqwfirstname_x": "Firstname",
"zqwlastname_x": "Lastname",
"zqwemail_x": "Email",
"zqwtel_x": "Phone",
"zqwstart_date_x": "Start Date",
"zqwexpiry_date_x": "Expiry Date",
"zqwaddress_x": "Address",
"zqwcity_x": "City",
"zqwprovince_x": "Province",
"zqwpostal_code_x": "Postal Code",
"zqwraw_data_x": "Raw Data",
"zqwdate_created_x": "Date Created",
"zqwdate_updated_x": "Date Updated",
"zqwstatus_x": "Status",
"zqwdob_x": "Dob",
"zqwid_clients_x": "Id Clients",
"zqwerrorFoundonform_x": "you have some errors on the form.",
"zqwacAddNeoB2bClients_x": "Add NeoB2bClients",
"zqwacEditNeoB2bClients_x": "Edit NeoB2bClients",
"zqwgridIndexesfound_x": "Sorry, you have to check only one record to be able to edit it.",
"zqwgridnoMatch_x": "Sorry, no matching records found",
"zqwgridtoolTiph_x": "Sort",
"zqwgridSortfor_x": "Sort for",
"zqwgridnext_x": "Next Page",
"zqwgridprevious_x": "Previous Page",
"zqwgridrowsPerPage_x": "Rows per page:",
"zqwgriddisplayRows_x": "of",
"zqwgridsearch_x": "Search",
"zqwgriddownloadCsv_x": "Download CSV",
"zqwgridprint_x": "Print",
"zqwgridviewColumns_x": "View Columns",
"zqwgridfilterTable_x": "Filter Table",
"zqwgridall_x": "All",
"zqwgridftitle_x": "FILTERS",
"zqwgridreset_x": "RESET",
"zqwgridvctitle_x": "Show Columns",
"zqwgridvctitleAria_x": "Show/Hide Table Columns",
"zqwNeoB2bClients_x": "Neob2bclients",
"zqwgridNoRecordfound_x": "No record found to display",
"zqwcompany_name_x": "Company Name",
"zqwinfos_x": "Infos",
"zqwemail_import_x": "Email Import",
"zqwacAddNeoB2bContracts_x": "Add NeoB2bContracts",
"zqwacEditNeoB2bContracts_x": "Edit NeoB2bContracts",
"zqwNeoB2bContracts_x": "Neob2bcontracts",
"zqwid_b2b_contracts_x": "Id B2b Contracts", 
"zqwcontract_start_x": "Contract Start",
"zqwcontract_end_x": "Contract End",
"zqwname_id_external_en_x": "Name Id External En",
"zqwquantity_x": "Quantity",
"zqwfrequency_x": "Frequency",
"zqwacAddNeoB2bContractsList_x": "Add NeoB2bContractsList",
"zqwacEditNeoB2bContractsList_x": "Edit NeoB2bContractsList",
"zqwNeoB2bContractsList_x": "Neob2bcontractslist",
"zqwid_product_x": "Id Product",
"zqwquantite_x": "Quantite",
"zqwacAddNeoB2bContractsServices_x": "Add NeoB2bContractsServices",
"zqwacEditNeoB2bContractsServices_x": "Edit NeoB2bContractsServices",
"zqwNeoB2bContractsServices_x": "Neob2bcontractsservices",
"zqwid_b2b_clients_x": "Id B2b Clients",
"zqwid_invoices_x": "Id Invoices",
"zqwacAddNeoB2bInvoices_x": "Add NeoB2bInvoices",
"zqwacEditNeoB2bInvoices_x": "Edit NeoB2bInvoices", 
"zqwNeoB2bInvoices_x": "Neob2binvoices",
"zqwCEligibility_x": "Check Eligibility",

"zqwTabsummary_x": "Summary",
"zqwTabinput_x": "Client File",
"zqwTabnouveau_x": "New",
"zqwoccupation_x": "Occupation",
"zqwTabDocument_x": "Documents",
"zqwTabAjoutProd_x": "Add product",
"zqwTabhome_x": "Home",

"zqwTSumtxt1_x": "File status",
"zqwTSumtxt2_x": "Status",
"zqwTSumtxt3_x": "Next Step",
"zqwTSumtxt3_2_x": "Followup precision",
"zqwTSumtxt4_x": "Services sold", //"Selected service",
"zqwTSumtxt5_x": "B2B customer",
"zqwTSumtxt6_x": "Insurrance number",
"zqwTSumtxt7_x": "Customer details",
"zqwTSumtxt8_x": "Client language",
"zqwTSumtxt9_x": "Customer region",
"zqwTSumtxt10_x": "Area of law",
"zqwTSumtxt10b_x": "Client next appointment",
"zqwTSumtxt11_x": "No data to show",
"zqwTSumtxt12_x": "French",
"zqwTSumtxt13_x": "English",
"zqwTSumtxt14_x": "Last name",
"zqwTSumtxt15_x": "First name",
"zqwTSumtxt16_x": "Email",
"zqwTSumtxt17_x": "Phone",
"zqwTSumtxt18_x": "Address",
"zqwTSumtxt19_x": "Open big window",

"zqwTSCnycompany_x": "Company details",
"zqwTSCnyrepre_x": "Contact details of the legal representative",
"zqwTSCnyname_x": "Company",
"zqwTSCnyothername_x": "Other name of the company",
"zqwTSCnyneq_x": "NEQ",
"zqwTSCnycity_x": "City",
"zqwTSCnycp_x": "Postal code",
"zqwTSCnyprovince_x": "Province",
"zqwTSCnycountry_x": "Country",
"zqwTSCnyext_x": "Extension",

"zqwTNVFtxt1_x": "Customer Info",
"zqwTNVFtxt2_x": "Company",
"zqwTNVFtxt3_x": "Please Select Contract",
"zqwTNVFtxt4_x": "Additional Info",
"zqwTNVFtxt5_x": "Eligible Products",
"zqwTNVFtxt6_x": "Eligible ProductsThis customer is not eligible.",
"zqwTNVFtxt7_x": "Services Used",
"zqwTNVFtxt8_x": "Invoice",
"zqwTNVFtxt9_x": "Date",
"zqwTNVFtxt10_x": "Description",
"zqwTNVFtxt11_x": "Click on \"Open Client File\" to proceed with opening the case or on \"Next Step\" to update the customer's information.",
"zqwTNVFtxt11b_x": "click on this button to finalize the sale if you are not going to update the customer's data. Otherwise click \"Next Step\".",
"zqwTNVFtxt12_x": "Open Client File",
"zqwTNVFtxt13_x": "Next Step",
"zqwTNVFtxt14_x": "Create Sales Lead",
"zqwTNVFtxt15_x": "Add Customer to the list",
"zqwTNVFtxt16_x": "required fields",
"zqwTNVFtxt17_x": "Postal code addresses",
"zqwTNVFtxt18_x": "The list of possible Address with the same postal code",
"zqwTNVFtxt19_x": "Choose Address option list (optional)",
"zqwTNVFtxt20_x": "Close",
"zqwTNVFtxt21_x": "Previous Step",
"zqwTNVFtxt22_x": "Next Step",
"zqwTNVFtxt23_x": "Start over completely",
"zqwTNVFtxt24_x": "Please validate the customer's email address by clicking on the email field and then outside the field.",
"zqwTNVFtxt25_x": "Customer Info",
"zqwTNVFtxt26_x": "Company",
"zqwTNVFtxt27_x": "Select a Language",
"zqwTNVFtxt28_x": "Francais",
"zqwTNVFtxt29_x": "English",
"zqwTNVFtxt30_x": "I agree to receive notifications by SMS concerning my file (recommended)",
"zqwTNVFtxt31_x": "App/Suite/Unit",
"zqwTNVFtxt32_x": "Google find address ...",
"zqwTNVFtxt33_x": "Search a Client",
"zqwTNVFtxt34_x": "New Client Mannually",
"zqwTNVFtxt35_x": "Clean up and restart all",

"zqwTDCtxt1_x": "Save All",

"zqwTNVFtxt36_x": "Congratulations",
"zqwTNVFtxt37_x": "The transaction was completed successfully.",
"zqwTNVFtxt38_x": "you can close that's all",
"zqwTNVFtxt39_x": "Finalization of Sale",
"zqwTNVFtxt40_x": "Summary of your order",
"zqwTNVFtxt41_x": "Existing Customer with Email",
"zqwTNVFtxt42_x": "The list of possible existing Client with the same email",

"zqwPuptxt1_x":"ADD PRODUCTS ON THE TICKET", 
"zqwPuptxt2_x":"Name of the product", 
"zqwPuptxt3_x":"Add Product",  
"zqwNFFtxt1_x": "Move a file",
"zqwNFFtxt2_x": "None",
"zqwNFFtxt3_x": "Move",
"zqwNFFtxt4_x": "from position",
"zqwNFFtxt5_x": "to position",
"zqwNFFtxt6_x": "The Products purchased",
"zqwNFFtxt7_x": "The Payments made",
"zqwNFFtxt8_x": "......",
"zqwNFFtxt9_x": "We loaded the data successfully!",
"zqwNFFtxt10_x": "We will compare the data from left to right.",
"zqwNFFtxt11_x": "Finally, We loaded the data successfully!",
"zqwNFFtxt12_x":"We have no left-hand zendesk data to update on the right.",
"zqwNFFtxt13_x":"Choose File",
"zqwNFFtxt14_x":"Sorry, It is a lead, you cannot change the client default Info. ",
"zqwNFFtxt15_x":"Sorry, you have to choose one position to move it to another position.",
"zqwNFFtxt16_x":"Data saved",
"zqwNFFtxt17_x":"Sorry, we do not save empty data. Make sure it is filled first.",
"zqwNFFtxt18_x":"Sorry, the format of your data is incorrect. Make sure it is filled properly!",
"zqwNFFtxt19_x":"B2B Client",
"zqwNFFtxt20_x":"Onwwwwwwwwww",
"zqwNFFtxt21_x":"Onwwwwwwwwww",
"zqwNFFtxtNeo_x": "Create new ticket",

"zqwNVFrmEl56_x": "Choose",
"zqwNVFrmEl57_x": "Subtotal",
"zqwNVFrmEl58_x": "Discount",
"zqwNVFrmEl59_x": "Total before Payment",
"zqwNVFrmEl60_x": "Payments Completed",
"zqwNVFrmEl61_x": "Total",
"zqwNVFrmEl62_x": "Organization of the Payment",
"zqwNVFrmEl63_x": "Way to Pay",
"zqwNVFrmEl64_x": "Pay Full Amount",
"zqwNVFrmEl65_x": "Pay a part (or multiple card)",
"zqwNVFrmEl66_x": "Amount to Pay Now",
"zqwNVFrmEl67_x": "Recurring payments",
"zqwNVFrmEl68_x": "Subsequent payment every ... of the month",
"zqwNVFrmEl69_x": "From",
"zqwNVFrmEl70_x": "date example 2000-12-22",
"zqwNVFrmEl71_x": "Congratulations, payment received successfully !!",
"zqwNVFrmEl72_x": "Payment",
"zqwNVFrmEl73_x": "Pay with",
"zqwNVFrmEl74_x": "Paypal by",
"zqwNVFrmEl75_x": "Upsell with CardOnFile",
"zqwNVFrmEl76_x": "Previous Step",
"zqwNVFrmEl77_x": "This is an interac sale, make sure the customer has paid (funds deposited) before clicking finalize. This will create the purchase ticket and email it to the customer.",
"zqwNVFrmEl78_x": "FINALIZE THE TRANSACTION",
"zqwNVFrmEl79_x": "CLOSE WITHOUT FINALIZING THE TRANSACTION",
"zqwNVFrmEl80_x": "Find a Client",
"zqwNVFrmEl81_x": "Please indicate the number of pages to proofread",
"zqwNVFrmEl82_x": "Pages",
"zqwNVFrmEl83_x": "Product Price",
"zqwNVFrmEl84_x": "Additional Service",
"zqwNVFrmEl85_x": "Total Taxes",
"zqwNVFrmEl86_x": "Additional Service Options",
"zqwNVFrmEl87_x": "Create a zendesk folder and ticket",
"zqwNVFrmEl88_x": "Remove",
"zqwNVFrmEl89_x": "THE LIST OF PRODUCTS IN THE INVOICE",
"zqwNVFrmEl90_x": "Tax of",
"zqwNVFrmEl91_x": "Total to pay",
"zqwNVFrmEl7_x": "Error! You must choose at least one product",
"zqwNVFrmEl8_x": "Error! To continue with the transaction, the amount must be greater than 0!",
"zqwNVFrmEl9_x": "Error! To continue you must have at least one valid product!",

"zqwNVFrmEl13_x": "Congratulations !!! We have successfully completed your transaction!",
"zqwNVFrmEl14_x": "An error occurred in the expected data from the server. Please try again later.",
"zqwNVFrmEl15_x": "Error! To use Charge Desk, the Way to Pay must be 'Pay Full Amount'!",
"zqwNVFrmEl16_x": "Sorry, we don't do more than once in less than a minute. Please try again in {{timerest}} seconds",
"zqwNVFrmEl17_x": "Error! To use Interac, the Way to Pay must be 'Pay Full Amount'!",
"zqwNVFrmEl18_x": "Error! Sorry this customer has no existing card!",
"zqwNVFrmEl19_x": "Error! To use existing card, the Way to Pay must be 'Pay Full Amount'!",
"zqwNVFrmEl20_x": "You must choose at least one product, there is no product in your basket.",
"zqwNVFrmEl21_x": "Some fields are required, please fill them in",

"zqwMniPEl_x": "there is no ticket (#) number",
"zqwNFZendesk1_x": "Don't forget to save in zendesk",
"zqwNVFrmEl22_x": "Error! Before creating the Lead, you must put a phone (see in additional info / comments).",
 
"zqwNDICItxl_x": "Conflicts of Interest",
"zqwNDICItx2_x": "Search for conflict of interests",
"zqwNDICItx3_x": "Search",
"zqwNDICItx4_x": "Ignore",
"zqwNDICItx5_x": "Not Applied",
"zqwNDICItx6_x": "No Info Available",
"zqwNDICItx7_x": "No Conflict",
"zqwNDICItx8_x": "Conflict",
"zqwNDICItx9_x": "No search record",
"zqwNDICItxl0_x": "Auther",
"zqwNDICItxl1_x": "Pending",

"zqwNFFrmZn01_x": "Judiciairy Invoice", 
"zqwNFFrmZn02_x": "Generate",
"zqwNFFrmZn03_x": "Generate & Download",
"zqwNFFrmZn04_x": "Sorry, we do not add empty data to generate judiciary invoice. Make sure you check an item first.",
"zqwNFFrmZn05_x": "Sorry, we need the ticket Id to be able to download the generated judiciary invoice.",
"zqwNFFrmZn06_x": "Sorry, we need the invoice Id to be able to download the generated judiciary invoice.",
"zqwNFFrmZn07_x": "Sorry, we need the province to be able to download the generated judiciary invoice.",
"zqwNFFrmZn08_x": "qxyqxy",
"zqwNVFrmEl86_x": "Additional Service Options",



}

export default messagesEn;
