import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getDocLinkType, sendDocLink, getDocLink, getDownloadFileLink, getProductFields, getGroupsAndUsers, sendAssignedGroupUser } from '../../actions/NdiFieldsAction'; 
//import { addOrUpdateZendGroupMemberships, getZendGroupMemberships } from '../../actions/ZendGroupMembershipsAction'; 
import { Validations } from '../../utils/validations'; 
import MuiAlert from '@material-ui/lab/Alert';
import Toastr from "../../messages";
import  Loader from "../../loader";
import Select, { StylesConfig } from 'react-select';

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['name']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['data_raw']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['status']={check:true, chkType:'boolean', message:'incorrect value'};
FIELD_VALIDATION['last_update']={check:true, chkType:'Timestamp', message:'incorrect value'};

class AssignTicketToGroupOrUser extends React.Component {
  validation=new Validations();
  actionMode='new';
  listFormOptions={};
  listFormOptions2={};
  
  constructor(props) {
    super(props);
    this.state = {
		isLoaderOpen:false, 
		toastrMessage: '', toastrType: '', toastrDuration: 6000,
		id:'', e_id: false,
		name:'', e_name: false,
		data_raw:'', e_data_raw: false,
		status:'', e_status: false,
		last_update:'', e_last_update: false,
		groupsAssignees:{},
		groupId:'',
		assigneeId:'', hideSendButton:false, 
		message:'', 
		groupName:'',   assigneeName:'',   
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange2 = (event) => {
	const backVal =event.target.value;
	let frontVal="";
	if(backVal!==undefined && backVal!==null && backVal!==""){
		const groupsAssignees=this.state.groupsAssignees;
		let listFormOptions = [];
		let docListformat = null; let docListformat2 = null; 
		if(groupsAssignees!==null && groupsAssignees.groupList!==undefined){ 
			const docList2=groupsAssignees.groupList;
			docListformat = docList2.length > 0
				&& docList2.map((item, i) => { 
					if(backVal===item.zendeskid){
						frontVal=""+item.name;
						console.log("MONITORING frontVal ", backVal, frontVal);
					}	
					//console.log("MONITORING docList ", item.id_zendesk);
					return null; 
					return (<option value={item.zendeskid} key={"gl"+i}>{item.name}</option>); 
				}, this);
		}
	
	}

    this.setState({ [event.target.name]: event.target.value, ["groupName"]: frontVal  });
  };

  handleChangeUserAssignee = (event) => {
	const backVal =event.value;
    //this.setState({ ["assigneeId"]: event.target.value });
	let frontVal="";
	if(backVal!==undefined && backVal!==null && backVal!==""){
		const groupsAssignees=this.state.groupsAssignees;
		let listFormOptions = [];
		let docListformat = null; let docListformat2 = null; 
		if(groupsAssignees!==null && groupsAssignees.agentList!==undefined){ 
		const docList=groupsAssignees.agentList;
		docListformat2 = docList.length > 0
			&& docList.map((item, i) => {  
				if(backVal===item.id_zendesk){
					frontVal=""+item.label;
					console.log("MONITORING frontVal ", backVal, frontVal); 
				}
				//console.log("MONITORING docList ", item.id_zendesk);
				return null;	
				
				//listFormOptions.push({ value: item.id_zendesk, label: item.label });
				if(item.id_zendesk!==null && item.id_zendesk!=="" && item.id_zendesk!=="null"){
					listFormOptions.push({ value: item.id_zendesk, label: item.label });
				}
				else if(item.id==="0" && item.id_zendesk===""){
					listFormOptions.push({ value: item.id_zendesk, label: item.label });
				}
				console.log("MONITORING docList ", item.id_zendesk);

				return (<option value={item.id_zendesk} key={"dc"+i}>{item.label}</option>); 
			}, this);
		}
	}

	this.setState({ ["assigneeId"]: backVal, ["assigneeName"]: frontVal });
  };

   
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	const { t } = this.props;  	
	const docObj={};
	const this2=this; 
	this.setState({ ["isLoaderOpen"]: true});  
	Promise.resolve(this.props.getGroupsAndUsers(docObj))
	.then((response)=>{
		const listForms=response.NdiFieldsOne;
		//this.setState(response.NdiFieldsOne);
		this.setState({ ["isLoaderOpen"]: false, ["groupsAssignees"]: listForms, });  
		console.log("..HOHOHO response from server .... ");  console.log(response);
		  
		return response;
	}).catch(function(error){ 
		this2.setState({ ["isLoaderOpen"]: false});  
		//alert(error);
		const errObj=error;
		//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		if(errObj.status===undefined){
			errMessage +="\n "+t("zqwNVFrmEl14_x")
		}
		//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
		
		console.log("ERROR WITH TRANSACTION"); 
		console.log(error);
		console.log("SHORT ERROR MESSAGE: "+errMessage);
		console.log("RWA RESPONSE"); 
		//console.log(rawError);
		this2.showToast(errMessage, "error"); 
	});

  }
 

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const data_raw = this.state.data_raw;
    const status = this.state.status;
    const last_update = this.state.last_update;
    const ZendGroupMemberships = {id: id, name: name, data_raw: data_raw, status: status, last_update: last_update, }

	const assigneeId = this.state.assigneeId;
	const groupId = this.state.groupId;
	const ticket_id=this.props.currentTicket;
	let errorFound=0;
	let message="";  let groupIdx="", assigneeIdx="";
	const groupName = this.state.groupName;
	const assigneeName = this.state.assigneeName;
	//groupName   assigneeName  
	
	if(ticket_id===null || ticket_id===undefined || ticket_id===""){
		window.alert(t('zqwerrorFoundonform_x')+" # Ticket"); 
		this.showToast(t('zqwerrorFoundonform_x')+" # Ticket", "error"); 
		return false;
	}

	if((assigneeId===null || assigneeId==="") && (groupId===null || groupId==="")){
		window.alert(t('zqwerrorFoundonform_x')+"\n"+t('zqwMesas01_x')); 
		this.showToast(t('zqwerrorFoundonform_x')+" "+t('zqwMesas01_x'), "error"); 
		return false;
	}

	if(assigneeId!==null && assigneeId!=="" && groupId!==null && groupId!==""){
		groupIdx=""+groupName+"";
		assigneeIdx=""+assigneeName+"";
		//message="Groupe #"+groupId+" et utilisateur #"+assigneeId+" sont assignés au ticket "+ticket_id;
		message=t("zqwMesas05_x", {group:groupIdx, assignee:assigneeIdx, ticket:ticket_id});
	}else if(groupId!==null && groupId!==""){
		groupIdx=""+groupName+"";
		//message="Groupe #"+groupId+" est assigné au ticket "+ticket_id;
		message=t("zqwMesas06_x", {group:groupIdx, ticket:ticket_id});
	}else if(assigneeId!==null && assigneeId!==""){
		assigneeIdx=""+assigneeName+"";
		//message="Utilisateur #"+assigneeId+" est assigné au ticket "+ticket_id;
		message=t("zqwMesas07_x", {assignee:assigneeIdx, ticket:ticket_id});
	}

	// "zqwMesas05_x": "Groupe #{{group}} et utilisateur #{{assignee}} sont assignés au ticket {{ticket}}",
	// "zqwMesas06_x": "Groupe #{{group}} est assigné au ticket {{ticket}}",
	// "zqwMesas07_x": "Utilisateur #{{assignee}} est assigné au ticket {{ticket}}",


	console.log('handleSubmitDoc ', ticket_id, assigneeId, groupId);
	//const docLink={form_name:form_name, target:target, ticketid:ticket};
	//const docLink={form_name:form_name, target:target, ticketid:ticket, formurl:formurl, action:"sendLinkToEmail", emailphone:emailphone};
	//const docLink={form_name:form_name, target:target, ticketid:ticket, formurl:formurl, action:mediatosend, emailphone:emailphone, };
	
	const docLink={ticketid:ticket_id, assigneeId:assigneeId, groupId:groupId, };
	
	const this2=this;
	//docLink.sendfilelink=0;  
	this.setState({ ["isLoaderOpen"]: true, ["hideSendButton"]:true});  
	Promise.resolve(this.props.sendAssignedGroupUser(docLink))
	.then((response)=>{
		const listForms=response.NdiFieldsOne;
		//this.setState(response.NdiFieldsOne);
		let hideSendButton=false;
		let messagew=""; 
		// if(listForms.puburl!==undefined && listForms.puburl.url!==undefined && listForms.puburl.url!==""){
		 	hideSendButton=true;
		// 	messagew="<b>"+t("zqwNFFrmZn012_x")+"</b><br/>"+emailphone;
		// 	//messagew="<b>"+t("zqwNFFrmZn012_x")+"</b><br/>"+listForms.client.firstname+" "+listForms.client.lastname+" <br/>Email: "+listForms.client.email; 
		// 	/// this.setState({ ["isLoaderOpen"]: false, ["hideSendButton"]: true,["isLoaderOpen"]: false, });
		// }

		messagew="<b>"+t("zqwNFFrmZn012_x")+"</b><br/>#"+ticket_id;


		// this.setState({ ["isLoaderOpen"]: false,  });
		this.setState({ ["isLoaderOpen"]: false, ["hideSendButton"]: hideSendButton,["message"]: message, });
		
		if(this.props.paStateFuncAction!==undefined){
			//console.log('caaaaaaaaaaaalling  paStateFuncAction ', ticket_id, assigneeId, groupId);
			const locMess=""+message;
			const locObj={stateName: "assignTicketFormtxt",  stateVal:locMess, };
			this.props.paStateFuncAction(locObj);
		}

		console.log("..HOHOHO response sendDocLink .... ");  console.log(response);
		this.showToast("Felicitations/Congratulations!", "success");    
		return response;
	}).catch(function(error){ 
		this2.setState({ ["isLoaderOpen"]: false, ["hideSendButton"]:false});  
		//alert(error);
		const errObj=error;
		//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		if(errObj.status===undefined){
			errMessage +="\n "+t("zqwNVFrmEl14_x")
		}
		//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage}); 
		console.log("ERROR WITH TRANSACTION"); 
		console.log(error);
		console.log("SHORT ERROR MESSAGE: "+errMessage);
		console.log("RWA RESPONSE"); 
		//console.log(rawError);
		this2.showToast(errMessage, "error"); 
	});

	//this.showToast("OOOOOOOOOOOOOOOOooooh till here......", "error"); 
	return false;
  };


  handleCancel = () => { 
	if(this.actionMode==='new'){ 
		this.props.history.push(`/ZendGroupMemberships`);
	}else{ 
		const idx = this.props.match.params.id; 
		this.props.history.push(`/ZendGroupMemberships/${idx}`);
		//this.props.history.push(`/ZendGroupMemberships/${this.props.ZendGroupMemberships.id}`);
	}
  }

  showToast = (arg, toastType) => {
	//toastType: success or error or warning or info	  
	//console.log("showToast showToast "); //console.log(arg);
	//alert("hello it is an eventDrop here "+arg.date);
	//Toastr("Hello I am a toast message right here, for testing purpose...");
	//toastrMessage: '', toastrType: '', toastrDuration: 6000, 
	this.setState({ // update a property
		toastrMessage: arg, 
	toastrType: toastType
	});
	setTimeout(() => { 
	this.setState({ 
		toastrMessage: "", 
		toastrType: 'success'
	}); 
	}, 5500);
}

  render() {
    const { t } = this.props; 
	const open = this.props.openx; 
	const hideButton=this.state.hideSendButton; 
    const myToastMessage=this.state.toastrMessage;
    const toastrType=this.state.toastrType;
	const groupsAssignees=this.state.groupsAssignees;
	let listFormOptions = [];
	let docListformat = null; let docListformat2 = null;
	const assigneeId=this.state.assigneeId;
	const ticket_id=this.props.currentTicket;
	const message=this.state.message;
	const Alert=this.Alert;

	if(groupsAssignees!==null && groupsAssignees.groupList!==undefined){ 
		const docList2=groupsAssignees.groupList;
		 docListformat = docList2.length > 0
			  && docList2.map((item, i) => { 
				//   const firstname=item.firstname;  
				let title="";				 
				//listFormOptions.push({ value: item.id_zendesk, label: item.name });
				// if(item.id_zendesk!==null && item.id_zendesk!=="" && item.id_zendesk!=="null"){
				// 	listFormOptions.push({ value: item.id_zendesk, label: item.name });
				// }
	
				//console.log("MONITORING docList ", item.id_zendesk);
	
				return (<option value={item.zendeskid} key={"gl"+i}>{item.name}</option>); 
			  }, this);
	}

	if(groupsAssignees!==null && groupsAssignees.agentList!==undefined){ 
	const docList=groupsAssignees.agentList;
	 docListformat2 = docList.length > 0
          && docList.map((item, i) => { 
            //   const firstname=item.firstname;  
			let title="";
			 
			//listFormOptions.push({ value: item.id_zendesk, label: item.label });
			if(item.id_zendesk!==null && item.id_zendesk!=="" && item.id_zendesk!=="null"){
				listFormOptions.push({ value: item.id_zendesk, label: item.label });
			}
			else if(item.id==="0" && item.id_zendesk===""){
				listFormOptions.push({ value: item.id_zendesk, label: item.label });
			}

			// const locObj={value:item.id, formname:item.form_name, target:item.target};
			// //listFormOptions.push({ value: JSON.stringify(locObj), label: title });
			// listFormOptions.push({ value: item.id, label: title, valuex: JSON.stringify(locObj) });
			//this.listFormOptions=listFormOptions;
			console.log("MONITORING docList ", item.id_zendesk);

			return (<option value={item.id_zendesk} key={"dc"+i}>{item.label}</option>);
			  
			return (<option value={item.id} key={"dc"+i} data-formname={item.form_name} 
			data-target={item.target}>{item.form_name}</option>);  
          }, this);
		}
	
    return (
      <div>
        {/* <h3>{this.actionMode==='new'? t('zqwacAddZendGroupMemberships_x'): t('zqwacEditZendGroupMemberships_x')}</h3> */}
		<h5>Ticket #: {ticket_id} </h5>
        <form onSubmit={this.handleSubmit}>          		  

		  <div className="form-group">
			<label htmlFor="groupId">{t('zqwMesas03_x')}</label>
            <select name="groupId" id="groupId" defaultValue={this.state.groupId} onChange={this.handleChange2} onBlur={this.handleFocusout}
              className={this.state.e_status ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwstatus_x')}> 
                        {/* <option value="">Please Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option> */}
						{docListformat}
                    </select>
			{/* {this.state.e_status && (<span className="text-danger">{FIELD_VALIDATION['status'].message}</span>)}  */}
          </div>

		  <div className="form-group">
			<label htmlFor="assigneeId">{t('zqwMesas02_x')}</label>
            {/* <select name="assigneeId" id="assigneeId" defaultValue={this.state.assigneeId} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_status ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwstatus_x')}> 
                        <option value="">Please Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select> */}
			{/* {this.state.e_status && (<span className="text-danger">{FIELD_VALIDATION['status'].message}</span>)} */}
			<Select defaultValue={assigneeId} options={listFormOptions}  
				onChange={this.handleChangeUserAssignee}  />
          </div>

		  {hideButton===false?			
          <div className="form-group">
            <button type="submit" className="btn btn-primary">{t('Update_x')}</button> &nbsp; 
			{/* <button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button> */}
          </div>:""}		  
		  {message!==''?			
          <div className="form-group">
            <Alert severity="info">{message}</Alert>
          </div>:""}
        </form>
		<Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
  		<Loader  openx={this.state.isLoaderOpen}  />
      </div>
    );
  }

  Alert  = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
}

//const TransAssignTicketToGroupOrUser = withTranslation()(AssignTicketToGroupOrUser); 
const mapStateToProps = (state) => ({ ZendGroupMemberships: state.ZendGroupMemberships });

//const mapDispatchToProps = { addOrUpdateZendGroupMemberships, getZendGroupMemberships };   
//const mapDispatchToProps = {  };  
const mapDispatchToProps = { getDocLinkType, sendDocLink, getDocLink, getDownloadFileLink, getProductFields, getGroupsAndUsers, sendAssignedGroupUser };   


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(AssignTicketToGroupOrUser));
