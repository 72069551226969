import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateZonlineUsuarios, getZonlineUsuarios } from '../../actions/ZonlineUsuariosAction'; 
import { Validations } from '../../utils/validations'; 

let FIELD_VALIDATION={};
FIELD_VALIDATION['id_usuario']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['fecha']={check:true, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['nombre']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['apellido']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['correo']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['usuario']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['contrasena']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['id_rol_usuario']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['habilitado']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['logintoken']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['logintime']={check:true, chkType:'Timestamp', message:'incorrect value'};
FIELD_VALIDATION['zona']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['funcion']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['sucursales']={check:false, chkType:'String', message:'incorrect value'};

class ZonlineUsuariosForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		id_usuario:0,
		fecha:'', e_fecha: false,
		nombre:'', e_nombre: false,
		apellido:'', e_apellido: false,
		correo:'', e_correo: false,
		usuario:'', e_usuario: false,
		contrasena:'', e_contrasena: false,
		id_rol_usuario:'', e_id_rol_usuario: false,
		habilitado:'', e_habilitado: false,
		logintoken:'', e_logintoken: false,
		logintime:'', e_logintime: false,
		zona:'', e_zona: false,
		funcion:'', e_funcion: false,
		sucursales:'', e_sucursales: false,
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	if(this.actionMode==='edit'){ 
		console.log('edit  test  '+this.props.ZonlineUsuarios.id_usuario);
		if(this.props.ZonlineUsuarios.id_usuario===undefined){ 
			//means some refresh happens  id
			const idx = this.props.match.params.id_usuario; 
			//getZonlineUsuarios(idx);
			Promise.resolve(this.props.getZonlineUsuarios(idx))
			.then((response)=>{
				this.setState(response.ZonlineUsuariosOne);
				return response;
			});
		}	
	}	
  }

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id_usuario = this.state.id_usuario;
    const fecha = this.state.fecha;
    const nombre = this.state.nombre;
    const apellido = this.state.apellido;
    const correo = this.state.correo;
    const usuario = this.state.usuario;
    const contrasena = this.state.contrasena;
    const id_rol_usuario = this.state.id_rol_usuario;
    const habilitado = this.state.habilitado;
    const logintoken = this.state.logintoken;
    const logintime = this.state.logintime;
    const zona = this.state.zona;
    const funcion = this.state.funcion;
    const sucursales = this.state.sucursales;
    const ZonlineUsuarios = {id_usuario: id_usuario, fecha: fecha, nombre: nombre, apellido: apellido, correo: correo, usuario: usuario, contrasena: contrasena, id_rol_usuario: id_rol_usuario, habilitado: habilitado, logintoken: logintoken, logintime: logintime, zona: zona, funcion: funcion, sucursales: sucursales, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.ZonlineUsuarios[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('errorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateZonlineUsuarios(ZonlineUsuarios, 1); 
		//this.props.addZonlineUsuarios(ZonlineUsuarios); 
	}else{ 
		this.props.addOrUpdateZonlineUsuarios(ZonlineUsuarios, 2);
		//this.props.updateZonlineUsuarios(ZonlineUsuarios);
		
	}
  };

  handleCancel = () => { 
	if(this.actionMode==='new'){ 
		this.props.history.push(`./ZonlineUsuarios`);
	}else{ 
		const idx = this.props.match.params.id; 
		this.props.history.push(`./ZonlineUsuarios/${idx}`);
		//this.props.history.push(`./ZonlineUsuarios/${this.props.ZonlineUsuarios.id_usuario}`);
	}
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <h3>{this.actionMode==='new'? t('acAddZonlineUsuarios_x'): t('acEditZonlineUsuarios_x')}</h3>
        <form onSubmit={this.handleSubmit}>
          		  
		  
		  <div className="form-group" style={{display:'none'}} >
			<label htmlFor="id_usuario">{t('id_usuario_x')}</label>
            <input type="text" name="id_usuario" id="id_usuario" defaultValue={this.state.id_usuario} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id_usuario ? 'form-control fieldErr' : 'form-control'} placeholder={t('id_usuario_x')} /> 
			{this.state.e_id_usuario && (<span className="text-danger">{FIELD_VALIDATION['id_usuario'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="fecha">{t('fecha_x')}</label>
            <input type="text" name="fecha" id="fecha" defaultValue={this.state.fecha} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_fecha ? 'form-control fieldErr' : 'form-control'} placeholder={t('fecha_x')} /> 
			{this.state.e_fecha && (<span className="text-danger">{FIELD_VALIDATION['fecha'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="nombre">{t('nombre_x')}</label>
            <input type="text" name="nombre" id="nombre" defaultValue={this.state.nombre} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_nombre ? 'form-control fieldErr' : 'form-control'} placeholder={t('nombre_x')} /> 
			{this.state.e_nombre && (<span className="text-danger">{FIELD_VALIDATION['nombre'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="apellido">{t('apellido_x')}</label>
            <input type="text" name="apellido" id="apellido" defaultValue={this.state.apellido} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_apellido ? 'form-control fieldErr' : 'form-control'} placeholder={t('apellido_x')} /> 
			{this.state.e_apellido && (<span className="text-danger">{FIELD_VALIDATION['apellido'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="correo">{t('correo_x')}</label>
            <input type="text" name="correo" id="correo" defaultValue={this.state.correo} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_correo ? 'form-control fieldErr' : 'form-control'} placeholder={t('correo_x')} /> 
			{this.state.e_correo && (<span className="text-danger">{FIELD_VALIDATION['correo'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="usuario">{t('usuario_x')}</label>
            <input type="text" name="usuario" id="usuario" defaultValue={this.state.usuario} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_usuario ? 'form-control fieldErr' : 'form-control'} placeholder={t('usuario_x')} /> 
			{this.state.e_usuario && (<span className="text-danger">{FIELD_VALIDATION['usuario'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="contrasena">{t('contrasena_x')}</label>
            <input type="text" name="contrasena" id="contrasena" defaultValue={this.state.contrasena} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_contrasena ? 'form-control fieldErr' : 'form-control'} placeholder={t('contrasena_x')} /> 
			{this.state.e_contrasena && (<span className="text-danger">{FIELD_VALIDATION['contrasena'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="id_rol_usuario">{t('id_rol_usuario_x')}</label>
            <select name="id_rol_usuario" id="id_rol_usuario" defaultValue={this.state.id_rol_usuario} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id_rol_usuario ? 'form-control fieldErr' : 'form-control'} placeholder={t('id_rol_usuario_x')}> 
                        <option value="">Please Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
			{this.state.e_id_rol_usuario && (<span className="text-danger">{FIELD_VALIDATION['id_rol_usuario'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="habilitado">{t('habilitado_x')}</label>
            <select name="habilitado" id="habilitado" defaultValue={this.state.habilitado} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_habilitado ? 'form-control fieldErr' : 'form-control'} placeholder={t('habilitado_x')}> 
                        <option value="S">{t('S_x')}</option>
                        <option value="N">{t('N_x')}</option>
                        <option value="E">{t('E_x')}</option>
                    </select>
			{this.state.e_habilitado && (<span className="text-danger">{FIELD_VALIDATION['habilitado'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="logintoken">{t('logintoken_x')}</label>
            <input type="text" name="logintoken" id="logintoken" defaultValue={this.state.logintoken} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_logintoken ? 'form-control fieldErr' : 'form-control'} placeholder={t('logintoken_x')} /> 
			{this.state.e_logintoken && (<span className="text-danger">{FIELD_VALIDATION['logintoken'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="logintime">{t('logintime_x')}</label>
            <input type="text" name="logintime" id="logintime" defaultValue={this.state.logintime} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_logintime ? 'form-control fieldErr' : 'form-control'} placeholder={t('logintime_x')} /> 
			{this.state.e_logintime && (<span className="text-danger">{FIELD_VALIDATION['logintime'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="zona">{t('zona_x')}</label>
            <input type="text" name="zona" id="zona" defaultValue={this.state.zona} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_zona ? 'form-control fieldErr' : 'form-control'} placeholder={t('zona_x')} /> 
			{this.state.e_zona && (<span className="text-danger">{FIELD_VALIDATION['zona'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="funcion">{t('funcion_x')}</label>
            <input type="text" name="funcion" id="funcion" defaultValue={this.state.funcion} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_funcion ? 'form-control fieldErr' : 'form-control'} placeholder={t('funcion_x')} /> 
			{this.state.e_funcion && (<span className="text-danger">{FIELD_VALIDATION['funcion'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="sucursales">{t('sucursales_x')}</label>
            <input type="text" name="sucursales" id="sucursales" defaultValue={this.state.sucursales} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_sucursales ? 'form-control fieldErr' : 'form-control'} placeholder={t('sucursales_x')} /> 
			{this.state.e_sucursales && (<span className="text-danger">{FIELD_VALIDATION['sucursales'].message}</span>)} 
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')}</button> &nbsp; 
			<button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button>
          </div>
        </form>
      </div>
    );
  }
}

//const TransZonlineUsuariosForm = withTranslation()(ZonlineUsuariosForm); 
const mapStateToProps = (state) => ({ ZonlineUsuarios: state.ZonlineUsuarios });

const mapDispatchToProps = { addOrUpdateZonlineUsuarios, getZonlineUsuarios };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(ZonlineUsuariosForm));
