import React from "react";

import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    NavLink,
    ButtonGroup
    // Table
} from 'reactstrap';

import {Redirect} from 'react-router-dom';

import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faCoins,
    faTachometerAlt,
    faTags,
    faFilter,
    faGlobeAmericas,
    faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import UserIcon from './UserIcon'
import DashboardElement from '../../../../utils/DashboardElement';

import rootsActions from '../../../../_actions/root-actions';

import {connect} from 'react-redux';

const FR = 'fr';
const STRING = 'string';

const OBJECT = 'object';
class TopMenu extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            redirect:''
        };

    }

    
    genLang = (text,lang=FR) =>{
        
        if(typeof text === STRING){
            return text;
        }
    
        if(typeof text === OBJECT){
            if(lang && text[lang]){ 
                return text[lang];
            }
            else{
                console.error('lang missing');
                return ''
            }
        }
    
        return '';
    }

    render(){
     
        
        if(this.state.redirect){
            console.log('prevented')
            return(
                <Redirect
                to={{
                    pathname: this.state.redirect,
                    // search: window.location.search+"?return="+window.location.pathname,
                    // state: { referrer: props.location.pathname }
                }}
            />
            )
        }


        if(!this.props.menu || !this.props.menu.length)
            return(<></>);

        return (
            <Col md={12}>
                <div className="top-bar color-scheme-light">
                    <ul>
                        {_.map(this.props.menu,(e) => {
                            return (
                                <li key={e.activeName} className={this.props.active===e.activeName?"active":""}>
                                    <a href={e.url} title={e.title} onClick={(f) => {f.preventDefault();f.stopPropagation();this.setState({redirect:e.url})}}>{
                                        this.genLang(e.name, this.props.lang)}
                                    </a>
                                </li>
                            )
                        })}
             

                        {/* <li className={this.props.active==='contact'?"active":""}> 
                            <a href="#">contact</a>
                        </li> */}
                        {/*            
                        <li className={this.props.active==='task'?"active":""}>
                            <a href="#">Tâches</a>
                        </li>
                        <li className={this.props.active==='clients'?"active":""}>
                            <a href="#">Clients</a>
                        </li>
                        <li className={this.props.active==='calendar'?"active":""}>
                            <a href="#">Calendrier</a>
                        </li>
                         */}
                    </ul>
                    <div className="top-menu-controls">
                        {/* barre de recherche */}
                        {/* <div className="element-search autosuggest-search-activator">
                            <input placeholder="Start typing to search..." /></div> */}

                        {/* drop down avec icone notification */}
                        {/* <div className="messages-notifications os-dropdown-trigger os-dropdown-position-left">
                            <i className="os-icon os-icon-mail-14"></i><div className="new-messages-count">12</div>
                            <div className="os-dropdown light message-list">
                                <ul>
                                    <li>
                                        <a href="#">
                                            <div className="user-avatar-w">
                                                <img alt="" src={"https://www.w3schools.com/howto/img_avatar2.png"} />
                                            </div>
                                            <div className="message-content">
                                                <h6 className="message-from">John Mayers</h6>
                                                <h6 className="message-title">Account Update</h6>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div className="user-avatar-w">
                                                <img alt="" src={"https://www.w3schools.com/howto/img_avatar2.png"} />
                                            </div>
                                            <div className="message-content">
                                                <h6 className="message-from">Phil Jones</h6>
                                                <h6 className="message-title">Secutiry Updates</h6>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div className="user-avatar-w">
                                                <img alt="" src={"https://www.w3schools.com/howto/img_avatar2.png"} />
                                            </div><div className="message-content"><h6 className="message-from">Bekky Simpson</h6>
                                                <h6 className="message-title">Vacation Rentals</h6>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div className="user-avatar-w">
                                                <img alt="" src={"https://www.w3schools.com/howto/img_avatar2.png"} />
                                            </div>
                                            <div className="message-content">
                                                <h6 className="message-from">Alice Priskon</h6>
                                                <h6 className="message-title">Payment Confirmation</h6>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        
                        {/* user drop menu */}
                        {/* <div className="logged-user-w">
                            <div className="logged-user-i">
                                <div className="avatar-w">
                                    <img alt="" src={"https://www.w3schools.com/howto/img_avatar2.png"} />
                                </div>
                                <div className="logged-user-menu color-style-bright">
                                    <div className="logged-user-avatar-info">
                                        <div className="avatar-w">
                                            <img alt="" src={"https://www.w3schools.com/howto/img_avatar2.png"} />
                                        </div><div className="logged-user-info-w"><div className="logged-user-name">Maria Gomez</div>
                                            <div className="logged-user-role">Administrator</div>
                                        </div>
                                    </div>
                                    <div className="bg-icon">
                                        <i className="os-icon os-icon-wallet-loaded"></i>
                                    </div>
                                    <ul>
                                        <li>
                                            <a href="apps_email.html">
                                                <i className="os-icon os-icon-mail-01"></i>
                                                <span>Incoming Mail</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="users_profile_big.html">
                                                <i className="os-icon os-icon-user-male-circle2"></i>
                                                <span>Profile Details</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="users_profile_small.html">
                                                <i className="os-icon os-icon-coins-4"></i>
                                                <span>Billing Details</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="os-icon os-icon-others-43"></i>
                                                <span>Notifications</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="os-icon os-icon-signs-11"></i>
                                                <span>Logout</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </Col>
        )
    }
}

const mapStateToProps = state => ({
    rootsActions:rootsActions,
    menu:state.menu
  });
  
  const mapActionsToProps = {
    setCustomer:rootsActions.customersActions.setUser,
  }
  
export default connect(mapStateToProps,mapActionsToProps)(TopMenu);