import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNeoVentes, getNeoVentes, getNeoVentesAll, getNeoVentesGoto, deleteNeoVentes, getNeoVentesByPeople } from '../../actions/NeoVentesAction'; 
import { addOrUpdateNeoSalesPlanningWeekly, getNeoSalesPlanningWeekly, getNeoSalesPlanningWeeklyAll, getNeoSalesPlanningWeeklyGoto, deleteNeoSalesPlanningWeekly } from '../../actions/NeoSalesPlanningWeeklyAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
import { TableFooter, TableCell, TableRow, TablePagination } from "@material-ui/core";
import CustomToolbarSelect from "../CustomToolbarSelect";
import BaseHeaderSaleReport from "../../BaseHeaderSaleReport";
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 
import Toastr from "../../messages";
import  Loader from "../../loader";

class NeoSalesPlanningSellerList extends Component {
	constructor(props) {
		super(props); 
		this.state ={weeksatrt:"", weekend:"", groupby:"day", groupbyprovince:"", 
		 	isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000,
			 sumQuantity:0, sumSubtotal:0, sumTax1:0, sumTax2:0, sumTaxtotal:0,  sumTotal:0, 
			 sumQuantityno:0, sumQuantitysu:0, 
			 listOfSales:[], 
		};
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);

		this.footerRef=React.createRef();
		this.footerRefno=React.createRef();
		this.footerRefsu=React.createRef();
		this.footerRef2=React.createRef();
		this.footerRef3=React.createRef();
		this.footerRef4=React.createRef();
		this.footerRef5=React.createRef();
		this.footerRef6=React.createRef();
		this.footerRef7=React.createRef();
		 
	}
	 

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value }); 
	};

	handleLoadData = (event) => {
		const { t } = this.props;
		//this.setState({ [event.target.name]: event.target.value }); 
		const weeksatrt=this.state.weeksatrt;
		const weekend=this.state.weekend;
		const groupby=this.state.groupby;
		const province=this.state.groupbyprovince;
		console.log("weeksatrt="+weeksatrt+" "+weekend+" "+groupby);
		if((weeksatrt!=="" && weekend==="") || (weeksatrt==="" && weekend!=="")){
			window.alert(t("zqwCViewEl22_x"));
			return false; 
		}
		const obj={date:weeksatrt, date2:weekend, dayWeekMonth:groupby, province:province};
		 
		//this.props.getNeoVentesByPeople(obj);
		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		Promise.resolve(this.props.getNeoVentesByPeople(obj))
        .then((response)=>{ 
		  this.setState({ ["listOfSales"]: response.NeoVentesOne, ["isLoaderOpen"]: false});
		  this.showToast(t("zqwCViewEl11_x"), "success"); 
		  console.log("weeeeeeeeeeeeeee ", response); 
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          alert(error);
        });
	};

  
	componentDidMount() {
		const { t } = this.props; 
		//this.props.getNeoVentesAll();
		const obj={date:"", date2:"", dayWeekMonth:"", province:""}; 
		//this.props.getNeoVentesByPeople(obj);
		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		Promise.resolve(this.props.getNeoVentesByPeople(obj))
        .then((response)=>{ 
		  this.setState({ ["listOfSales"]: response.NeoVentesOne, ["isLoaderOpen"]: false});
		  this.showToast(t("zqwCViewEl11_x"), "success"); 
		  console.log("weeeeeeeeeeeeeee ", response); 
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          alert(error);
        });
	}

	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info
		const toastrDuration=this.state.toastrDuration; //5500	 
		this.setState({  
			toastrMessage: arg, 
		  toastrType: toastType
		  });
		setTimeout(() => { 
		  this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		  }); 
		}, toastrDuration);
	}

	handleTableDataChange = (displayData) => {
		//this.setState({ [event.target.name]: event.target.value }); 
		//const displayData=tableState.displayData;
		let sumQuantity=0; 
		let sumQuantityno=0; 
		let sumQuantitysu=0; 
		let sumSubtotal=0; 
		let sumTax1=0; 
		let sumTax2=0; 
		let sumTaxtotal=0; 
		let sumTotal=0; 

		//  sumQuantity=0; 
		//  sumSubtotal=0; 
		//  sumTax1=0; 
		//  sumTax2=0; 
		//  sumTaxtotal=0; 
		//  sumTotal=0; 
		let promosList = displayData.length > 0
			&& displayData.map((rowItemx, i) => { 
				const item=rowItemx.data;
				sumQuantity +=parseFloat(item[0]);
				sumSubtotal +=parseFloat(item[2]);
				sumTax1 +=parseFloat(item[3]);
				sumTax2 +=parseFloat(item[4]);
				sumTaxtotal +=parseFloat(item[6]);
				sumTotal +=parseFloat(item[7]);
			 //console.log("rwwwwwwwwww", item);
			return (
				null
			);
		}, this);

		setTimeout(() => {
            this.setState({ ["sumQuantity"]: sumQuantity, ["sumSubtotal"]: sumSubtotal, 
		["sumTax1"]: sumTax1, ["sumTax2"]: sumTax2, 
		["sumQuantityno"]: sumQuantityno, ["sumQuantitysu"]: sumQuantitysu,
		["sumTaxtotal"]: sumTaxtotal,["sumTotal"]: sumTotal, });
          }, 3000);
		  
		 
		// this.setState({ ["sumQuantity"]: sumQuantity, ["sumSubtotal"]: sumSubtotal, 
		// ["sumTax1"]: sumTax1, ["sumTax2"]: sumTax2,
		// ["sumTaxtotal"]: sumTaxtotal,["sumTotal"]: sumTotal, });
		//sumQuantity:0, sumSubtotal:0, sumTax1:0, sumTax2:0, sumTaxtotal:0,  sumTotal:0,
	};
	
	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.NeoVentes;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteNeoVentes(gridIds[0]);

	}

  updateXRecordsMain(selectedRows){  
    const { t } = this.props;
	const objList  = this.props.NeoVentes;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
      gridIndexes.push(row.index); 
      idIndex=row.index; 
	return null;
    });
 
    if(gridIndexes.length>1){
      alert(`{t('zqwgridIndexesfound_x')}`);
      return false;
    }
 
    let obj=objList[idIndex]; 
	this.props.getNeoVentesGoto(obj.id); 
	//history.push(`./neoventes/${obj.id}/edit`); 
  }
  

  render() { 
	const { t } = this.props;
	const dataSales=this.state.listOfSales;
	//const dataSales=this.props.NeoVentes; 
	
	const columns = [
		// {
		//  name: "id", label: t('zqwid_x'),
		//  options: { filter: true, sort: true,}
		// },
		{
			name: "due_date", label: t('zqwCViewEl23_x'),
			options: { filter: true, sort: true,}
		},
		// {
		//  name: "quantity", label: "Qté Nouv.",
		//  options: { filter: true, sort: true,}
		// },	 
		{
			name: "qtNouveau", label: t('zqwCViewEl24_x'),
			options: { filter: true, sort: true,}
		},	
		{
			name: "nouveau", label: t('zqwCViewEl25_x'),
			options: { filter: true, sort: true,}
		},
		// {
		//  name: "sub_total", label: t('zqwsub_total_x'),
		//  options: { filter: true, sort: true,}
		// },
		{
			name: "qtUpsale", label: t('zqwCViewEl26_x'),
			options: { filter: true, sort: true,}
		   },	 
		{
		 name: "upsell", label: t('zqwCViewEl27_x'),
		 options: { filter: true, sort: true,}
		},		
		{
			name: "leads_amount", label: "Lead",
			options: { filter: true, sort: true,}
		   },		
		{
			name: "total", label: t('zqwtotal_x'),
			options: { filter: true, sort: true,}
		},
		{
			name: "panier", label: t('zqwCViewEl28_x'),
			options: { filter: true, sort: true,}
		},
		{
			name: "vendor", label: t('zqwCViewEl29_x'),
			options: { filter: true, sort: true,}
		}, 		
		{
		 name: "province", label: t('zqwprovince_x'),
		 options: { filter: true, sort: true,}
		},
	   ];   

const textLabelOptions= {
    body: {
      noMatch: t('zqwgridnoMatch_x'),
      toolTip: t('zqwgridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('zqwgridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('zqwgridnext_x'),
      previous: t('zqwgridprevious_x'),
      rowsPerPage: t('zqwgridrowsPerPage_x'),
      displayRows: t('zqwgriddisplayRows_x'),
    },
    toolbar: {
      search: t('zqwgridsearch_x'),
      downloadCsv: t('zqwgriddownloadCsv_x'),
      print: t('zqwgridprint_x'),
      viewColumns: t('zqwgridviewColumns_x'),
      filterTable: t('zqwgridfilterTable_x'),
    },
    filter: {
      all: t('zqwgridall_x'),
      title: t('zqwgridftitle_x'),
      reset: t('zqwgridreset_x'),
    },
    viewColumns: {
      title: t('zqwgridvctitle_x'),
      titleAria: t('zqwgridvctitleAria_x'),
    }, 
  };
  
  let sumQuantity=0; let sumQuantityno=0; let sumQuantitysu=0; 
  let sumSubtotal=0; 
  let sumTax1=0; 
  let sumTax2=0; 
  let sumTaxtotal=0; 
  let sumTotal=0; 
  let sumTotalLead=0; 
			
	const options = {
	  filterType: 'checkbox',
	  textLabels: textLabelOptions,
	  onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		//console.log(currentRowsSelected, allRowsSelected);
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log(allRowsSelected);
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} isEdit={true} isDelete={true} />
	  ),
	  /*onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
		//What can I add here?
		//console.log(rowData, rowState);
		console.log("onFilterChange");
		console.log(changedColumn, changedColumnIndex, displayData);
	  },
	  onSearchChange: (searchText) => {
		//What can I add here?
		if(searchText!=null){ 
		console.log("onSearchChange");
		console.log(searchText);
		}
	  },
	  onSearchClose: () => {
		//What can I add here?
		console.log("onSearchClose");
	  },*/

	  onTableChange: (action, tableState) => {
		//What can I add here?
		//console.log("onTableChange"); console.log(action, tableState);
		console.log("onTableChange 2");
		console.log(tableState);
		const displayData=tableState.displayData;
		 sumQuantity=0; sumQuantityno=0; sumQuantitysu=0; 
		 sumSubtotal=0; 
		 sumTax1=0; 
		 sumTax2=0; 
		 sumTaxtotal=0; 
		 sumTotal=0; sumTotalLead=0; 
		let promosList = displayData.length > 0
			&& displayData.map((rowItemx, i) => { 
				const item=rowItemx.data;
				// if(item[0]!=null){ sumQuantity +=parseFloat(item[0]); }
				// if(item[2]!=null){ sumSubtotal +=parseFloat(item[2]); }
				// if(item[3]!=null){ sumTax1 +=parseFloat(item[3]);}
				// if(item[4]!=null){ sumTax2 +=parseFloat(item[4]); }
				// if(item[6]!=null){ sumTaxtotal +=parseFloat(item[6]); }
				// if(item[7]!=null){ sumTotal +=parseFloat(item[7]); }
				//-------------------------------------------------
				// ["2020-12-16", "2", "178.00", "0.00", "178.00", "89.00", "Julie Canuel", "ON"]

				if(item[1]!=null){ sumQuantity +=parseFloat(item[1]); }
				if(item[1]!=null){ sumQuantityno +=parseFloat(item[1]); }
				if(item[3]!=null){ sumQuantitysu +=parseFloat(item[3]); }
				if(item[2]!=null){ sumSubtotal +=parseFloat(item[2]); } //nouveauClient
				if(item[4]!=null){ sumTax1 +=parseFloat(item[4]);} //upsell
				if(item[7]!=null){ sumTax2 +=parseFloat(item[7]); } //panierMoyen
				if(item[1]!=null){ sumTaxtotal +=0; }
				if(item[5]!=null){ sumTotalLead +=parseFloat(item[5]); } //laed
				if(item[6]!=null){ sumTotal +=parseFloat(item[6]); } //total
				 
				// sumQuantity +=parseFloat(item[0]);
				// sumSubtotal +=parseFloat(item[2]);
				// sumTax1 +=parseFloat(item[3]);
				// sumTax2 +=parseFloat(item[4]);
				// sumTaxtotal +=parseFloat(item[6]);
				// sumTotal +=parseFloat(item[7]);

				//console.log("zaaaaaaaaa", item); 
			 
			return (
				null
			);
		}, this);

		//this.handleTableDataChange(displayData);
		this.footerRef.current.innerText=sumQuantity;
		//this.footerRefno.current.innerText=sumQuantityno;
		this.footerRefsu.current.innerText=sumQuantitysu;
		this.footerRef2.current.innerText=sumSubtotal.toFixed(2);
		this.footerRef3.current.innerText=sumTax1.toFixed(2);
		this.footerRef4.current.innerText=sumTax2.toFixed(2);
		this.footerRef5.current.innerText=sumTaxtotal.toFixed(2);
		this.footerRef6.current.innerText=sumTotal.toFixed(2); 
		this.footerRef7.current.innerText=sumTotalLead.toFixed(2); 
		 
		// this.setState({ ["sumQuantity"]: sumQuantity, ["sumSubtotal"]: sumSubtotal, 
		// ["sumTax1"]: sumTax1, ["sumTax2"]: sumTax2,
		// ["sumTaxtotal"]: sumTaxtotal,["sumTotal"]: sumTotal, });
		//sumQuantity:0, sumSubtotal:0, sumTax1:0, sumTax2:0, sumTaxtotal:0,  sumTotal:0, 
	  },
	  rowsPerPage: 20,
	  rowsPerPageOptions: [10, 15, 20, 50,100,200,300,400,500,1000,5000],
	  customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage 
      ) => {
        const startIndex = page * rowsPerPage;
		const endIndex = (page + 1) * rowsPerPage;
		const total=sumTotalReducer(startIndex, endIndex);
        return (
          <TableFooter>
            <TableRow>
              {/* <TableCell colSpan={6}>
                Total Salery for current page: {sumSalery(startIndex, endIndex)}
              </TableCell> */}
			  <TableCell>
                 &nbsp; 
				 {/* Quantité: <b><span ref={this.footerRef}>{sumQuantity}</span></b> */}
              </TableCell>
			  <TableCell colSpan={2}>
			  {t("zqwCViewEl24_x")}: <b><span ref={this.footerRef}>{sumQuantity}</span></b>
              </TableCell>
			  <TableCell colSpan={2}>
			   &nbsp; 
              </TableCell>
			  <TableCell colSpan={2}>
			  {t("zqwCViewEl30_x")} $: <b><span ref={this.footerRef2}>{sumSubtotal.toFixed(2)}</span></b>
              </TableCell>
			  <TableCell colSpan={1}>
				  {/* Upsell */}
			  {t("zqwCViewEl26_x")}: <b><span ref={this.footerRefsu}>{sumQuantitysu}</span></b>
              </TableCell>
			  <TableCell colSpan={1}>
				  {/* Upsell */}
			  {t("zqwCViewEl27_x")}: <b><span ref={this.footerRef3}>{sumTax1.toFixed(2)}</span></b>
              </TableCell>
			  <TableCell colSpan={1}>
				  {/* lead */}
			  Lead: <b><span ref={this.footerRef7}>{sumTotalLead.toFixed(2)}</span></b>
              </TableCell>
			  <TableCell colSpan={2}>
                {/* {sumTotal(startIndex, endIndex)} */}
				{t("zqwNVFrmEl61_x")}: <b><span ref={this.footerRef6}>{total.toFixed(2)}</span></b>
              </TableCell>
			  <TableCell colSpan={1}>
			  {t("zqwCViewEl28_x")}: <b><span ref={this.footerRef4}>{sumTax2.toFixed(2)}</span></b>
              </TableCell>
			  <TableCell colSpan={2}>
			  &nbsp; 
              </TableCell>
			  <TableCell colSpan={3}>
				  &nbsp; &nbsp; 
				  <div style={{display:'none'}}>{t("zqwCViewEl31_x")}: <b><span ref={this.footerRef5}>{sumTaxtotal.toFixed(2)}</span></b></div>  
              </TableCell>
			  {/* <TableCell colSpan={2}> 
				Total: <b><span ref={this.footerRef6}>{total.toFixed(2)}</span></b>
              </TableCell> */}
            </TableRow>
			<TableRow>
				<TablePagination
					count={count}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={(_, page) => changePage(page)}
					onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
					rowsPerPageOptions={[5, 10, 15, 20, 50,100,200,300,400,500,1000,5000]}
				/>
			</TableRow>
          </TableFooter>
        );
      }
	};

	//const dataSales=this.props.NeoVentes; 
	/*const sumSalery = (startIndex, endIndex) => {
		return dataSales
		  .slice(startIndex, endIndex)
		  .map(a => a[4])
		  .reduce((total, salery) => (total += salery), 0);
	  };*/

	  const sumTotalReducer = (startIndex, endIndex) => {
		return dataSales
		  .slice(startIndex, endIndex)
		  .map((item, i) => {  //a => a.total
			if(item.quantity!=null){ sumQuantity +=parseFloat(item.quantity); }
			if(item.sub_total!=null){ sumSubtotal +=parseFloat(item.sub_total); }
			if(item.tax1!=null){ sumTax1 +=parseFloat(item.tax1); }
			if(item.tax2!=null){ sumTax2 +=parseFloat(item.tax2); }
			if(item.tax_total!=null){ sumTaxtotal +=parseFloat(item.tax_total); }
			if(item.total!=null){ sumTotal +=parseFloat(item.total); }
			//console.log("hello here... ", i, item.quantity);

			// sumQuantity +=parseFloat(item.quantity);
			// sumSubtotal +=parseFloat(item.sub_total);
			// sumTax1 +=parseFloat(item.tax1);
			// sumTax2 +=parseFloat(item.tax2);
			// sumTaxtotal +=parseFloat(item.tax_total);
			// sumTotal +=parseFloat(item.total);
			return parseFloat(item.total);
		  })
		  .reduce((total, salery) => (total += salery), 0);
	  };
	  //.map(a => a.total) 
	  
	//   {sumQuantity(startIndex, endIndex)} 
	// 	{sumSubtotal(startIndex, endIndex)} 
	// 	{sumTax1(startIndex, endIndex)} 
	// 	{sumTax2(startIndex, endIndex)} 
	// 	{sumTaxtotal(startIndex, endIndex)} 
	// 	{sumSalery(startIndex, endIndex)}
	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType;
	const listOfSales=this.state.listOfSales;
	  
	  
    if(dataSales.length) {                                
      return (
        <div>
          {/* <h4>Liste de {t('zqwNeoVentes_x')}</h4>	 */}
		  <h4>{t("zqwCViewEl32_x")}</h4>

		  <div className="col-lg-12"> 
			<div className="card card-primary card-outline">
				<div className="card-header">
				<h5 className="m-0">{t("zqwCViewEl33_x")}</h5>
				</div> 
				<div className="card-body">
				
				<BaseHeaderSaleReport />
  
				</div>
			</div> 
		 </div>
		  {/* <BaseHeaderSaleReport /> */}
		    
		  <div className="form-group"> 
		  	<div className="input-group">
				<input type="date" name="weeksatrt"  onChange={this.handleChange} 
				className={'form-control'} placeholder={t("zqwCViewEl34_x")} /> 
				&nbsp; &nbsp;  
				<input type="date" name="weekend"  onChange={this.handleChange} 
				className={'form-control'} placeholder={t("zqwCViewEl35_x")} />
				&nbsp; &nbsp; 
				<select name="groupby" className={'form-control'} 
              	defaultValue={"day"}  onChange={this.handleChange} > 
                <option value="day"> {t("zqwCViewEl36_x")} </option>
                <option value="week">{t("zqwCViewEl37_x")} </option>
                <option value="month"> {t("zqwCViewEl38_x")} </option>  
              </select>
			  &nbsp; &nbsp; 
				<select name="groupbyprovince" className={'form-control'} 
              	defaultValue={""}  onChange={this.handleChange} > 
                <option value=""> {t("zqwCViewEl39_x")} </option>
				<option value="all"> {t("zqwCViewEl40_x")} </option>
				<option value="QC"> {t("zqwCViewEl41_x")} Québec </option>
                <option value="ON">{t("zqwCViewEl41_x")} Ontario </option> 
              </select>
			  &nbsp; &nbsp; 
				<button type="button" className="btn btn-primary" onClick={this.handleLoadData}>{t("zqwCViewEl42_x")}</button>   
			</div>	
          </div>		  	  
		  <MUIDataTable
			  title={""}
			  data={dataSales}
			  columns={columns}
			  options={options}
			/>
			<Loader  openx={this.state.isLoaderOpen}  />
		  	<Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
        </div>
      )    
    } else {
      return (<div>{t('zqwgridNoRecordfound_x')}  <Loader  openx={this.state.isLoaderOpen}  /></div>)
    }  }
}


const mapStateToProps = (state) => ({ NeoVentes: state.NeoVentes });  
const mapDispatchToProps = { addOrUpdateNeoVentes, getNeoVentes, getNeoVentesAll, getNeoVentesGoto, deleteNeoVentes, getNeoVentesByPeople };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoSalesPlanningSellerList));
