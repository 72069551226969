import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Snackbar } from "@mui/material";
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Button } from "reactstrap";
import "./Toast.css";

export default class Toast extends Component {
  genStyleClass = (type) => {
    if (type === "success") return "toast-success";
    else if (type === "error") return "toast-error";
    else if (type === "info") return "toast-info";
    else if (type === "warning") return "toast-warning";
    else return "";
  };

  genIcon = (type) => {
    if (type === "success") return faCheckCircle;
    else if (type === "warning") return faExclamationTriangle;
    else if (type === "info") return faInfoCircle;
    else if (type === "error") return faTimesCircle;
    else return "";
  };

  genColor = (type) => {
    if (type === "success") return { color: "green" };
    else if (type === "warning") return { color: "#ff7300" };
    else if (type === "info") return { color: "#20acdb" };
    else if (type === "error") return { color: "red" };
    else return {};
  };

  genTitle = (type) => {
    if (type === "success") return "Succès";
    else if (type === "warning") return "Attention";
    else if (type === "info") return "Info";
    else if (type === "error") return "Erreur";
    else return "";
  };

  // This component takes in a type(success, error, warning or info), message and clear.
  // Optional props: autoHide will hide after 2 seconds, blockClickaway: don't close toast when click on screen

  render() {
    const { type, message, clear, autoHide, blockClickaway } = this.props;
    const icon = this.genIcon(type);
    const color = this.genColor(type);
    const time = autoHide ? 2000 : undefined;
    return (
      <Snackbar
        open={message.length ? true : false}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={time}
        onClose={(e, reason) => {
          if (blockClickaway && reason === "clickaway") {
            return;
          } else {
            clear();
          }
        }}
      >
        <div className={`toast-container ${this.genStyleClass(type)}`}>
          <Col sm="1">
            <FontAwesomeIcon icon={icon} size="lg" style={color} />
          </Col>
          <Col sm="11" style={{ paddingRight: "1rem", marginLeft: "1rem" }}>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <h6 style={color}>{this.genTitle(type)}</h6>
              <Button
              close
                size="sm"
                onClick={() => clear()}
                buttonStyle={{ backgroundColor: "inherit", color: "darkGrey" }}
              />
            </Row>
            <Row style={{ marginRight: "1rem" }}>
              <div style={{ whiteSpace: "nowrap" }}>{message}</div>
            </Row>
          </Col>
        </div>
      </Snackbar>
    );
  }
}
