import React, { useEffect } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';

import FaceIcon from '@material-ui/icons/Face';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

import UserLogout  from "./UserLogout";

import ChangeLanguage from './changeLanguage'; 
import { Link } from "react-router-dom";
 
import { connect, useDispatch } from 'react-redux';
import { withTranslation, useTranslation } from 'react-i18next';
import { getDailyGoalSale } from './actions/NeoSalesPlanningDailyAction';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

//export class BaseHeader2 extends React.Component {
export function BaseHeaderSaleReport(props) {
  const { t, i18n } = useTranslation('common');
  let _isMounted = true; //false;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorLeftMenu, setAnchorLeftMenu] = React.useState(false);
  const [userLogoutOpen, setUserLogoutOpen] = React.useState(false);
  const [saleGoalDaily, setSaleGoalDaily] = React.useState("...");
  const [itemSale2, setItemSale2] = React.useState("...");
  const [itemSale3, setItemSale3] = React.useState("...");
  const [itemSale4, setItemSale4] = React.useState("...");
  const [itemSale5, setItemSale5] = React.useState("...");
  const [itemSale6, setItemSale6] = React.useState("...");
  const [itemSale7, setItemSale7] = React.useState("...");
  const [itemSale8, setItemSale8] = React.useState("...");
  const [itemSaledlq, setItemSaledlq] = React.useState("...");
  const [itemSaledla, setItemSaledla] = React.useState("...");
  const [itemSaledtot, setItemSaledtot] = React.useState("...");
  const [itemSaledlqsuivi, setItemSaledlqsuivi] = React.useState("...");

  const [saleGoalWeekly, setSaleGoalWeekly] = React.useState("...");
  const [itemSale20, setItemSale20] = React.useState("...");
  const [itemSale30, setItemSale30] = React.useState("...");
  const [itemSale40, setItemSale40] = React.useState("...");
  const [itemSale50, setItemSale50] = React.useState("...");
  const [itemSale60, setItemSale60] = React.useState("...");
  const [itemSale70, setItemSale70] = React.useState("...");
  const [itemSale80, setItemSale80] = React.useState("...");
  const [itemSalewlq, setItemSalewlq] = React.useState("...");
  const [itemSalewla, setItemSalewla] = React.useState("...");
  const [itemSalewtot, setItemSalewtot] = React.useState("...");
  const [itemSalewlqsuivi, setItemSalewlqsuivi] = React.useState("...");
  const [itemSale83, setItemSale83] = React.useState("...");
  const [itemSale83b, setItemSale83b] = React.useState("...");
  const [itemSale83c, setItemSale83c] = React.useState("...");
  const [itemSale83a, setItemSale83a] = React.useState("...");
  const [itemSale83ba, setItemSale83ba] = React.useState("...");
  const [itemSale83ca, setItemSale83ca] = React.useState("...");
   

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isLeftMenuOpen = Boolean(anchorLeftMenu);
  //const isUserLogoutOpen= Boolean(anchorLeftMenu);

  const dispatch=useDispatch();

  const [anchorElSale, setAnchorElSale] = React.useState(true);
  const openSale = Boolean(anchorElSale);

  const handleClickSale = (event) => {
    setAnchorElSale(event.currentTarget);
  };

  const handleCloseSale = () => {
    //setAnchorElSale(null);
    setAnchorElSale(true);
  };

  useEffect(() => {
    _isMounted = true;
    //console.log("useEffect Header2 POOOOPS "); ////console.log(props);
    //const czx= dispatch(getDailyGoalSale()); //dispatch(getDailyGoalSale());
    ////console.log(czx); 
    ////console.log("useEffect Header2 POOOOPS ");
    
    handleSaleGoal(null);

    const interval= setInterval(() => {
      handleSaleGoal(null);
    }, 300000);

    return () => clearInterval(interval);
    
  }, []);

  const handleSaleGoal = (event) => {
    const cxz= JSON.parse(localStorage.getItem("goalsale")); // localStorage.getItem("goalsale");
    //const cxz=response.NeoSalesPlanningDailyOne;
    //setSaleGoalDaily(cxz.qtyToday+"/"+cxz.expectation.expected_quantity);
    //const expected_quantity=(cxz.expectation.expected_quantity==="0")? cxz.expectation.expected_quantity:1;
    setSaleGoalDaily(cxz.expectation.expected_quantity+" "+t("zqwBHElem8_x")+" "+  
    cxz.qtyToday +"  ("+cxz.qtyToday+"/"+cxz.expectation.expected_quantity+")");
    setItemSale2(cxz.dat2); setItemSale3(cxz.expectation.expected_quantity);  
    setItemSale4(cxz.expectation.expected_amount);
    setItemSale5(cxz.qtyToday); setItemSale6(cxz.amntToday);  
    setItemSale7(((cxz.qtyToday*100)/(cxz.expectation.expected_quantity*1)).toFixed(2)+"%");  

    setItemSaledlq(cxz.qtyTodayLead);
    if(cxz.today!==null && cxz.today.qtUpsale!==undefined && cxz.expectation.suivi!==undefined){
      setItemSaledlqsuivi(cxz.today.qtUpsale+"/"+cxz.expectation.suivi); 
    }   
    setItemSaledla(cxz.amntTodayLead);  
    setItemSaledtot(((cxz.amntToday*1)+(cxz.amntTodayLead*1)));  
    ////console.log("useEffect Header2 "); //console.log(response.NeoSalesPlanningDailyOne);

    // setSaleGoalWeekly(cxz.weekexpectation.expected_quantity+" Totale vente hebdomadaire "+ 
    // cxz.qtyWeek +"  ("+cxz.qtyWeek+"/"+cxz.weekexpectation.expected_quantity+")");

    setSaleGoalWeekly(t("zqwBHElem7_x")+" "+cxz.qtyWeek+"/"+cxz.weekexpectation.expected_quantity+"");


    setItemSale20(cxz.dat2); setItemSale30(cxz.weekexpectation.expected_quantity);  
    setItemSale40(cxz.weekexpectation.expected_amount);
    setItemSale50(cxz.qtyWeek); setItemSale60(cxz.amntWeek);  
    setItemSale70(((cxz.qtyWeek*100)/(cxz.weekexpectation.expected_quantity*1)).toFixed(2)+"%");
    
    //let panierMoyen= ((cxz.amntWeek*1)/(cxz.qtyWeek*1)); // panierMoyen===NaN 
    let panierMoyen= (((cxz.amntWeek*1)+(cxz.amntWeekLead*1))/(cxz.qtyWeek*1)); 
    //let panierMoyen= (((cxz.amntWeek*1)/(cxz.qtyWeek*1))+(cxz.amntWeekLead*1)); 
    if(isNaN(panierMoyen)){
      panierMoyen=0; 
    }
    //setItemSale80(((cxz.amntWeek*1)/(cxz.qtyWeek*1)).toFixed(2)+"$/"+

    // setItemSale80(panierMoyen.toFixed(2)+"$/"+
    //     ((cxz.weekexpectation.expected_amount*1)/(cxz.weekexpectation.expected_quantity*1)).toFixed(2)+"$");  
    setItemSale80(panierMoyen.toFixed(2)+"$/"+ (cxz.weekexpectation.panier*1).toFixed(2)+"$");     

    setItemSalewlq(cxz.qtyWeekLead);
    if(cxz.week!==null && cxz.week.qtUpsale!==undefined && cxz.weekexpectation.suivi!==undefined){
      setItemSalewlqsuivi(cxz.week.qtUpsale+"/"+cxz.weekexpectation.suivi);  
    }   
    setItemSalewla(cxz.amntWeekLead);  
    setItemSalewtot(((cxz.amntWeek*1)+(cxz.amntWeekLead*1))); 
    
    
    if(cxz.defferredPaid.qtySales!==undefined && cxz.defferredUnPaid.qtySales!==undefined) {
      //const percentpaidUnpaid=((cxz.defferredPaid.qtySales*1*100)/(cxz.defferredUnPaid.qtySales*1));
      //const percentpaidUnpaid=((cxz.defferredUnPaid.qtySales*1*100)/((cxz.defferredPaid.qtySales*1)+(cxz.defferredUnPaid.qtySales*1)));
      //setItemSale83(""+cxz.defferredPaid.qtySales+"/"+cxz.defferredUnPaid.qtySales+" --- "+percentpaidUnpaid.toFixed(2)+"%");

      const percentpaidUnpaid=((cxz.defferredPaid.qtySales*1*100)/((cxz.defferredPaid.qtySales*1)+(cxz.defferredUnPaid.qtySales*1)));
      setItemSale83(""+cxz.defferredPaid.qtySales);
      setItemSale83b(""+cxz.defferredUnPaid.qtySales);
      setItemSale83c(""+percentpaidUnpaid.toFixed(2)+"%");

      const percentpaidUnpaida=((cxz.defferredPaid.totalSales*1*100)/((cxz.defferredPaid.totalSales*1)+(cxz.defferredUnPaid.totalSales*1)));
      setItemSale83a(""+(cxz.defferredPaid.totalSales*1).toFixed(2));
      setItemSale83ba(""+(cxz.defferredUnPaid.totalSales*1).toFixed(2));
      setItemSale83ca(""+percentpaidUnpaida.toFixed(2)+"%");
    }
    
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  
  const handleExternalMenuOpen = (event) => {
    //setMobileMoreAnchorEl(event.currentTarget);
    if(_isMounted){
      //console.log("+++++++ TEMP MENU TOGGLE ");
      props.handleLeftPanelMenuRef(event);
    }
  };
  
  const logoutSystem = (event) => {
    //window.confirm();
	/*if(window.confirm('Are you sure you want to log out the system?')){
		///zonlineusuarios
	}*/
	setUserLogoutOpen(true);
  };
  
  /*
  <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
	  <MenuItem><Link to="./zonlineroles">Profile</Link></MenuItem>
	  <MenuItem><Link to="./zxcompanies">Compañías</Link></MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
	  <MenuItem onClick={logoutSystem}>Logout</MenuItem>
	  <MenuItem><UserLogout openx={userLogoutOpen} /></MenuItem>
  */

 

  return (
    <div className={classes.grow}>
          <div style={{margin:'0 auto', minHeight:'400px'}}>
          
          <span onClick={handleCloseSale} disabled={true}>
              <Chip
              icon={<FaceIcon />}
              // label={`Objectif de Vente hebdomadaire ${saleGoalWeekly}`} 
              label={`${saleGoalWeekly}   . . . ${t("zqwBHElem1_x")}  ${itemSale80}`}
              deleteIcon={<DoneIcon />} 
              color="secondary"
              variant="default" 
            />
            </span><br/>
            <span onClick={handleCloseSale}>{t("zqwBHElem9_x")} {itemSale20}</span><br/>
            <span onClick={handleCloseSale}>{t("zqwBHElem2_x")}  &nbsp; <b>{itemSale30}</b></span><br/>
            <span onClick={handleCloseSale}>{t("zqwBHElem10_x")}  {itemSale40}</span><br/> 
            <span onClick={handleCloseSale}>{t("zqwBHElem3_x")}  &nbsp; <b>{itemSale50}</b></span><br/>
            <span onClick={handleCloseSale}>{t("zqwBHElem11_x")}  {itemSale60}</span><br/> 
            <span onClick={handleCloseSale}>{t("zqwBHElem4_x")}  &nbsp; <b>{itemSale70}</b></span><br/>
            
            <span onClick={handleCloseSale}>#Lead  &nbsp; <b>{itemSalewlq}</b></span><br/>
            <span onClick={handleCloseSale}>Lead   &nbsp; <b>${itemSalewla}</b></span><br/>
            <span onClick={handleCloseSale}>Total Montant  &nbsp; <b>{itemSalewtot}</b></span><br/>
            <span onClick={handleCloseSale}>{t('zqwCViewEl27_x')}  &nbsp; <b>{itemSalewlqsuivi}</b></span><br/>
            {/* <span onClick={handleCloseSale}>{t('zqwBHElem15_x')} &nbsp; <b>{itemSale83}</b></span><br/> */}
            <span onClick={handleCloseSale}>{t('zqwMesas023_x', {sales:itemSale83, pendings:itemSale83b, salePercentage:itemSale83c})} &nbsp; </span><br/>
            <span onClick={handleCloseSale}>{t('zqwMesas024_x', {sales:itemSale83a, pendings:itemSale83ba, salePercentage:itemSale83ca})} &nbsp; </span><br/>






            <span onClick={handleCloseSale} disabled={true}>
              <Chip
              icon={<FaceIcon />}
              label={`${t("zqwBHElem6_x")} ${saleGoalDaily}`} 
              deleteIcon={<DoneIcon />} 
              color="secondary"
              variant="default" 
            />
            </span><br/>
            <span onClick={handleCloseSale}>{t("zqwBHElem5_x")}  {itemSale2}</span><br/>
            <span onClick={handleCloseSale}>{t("zqwBHElem2_x")} &nbsp; <b>{itemSale3}</b></span><br/>
            <span onClick={handleCloseSale}>{t("zqwBHElem10_x")}  {itemSale4}</span><br/> 
            <span onClick={handleCloseSale}>{t("zqwBHElem3_x")}  &nbsp; <b>{itemSale5}</b></span><br/>
            <span onClick={handleCloseSale}>{t("zqwBHElem11_x")}  {itemSale6}</span><br/> 
            <span onClick={handleCloseSale}>{t("zqwBHElem4_x")}  &nbsp; <b>{itemSale7}</b></span><br/>

            <span onClick={handleCloseSale}>#Lead  &nbsp; <b>{itemSaledlq}</b></span><br/>
            <span onClick={handleCloseSale}>Lead   &nbsp; <b>${itemSaledla}</b></span><br/>
            <span onClick={handleCloseSale}>Total Montant  &nbsp; <b>{itemSaledtot}</b></span><br/>
            <span onClick={handleCloseSale}>{t('zqwCViewEl27_x')}  &nbsp; <b>{itemSaledlqsuivi}</b></span><br/>
          
          </div>
      
    </div>
  );

  
}

const mapDispatchToProps = dispatch => ({ 
  getDailyGoalSale: () => dispatch(getDailyGoalSale()) 
});
const mapStateToProps = (state) => ({ NeoSalesPlanningDaily: state.NeoSalesPlanningDaily });  //NeoVentes: state.NeoVentes
//const mapDispatchToProps = {  getDailyGoalSale: dispatch(getDailyGoalSale) };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(BaseHeaderSaleReport));
