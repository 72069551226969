import React from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";

export function NeoVentesListFilterBar({
  data,
  handleChange,
  clear,
  fetchData,
}) {
  function genLang(text) {
    const lang = localStorage.getItem("i18nextLng") ?? "fr";
    try {
      if (typeof text === "object") {
        return text[lang];
      }
    } catch (e) {
      console.error(e);
      console.error("TRAD KEY NOT FOUND: ", text);
    }
  }
  return (
    <Row
      style={{
        textAlign: "left",
        justifyContent: "flex-end",
        alignItems: "end",
      }}
    >
      <Col sm="2">
        <Label className="price-label">
          {genLang({ en: "First name", fr: "Prénom" })}
        </Label>
        <Input
          name="firstname"
          value={data?.firstname}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => {
            if (e.key === "Enter") fetchData();
          }}
        />
      </Col>

      <Col sm="2">
        <Label className="price-label">
          {genLang({ en: "Last name", fr: "Nom" })}
        </Label>
        <Input
          value={data?.lastname}
          name="lastname"
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => {
            if (e.key === "Enter") fetchData();
          }}
        />
      </Col>

      <Col sm="2">
        <Label className="price-label">Email</Label>
        <Input
          name="email"
          value={data?.email}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => {
            if (e.key === "Enter") fetchData();
          }}
        />
      </Col>

      <Col sm="2">
        <Label className="price-label">
          {genLang({ en: "Telephone", fr: "Téléphone" })}
        </Label>
        <Input
          value={data?.cell}
          name="cell"
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => {
            if (e.key === "Enter") fetchData();
          }}
        />
      </Col>

      <Col sm="2">
        <Button
          block
          style={{ height: "35px" }}
          color="primary"
          onClick={fetchData}
        >
          {genLang({ en: "Search", fr: "Recherche" })}
        </Button>
      </Col>

      <Col sm="2">
        <Button
          block
          style={{ height: "35px" }}
          color="primary"
          outline
          onClick={clear}
        >
          {genLang({ en: "Clear", fr: "Vider" })}
        </Button>
      </Col>
    </Row>
  );
}
