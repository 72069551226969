import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

import { encryptText, decryptText } from '../appEncryption';

export const RECEIVE_NDIFIELDS = 'GET_NDIFIELDS';
export const ADD_NDIFIELDS = 'ADD_NDIFIELDS';
export const RECEIVE_NDIFIELDSX = 'RECEIVE_NDIFIELDSX';
export const REMOVE_NDIFIELDS = 'REMOVE_NDIFIELDS';
export const UPDATE_NDIFIELDS = 'UPDATE_NDIFIELDS';
export const REPLACE_NDIFIELDS = 'REPLACE_NDIFIELDS';
const apiUrl = API_DOMAIN + '/ndifields'; 
const apiUrlPField = API_DOMAIN + '/ndiproductfields'; 
const apiUrlBase = API_DOMAIN; 
 
 
axiosDefaults(null);

export const getNdiFieldsAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}`)
      .then(response => {
		  //console.log("getNdiFieldsAll", response.data.data.ndifields);
        return dispatch({type: RECEIVE_NDIFIELDS, NdiFields: response.data.data.ndifields});
      })
      .catch(error => { console.error(error); });
  };
};

export const addOrUpdateTicketData = (TicketData) => { 
	 if(TicketData.isMULTIPART!==undefined){
		 const myAxios={isMULTIPART:1}
		axiosDefaults(myAxios); 
	 }
	return (dispatch) => {
	  return axios.post(`${apiUrlBase}/ticketdata`, TicketData)
		.then(response => {
		  let data = response.data.data; //response.data.data;
		    
		  //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT  ');  
		  //console.log(data);   
		  //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT ---.. '+data.id);  
		  //return dispatch({type: ADD_NEOVENTES, payload: mydataAr });
		  //dispatch({type: ADD_NEOVENTES, payload: { ...mydataAr } });
		  return dispatch({type: ADD_NDIFIELDS, NdiFields: data});
		})
		/*.then(response => {
		  //history.push("/NeoVentes")
		  return response;
		})*/
		//.catch(error => { console.error(error) });
		.catch(err => {
		  if (err.response) {
			// client received an error response (5xx, 4xx)
			console.log("error falls here #1"); console.log(err.response);
			console.log(err);
			console.error(err.response);
		  } else if (err.request) {
			// client never received a response, or request never left
			console.log("error falls here #2");
			const errMessage="client never received a response, or request never left";
			console.error(errMessage);
		  } else {
			// anything else
			console.log("error falls here #3"); console.log(err);   
			console.error(err); 
		  }
	  });
	};
  };

  export const downloadFileTicketData001 = (TicketData) => { 
	// if(TicketData.isMULTIPART!==undefined){
		const myAxios={isBLOB:1}
	    axiosDefaults(myAxios); 
	// }

	/*return axios.post(`${apiUrlBase}/ticketdata`, TicketData, {
		headers: { responseType: 'blob',}
	})*/
	// responseType: 'arraybuffer', dataType:'blob'

   return (dispatch) => {
	 return axios.post(`${apiUrlBase}/ticketdata`, TicketData )
	   .then(response => {
		 let data = response.data; //response.data.data;
		   
		 console.log('0000000 RESPONSE downloadFileTicketData  ');  
		 console.log(response);   
		 console.log(data.data);   
		 //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT ---.. '+data.id);  
		 //return dispatch({type: ADD_NEOVENTES, payload: mydataAr });
		 //dispatch({type: ADD_NEOVENTES, payload: { ...mydataAr } });

		const file = new Blob([(data)], {type: 'application/pdf'});
		const downloadUrl = window.URL.createObjectURL(file); 
		//const downloadUrl = window.URL.createObjectURL(new Blob([data]));
		//const downloadUrl = window.URL.createObjectURL(data)
		const link = document.createElement('a');
		link.href = downloadUrl;
		//link.setAttribute('download', 'file.pdf'); //any other extension
		link.setAttribute('download', TicketData.shortFilename);
		document.body.appendChild(link);
		link.click();
		link.remove();
		 
		 //return dispatch({type: ADD_NDIFIELDS, NdiFields: data});
		 return dispatch({type: ADD_NDIFIELDS, NdiFields: null});
	   })
	   /*.then(response => {
		 //history.push("/NeoVentes")
		 return response;
	   })*/
	   //.catch(error => { console.error(error) });
	   .catch(err => {
		 if (err.response) {
		   // client received an error response (5xx, 4xx)
		   console.log("error falls here #1"); console.log(err.response);
		   console.log(err);
		   console.error(err.response);
		 } else if (err.request) {
		   // client never received a response, or request never left
		   console.log("error falls here #2");
		   const errMessage="client never received a response, or request never left";
		   console.error(errMessage);
		 } else {
		   // anything else
		   console.log("error falls here #3"); console.log(err);   
		   console.error(err); 
		 }
	 });
   };
 }; 
 
 export const downloadFileTicketData = (TicketData) => {
	return dispatch => {
	  const token = decryptText(localStorage.token);
	  ////console.log("###******* TOKEN SAVED "+token);  
	const tokenRid = localStorage.rid; 
	const profile = decryptText(localStorage.profile);
	let user=null;
	if(localStorage.getItem("user")!==undefined  && localStorage.getItem("user")!==null){
			user=(decryptText(localStorage.getItem("user"))).split(':');
	} 
 
	const profileLoc=JSON.parse(profile); 
	//axios.defaults.headers.common['auth-id'] = user[4];
	const userId= user[4];
	 
	//return (dispatch) => {
	  //if (token) {
		return fetch(`${apiUrlBase}/ticketdata`, {    
		  method: "POST",
		  headers: {
			'Content-Type': 'blob',
			//'Content-Type': 'application/json',
			Accept: 'application/json',
			'Authorization': `Bearer ${token}`,
			'auth-id': `${userId}`
		  },
		  body: JSON.stringify(TicketData)
		})
		.then(function(response) {
			if (response.ok) {
				return response.blob().then(function(myBlob) {
					// var objectURL = URL.createObjectURL(myBlob);
					// myImage.src = objectURL;
					const downloadUrl = window.URL.createObjectURL(myBlob);

					// const file = new Blob([(data)], {type: 'application/pdf'});
					// const downloadUrl = window.URL.createObjectURL(file); 
					////const downloadUrl = window.URL.createObjectURL(new Blob([data]));
					////const downloadUrl = window.URL.createObjectURL(data)
					const link = document.createElement('a');
					link.href = downloadUrl;
					//link.setAttribute('download', 'file.pdf'); //any other extension
					link.setAttribute('download', TicketData.shortFilename);
					document.body.appendChild(link);
					link.click();
					link.remove();

					//return dispatch({type: ADD_NDIFIELDS, NdiFields: null});
					return true;
				});
			} else {
				return response.json().then(function(jsonError) {
					window.alert("Sorry, while trying to get the file"); 
					console.error("Sorry, while trying to get the file");
				});
			}
		}).catch(function(error) {
			console.log('There has been a problem with your fetch operation: ', error.message);
			window.alert('There has been a problem with your fetch operation: ', error.message); 
		});
	//};
 
	}
  }


  export const downloadFileInvoiceTicketData = (TicketData) => {
	return dispatch => {
	  const token = decryptText(localStorage.token);
	  ////console.log("###******* TOKEN SAVED "+token);  
	const tokenRid = localStorage.rid; 
	const profile = decryptText(localStorage.profile);
	let user=null;
	if(localStorage.getItem("user")!==undefined  && localStorage.getItem("user")!==null){
			user=(decryptText(localStorage.getItem("user"))).split(':');
	} 
 
	const profileLoc=JSON.parse(profile); 
	//axios.defaults.headers.common['auth-id'] = user[4];
	const userId= user[4];
	const invoiceId=TicketData.invoiceId; 
	//return (dispatch) => {
	  //if (token) {
		return fetch(`${apiUrlBase}/ndi/client/invoice?invoiceId=${invoiceId}&fileaddress=${invoiceId}.pdf`, {    
		  method: "GET",
		  headers: {
			'Content-Type': 'blob',
			//'Content-Type': 'application/json',
			Accept: 'application/json',
			'Authorization': `Bearer ${token}`,
			'auth-id': `${userId}`
		  },
		  //body: JSON.stringify(TicketData)
		})
		.then(function(response) {
			if (response.ok) {
				return response.blob().then(function(myBlob) {
					// var objectURL = URL.createObjectURL(myBlob);
					// myImage.src = objectURL;
					const downloadUrl = window.URL.createObjectURL(myBlob);

					// const file = new Blob([(data)], {type: 'application/pdf'});
					// const downloadUrl = window.URL.createObjectURL(file); 
					////const downloadUrl = window.URL.createObjectURL(new Blob([data]));
					////const downloadUrl = window.URL.createObjectURL(data)
					const link = document.createElement('a');
					link.href = downloadUrl;
					//link.setAttribute('download', 'file.pdf'); //any other extension
					link.setAttribute('download', TicketData.shortFilename);
					document.body.appendChild(link);
					link.click();
					link.remove();

					//return dispatch({type: ADD_NDIFIELDS, NdiFields: null});
					return true;
				});
			} else {
				return response.json().then(function(jsonError) {
					window.alert("Sorry, while trying to get the file"); 
					console.error("Sorry, while trying to get the file");
				});
			}
		}).catch(function(error) {
			console.log('There has been a problem with your fetch operation: ', error.message);
			window.alert('There has been a problem with your fetch operation: ', error.message); 
		});
	//};
 
	}
  }



 export const downloadFileTicketData000 = (TicketData) => { 
	// if(TicketData.isMULTIPART!==undefined){
		const myAxios={isBLOB:1}
	    axiosDefaults(myAxios); 
	// }

	/*return axios.post(`${apiUrlBase}/ticketdata`, TicketData, {
		headers: { responseType: 'blob',}
	})*/
	// responseType: 'arraybuffer', dataType:'blob'

   return (dispatch) => {
	 return axios.post(`${apiUrlBase}/ticketdata`, TicketData )
	   .then(response => {
		 let data = response.data; //response.data.data;
		   
		 console.log('0000000 RESPONSE downloadFileTicketData  ');  
		 console.log(response);   
		 console.log(data.data);   
		 //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT ---.. '+data.id);  
		 //return dispatch({type: ADD_NEOVENTES, payload: mydataAr });
		 //dispatch({type: ADD_NEOVENTES, payload: { ...mydataAr } });

		const file = new Blob([(data)], {type: 'application/pdf'});
		const downloadUrl = window.URL.createObjectURL(file); 
		//const downloadUrl = window.URL.createObjectURL(new Blob([data]));
		//const downloadUrl = window.URL.createObjectURL(data)
		const link = document.createElement('a');
		link.href = downloadUrl;
		//link.setAttribute('download', 'file.pdf'); //any other extension
		link.setAttribute('download', TicketData.shortFilename);
		document.body.appendChild(link);
		link.click();
		link.remove();
		 
		 //return dispatch({type: ADD_NDIFIELDS, NdiFields: data});
		 return dispatch({type: ADD_NDIFIELDS, NdiFields: null});
	   })
	   /*.then(response => {
		 //history.push("/NeoVentes")
		 return response;
	   })*/
	   //.catch(error => { console.error(error) });
	   .catch(err => {
		 if (err.response) {
		   // client received an error response (5xx, 4xx)
		   console.log("error falls here #1"); console.log(err.response);
		   console.log(err);
		   console.error(err.response);
		 } else if (err.request) {
		   // client never received a response, or request never left
		   console.log("error falls here #2");
		   const errMessage="client never received a response, or request never left";
		   console.error(errMessage);
		 } else {
		   // anything else
		   console.log("error falls here #3"); console.log(err);   
		   console.error(err); 
		 }
	 });
   };
 };


 export const downloadFileJudiciaryInvoice = (TicketData) => {
	return dispatch => {
	  const token = decryptText(localStorage.token);
	  ////console.log("###******* TOKEN SAVED "+token);  
	const tokenRid = localStorage.rid; 
	const profile = decryptText(localStorage.profile);
	let user=null;
	if(localStorage.getItem("user")!==undefined  && localStorage.getItem("user")!==null){
			user=(decryptText(localStorage.getItem("user"))).split(':');
	} 
 
	const profileLoc=JSON.parse(profile);  
	const userId= user[4];
	//const invoiceId=TicketData.invoiceId; 
	//return fetch(`${apiUrlBase}/ndi/client/invoice?invoiceId=${invoiceId}&fileaddress=${invoiceId}.pdf`, {    
	//return (dispatch) => {
	  //if (token) {
		return fetch(`${apiUrlBase}/ndi/client/invoice`, {    
		  method: "POST",
		  headers: {
			'Content-Type': 'blob',
			//'Content-Type': 'application/json',
			Accept: 'application/json',
			'Authorization': `Bearer ${token}`,
			'auth-id': `${userId}`
		  },
		  body: JSON.stringify(TicketData)
		})
		.then(function(response) {
			if (response.ok) {
				return response.blob().then(function(myBlob) {
					// var objectURL = URL.createObjectURL(myBlob);
					// myImage.src = objectURL;
					const downloadUrl = window.URL.createObjectURL(myBlob);

					// const file = new Blob([(data)], {type: 'application/pdf'});
					// const downloadUrl = window.URL.createObjectURL(file); 
					////const downloadUrl = window.URL.createObjectURL(new Blob([data]));
					////const downloadUrl = window.URL.createObjectURL(data)
					const link = document.createElement('a');
					link.href = downloadUrl;
					//link.setAttribute('download', 'file.pdf'); //any other extension
					link.setAttribute('download', TicketData.shortFilename);
					//link.setAttribute('download', 'JUDICIARYiNVfile.pdf');
					document.body.appendChild(link);
					link.click();
					link.remove();

					//return dispatch({type: ADD_NDIFIELDS, NdiFields: null});
					return true;
				});
			} else {
				return response.json().then(function(jsonError) {
					window.alert("Sorry, while trying to get the file"); 
					console.error("Sorry, while trying to get the file");
				});
			}
		}).catch(function(error) {
			console.log('There has been a problem with your fetch operation: ', error.message);
			window.alert('There has been a problem with your fetch operation: ', error.message); 
		});
	//};
 
	}
  }

 
 
export const addFoldersProducts = (foldersProductsData) => { 
	// if(TicketData.isMULTIPART!==undefined){
	// 	const myAxios={isMULTIPART:1}
	//    axiosDefaults(myAxios); 
	// }
   return (dispatch) => {
	 return axios.post(`${apiUrlBase}/ndifoldersproducts`, foldersProductsData)
	   .then(response => {
		 let data = response.data.data; //response.data.data;
		   
		 console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT  ');  
		 console.log(data);   
		 //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT ---.. '+data.id);  
		 //return dispatch({type: ADD_NEOVENTES, payload: mydataAr });
		 //dispatch({type: ADD_NEOVENTES, payload: { ...mydataAr } });
		 return dispatch({type: ADD_NDIFIELDS, NdiFields: data});
	   })
	   /*.then(response => {
		 //history.push("/NeoVentes")
		 return response;
	   })*/
	   //.catch(error => { console.error(error) });
	   .catch(err => {
		 if (err.response) {
		   // client received an error response (5xx, 4xx)
		   console.log("error falls here #1"); console.log(err.response);
		   console.log(err);
		   console.error(err.response);
		 } else if (err.request) {
		   // client never received a response, or request never left
		   console.log("error falls here #2");
		   const errMessage="client never received a response, or request never left";
		   console.error(errMessage);
		 } else {
		   // anything else
		   console.log("error falls here #3"); console.log(err);   
		   console.error(err); 
		 }
	 });
   };
 };


export const addNdiFields = (NdiFields) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}`, NdiFields)
      .then(response => {
        let data = response.data;
         
		return dispatch({type: ADD_NDIFIELDS, NdiFields: data});
      })
      .then(() => {
        history.push("/NdiFields")
      })
      .catch(error => { console.error(error) });
  };
};



export const getNdiFields = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}?id=${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.data);		  
        return dispatch({type: RECEIVE_NDIFIELDSX, NdiFieldsOne: response.data.data}); //.ndifields
      })
      .catch(error => { 
        console.error(error); 
      });
  };
};

export const getProductFields = (obj) => {
	//const objToSend={id:obj.id, ticketId:obj.ticketId};
	const id=obj.id; const ticketId=obj.ticketId; let lang=""; let zendsk=""; 
	if(obj.lang!==undefined){ lang=obj.lang; }else{
		lang=(localStorage.i18nextLng!==undefined)?localStorage.i18nextLng:"fr";
		//lang="fr";  if(myLang.indexOf("fr")< 0){ lang="fr"; }else{ lang="en"; }
	}
	if(obj.zendeskapi!==undefined){ zendsk="&zendeskapi="+obj.zendeskapi; }
	 
	 console.log("BEFORE response from server .... "+id, lang); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrlPField}?id=${id}&ticketId=${ticketId}&lang=${lang}${zendsk}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data);		  
        return dispatch({type: RECEIVE_NDIFIELDSX, NdiFieldsOne: response.data});
      })
      .catch(error => { 
        console.error(error); 
      });
  };
};

 
export const getSearchConflict = (obj) => {
	//const objToSend={id:obj.id, ticketId:obj.ticketId};
	const id=obj.id; const ticketId=obj.ticketId; 
	let lang=""; let zendsk=""; let search=""; let byfield=""; 
	// if(obj.lang!==undefined){ lang=obj.lang; }else{
	// 	lang=(localStorage.i18nextLng!==undefined)?localStorage.i18nextLng:"fr";
	// 	//lang="fr";  if(myLang.indexOf("fr")< 0){ lang="fr"; }else{ lang="en"; }
	// }
	// if(obj.zendeskapi!==undefined){ zendsk="&zendeskapi="+obj.zendeskapi; }
	if(obj.search!==undefined){ search=obj.search; }
	if(obj.search!==undefined){ search=obj.search; }
	 
	 console.log("BEFORE response from server .... "+id, lang); 
	 //return axios.get(`${apiUrl}/${id}`)
	 //return axios.get(`${apiUrlPField}?id=${id}&ticketId=${ticketId}&lang=${lang}${zendsk}`)

  return (dispatch) => {
    return axios.get(`${apiUrlBase}/clients/searchconflicts?search=${search}&byfield=${byfield}`)
      .then(response => {
		  //console.log("response getSearchConflict from server .... ");  console.log(response.data);		  
        return dispatch({type: RECEIVE_NDIFIELDSX, NdiFieldsOne: response.data});
      })
      .catch(error => { 
        console.error(error); 
      });
  };
};

 
export const getTicketSummary = (obj) => {
	//const objToSend={id:obj.id, ticketId:obj.ticketId};
	const id=obj.id; const ticketId=obj.ticketId; let lang="";  let xtraParams="";
	if(obj.lang!==undefined){ lang=obj.lang; }else{
		lang=(localStorage.i18nextLng!==undefined)?localStorage.i18nextLng:"fr";
		//lang="fr";  if(myLang.indexOf("fr")< 0){ lang="fr"; }else{ lang="en"; }
	}
	if(obj.neoid!==undefined){ xtraParams +="&neoid="+obj.neoid; }
	if(obj.email!==undefined){ xtraParams +="&neoemail="+obj.email; }
	 console.log("BEFORE response from server .... "+id, lang); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrlPField}/ticketsummary?id=${id}&ticketId=${ticketId}&lang=${lang}${xtraParams}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data);		  
        return dispatch({type: RECEIVE_NDIFIELDSX, NdiFieldsOne: response.data});
      })
      .catch(error => { 
        console.error(error); 
      });
  };
};

//sendTicketLead 
export const sendTicketLead = (obj) => {
	//const objToSend={id:obj.id, ticketId:obj.ticketId};
	const id=obj.id; const ticketId=obj.ticketId; let lang="";  
	const idb2bclient=obj.idb2bclient; const id_clients=obj.id_clients;
	const email=obj.email;  const tel=obj.tel; 
	if(obj.lang!==undefined){ lang=obj.lang; }else{
		lang=(localStorage.i18nextLng!==undefined)?localStorage.i18nextLng:"fr";
		//lang="fr";  if(myLang.indexOf("fr")< 0){ lang="fr"; }else{ lang="en"; }
	}
	 console.log("BEFORE response from server .... "+id, lang); 
	 //return axios.get(`${apiUrl}/${id}`) 
	 //const elig={id:0, ticketId:"", idb2bclient:b2b.id,id_clients:b2b.id_clients, };
	//return axios.get(`${apiUrlPField}/ticketsummary?id=${id}&ticketId=${ticketId}&lang=${lang}&ticketLead=1`) 
	return (dispatch) => {
		return axios.get(`${apiUrlPField}/ticketsummary?idb2bclient=${idb2bclient}&id_clients=${id_clients}&lang=${lang}&ticketLead=1&tel=${tel}&email=${email}`)
		.then(response => {
			console.log("sendTicketLead-Response from server .... ");  console.log(response.data);		  
			return dispatch({type: RECEIVE_NDIFIELDSX, NdiFieldsOne: response.data});
		})
		.catch(error => { 
			console.error(error); 
		});
	};
};
 
export const getClientTransactions = (obj) => {
	const id=obj.clientId;
	return (dispatch) => {
	  return axios.get(`${apiUrlBase}/ndiclientransactions?client_id=${id}`)
		.then(response => {
			//console.log("getClientTransactions", response); //response.data.data.ndifields
		  return dispatch({type: RECEIVE_NDIFIELDS, NdiFields: response.data.data});
		})
		.catch(error => { console.error(error); });
	};
  };

export const getNdiFieldsGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NdiFields/${id}/edit`)
  };
};
export const deleteNdiFields = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NDIFIELDS, payload: {id}})
      })
      .then(() => {
        history.push("/NdiFields")
      })
      .catch(error => {
        console.error(error);
      });
  };
};
export const updateNdiFields = (NdiFields) => {
  const id = NdiFields.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NdiFields.id}`, {
		name: NdiFields.name,
		text_label: NdiFields.text_label,
		placeholder: NdiFields.placeholder,
		field: NdiFields.field,
		field_type: NdiFields.field_type,
		field_default_value: NdiFields.field_default_value,
		mask: NdiFields.mask,
		field_service: NdiFields.field_service,
		field_dataset: NdiFields.field_dataset,
		tag_id: NdiFields.tag_id,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NDIFIELDS, payload: {
			id: data.id,
			name: data.name,
			text_label: data.text_label,
			placeholder: data.placeholder,
			field: data.field,
			field_type: data.field_type,
			field_default_value: data.field_default_value,
			mask: data.mask,
			field_service: data.field_service,
			field_dataset: data.field_dataset,
			tag_id: data.tag_id,
		}})
        dispatch({type: REPLACE_NDIFIELDS, payload: {
			id: data.id,
			name: data.name,
			text_label: data.text_label,
			placeholder: data.placeholder,
			field: data.field,
			field_type: data.field_type,
			field_default_value: data.field_default_value,
			mask: data.mask,
			field_service: data.field_service,
			field_dataset: data.field_dataset,
			tag_id: data.tag_id,
		}})
      })
      .then(() => {
        history.push(`/NdiFields/${id}`)
      })
      .catch(error => { console.error(error) });
  };
};
export const addOrUpdateNdiFields = (NdiFields, actionMode) => {
	if(actionMode===1){
        return addNdiFields(NdiFields);
      }else {
        return updateNdiFields(NdiFields);
      }
};