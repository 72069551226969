import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Label,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import "./MergeTickets.css";
import Notes from "../inputComponents/sales/component/Notes/Notes";
import axios from "axios";
import { genLang } from "../../../composables/genLang";

const URL = process.env.REACT_APP_API_URLX;

const mergeTicketsPost = (action, fromTicketId, toTicketId) => {
  return axios.post(
    URL + "/mergeTickets",
    {
      auth_id: localStorage.getItem("user_id"),
      fromTicketId,
      toTicketId,
      action,
    },
    {
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("admin_token"),
      },
    }
  );
};

function MergeTicketsPostSale({ folders = [], fetchData }) {
  const [fromTicketId, setFromTicketId] = useState("");
  const [toTicketId, setToTicketId] = useState("");
  const [fromTicketNotes, setFromTicketNotes] = useState([]);
  const [toTicketNotes, setToTicketNotes] = useState([]);
  const [postLoading, setPostLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (toTicketId && fromTicketId) {
      mergeTicketsPost("get", fromTicketId, toTicketId)
        .then(({ data }) => {
          if (data.statusCode === 200) {
            setFromTicketNotes(data.data.from);
            setToTicketNotes(data.data.to);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [toTicketId, fromTicketId]);

  const handleSubmit = () => {
    setPostLoading(true);
    setShowModal(false);
    mergeTicketsPost("save", fromTicketId, toTicketId)
      .then(({ data }) => {
        if (data.statusCode === 200) {
          setPostLoading(false);
          fetchData();
          setOpen(false);
          toast.success(genLang({ en: "Success", fr: "Succès" }));
        }
      })
      .catch((e) => {
        setPostLoading(false);
        toast.error(genLang({ en: "Error", fr: "Erreur" }));
        console.error(e);
      });
  };

  const folderOptions = folders.map((folder) => ({
    label: `${folder.ta} ${folder.description}`,
    value: folder.ta,
  }));

  return (
    <>
      <Button onClick={() => setOpen(true)} color="primary">
        {genLang({ fr: "Fusionner les dossiers", en: "Merge folders" })}
      </Button>
      <Modal isOpen={open} centered size="lg">
        <ModalHeader toggle={() => setOpen(false)}>
          {genLang({ fr: "Fusionner les dossiers", en: "Merge folders" })}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6}>
              <Label style={{ fontWeight: "bold" }} className="notes-label">
                {genLang({ en: "Source Folder", fr: "Dossier source" })}
              </Label>
              <Select
                isDisabled={!folderOptions.length}
                isClearable
                value={
                  folderOptions?.find((i) => i.value === fromTicketId) || ""
                }
                onChange={(e) => {
                  if (!e) {
                    setFromTicketId("");
                    setFromTicketNotes([]);
                  } else setFromTicketId(e.value);
                }}
                options={folderOptions.filter((i) => i.value !== toTicketId)}
              />
              {fromTicketNotes.length ? (
                <>
                  <h6
                    style={{ marginTop: "1rem" }}
                  >{`Notes du dossier ${fromTicketId}`}</h6>
                  <div className="merge-tickets-notes-container scrollbar-visible">
                    <Notes notes={fromTicketNotes} />
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>

            <Col sm={6}>
              <Label style={{ fontWeight: "bold" }} className="notes-label">
                {genLang({
                  en: "Destination Folder",
                  fr: "Dossier de destination",
                })}
              </Label>
              <Select
                isDisabled={!folderOptions.length}
                isClearable
                value={folderOptions?.find((i) => i.value === toTicketId) || ""}
                onChange={(e) => {
                  if (!e) {
                    setToTicketId("");
                    setToTicketNotes([]);
                  } else setToTicketId(e.value);
                }}
                options={folderOptions.filter((i) => i.value !== fromTicketId)}
              />

              {toTicketNotes.length ? (
                <>
                  <h6
                    style={{ marginTop: "1rem" }}
                  >{`Notes du dossier ${toTicketId}`}</h6>
                  <div className="merge-tickets-notes-container scrollbar-visible">
                    <Notes notes={toTicketNotes} />
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "auto" }}>
            <Col>
              <Button
                color="primary"
                block
                onClick={() => setShowModal(true)}
                disabled={!toTicketId || !fromTicketId}
                style={{ marginTop: "1rem" }}
              >
                {genLang({ en: "Merge", fr: "Fusionner" })}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <MergeTicketsConfirmationModal
        isOpen={showModal}
        handleSubmit={handleSubmit}
        toggle={() => setShowModal(false)}
        postLoading={postLoading}
        message={genLang({
          en: `Merge ${fromTicketId} into ${toTicketId}`,
          fr: `Fusionner ${fromTicketId} dans ${toTicketId}`,
        })}
      />
    </>
  );
}

function MergeTicketsConfirmationModal({
  handleSubmit,
  isOpen,
  toggle,
  postLoading,
  message,
}) {
  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader toggle={toggle}>
        {genLang({ en: "Confirm", fr: "Confirmer" })}
      </ModalHeader>
      <ModalBody>
        <b>{message}</b>
        <div style={{ fontWeight: "600", color: "red" }}>
          {genLang({
            en: "This action is irreversible",
            fr: "Cette action est irréversible",
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" block onClick={handleSubmit}>
          {postLoading ? (
            <Spinner size="sm" />
          ) : (
            genLang({ en: "Confirm", fr: "Confirmer" })
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default withRouter(MergeTicketsPostSale);
