import React from "react";

import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    NavLink,
    ButtonGroup
    // Table
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faCoins,
    faTachometerAlt,
    faTags,
    faFilter,
    faGlobeAmericas,
    faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import DashboardElement from '../../../../utils/DashboardElement';

const profile = require('../thonsid.png');
const pinguLink = "url('https://www.goto.com/-/media/images/url/connect/call-center-software/two-col-with-image-1.jpg?h=836&w=1110&la=en&hash=980A1009FFCB326D74402705C5812A2C')";
const samantha = 'url("https://www.w3schools.com/w3images/avatar6.png")'
const samanthaName = 'Samantha Laroche'
const name = "Mike le vendeur"||'Le Très hon. S. Benachenhou'
const local = !!window.location.host.match(/localhost/)
export default class UserIcon extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return (
            <div className="userIcon">
            <div className='userTitle'>{local?name:samanthaName}</div>
            <div className='profile' style={{
                backgroundSize:local?'120%':'110%',
                backgroundImage:local?pinguLink:samantha,
                backgroundPositionX:local?'0px':'0'
            }} />
            {/* <img src={profile} /> */}
{/* {profile} */}
        </div>
        )
    }
}