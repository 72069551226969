import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NEOPROSPECTSSMS = 'GET_NEOPROSPECTSSMS';
export const ADD_NEOPROSPECTSSMS = 'ADD_NEOPROSPECTSSMS';
export const RECEIVE_NEOPROSPECTSSMSX = 'RECEIVE_NEOPROSPECTSSMSX';
export const REMOVE_NEOPROSPECTSSMS = 'REMOVE_NEOPROSPECTSSMS';
export const UPDATE_NEOPROSPECTSSMS = 'UPDATE_NEOPROSPECTSSMS';
export const REPLACE_NEOPROSPECTSSMS = 'REPLACE_NEOPROSPECTSSMS';
const apiUrl = API_DOMAIN + '/prospectssms'; 

axiosDefaults(null);

export const getNeoProspectsSmsAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}`)
      .then(response => {
        dispatch({type: RECEIVE_NEOPROSPECTSSMS, NeoProspectsSms: response.data.data.neoprospectssms})
      })
      .catch(error => { console.error(error); });
  };
};

export const addNeoProspectsSms = (NeoProspectsSms) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NeoProspectsSms.id,
		message: NeoProspectsSms.message,
		ticket_id: NeoProspectsSms.ticket_id,
		client_id: NeoProspectsSms.client_id,
		email: NeoProspectsSms.email,
		user_creation: NeoProspectsSms.user_creation,
		user_update: NeoProspectsSms.user_update,
		creation_date: NeoProspectsSms.creation_date,
		update_date: NeoProspectsSms.update_date,
		status: NeoProspectsSms.status,
		cell: NeoProspectsSms.cell,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NEOPROSPECTSSMS, payload: {
			id: data.id,
			message: data.message,
			ticket_id: data.ticket_id,
			client_id: data.client_id,
			email: data.email,
			user_creation: data.user_creation,
			user_update: data.user_update,
			creation_date: data.creation_date,
			update_date: data.update_date,
			status: data.status,
			cell: data.cell,
		}})
      })
      .then(() => {
        history.push("/NeoProspectsSms")
      })
      .catch(error => { console.error(error) });
  };
};
export const getNeoProspectsSms = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.neoprospectssms);		  
        return dispatch({type: RECEIVE_NEOPROSPECTSSMSX, NeoProspectsSmsOne: response.data.neoprospectssms});
      })
      .catch(error => { 
        console.error(error); 
      });
  };
};
export const getNeoProspectsSmsGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NeoProspectsSms/${id}/edit`)
  };
};
export const deleteNeoProspectsSms = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NEOPROSPECTSSMS, payload: {id}})
      })
      .then(() => {
        history.push("/NeoProspectsSms")
      })
      .catch(error => {
        console.error(error);
      });
  };
};
export const updateNeoProspectsSms = (NeoProspectsSms) => {
  const id = NeoProspectsSms.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NeoProspectsSms.id}`, {
		message: NeoProspectsSms.message,
		ticket_id: NeoProspectsSms.ticket_id,
		client_id: NeoProspectsSms.client_id,
		email: NeoProspectsSms.email,
		user_creation: NeoProspectsSms.user_creation,
		user_update: NeoProspectsSms.user_update,
		creation_date: NeoProspectsSms.creation_date,
		update_date: NeoProspectsSms.update_date,
		status: NeoProspectsSms.status,
		cell: NeoProspectsSms.cell,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NEOPROSPECTSSMS, payload: {
			id: data.id,
			message: data.message,
			ticket_id: data.ticket_id,
			client_id: data.client_id,
			email: data.email,
			user_creation: data.user_creation,
			user_update: data.user_update,
			creation_date: data.creation_date,
			update_date: data.update_date,
			status: data.status,
			cell: data.cell,
		}})
        dispatch({type: REPLACE_NEOPROSPECTSSMS, payload: {
			id: data.id,
			message: data.message,
			ticket_id: data.ticket_id,
			client_id: data.client_id,
			email: data.email,
			user_creation: data.user_creation,
			user_update: data.user_update,
			creation_date: data.creation_date,
			update_date: data.update_date,
			status: data.status,
			cell: data.cell,
		}})
      })
      .then(() => {
        history.push(`/NeoProspectsSms/${id}`)
      })
      .catch(error => { console.error(error) });
  };
};
export const addOrUpdateNeoProspectsSms = (NeoProspectsSms, actionMode) => {
	if(actionMode===1){
        return addNeoProspectsSms(NeoProspectsSms);
      }else {
        return updateNeoProspectsSms(NeoProspectsSms);
      }
};