import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHashtag,
  faClock,
  faCheck,
  faUser,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ConfirmPopover from "../../../ConfirmPopover/ConfirmPopover";
import { genLang } from "../../../../../composables/genLang";
import IconButton from "../../../IconButton/IconButton";

const URL = process.env.REACT_APP_API_URLX;

export default function Tasks({}) {
  const [taken, setTaken] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    refreshTaskList();
    const intervalId = setInterval(() => {
      refreshTaskList();
    }, 90000);

    return () => clearInterval(intervalId);
  }, []);

  const handleDelete = (eventId) => {
    // setLoading(true);
    axios
      .post(
        `${URL}/saas/calendar/appointment/remove`,
        { id: eventId, auth_id: localStorage.getItem("user_id") },
        {
          params: {},
          headers: {
            Authorization: "Bearer " + localStorage.getItem("admin_token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const newData = taken.filter((i) => i.id !== eventId);
        setTaken(newData);
      })
      .catch((e) => console.error(e));
    // .finally(() => setLoading(false));
  };

  const refreshTaskList = () => {
    const start = moment().format();
    const end = moment().endOf("day").format();
    axios
      .post(
        `${URL}/saas/calendar/appointments`,
        {
          auth_id: localStorage.getItem("user_id"),
          user: localStorage.getItem("user_id"),
          start,
          end,
        },
        {
          params: {},
          headers: {
            Authorization: "Bearer " + localStorage.getItem("admin_token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((status) => {
        let { data } = status.data;
        const now = new Date();
        const sortedAppointments =
          data.takens
            .sort((a, b) => {
              const startA = new Date(a.start);
              const startB = new Date(b.start);
              return startA - startB;
            })
            .map((i) => ({ ...i, showDelete: false, id: i?._id.$oid })) ?? [];
        setTaken(sortedAppointments);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        <Spinner style={{ color: "#0076CF" }} />
      </div>
    );
  }

  if (!loading && !taken.length) {
    return (
      <div style={{ fontStyle: "italic", fontWeight: "600" }}>
        {genLang({ en: "No tasks to display", fr: "Aucune tâche à afficher" })}
      </div>
    );
  }

  return (
    <div>
      <ul
        style={{
          listStyle: "none",
          paddingInlineStart: "0",
        }}
      >
        {taken.map((i) => (
          <TaskItem handleDelete={() => handleDelete(i.id)} {...i} />
        ))}
      </ul>
    </div>
  );
}

function TaskItem({ id, start, ticket_id, handleDelete, clients }) {
  const [showDelete, setShowDelete] = useState(false);
  const parsedTicketId = ticket_id.includes("LD")
  ? ticket_id.substring(2)
  : ticket_id;
  const timezone = moment.tz.guess();
  return (
    <div
      key={id}
      style={{ display: "flex", alignItems: "center" }}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
    >
      <a
        style={{ textDecoration: "none" }}
        key={id}
        // href={`/leads/0/${ticket_id}/${id}`}
        href={`/leads/0/${parsedTicketId}/${id}`}
      >
        <span>
          <FontAwesomeIcon icon={faClock} />{" "}
          <span style={{ marginRight: "1rem" }}>
            <b>{moment(start).tz(timezone).format("HH:mm")}</b>
          </span>
          <FontAwesomeIcon icon={faHashtag} />{" "}
          <span style={{ marginRight: "1rem" }}>
            <b>{ticket_id}</b>
          </span>
          {clients?.length && (
            <span style={{ marginRight: "0.5rem" }}>
              <FontAwesomeIcon icon={faUser} size="sm" />{" "}
              <b>
                {clients
                  ?.map((i) => `${i?.firstname ?? ""} ${i?.lastname ?? ""}`)
                  .join(", ")}
              </b>
            </span>
          )}
        </span>
      </a>
      <ConfirmPopover
        icon={faCheck}
        style={{
          color: "green",
          display: showDelete ? "" : "none",
        }}
        tooltip={genLang({
          en: "Remove appointment",
          fr: "Enleve ce rappel",
        })}
        message={genLang({
          en: "Remove appointment from tasks?",
          fr: "Enleve ce rappel de vos tâches?",
        })}
        buttonText={genLang({ en: "Confirm", fr: "Confirmer" })}
        onSubmit={handleDelete}
      />
    </div>
  );
}

export function CalendarIconButton({}) {
  const calendarBaseURL =
    process.env.REACT_APP_ENV === "production"
      ? "https://neodoc.app"
      : "https://stg.neodoc.app";
  const URL = axios.getUri({
    url: `${calendarBaseURL}/login-psj/${localStorage.getItem("admin_token")}`,
    params: {
      redirect: "/calendar/schedule",
    },
  });
  return (
    <IconButton
      icon={faCalendar}
      onClick={() => window.open(URL)}
      tooltip={genLang({
        en: "Open calendar in new tab",
        fr: "Ouvrir le calendrier dans un nouvel onglet",
      })}
      style={{ color: "#0076cf" }}
      size="sm"
    />
  );
}
