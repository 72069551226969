import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Notes from "./Notes/Notes";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Button,
} from "reactstrap";
import moment from "moment";

class LeadHistoryContact extends React.Component {
  constructor(props) {
    super(props);
  }

  genLang = (text) => {
    const { trad, lang } = this.props;
    return trad["neoleads"][text][lang];
  };

  render() {
    console.log(this.props);
    const { notes, close, isOpen, genLang } = this.props;
    return (
      <>
        <Modal centered={true} isOpen={isOpen}>
          <ModalHeader toggle={close}>
            {genLang("leadHistoryNotes")}
          </ModalHeader>
          <ModalBody style={{ maxHeight: "500px", overflowY: "scroll" }}>
            {/* <h6>{this.state.info.id ? `Lead #${this.state.info.id}` : ""}</h6>
            <h6><b style={{ color: "#0052bb", fontSize: '15px'}}>{this.state.info.lead_subject ? `${this.state.info.lead_subject}` : ""}</b></h6> */}
            {notes.length ? 
            <Notes notes={notes}/>
            : (
              <div style={{ color: "#0052bb" }}>
                {`${genLang("downloading")}... `}
                <Spinner style={{}} />
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={close} outline color="danger">
              {genLang("close")}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default LeadHistoryContact;
