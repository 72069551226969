import React from "react";
import { Row, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import Note from "./Note";

export default class Notes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      previewUrl: "",
    };
  }
  linkify = (text) => {
    if (text === undefined || text === null) {
      return "";
    }
    // didn't work on iOs
    var urlRegex =
      /(?<!=|"|" |' |')(<?)((http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/?[a-zA-Z0-9\-\.\_\?\=\&\/]*))(\>?)/gm;
    // const urlRegex =
    //   /([^=!'"]|^)(\<)?((http|https):\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/?[a-zA-Z0-9\-\.\_\?\=\&\/#]*))(\>?)/gm;
    return text.replace(urlRegex, function (url, a, b) {
      return (
        '<a href="' +
        b +
        '" target="_blank">' +
        " Open Link/Ouvrir Lien" +
        "</a>"
      );
    });
  };

  setPreviewUrl = (url) => {
    this.setState({ previewUrl: url });
  };

  clearPreviewUrl = () => {
    this.setState({ previewUrl: "" });
  };
  render() {
    const notes = this.props?.notes || [];
    return (
      <Row>
        <Col md="12">
          {notes.map((i) => {
            const note = this.linkify(i.note);
            return (
              <Note
                note={note}
                noteObj={i}
                id={i.id}
                user={i.user}
                date={i.date_created}
                setPreviewUrl={this.setPreviewUrl}
              />
            );
          })}
        </Col>
        <FileModal
          // modalData={modalData}
          closeModal={this.clearPreviewUrl}
          url={this.state.previewUrl}
        />
      </Row>
    );
  }
}

function FileModal({ modalData, closeModal, url }) {
  // console.log(modalData);
  return (
    <Modal
      // size="lg"
      isOpen={url !== ""}
      // style={{ width: "100%", height: "600px" }}
      centered
    >
      <ModalHeader toggle={closeModal}>{"Aperçu"}</ModalHeader>
      <ModalBody style={{height: "85vh"}}>
        {
          <iframe
            key={url}
            src={url}
            loading="lazy"
            frameBorder="0"
            scrolling="auto"
            height="100%"
            width="100%"
          ></iframe>
        }
      </ModalBody>
    </Modal>
  );
}
