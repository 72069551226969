import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_ZENDMACROS = 'GET_ZENDMACROS';
export const ADD_ZENDMACROS = 'ADD_ZENDMACROS';
export const RECEIVE_ZENDMACROSX = 'RECEIVE_ZENDMACROSX';
export const REMOVE_ZENDMACROS = 'REMOVE_ZENDMACROS';
export const UPDATE_ZENDMACROS = 'UPDATE_ZENDMACROS';
export const REPLACE_ZENDMACROS = 'REPLACE_ZENDMACROS';
// const apiUrl = API_DOMAIN + '/zendmacros'; 
const apiUrl = API_DOMAIN; 

axiosDefaults(null);

export const getZendMacrosAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/zendmacros/macros/list`)
      .then(response => {
        // dispatch({type: RECEIVE_ZENDMACROS, ZendMacros: response.data.zendmacros.data}) 
        return dispatch({type: RECEIVE_ZENDMACROS, ZendMacros: response.data.data.zendmacros});
      })
      .catch(error => { console.error(error); });
  };
};

export const addZendMacros = (ZendMacros) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: ZendMacros.id,
		name: ZendMacros.name,
		data_raw: ZendMacros.data_raw,
		status: ZendMacros.status,
		last_update: ZendMacros.last_update,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_ZENDMACROS, payload: {
			id: data.id,
			name: data.name,
			data_raw: data.data_raw,
			status: data.status,
			last_update: data.last_update,
		}})
      })
      .then(() => {
        history.push("/ZendMacros")
      })
      .catch(error => { console.error(error) });
  };
};
export const getZendMacros = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.zendmacros);		  
        return dispatch({type: ADD_ZENDMACROS, ZendMacrosOne: response.data.zendmacros});
      })
      .catch(error => { 
        console.error(error); 
      });
  };
};

export const sendZendMacros = (ZendMacros) => { 
  const auth_id=38;
  //return axios.post(`${apiUrl}/sales?auth_id=${auth_id}`, ZendMacros)
  return (dispatch) => {
    return axios.post(`${apiUrl}/zendmacros/macros/sender`, ZendMacros)
      .then(response => {
        let data = response.data.data;
        
        console.log('0000000 RESPONSE MACROOOOOS  ');  
        console.log(response);   
        //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT ---.. '+data.id);  
        //return dispatch({type: ADD_NEOVENTES, payload: mydataAr });
        //dispatch({type: ADD_NEOVENTES, payload: { ...mydataAr } });
        //return dispatch({type: ADD_NEOVENTES, NeoVentes: data})
        return dispatch({type: ADD_ZENDMACROS, ZendMacrosOne: response.data});
      })
      .then(response => {
        //history.push("/NeoVentes")
        return response;
      })
      //.catch(error => { console.error(error) });
      .catch(err => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("error falls here #1"); console.log(err.response);
          console.log(err);
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage="client never received a response, or request never left";
          console.error(errMessage);
        } else {
          // anything else
          console.log("error falls here #3"); console.log(err);   
          console.error(err); 
        }
    });
  };
};

 
export const sendZendNoteMerge = (ZendMacros) => { 
  const auth_id=38;
  //return axios.post(`${apiUrl}/sales?auth_id=${auth_id}`, ZendMacros)
  return (dispatch) => {
    return axios.post(`${apiUrl}/zendmacros/macros/notemerge`, ZendMacros)
      .then(response => {
        let data = response.data.data;
        
        console.log('0000000 RESPONSE MACROOOOOS  ');  
        console.log(response);   
        //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT ---.. '+data.id);  
        //return dispatch({type: ADD_NEOVENTES, payload: mydataAr });
        //dispatch({type: ADD_NEOVENTES, payload: { ...mydataAr } });
        //return dispatch({type: ADD_NEOVENTES, NeoVentes: data})
        return dispatch({type: ADD_ZENDMACROS, ZendMacrosOne: response.data});
      })
      .then(response => {
        //history.push("/NeoVentes")
        return response;
      })
      //.catch(error => { console.error(error) });
      .catch(err => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("error falls here #1"); console.log(err.response);
          console.log(err);
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage="client never received a response, or request never left";
          console.error(errMessage);
        } else {
          // anything else
          console.log("error falls here #3"); console.log(err);   
          console.error(err); 
        }
    });
  };
};


export const getZendMacrosGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/ZendMacros/${id}/edit`)
  };
};
export const deleteZendMacros = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_ZENDMACROS, payload: {id}})
      })
      .then(() => {
        history.push("/ZendMacros")
      })
      .catch(error => {
        console.error(error);
      });
  };
};
export const updateZendMacros = (ZendMacros) => {
  const id = ZendMacros.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${ZendMacros.id}`, {
		id: ZendMacros.id,
		name: ZendMacros.name,
		data_raw: ZendMacros.data_raw,
		status: ZendMacros.status,
		last_update: ZendMacros.last_update,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_ZENDMACROS, payload: {
			id: data.id,
			name: data.name,
			data_raw: data.data_raw,
			status: data.status,
			last_update: data.last_update,
		}})
        dispatch({type: REPLACE_ZENDMACROS, payload: {
			id: data.id,
			name: data.name,
			data_raw: data.data_raw,
			status: data.status,
			last_update: data.last_update,
		}})
      })
      .then(() => {
        history.push(`/ZendMacros/${id}`)
      })
      .catch(error => { console.error(error) });
  };
};
export const addOrUpdateZendMacros = (ZendMacros, actionMode) => {
	if(actionMode===1){
        return addZendMacros(ZendMacros);
      }else {
        return updateZendMacros(ZendMacros);
      }
};