import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_ZXTEAMS = 'GET_ZXTEAMS';
export const ADD_ZXTEAMS = 'ADD_ZXTEAMS';
export const RECEIVE_ZXTEAMSX = 'RECEIVE_ZXTEAMSX';
export const REMOVE_ZXTEAMS = 'REMOVE_ZXTEAMS';
export const UPDATE_ZXTEAMS = 'UPDATE_ZXTEAMS';
export const REPLACE_ZXTEAMS = 'REPLACE_ZXTEAMS';
const apiUrl = API_DOMAIN + '/zxteams'; 

axiosDefaults(null);

export const getZxTeamsAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/`)
      .then(response => {
        dispatch({type: RECEIVE_ZXTEAMS, ZxTeams: response.data.zxteams.data})
      })
      .catch(error => { console.error(error); });
  };
};

export const addZxTeams = (ZxTeams) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: ZxTeams.id,
		team_name: ZxTeams.team_name,
		enable: ZxTeams.enable,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_ZXTEAMS, payload: {
			id: data.id,
			team_name: data.team_name,
			enable: data.enable,
		}})
      })
      .then(() => {
        history.push("/ZxTeams")
      })
      .catch(error => { console.error(error) });
  };
};
export const getZxTeams = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.zxteams);		  
        return dispatch({type: RECEIVE_ZXTEAMSX, ZxTeamsOne: response.data.zxteams});
      })
      .catch(error => { 
        console.error(error); 
      });
  };
};
export const getZxTeamsGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`./ZxTeams/${id}/edit`)
  };
};
export const deleteZxTeams = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_ZXTEAMS, payload: {id}})
      })
      .then(() => {
        history.push("/ZxTeams")
      })
      .catch(error => {
        console.error(error);
      });
  };
};
export const updateZxTeams = (ZxTeams) => {
  const id = ZxTeams.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${ZxTeams.id}`, {
		team_name: ZxTeams.team_name,
		enable: ZxTeams.enable,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_ZXTEAMS, payload: {
			id: data.id,
			team_name: data.team_name,
			enable: data.enable,
		}})
        dispatch({type: REPLACE_ZXTEAMS, payload: {
			id: data.id,
			team_name: data.team_name,
			enable: data.enable,
		}})
      })
      .then(() => {
        history.push(`./ZxTeams/${id}`)
      })
      .catch(error => { console.error(error) });
  };
};
export const addOrUpdateZxTeams = (ZxTeams, actionMode) => {
	if(actionMode===1){
        return addZxTeams(ZxTeams);
      }else {
        return updateZxTeams(ZxTeams);
      }
};