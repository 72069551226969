import React, { Component } from "react";
import { Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "../IconButton/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./IconButton.css";

export default class MenuIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: "",
    };
  }

  render() {
    const { anchorEl } = this.state;
    const {
      onItemClick,
      icon,
      tooltip,
      style,
      size,
      menuOptions,
      buttonStyle,
      loading
    } = this.props;
    return (
      <>
        <IconButton
          icon={icon}
          tooltip={tooltip}
          onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
          style={style}
          buttonStyle={buttonStyle}
          size={size}
          loading={loading}
        ></IconButton>
        <Menu
          PaperProps={{
            elevation: 24,
          }}
          open={anchorEl !== ""}
          anchorEl={anchorEl}
          onClose={() => this.setState({ anchorEl: "" })}
        >
          {menuOptions.map((i) => {
            if (i?.link) {
              return (
                <Link
                  key={i.label}
                  to={i.link}
                  onClick={() => this.setState({ anchorEl: "" })}
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem>{i.label}</MenuItem>
                </Link>
              );
            }
            return (
              <MenuItem
                className="hide_print"
                disabled={i?.disabled}
                key={i.value}
                value={i.value}
                onClick={(e) => {
                  if (i?.onClick) {
                    i.onClick();
                  } else if (onItemClick) {
                    onItemClick(e);
                  }
                  this.setState({ anchorEl: "" });
                }}
              >
                {i?.icon && (
                  <FontAwesomeIcon
                    icon={i.icon}
                    style={{ marginRight: "1rem", color: "grey" }}
                  />
                )}
                {i.label}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }
}
