import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getVwFacturas, deleteVwFacturas } from '../../actions/VwFacturasAction';

class VwFacturasInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
		Facturacion:'', e_Facturacion: false,
		Factura_Fiscal:'', e_Factura_Fiscal: false,
		Cliente:'', e_Cliente: false,
		Vendedor:'', e_Vendedor: false,
		Fecha:'', e_Fecha: false,
		Tasa_Imp:'', e_Tasa_Imp: false,
		Subtotal:'', e_Subtotal: false,
		Impuesto:'', e_Impuesto: false,
		Total:'', e_Total: false,
		Tipo:'', e_Tipo: false,
		Estado:'', e_Estado: false,
		Fecha_Sistema:'', e_Fecha_Sistema: false,
		head:'', e_head: false,
		id:'', e_id: false,
    }
  }
  componentDidMount() {                                                         
    //this.props.getVwFacturas(this.props.match.params.id);
	const idx = this.props.match.params.id;  
	Promise.resolve(this.props.getVwFacturas(idx))
	.then((response)=>{
		//console.log("0000********INI**********"); console.log(response.VwFacturasOne);  console.log("0000********FIN**********");
		this.setState(response.VwFacturasOne); 
		return response;
	});
  }

  render() {
    const { t } = this.props;
    //const VwFacturas = this.props.VwFacturas;
    const VwFacturas = this.state; 
    return (
      <div>
        <p><label>{t('Facturacion_x')}</label><br/>{VwFacturas.Facturacion}</p>
        <p><label>{t('Factura_Fiscal_x')}</label><br/>{VwFacturas.Factura_Fiscal}</p>
        <p><label>{t('Cliente_x')}</label><br/>{VwFacturas.Cliente}</p>
        <p><label>{t('Vendedor_x')}</label><br/>{VwFacturas.Vendedor}</p>
        <p><label>{t('Fecha_x')}</label><br/>{VwFacturas.Fecha}</p>
        <p><label>{t('Tasa_Imp_x')}</label><br/>{VwFacturas.Tasa_Imp}</p>
        <p><label>{t('Subtotal_x')}</label><br/>{VwFacturas.Subtotal}</p>
        <p><label>{t('Impuesto_x')}</label><br/>{VwFacturas.Impuesto}</p>
        <p><label>{t('Total_x')}</label><br/>{VwFacturas.Total}</p>
        <p><label>{t('Tipo_x')}</label><br/>{VwFacturas.Tipo}</p>
        <p><label>{t('Estado_x')}</label><br/>{VwFacturas.Estado}</p>
        <p><label>{t('Fecha_Sistema_x')}</label><br/>{VwFacturas.Fecha_Sistema}</p>
        <p><label>{t('head_x')}</label><br/>{VwFacturas.head}</p>
        <p><label>{t('id_x')}</label><br/>{VwFacturas.id}</p>
        <hr/>
        <div className="btn-group">
          <Link to={{ pathname: `/VwFacturas/${VwFacturas.id}/edit`, state: { VwFacturas: VwFacturas } }} className='btn btn-info'>  
            {t('Edit')}
          </Link>
          <button className="btn btn-danger" type="button" onClick={() => this.props.deleteVwFacturas(VwFacturas.id)}>          
            {t('Delete')}
          </button>
          <Link to="./VwFacturas" className="btn btn-secondary">{t('Close')}</Link>                                                 
        </div>
        <hr/>
      </div>
    )
  }
}

//const TransVwFacturasInfo = withTranslation()(LegacyComponentClass);
const mapStateToProps = (state) => ({ VwFacturas: state.VwFacturas });                 

const mapDispatchToProps = { getVwFacturas, deleteVwFacturas };                        

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(VwFacturasInfo));   

