import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NEOINVAFTERSALESACTIONS = 'GET_NEOINVAFTERSALESACTIONS';
export const ADD_NEOINVAFTERSALESACTIONS = 'ADD_NEOINVAFTERSALESACTIONS';
export const RECEIVE_NEOINVAFTERSALESACTIONSX = 'RECEIVE_NEOINVAFTERSALESACTIONSX';
export const REMOVE_NEOINVAFTERSALESACTIONS = 'REMOVE_NEOINVAFTERSALESACTIONS';
export const UPDATE_NEOINVAFTERSALESACTIONS = 'UPDATE_NEOINVAFTERSALESACTIONS';
export const REPLACE_NEOINVAFTERSALESACTIONS = 'REPLACE_NEOINVAFTERSALESACTIONS';
//const apiUrl = API_DOMAIN + '/neoinvaftersalesactions'; 
const apiUrl = API_DOMAIN + '/actionsaftersales'; 

axiosDefaults(null);

export const getNeoInvAftersalesActionsAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}`)
      .then(response => {
        //const resp=response.data.data.neoinvaftersalesactions;
        console.log("iiiiiiii---WWWWWW ");   
        const resp=response.data.data;
        console.log("iiiiiiii---WWWWWW", resp);   
        //return dispatch({type: RECEIVE_NEOINVAFTERSALESACTIONS, NeoInvAftersalesActions: resp});
        dispatch({type: RECEIVE_NEOINVAFTERSALESACTIONS, NeoInvAftersalesActions: resp.neoinvaftersalesactions});
        //dispatch({type: RECEIVE_NEOINVAFTERSALESACTIONS, NeoInvAftersalesActions: resp.neoinvaftersalesactions});
        return resp;
        //dispatch({type: RECEIVE_NEOINVAFTERSALESACTIONS, NeoInvAftersalesActions: response.data.neoinvaftersalesactions.data})
      })
      .catch(error => {  
        console.error(error)
      });
  };
};

export const addNeoInvAftersalesActions = (NeoInvAftersalesActions) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}`, NeoInvAftersalesActions)
      .then(response => {
        let data = response.data; 
        if(data!==undefined && data.record!==undefined){
          dispatch({type: ADD_NEOINVAFTERSALESACTIONS, payload: data.record});
        }else{
          dispatch({type: ADD_NEOINVAFTERSALESACTIONS, payload: NeoInvAftersalesActions});
        }
        return response; 
      })
      .then(response => {
        //history.push("/NeoInvAftersalesActions");
        return response;
      })
      .catch(error => {
        if (error.response) {
          console.log(error, error?.response);
          console.error(error)
        } else if (error.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage="client never received a response, or request never left";
          console.error(errMessage);
        } else {
          console.log("error falls here #3"); console.log(error);   
        }
    });
  };
};


export const getNeoInvAftersalesActions = (id) => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log(response.data.neoinvaftersalesactions);		  
        return dispatch({type: RECEIVE_NEOINVAFTERSALESACTIONSX, NeoInvAftersalesActionsOne: response.data.neoinvaftersalesactions});
      })
      .catch(error => { 
        console.error(error)
      });
  };
};

export const getNeoInvAftersalesActionsx2 = (id) => {
  console.log("BEFORE response from server .... "+id); 
  //return axios.get(`${apiUrl}/${id}`)
 return (dispatch) => {
   return axios.get(`${apiUrl}/${id}`)
     .then(response => {
     console.log("response from server .... ");  console.log(response.data.neoinvaftersalesactions);		  
       return dispatch({type: RECEIVE_NEOINVAFTERSALESACTIONSX, NeoInvAftersalesActionsOne: response.data.neoinvaftersalesactions});
     })
     .catch(error => { 
      console.error(error)
     });
 };
};

export const getNeoInvAftersalesActionsx = (statusXObj) => { 
  //let status="payed";
  let ticket_id="0"; let invoice_id="0";

  //$ticket_id=$ArgsData["ticket_id"] ?? 0;
  //$invoice_id=$ArgsData["invoice_id"] ?? 0;

  //const statusList=statusXObj.statusList;
  //if(statusList!==""){ status=statusList; }

  let restParam="";
  if(statusXObj.ticket_id!==undefined){
    ticket_id =""+statusXObj.ticket_id;
  }
  if(statusXObj.invoice_id!==undefined){
    invoice_id =""+statusXObj.invoice_id;
  }
  
  //axios.get(`${apiUrl}?ticket_id=${ticket_id}${restParam}`)

  return (dispatch) => { 
    return axios.get(`${apiUrl}?ticket_id=${ticket_id}&invoice_id=${invoice_id}`)
      .then(response => { 
        //console.log("getNeoVentesAllXStausx-POSTACTION ", response);  
        console.log("NeoInvAftersalesActionsOne-GETCTION ", response); 
        //return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data.data});
        //return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data});
        return dispatch({type: RECEIVE_NEOINVAFTERSALESACTIONSX, NeoInvAftersalesActionsOne: response.data.data});
      })
      .catch(error => { 
        console.error(error)
      });
  };
};

export const getNeoInvAftersalesActionsGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/NeoInvAftersalesActions/${id}/edit`)
  };
};
export const deleteNeoInvAftersalesActions = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NEOINVAFTERSALESACTIONS, payload: {id}})
      })
      .then(() => {
        history.push("/NeoInvAftersalesActions")
      })
      .catch(error => {
        console.error(error)
      });
  };
};
export const updateNeoInvAftersalesActions = (NeoInvAftersalesActions) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}`, NeoInvAftersalesActions)
      .then(response => {
        let data = response.data.data; //response.data.neoinvaftersalesactions
        //console.log('xx0x0x0x RESPONSE   ');  
        //console.log(response);    
        //return dispatch({type: REPLACE_NEOINVAFTERSALESACTIONS, NEOINVAFTERSALESACTIONS: data});
        //console.log('RESPONSE UPDAAAATE NEOINVAFTERSALESACTIONS  ', data);    
        if(data!==undefined && data.record!==undefined){
          dispatch({type: REPLACE_NEOINVAFTERSALESACTIONS, payload: data.record});
          //console.log('RESPONSE UPDAAAATE NEOINVAFTERSALESACTIONS inside ');    
        }else{
          dispatch({type: REPLACE_NEOINVAFTERSALESACTIONS, payload: NeoInvAftersalesActions});
          //console.log('RESPONSE UPDAAAATE NEOINVAFTERSALESACTIONS outside ');    
        }
        
        //dispatch({type: UPDATE_NEOINVAFTERSALESACTIONS, payload: NeoInvAftersalesActions});
        //dispatch({type: REPLACE_NEOINVAFTERSALESACTIONS, payload: NeoInvAftersalesActions});
        return dispatch({type: RECEIVE_NEOINVAFTERSALESACTIONSX, NeoInvAftersalesActionsOne: data}); 
      })
      .then(response => {
        //history.push("/NeoInvAftersalesActions");
        return response;
      })
      .catch(error => {
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log("error falls here #1"); console.log(error.response);
          console.log(error);
          console.error(error)
        } else if (error.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage="client never received a response, or request never left";
          console.error(errMessage);
        } else {
          // anything else
          console.log("error falls here #3"); console.log(error);   
          console.error(error); 
        }
    });
  };
};


export const addOrUpdateNeoInvAftersalesActions = (NeoInvAftersalesActions, actionMode) => {
	if(actionMode===1){
        return addNeoInvAftersalesActions(NeoInvAftersalesActions);
      }else {
        return updateNeoInvAftersalesActions(NeoInvAftersalesActions);
      }
};