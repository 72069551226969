import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getNeoVentes, deleteNeoVentes } from '../../actions/NeoVentesAction';

class NeoVentesInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		name:'', e_name: false,
		description:'', e_description: false,
		amount:'', e_amount: false,
    }
  }
  componentDidMount() {                                                         
    //this.props.getNeoVentes(this.props.match.params.id);
	const idx = this.props.match.params.id;  
	Promise.resolve(this.props.getNeoVentes(idx))
	.then((response)=>{
		//console.log("0000********INI**********"); console.log(response.NeoVentesOne);  console.log("0000********FIN**********");
		this.setState(response.NeoVentesOne); 
		return response;
	});
  }

  render() {
    const { t } = this.props;
    //const NeoVentes = this.props.NeoVentes;
    const NeoVentes = this.state; 
    return (
      <div>
        <h2>NeoVentes</h2>
        <hr/>
        <p><label>{t('zqwid_x')}</label> &nbsp; &nbsp; #{NeoVentes.id}</p>
        <p><label>{t('zqwname_x')}</label><br/>{NeoVentes.name}</p>
        <p><label>{t('zqwdescription_x')}</label><br/>{NeoVentes.description}</p>
        <p><label>{t('zqwamount_x')}</label><br/>{NeoVentes.amount}</p>
        <hr/>
        <div className="btn-group">
          <Link to={{ pathname: `/NeoVentes/${NeoVentes.id}/edit`, state: { NeoVentes: NeoVentes } }} className='btn btn-info'>  
            {t('Edit')}
          </Link>
          <button className="btn btn-danger" type="button" onClick={() => this.props.deleteNeoVentes(NeoVentes.id)}>          
            {t('Delete')}
          </button>
          <Link to="./NeoVentes" className="btn btn-secondary">{t('Close')}</Link>                                                 
        </div>
        <hr/>
      </div>
    )
  }
}

//const TransNeoVentesInfo = withTranslation()(LegacyComponentClass);
const mapStateToProps = (state) => ({ NeoVentes: state.NeoVentes });                 

const mapDispatchToProps = { getNeoVentes, deleteNeoVentes };                        

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoVentesInfo));   

