import React from "react";

import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    NavLink,
    ButtonGroup
    // Table
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faCoins,
    faTachometerAlt,
    faTags,
    faFilter,
    faGlobeAmericas,
    faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import DashboardElement from '../../../../utils/DashboardElement'
import Tasks, { CalendarIconButton } from "./Tasks";
import UserDetails from "./UserDetails";
import LeadInfo from "./LeadInfo";
import LeadHistory from "./LeadHistory";
import ClientPurchases from './ClientPurchases';
import LeadInfoNDI from "./LeadInfoNDI";

import {connect} from 'react-redux';

class AppBar extends React.Component{
    constructor(props){
        super(props)
    }

    genLang = (text) => {
        const {trad, lang} = this.props;
        return trad['neoleads'][text][lang]
    }

    render(){
        return (
            <Row
                style={{
                    // overflowY:'scroll',
                    // maxHeight:'100vh',
                    // height:'100vh'

                }}
            // className="scrollbar-visible"
            >
                {/* <DashboardElement
                    col={12}
                    title={"Applications"}
                    className="p-l-5 p-r-5"
                    classNameBody="p-0 app-bar scrollbar-visible okamisama-tasukete-kudasai"

                > */}
                    {/* CALENDAR */}
                    <DashboardElement
                        // classNameBody="p-0"
                        className="p-0"
                        col={12}
                        height={this.props.customer.type === 'client' ? 'auto' : '200px'}
                        maxHeight={this.props.customer.type === 'client' ? 'auto' : '300px'}
                        title={this.props.customer.type === 'client' ? `${this.genLang('clientInfo')}` : `${this.genLang('contactInfo')}`}
                        toggle={true}
                        open={true}
                    >
                        <UserDetails lang={this.props.lang} trad={this.props.trad}/>
                    </DashboardElement>

                    {this.props.customer.type === 'client' && <DashboardElement
                        // classNameBody="p-0"
                        className="p-0"
                        col={12}
                        height={'auto'}
                        maxHeight={'auto'}
                        title={`${this.genLang('clientPurchases')}`}
                        toggle={true}
                        open={false}
                    >
                        <ClientPurchases lang={this.props.lang} trad={this.props.trad} />

                        {/* leadSuivi = {type_for_lead */}
                    </DashboardElement>}

                    {this.props.customer.type === 'client' && this.props.customer.leadSuivi 
                    && this.props.customer.leadSuivi.type_for_lead==="suivi" 
                    && <DashboardElement
                        // classNameBody="p-0"
                        className="p-0"
                        col={12}
                        height={'auto'}
                        maxHeight={'auto'}
                        title={"Info Dossier Client"}
                        toggle={true}
                        open={true}
                    >
                        <LeadInfoNDI lang={this.props.lang} trad={this.props.trad}
                         leadSuivi={this.props.customer.leadSuivi} />
                    </DashboardElement>}

                    {/* <DashboardElement
                        // classNameBody="p-0"
                        className="p-0"
                        col={12}
                        height={'auto'}
                        maxHeight={'150px'}
                        title={this.genLang('leadHistory')}
                        toggle={true}
                        open={true}
                    >
                        <LeadHistory lang={this.props.lang} trad={this.props.trad}/>
                    </DashboardElement> */}

                    <DashboardElement
                        // classNameBody="p-0"
                        className="p-0"
                        col={12}
                        height={'auto'}
                        maxHeight={'auto'}
                        title={this.genLang('leadInfo')}
                        toggle={true}
                        open={true}
                    >
                        <LeadInfo lang={this.props.lang} trad={this.props.trad}/>
                    </DashboardElement>

                    <DashboardElement
                        // classNameBody="p-0"
                        className="p-0"
                        col={12}
                        height={'auto'}
                        maxHeight={'150px'}
                        title={this.genLang('leadHistory')}
                        toggle={true}
                        open={true}
                    >
                        <LeadHistory lang={this.props.lang} trad={this.props.trad}/>
                    </DashboardElement>
        
                    <DashboardElement
                        // classNameBody="p-0"
                        className="p-0"
                        col={12}
                        height={'auto'}
                        maxHeight={'200px'}
                        title={this.genLang('tasks')}
                        toggle={true}
                        open={true}
                        headerButton={<CalendarIconButton />}
                    >
                        <Tasks title={this.genLang('nextCalls')}/>
                    </DashboardElement>

                {/* </DashboardElement> */}

            </Row>
        )
    }
}


const mapStateToProps = state => ({
    // rootsActions:rootsActions,
    customer:state.customer
  });
  
//   const mapActionsToProps = {
//     setCustomer:rootsActions.customersActions.setUser,
//     setConflict:rootsActions.customersActions.setConflict
//   }
  
export default connect(
    mapStateToProps,
    // mapActionsToProps
)(AppBar);