import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { Button } from "@mui/material";
import axios from "axios";

const URL = process.env.REACT_APP_API_URLX;
export function DeactivateProductModal({
  isOpen,
  toggle,
  products,
  setProducts,
  setSelectedProduct,
  selectedProduct,
  setToast,
  fetchData
}) {
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    if (selectedProduct?.id) {
      setLoading(!loading);
      axios
        .post(
          URL + "/deleteProduct",
          {
            auth_id: localStorage.getItem("user_id"),
            id_product: selectedProduct.id,
          },
          {
            params: {},
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("admin_token"),
            },
          }
        )
        .then(({ data }) => {
          console.log(data);
          const { success } = data.data;
          if (success) {
            toggle();
            const newProducts = products.filter(
              (i) => i?.id !== selectedProduct.id
            );
            setProducts(newProducts);
            setToast({
              type: "success",
              message: "Produit désactiver avec succès !",
            });
            fetchData();
          } else {
            setToast({ type: "error", message: "Delete unsuccessful" });
          }
        })
        .catch((e) => {
          console.error(e);
          setToast({ type: "error", message: "Delete unsuccessful" });
        })
        .finally(() => setLoading(false));
    }
  };

  const onClose = () => {
    setSelectedProduct({ options: [] });
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      style={{ minWidth: "650px" }}
      onClosed={onClose}
    >
      <ModalHeader toggle={toggle}>{"Désactiver produit"}</ModalHeader>
      <ModalBody>
        <div style={{ fontStyle: "italic" }}>
          Veuillez confirmer que vous souhaitez désactiver ce produit:
        </div>
        <div style={{ fontWeight: "500" }}>
          {selectedProduct?.title?.fr ?? ""}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "0.5rem",
            marginTop: "1rem",
          }}
        >
          <Button
            onClick={toggle}
            sx={{ width: "48%" }}
            color="error"
            variant="outlined"
          >
            Annuler
          </Button>
          <Button
            onClick={handleDelete}
            sx={{ width: "48%" }}
            color="error"
            variant="contained"
          >
            {loading ? <Spinner size={"sm"} /> : "Confirmer"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}
