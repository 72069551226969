import { 
  PUT_FIELD,
  PUT_DOCUMENT,
  INIT,
  SET_LIST_ATTACH,
} from '../_actions/requestDocuments-actions';
import _ from 'lodash';

export default function formsReducer(state = '',{type,params}){
 
  switch(type){
    case INIT:
      return {
        ...state,
        fields:{},
        documents:{},
        
    };

    case SET_LIST_ATTACH:
      let listAttach = state.listAttach;
      if(listAttach && params){
        listAttach = params;
        
      }

      return {...state,listAttach};
      
    case PUT_DOCUMENT:
      
      let documents = state.documents;
      if(documents && params){
        documents = params;
        
      }

      return {...state,documents};

    case PUT_FIELD:
      let fields = state.fields
      if(fields && params && params.key){
        fields[params.key] = params.value;
        
      }
        
      // console.log('docs 🧶🧶', documents,state);
      return {...state,fields:fields};
    default:
      return state;
  }
}