import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_NEOSALESPLANNINGDAILY = 'GET_NEOSALESPLANNINGDAILY';
export const ADD_NEOSALESPLANNINGDAILY = 'ADD_NEOSALESPLANNINGDAILY';
export const RECEIVE_NEOSALESPLANNINGDAILYX = 'RECEIVE_NEOSALESPLANNINGDAILYX';
export const REMOVE_NEOSALESPLANNINGDAILY = 'REMOVE_NEOSALESPLANNINGDAILY';
export const UPDATE_NEOSALESPLANNINGDAILY = 'UPDATE_NEOSALESPLANNINGDAILY';
export const REPLACE_NEOSALESPLANNINGDAILY = 'REPLACE_NEOSALESPLANNINGDAILY';
const apiUrl = API_DOMAIN + '/salesplanning/days';  //neosalesplanningdaily
const apiUrlBase = API_DOMAIN;

axiosDefaults(null);

export const getNeoSalesPlanningDailyAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/dayplans`)
      .then(response => {
        dispatch({type: RECEIVE_NEOSALESPLANNINGDAILY, NeoSalesPlanningDaily: response.data.data.planningdaily})
      })
      .catch(error => { console.error(error); });
  };
};

export const getNeoSalesPlanningDailyWeek = (id) => {
	return (dispatch) => {
	  return axios.get(`${apiUrl}/dayplans?weekId=${id}`)
		.then(response => {
		  dispatch({type: RECEIVE_NEOSALESPLANNINGDAILY, NeoSalesPlanningDaily: response.data.data.planningdaily})
		})
		.catch(error => { console.error(error); });
	};
  };

export const addNeoSalesPlanningDaily = (NeoSalesPlanningDaily) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: NeoSalesPlanningDaily.id,
		week_plan_id: NeoSalesPlanningDaily.week_plan_id,
		work_date: NeoSalesPlanningDaily.work_date,
		expected_quantity: NeoSalesPlanningDaily.expected_quantity,
		expected_amount: NeoSalesPlanningDaily.expected_amount,
		invoice_quantity: NeoSalesPlanningDaily.invoice_quantity,
		invoice_amount: NeoSalesPlanningDaily.invoice_amount,
		payment_quantity: NeoSalesPlanningDaily.payment_quantity,
		payment_amount: NeoSalesPlanningDaily.payment_amount,
		creation_date: NeoSalesPlanningDaily.creation_date,
		last_update: NeoSalesPlanningDaily.last_update,
		user_creation: NeoSalesPlanningDaily.user_creation,
		user_update: NeoSalesPlanningDaily.user_update,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_NEOSALESPLANNINGDAILY, payload: {
			id: data.id,
			week_plan_id: data.week_plan_id,
			work_date: data.work_date,
			expected_quantity: data.expected_quantity,
			expected_amount: data.expected_amount,
			invoice_quantity: data.invoice_quantity,
			invoice_amount: data.invoice_amount,
			payment_quantity: data.payment_quantity,
			payment_amount: data.payment_amount,
			creation_date: data.creation_date,
			last_update: data.last_update,
			user_creation: data.user_creation,
			user_update: data.user_update,
		}})
      })
      .then(() => {
        history.push("/NeoSalesPlanningDaily")
      })
      .catch(error => { console.error(error) });
  };
};
export const getNeoSalesPlanningDaily = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.neosalesplanningdaily);		  
        return dispatch({type: RECEIVE_NEOSALESPLANNINGDAILYX, NeoSalesPlanningDailyOne: response.data.neosalesplanningdaily});
      })
      .catch(error => { 
        console.error(error); 
      });
  };
};
export const getDailyGoalSale = () => {
	console.log("getDailyGoalSale BEFORE response from server .... "); 
	//return axios.get(`${apiUrl}/${id}`)
 return (dispatch) => {
   return axios.get(`${apiUrl}/getdailylygoal`)
	 .then(response => {
		 //console.log("getDailyGoalSale response from server .... ");  console.log(response.data.data);
		 localStorage.setItem("goalsale", JSON.stringify(response.data.data)); 		  
	   return dispatch({type: RECEIVE_NEOSALESPLANNINGDAILYX, NeoSalesPlanningDailyOne: response.data.data});
	 })
	 .catch(error => { 
	   console.error(error); 
	 });
 };
};

 
export const checkIfUserIsAdmin = () => {
	console.log("checkIfUserIsAdmin BEFORE response from server .... "); 
	//return axios.get(`${apiUrlBase}/${id}`)
 return (dispatch) => {
   return axios.get(`${apiUrlBase}/users/checkisadmin`)
	 .then(response => {
		 //console.log("checkIfUserIsAdmin response from server .... ");  console.log(response.data.data);
		 //localStorage.setItem("goalsale", JSON.stringify(response.data.data)); 		  
	   return dispatch({type: RECEIVE_NEOSALESPLANNINGDAILYX, NeoSalesPlanningDailyOne: response.data.data});
	 })
	 .catch(error => { 
	   console.error(error); 
	 });
 };
};

export const getNeoSalesPlanningDailyGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`./NeoSalesPlanningDaily/${id}/edit`)
  };
};
export const deleteNeoSalesPlanningDaily = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_NEOSALESPLANNINGDAILY, payload: {id}})
      })
      .then(() => {
        history.push("/NeoSalesPlanningDaily")
      })
      .catch(error => {
        console.error(error);
      });
  };
};
export const updateNeoSalesPlanningDaily = (NeoSalesPlanningDaily) => {
  const id = NeoSalesPlanningDaily.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${NeoSalesPlanningDaily.id}`, {
		week_plan_id: NeoSalesPlanningDaily.week_plan_id,
		work_date: NeoSalesPlanningDaily.work_date,
		expected_quantity: NeoSalesPlanningDaily.expected_quantity,
		expected_amount: NeoSalesPlanningDaily.expected_amount,
		invoice_quantity: NeoSalesPlanningDaily.invoice_quantity,
		invoice_amount: NeoSalesPlanningDaily.invoice_amount,
		payment_quantity: NeoSalesPlanningDaily.payment_quantity,
		payment_amount: NeoSalesPlanningDaily.payment_amount,
		creation_date: NeoSalesPlanningDaily.creation_date,
		last_update: NeoSalesPlanningDaily.last_update,
		user_creation: NeoSalesPlanningDaily.user_creation,
		user_update: NeoSalesPlanningDaily.user_update,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_NEOSALESPLANNINGDAILY, payload: {
			id: data.id,
			week_plan_id: data.week_plan_id,
			work_date: data.work_date,
			expected_quantity: data.expected_quantity,
			expected_amount: data.expected_amount,
			invoice_quantity: data.invoice_quantity,
			invoice_amount: data.invoice_amount,
			payment_quantity: data.payment_quantity,
			payment_amount: data.payment_amount,
			creation_date: data.creation_date,
			last_update: data.last_update,
			user_creation: data.user_creation,
			user_update: data.user_update,
		}})
        dispatch({type: REPLACE_NEOSALESPLANNINGDAILY, payload: {
			id: data.id,
			week_plan_id: data.week_plan_id,
			work_date: data.work_date,
			expected_quantity: data.expected_quantity,
			expected_amount: data.expected_amount,
			invoice_quantity: data.invoice_quantity,
			invoice_amount: data.invoice_amount,
			payment_quantity: data.payment_quantity,
			payment_amount: data.payment_amount,
			creation_date: data.creation_date,
			last_update: data.last_update,
			user_creation: data.user_creation,
			user_update: data.user_update,
		}})
      })
      .then(() => {
        history.push(`./NeoSalesPlanningDaily/${id}`)
      })
      .catch(error => { console.error(error) });
  };
};
export const addOrUpdateNeoSalesPlanningDaily = (NeoSalesPlanningDaily, actionMode) => {
	if(actionMode===1){
        return addNeoSalesPlanningDaily(NeoSalesPlanningDaily);
      }else {
        return updateNeoSalesPlanningDaily(NeoSalesPlanningDaily);
      }
};