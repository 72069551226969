import React, { useState } from "react";
import Select from "react-select";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavLink,
  NavItem,
  Label,
  UncontrolledAlert,
  Spinner,
  Button,
  Col,
  Row,
} from "reactstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notes from "./Notes/Notes";
import {
  faDownload,
  faEnvelope,
  faExchange,
  faExternalLink,
  faEye,
  faFolder,
  faHashtag,
  faPhone,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import axios from "axios";
import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import { NDIWebsite } from "../../../../../appConfig";
import { Tooltip } from "@mui/material";
import Toast from "../../../Toast/Toast";
import IconButton from "../../../IconButton/IconButton";
import { genLang as genLang2 } from "../../../../../composables/genLang";
const BACKEND1 = process.env.REACT_APP_API_URLX;

var toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  // ['blockquote', 'code-block'],

  // [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  // [{ header: [1, 2, 3, 4, 5, 6, false] }],

  // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  // [{ font: [] }],
  // [{ align: [] }],

  ["clean"], // remove formatting button
];
const QL_CURSOR = /\<span class=["']ql-cursor["']>\s*<\/span>/gm;

export default class EditLeadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalNote: false,
      notes: [],
      activeTab: "edit",
      macroText: "<p></p>",
      macro: "",
      toast: { type: "", message: "" },
      macroLoading: false,
      generatedDocs: [],
      lawyerOptions: [],
    };
  }

  componentDidMount() {
    //const { t } = this.props;  //ca-01
    const { selectedLead, getDueDate } = this.props;
    const e = { label: "Note privée", value: "ca-01", text: "" };
    this.handleMacroChange(e);
    if (selectedLead.type_for_lead === "suivi" && getDueDate) {
      getDueDate(selectedLead.ticket_id);
      this.getLawyerOptions();
    }
    if (this.props.showGeneratedDocs) {
      this.getDocuments();
    }
  }

  getLawyerOptions = () => {
    axios
      .get(BACKEND1 + "/leads", {
        params: {
          agentTransfer: "yes",
        },
      })
      .then((status) => {
        let { data } = status.data;
        if (data && data.agentList2) {
          const lawyerOptions =
            data.agentList2.map((i) => {
              return { label: i.label, value: i.value };
            }) ?? [];
          lawyerOptions.push({
            label: "Aucun/None",
            value: "",
          });
          this.setState({ lawyerOptions });
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.error(error.response.data);
        }
      });
  };

  getDocumentList(ticket_id) {
    return axios.get(`${BACKEND1}/case/attachments/list`, {
      params: { ticket_id },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin_token"),
      },
    });
  }

  genDownloadURL = (url, filename, bucket, action = "download") => {
    return (
      BACKEND1 +
      "/files/getfile?url=" +
      url +
      "&token=" +
      localStorage.getItem("admin_token") +
      `&action=${action}` +
      "&filename=" +
      filename?.normalize("NFD").replace(/[\u0300-\u036f]/g, "") +
      "&bucket=" +
      bucket +
      "&auth_id=" +
      localStorage.getItem("user_id").replace(/\s+/g, "")
    );
  };

  getDocuments() {
    return this.getDocumentList(this.props?.selectedLead?.ticket_id || "")
      .then((res) => {
        const docs = res.data.data.map((d) => ({
          ...d,
          download_url: this.genDownloadURL(
            d.url_gcloud,
            d.filename,
            d.bucket,
            "download"
          ),
          preview_url: this.genDownloadURL(
            d.url_gcloud,
            d.filename,
            d.bucket,
            "preview"
          ),
        }));
        const generatedDocs =
          docs.filter((d) => d.generated === "1" && d.status !== "4") || [];
        this.setState({ generatedDocs });
      })
      .catch((e) => console.error(e));
  }

  setToast = (type = "suceess", message = "") => {
    this.setState({ toast: { type, message } });
  };

  renderLeadInfo = () => {
    const { selectedLead } = this.props;
    if (!selectedLead) return "";
    if (!selectedLead.definition) return "";
    const { phone, user_email } = selectedLead.definition;
    const { contact, id, ticket_id, type_for_lead } = selectedLead;

    return (
      <div style={{ display: "flex", gap: "2rem" }}>
        <div style={{ fontWeight: "600" }}>
          <Tooltip title="Lead Id">
            <span>
              <FontAwesomeIcon icon={faHashtag} color={"#A9A9A9"} />
              {` ${!id ? "" : id}`}
            </span>
          </Tooltip>
        </div>
        {type_for_lead === "suivi" && (
          <div style={{ fontWeight: "600" }}>
            <Tooltip title="Dossier">
              <span>
                <FontAwesomeIcon icon={faFolder} color={"#A9A9A9"} />
                {` ${!ticket_id ? "" : ticket_id}`}
              </span>
            </Tooltip>
          </div>
        )}
        <div style={{ fontWeight: "600" }}>
          <FontAwesomeIcon icon={faUserCircle} color={"#A9A9A9"} />
          {` ${!contact ? "" : contact}`}
        </div>
        <div style={{ fontWeight: "600" }}>
          <FontAwesomeIcon icon={faPhone} color={"#A9A9A9"} />
          {` ${!phone ? "" : phone}`}
        </div>
        <div style={{ fontWeight: "600" }}>
          <FontAwesomeIcon icon={faEnvelope} color={"#A9A9A9"} />
          {` ${!user_email ? "" : user_email}`}
        </div>
      </div>
    );
  };

  handleMacroChange = (e) => {
    //console.log("hoooooooooooola", e);
    this.setState({ macro: e.value, macroText: e.text });
  };

  fetchNotes = async (id) => {
    const { data } = await axios.get(BACKEND1 + "/leads", {
      params: {
        // src: "desk",
        id: id,
        leadreadonly: 3,
      },
      // headers: {
      //   "Content-Type": "application/json",
      //   // 'x-jwt-token': localStorage.getItem('token'),
      //   "auth-id": localStorage.getItem("user_id"),
      //   Authorization: "Bearer " + localStorage.getItem("token"),
      // },
    });
    //console.log("WAAAAAAAAAAAAAAAALA");
    console.log(data.data);
    this.setState({ notes: data.data.notes });
  };

  handleMacroPost = () => {
    if (this.state.macroText && this.state.macro) {
      this.setState({ macroLoading: true });
      const obj = {
        note: this.state.macroText,
        actionid: this.state.macro,
        id: this.props.selectedLead.id,
      };
      console.log(obj);
      axios
        .post(BACKEND1 + "/leads/lead", obj, {
          params: {
            //src: "desk",
          },
          // headers: {
          //   "Content-Type": "application/json",
          //   // 'x-jwt-token': localStorage.getItem('token'),
          //   "auth-id": localStorage.getItem("user_id"),
          //   Authorization: "Bearer " + localStorage.getItem("token"),
          // },
        })
        .then((status) => {
          const { data } = status.data;
          if (data.notes) {
            this.setState({
              notes: [...data.notes],
              macro: "",
              macroText: "<p></p>",
              toast: { type: "success", message: "Macro envoyée avec succès" },
              macroLoading: false,
            });
          }
        })
        .catch((e) => {
          console.error(e);
          this.setState({
            toast: {
              type: "error",
              message: "Erreur lors de l'envoi de la Macro",
            },
            macroLoading: false,
          });
        });
    }
  };

  handleNavChange = (tab) => {
    if (tab !== this.state.activeTab) {
      if (tab === "notes") {
        this.fetchNotes(this.props.selectedLead.id);
        this.setState({ activeTab: tab });
      } else {
        this.setState({ activeTab: tab });
      }
    }
  };

  urlify = (text) => {
    //var urlRegex = /(https?:\/\/[^\s]+)/g;
    //var urlRegex = /(https?:\/\/[^\s]+[^>]?)/g;
    var urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url + "</a>";
    });
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  };

  getTexturlify = (notes) => {
    if (notes.length > 0) {
      const htmlBlock = this.urlify(notes);
      notes = htmlBlock;
    }

    return notes;
  };

  handleValChange = (event) => {
    const { handleSetValueChange } = this.props;
    //const { t } = this.props;
    if (event.target.name === "duedate") {
      this.setState({ [event.target.name]: event.target.value });
      console.log("handleValChange-handleValChange 1.1 ", event.target.name);
      if (handleSetValueChange !== undefined) {
        const e = { ...event, value: event.target.value };
        handleSetValueChange(e, "duedate");
        console.log("handleValChange-handleValChange 1.2 ", event.target.name);
      }
    } else if (event.target.name === "followup") {
      //this.setState({ [event.target.name]: event.target.value });
      if (event.target.checked) {
        this.setState({ [event.target.name]: "yes" });
        console.log("handleValChange-handleValChange 2.1 ", event.target.name);
        if (handleSetValueChange) {
          const e = { ...event, value: "yes" };
          handleSetValueChange(e, "followup");
          console.log(
            "handleValChange-handleValChange 2.2 ",
            event.target.name
          );
        }
      } else {
        this.setState({ [event.target.name]: "no" });
        console.log("handleValChange-handleValChange 3.1 ", event.target.name);
        if (handleSetValueChange) {
          const e = { ...event, value: "no" };
          handleSetValueChange(e, "followup");
          console.log(
            "handleValChange-handleValChange 3.2 ",
            event.target.name
          );
        }
      }
    } else if (event.target.name) {
      this.setState({ [event.target.name]: event.target.value });
    }

    console.log(
      "handleValChange-handleValChange ",
      event.target.name,
      event.target.value
    );
  };

  render() {
    const {
      showEditLead,
      error,
      selectedLead,
      close,
      agentOptions,
      domaineOptions,
      handleSelectChange,
      handleSelectActionChange,
      handleSetValueChange,
      handleSubmit,
      tableView,
      loading,
      genLang,
      macrosOptions,
    } = this.props;

    const actionOptions = [
      { label: genLang("attribute"), value: "1" },
      { label: genLang("cancelLead"), value: "5" },
      { label: genLang("automaticDistrubution"), value: "0" },
      { label: genLang("closeLead"), value: "3" },
      { label: genLang("ecommercesms"), value: "8" },
    ];
    const thermoOptions = [
      { label: genLang("cold"), value: "1" },
      { label: genLang("warm"), value: "2" },
      { label: genLang("hot"), value: "3" },
    ];

    const provinceOptions = [
      { label: "Québec", value: "QC" },
      { label: "Ontario", value: "ON" },
    ];
    const languageOptions = [
      { label: "Français", value: "fr" },
      { label: "English", value: "en" },
    ];
    const departmentOptions = [
      { label: "Sans Transfert", value: "" },
      // { label: "Ventes", value: "1" },
      { label: "Service Client", value: "2" },
    ];

    const language =
      selectedLead.language !== undefined
        ? selectedLead.language
        : selectedLead.definition.language;
    const province =
      selectedLead.province !== undefined
        ? selectedLead.province
        : selectedLead.definition.province;
    console.log("hweeeeeeeeeeeeeeeeeeohoho", selectedLead);
    const type_for_lead =
      selectedLead.type_for_lead !== undefined
        ? selectedLead.type_for_lead
        : "lead";
    const department =
      selectedLead.department !== undefined ? selectedLead.department : "";
    const ticket_id =
      selectedLead.ticket_id !== undefined ? selectedLead.ticket_id : 0;

    let str;
    // try{str = selectedLead.definition.ticket_latest_public_comment.replace(/(\r\n|\n\r|\r|\n)/g, '<br>');}
    try {
      str = selectedLead.definition.ticket_latest_public_comment.replaceAll(
        "\n",
        "<br/>"
      );
    } catch {
      str = "";
    }

    let notesx = this.state.notes || [];
    const neoZendNotes = this.props.neoZendNotes; //this.state.neoZendNotes;
    const attachmentsDocs = [];
    let notes = [];

    if (neoZendNotes && neoZendNotes.length > 0) {
      //notes.push.apply(notes, neoZendNotes);  neoZendNotes
      notes = notesx.concat(neoZendNotes);
      console.log(
        "EditLeadModal GOOOOOOOOOOOOOOOOOOheeeeeeeeeeeeeee CONGRATS APPLY for zend t=",
        neoZendNotes.length
      );

      //points.sort(function(a, b){return b-a});
      notes.sort(function (a, b) {
        //const strStart = moment.utc(start).local().format("YYYY-MM-DD HH:mm");
        //const strEnd = moment.utc(end).local().format("YYYY-MM-DD HH:mm");
        const date1 = moment(a.date_created);
        const date2 = moment(b.date_created);
        return date1 < date2 ? 1 : date1 > date2 ? -1 : 0;
        //return ((date2 < date1) ? 1 : (date2 > date1) ? -1 : 0);
        // date_created
        // return b-a
      });
    } else {
      notes = notesx;
    }

    const showPSJAssignButton = selectedLead?.type_for_lead === "suivi";

    return (
      <Modal
        isOpen={showEditLead}
        centered={true}
        fade={false}
        size="lg"
        onClosed={() => {
          close();
          this.setState({
            showModalNote: false,
            notes: [],
            activeTab: "edit",
            macro: "",
            macroText: "<p></p>",
            macroLoading: false,
            loading: false,
            generatedDocs: [],
          });
        }}
      >
        <ModalHeader
          onClick={() => console.log(this.state, selectedLead)}
          toggle={() => {
            close();
          }}
        >
          {genLang("editLead")}
        </ModalHeader>
        <ModalBody style={{ maxHeight: "750px", height: "auto" }}>
          {error ? (
            <UncontrolledAlert color="danger">
              {genLang("postError")}
            </UncontrolledAlert>
          ) : (
            ""
          )}
          {/* <h4 style={{ textAlign: "center", color: "#0052BB" }}>
            Lead #{selectedLead.id}
          </h4> */}
          {this.renderLeadInfo()}
          <Nav tabs fill style={{ marginTop: "1rem" }}>
            <NavItem>
              <NavLink
                active={this.state.activeTab === "edit"}
                onClick={() => this.handleNavChange("edit")}
              >
                {genLang("editLead")}
              </NavLink>
            </NavItem>
            {this.props.selectedLead.type_for_lead === "suivi" && (
              <NavItem>
                <NavLink
                  active={this.state.activeTab === "moreactions"}
                  onClick={() => this.handleNavChange("moreactions")}
                >
                  Information
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink
                active={this.state.activeTab === "macros"}
                onClick={() => this.handleNavChange("macros")}
              >
                Macros
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={this.state.activeTab === "notes"}
                onClick={() => this.handleNavChange("notes")}
              >
                {genLang("leadHistoryNotes")}
              </NavLink>
            </NavItem>
            {this.props.showGeneratedDocs && (
              <NavItem>
                <NavLink
                  active={this.state.activeTab === "docs"}
                  onClick={() => this.handleNavChange("docs")}
                >
                  {`${genLang("generatedDocs")} (${
                    this.state.generatedDocs.length
                  })`}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          {this.state.activeTab === "edit" && (
            <>
              <Row>
                {tableView === "lead" && (
                  <Col sm="6">
                    <Label style={{ marginTop: "1rem" }}>Action</Label>
                    <Select
                      options={actionOptions}
                      placeholder={"Select an Action"}
                      value={actionOptions.find(
                        (i) => i.value === selectedLead.status
                      )}
                      onChange={(e) => {
                        handleSelectActionChange(e);
                      }}
                    />
                  </Col>
                )}
                <Col sm={showPSJAssignButton ? "5" : "6"}>
                  <Label style={{ marginTop: "1rem" }}>Agent</Label>
                  <Select
                    isDisabled={
                      tableView === "lead" && selectedLead.status !== "1"
                    }
                    options={agentOptions.filter((i) => i.agentId !== "46351")}
                    placeholder={"Select an agent"}
                    value={agentOptions.find(
                      (i) => i.agentId === selectedLead.agentId
                    )}
                    onChange={(e) => {
                      handleSelectChange(e, "agent");
                    }}
                  />
                </Col>
                {showPSJAssignButton && (
                  <Col sm="1" style={{ alignSelf: "flex-end" }}>
                    <ChangePSJAssign
                      options={this.state.lawyerOptions}
                      selectedLead={selectedLead}
                      setToast={this.setToast}
                      genLang={genLang}
                    />
                  </Col>
                )}
              </Row>

              <Row>
                {tableView === "lead" && (
                  <Col sm="6">
                    <Label style={{ marginTop: "1rem" }}>
                      {genLang("domain")}
                    </Label>
                    <Select
                      options={domaineOptions}
                      placeholder={"Select a Domaine"}
                      value={domaineOptions.find(
                        (i) => i.value === selectedLead.domaineLead
                      )}
                      onChange={(e) => {
                        handleSelectChange(e, "domaine");
                      }}
                    />
                  </Col>
                )}
                <Col>
                  <Label style={{ marginTop: "1rem" }}>
                    {genLang("priority")}
                  </Label>
                  <Select
                    options={thermoOptions}
                    placeholder={"Select priority"}
                    value={thermoOptions.find(
                      (i) => i.value === selectedLead.thermo
                    )}
                    onChange={(e) => {
                      handleSelectChange(e, "thermo");
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="6">
                  <Label style={{ marginTop: "1rem" }}>Province(s)</Label>
                  <Select
                    options={provinceOptions}
                    value={provinceOptions.find((i) => i.value === province)}
                    onChange={(e) => handleSelectChange(e, "province")}
                  />
                </Col>
                <Col sm="6">
                  <Label style={{ marginTop: "1rem" }}>Langue(s)</Label>
                  <Select
                    options={languageOptions}
                    value={languageOptions.find((i) => i.value === language)}
                    onChange={(e) => handleSelectChange(e, "language")}
                  />
                </Col>
              </Row>
              {type_for_lead === "suivi" && (
                <Row>
                  {/* <Label style={{ marginTop: "1rem" }}>Follow up </Label>
                  &nbsp; &nbsp;{" "}
                  <input
                    type="checkbox"
                    id="followup"
                    name="followup"
                    onChange={this.handleValChange}
                    // value={}
                    checked={selectedLead?.followup === "yes" ? true : false}
                  />{" "}
                  &nbsp; &nbsp; <br /> */}
                  <Col sm="6">
                    <Label style={{ marginTop: "0.5rem" }}>
                      Date d'écheance
                    </Label>
                    <input
                      type="date"
                      id="duedate"
                      name="duedate"
                      placeholder="Date d'écheance"
                      onChange={this.handleValChange}
                      className="form-control"
                      value={selectedLead?.duedate || ""}
                    />
                  </Col>
                  <Col sm="6">
                    <Label style={{ marginTop: "0.5rem" }}>
                      Transférer au Departement
                    </Label>
                    <Select
                      options={departmentOptions}
                      value={departmentOptions.find(
                        (i) => i.value === department
                      )}
                      onChange={(e) => handleSelectChange(e, "department")}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Button
                  color="primary"
                  block
                  style={{
                    borderRadius: "50px",
                    margin: "1rem 1rem 0rem 1rem",
                    height: "35px",
                  }}
                  onClick={handleSubmit}
                >
                  {!loading ? "Enregister" : <Spinner size="sm" />}
                </Button>
              </Row>
            </>
          )}
          {this.state.activeTab === "moreactions" &&
            type_for_lead !== "lead" && (
              <>
                <div
                  style={{ height: "auto" }}
                  className="p-l-10 p-r-10 p-t-10 p-b-10 element-wrapper"
                >
                  <iframe
                    src={
                      NDIWebsite +
                      "/zendesk/" +
                      ticket_id +
                      "/ticket?ifr=neosales&limTab=3"
                    }
                    id="iframeNDIchild0007qx"
                    style={{ width: "100%", minHeight: "380px", border: "0" }}
                  ></iframe>
                </div>
              </>
            )}
          {this.state.activeTab === "dossier" && type_for_lead !== "lead" && (
            <>
              <div
                style={{ height: "auto" }}
                className="p-l-10 p-r-10 p-t-10 p-b-10 element-wrapper"
              >
                <iframe
                  src={
                    NDIWebsite +
                    "/zendesk/" +
                    ticket_id +
                    "/ticket?ifr=neosales&limTab=3"
                  }
                  id="iframeNDIchild0007qx"
                  style={{ width: "100%", minHeight: "500px", border: "0" }}
                ></iframe>
              </div>
            </>
          )}
          {this.state.activeTab === "macros" && (
            <>
              <Label style={{ marginTop: "0.5rem" }}>{"Macro"}</Label>
              <Select
                style={{ marginBottom: "1rem" }}
                placeholder={"Macro"}
                value={macrosOptions.find((i) => i.value === this.state.macro)}
                options={macrosOptions}
                onChange={(e) => this.handleMacroChange(e)}
              />
              <ReactQuill
                className="contenteditable"
                style={{
                  marginTop: "1rem",
                  maxHeight: "375px",
                  overflowY: "scroll",
                }}
                name={"notre_dame_des_eclaireurs"}
                theme="snow"
                modules={{ toolbar: toolbarOptions }}
                value={this.state.macroText || ""}
                onChange={(e, a, b, c) => {
                  let clean = e.replace(QL_CURSOR, "");
                  this.setState({ macroText: clean });
                }}
              />
              <Button
                color="primary"
                block
                style={{
                  borderRadius: "50px",
                  margin: "1rem 1rem 0rem 1rem",
                  height: "35px",
                }}
                onClick={this.handleMacroPost}
              >
                {!this.state.macroLoading ? "Envoyer" : <Spinner size="sm" />}
              </Button>
            </>
          )}
          {this.state.activeTab === "notes" && (
            <>
              <div
                className="messageArchiveContainer"
                style={{
                  marginBottom: "0.5rem",
                  marginTop: "1rem",
                  maxHeight: "450px",
                  overflowY: "scroll",
                }}
              >
                {this.state.notes.length ? (
                  <Notes notes={notes} />
                ) : (
                  <div style={{ color: "#0052bb" }}>
                    {`${genLang("downloading")}... `}
                    <Spinner style={{}} />
                  </div>
                )}
              </div>
            </>
          )}
          {this.state.activeTab === "docs" && (
            <GeneratedDocs
              ticket_id={this.props?.selectedLead?.ticket_id ?? ""}
              genLang={genLang}
              generatedDocs={this.state.generatedDocs}
            />
          )}
        </ModalBody>
        <Toast
          type={this.state.toast.type}
          message={this.state.toast.message}
          clear={() => this.setState({ toast: { type: "", message: "" } })}
          autoHide
        />
      </Modal>
    );
  }
}

function GeneratedDocs({ generatedDocs, genLang, ticket_id }) {
  const [previewUrl, setPreviewUrl] = useState("");

  const handleDownload = async (url, filename = "file") => {
    if (url.includes("zendesk") && !url.includes("docx")) {
      let blob = await fetch(url).then((r) => r.blob());
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    } else {
      console.log(url);
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    }
  };

  const handleOpenInPSJ = (filename) => {
    let name = ((filename?.split("/") ?? []).pop() ?? "").split(".");
    name.pop();
    name = name.join(".");
    const file_url = `https://www.neodesk.app/folder/${ticket_id}?file_names=${name}`;
    window.open(file_url);
  };

  if (!generatedDocs.length)
    return (
      <div
        style={{ textAlign: "center", fontStyle: "italic", marginTop: "1rem" }}
      >
        {genLang("noGeneratedDocs")}
      </div>
    );
  return (
    <>
      <div className="scrollbar-visible" style={{ maxHeight: "450px" }}>
        {generatedDocs.map((i) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              marginTop: "0.5rem",
            }}
          >
            <div
              style={{ fontWeight: "600", color: "#0052bb", fontSize: "14px" }}
            >
              {i?.filename}
            </div>
            <IconButton
              icon={faEye}
              tooltip={genLang("preview")}
              style={{ color: "#0052bb" }}
              onClick={() => setPreviewUrl(i?.preview_url)}
            />
            <IconButton
              icon={faDownload}
              tooltip={genLang("download")}
              style={{ color: "#0052bb" }}
              onClick={() => handleDownload(i.download_url, i.filename)}
            />
            <IconButton
              icon={faExternalLink}
              tooltip={genLang("openInPSJ")}
              style={{ color: "#0052bb" }}
              onClick={() => handleOpenInPSJ(i.filename)}
            />
          </div>
        ))}
      </div>
      <Modal isOpen={previewUrl} size="xl" centered zIndex={99999}>
        <ModalHeader toggle={() => setPreviewUrl("")}>
          {genLang("preview")}
        </ModalHeader>
        <ModalBody style={{ height: "80vh" }}>
          {
            <iframe
              key={previewUrl}
              seamless
              src={previewUrl}
              loading="lazy"
              frameBorder="0"
              scrolling="auto"
              height="100%"
              width="100%"
            />
          }
        </ModalBody>
      </Modal>
    </>
  );
}

function ChangePSJAssign({ options, selectedLead, setToast, genLang }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    const { label, value } = selectedOption ?? "";
    const rawdata = { agentID: value };
    const str = genLang2({
      en: "Assignment changed by administrator in Sales Portal",
      fr: "Assignation modifiée par l'administrateur dans portail des ventes",
    });

    axios
      .post(BACKEND1 + "/leads/lead", {
        id: selectedLead?.id ?? "",
        actionid: "op-02",
        note: str,
        rawdata: rawdata,
      })
      .then((status) => {
        let { data, statusCode } = status.data;
        if (statusCode === 200) {
          setToast(
            "success",
            genLang2({
              en: "Assignation changed!",
              fr: "Assignation modifiée!",
            })
          );
          setIsOpen(false);
        }
      })
      .catch(function (error) {
        setToast("error", genLang2({ en: "Error", fr: "Erreur" }));
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <IconButton
        icon={faExchange}
        tooltip={genLang("changeAssign")}
        style={{ color: "#0052bb" }}
        onClick={() => setIsOpen(true)}
      />
      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={() => setIsOpen(false)}>
          {genLang("changeAssign")}
        </ModalHeader>
        <ModalBody>
          <Row>
            <div style={{ fontStyle: "italic", margin: "0 0 1rem 0.6rem" }}>
              {genLang2({
                en: "This will change the file assignation in the PSJ",
                fr: "Cela changera l'assignation du dossier dans le PSJ",
              })}
            </div>
          </Row>
          <Row style={{ alignItems: "flex-end" }}>
            <Col sm="9">
              <Label>
                {genLang2({ en: "Assignation", fr: "Assignation" })}
              </Label>
              <Select
                options={options}
                onChange={(e) => setSelectedOption(e ?? null)}
              />
            </Col>
            <Col sm="3">
              <Button
                isDisabled={!selectedOption}
                onClick={handleSubmit}
                color="primary"
                style={{ height: "38px", minWidth: "90px" }}
              >
                {loading ? <Spinner size="sm" /> : genLang("register")}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
