import axios from 'axios';
import { encryptText, decryptText } from './appEncryption';
import history from './history';
import { setLoggedUser } from './redux/actions';
export const axiosDefaults = function (config) { 
    // Global axios defaults
	
	//console.log("AUTHORIZATION "); 
   const token = localStorage.token;
   //const rawUser = localStorage.user; //decryptText(localStorage.upass)
   const tokenRid = localStorage.rid;
   let user=null;
   if(localStorage.getItem("user")!==undefined  && localStorage.getItem("user")!==null){
		user=(decryptText(localStorage.getItem("user"))).split(':');
   } 
   
   if(config!==undefined && config!==null && config.noheader==="1"){ 
		//delete axios.defaults.headers.common["auth-id"];
   }
   else if(token!==undefined && token!==null){  
		const token2 = decryptText(token);
		// 
	   axios.defaults.headers.common['Authorization'] = "bearer "+token2;
	   if(user!==null){
		axios.defaults.headers.common['auth-id'] = user[4];
	   } 
	   
	   if(tokenRid!==undefined && tokenRid!==null){
		   //axios.defaults.headers.common['rid'] = tokenRid;
	   } 
	   
	   axios.defaults.headers.post['Content-Type'] = 'application/json';
	   axios.defaults.headers.post['Accept'] = 'application/json';
	   ////axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
	   
	   axios.defaults.headers.get['Content-Type'] = 'application/json';
	   axios.defaults.headers.get['Accept'] = 'application/json';
	   //////////axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
	   
	   //axios.defaults.headers.common['withCredentials'] = true;
   }
	
	//axios.defaults.baseURL = 'https://api.example.com';
	//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
	//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
	//const instance = axios.create();

	axios.interceptors.response.use(function (response) {
		return response;
	}, function (error) {
		//console.log(error);
		if(error!==undefined && error!==null){
			console.log("INTERCEPTOR//"); console.log(error); console.log("//interceptor");
			if (error.response!==undefined && error.response.status!==undefined && 401 === error.response.status) {
				//window.location = '/login';
				//console.log("INTERCEPTOR// going to login *********");
				history.push(`./login`);
				return Promise.reject(error);
			} else {
				console.log("INTERCEPTOR// error *********"); console.log(error);
				return Promise.reject(error);
				//return null;
			}
		}
	});

};


export const getTokenAndId = () => {	
	const token = decryptText(localStorage.token);
	  ////console.log("###******* TOKEN SAVED "+token);  
	const tokenRid = localStorage.rid; 
	const profile = decryptText(localStorage.profile);
	//console.log("###******* PROFILE DEC  "+profile);
	if(profile===undefined || profile===null || profile==""){ 
	  //return dispatch(loginUser(null));  
	  //return null; 
	}
	//const profileLoc=JSON.parse(profile); 

	let user=null;  let userID=0;
	if(localStorage.getItem("user")!==undefined  && localStorage.getItem("user")!==null){
			user=(decryptText(localStorage.getItem("user"))).split(':');
	} 
   
	if(user!==null){
		userID = user[4];
	} 
	
	// if(profileLoc.id >0){
	//   let neoState = {
	// 	currentUser: profileLoc,
	// 	id: profileLoc.id, name: profileLoc.firstname+' '+profileLoc.lastname, role: profileLoc.user_type, 
	// 	isAuthenticated:true
	//   }; 
	//   //console.log("weeeeeeeeee return local profile....");
	//   //return dispatch(loginUser(neoState));
	// }
	  
	//   if (token) {
		
	// 		//console.log("//////******* #2 SUCCESS "+data.user.id);  
	// 	//localStorage.setItem("token", data.token);
	// 		let neoState = {
	// 		currentUser: data.user,
	// 		id: data.user.id, name: data.user.name, role: data.user.type, 
	// 		isAuthenticated:true
	// 		};
	// 	//return dispatch(loginUser(data.user));  
	return { userid:userID,  token: token, };		
  };
