import React from "react";
import Select from 'react-select'
import InputMask from 'react-input-mask';
import {
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  CardTitle,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  FormGroup,
  Input,
  InputGroup,
  Label,
  InputGroupAddon,
  NavLink,
  ButtonGroup,
  Form,
  Button,
  CardHeader,
  // Table
} from "reactstrap";

import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCoins,
  faFlag,
  faTachometerAlt,
  faTags,
  faEdit,
  faAt,
  faInfoCircle,
  faEnvelope,
  faMeh,
  faFilter,
  faLanguage,
  faGlobeAmericas,
  faPlusCircle,
  faPhone,
  faUserSecret,
  faHome,
  faUserCircle,
  faMale,
  faUserPlus,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";

import UserIcon from "./UserIcon";
import DashboardElement from "../../../../utils/DashboardElement";

import rootsActions from "../../../../_actions/root-actions";
import _ from "lodash";

import { connect } from "react-redux";

import { BeatLoader } from "react-spinners";
//import { Font } from "@react-pdf/renderer";
const BACKEND1 = process.env.REACT_APP_API_URLX;

const userFields = [
  "clientLastName",
  "clientName",
  "clientEmail",
  "clientAddress",
  "clientPhone",
  "clientTitle", //mr, mme, dr...
];

// const purchases = [
//   { date: "Feb 25, 2021", item: "Item 1" },
//   { date: "March 25, 2021", item: "Item 2" },
//   { date: "April 25, 2021", item: "Item 3" },
//   { date: "June 25, 2021", item: "Item 4" },
// ];

class UserDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conslict: "",
      conflicts: [],
      conflictsZendesk: [],
      searched: false,
      submitted: false,
      postError: false,
      formData: {
        id: '',
        firstname: '',
        lastname: '',
        cell: '',
        email: '',
        language: '',
        province: '',
      },
      showEditContact: false
    };

    this.postConflict = this.postConflict.bind(this);
    this.searchConflict = this.searchConflict.bind(this);
  }

  componentDidMount(){
    const provinceOptions = [{label: 'Québec', value: 'QC'}, {label: 'Ontario', value: 'ON'}]
    const languageOptions = [{label: 'Français', value: 'fr'}, {label: 'English', value: 'en'}]
    this.setState({provinceOptions: provinceOptions, languageOptions: languageOptions})
  }
  handleSelectChange = (e, field) => {
    this.setState(prevState => ({
        ...prevState, formData: {...prevState.formData, [field]: e.value}
    }))

  }

  handleChange = (e) => {
    e.persist();
    (async () => {
   await this.setState((prevState) => {
       return {...prevState, formData: {...prevState.formData,
            [e.target.name]: e.target.value}}
        });
    })();  
  }

  toggleEditContact = () => {
    this.setState((prevState) => {
      const {firstname, lastname, cell, email, language, province, id } = this.props.customer
        return {...prevState, showEditContact: true, 
            formData: {...prevState.formData, firstname: firstname, lastname: lastname, 
                cell: cell, email: email, language: language, id: id, province: province}}
    })
  }

  handleEditContact = () => {
    const {firstname, lastname, cell, language, province, domaine, subject,} = this.state.formData;
    var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    this.setState({submitted: true});
    let isValid = false;
    if (firstname && lastname && phoneRegex.test(cell) === true && language && province) isValid = true
    console.log(isValid)
    if (!isValid) this.setState({postError: true})
    if (isValid){
        this.setState({modalLoading: true, postError: false})
        const obj = {
                    id: this.state.formData.id,
                    firstname: this.state.formData.firstname,
                    lastname: this.state.formData.lastname,
                    cell: this.state.formData.cell,
                    email: this.state.formData.email,
                    domain: this.state.formData.domaine,
                    subject: this.state.formData.subject,
                    language: this.state.formData.language,
                    province: this.state.formData.province
                }
                console.log(obj)
    axios.post(
        BACKEND1+'/contacts',
        obj,
        {
            params:{
                //src:"desk"
            },
            // headers: { 
            //     'Content-Type': 'application/json',
            //     // 'x-jwt-token': localStorage.getItem('token'),
            //     'auth-id': localStorage.getItem('user_id'),
            //     'Authorization':"Bearer "+localStorage.getItem('token')
            // }
        }
    ).then((res) => {
        if (res.data.statusCode === 200){
            console.log(res.data);
                console.log(res.data.data.record)
                const updatedCustomer = {...this.props.customer, ...res.data.data.record}
                console.log(updatedCustomer)
                this.props.setCustomer(updatedCustomer);
                this.setState({showEditContact: false, modalLoading: false});
        }
        if (res.data.statusCode === 400){
            this.setState({postErrorMsg: res, modalLoading: false})
            console.log(res);
        }
    })
  }
  }

  renderContactInfo = () => {
    const {firstname, lastname, cell, email, province, language} = this.props.customer
    //const lang = language === 'fr' ? this.genLang('french') : lang === 'en' ? this.genLang('english') : '';
    const lang = language === 'fr' ? this.genLang('french') : language === 'en' ? this.genLang('english') : '';
       
    return (
        <div>
            {/* <div>
                <FontAwesomeIcon icon={faUserCircle} color={"#0052bb"}/>{` ${firstname} ${lastname}`}
            </div> */}
            <div style={{marginTop: '0.3rem',}}>
              <b style={{ color: "#0052bb" }}><FontAwesomeIcon icon={faPhone} color={"#0052bb"}/>{` ${typeof cell === 'string' ? cell : ''}`}</b>
            </div>
            <div style={{marginTop: '0.3rem'}}>
              <b style={{ color: "#0052bb" }}><FontAwesomeIcon icon={faEnvelope} color={"#0052bb"}/>{` ${typeof email === 'string' ? email : ''}`}</b>
            </div>
            <div style={{marginTop: '0.3rem'}}>
              <b style={{ color: "#0052bb" }}><FontAwesomeIcon icon={faLanguage} color={"#0052bb"}/>{` ${typeof lang === 'string' ? lang : ''}`}</b>
            </div>
            <div style={{marginTop: '0.3rem'}}>
              <b style={{ color: "#0052bb" }}><FontAwesomeIcon icon={faGlobeAmericas} color={"#0052bb"}/>{` ${!province ? '' : province}`}</b>
            </div>
        </div>
    )
}

  searchConflict() {
    ///////////////////////////////////////////////
    // icite pour demain
    ///////////////////////////////////////////////
    axios
      .get(BACKEND1+"/clients/searchconflicts", {
        params: {
          ////src: "desk",
          search: this.state.conflict,
          // byfield:''
          // id:this.props.id
          // page:2,
          // limit:6,
          // newlead:1 pour obtenir des un nouveau lead
        },
        // headers: {
        //   "Content-Type": "application/json",
        //   // 'x-jwt-token': localStorage.getItem('token'),
        //   "auth-id": localStorage.getItem("user_id"),
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // },
      })
      .then((status) => {
        // debugger;
        let { data } = status.data;
        // debugger;
        // console.log(data);
        if (data) {
          //   debugger;
          let arr = [];
          for (let n in data.clients) {
            // console.log('je n\'ai plus d\'énergie',data[n])
            if (data.clients[n]) arr.push(data.clients[n]);
          }

          let arr2 = [];
          // debugger
          for (let z in data.zendesk) {
            // console.log('je n\'ai plus d\'énergie',data[n])
            if (data.zendesk[z]) arr2.push(data.zendesk[z]);
          }

          this.setState(
            {
              conflicts: data.clients,
              conflictsZendesk: data.zendesk,
              searched: true,
            },
            () => {
              this.props.setConflict("conflict");
            }
          );
        }
      });

    // https://api.neolegal.ca/clients/searchconflicts?search=jacob@poireir.fr&byfield=
    //         search: jacob@poireir.fr
    // byfield:
  }

  postConflict(e, conflicts, conflictsZendesk, action) {
    // debugger;
    //         POST
    // /api/search/searchconflicts
    // const searchResult={clients: conflictList.clients, zendesk:conflictList.zendesk};
    // const NeoSearch = {id: id, search: search, filter: filter, result: searchResult, actions: actions, ticket_id: ticket_id, client_id: client_id, search_type: search_type, actionStatus:actionName,}

    axios
      .post(
        BACKEND1+"/search/searchconflicts",
        {
          // ...e,
          id: 0, //parseInt(e.id),
          search: e.search,
          filter: e.filter || "",
          actions: e.actions || "",
          ticket_id: e.ticket_id,
          client_id: "15" || e.client_id,
          search_type: "conflict",
          search: this.state.conflict,
          // actionStatus:"infona"||"notap"||"conflict",
          actionStatus: action,
          result: {
            clients: conflicts,
            //     :[
            //     {case_id: "25852",
            //     // '0':{cell: "",
            //     //     dataleft_id: "987910",
            //     //     details: "Nom de Compagnie: N/A<br/>Autre Nom de Compagnie: <br/>NEQ: <br/>Adresse: b-109 Chemin Riverside Ouest, Listuguj<br/>Ville: Listuguj<br/>Code Postal: G0C 2R0<br/>Province: QC<br/>Pays: Canada<br/>Téléphone: <br/>Extension: <br/>",
            //     //     email: "",
            //     //     firstname: "",
            //     //     lastname: "N/A",
            //     //     ticket_id: "423743"},
            //     cell: "",
            //     dataleft_id: "987910",
            //     detExisted: "164",
            //     email: "",
            //     firstname: "",
            //     lastname: "N/A",
            //     ticket_id: "423743"}
            // ],
            // clients:conflicts,
            zendesk: conflictsZendesk,
          },
          ticket_id: "599761",
          //"id":0,"search":"","filter":"","result":{},"actions":"","ticket_id":"875816","client_id":"3115","search_type":"conflict","actionStatus":"notap"
        },
        {
          params: {
            ////src: "desk",
            // byfield:''
            // id:this.props.id
            // page:2,
            // limit:6,
            // newlead:1 pour obtenir des un nouveau lead
          },
          // headers: {
          //   "Content-Type": "application/json",
          //   // 'x-jwt-token': localStorage.getItem('token'),
          //   "auth-id": localStorage.getItem("user_id"),
          //   Authorization: "Bearer " + localStorage.getItem("token"),
          // },
        }
      )
      .then((status) => {
        let { data } = status.data;

        this.props.setConflict(action);

        // quoi faire on success???

        // if(data){
        //     //   debugger;
        //     let arr = [];
        //     for(let n in data.clients){
        //         // console.log('je n\'ai plus d\'énergie',data[n])
        //         if(data.clients[n])
        //             arr.push(data.clients[n])
        //     }

        //     console.log('Merci seigneur',arr)

        //     this.setState({
        //         conflicts:arr
        //     })
        //     }
      });
  }

  genLang = (text) => {
    const {trad, lang} = this.props;
    return trad['neoleads'][text][lang]
}

  renderCustomer(customer) {
    // customer.clientAddress = "1234 rue des adresses\n Montréal Québec";
    // customer.clientName = "Matthew";
    let arr = _.map(userFields, (e) => {
      switch (e) {
        case "clientEmail":
          return (
            <div
              key={e}
              style={{
                wordBreak: "break-all",
                wordWrap: "break-space",
                width: "100%",
                // whiteSpace:'nowrap'
              }}
            >
              <b style={{ color: "#0052bb" }}>
                <FontAwesomeIcon
                  style={{
                    display: "inline",
                    alignItems: "baseline",
                    fontSize: "10px",
                  }}
                  icon={faEnvelope}
                  color={"#ccc"}
                />{" "}
                {customer[e]}
              </b>
            </div>
          );

        // case "clientName":
        //   return (
        //     <div
        //       key={e}
        //       style={{
        //         wordBreak: "break-all",
        //         wordWrap: "break-space",
        //         width: "100%",
        //         // whiteSpace:'nowrap'
        //       }}
        //     >
        //       <b style={{ color: "#0052bb" }}>
        //         <span style={{ color: "#ccc", fontSize: "10px" }}>
        //           {this.genLang('firstName')}:{" "}
        //         </span>
        //         {customer[e]}
        //       </b>
        //     </div>
        //   );

        // case "clientLastName":
        //   return (
        //     <div
        //       key={e}
        //       style={{
        //         wordBreak: "break-all",
        //         wordWrap: "break-space",
        //         width: "100%",
        //         // whiteSpace:'nowrap'
        //       }}
        //     >
        //       <b style={{ color: "#0052bb" }}>
        //         <span style={{ color: "#ccc", fontSize: "10px" }}>{this.genLang('lastName')} </span>
        //         {customer[e]}
        //       </b>
        //     </div>
        //   );

        // return (<FormGroup><Label>Prénom</Label><Input disabled={true} value={customer[e]}></Input></FormGroup>)

        case "clientPhone":
          return (
            <div
              key={e}
              style={{
                wordBreak: "break-all",
                wordWrap: "break-space",
                width: "100%",
                // whiteSpace:'nowrap'
              }}
            >
              <b style={{ color: "#0052bb" }}>
                <FontAwesomeIcon
                  style={{
                    display: "inline",
                    alignItems: "baseline",
                    fontSize: "10px",
                  }}
                  icon={faPhone}
                  color={"#ccc"}
                />{" "}
                {customer[e]}
              </b>
            </div>
          );
        case "clientAddress":
          return (
            <div
              key={e}
              style={{
                wordBreak: "break-all",
                wordWrap: "break-space",
                width: "100%",
                // whiteSpace:'nowrap'
              }}
            >
              <b style={{ color: "#0052bb" }}>
                <FontAwesomeIcon
                  style={{
                    display: "inline",
                    alignItems: "baseline",
                    fontSize: "10px",
                  }}
                  icon={faHome}
                  color={"#ccc"}
                />{" "}
                {customer[e]}
              </b>
            </div>
          );

        default:
        // arr.push(<li>{customer[c]}</li>)
      }
    });

    // for(let c in customer){
    //     if(customer[c]!==''){
    //         switch(c){
    //             case 'clientEmail':
    //                 arr.push(<FormGroup><Label>Courriel</Label><Input disabled={true} value={customer[c]}></Input></FormGroup>)
    //                 break;
    //             case 'clientName':
    //                 arr.push(<FormGroup><Label>Nom de Famille</Label><Input disabled={true} value={customer[c]}></Input></FormGroup>)
    //                 break;

    //             case 'clientFistname':
    //                 arr.push(<FormGroup><Label>Prénom</Label><Input disabled={true} value={customer[c]}></Input></FormGroup>)
    //                 break;
    //             case 'clientPhone':
    //                 arr.push(<FormGroup><Label>Cellulaire</Label><Input disabled={true} value={customer[c]}></Input></FormGroup>)
    //                 break;
    //             case 'clientFullName':
    //                 // arr.push(<li>{customer[c]}</li>)
    //                 break;
    //             default:
    //                 // arr.push(<li>{customer[c]}</li>)
    //                 break;

    //         }
    //     }
    // }

    return (
      <Form
        style={{
          listStyle: "none",
        }}
      >
        {arr}
      </Form>
    );
  }

  renderPurchases(arr = []) {
    if (arr.length === 0){
      return <div
      style={{
        wordBreak: "break-all",
        wordWrap: "break-space",
        width: "100%",
      }}
    >
      <div style={{ color: "#0052bb", marginBottom: '0.5rem'}}>{this.genLang('noPurchases')}</div>
    </div>
    } 
    return arr.map((p, i) => {
      return (
        <div
          key={i}
          style={{
            wordBreak: "break-all",
            wordWrap: "break-space",
            width: "100%",
            // whiteSpace:'nowrap'
          }}
        >
          {/* <div style={{ color: "#0052bb" }}>{`${p.item} - ${p.date}`}</div> */}
          <div style={{ color: "#0052bb" }}>{`${i + 1}) ${p}`}</div>
        </div>
      );
    })
  }
  renderConflict({ conflicts = [], conflictsZendesk = [] }) {
    let arr = _.map(conflicts, (e) => {
      return (
        <Card className="m-t-15">
          <CardHeader
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState((state) => {
                let found = _.findIndex(state.conflicts, (f) => {
                  return f.id === e.id;
                });

                if (found >= 0) {
                  state.conflicts[found].display =
                    !state.conflicts[found].display ||
                    state.conflicts[found].display === "none"
                      ? "block"
                      : "none";
                }
                return state;
              });
            }}
          >
            <h6 style={{ textAlign: "center" }}>
              {e.firstname} {e.lastname}{" "}
            </h6>
          </CardHeader>
          <CardBody style={{ display: e.display || "none" }}>
            <b style={{ color: "#0052bb" }}>
              <FontAwesomeIcon
                style={{
                  display: "inline",
                  alignItems: "baseline",
                  fontSize: "10px",
                }}
                icon={faEnvelope}
                color={"#ccc"}
              />{" "}
              {e.email}
            </b>
            <br />
            <b style={{ color: "#0052bb" }}>
              <FontAwesomeIcon
                style={{
                  display: "inline",
                  alignItems: "baseline",
                  fontSize: "10px",
                }}
                icon={faMale}
                color={"#ccc"}
              />{" "}
              {e.occupation}
            </b>
            <br />
            <b style={{ color: "#0052bb" }}>
              <FontAwesomeIcon
                style={{
                  display: "inline",
                  alignItems: "baseline",
                  fontSize: "10px",
                }}
                icon={faHome}
                color={"#ccc"}
              />{" "}
              {e.address}
            </b>
            <br />
          </CardBody>
        </Card>
      );
    });
    return (
      <div>
        <h6 className="element-header">Neolegal</h6>
        {arr}
      </div>
    );
  }

  renderZdConflict({ conflicts = [], conflictsZendesk = [] }) {
    console.log("zd conflict", conflictsZendesk);
    let arr = _.map(conflictsZendesk, (e) => {
      return (
        <Card className="m-t-15">
          <CardHeader
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState((state) => {
                let found = _.findIndex(state.conflicts, (f) => {
                  return f.id === e.id;
                });

                if (found >= 0) {
                  state.conflicts[found].display =
                    !state.conflicts[found].display ||
                    state.conflicts[found].display === "none"
                      ? "block"
                      : "none";
                }
                return state;
              });
            }}
          >
            <h6 style={{ textAlign: "center" }}>
              {e.firstname} {e.lastname}{" "}
            </h6>
          </CardHeader>
          <CardBody style={{ display: e.display || "none" }}>
            <b style={{ color: "#0052bb" }}>
              <FontAwesomeIcon
                style={{
                  display: "inline",
                  alignItems: "baseline",
                  fontSize: "10px",
                }}
                icon={faEnvelope}
                color={"#ccc"}
              />{" "}
              {e.email}
            </b>
            <br />
            <b style={{ color: "#0052bb" }}>
              <FontAwesomeIcon
                style={{
                  display: "inline",
                  alignItems: "baseline",
                  fontSize: "10px",
                }}
                icon={faMale}
                color={"#ccc"}
              />{" "}
              {e.occupation}
            </b>
            <br />
            <b style={{ color: "#0052bb" }}>
              <FontAwesomeIcon
                style={{
                  display: "inline",
                  alignItems: "baseline",
                  fontSize: "10px",
                }}
                icon={faHome}
                color={"#ccc"}
              />{" "}
              {e.address}
            </b>
            <br />
          </CardBody>
        </Card>
      );
    });
    return (
      <div>
        <h6 className="element-header">Zendesk</h6>
        {arr}
      </div>
    );
  }

  renderName = () => {
    const {firstname, lastname} = this.props.customer;
    if(this.props.customer.type === 'client') {
      return " " + this.props.customer.clientFullName;
    }
      
    return `${!firstname ? '' : firstname} ${!lastname? '' : lastname}`
  }

  render() {
    console.log(this.props.customer)
    var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    return (
      <>
        <div className="p-15 grayish">
          <div className="p-l-10 p-r-10 p-t-10 p-b-10 element-wrapper">
            <h6
              className={"element-header"}
              style={{ color: "#0052bb", marginBottom: "1em" }}
            >
              <FontAwesomeIcon icon={faUserCircle} />{" "}
              {this.renderName()}
              {this.props.customer.type === 'contact' && <Button size='sm' outline color="primary" style={{float: 'right'}}
                onClick={() => this.toggleEditContact()}
              >
                {`${this.genLang('edit')} `}<FontAwesomeIcon icon={faEdit}/>
              </Button>}
              {/* {this.props.customer.type === 'client' ? " " + this.props.customer.clientFullName : this.props.customer.firstname + " " + this.props.customer.lastname} */}
              {/* // {" " + this.props.customer && this.props.customer.clientFullName */}
              {/* //   ? this.props.customer.clientFullName
              //   : this.props.customer.firstname + " " + this.props.customer.lastname} */}
            </h6>
            {/* <div className="userIcon left"> */}
            {/* <div className='profile' style={{
                                backgroundSize:!window.location.host.match(/localhost/)?'80%':'110%',
                                backgroundImage:!window.location.host.match(/localhost/)?'url("http://localhost:3000/static/media/thonsid.6988f987.png")':'url("https://www.w3schools.com/w3images/avatar6.png")',
                                backgroundPositionX:!window.location.host.match(/localhost/)?'5px':'0'
                            }} /> */}
            {/* <div className='userTitle'>{this.props.customer&&this.props.customer.clientFullName?this.props.customer.clientFullName:'N/A'}</div>
                        <hr></hr> */}
            {this.props.customer.type === 'client' ? this.renderCustomer(this.props.customer): this.renderContactInfo()}
            
          </div>
        </div>

        {/* {this.props.customer.type === 'client' && <div className="p-15 m-t-15 grayish">
          <div className="p-l-10 p-r-10 p-t-10 p-b-10 element-wrapper"> 
            <h6
              className={"element-header"}
              style={{ color: "#0052bb", marginBottom: "1em" }}
            >
              <FontAwesomeIcon icon={faTags} />{`${this.genLang('clientPurchases')} `}
            </h6>
            {this.renderPurchases(this.props.customer.transactions)} 
          </div>
        </div>} */}
        
        {/* HEADER OF CONFLIT D'INTERETS */}
        {/* <div className="p-15 m-t-15 grayish">
                    <div className="p-l-10 p-r-10 p-t-10 p-b-10 element-wrapper" > */}

        {/* <h6 className={"element-header"} style={{color:"#0052bb",marginBottom:'1em'}}><FontAwesomeIcon icon={faSearch} /> Conflit d'intérêts</h6> */}

        {/* <ButtonGroup >
                                
                            
                            <Button 
                                title={"approuver"}
                                type={"button"}
                                onClick={(e) => {this.postConflict(e,this.state.conflicts,this.state.conflictsZendesk,'conflict')}}
                                color={"danger"}
                                disabled={!this.state.conflicts.length&&!this.state.conflictsZendesk.length}
                            >
                                <FontAwesomeIcon icon={faFlag} />
                            </Button>
                            <Button 
                            
                                title={"Conflit"}
                                type={"button"}
                                onClick={(e) => {this.postConflict(e,this.state.conflicts,this.state.conflictsZendesk,'noconflict')}}
                                color={"success"}
                                disabled={!this.state.searched}
                            >
                                <FontAwesomeIcon icon={faUserPlus} />
                            </Button>
                            <Button 
                            
                                title={"aucun résultats"}
                                type={"button"}
                                onClick={(e) => {this.postConflict(e,this.state.conflicts,this.state.conflictsZendesk,'infona')}}
                                color={"warning"}
                                // style={{color:'#000'}}
                                // disabled={!this.state.searched}

                            >
                                <b style={{color:'white',fontWeight:'bolder'}}>=</b>
                                {/* <FontAwesomeIcon icon={faMeh} color="white" /> */}
        {/* </Button>

                            <Button 
                                
                                title={"Non applicable"}
                                type={"button"}
                                onClick={(e) => {this.postConflict(e,this.state.conflicts,this.state.conflictsZendesk,'notap')}}
                                color={"primary"}
                                // disabled={!this.state.searched}
                            >
                                <b>N/A</b>
                                {/* <FontAwesomeIcon icon={faUserPlus} /> */}
        {/* </Button> */}
        {/* </div></ButtonGroup>*/}

        {/* BODY OF CONFLIT D'INTERETS */}
        {/* <FormGroup>
                            <Label>Rechercher</Label>
                            <Input
                                type="text"
                                onChange={(e) => {
                                    this.setState({conflict:e.target.value},() => {
                                        if(this.timeout)
                                            clearTimeout(this.timeout);

                                        this.timeout = setTimeout(() => {
                                            this.searchConflict()
                                        },500)
                                    }
                                )}}
                                value={this.state.conflict}
                            />
                            {/* <Button 
                                color="primary" 
                                type="button" 
                                size="lg"
                                onClick={this.searchConflict} 
                                block
                            >
                                <FontAwesomeIcon icon={faUserSecret} />
                            </Button>*/}
        {/* </FormGroup> */}
        {/* <div style={{maxHeight:"300px",overflowY:'scroll'}}>
                            {this.renderConflict({conflicts:this.state.conflicts,conflictsZendesk:this.state.conflictsZendesk})}
                        </div> */}
        {/*<div style={{maxHeight:"300px",overflowY:'scroll'}}>
                        //     {this.renderZdConflict({conflicts:this.state.conflicts,conflictsZendesk:this.state.conflictsZendesk})}
                        // </div> */}
        {/* </div> */}

        {/* // </div> */}
        {this.state.showEditContact && 
                    <Modal centered={true} isOpen={this.state.showEditContact}>
                        <ModalHeader onClick={() => console.log(this.state)} toggle={() => this.setState({showEditContact: false})}>
                            {this.genLang('editContact')}
                        </ModalHeader>

                        <ModalBody>
                            {this.state.postError && this.state.submitted && <Alert color='danger'><FontAwesomeIcon icon={faInfoCircle}/>{` ${this.genLang('validationError')}`}</Alert>}

                            <Label>{this.genLang('firstName')}</Label>
                            <Input name='firstname' value={this.state.formData.firstname} 
                                invalid={this.state.submitted && !this.state.formData.firstname}
                                type='text' onChange={(e) => this.handleChange(e)} 
                            />

                            <Label style={{marginTop: '0.5rem'}}>{this.genLang('lastName')}</Label>
                            <Input name='lastname' value={this.state.formData.lastname} 
                                invalid={this.state.submitted && !this.state.formData.lastname}
                                type='text' onChange={e => this.handleChange(e)}
                            />

                            <Label style={{marginTop: '0.5rem'}}>{this.genLang('phone')}</Label>
                            <InputMask 
                                mask="(999) 999-9999"
                                name='cell'
                                value={this.state.formData.cell}
                                onChange={e => {
                                    this.handleChange(e)
                                    console.log(phoneRegex.test(this.state.formData.cell))
                                }}
                                className={`${this.state.submitted && phoneRegex.test(this.state.formData.cell) === false ? 'form-control is-invalid' : 'form-control'}`}
                            />

                            <Label style={{marginTop: '0.5rem'}}>{this.genLang('email')}</Label>
                            <Input name='email' value={this.state.formData.email} type='text' onChange={e => this.handleChange(e)}/> 

                            <Label style={{marginTop: '0.5rem'}}>Province</Label>
                            <Select options={this.state.provinceOptions}
                                value={this.state.provinceOptions.find(i => i.value === this.state.formData.province)} 
                                // styles={this.state.submitted && !this.state.formData.province ? inValidSelectStyle : {}}
                                onChange={e => this.handleSelectChange(e, 'province')} 
                            />

                            <Label style={{marginTop: '0.5rem'}}>{this.genLang('language')}</Label>
                            <Select options={this.state.languageOptions}
                                value={this.state.languageOptions.find(i => i.value === this.state.formData.language)} 
                                // styles={this.state.submitted && !this.state.formData.language ? inValidSelectStyle : {}}
                                onChange={e => this.handleSelectChange(e, 'language')} 
                            />
                        </ModalBody>

                        <ModalFooter>
                            <Button onClick={() => this.setState({showEditContact: false})} outline color='danger'>
                            {this.genLang('cancel')}
                            </Button>
                            <Button onClick={this.handleEditContact}
                                outline color='primary'>
                                {this.state.modalLoading ? 
                                    <div>
                                        {this.genLang('loading')}
                                        <Spinner color="primary" size="sm" />
                                    </div> : 
                                    <div>
                                        {this.genLang('register')}
                                    </div>}
                            </Button>
                        </ModalFooter>
                    </Modal>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  rootsActions: rootsActions,
  customer: state.customer,
});

const mapActionsToProps = {
  setCustomer: rootsActions.customersActions.setUser,
  setConflict: rootsActions.customersActions.setConflict,
};

export default connect(mapStateToProps, mapActionsToProps)(UserDetails);
