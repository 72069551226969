export const DISPLAY_SPINNER   = 'Spinner:display';
export const HIDE_SPINNER   = 'Spinner:hide';

export function display(){
    return {
        type:DISPLAY_SPINNER
    }
}

export function hide(){
    return {
        type:HIDE_SPINNER
    }
}