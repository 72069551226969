import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_ZENDLEADS = 'GET_ZENDLEADS';
export const ADD_ZENDLEADS = 'ADD_ZENDLEADS';
export const RECEIVE_ZENDLEADSX = 'RECEIVE_ZENDLEADSX';
export const REMOVE_ZENDLEADS = 'REMOVE_ZENDLEADS';
export const UPDATE_ZENDLEADS = 'UPDATE_ZENDLEADS';
export const REPLACE_ZENDLEADS = 'REPLACE_ZENDLEADS';
// const apiUrl = API_DOMAIN + '/zendleads'; 
const apiUrl = API_DOMAIN + '/zendleads/leads'; 
// /zendleads/leads/list

axiosDefaults(null);

export const getZendLeadsAll = (obj) => {
	// xtrapar={status:"1", ticket_type:"lead"};
	let status="1"; let ttype="lead"; let prov="";
	let xparam="";
	if(obj!==undefined && obj!==null && obj.status!==undefined && obj.status!==null){ status=obj.status;  }
	if(obj!==undefined && obj!==null && obj.ticket_type!==undefined && obj.ticket_type!==null){ ttype=obj.ticket_type;  }
	if(obj!==undefined && obj!==null && obj.prov!==undefined && obj.prov!==null){ prov=obj.prov;  }
  return (dispatch) => {
    return axios.get(`${apiUrl}/list?status=${status}&ticket_type=${ttype}&prov=${prov}`)
      .then(response => {
		//   dispatch({type: RECEIVE_ZENDLEADS, ZendLeads: response.data.zendleads.data})
		// console.log("weeeeeee ", response.data.data.zendleads);
        return dispatch({type: RECEIVE_ZENDLEADS, ZendLeads: response.data.data.zendleads});
      })
      .catch(error => { console.error(error); });
  };
};

export const addZendLeads = (ZendLeads) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: ZendLeads.id,
		reason: ZendLeads.reason,
		assig_by: ZendLeads.assig_by,
		assig_date: ZendLeads.assig_date,
		assig_to: ZendLeads.assig_to,
		recall_date: ZendLeads.recall_date,
		update_by: ZendLeads.update_by,
		last_update: ZendLeads.last_update,
		p_id: ZendLeads.p_id,
		definition: ZendLeads.definition,
		ticket_id: ZendLeads.ticket_id,
		client_id: ZendLeads.client_id,
		email: ZendLeads.email,
		date_created: ZendLeads.date_created,
		delay: ZendLeads.delay,
		done: ZendLeads.done,
		date_executed: ZendLeads.date_executed,
		result: ZendLeads.result,
		ticket_type: ZendLeads.ticket_type,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_ZENDLEADS, payload: {
			id: data.id,
			reason: data.reason,
			assig_by: data.assig_by,
			assig_date: data.assig_date,
			assig_to: data.assig_to,
			recall_date: data.recall_date,
			update_by: data.update_by,
			last_update: data.last_update,
			p_id: data.p_id,
			definition: data.definition,
			ticket_id: data.ticket_id,
			client_id: data.client_id,
			email: data.email,
			date_created: data.date_created,
			delay: data.delay,
			done: data.done,
			date_executed: data.date_executed,
			result: data.result,
			ticket_type: data.ticket_type,
		}})
      })
      .then(() => {
        history.push("/ZendLeads")
      })
      .catch(error => { console.error(error) });
  };
};
export const getZendLeads = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/list?id=${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data);	
		//   return dispatch({type: RECEIVE_ZENDLEADSX, ZendLeadsOne: response.data.zendleads});	 
        return dispatch({type: RECEIVE_ZENDLEADSX, ZendLeadsOne: response.data.data});
      })
      .catch(error => { 
        console.error(error); 
      });
  };
};
export const getTicketZendLeads = (zendedObj) => {
	const id=zendedObj.id;
	const ticket=zendedObj.ticket;
	const yes=zendedObj.zendeskyes;
	console.log("BEFORE response from server .... "+id); 
	//return axios.get(`${apiUrl}/${id}`)
 return (dispatch) => {
   return axios.get(`${apiUrl}/list?id=${id}&ticket=${ticket}&gotozend=${yes}`)
	 .then(response => {
		 console.log("response from server .... ");  console.log(response.data);	
	   //   return dispatch({type: RECEIVE_ZENDLEADSX, ZendLeadsOne: response.data.zendleads});	 
	   return dispatch({type: RECEIVE_ZENDLEADSX, ZendLeadsOne: response.data.data});
	 })
	 .catch(error => { 
	   console.error(error); 
	 });
 };
};
export const getZendLeadsGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/ZendLeads/${id}/edit`)
  };
};
export const deleteZendLeads = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_ZENDLEADS, payload: {id}})
      })
      .then(() => {
        history.push("/ZendLeads")
      })
      .catch(error => {
        console.error(error);
      });
  };
};
export const updateZendLeads00 = (ZendLeads) => {
  const id = ZendLeads.id;
  return (dispatch) => {
    return axios.post(`${apiUrl}/lead`, ZendLeads)
      .then(response => {
        const data = response.data;
		console.log("response from server .... ");  console.log(response.data);	
		return dispatch({type: UPDATE_ZENDLEADS, ZendLeadsOne: response.data.data});        
      })
      .then(() => {
        history.push(`/ZendLeads/${id}`)
      })
      .catch(error => { console.error(error) });
  };
};


export const updateZendLeads = (ZendLeads) => { 
	const auth_id=38; 
	return (dispatch) => {
		return axios.post(`${apiUrl}/lead`, ZendLeads)
		.then(response => {
		  let data = response.data.data;
		  console.log("response from server .... ");  console.log(response.data);	
		  return dispatch({type: UPDATE_ZENDLEADS, ZendLeadsOne: response.data.data});     
		})
		.then(response => {
		  //history.push("/NeoVentes")
		  return response;
		})
		//.catch(error => { console.error(error) });
		.catch(err => {
		  if (err.response) {
			// client received an error response (5xx, 4xx)
			console.log("error falls here #1"); console.log(err.response);
			console.log(err);
			console.error(err.response);
		  } else if (err.request) {
			// client never received a response, or request never left
			console.log("error falls here #2");
			const errMessage="client never received a response, or request never left";
			console.error(errMessage);
		  } else {
			// anything else
			console.log("error falls here #3"); console.log(err);   
			console.error(err); 
		  }
	  });
	};
  };
  

 export const sendSMSNote = (ZendLeads) => { 
	const auth_id=38; 
	return (dispatch) => {
		return axios.post(`${apiUrl}/lead`, ZendLeads)
		.then(response => {
		  let data = response.data.data;
		  console.log("response from server .... ");  console.log(response.data);	
		  return dispatch({type: UPDATE_ZENDLEADS, ZendLeadsOne: response.data.data});     
		})
		.then(response => {
		  //history.push("/NeoVentes")
		  return response;
		})
		//.catch(error => { console.error(error) });
		.catch(err => {
		  if (err.response) {
			// client received an error response (5xx, 4xx)
			console.log("error falls here #1"); console.log(err.response);
			console.log(err);
			console.error(err.response);
		  } else if (err.request) {
			// client never received a response, or request never left
			console.log("error falls here #2");
			const errMessage="client never received a response, or request never left";
			console.error(errMessage);
		  } else {
			// anything else
			console.log("error falls here #3"); console.log(err);   
			console.error(err); 
		  }
	  });
	};
  };

export const updateZendLeadsold = (ZendLeads) => {
	const id = ZendLeads.id;
	return (dispatch) => {
	  return axios.patch(`${apiUrl}/update/${ZendLeads.id}`,  {
		  reason: ZendLeads.reason,
		  assig_by: ZendLeads.assig_by,
		  assig_date: ZendLeads.assig_date,
		  assig_to: ZendLeads.assig_to,
		  recall_date: ZendLeads.recall_date,
		  update_by: ZendLeads.update_by,
		  last_update: ZendLeads.last_update,
		  p_id: ZendLeads.p_id,
		  definition: ZendLeads.definition,
		  ticket_id: ZendLeads.ticket_id,
		  client_id: ZendLeads.client_id,
		  email: ZendLeads.email,
		  date_created: ZendLeads.date_created,
		  delay: ZendLeads.delay,
		  done: ZendLeads.done,
		  date_executed: ZendLeads.date_executed,
		  result: ZendLeads.result,
		  ticket_type: ZendLeads.ticket_type,
		  })
		.then(response => {
		  const data = response.data;
		  dispatch({type: UPDATE_ZENDLEADS, payload: {
			  id: data.id,
			  reason: data.reason,
			  assig_by: data.assig_by,
			  assig_date: data.assig_date,
			  assig_to: data.assig_to,
			  recall_date: data.recall_date,
			  update_by: data.update_by,
			  last_update: data.last_update,
			  p_id: data.p_id,
			  definition: data.definition,
			  ticket_id: data.ticket_id,
			  client_id: data.client_id,
			  email: data.email,
			  date_created: data.date_created,
			  delay: data.delay,
			  done: data.done,
			  date_executed: data.date_executed,
			  result: data.result,
			  ticket_type: data.ticket_type,
		  }})
		  dispatch({type: REPLACE_ZENDLEADS, payload: {
			  id: data.id,
			  reason: data.reason,
			  assig_by: data.assig_by,
			  assig_date: data.assig_date,
			  assig_to: data.assig_to,
			  recall_date: data.recall_date,
			  update_by: data.update_by,
			  last_update: data.last_update,
			  p_id: data.p_id,
			  definition: data.definition,
			  ticket_id: data.ticket_id,
			  client_id: data.client_id,
			  email: data.email,
			  date_created: data.date_created,
			  delay: data.delay,
			  done: data.done,
			  date_executed: data.date_executed,
			  result: data.result,
			  ticket_type: data.ticket_type,
		  }})
		})
		.then(() => {
		  history.push(`/ZendLeads/${id}`)
		})
		.catch(error => { console.error(error) });
	};
  };

export const addOrUpdateZendLeads = (ZendLeads, actionMode) => {
	if(actionMode===1){
        return addZendLeads(ZendLeads);
      }else {
        return updateZendLeads(ZendLeads);
      }
};