import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateZendLeads, getZendLeads } from '../../actions/ZendLeadsAction'; 
import { Validations } from '../../utils/validations'; 
import { encryptText, decryptText } from '../../appEncryption';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 
import history from '../../history';
import Toastr from "../../messages";
import  Loader from "../../loader";
import MuiAlert from '@material-ui/lab/Alert';

import moment from 'moment';
import frLocale from "moment/locale/fr";


let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['reason']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['assig_by']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['assig_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['assig_to']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['recall_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['update_by']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['last_update']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['viewing_by']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['viewing_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['status']={check:true, chkType:'boolean', message:'incorrect value'};
FIELD_VALIDATION['p_id']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['definition']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['ticket_id']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['client_id']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['email']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['date_created']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['delay']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['done']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['date_executed']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['result']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['ticket_type']={check:false, chkType:'String', message:'incorrect value'};

class ZendLeadmedsForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  currentUserId=0;
  actionBackMode='all';
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		reason:'', e_reason: false,
		assig_by:'', e_assig_by: false,
		assig_date:'', e_assig_date: false,
		assig_to:'', e_assig_to: false,
		recall_date:'', e_recall_date: false,
		update_by:'', e_update_by: false,
		last_update:'', e_last_update: false,
		viewing_by:'', e_viewing_by: false,
		viewing_date:'', e_viewing_date: false,
		status:'', e_status: false,
		p_id:'', e_p_id: false,
		definition:'', e_definition: false,
		ticket_id:'', e_ticket_id: false,
		client_id:'', e_client_id: false,
		email:'', e_email: false,
		date_created:'', e_date_created: false,
		delay:'', e_delay: false,
		done:'', e_done: false,
		date_executed:'', e_date_executed: false,
		result:'', e_result: false,
		ticket_type:'', e_ticket_type: false,

		isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000,
		statusChargeDesk: 0, chargeDeskIdStr:"", invoiceId:0, 
		leadTicket: {}, errorTransacMessage:"", 
		openMessageInfo:false, messageInfo:"", showRendevousBooking:false, 
      	calendarBookingInfo:"", 
		readonlyMode:false, personTime:"", stayonpage:false,  
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	const patt2=/([0-9]+)(\/edit)(.*)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit';
		this.actionBackMode='all'; 
	}else if(patt2.test(locurl)){ 
		this.actionMode='edit';
		this.actionBackMode='my'; 
	}else{ 
		this.actionMode='new'; 
		this.actionBackMode='all'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	const { t } = this.props; 
	let user=null;
   if(localStorage.getItem("user")!==undefined  && localStorage.getItem("user")!==null){
		user=(decryptText(localStorage.getItem("user"))).split(':');
		this.currentUserId=""+ user[4];
   } 
      





	// this.currentUserId=0;
	const this2=this; 
	if(this.actionMode==='edit'){ 
		const idx = this.props.match.params.id; 
		// console.log('edit  test  '+this.props.ZendLeads.id);
		console.log('edit  test  ', idx);
		if(idx!==undefined){  //if(this.props.ZendLeads.id===undefined){ 
			//means some refresh happens  id
			// const idx = this.props.match.params.id; 
			//getZendLeads(idx);
			/*Promise.resolve(this.props.getZendLeads(idx))
			.then((response)=>{
				this.setState(response.ZendLeadsOne);
				return response;
			});*/
			 
		 ;
		this.setState({ ["isLoaderOpen"]: true});  
		// const this2=this; 
		Promise.resolve(this.props.getZendLeads(idx))
        .then((response)=>{
			const record=response.ZendLeadsOne; 
			//   this.setState({ ["isLoaderOpen"]: false, ["leadTicket"]: pendingList});
			let readonlyMode=false; let personTime="";
			if(record.readonly===true){ 
				readonlyMode=true; 
				personTime=record.viewing_bytxt+" "+record.remainMinutes+" minutes";
			}
			if(record.id>0){ 
				// id, reason, assig_by, assig_date, assig_to, recall_date, update_by, 
				// last_update, viewing_by, viewing_date, status, 
				// p_id, definition, ticket_id, client_id, email, date_created, delay, done, date_executed, result, ticket_type	
				this.setState(record);
				console.log("aaaaaaaaaaaasigne to all states");
			}
			this.setState({ ["leadTicket"]: record, ["id"]:idx,});
		  this.setState({ ["isLoaderOpen"]: false, ["leadTicket"]: record, 
		  ["readonlyMode"]: readonlyMode, ["personTime"]:personTime, ["id"]:idx,});
		  this.showToast(t("zqwCViewEl11_x"), "success"); 
		  console.log("PEEEEEEENDING", response, record); 
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          window.alert(error);
        });
		}	
	}
	
	window.addEventListener("message", (event) => {
		// Do we trust the sender of this message?  (might be
		// different from what we originally opened, for example).
		if (event.origin !== "http://localhost" && event.origin !=="https://893efecad6f0.ngrok.io" 
		&& event.origin !=="https://admin.neolegal.ca"){
		  //  console.log("mmmm from reactjs rejected ones...", event.origin, );
		  //  console.log(event.data);
		  return;
		}
		  const dataReceived=JSON.parse(event.data);
		  console.log("from reactjs rceived one...");
		  console.log("receiving ", event.data);
		  moment.locale('en'); 
		  // moment.locale('fr', [frLocale]); 
		  if(t("curlang")==="fr"){ moment.locale('fr', [frLocale]); }
		  console.log(moment.locale());
		  if(dataReceived!==undefined && dataReceived!==null && dataReceived.name==="booking"){
			const fullTxt=dataReceived.user.firstname+" "+dataReceived.user.lastname+" "+moment(dataReceived.date).format('LLLL');
			this2.setState({["calendarBookingInfo"]: " ("+fullTxt+")" }); 
			console.log("receiving Date ", dataReceived.date, moment(dataReceived.date).format('LLLL'), fullTxt); 
		  }
	  
		// event.source is popup
		// event.data is "hi there yourself!  the secret response is: rheeeeet!"
	  }, false);
  
	  // webcomponent close here
	  // var iframe = document.getElementById("iframechild00");
	  // var iframeDoc = iframe.contentWindow;
	  // iframeDoc.postMessage("hello from parent reactjs there!", "http://localhost");
	  // iframeDoc.postMessage("hello from parent reactjs there #2!", "http://localhost:3000");
  }

  showToast = (arg, toastType) => {
	//toastType: success or error or warning or info
	const toastrDuration=this.state.toastrDuration; //5500	 
	this.setState({  
		toastrMessage: arg, 
	  toastrType: toastType
	  });
	setTimeout(() => { 
	  this.setState({ 
		toastrMessage: "", 
		toastrType: 'success'
	  }); 
	}, toastrDuration);
  }

  handleCheckBoxCheck = (event) => {
    let isChecked=false; let onOffval="0";
    if(event.target.checked){ isChecked=true; onOffval="1"; }
    //console.log(" ....SMMMMMMMMMMMS CHANGED handleCheckBoxCheck .... "+onOffval);  
    if(event.target.name==="sms"){
      // zqwNVFrmEl99_x 
      console.log(" SMMMMMMMMMMMS CHANGED.... "+onOffval); 
      //this.showToast(" SMMMMMMMMMMMS CHANGED.... "+onOffval, "warning");
      this.setState({ [event.target.name]: onOffval }); 
    }else{
      this.setState({ [event.target.name]: isChecked }); 
    }
          
  };

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const reason = this.state.reason;
    const assig_by = this.state.assig_by;
    const assig_date = this.state.assig_date;
    const assig_to = this.state.assig_to;
    const recall_date = this.state.recall_date;
    const update_by = this.state.update_by;
    const last_update = this.state.last_update;
    const viewing_by = this.state.viewing_by;
    const viewing_date = this.state.viewing_date;
    const status = this.state.status;
    const p_id = this.state.p_id;
    const definition = this.state.definition;
    const ticket_id = this.state.ticket_id;
    const client_id = this.state.client_id;
    const email = this.state.email;
    const date_created = this.state.date_created;
    const delay = this.state.delay;
    const done = this.state.done;
    const date_executed = this.state.date_executed;
    const result = this.state.result;
    const ticket_type = this.state.ticket_type;
    // const ZendLeads = {id: id, reason: reason, assig_by: assig_by, assig_date: assig_date, assig_to: assig_to, recall_date: recall_date, update_by: update_by, last_update: last_update, viewing_by: viewing_by, viewing_date: viewing_date, status: status, p_id: p_id, definition: definition, ticket_id: ticket_id, client_id: client_id, email: email, date_created: date_created, delay: delay, done: done, date_executed: date_executed, result: result, ticket_type: ticket_type, }
    
	let errorFound=0;
	/*for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.ZendLeads[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`${t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateZendLeads(ZendLeads, 1); 
		//this.props.addZendLeads(ZendLeads); 
	}else{ 
		this.props.addOrUpdateZendLeads(ZendLeads, 2);
		//this.props.updateZendLeads(ZendLeads);
		
	}*/
	// reason assig_to
	if((reason===null || reason==="") && (assig_to===null || assig_to==="")){
		window.alert(t('zqwerrorFoundonform_x')+" "+t('zqwreason2_x')+" "+t('orr2_x')+" "+t('zqwassig_to_x')+" "); 
		return false;
	}

	const ZendLeads = {id: id, reason: reason, assig_to: assig_to, recall_date: recall_date,  };

	console.log("lead block", ZendLeads);  //return false;
    
	const this00=this;
  	let rawError={};
  
  	this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: ""});  
    Promise.resolve(this.props.addOrUpdateZendLeads(ZendLeads, 2))
    .then((response)=>{ 
      const resp=response.ZendLeadsOne;
      rawError=response;
      console.log('RESPONSE PAAAAAAAAAAAAAYMENT OJOJO ');  
      console.log(resp); 
      //this.setState({ ["listOfSuggestion"]: response.NeoClientsOne });
      this.setState({ ["isLoaderOpen"]: false});  
      return response;
    }).catch(function(error){ 
      const errObj=error;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n "+t("zqwNVFrmEl14_x")
      }
      this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
       
      console.log("ERROR WITH TRANSACTION"); 
      console.log(error);
      console.log("SHORT ERROR MESSAGE: "+errMessage);
      console.log("RWA RESPONSE"); 
      console.log(rawError);
       
      //localStorage.setItem("transerror", JSON.stringify(error));
      //localStorage.setItem("transerrorrawresp", JSON.stringify(rawError));
      //alert(errMessage); //error
    });

	
  };

  handleCancel = () => { 
	if(this.actionMode==='new'){ 
		this.props.history.push(`/zendmeds`);
	}else{ 
		const idx = this.props.match.params.id; 
		this.props.history.push(`/zendmeds/${idx}`);
		//this.props.history.push(`/ZendLeads/${this.props.ZendLeads.id}`);
	}
  }

  handleCloseReturn = (ev) => {
	let readonly=this.state.readonlyMode;
	// if(readonly===true && this.currentUserId===this.state.assig_to){
	// 	readonly=false;
	// 	console.log("readonly is not so readonly because  ", this.state.assig_to, this.currentUserId);
	// }
	// this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: ""});  
	if(readonly===true){
		// this.props.history.push(`/ZendLeads`); 
		if(this.actionBackMode==='my'){
			this.props.history.push(`/myzendmeds`);
		}else{
			this.props.history.push(`/zendmeds`);
		} 
	}else{
		const { t } = this.props;
		const id = this.state.id;
		const reason = this.state.reason;
		const assig_by = this.state.assig_by;
		const assig_date = this.state.assig_date;
		const assig_to = this.state.assig_to;
		const recall_date = this.state.recall_date;
		const update_by = this.state.update_by;
		const last_update = this.state.last_update;
		const viewing_by = this.state.viewing_by;
		const viewing_date = this.state.viewing_date;
		const status = this.state.status;
		const p_id = this.state.p_id;
		const definition = this.state.definition;
		const ticket_id = this.state.ticket_id;
		const client_id = this.state.client_id;
		const email = this.state.email;
		const date_created = this.state.date_created;
		const delay = this.state.delay;
		const done = this.state.done;
		const date_executed = this.state.date_executed;
		const result = this.state.result;
		const ticket_type = this.state.ticket_type;
		const ZendLeads = {id: id, reason: reason, assig_to: assig_to, recall_date: recall_date, releaseLead:99, };

		console.log("reeeeeaaaaaaaaaaaaleasing lead block", ZendLeads);  //return false;
		
		const this00=this;
		let rawError={};
	
		this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: ""});  
		Promise.resolve(this.props.addOrUpdateZendLeads(ZendLeads, 2))
		.then((response)=>{ 
			const resp=response.ZendLeadsOne;
			rawError=response;
			console.log('RESPONSE PAAAAAAAAAAAAAYMENT OJOJO ');  
			console.log(resp); 
			//this.setState({ ["listOfSuggestion"]: response.NeoClientsOne });
			this.setState({ ["isLoaderOpen"]: false});  
			// this.props.history.push(`/ZendLeads`);
			if(this.actionBackMode==='my'){
				this.props.history.push(`/myzendmeds`);
			}else{
				this.props.history.push(`/zendmeds`);
			}   
			return response;
		}).catch(function(error){ 
			const errObj=error;
			//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
			let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
			if(errObj.status===undefined){
				errMessage +="\n "+t("zqwNVFrmEl14_x")
			}
			this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
			
			console.log("ERROR WITH TRANSACTION"); 
			console.log(error);
			console.log("SHORT ERROR MESSAGE: "+errMessage);
			console.log("RWA RESPONSE"); 
			console.log(rawError); 
			// this.props.history.push(`/ZendLeads`); 
			if(this00.actionBackMode==='my'){
				this00.props.history.push(`/myzendmeds`);
			}else{
				this00.props.history.push(`/zendmeds`);
			}  
		});
	}
	// this.props.history.push(`/ZendLeads`); 
	// this.props.history.push(`/ZendLeads/${idx}`); 
  }

  //   handleCancelLead
  //   handleCloseLead 
  handleActionOptionsLead = (ev) => {
	let readonly=this.state.readonlyMode;
	// if(readonly===true && this.currentUserId===this.state.assig_to){
	// 	readonly=false;
	// 	console.log("readonly is not so readonly because  ", this.state.assig_to, this.currentUserId);
	// }
	const optionAct=ev.target.dataset.action;

	// console.log("optionAct lead optionAct ", optionAct);  return false;	
	
	const { t } = this.props;
	const id = this.state.id;
	const reason = this.state.reason;
	const assig_by = this.state.assig_by;
	const assig_date = this.state.assig_date;
	const assig_to = this.state.assig_to;
	const recall_date = this.state.recall_date;
	const update_by = this.state.update_by;
	const last_update = this.state.last_update;
	const viewing_by = this.state.viewing_by;
	const viewing_date = this.state.viewing_date;
	const status = this.state.status;
	const p_id = this.state.p_id;
	const definition = this.state.definition;
	const ticket_id = this.state.ticket_id;
	const client_id = this.state.client_id;
	const email = this.state.email;
	const date_created = this.state.date_created;
	const delay = this.state.delay;
	const done = this.state.done;
	const date_executed = this.state.date_executed;
	const result = this.state.result;
	const ticket_type = this.state.ticket_type;
	// const ZendLeads = {id: id, reason: reason, assig_to: assig_to, recall_date: recall_date, cancelLead:99, };
	const ZendLeads = {id: id, reason: reason, assig_to: assig_to, recall_date: recall_date, };
	if(optionAct==="close"){
		ZendLeads.closeLead=99;
	}else if(optionAct==="cancel"){
		ZendLeads.cancelLead=99;  
	}else if(optionAct==="releaseLead"){
		ZendLeads.releaseLead=99;
	}

	console.log("reeeeeaaaaaaaaaaaaleasing lead block", ZendLeads);  //return false;	
	const this00=this;
	let rawError={};
	this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: ""});  
	Promise.resolve(this.props.addOrUpdateZendLeads(ZendLeads, 2))
	.then((response)=>{ 
		const resp=response.ZendLeadsOne;
		rawError=response;
		console.log('RESPONSE PAAAAAAAAAAAAAYMENT OJOJO ');  
		console.log(resp); 
		//this.setState({ ["listOfSuggestion"]: response.NeoClientsOne });
		this.setState({ ["isLoaderOpen"]: false});  
		// this.props.history.push(`/ZendLeads`);
		if(this.actionBackMode==='my'){
			this.props.history.push(`/myzendmeds`);
		}else{
			this.props.history.push(`/zendmeds`);
		}  
		return response;
	}).catch(function(error){ 
		const errObj=error;
		//let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		if(errObj.status===undefined){
			errMessage +="\n "+t("zqwNVFrmEl14_x")
		}
		this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
		
		console.log("ERROR WITH TRANSACTION"); 
		console.log(error);
		console.log("SHORT ERROR MESSAGE: "+errMessage);
		console.log("RWA RESPONSE"); 
		console.log(rawError); 
		// this.props.history.push(`/ZendLeads`);
		if(this00.actionBackMode==='my'){
			this00.props.history.push(`/myzendzendmeds`);
		}else{
			this00.props.history.push(`/zendzendmeds`);
		} 
	});
	
	// this.props.history.push(`/ZendLeads`); 
	// this.props.history.push(`/ZendLeads/${idx}`); 
  }

  handleConfActions4 = (event) => {
    this.setState({ 
		// ["showSearchConflict"]: false, ["showB2bLead"]:false, 
    ["showRendevousBooking"]:false,}); 
  };

  render() {
    const { t } = this.props;
	const leadTicket=this.state.leadTicket;
	console.log("traaaaacking leadTicket...", leadTicket);
	if(leadTicket===null || leadTicket.ticket_id===undefined){
		return (<div>
			<h3>{t('zqwZendtx600_x')}</h3>
			{t('zqwZendtx8_x')} 
			<Loader  openx={this.state.isLoaderOpen}  />
			</div>);
	} 

	let valCmp = leadTicket.description; //dataIndex
	let ticket = leadTicket.ticket_id;
	let subject = leadTicket.subject;
	const zx=valCmp.split("http://send.neolegal.ca");
	// http://send.neolegal.ca  
	let description=zx[0];
	description=description.replaceAll("\n\n", "<br/>");
	description=description.replaceAll("\n", "<br/>");
	//const comments=leadTicket.comments;
	moment.locale('en'); 
	// moment.locale('fr', [frLocale]); 
	if(t("curlang")==="fr"){ moment.locale('fr', [frLocale]); }
	console.log(moment.locale());

	const reasons=leadTicket.reasons.length > 0
    	&& leadTicket.reasons.map((item, i) => { 
		const reasontxt=t("curlang")==="fr"?item.name:item.name_en;
		return (<option value={item.id} key={"rs"+i}>{reasontxt}</option>);  
		return null;
    }, this);


	const neousers=leadTicket.neousers.length > 0
    	&& leadTicket.neousers.map((item, i) => {   
		return (<option value={item.id_users} key={"us"+i}>{item.label}</option>);   
		return null;
    }, this);


	
	const comments=leadTicket.comments.length > 0
    	&& leadTicket.comments.map((item, i) => {  
        const myItemtxt=""+item.id;
        const index=i; 
		const raw=JSON.parse(item.data_raw);
		const isPublic=raw.public===undefined?false:raw.public;
		const pvcomment=isPublic===true?"":" pvcomment";
		const author = raw.author_id;
		const created= moment(raw.created_at).format('LLLL'); //raw.created_at;
		console.log("comments index ", i, item.id, isPublic, pvcomment, raw.public);
		//console.log("comments raw ", raw.body);
		console.log("receiving Date ", raw.created_at, moment(raw.created_at).format('LLLL'));
	

		if(raw.type==="Comment"){
			// let descBody=raw.body;
			// //descBody=descBody.replaceAll("\n\n", "<br/>");
			// descBody=descBody.replaceAll("\n", "<br/>");
			let descBody=raw.html_body;
			return(<div className="zendcomment" key={"cmt"+i}>
				<div className="row"> 
					<div className="user_photo  agent"> 
						<div id="ember4252" className="ember-view round-avatar">  
						<figure className="thumbnail-container thumbnail-container--md ">
							<img alt="" className="img-user" src="/Logo_N_Bleu.png"/>
							<div id="ember4254" className="ember-view"><img alt="" className="avatar-badge" src="/avatar_badge-91.svg"/>
							</div>
						</figure>
						</div>     
					</div>
					<b>AuthorID {author}</b> &nbsp; &nbsp;  {created}
				</div>

				<div className={"tcomment"+pvcomment}>
					{/* {descBody} */}
					<div dangerouslySetInnerHTML={{ __html: descBody }}></div>
				</div> 
			</div>);
		}else if(raw.type==="VoiceComment"){
			// let descBody=raw.body;
			// //descBody=descBody.replaceAll("\n\n", "<br/>");
			// descBody=descBody.replaceAll("\n", "<br/>");
			let descBody=raw.html_body;
			//const basePat="<a href=\"https://neolegal.zendesk.com/api/v2/channels/voice/calls/\"";
			const basePat=/<a href="https:\/\/neolegal.zendesk.com\/api\/v2\/channels\/voice\/calls\//g;
			let audPlayer000="<div className=\"player-container\">"+
			"<audio className=\"audio player\" preload=\"metadata\" controls=\"\" controlslist=\"nodownload duration\" id=\"audio_player_neo1204"+i+"_events\">"+
			"   <source type=\"audio/mp3\" id=\"src_neo120"+i+"\" src=\"#00000\">"+
			"</audio> "+
		"</div><br/>";  
			//if(descBody.test())
			//descBody.replace(basePat, audPlayer+''+basePat);
			let regex = /(<a href=")([^\s]+)(")/g;
			let match = regex.exec(descBody);
			let tmphref="#00000";
			// console.log("check href ", match[2]); 
			if(match[2]!==undefined || match[2]!==""){
				tmphref=match[2];
			} 
			let audPlayer="<div className=\"player-container\">"+
			"<audio className=\"audio player\" preload=\"metadata\" controls=\"\" controlslist=\"nodownload duration\" id=\"audio_player_neo1204"+i+"_events\">"+
			"   <source type=\"audio/mp3\" id=\"src_neo120"+i+"\" src=\""+tmphref+"\">"+
			"</audio> "+
		"</div><br/>";     
			// let re = /(\w+)\s(\w+)/; 
			let re = /(<a )\s*/;   
			descBody = descBody.replace(re, audPlayer+' $1'); //descBody.replace(re, '$2, $1');

			return(<div className="zendcomment" key={"cmt"+i}>
				<div className="row"> 
					<div className="user_photo  agent"> 
						<div id="ember4262" className="ember-view round-avatar">  
						<figure className="thumbnail-container thumbnail-container--md ">
							<img alt="" className="img-user" src="/Logo_N_Bleu.png"/>
							<div id="ember4264" className="ember-view"><img alt="" className="avatar-badge" src="/avatar_badge-91.svg"/>
							</div>
						</figure>
						</div>     
					</div>
					<b>AuthorID {author}</b> &nbsp; &nbsp;  {created}
				</div>
				<div className={"vcomment"+pvcomment}>
					{/* {descBody} */}
				<div dangerouslySetInnerHTML={{ __html: descBody }}></div>
				</div> 
			</div>);
		}
		else if(raw.type==="Commentx"){}
            
		return null; 

    }, this);

	const showRendevousBooking=this.state.showRendevousBooking;
	let readonly=this.state.readonlyMode;
	const Alert=this.Alert;
	const personTime=this.state.personTime;
	const stayonpage=this.state.stayonpage;


	const ticket_id= this.state.ticket_id; 
    //const ticket_id= "232358"; 
    const client_id=this.state.idClient; // client_id;  
    //{""+ticket_id} client_id={""+client_id}
    console.log("oooCONFLICT ", ticket_id, client_id, this.currentUserId);

	// if(readonly===true && this.currentUserId===this.state.assig_to){
	// 	readonly=false;
	// 	console.log("readonly is not so readonly because  ", this.state.assig_to, this.currentUserId);
	// }

    //helloWorld,testStr 
    //helloWorld();  testStr("Neolegal");             //FieldsMiniBoardzendesk 
    const babygirlIs=""; 
    const backTicketId=433332;
    const neoemail="sidbenac_zx23@gmail.com";  //client_email
    const client_email="sidbenac@gmail.com";
    const neoClientId=413958800713;      
    // 15;
    const client_name="John 007 Doe"; 
    const subjectTicket="Envoyez une mise en demeure avec un avocat"; 
    const details="Jon Doe \n416 Av De La Pommeraie, Terrebonne(QC) J6Y 1V6 Canada"; 

	console.log("mmmmm  all states", this.state); 

    return (
      <div>
        {/* <h3>{this.actionMode==='new'? t('zqwacAddZendLeads_x'): t('zqwacEditZendLeads_x')}</h3> */}
		<h3>{t('zqwZendtx600_x')}</h3>
        <form onSubmit={this.handleSubmit}>
          		  
		<div className="row">      		  
    	 <div className="neox50">
		 <div className="card card-primary card-outline">
			<div className="card-header">
			<h5 className="m-0">Ticket  #{ticket}</h5>
			</div>
			<div className="card-body">
				
		  <div className="form-group neoStantoutTxt">
			{/* <label htmlFor="id">{t('zqwid_x')}</label> */}
            {/* Ticket  #{ticket}<br/><br/> */}
			<b>{subject}</b><br/><br/>
			<div dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>

		  <div className="form-group" style={{display:'none'}} >
			<label htmlFor="id">{t('zqwid_x')}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
			{this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="reason">{t('zqwreason2_x')}</label>
			<select name="reason" id="reason" defaultValue={this.state.reason}  onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_reason ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwreason_x')}> 
                        <option value="">{t("pleaseSel_x")}</option> 
						{reasons}
                    </select>
            {/* <input type="text" name="reason" id="reason" defaultValue={this.state.reason} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_reason ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwreason_x')} />  */}
			{this.state.e_reason && (<span className="text-danger">{FIELD_VALIDATION['reason'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="assig_to">{t('zqwassig_to_x')}</label>
			<select name="assig_to" id="assig_to" defaultValue={this.state.assig_to} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_assig_to ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwassig_to_x')}> 
                        <option value="">{t("pleaseSel_x")}</option> 
						{neousers}
                    </select>  
            {/* <input type="text" name="assig_to" id="assig_to" defaultValue={this.state.assig_to} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_assig_to ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwassig_to_x')} />  */}
			{this.state.e_assig_to && (<span className="text-danger">{FIELD_VALIDATION['assig_to'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="recall_date">{t('zqwrecall_date_x')}</label>
			<br/><input type="checkbox" id="showRendevousBooking" name="showRendevousBooking" className="form-controlx" 
                      defaultChecked={showRendevousBooking} disabled={readonly} 
                      onClick={this.handleCheckBoxCheck}  /> {t("zqwNDICItbook2_x")+""+this.state.calendarBookingInfo}  

            <input type="text" name="recall_date" id="recall_date" defaultValue={this.state.recall_date} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_recall_date ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwrecall_date_x')} readOnly={true} /> 
			{this.state.e_recall_date && (<span className="text-danger">{FIELD_VALIDATION['recall_date'].message}</span>)} 
          </div>
		  {(showRendevousBooking===true)?
       <div className="form-group">
        {/* <div className="form-row"> 
            <input type="checkbox" id="showRendevousBooking" name="showRendevousBooking" className="form-controlx" 
                      defaultChecked={this.state.showRendevousBooking} 
                      onClick={this.handleCheckBoxCheck}  /> {t("zqwNDICItbook2_x")+""+this.state.calendarBookingInfo}  
                      
          </div> */}
        <div className="form-rowx">   
          {/* <iframe src={"https://893efecad6f0.ngrok.io/booking?ticket_id="+backTicketId+"&client="+neoClientId+"&id_zendesk=26141155848"} style={{width: "100%", minHeight: "700px", border:"0"}}></iframe> */}
		  <div>Consulter l'equipe IT</div>
           {/* <iframe src={"https://admin.neolegal.ca/booking?ticket_id="+backTicketId+"&client_id="+this.state.idClient+"&id_zendesk=26141155848&client_email="+client_email+"&client_name="+client_name+"&subject="+subjectTicket+"&client_details="+details+""} 
          style={{width: "100%", minHeight: "700px", border:"0"}}></iframe>        */}
  
        {/* <iframe src={"http://localhost/iframechild.html"} id="iframechild" ref={this.iframeRef} 
          style={{width: "100%", minHeight: "400px", border:"0"}} ></iframe>      */}
        </div>             
        <div className="form-group" style={{display:'block'}}>  
        <br/><br/> <br/>  &nbsp; <button type="button" className="btn btn-warning" onClick={this.handleConfActions4}>{t('Close')}</button> 
          
        </div>
      </div>:''}





 

		{readonly===false?
          <div className="form-group">
			  {(this.currentUserId===this.state.assig_to && this.currentUserId!==this.state.viewing_by)?
			  <Alert severity="warning"> {personTime} </Alert>:""
			  }
			  <br/>	
			<input type="checkbox" id="stayonpage" name="stayonpage" className="form-controlx" 
                      defaultChecked={stayonpage}   
                      onClick={this.handleCheckBoxCheck}  /> {t("zqwNDICItbook2_x")+" rester sur cette page"}  
			<br/>		    
            <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')}</button> &nbsp; 
			{/* <button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button>  &nbsp;  &nbsp;  */}
			<button type="button" onClick={this.handleCloseReturn} className="btn btn-default">{t('zqwCViewEl56_x')}</button>  &nbsp;  &nbsp; 
			{(this.currentUserId===this.state.assig_to)?
			 <><button type="button" onClick={this.handleActionOptionsLead} data-action="close" className="btn btn-warning">{t('Closel_x')}</button>  &nbsp;  &nbsp;
			  <button type="button" onClick={this.handleActionOptionsLead} data-action="cancel" className="btn btn-danger">{t('Cancellead')}</button></>:""
			}
			{readonly===false && (this.state.assig_to===null || this.state.assig_to==="")?
			<><button type="button" onClick={this.handleActionOptionsLead} data-action="close" className="btn btn-warning">{t('Closel_x')}</button>  &nbsp;  &nbsp;
			  <button type="button" onClick={this.handleActionOptionsLead} data-action="cancel" className="btn btn-danger">{t('Cancellead')}</button></>:""
			}
          </div>:
		  	<div className="form-group">  
        		<Alert severity="warning"><b>{t('zqwZendtx9_x')}</b> | {personTime} </Alert><br/>
				<button type="button" onClick={this.handleCloseReturn} className="btn btn-default">{t('zqwCViewEl56_x')}</button>
        	</div>
		}
				</div>
		</div>

		</div>
		<div className="neox50">  
			<div className="text-center b-t  b-grey p-t-20 p-b-20 p-l-40 p-r-40">
				<img src="/static/assets/img/sceau-garantie-neo-fr.png" height="100" width="100" className="m-b-10" />
				<div className="text-black fs-16 sm-fs-15 m-b-5">{t("zqwNVFrmEl46_x")}</div> 
			</div>
			<div style={{margin:'20px' } }> 
				{/* aaaaaaaaaaa <br/>bbbbbbbbbbb <br/>ccccccccccccc <br/>dddddddddddddddd  */}
				<div className="card card-default">
					<div className="card-header">
						<h5 className="m-0">...</h5>
					</div>
					<div className="card-body">
						<h5>Conversations</h5>
						{comments}
	

					</div>  
				</div>  
			</div> 
		</div> 
		</div>  
        </form>
		<Loader  openx={this.state.isLoaderOpen}  />
      </div>
    );
  }

  Alert  = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
}

//const TransZendLeadsForm = withTranslation()(ZendLeadsForm); 
const mapStateToProps = (state) => ({ ZendLeads: state.ZendLeads });

const mapDispatchToProps = { addOrUpdateZendLeads, getZendLeads };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(ZendLeadmedsForm));
