import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
//import { addOrUpdateNdiFields, getNdiFields, getSearchConflict } from '../../actions/NdiFieldsAction'; 
import { getSearchConflict, setSearchConflict } from '../../actions/NeoSearchAction'; 
 
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
//import MinimizeIcon from '@material-ui/icons/Minimize';
import MinimizeIcon from '@material-ui/icons/Remove'; //RemoveIcon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import { getNdiTagsAll } from '../../actions/NdiTagsAction'; 
// import { Validations } from '../../utils/validations'; 
 import { APP_PATHNAME } from '../../appConfig'; 
 
import Toastr from "../../messages";
import  Loader from "../../loader";


import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'; 
import HelpIcon from '@material-ui/icons/Help';
import DeleteIcon from '@material-ui/icons/Delete';

const Accordion = withStyles({
	root: {
	  border: '1px solid rgba(0, 0, 0, .125)',
	  boxShadow: 'none',
	  '&:not(:last-child)': {
		borderBottom: 0,
	  },
	  '&:before': {
		display: 'none',
	  },
	  '&$expanded': {
		 margin: 'auto',
	  },
	},
	expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
	root: {
	//   backgroundColor: 'rgba(0, 0, 0, .03)',
	   borderBottom: '1px solid rgba(0, 0, 0, .125)',
	   marginBottom: -1,
	//   minHeight: 56,
	//   '&$expanded': {
	// 	minHeight: 56,
	//   },
	},
	content: {
	//   '&$expanded': {
	// 	margin: '12px 0',
	//   },
	},
	expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
	root: {
	  padding: theme.spacing(2),
	},
  }))(MuiAccordionDetails);

  const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
	  backgroundColor: '#f5f5f9',
	  color: 'rgba(0, 0, 0, 0.87)',
	  maxWidth: 220,
	  fontSize: theme.typography.pxToRem(12),
	  border: '1px solid #dadde9',
	},
  }))(Tooltip);

let FIELD_VALIDATION={};

class NdiInterestConflict extends React.Component {
  //validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000, 
		id:0,
		e_search: false,
		filter:'', e_filter: false,
		result:'', e_result: false,
		actions:'', e_actions: false,
		ticket_id:'', e_ticket_id: false,
		client_id:'', e_client_id: false,
		search_type:'', e_search_type: false,
		date_created:'', e_date_created: false,
		user_creation:'', e_user_creation: false,
		user_update:'', e_user_update: false,
		update_date:'', e_update_date: false,
		status:'', e_status: false,
		///////////////////////////////////// 
		search:'', byfield:'', conflictList:{}, searchkey:'', isSearchActive:false, 
		openHelpInfo:false, isMainSuccess:false, openNeoSearch:false, 
		openMessageInfo:false, messageInfo:'', frontendTime:'', 
    }


	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeField = (event) => {
	this.setState({ [event.target.name]: event.target.value });
	const val=(""+event.target.value).split("-");
	this.setState({ ["field"]: val[0], ["showMaskBlock"]: false });
	this.setState({ ["field_type"]: val[1] });
	if(event.target.value==="inputMask-inputmask"){
		this.setState({ ["showMaskBlock"]: true});
	}
	 
  };

  
  handleFocusout = (event) => {
	this.setState({ [event.target.name]: event.target.value });
	//   if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
	// 	  this.setState({ ["e_"+event.target.name]: false });
	// 	  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
	// 		  this.setState({ ["e_"+event.target.name]: true }); 	
	// 	  }
	//   } 	
  };
   

  componentDidMount() { 
	/*if(this.actionMode==='edit'){ 
		console.log('edit  test  '+this.props.NdiFields.id);
		if(this.props.NdiFields.id===undefined){ 
			//means some refresh happens  id
			const idx = this.props.match.params.id; 
			//getNdiFields(idx);
			Promise.resolve(this.props.getNdiFields(idx))
			.then((response)=>{
				const ndiFields=response.NdiFieldsOne;
				this.setState(response.NdiFieldsOne);
				const fxval=ndiFields.field+"-"+ndiFields.field_type;
				this.setState({ ["fieldx"]: fxval});
				console.log("weeeeeeeeeeeeeeeeeeee", fxval); 
				if(fxval==="inputMask-inputmask"){
					this.setState({ ["showMaskBlock"]: true});
				}
				
				return response;
			});
		}	
	}

	// Promise.resolve(this.props.getNdiFields(4))
	// .then((response)=>{
	// 	this.setState(response.NdiFieldsOne);
	// 	return response;
	// });	

	Promise.resolve(this.props.getNdiTagsAll())
	.then((response)=>{
		//this.setState(response.NdiProductTypesOne);
		const ndiTags=response.NdiTags.nditags;
		//console.log("resp props.getNdiTagsAll ", ndiTags); 
		this.setState({ ["listTagIds"]: ndiTags });
		 

		return response;
	});*/

	if (undefined!== this.props.ticket_id || undefined !== this.props.client_id) {
		if (undefined !== this.props.ticket_id){
			this.setState({ ["ticket_id"]: this.props.ticket_id, });  
		}
		if (undefined !== this.props.client_id){
			this.setState({ ["client_id"]: this.props.client_id,  });  
		}
		console.log("componentDidMount ticket_id "+this.props.ticket_id+" client_id "+this.props.client_id);  
	}

	 
	if (undefined !== this.props.neoSearchFullName){
		this.setState({ ["search"]: this.props.neoSearchFullName, });
		console.log("componentDidMount ticket_id "+this.props.ticket_id+" neoSearchFullName "+this.props.neoSearchFullName);  
		setTimeout(() => {
			this.handleSubmitSearchauto(null) ;
		}, 200);    
	}

  }

  componentDidUpdate(prevProps) {
		if (prevProps.ticket_id !== this.props.ticket_id || prevProps.client_id !== this.props.client_id) {
			if (prevProps.ticket_id !== this.props.ticket_id){
				this.setState({ ["ticket_id"]: this.props.ticket_id, });  
			}
			if (prevProps.client_id !== this.props.client_id){
				this.setState({ ["client_id"]: this.props.client_id,  });  
			}
			console.log("componentDidUpdate ticket_id "+this.props.ticket_id+" client_id "+this.props.client_id);  
		}
		if (prevProps.neoSearchFullName !== this.props.neoSearchFullName && undefined !== this.props.neoSearchFullName){
			this.setState({ ["search"]: this.props.neoSearchFullName, });
			console.log("componentDidUpdate   neoSearchFullName "+this.props.neoSearchFullName);  
			setTimeout(() => {
				this.handleSubmitSearchauto(null) ;
			}, 200);    
		}
		 
  }

	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info
		const toastrDuration=this.state.toastrDuration; //5500	 
		this.setState({  
			toastrMessage: arg, 
		toastrType: toastType
		});
		setTimeout(() => { 
		this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		}); 
		}, toastrDuration);
	}

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const text_label = this.state.text_label;
    const placeholder = this.state.placeholder;
    const field = this.state.field;
    const field_type = this.state.field_type;
    const field_default_value = this.state.field_default_value;
    const mask = this.state.mask;
    const field_service = this.state.field_service;
    const field_dataset = this.state.field_dataset;
    const tag_id = this.state.tag_id;
    const NdiFields = {id: id, name: name, text_label: text_label, placeholder: placeholder, field: field, field_type: field_type, field_default_value: field_default_value, mask: mask, field_service: field_service, field_dataset: field_dataset, tag_id: tag_id, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.NdiFields[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		//this.props.addOrUpdateNdiFields(NdiFields, 1); 
		//this.props.addNdiFields(NdiFields);
		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		Promise.resolve(this.props.addOrUpdateNdiFields(NdiFields, 1))
		.then((response)=>{ 
			const ndiTags=response; //.NdiTags.nditags;
			console.log("submit form resp props.getNdiTagsAll ", ndiTags); 
			//this.setState({ ["listTagIds"]: ndiTags });
			this.setState({ ["isLoaderOpen"]: false});
		 	this.showToast("On a sauvegargé les données avec succès!", "success");  

			return response;
		}).catch(function(error){ 
			this2.setState({ ["isLoaderOpen"]: false});  
			alert(error);
		});  
	}else{ 
		//this.props.addOrUpdateNdiFields(NdiFields, 2);
		//this.props.updateNdiFields(NdiFields);
		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		Promise.resolve(this.props.addOrUpdateNdiFields(NdiFields, 1))
		.then((response)=>{ 
			const ndiTags=response; //.NdiTags.nditags;
			console.log("submit form resp props.getNdiTagsAll ", ndiTags); 
			//this.setState({ ["listTagIds"]: ndiTags });
			this.setState({ ["isLoaderOpen"]: false});
		 	this.showToast("On a actualisé les données avec succès!", "success");  

			return response;
		}).catch(function(error){ 
			this2.setState({ ["isLoaderOpen"]: false});  
			alert(error);
		});  
		
	}
  };

  //neoSearchFullName handleSubmitSearchauto
  handleSubmitSearch = (event) => {
	event.preventDefault();
	this.handleSubmitSearchx(event);
  };
  handleSubmitSearchauto = (event) => {
	this.handleSubmitSearchx(event);
  };
  handleSubmitSearchx = (event) => {                                                             
    //event.preventDefault();
    const { t } = this.props;
	const id = this.state.id;
    const search = this.state.search;
    const filter = this.state.filter;
    const result = this.state.result;
    const actions = this.state.actions;
    const ticket_id = this.state.ticket_id;
    const client_id = this.state.client_id;
    const search_type = this.state.search_type;
    const date_created = this.state.date_created;
    const user_creation = this.state.user_creation;
    const user_update = this.state.user_update;
    const update_date = this.state.update_date;
    const status = this.state.status;
    const NeoSearch = {id: id, search: search, filter: filter, result: result, actions: actions, ticket_id: ticket_id, client_id: client_id, search_type: search_type, date_created: date_created, user_creation: user_creation, user_update: user_update, update_date: update_date, status: status, }
    
	const byfield=this.state.byfield;
	const NdiSearch = {search: search, byfield: byfield, };
	
	if(search===null || search==="" || search.length <3){
		this.showToast("La recherche doit contenir au moins 3 charactères!", "error");  
		return false;
	}
    

	let errorFound=0;	
		//this.props.addOrUpdateNdiFields(NdiFields, 2);
		//this.props.updateNdiFields(NdiFields);
		this.setState({ ["isLoaderOpen"]: true, ["isSearchActive"]: false,});  
		const this2=this; //NdiFieldsOne 
		const startExecution = window.performance.now();
		Promise.resolve(this.props.getSearchConflict(NdiSearch))
		.then((response)=>{ 
			const endExecution = window.performance.now();
			const frontendTime=((endExecution - startExecution)/1000);
			const ndiTags=response; //.NdiTags.nditags;
			//console.log("submit form resp props.getSearchConflict ", ndiTags.NeoSearch.data); 
			//this.setState({ ["listTagIds"]: ndiTags });
			this.setState({ ["conflictList"]: ndiTags.NeoSearch.data, ["isLoaderOpen"]: false, 
			["isSearchActive"]: true, ["isMainSuccess"]:ndiTags.NeoSearch.data.isMainSuccess,
			["frontendTime"]: frontendTime,});
			
		 	this.showToast("On a obtenu les données avec succès!", "success");  

			return response;
		}).catch(function(error){ 
			//this2.setState({ ["isLoaderOpen"]: false});
			// this2.setState({ ["isLoaderOpen"]: false, ["openMessageInfo"]: true, ["messageInfo"]: error});
			// this2.showToast(error, "error");    
			
			const errObj=error;
			//this2.setState({ ["isLoaderOpen"]: false});  
			///let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
			let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
			//let errMessage="Error/Erreur ";
			const statusCode=errObj.data.statusCode;
			//console.log("teeeeeeeeeest data ", statusCode, errObj.data.error.description, errObj.data);
			if(errObj.status===undefined && statusCode===undefined){
				errMessage +="\n There was some error from the expected data from the server. Please try again later.";
				//this2.saveUpdateTicketDataToZendesks(zendeskList); 
				if (typeof errObj === 'string' || errObj instanceof String){
					console.log("inside undefined");
					this2.setState({ ["isLoaderOpen"]: false, ["openMessageInfo"]: true, ["messageInfo"]: errObj});  
					this2.showToast(errObj, "error");  
				}else{
					console.log("inside else undefined"); 
					this2.setState({ ["isLoaderOpen"]: false, ["openMessageInfo"]: true, ["messageInfo"]: errMessage}); 
					this2.showToast(errMessage, "error");  
				} 
				//this2.showToast(errMessage, "error");  
				console.log("FAKE ERRORS SOLVED MINIMIZED");  
				console.log(errObj);
			}
			else if(statusCode===500){    
				//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
				//errMessage +="\n 9999999 ".errObj.data.error.description;
				//errMessage +="\n 9999999 ".errObj.error.description;
				let mesx=errObj.data.error.description;
				if(mesx===undefined){ mesx=""; }
				errMessage ="SERVER ERROR INTERNAL  "+mesx;
				this2.setState({ ["isLoaderOpen"]: false, ["openMessageInfo"]: true, ["messageInfo"]: errMessage});
				this2.showToast(errMessage, "error");  
				console.log("500 ERROR");  
				console.log(errObj);  
			}else{
				//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
				//this00.showToastError(errMessage);
				//alert(errMessage); //error
				this2.setState({ ["isLoaderOpen"]: false, ["openMessageInfo"]: true, ["messageInfo"]: errMessage});
				this2.showToast(errMessage, "error");
				console.log("UNDEFINED ERROR");  
				console.log(errObj);  
			}
		}); 
		
	//}
  };

  
  handleConfActions = (event) => {
    this.handleConflictActions("noconflict");
  };
  handleConfActions2 = (event) => {
    this.handleConflictActions("conflict");
  };
  handleConfActions3 = (event) => {
    this.handleConflictActions("infona");
  };
  handleConfActions4 = (event) => {
    this.handleConflictActions("notap");
  };

  handleConflictActions = (actionName) => {                                                             
    //event.preventDefault();
    const { t } = this.props; 

	const id = this.state.id;
    const search = this.state.search;
    const filter = this.state.filter;
    const result = this.state.result;
    const actions = this.state.actions;
    const ticket_id = this.state.ticket_id;
    const client_id = this.state.client_id;
    const search_type = "conflict"; //this.state.search_type;
    const date_created = this.state.date_created;
    const user_creation = this.state.user_creation;
    const user_update = this.state.user_update;
    const update_date = this.state.update_date;
    const status = this.state.status;
    //const NeoSearch = {id: id, search: search, filter: filter, result: result, actions: actions, ticket_id: ticket_id, client_id: client_id, search_type: search_type, date_created: date_created, user_creation: user_creation, user_update: user_update, update_date: update_date, status: status, }
    
	const byfield=this.state.byfield;
	const NdiSearch = {search: search, byfield: byfield, };
	const conflictList = this.state.conflictList;
	const isSearchActive=this.state.isSearchActive; 
	const isMainSuccess=this.state.isMainSuccess; 
	
	// if(search===null || search==="" || search.length <3){
	// 	this.showToast("La recherche doit contenir au moins 3 charactères!", "error");  
	// 	return false;
	// }

	// this.handleConflictActions("noconflict"); 
    // this.handleConflictActions("conflict"); 
    // this.handleConflictActions("infona"); 
    // this.handleConflictActions("notap");
	if(actionName==="noconflict" || actionName==="conflict"){
		if(search===null || search==="" || search.length <3){
			this.showToast("Tu dois faire la recherche avant et elle doit contenir au moins 3 charactères!", "error");  
			return false;
		}
		if(!isSearchActive){
			this.showToast("Tu dois executer la recherche pour continuer.", "error");  
			return false;
		}
	}

	if(actionName==="conflict"){
		if(conflictList===null || (conflictList.clients===undefined && conflictList.zendesk===undefined)){   
			this.showToast("Tu dois executer la recherche pour continuer.", "error");  
			return false;
		}
		if(conflictList.clients.length==0 && conflictList.zendesk.length===0){   
			this.showToast("Le resultat de la recherche doit avoir des coicidences pour avoir de conflit.", "error");  
			return false;
		}
		 
	}

	const searchResult={clients: conflictList.clients, zendesk:conflictList.zendesk};
	const NeoSearch = {id: id, search: search, filter: filter, result: searchResult, actions: actions, ticket_id: ticket_id, client_id: client_id, search_type: search_type, actionStatus:actionName,}
    

    
	//this.showToast("Teeeeeeest "+actionName, "error");  return false;

	let errorFound=0;	
		//this.props.addOrUpdateNdiFields(NdiFields, 2);
		//this.props.updateNdiFields(NdiFields);
		this.setState({ ["isLoaderOpen"]: true, });  
		const this2=this; //NdiFieldsOne 
		Promise.resolve(this.props.setSearchConflict(NeoSearch))
		.then((response)=>{ 
			const ndiTags=response; //.NdiTags.nditags;
			console.log("submit form resp props.setSearchConflict ", ndiTags.NeoSearch.data); 
			this.setState({ ["isLoaderOpen"]: false, });
			if(this.props.paStateFuncAction!==undefined){
				//console.log('caaaaaaaaaaaalling  paStateFuncAction ', ticket_id, assigneeId, groupId);
				//const locMess="Recherche de conflit fait/Search conflict done";
				const locMess=t("zqwMesas015_x");
				const locObj={stateName: "searchConflicttxt",  stateVal:locMess, };
				this.props.paStateFuncAction(locObj);
			}
			// this.setState({ ["conflictList"]: ndiTags.NeoSearch.data, ["isLoaderOpen"]: false, 
			// ["isSearchActive"]: true, ["isMainSuccess"]:ndiTags.NeoSearch.data.isMainSuccess});
		 	this.showToast("On a sovegardé les données avec succès!", "success");  

			return response;
		}).catch(function(error){ 
			this2.setState({ ["isLoaderOpen"]: false});
			this2.showToast(error, "error");    
			//alert(error);
		}); 
		
	//}
  };

   
  handleClear = (event) => { 
	//  const path =APP_PATHNAME;
	//this.props.history.push(`${path}NdiFields`); 
	const rndKey=""+Math.floor(Math.random()*100); 
	this.setState({ ["conflictList"]: {}, ["search"]: "", ["searchkey"]: rndKey, 
	["isLoaderOpen"]: false, ["isSearchActive"]: false,});
	console.log("CLEEAAAAAAAAAARING... ");  
  };


  handleCancel = () => { 
	//  const path =APP_PATHNAME;
	//this.props.history.push(`${path}NdiFields`);
	// if(this.actionMode==='new'){ 
	// 	this.props.history.push(`./NdiFields`);
	// }else{ 
	// 	const idx = this.props.match.params.id; 
	// 	this.props.history.push(`./NdiFields/${idx}`);
	// 	//this.props.history.push(`/NdiFields/${this.props.NdiFields.id}`);
	// }
  }

  //https://developer.zendesk.com/apps/docs/core-api/client_api 
  //https://developer.zendesk.com/apps/docs/developer-guide/manifest


  render() {
	const { t } = this.props;
	//const listTagIds=this.state.listTagIds;
	// const listProductTypes =  listProductTypesx.filter(function(prodType) {  
	// 	return prodType.main_category === "1"; 
	// });  
	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType;
	const tag_id=this.state.tag_id;
	const HelpInfoAsOnTheSite=this.HelpInfoAsOnTheSite;
	const MessageInfoPopup=this.MessageInfoPopup;
	
	// const listeOptions = listTagIds.map((item, i) => {  
	// 	const tgId=""+item.id;
	// 	return (
	// 	  <option key={"op"+(i+1)} value={tgId}>{item.name}</option>
	// 	);
	//   }, this);

	  const CustomizedAccordions=this.CustomizedAccordions;
	  const searchkey=this.state.searchkey; 
	  const isSearchActive=this.state.isSearchActive; 
	  const isMainSuccess=this.state.isMainSuccess; 

	  const ticket_id = this.state.ticket_id;
	  const client_id = this.state.client_id;

	  console.log("BUUUUUUUUUUUUUUUUUUUUUUUG ticket_id=", ticket_id, "client_id=", client_id );

	  if(this.props.neoSearchData!==undefined){
		const CustomizedAccordionsdata=this.CustomizedAccordionsdata;
		  return(
			<div className="form-group">		  
				<CustomizedAccordionsdata />	
			</div>
		  );
	  }

    return (
      <div>
        {/* <h3>{t('zqwNDICItxl_x')}</h3> */} 
        <div className="form-row">
			<div className="form-group col-md-8" key={searchkey}>
				{/* <label htmlFor="text_label">Recherche</label> */}   
				<input type="text" name="search" autoFocus defaultValue={this.state.search} onChange={this.handleChange} onBlur={this.handleFocusout}
				className={'form-control'} placeholder={t('zqwNDICItx2_x')} autoComplete="off" />  
				{/* <button type="submit" className="btn btn-primary">{t('Update')}</button>  */}
				<span style={{display:'inline'}}>
					<a href="#" data-id={0} onClick={this.handleOpenHelpInfo}><HelpIcon /></a>
				</span> 
			</div>
			
			<div className="form-group col-md-4"> 
				<button type="button" className="btn btn-primary" onClick={this.handleSubmitSearch}>{t('zqwNDICItx3_x')}</button> 
				&nbsp;
				{isSearchActive===true? 
				<button type="button" className="btn btn-danger" onClick={this.handleClear} title={"Vider tout/ clear all"}><DeleteIcon /></button> 
				:''}
			</div>
			{/* <span style={{display:'inline'}}>
				<a href="#" data-id={0} onClick={this.handleOpenHelpInfo}><HelpIcon /></a>
			</span> */}
		</div>
		{(isSearchActive===true && isMainSuccess===false)? 
				<div className="form-group red">pas de résultat principal.</div>:''
		}
		<div className="form-group">		  
			<CustomizedAccordions />	
        </div>
		{(ticket_id!=="" && client_id!=="")?
		<div className="form-group" style={{display:'nonea'}}> 
			<button type="button" className="btn btn-success" onClick={this.handleConfActions}>{t('zqwNDICItx7_x')}</button> 
			&nbsp; <button type="button" className="btn btn-danger" onClick={this.handleConfActions2}>{t('zqwNDICItx8_x')}</button> 
			&nbsp; <button type="button" className="btn btn-primary" onClick={this.handleConfActions3}>{t('zqwNDICItx6_x')}</button>
			&nbsp; <button type="button" className="btn btn-warning" onClick={this.handleConfActions4}>{t('zqwNDICItx5_x')}</button> 
			 
		</div>:''
		}

		 {/* <div className="panel panel-primary">
			<div className="panel-heading">Panel with panel-primary class</div>
			<div className="panel-body">Panel Content</div>
		</div>

		<div className="panel panel-info">
			<div className="panel-heading">Panel with panel-info class</div>
			<div className="panel-body">Panel Content</div>
		</div>	

		<div className="card card-primary card-outline">
			<div className="card-header">
			<h5 className="m-0">Featured qx</h5>
			</div>
			<div className="card-body">
			<h6 className="card-title">Special title treatment</h6>

			<p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
			<a href="#" className="btn btn-primary">Go somewhere</a>
			</div>
		</div>	

		<div className="well-panel-info">			
			<p className="card-text">well pozo With supporting text below as a natural lead-in to additional content.</p>
			<a href="#" className="btn btn-primary">Go somewhere</a>			 
		</div>

		<div className="well-panel-info2">			
			<p className="card-text">well pozo With supporting text below as a natural lead-in to additional content.</p>
			<a href="#" className="btn btn-primary">Go somewhere</a>			 
		</div>
		<div className="well-panel-info3">			
			<p className="card-text">well pozo With supporting text below as a natural lead-in to additional content.</p>
			<a href="#" className="btn btn-primary">Go somewhere</a>			 
		</div>	      */}

		 

		<Loader  openx={this.state.isLoaderOpen}  />
		<Toastr messagex={myToastMessage} open={true} toastType={toastrType} /> 
		<HelpInfoAsOnTheSite  />
		<MessageInfoPopup  />
		
		 
      </div>
    );
  }

  
  CustomizedAccordions= () => { 
	const [expanded, setExpanded] = React.useState('panel1');
  
	const handleChange = (panel) => (event, newExpanded) => {
	  setExpanded(newExpanded ? panel : false);
	};
  
	//<Accordion TransitionProps={{ unmountOnExit: true }} /> 
	//<Icon className="fa fa-plus-circle" fontSize="small" />
	//expandIcon={expanded === 'panel1'?<ExpandMoreIcon />:<Minimize/>}>
	const conflictList=this.state.conflictList; 
	const isSearchActive=this.state.isSearchActive; 
	
	if(conflictList===null || conflictList.clients===undefined){  //|| conflictList.searchSubPartsTop===undefined
		return(<div></div>);
	}

	const confictxList=[];	 
	if(conflictList.clients!==undefined){
		 
	}
	const benchmarking=conflictList.benchmarking;
	const result_zendesk=benchmarking.zendesk;
    const result_total=benchmarking.total;
    const result_db=benchmarking.db;
	const dbrec=benchmarking.dbrec;
	const zendeskrec=benchmarking.zendeskrec;
	const frontendTime=this.state.frontendTime;

	let clientListQty = 0;
	const clientList = conflictList.clients.map((elemObj, i) => { 
		const rndKey=Math.floor(Math.random()*100);	
		let countQty=0;	
		const detExisted=elemObj.detExisted;  
		const clientCases =elemObj.cases!==undefined && elemObj.cases!==null 
		 &&  elemObj.cases.map((subelemObj, k) => { 
			countQty++;
			if(detExisted!==undefined){
				if(detExisted==="8200"){}else{
					return(<div className="form-group" key={"subMyXydet"+i+k}>
				{k >0?<hr/>:''}
				#Ticket: {subelemObj.ticket_id}<br/>
				#Tél: {subelemObj.cell}<br/>
				Email: {subelemObj.email}<br/> 
				<div className="well-panel-info2">
				<b>Autres Détails:</b><br/> 
				<p dangerouslySetInnerHTML={ { __html: subelemObj.details } }></p>
				</div> 
				Zendesk: <a href={"https://neolegal.zendesk.com/agent/tickets/"+subelemObj.ticket_id} target="_blank">ici</a><br/>
				Dossier Client: <a href={"https://crm2.neolegal.ca/zendesk/"+subelemObj.ticket_id+"/ticket"} target="_blank">ici</a><br/>  
			</div>);
				}
			}

			return(<div className="form-group" key={"subclidet"+i+k}>
				{k >0?<hr/>:''}
				#Ticket: {subelemObj.ticket_id}<br/>
				#Tél: {subelemObj.cell}<br/>
				Email: {subelemObj.email}<br/> 
				Adresse: {subelemObj.address+', '+subelemObj.city+', ('+subelemObj.province+') '+subelemObj.cp}<br/> 
				Zendesk: <a href={"https://neolegal.zendesk.com/agent/tickets/"+subelemObj.ticket_id} target="_blank">ici</a><br/>
				Dossier Client: <a href={"https://crm2.neolegal.ca/zendesk/"+subelemObj.ticket_id+"/ticket"} target="_blank">ici</a><br/>  
				{/* <b>Autres Détails:</b><br/> 
				<p dangerouslySetInnerHTML={ { __html: subelemObj.details } }></p> */}
			</div>);
		}, this);

		// if(countQty===0){
		// 	return null;
		// }
		clientListQty++;
		if(detExisted!==undefined){
			if(detExisted==="8200"){}else{
				return (<Accordion square expanded={expanded === 'panelMyXclia'+i} onChange={handleChange('panelMyXclia'+i)} 
		key={"mycliax"+i}>
		<AccordionSummary aria-controls={"panelMyXclia"+i+"d-content"} id={"panelMyXclia"+i+"d-header"}>
		{expanded === 'panelMyXclia'+i? <MinimizeIcon/>:<AddIcon/> }  
		  <Typography>{elemObj.firstname+" "+elemObj.lastname+" "+elemObj.cell}</Typography>
		</AccordionSummary>
		<AccordionDetails>
			{clientCases}
		</AccordionDetails>
	  </Accordion>);
			}
		}

		return (<Accordion square expanded={expanded === 'panelcli'+i} onChange={handleChange('panelcli'+i)} 
		key={"clix"+i}>
		<AccordionSummary aria-controls={"panelcli"+i+"d-content"} id={"panelcli"+i+"d-header"}>
		{expanded === 'panelcli'+i? <MinimizeIcon/>:<AddIcon/> }  
		  <Typography>{elemObj.firstname+" "+elemObj.lastname+" "+elemObj.cell} 
		  {countQty===0?'':'('+countQty+')'}</Typography>
		</AccordionSummary>
		<AccordionDetails>
			<div className="form-group well-panel-info3" key={"00subclidet"+i}> 
				#Tél: {elemObj.cell}<br/>
				Email: {elemObj.email}<br/> 
				Occupation: {elemObj.occupation}<br/> 
				Adresse Actuelle: {elemObj.address+', '+elemObj.city+', ('+elemObj.province+') '+elemObj.cp}<br/> 
			</div>
			{clientCases}
		</AccordionDetails>
	  </Accordion>);
	  }, this);

	  let clientcnyListQty = 0;
	  let clientsAdditionalListQty = 0; let paListQty = 0; let paAdListQty = 0;     


	  let zendeskListQty = 0;  
	  const zendeskList = conflictList.zendesk.map((elemObj, i) => { 
		//const rndKey=Math.floor(Math.random()*100);	
		
		zendeskListQty++;
		return (<Accordion square expanded={expanded === 'panelzen'+i} onChange={handleChange('panelzen'+i)} 
		key={"zenx"+i}>
		<AccordionSummary aria-controls={"panelzen"+i+"d-content"} id={"panelzen"+i+"d-header"}>
		{expanded === 'panelzen'+i? <MinimizeIcon/>:<AddIcon/> }  
		  <Typography>{elemObj.termsearch+" "+elemObj.ticket_id}</Typography>
		</AccordionSummary>
		<AccordionDetails>
			<div className="form-group" key={"subzendet"+i}> 
				#Ticket: {elemObj.ticket_id}<br/> 
				Fonction: {elemObj.clientRole}<br/> 
				<div className="well-panel-info2">
				<b>Autres Détails:</b><br/> 
				<p dangerouslySetInnerHTML={ { __html: elemObj.details } }></p>
				</div>
				Zendesk: <a href={"https://neolegal.zendesk.com/agent/tickets/"+elemObj.ticket_id} target="_blank">ici</a><br/>
				Dossier Client: <a href={"https://crm2.neolegal.ca/zendesk/"+elemObj.ticket_id+"/ticket"} target="_blank">ici</a><br/>  
			</div>
		</AccordionDetails>
	  </Accordion>);
	  }, this);

	  let clientb2bListQty = 0;
	  const clientb2bList = conflictList.clientsb2b.map((elemObj, i) => { 
		const rndKey=Math.floor(Math.random()*100);	
		let countQty=0;	 
		// if(countQty===0){
		// 	return null;
		// }
		clientb2bListQty++; 
		return (<Accordion square expanded={expanded === 'panelclib2b'+i} onChange={handleChange('panelclib2b'+i)} 
		key={"clib2bx"+i}>
		<AccordionSummary aria-controls={"panelclib2b"+i+"d-content"} id={"panelclib2b"+i+"d-header"}>
		{expanded === 'panelcli'+i? <MinimizeIcon/>:<AddIcon/> }  
		  <Typography>{elemObj.firstname+" "+elemObj.lastname+" "+elemObj.tel} 
		  {countQty===0?'':'('+countQty+')'}</Typography>
		</AccordionSummary>
		<AccordionDetails>
			<div className="form-group" key={"00subclib2bdet"+i}> 
				#Tél: {elemObj.tel}<br/>
				#Police: {elemObj.id_external}<br/> 
				Email: {elemObj.email}<br/> 
				Adresse: {elemObj.address+', '+elemObj.city+', ('+elemObj.province+') '+elemObj.postal_code}<br/> 
			</div> 
		</AccordionDetails>
	  </Accordion>);
	  }, this);


	  //console.log("track (clientListQty="+clientListQty+" && clientsAdditionalListQty="+clientsAdditionalListQty+" && clientcnyListQty="+clientcnyListQty+" && paListQty="+paListQty+" && paAdListQty="+paAdListQty+" && zendeskListQty="+zendeskListQty+" && clientb2bListQty="+clientb2bListQty+" && isSearchActive="+isSearchActive+")");

	return (
	  <div>
		  {/* {clientListQty >0?
			  <div className="neoxacord">
		  <label>Clients</label>
		  {clientList}</div>:''} */}
 

		<HtmlTooltip
			title={
			<React.Fragment>
				<Typography color="inherit">Neolegal Benchmarking</Typography>
				<b>{'Clients'}</b>: {result_db} seconds<br/>
				<b>{'Zendesk'}</b>: {result_zendesk} seconds<br/>
				{/* <b>{'preparation'}</b>: {'0000.0089000'} secondes<br/> */}
				<b>{'total'}</b>: {result_total} seconds<br/>
				<b>{'Client Result'}</b>: {dbrec} <br/>
				<b>{'Zendesk Result'}</b>: {zendeskrec} <br/>   
				<b>{'Front-end'}</b>: {frontendTime} seconds<br/> &nbsp; <br/>
			</React.Fragment>
			}
		>
			<Button>{(dbrec+zendeskrec)} results in {frontendTime} seconds...</Button>
      </HtmlTooltip>


		  {clientListQty >0?
		  <div className="card card-primary card-outline">
			<div className="card-header">
			<h5 className="m-0">Clients</h5>
			</div>
			<div className="card-body"> 
			<div className="neoxacord">{clientList}</div>
			</div>
		</div>:''}	  

{/* 
		  {clientsAdditionalListQty >0?
			  <div className="neoxacord"> 
		  <label>Clients Additionnels</label>
		  {clientsAdditionalList}</div>:''}
		  {clientcnyListQty >0?
			  <div className="neoxacord"> 
		  <label>Clients Compagnies</label>
		  {clientcnyList}</div>:''}
		  {paListQty >0?
			  <div className="neoxacord"> 
		  <label>Parties Adverses</label><br/>
		  {paList}</div>:''}
		  {paAdListQty >0?
			  <div className="neoxacord"> 
		  <label>Parties Adverses Additionnelles</label>
		  {paAdList}</div>:''}
		  {zendeskListQty >0?
			  <div className="neoxacord">
		  <label>Zendesk</label>
		  {zendeskList}</div>:''}
		  {clientb2bListQty >0?
			  <div className="neoxacord">
		  <label>Clients Partenaires</label>
		  {clientb2bList}</div>:''}
 */}


		  {/* {zendeskListQty >0?
			  <div className="neoxacord">
		  <label>Zendesk</label>
		  {zendeskList}</div>:''} */}

		  {zendeskListQty >0?
		  <div className="card card-primary card-outline">
			<div className="card-header">
			<h5 className="m-0">Zendesk</h5>
			</div>
			<div className="card-body"> 
			<div className="neoxacord">{zendeskList}</div>
			</div>
		</div>:''}	  

		  {(clientListQty===0 && clientsAdditionalListQty===0 && clientcnyListQty===0 && 
		  paListQty===0 && paAdListQty===0 && zendeskListQty===0 && clientb2bListQty===0 && isSearchActive===true)?
			<div className="form-group red">Aucun résultat pour la recherche.</div>:''
		  }
		   
		  {/* <div className="row"></div>
		  <label>auuuuuutres ------ </label> */}
		
	  </div>
	);
  }

 


  CustomizedAccordionsdata= () => { 
	const { t } = this.props;
	const [expanded, setExpanded] = React.useState('panel1');
  
	const handleChange = (panel) => (event, newExpanded) => {
	  setExpanded(newExpanded ? panel : false);
	};
  
	//<Accordion TransitionProps={{ unmountOnExit: true }} /> 
	//<Icon className="fa fa-plus-circle" fontSize="small" />
	//expandIcon={expanded === 'panel1'?<ExpandMoreIcon />:<Minimize/>}>
	
	//const neoSearchData=this.props.neoSearchData; //conflictList; 
	const isSearchActive=this.state.isSearchActive; 
	let clientListg=[];  let zendeskListg=[];   
	
	// if(conflictList===null || conflictList.clients===undefined){  //|| conflictList.searchSubPartsTop===undefined
	// 	return(<div></div>);
	// }

	const confictxList=[];	
	let clientListQty = 0; let px = 0; 
	let clientcnyListQty = 0;  let zendeskListQty = 0;  let clientb2bListQty = 0;  
	let clientsAdditionalListQty = 0; let paListQty = 0; let paAdListQty = 0;     
	 
	for(let neoSearch of this.props.neoSearchData){ 
		//const conflictList=this.props.neoSearchData; 
	//let clientListQty = 0;
	px++;
	const conflictList=JSON.parse(neoSearch.result); 
	let neoSearchStatus="zqwNDICItx9_x";
	  if(neoSearch.status==="4"){
        neoSearchStatus="zqwNDICItx7_x";
      }else if(neoSearch.status==="5"){
        neoSearchStatus="zqwNDICItx8_x";
      }else if(neoSearch.status==="3"){
        neoSearchStatus="zqwNDICItx6_x";
      }else if(neoSearch.status==="2"){
        neoSearchStatus="zqwNDICItx5_x";
      }else if(neoSearch.status==="1"){
        neoSearchStatus="zqwNDICItxl0_x";
      }else if(neoSearch.status==="0"){
        neoSearchStatus="zqwNDICItxl1_x";
      }else{
        neoSearchStatus="none";
      }
	//console.log("neoSearchDataneoSearchData ", conflictList);
	const clientList = conflictList.clients!==undefined && conflictList.clients.map((elemObj, iz) => { 
		const rndKey=Math.floor(Math.random()*100);	
		let countQty=0;	
		const detExisted=elemObj.detExisted;
		const i=""+px+iz;  
		const clientCases =elemObj.cases!==undefined && elemObj.cases!==null 
		 &&  elemObj.cases.map((subelemObj, k) => { 
			countQty++;
			if(detExisted!==undefined){
				if(detExisted==="8200"){}else{
					return(<div className="form-group" key={"subMyXydet"+i+k}>
				{k >0?<hr/>:''}
				#Ticket: {subelemObj.ticket_id}<br/>
				#Tél: {subelemObj.cell}<br/>
				Email: {subelemObj.email}<br/> 
				<div className="well-panel-info2">
				<b>Autres Détails:</b><br/> 
				<p dangerouslySetInnerHTML={ { __html: subelemObj.details } }></p>
				</div> 
				Zendesk: <a href={"https://neolegal.zendesk.com/agent/tickets/"+subelemObj.ticket_id} target="_blank">ici</a><br/>
				Dossier Client: <a href={"https://crm2.neolegal.ca/zendesk/"+subelemObj.ticket_id+"/ticket"} target="_blank">ici</a><br/>  
			</div>);
				}
			}

			return(<div className="form-group" key={"subclidet"+i+k}>
				{k >0?<hr/>:''}
				#Ticket: {subelemObj.ticket_id}<br/>
				#Tél: {subelemObj.cell}<br/>
				Email: {subelemObj.email}<br/> 
				Adresse: {subelemObj.address+', '+subelemObj.city+', ('+subelemObj.province+') '+subelemObj.cp}<br/> 
				Zendesk: <a href={"https://neolegal.zendesk.com/agent/tickets/"+subelemObj.ticket_id} target="_blank">ici</a><br/>
				Dossier Client: <a href={"https://crm2.neolegal.ca/zendesk/"+subelemObj.ticket_id+"/ticket"} target="_blank">ici</a><br/>  
				{/* <b>Autres Détails:</b><br/> 
				<p dangerouslySetInnerHTML={ { __html: subelemObj.details } }></p> */}
			</div>);
		}, this);

		// if(countQty===0){
		// 	return null;
		// }
		clientListQty++;
		if(detExisted!==undefined){
			if(detExisted==="8200"){}else{
				return (<Accordion square expanded={expanded === 'panelMyXclia'+i} onChange={handleChange('panelMyXclia'+i)} 
		key={"mycliax"+i}>
		<AccordionSummary aria-controls={"panelMyXclia"+i+"d-content"} id={"panelMyXclia"+i+"d-header"}>
		{expanded === 'panelMyXclia'+i? <MinimizeIcon/>:<AddIcon/> }  
		  <Typography>{elemObj.firstname+" "+elemObj.lastname+" "+elemObj.cell}</Typography>
		</AccordionSummary>
		<AccordionDetails>
			{clientCases}
		</AccordionDetails>
	  </Accordion>);
			}
		}

		return (<Accordion square expanded={expanded === 'panelcli'+i} onChange={handleChange('panelcli'+i)} 
		key={"clix"+i}>
		<AccordionSummary aria-controls={"panelcli"+i+"d-content"} id={"panelcli"+i+"d-header"}>
		{expanded === 'panelcli'+i? <MinimizeIcon/>:<AddIcon/> }  
		  <Typography>{elemObj.firstname+" "+elemObj.lastname+" "+elemObj.cell} 
		  {countQty===0?'':'('+countQty+')'}</Typography>
		</AccordionSummary>
		<AccordionDetails>
			<div className="form-group well-panel-info3" key={"00subclidet"+i}> 
				#Tél: {elemObj.cell}<br/>
				Email: {elemObj.email}<br/> 
				Occupation: {elemObj.occupation}<br/> 
				Adresse Actuelle: {elemObj.address+', '+elemObj.city+', ('+elemObj.province+') '+elemObj.cp}<br/> 
			</div>
			{clientCases}
		</AccordionDetails>
	  </Accordion>);
	  }, this);
  
	  clientListg.push(
		<div className="form-rowhard" key={"clientListg"+px}><div className="form-group"> 
			Recherche: {neoSearch.search}<br/> 
			{t("zqwNDICItx2_x")}: {t(neoSearchStatus)} <br/> 
		</div></div>);
	  clientListg.push(clientList);
	  //clientListg.push(<div className="form-rowhard"> &nbsp; </div>);

	//   let clientcnyListQty = 0;
	//   let clientsAdditionalListQty = 0; let paListQty = 0; let paAdListQty = 0;     

	  /*
	  const clientcnyList = conflictList.clientsCny.map((elemObj, i) => { 
		const rndKey=Math.floor(Math.random()*100);		  
		const clientCases =elemObj.cases!==undefined && elemObj.cases!==null 
		 &&  elemObj.cases.map((subelemObj, k) => { 
			return(<div className="form-group" key={"subclicnydet"+i+k}>
				{k >0?<hr/>:''}
				#Ticket: {subelemObj.ticket_id}<br/>
				#Tél: {subelemObj.cell}<br/>
				Email: {subelemObj.email}<br/> 
				<b>Autres Détails:</b><br/> 
				<p dangerouslySetInnerHTML={ { __html: subelemObj.details } }></p> 
				Zendesk: <a href={"https://neolegal.zendesk.com/agent/tickets/"+subelemObj.ticket_id} target="_blank">ici</a><br/>
				Dossier Client: <a href={"https://crm2.neolegal.ca/zendesk/"+subelemObj.ticket_id+"/ticket"} target="_blank">ici</a><br/>  
			</div>);
		}, this);

		clientcnyListQty++;

		return (<Accordion square expanded={expanded === 'panelclicny'+i} onChange={handleChange('panelclicny'+i)} 
		key={"clicnyx"+i}>
		<AccordionSummary aria-controls={"panelclicny"+i+"d-content"} id={"panelclicny"+i+"d-header"}>
		{expanded === 'panelclicny'+i? <MinimizeIcon/>:<AddIcon/> }  
		  <Typography>{elemObj.firstname+" "+elemObj.lastname+" "+elemObj.cell}</Typography>
		</AccordionSummary>
		<AccordionDetails>
			{clientCases}
		</AccordionDetails>
	  </Accordion>);
	  }, this);

	  let clientsAdditionalListQty = 0;
	  const clientsAdditionalList = conflictList.clientsAdditional.map((elemObj, i) => { 
		const rndKey=Math.floor(Math.random()*100);		  
		const dataCases =elemObj.cases!==undefined && elemObj.cases!==null 
		 &&  elemObj.cases.map((subelemObj, k) => { 
			return(<div className="form-group" key={"subcliadet"+i+k}>
				{k >0?<hr/>:''}
				#Ticket: {subelemObj.ticket_id}<br/>
				#Tél: {subelemObj.cell}<br/>
				Email: {subelemObj.email}<br/> 
				<b>Autres Détails:</b><br/> 
				<p dangerouslySetInnerHTML={ { __html: subelemObj.details } }></p>
				Zendesk: <a href={"https://neolegal.zendesk.com/agent/tickets/"+subelemObj.ticket_id} target="_blank">ici</a><br/>
				Dossier Client: <a href={"https://crm2.neolegal.ca/zendesk/"+subelemObj.ticket_id+"/ticket"} target="_blank">ici</a><br/>  
			</div>);
		}, this);

		clientsAdditionalListQty++; 

		return (<Accordion square expanded={expanded === 'panelclia'+i} onChange={handleChange('panelclia'+i)} 
		key={"cliax"+i}>
		<AccordionSummary aria-controls={"panelclia"+i+"d-content"} id={"panelclia"+i+"d-header"}>
		{expanded === 'panelclia'+i? <MinimizeIcon/>:<AddIcon/> }  
		  <Typography>{elemObj.firstname+" "+elemObj.lastname+" "+elemObj.cell}</Typography>
		</AccordionSummary>
		<AccordionDetails>
			{dataCases}
		</AccordionDetails>
	  </Accordion>);
	  }, this);

	  let paListQty = 0;
	  const paList = conflictList.pa.map((elemObj, i) => { 
		const rndKey=Math.floor(Math.random()*100);		  
		const dataCases =elemObj.cases!==undefined && elemObj.cases!==null 
		 &&  elemObj.cases.map((subelemObj, k) => { 
			return(<div className="form-group" key={"padet"+i+k}>
				{k >0?<hr/>:''}
				#Ticket: {subelemObj.ticket_id}<br/>
				#Tél: {subelemObj.cell}<br/>
				Email: {subelemObj.email}<br/> 
				<b>Autres Détails:</b><br/> 
				<p dangerouslySetInnerHTML={ { __html: subelemObj.details } }></p>
				Zendesk: <a href={"https://neolegal.zendesk.com/agent/tickets/"+subelemObj.ticket_id} target="_blank">ici</a><br/>
				Dossier Client: <a href={"https://crm2.neolegal.ca/zendesk/"+subelemObj.ticket_id+"/ticket"} target="_blank">ici</a><br/>  
			</div>);
		}, this);

		paListQty++;

		return (<Accordion square expanded={expanded === 'panelpa'+i} onChange={handleChange('panelpa'+i)} 
		key={"pax"+i}>
		<AccordionSummary aria-controls={"panelpa"+i+"d-content"} id={"panelpa"+i+"d-header"}>
		{expanded === 'panelpa'+i? <MinimizeIcon/>:<AddIcon/> }  
		  <Typography>{elemObj.firstname+" "+elemObj.lastname+" "+elemObj.cell}</Typography>
		</AccordionSummary>
		<AccordionDetails>
			{dataCases}
		</AccordionDetails>
	  </Accordion>);
	  }, this);

	  let paAdListQty = 0;
	  const paAdList = conflictList.opAdditional.map((elemObj, i) => { 
		const rndKey=Math.floor(Math.random()*100);		  
		const dataCases =elemObj.cases!==undefined && elemObj.cases!==null 
		 &&  elemObj.cases.map((subelemObj, k) => { 
			return(<div className="form-group" key={"paaddet"+i+k}>
				{k >0?<hr/>:''}
				#Ticket: {subelemObj.ticket_id}<br/>
				#Tél: {subelemObj.cell}<br/>
				Email: {subelemObj.email}<br/> 
				<b>Autres Détails:</b><br/> 
				<p dangerouslySetInnerHTML={ { __html: subelemObj.details } }></p>
				Zendesk: <a href={"https://neolegal.zendesk.com/agent/tickets/"+subelemObj.ticket_id} target="_blank">ici</a><br/>
				Dossier Client: <a href={"https://crm2.neolegal.ca/zendesk/"+subelemObj.ticket_id+"/ticket"} target="_blank">ici</a><br/>  
			</div>);
		}, this);

		paAdListQty++;

		return (<Accordion square expanded={expanded === 'panelpaad'+i} onChange={handleChange('panelpaad'+i)} 
		key={"paadx"+i}>
		<AccordionSummary aria-controls={"panelpaad"+i+"d-content"} id={"panelpaad"+i+"d-header"}>
		{expanded === 'panelpaad'+i? <MinimizeIcon/>:<AddIcon/> }  
		  <Typography>{elemObj.firstname+" "+elemObj.lastname+" "+elemObj.cell}</Typography>
		</AccordionSummary>
		<AccordionDetails>
			{dataCases}
		</AccordionDetails>
	  </Accordion>);
	  }, this);*/


	  //let zendeskListQty = 0;  
	  const zendeskList = conflictList.zendesk!==undefined && conflictList.zendesk.map((elemObj, iz) => { 
		//const rndKey=Math.floor(Math.random()*100);	
		
		zendeskListQty++;
		const i=""+px+iz;  
		return (<Accordion square expanded={expanded === 'panelzen'+i} onChange={handleChange('panelzen'+i)} 
		key={"zenx"+i}>
		<AccordionSummary aria-controls={"panelzen"+i+"d-content"} id={"panelzen"+i+"d-header"}>
		{expanded === 'panelzen'+i? <MinimizeIcon/>:<AddIcon/> }  
		  <Typography>{elemObj.termsearch+" "+elemObj.ticket_id}</Typography>
		</AccordionSummary>
		<AccordionDetails>
			<div className="form-group" key={"subzendet"+i}> 
				#Ticket: {elemObj.ticket_id}<br/> 
				Fonction: {elemObj.clientRole}<br/> 
				<div className="well-panel-info2">
				<b>Autres Détails:</b><br/> 
				<p dangerouslySetInnerHTML={ { __html: elemObj.details } }></p>
				</div>
				Zendesk: <a href={"https://neolegal.zendesk.com/agent/tickets/"+elemObj.ticket_id} target="_blank">ici</a><br/>
				Dossier Client: <a href={"https://crm2.neolegal.ca/zendesk/"+elemObj.ticket_id+"/ticket"} target="_blank">ici</a><br/>  
			</div>
		</AccordionDetails>
	  </Accordion>);
	  }, this);

	  zendeskListg.push(
		<div className="form-rowhard" key={"zendeskListg"+px}><div className="form-group"> 
	  	Recherche: {neoSearch.search}<br/> 
		  {t("zqwNDICItx2_x")}: {t(neoSearchStatus)} <br/>    
	  </div></div>);
	  zendeskListg.push(zendeskList);
	  //zendeskListg.push(<div className="form-rowhard"> &nbsp; </div>);

	  //let clientb2bListQty = 0;
	  /*const clientb2bList = conflictList.clientsb2b.map((elemObj, i) => { 
		const rndKey=Math.floor(Math.random()*100);	
		let countQty=0;	 
		// if(countQty===0){
		// 	return null;
		// }
		clientb2bListQty++; 
		return (<Accordion square expanded={expanded === 'panelclib2b'+i} onChange={handleChange('panelclib2b'+i)} 
		key={"clib2bx"+i}>
		<AccordionSummary aria-controls={"panelclib2b"+i+"d-content"} id={"panelclib2b"+i+"d-header"}>
		{expanded === 'panelcli'+i? <MinimizeIcon/>:<AddIcon/> }  
		  <Typography>{elemObj.firstname+" "+elemObj.lastname+" "+elemObj.tel} 
		  {countQty===0?'':'('+countQty+')'}</Typography>
		</AccordionSummary>
		<AccordionDetails>
			<div className="form-group" key={"00subclib2bdet"+i}> 
				#Tél: {elemObj.tel}<br/>
				#Police: {elemObj.id_external}<br/> 
				Email: {elemObj.email}<br/> 
				Adresse: {elemObj.address+', '+elemObj.city+', ('+elemObj.province+') '+elemObj.postal_code}<br/> 
			</div> 
		</AccordionDetails>
	  </Accordion>);
	  }, this);*/

	} 

	const open=this.state.openNeoSearch;

	  console.log("track (clientListQty="+clientListQty+" && clientsAdditionalListQty="+clientsAdditionalListQty+" && clientcnyListQty="+clientcnyListQty+" && paListQty="+paListQty+" && paAdListQty="+paAdListQty+" && zendeskListQty="+zendeskListQty+" && clientb2bListQty="+clientb2bListQty+" && isSearchActive="+isSearchActive+")");

	return (
	  <div> 
		  {/* {clientListQty >0? */}
		  <div className="card card-primary card-outline">
			<div className="card-header">
			<h5 className="m-0">Clients</h5>
			</div>
			<div className="card-body"> 
			<div className="neoxacord">{clientListg}</div>
			</div>
		</div> 	  

  

		  {/* {zendeskListQty >0? */}
		  <div className="card card-primary card-outline">
			<div className="card-header">
			<h5 className="m-0">Zendesk</h5>
			</div>
			<div className="card-body"> 
			<div className="neoxacord">{zendeskListg}</div>
			</div>
		</div>   

		  {(clientListQty===0 && clientsAdditionalListQty===0 && clientcnyListQty===0 && 
		  paListQty===0 && paAdListQty===0 && zendeskListQty===0 && clientb2bListQty===0 && isSearchActive===true)?
			<div className="form-group red">Aucun résultat pour la recherche.</div>:''
		  }
		   
		  {/* <div className="row"></div>
		  <label>auuuuuutres ------ </label> */}
		
	  </div> 
	);
  }

  handleCloseNeoSearch  = (anyEvent) => {  
    console.log("OLD STYLE SUCCESS handleCloseNeoSearch ");  
    this.setState({["openNeoSearch"]: false });  
  }

  handleOpenNeoSearch  = (anyEvent) => {     
    this.setState({["openNeoSearch"]: true }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
    //this.setState(this.stateInit);
    console.log("OLD STYLE SUCCESS handleOpenNeoSearch ");  

  }


  handleCloseHelpInfo  = (anyEvent) => {  
    //console.log("OLD STYLE SUCCESS handleCloseHelpInfo");  
    this.setState({["openHelpInfo"]: false, ["openMessageInfo"]: false, }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
    //this.setState(this.stateInit);

  }

  handleOpenHelpInfo  = (anyEvent) => {     
    this.setState({["openHelpInfo"]: true }); 
    //this.showToast("Premiere etape, veuillez patieter un instant", "success");
    //this.setState(this.stateInit);

  }

  HelpInfoAsOnTheSite = (event) => {
    const { t } = this.props;
    //const { t, i18n } = useTranslation("common"); 
    const myLang=t("curlang");
    if(myLang==="fr"){
      return this.xHelpInfoAsOnTheSiteFr(event);
    }else{
      return this.xHelpInfoAsOnTheSiteEn(event);
    }
  }

  xHelpInfoAsOnTheSiteFr = (event) => {  
    const open = this.state.openHelpInfo; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
    const [value, setValue] = React.useState(0); 
    let nomenu=0;  

    //const { t, i18n } = useTranslation("common");
    //const listAddress=this.state.listAddressOnCode;
  
    return (
      <div>
        <Dialog maxWidth={"sm"} open={open} onClose={this.handleCloseHelpInfo} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirm">
          <DialogContent id="form-dialog-confirm"> 
            <div className="text-center"><h2>Aide sur la recherche  
              &nbsp; &nbsp; <a href="#" onClick={this.handleCloseHelpInfo}><CloseIcon /></a>
              </h2></div>  
            <div className="form-row neoxtbl">
             <p>Voici quelques tips concernant la recherche de conflits d'intérêts:</p>
			 <ul className="list-dashed ">
			 	<li>utiliser prénom et nom ou le nom et prénom pour une recherche plus précise</li>
				<li>pour lancer une recherche tu dois utiliser au moins 3 charactères du nom ou prénom</li>
				<li>tu peux utiliser tout le prénom ou le nom ou une partie pour faire une recherche</li>
				<li>utiliser le bouton <button type="button" className="btn btn-danger  btn-sm"><DeleteIcon /></button> pour vider tout la boite de texte et les résultats.</li>
				<li>tu peux utiliser aussi le % dans certains cas. Par exemple: "je% tremblay" 
					le resultat apportera toute personne qui commence avec je dans son nom ou prénom 
					et tremblay comme nom ou prénom.   </li>
				<li>pas de distinction entre majuscule et minuscule</li>	
			</ul>


              <div className="form-group col-md-9">
              <br/>
              <input type="button" onClick={this.handleCloseHelpInfo} className="btn btn-default" value={t('Close_x')} /> &nbsp; &nbsp;
               
              </div> 
            </div>
              
          </DialogContent>
          
        </Dialog>
      </div>
    );
  }

  xHelpInfoAsOnTheSiteEn = (event) => {
    //this.showToast("The translation is not available for this option.", "warning");
    //return("");
	const open = this.state.openHelpInfo; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
    const [value, setValue] = React.useState(0); 
    let nomenu=0;  

    //const { t, i18n } = useTranslation("common");
    //const listAddress=this.state.listAddressOnCode;
  
    return (
      <div>
        <Dialog maxWidth={"sm"} open={open} onClose={this.handleCloseHelpInfo} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirm">
          <DialogContent id="form-dialog-confirm"> 
            <div className="text-center"><h2>Search help  
              &nbsp; &nbsp; <a href="#" onClick={this.handleCloseHelpInfo}><CloseIcon /></a>
              </h2></div>  
            <div className="form-row neoxtbl">
             <p>Here are some tips on searching conflicts of interest:</p>
			 {/* <ul className="list-dashed ">
				<li>pour lancer une recherche tu dois utiliser au moins 3 charactères du nom ou prenom</li>
				<li>tu peux utiliser prenom et nom ou le nom et prenom pour chercher</li>
				<li>utiliser le bouton <button type="button" className="btn btn-danger  btn-sm"><DeleteIcon /></button> pour vider tout la boite de texte et les resultats.</li>
				<li>tu peux utiliser aussi le % dans certains cas. Example: je% tremblay 
					le resultat apporterar toute personne qui commence avec je dans son nom ou prenom 
					et tremblay comme nom ou prenom.   </li>
				<li>pas de distinction entre majuscule et minuscule</li>	
			</ul> */}

			<ul className="list-dashed">
				<li>use first name and last name or last name and first name for a more specific search</li>
				<li>to launch a search you must use at least 3 characters of the name or first name </li>
				<li>you can use the whole first or last name or part of it to search</li>
				<li>use the button <button type="button" className="btn btn-danger  btn-sm"><DeleteIcon /></button> to empty all the text box and the search results. </li>
				<li>you can also use the % in some cases. For example: "je% tremblay"
				the result will bring any person who starts with je in their firs tname or last name
				and tremblay as a first name or last name. </li>
				<li>no distinction between upper and lower case </li>
			</ul>


              <div className="form-group col-md-9">
              <br/>
              <input type="button" onClick={this.handleCloseHelpInfo} className="btn btn-default" value={t('Close_x')} /> &nbsp; &nbsp;
               
              </div> 
            </div>
              
          </DialogContent>
          
        </Dialog>
      </div>
    );
  }

  
  MessageInfoPopup = (event) => {
    //this.showToast("The translation is not available for this option.", "warning");
    //return("");
	const open = this.state.openMessageInfo; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}  
    const { t } = this.props;
    //const [value, setValue] = React.useState(0); 
    let nomenu=0; 
	const messageInfo = this.state.messageInfo;  

    //const { t, i18n } = useTranslation("common");
    //const listAddress=this.state.listAddressOnCode;
	if(open===false){
		return null;
	}
  
    return (
      <div>
        <Dialog maxWidth={"md"} open={open} onClose={this.handleCloseHelpInfo} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-confirm">
          <DialogContent id="form-dialog-confirm"> 
            {/* <div className="text-center"><h2>Message Info  
              &nbsp; &nbsp; <a href="#" onClick={this.handleCloseHelpInfo}><CloseIcon /></a>
              </h2></div>   */}
            <div className="form-row neoxtbl">
			<p>
				 &nbsp; 
			 </p>
             <p>
				 {messageInfo}
			 </p>  

              <div className="form-group col-md-9">
              <br/>
              <input type="button" onClick={this.handleCloseHelpInfo} className="btn btn-default" value={t('Close_x')} /> &nbsp; &nbsp;
               
              </div> 
            </div>
              
          </DialogContent>
          
        </Dialog>
      </div>
    );
  }


  



}

//const TransNdiFieldsForm = withTranslation()(NdiFieldsForm); 
const mapStateToProps = (state) => ({ NdiFields: state.NdiFields });

//const mapDispatchToProps = { addOrUpdateNdiFields, getNdiFields, getNdiTagsAll, getSearchConflict };   
const mapDispatchToProps = { getSearchConflict, setSearchConflict };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NdiInterestConflict));
