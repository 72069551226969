import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCalendar,
  faPaperclip,
  faDownload,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { API_DOMAIN } from "../../../../../../appConfig";
import { getTokenAndId } from "../../../../../../appConfigaxios";
import IconButton from "../../../../IconButton/IconButton";

//const URL = "https://apidoc.neodesk.app/api/v2";
const URL = API_DOMAIN;

export default class Note extends React.Component {
  renderAttachments = (att = []) => {
    if (!att) return [];
    else if (Array.isArray(att)) return att;
    else if (typeof att === "object") return Object.values(att);
  };

  genNoteType = (noteType = "") => {
    const { lang } = this.props;
    if (!noteType) return "";
    else if (typeof noteType === "string") return noteType;
    else if (typeof noteType === "object") return noteType[lang || "fr"];
    return "";
  };

  handleDownload = async (url, filename = "file") => {
    if (url.includes("zendesk") && !url.includes("docx")) {
      let blob = await fetch(url).then((r) => r.blob());
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    } else {
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    }
  };

  genDownloadURL = (url, filename, bucket) => {
    const tokobj = getTokenAndId();
    const token = tokobj.token;
    const userid = tokobj.userid;
    return (
      URL +
      "/files/getfile?url=" +
      url +
      "&token=" +
      token +
      //localStorage.getItem("token") +
      "&action=download" +
      "&filename=" +
      filename +
      "&bucket=" +
      bucket +
      "&auth_id=" +
      userid.replace(/\s+/g, "")
      //AUTH_ID.replace(/\s+/g, "")
    );
  };

  genPreviewURL = (url, filename, bucket) => {
    const tokobj = getTokenAndId();
    const token = tokobj.token;
    const userid = tokobj.userid;
    return (
      URL +
      "/files/getfile?url=" +
      url +
      "&token=" +
      token +
      "&action=preview" +
      "&filename=" +
      filename.normalize("NFD").replace(/[\u0300-\u036f]/g, "") +
      "&bucket=" +
      bucket +
      "&auth_id=" +
      userid
    );
  };

  render() {
    const { id, note, date, user, noteObj } = this.props;
    const {
      date_created,
      pinned,
      actionid,
      isPublic,
      type,
      attachments,
      noteType,
    } = noteObj;
    const attachmentsDocs = [];
    let keyID = noteObj.id; //0;
    let idzendesk = "";
    let isPrivate = true;
    const renderedAttachments = this.renderAttachments(attachments);
    if (
      (noteObj.actionid && noteObj.actionid === "ca-02") ||
      noteObj.isPublic
    ) {
      isPrivate = false;
    }

    return (
      <div
        className="messageArchiveContainer"
        key={"mac" + keyID + "idz" + idzendesk}
      >
        <h6>
          <FontAwesomeIcon icon={faUser} /> {user}{" "}
          <FontAwesomeIcon
            icon={faCalendar}
            size={"sm"}
            style={{ marginLeft: "1rem" }}
          />{" "}
          {moment(date).format("LLL")} (#{keyID})
          {idzendesk !== "" ? " /zendesk #" + idzendesk : ""}
        </h6>
        <div
          key={"booooooooook" + keyID + "idz" + idzendesk}
          className={isPrivate ? "messageArchive" : "messageArchive2"}
          dangerouslySetInnerHTML={{ __html: note }}
        />
        {renderedAttachments?.length ? (
          <>
            {renderedAttachments?.map((i) => {
              const isDocx = i?.value?.includes("docx");
              let downloadURL;
              let previewURL;
              if (type === "zendesk") {
                downloadURL = i.content_url;
                previewURL = i.content_url;
              } else {
                downloadURL = this.genDownloadURL(
                  i.value,
                  i.file_name,
                  i.bucket
                );
                previewURL = this.genPreviewURL(i.value, i.file_name, i.bucket);
              }
              // console.log(previewURL)
              // console.log(downloadURL)
              return (
                <div key={i?.file_name} className="notes-attachments">
                  <FontAwesomeIcon icon={faPaperclip} /> {i.file_name}{" "}
                  <span>
                    {i?.inline !== false && !isDocx && (
                      <IconButton
                        onClick={() => this.props.setPreviewUrl(previewURL)}
                        tooltip={"Aperçu"}
                        icon={faEye}
                        style={{ color: "66b6f3" }}
                      />
                    )}
                    <IconButton
                      onClick={() =>
                        this.handleDownload(downloadURL, i.file_name)
                      }
                      icon={faDownload}
                      tooltip={"Télécharger"}
                      style={{ color: "#66b6f3" }}
                    />
                  </span>
                </div>
              );
            })}
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}
