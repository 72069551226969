import React from "react";

import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    NavLink,
    ButtonGroup
    // Table
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faCoins,
    faTachometerAlt,
    faTags,
    faFilter,
    faGlobeAmericas,
    faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import DashboardElement from '../../../../utils/DashboardElement'
export default class Widget extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return (
            // <Nav
            //     className='widgets'
            //     style={{

            //     }}

            //     vertical
            // >
            //     <NavItem>
            //         <Row>
                        <DashboardElement
                            title={"Performance"}
                            col={12}
                        >
                            <h5 style={{}}>Moi</h5>
                            <div style={{ fontSize: '40pt' }}>
                                <FontAwesomeIcon icon={faTachometerAlt} color="#0052BB" />
                            </div>
                            <h5 style={{}}>Équipe</h5>
                            <div style={{ fontSize: '40pt' }}>
                                <FontAwesomeIcon icon={faTachometerAlt} color="#0052BB" />
                            </div>
                        </DashboardElement>
            //         </Row>
            //     </NavItem>
            //     {/* </Nav> */}
            // </Nav>
        )
    }
}