import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import messagesEn from './locales/en/messages';
import messagesFr from './locales/fr/messages';
import messagesEs from './locales/es/messages';

import messagesEn2 from './srcndi/locales/en/messages';
import messagesFr2  from './srcndi/locales/fr/messages';
import messagesEs2  from './srcndi/locales/es/messages';

// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
	resources: {
		en: {common: messagesEn, commonndi: messagesEn2},
		fr: {common: messagesFr, commonndi: messagesFr2},
		es: {common: messagesEs, commonndi: messagesEs2}
	}
  });


export default i18n;