import React, { Component } from "react";
import { Button } from "reactstrap";
import { Menu, Popover, Tooltip } from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import IconButton from "../IconButton/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTimes,
  faUser,
  faEllipsisV,
  faEllipsisH,
  faPlusCircle,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import "./ConfirmPopover.css";

export default class ConfirmPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: "",
    };
  }

  handleClose = () => {
    this.setState({ anchorEl: "" });
  };

  render() {
    const { anchorEl } = this.state;
    const {
      icon,
      tooltip,
      style,
      size,
      buttonStyle,
      onSubmit,
      message,
      buttonText,
      primaryButtonText,
    } = this.props;
    console.log(style);
    return (
      <>
        {icon ? (
          <IconButton
            icon={icon}
            tooltip={tooltip}
            onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
            style={style}
            buttonStyle={buttonStyle}
            size={size}
          ></IconButton>
        ) : (
          <Tooltip title={tooltip ? tooltip : ""}>
            <Link
              style={style}
              onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
            >
              {primaryButtonText ? primaryButtonText : ""}
            </Link>
          </Tooltip>
        )}
        <Popover
          PaperProps={{
            elevation: 24,
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={anchorEl !== ""}
          anchorEl={anchorEl}
          onClose={this.handleClose}
        >
          <div className="confirm-popover">
            <div className="confirm-popover-close-btn">
              <Button close onClick={this.handleClose} />
            </div>
            <div className="confirm-popover-message">{message}</div>
            <div className="confirm-button">
              <Button
                size="sm"
                style={{ borderRadius: "50px" }}
                color="primary"
                onClick={onSubmit}
                block
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </Popover>
      </>
    );
  }
}
