//export const API_DOMAIN = 'http://localhost:8080/v1'; 
//export const API_DOMAIN = 'http://localhost:8000/api';

//export const APP_PATHNAME = '/zendesk/';  // /admin/ /zendesk/
//export const APP_PATHNAME = '/neosales/';

//export const API_DOMAIN = 'http://127.0.0.1:8055/api'; 

function getPathNameForApp(){
    const locurl=window.location.href;
    //const patt=/([0-9]+)(\/edit)$/;
    const patt=/(\/admin\/)/;
    console.log("LOOOOOKING FOR PATH admin? "+patt.test(locurl)); 
    if(patt.test(locurl)){ 
		return '/admin/';
	}else{ 
        return '/zendesk/'; 
    }
}

//export const APP_PATHNAME = '/zendesk/';  // /admin/ /zendesk/
//export const APP_PATHNAME = getPathNameForApp();  // /admin/ /zendesk/
 
export const APP_PATHNAME = '/'; 
//export const API_DOMAIN = 'http://127.0.0.1:8055/api'; 
//export const API_DOMAIN = 'https://api.neolegal.ca';
//export const STRIPE_PROMISE = "pk_test_51HEQYwBCqOPPc1MwfpUEcue9GeNMjfLLsO4sjhAJ2lOF5mL2iuqaEGx9pNQsGjrWN3sR3ae7akg2ApUXdAavWbSo008mevIfUK";
//export const listOfRefundAgent=[38,31,32,33];
  


// export const API_DOMAIN =process.env.REACT_APP_API_URL;
// export const APP_PATHNAME = process.env.REACT_APP_PATHNAME; 

 export const API_DOMAIN = 'https://api.neolegal.ca'
 export const STRIPE_PROMISE = "pk_live_QqtM2wtY9N8yrbv5AEzm1Swm"; 
 export const listOfRefundAgent=[1332,445,16293]; //Aime Gen Leidina

  
