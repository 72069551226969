import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getNeoProspectsSms, deleteNeoProspectsSms } from '../../actions/NeoProspectsSmsAction';

class NeoProspectsSmsInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		message:'', e_message: false,
		ticket_id:'', e_ticket_id: false,
		client_id:'', e_client_id: false,
		email:'', e_email: false,
		user_creation:'', e_user_creation: false,
		user_update:'', e_user_update: false,
		creation_date:'', e_creation_date: false,
		update_date:'', e_update_date: false,
		status:'', e_status: false,
    }
  }
  componentDidMount() {                                                         
    //this.props.getNeoProspectsSms(this.props.match.params.id);
    if(this.props.prospectsSms!==undefined){
      this.setState(this.props.prospectsSms); 
    }else{ 
      const idx = this.props.match.params.id;  
      Promise.resolve(this.props.getNeoProspectsSms(idx))
      .then((response)=>{
        //console.log("0000********INI**********"); console.log(response.NeoProspectsSmsOne);  console.log("0000********FIN**********");
        this.setState(response.NeoProspectsSmsOne); 
        return response;
      });
    }
  }

  render() {
    const { t } = this.props;
    //const NeoProspectsSms = this.props.NeoProspectsSms;
    const NeoProspectsSms = this.state;
    //status if 0:cancelled  1: open/created, 2:closed
    const message= NeoProspectsSms.message.replace("\n", "<br/>");
    const status=["cancelled","OK/created","closed"];
    const statusx=status[parseInt(NeoProspectsSms.status)];
    return (
      <div>
        <h2>Prospects Sms</h2>
        <hr/>
        <p><label>{t('zqwid_x')}</label> &nbsp; &nbsp; #{NeoProspectsSms.id}</p>
        {/* <p><label>{t('zqwmessage_x')}</label><br/>{NeoProspectsSms.message}</p> */}
        <p><label>{t('zqwmessage_x')}</label></p>
        <div dangerouslySetInnerHTML={{ __html: message }}></div>
        <p><label>{t('zqwticket_id_x')}</label><br/>{NeoProspectsSms.ticket_id}</p>
        <p><label>{t('zqwclient_id_x')}</label><br/>{NeoProspectsSms.client_idtxt} (#{NeoProspectsSms.client_id})</p>
        <p><label>{t('zqwemail_x')}</label><br/>{NeoProspectsSms.email}</p>
        <p><label>{t('zqwuser_creation_x')}</label><br/>{NeoProspectsSms.user_creation}/{NeoProspectsSms.user_creationtxt}</p>
        <p><label>{t('zqwuser_update_x')}</label><br/>{NeoProspectsSms.user_update}/{NeoProspectsSms.user_updatetxt}</p>
        <p><label>{t('zqwcreation_date_x')}</label><br/>{NeoProspectsSms.creation_date}</p>
        <p><label>{t('zqwupdate_date_x')}</label><br/>{NeoProspectsSms.update_date}</p>
        <p><label>{t('zqwstatus_x')}</label><br/>{statusx}</p>
        <hr/>
        {this.props.prospectsSms===undefined?
        <div className="btn-group">
          <Link to={{ pathname: `/NeoProspectsSms/${NeoProspectsSms.id}/edit`, state: { NeoProspectsSms: NeoProspectsSms } }} className='btn btn-info'>  
            {t('Edit')}
          </Link>
          <button className="btn btn-danger" type="button" onClick={() => this.props.deleteNeoProspectsSms(NeoProspectsSms.id)}>          
            {t('Delete')}
          </button>
          <Link to="/NeoProspectsSms" className="btn btn-secondary">{t('Close')}</Link>                                                 
        </div>:""
        }
        <hr/>
      </div>
    )
  }
}

//const TransNeoProspectsSmsInfo = withTranslation()(LegacyComponentClass);
const mapStateToProps = (state) => ({ NeoProspectsSms: state.NeoProspectsSms });                 

const mapDispatchToProps = { getNeoProspectsSms, deleteNeoProspectsSms };                        

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoProspectsSmsInfo));   

