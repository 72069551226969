import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSnowflake,
  faCloudSun,
  faFireAlt,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { display } from "../../../../_actions/spinner-actions";
import { NDIWebsite } from "../../../../../appConfig";

class LeadInfo extends React.Component {
  genLang = (text) => {
    const { trad, lang } = this.props;
    return trad["neoleads"][text][lang];
  };

  genIcon = (thermo = "") => {
    if (thermo === "1") {
      return (
        <FontAwesomeIcon
          icon={faSnowflake}
          style={{
            marginRight: "10px",
            height: "20px",
            width: "20px",
            color: "lightBlue",
          }}
        />
      );
    } else if (thermo === "2") {
      return (
        <FontAwesomeIcon
          icon={faCloudSun}
          style={{
            marginRight: "10px",
            height: "22px",
            width: "22px",
            color: "darkGrey",
          }}
        />
      );
    } else if (thermo === "3") {
      return (
        <FontAwesomeIcon
          icon={faFireAlt}
          style={{
            marginRight: "10px",
            height: "20px",
            width: "20px",
            color: "orange",
          }}
        />
      );
    }
  };

  handleStatus = (status = "") => {
    switch (status) {
      case "0":
        return this.genLang("automaticDistrubution");
      case "1":
        return this.genLang("attributed");
      case "2":
        return this.genLang("supported");
      case "3":
        return this.genLang("closed");
      case "4":
        return this.genLang("notAutomaticDistribution");
      case "5":
        return this.genLang("cancelled");
      default:
        return "";
    }
  };

  renderLeadInfo = () => {
    if (this.props.customer && this.props.customer.leadinfo) {
      const { leadinfo } = this.props.customer;
      const {
        lead_subject,
        lead_message,
        domaine,
        thermo,
        status,
        id,
        ticket_id,
        fromtrack
      } = leadinfo;
      const statusInfo = this.handleStatus(status);
      const arr = [
        { label: "subject", value: lead_subject },
        { label: "message", value: lead_message },
        { label: "status", value: statusInfo },
        { label: "leadId", value: id },
        { label: "ticketNumberZendesk", value: ticket_id },
        { label: "domain", value: domaine },
        { label: "priority", value: thermo },
      ];
      return arr.map((i) => {
        if (i.label === "priority") {
          return (
            <div key={i.label} style={{ marginTop: "0.3rem" }}>
              <span
                style={{
                  marginBottom: "0.4rem",
                }}
              >
                <b style={{ color: "#0052bb", fontSize: "15px" }}>
                  <span style={{ color: "#a3a3a3", fontSize: "12px" }}>
                    {this.genLang(i.label)}:{" "}
                  </span>
                  {this.genIcon(i.value)}
                </b>
              </span>
            </div>
          );
        } else if (i.label === "ticketNumberZendesk") {
          return fromtrack === "1" ? (
            <div
              key={i.label}
              style={{
                wordBreak: "break-all",
                wordWrap: "break-space",
                width: "100%",
                marginTop: "0.3rem",
              }}
            >
              <b style={{ color: "#0052bb", fontSize: "15px" }}>
                <span style={{ color: "#a3a3a3", fontSize: "12px" }}>
                  {this.genLang(i.label)}:{" "}
                </span>
                {i.value === undefined ? "" : i.value}
              </b>
            </div>
          ) : (
            ""
          );
        } else {
          return !i.value ? (
            ""
          ) : (
            <div
              key={i.label}
              style={{
                wordBreak: "break-all",
                wordWrap: "break-space",
                width: "100%",
                marginTop: "0.3rem",
              }}
            >
              <b style={{ color: "#0052bb", fontSize: "15px" }}>
                <span style={{ color: "#a3a3a3", fontSize: "12px" }}>
                  {this.genLang(i.label)}:{" "}
                </span>
                {i.value === undefined ? "" : i.value}
              </b>
            </div>
          );
        }
      });
    }
  };

  render() {
    //this.props.customer.leadSuivi.type_for_lead==="suivi"
    const leadSuivi=this.props.leadSuivi;
    let ticket_id=0;
    if(leadSuivi && leadSuivi.type_for_lead==="suivi"){
      ticket_id=leadSuivi.ticket;
    }else{
      return null;
    }
    //const NDIWebsite=NDIWebsite


    return (
      <div className="p-15 grayish" style={{ height: "auto" }}>
        <div
          style={{ height: "auto" }}
          className="p-l-10 p-r-10 p-t-10 p-b-10 element-wrapper"
        >
          {/* <iframe src={NDIWebsite+"/zendesk/"+ticket_id+"/ticket?ifr=neosales&limTab=2"} id="iframeNDIchild0007" 
          style={{width: "100%", minHeight: "500px", border:"0"}} ></iframe>  */}
          <iframe src={NDIWebsite+"/zendesk/"+ticket_id+"/ticket?ifr=neosales&limTab=3"} id="iframeNDIchild0007" 
          style={{width: "100%", minHeight: "500px", border:"0"}} ></iframe> 
        </div>
      </div>
    );

    return (
      <div className="p-15 grayish" style={{ height: "auto" }}>
        <div
          style={{ height: "auto" }}
          className="p-l-10 p-r-10 p-t-10 p-b-10 element-wrapper"
        >
          {this.renderLeadInfo()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
});

export default connect(mapStateToProps)(LeadInfo);
