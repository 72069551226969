
export const TOGGLE_LOADING = 'TOGGLE_LOADING';

export const PUSH_WAITING_LIST = 'PUSH_WAITING_LIST';

export const PULL_WAITING_LIST = 'PULL_WAITING_LIST';
export function toggleLoading(){
    
    return {type:TOGGLE_LOADING};
}

export function pushWaitingList(params){
    
    return {
        type:PUSH_WAITING_LIST,
        params:params
    };
}

export function pullWaitingList(params){
    
    return {
        type:PULL_WAITING_LIST,
        params:params
    };
}