import React, { Component } from "react";
import "./SegmentedControlButton.css";
export default class SegmentedControlButton extends Component {
  render() {
    const { selected, options, onClick } = this.props;

    return (
      <div className="control-container">
        {options.map((i) => {
          return (
            <button
              key={i.value}
              type="button"
              className={
                selected === i.value
                  ? "option-container seg-selected"
                  : "option-container"
              }
              value={i.value}
              onClick={(e) => onClick(e)}
            >
              {i.label}
              {i.count !== undefined && (
                <div className="option-container-count-md">{i.count}</div>
              )}
            </button>
          );
        })}
      </div>
    );
  }
}

export class SegmentedControlButtonSM extends Component {
  render() {
    const { selected, options, onClick } = this.props;

    return (
      <div className="control-container-sm">
        {options.map((i) => {
          return (
            <button
              key={i.value}
              type="button"
              className={
                selected === i.value
                  ? "option-container-sm seg-selected"
                  : "option-container-sm"
              }
              value={i.value}
              onClick={(e) => onClick(e)}
            >
              {i.label}
              {i.count !== undefined && (
                <div className="option-container-count-sm">{i.count}</div>
              )}
            </button>
          );
        })}
      </div>
    );
  }
}

export class SegmentedControlButtonLG extends Component {
  render() {
    const { selected, options, onClick } = this.props;

    return (
      <div className="control-container-lg">
        {options.map((i) => {
          return (
            <button
              key={i.value}
              type="button"
              className={
                selected === i.value
                  ? "option-container-lg seg-selected"
                  : "option-container-lg"
              }
              value={i.value}
              onClick={(e) => onClick(e)}
            >
              {i.label}
            </button>
          );
        })}
      </div>
    );
  }
}
