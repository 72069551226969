import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_SALESFLOW = 'GET_SALESFLOW';
export const ADD_SALESFLOW = 'ADD_SALESFLOW';
export const RECEIVE_SALESFLOWX = 'RECEIVE_SALESFLOWX';
export const REMOVE_SALESFLOW = 'REMOVE_SALESFLOW';
export const UPDATE_SALESFLOW = 'UPDATE_SALESFLOW';
export const REPLACE_SALESFLOW = 'REPLACE_SALESFLOW';
const apiUrl = API_DOMAIN + '/salesflow'; 
// /salesflow/flow/list 
axiosDefaults(null);

export const getSalesFlowAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/flow/list`)
      .then(response => {
        dispatch({type: RECEIVE_SALESFLOW, SalesFlow: response.data.salesflow.data})
      })
      .catch(error => { console.error(error); });
  };
};

export const addSalesFlow = (SalesFlow) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id: SalesFlow.id,
		invoice: SalesFlow.invoice,
		flow: SalesFlow.flow,
		description: SalesFlow.description,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_SALESFLOW, payload: {
			id: data.id,
			invoice: data.invoice,
			flow: data.flow,
			description: data.description,
		}})
      })
      .then(() => {
        history.push("/SalesFlow")
      })
      .catch(error => { console.error(error) });
  };
};

export const getSalesFlow = (id) => {
	 console.log("BEFORE response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.salesflow);		  
        return dispatch({type: RECEIVE_SALESFLOWX, SalesFlowOne: response.data.salesflow});
      })
      .catch(error => { 
        console.error(error); 
      });
  };
};

export const getSaleFlow = (SalesFlow) => { 
  const auth_id=38;
  //return axios.post(`${apiUrl}/sales?auth_id=${auth_id}`, ZendMacros)
  let invoiceId=0;  let lang="fr";
  if(SalesFlow!==undefined && SalesFlow!==null && SalesFlow.invoiceId >0){
    invoiceId=SalesFlow.invoiceId;
  }
  if(SalesFlow!==undefined && SalesFlow!==null && SalesFlow.lang!=""){
    lang=SalesFlow.lang;
  }

  return (dispatch) => {
    return axios.get(`${apiUrl}/flow/list?id=0&invoiceId=${invoiceId}&lang=${lang}`)
      .then(response => {
        let data = response.data.data;
        
        console.log('0000000 RESPONSE SalesFlow  ');  
        console.log(response);   
        //console.log('0000000 RESPONSE PAAAAAAAAAAAAAYMENT ---.. '+data.id);  
        
        // return dispatch({type: ADD_ZENDMACROS, ZendMacrosOne: response.data});

        console.log("response from server .... ");  console.log(response.data.data);		  
        return dispatch({type: RECEIVE_SALESFLOWX, SalesFlowOne: response.data});
      })
      .then(response => {
        //history.push("/NeoVentes")
        return response;
      })
      //.catch(error => { console.error(error) });
      .catch(err => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("error falls here #1"); console.log(err.response);
          console.log(err);
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage="client never received a response, or request never left";
          console.error(errMessage);
        } else {
          // anything else
          console.log("error falls here #3"); console.log(err);   
          console.error(err); 
        }
    });
  };
};

export const getSalesFlowGoto = (id) => {
	 console.log("BEFORE Goto response from server .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
        return history.push(`/SalesFlow/${id}/edit`)
  };
};
export const deleteSalesFlow = (id) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id}`)
      .then(response => {
        dispatch({type: REMOVE_SALESFLOW, payload: {id}})
      })
      .then(() => {
        history.push("/SalesFlow")
      })
      .catch(error => {
        console.error(error);
      });
  };
};
export const updateSalesFlow = (SalesFlow) => {
  const id = SalesFlow.id;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${SalesFlow.id}`, {
		invoice: SalesFlow.invoice,
		flow: SalesFlow.flow,
		description: SalesFlow.description,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_SALESFLOW, payload: {
			id: data.id,
			invoice: data.invoice,
			flow: data.flow,
			description: data.description,
		}})
        dispatch({type: REPLACE_SALESFLOW, payload: {
			id: data.id,
			invoice: data.invoice,
			flow: data.flow,
			description: data.description,
		}})
      })
      .then(() => {
        history.push(`/SalesFlow/${id}`)
      })
      .catch(error => { console.error(error) });
  };
};
export const addOrUpdateSalesFlow = (SalesFlow, actionMode) => {
	if(actionMode===1){
        return addSalesFlow(SalesFlow);
      }else {
        return updateSalesFlow(SalesFlow);
      }
};