/*
if(colType.equals("CHAR")){ return "String";}
    else if(colType.equals("VARCHAR")){ return "String";}
    else if(colType.equals("LONGVARCHAR")){ return "String";}
    else if(colType.equals("NUMERIC")){ setImportClassesList("java.math.BigDecimal"); return "BigDecimal";}
    else if(colType.equals("DECIMAL")){ setImportClassesList("java.math.BigDecimal"); return "BigDecimal";}
    else if(colType.equals("BIT")){ return "boolean";}
    else if(colType.equals("TINYINT")){ return "int";}
    else if(colType.equals("SMALLINT")){ return "int";}
    else if(colType.equals("MEDIUMINT")){ return "int";}
    else if(colType.equals("INTEGER")){ return "int";}
    else if(colType.equals("BIGINT")){ return "long";}
    else if(colType.equals("REAL")){ return "float";}
    else if(colType.equals("FLOAT")){ return "double";}
    else if(colType.equals("DOUBLE")){ return "double";}
    else if(colType.equals("DOUBLE PRECISION")){ return "double";}
    else if(colType.equals("BINARY")){ return "byte []";}
    else if(colType.equals("VARBINARY")){ return "byte []";}
    else if(colType.equals("LONGVARBINARY")){ return "byte []";}
    else if(colType.equals("DATE")){ setImportClassesList("java.sql.Date"); return "Date";}
    else if(colType.equals("TIME")){ setImportClassesList("java.sql.Time"); return "Time";}
    else if(colType.equals("DATETIME")){ setImportClassesList("java.sql.Date"); return "Date";}
    else if(colType.equals("TIMESTAMP")){ setImportClassesList("java.sql.Timestamp"); return "Timestamp";}
    
    else if(colType.equals("YEAR")){ return "int";}
    else if(colType.equals("INT")){ return "int";}
    else if(colType.equals("SET")){ return "String";}
    else if(colType.equals("ENUM")){ return "String";}
    else if(colType.equals("TINYTEXT")){ return "String";}
    else if(colType.equals("TEXT")){ return "String";}
    else if(colType.equals("MEDIUMTEXT")){ return "String";}
    else if(colType.equals("LONGTEXT")){  return "String";} 
    
    else if(colType.equals("TINYBLOB")){ setImportClassesList("java.sql.Blob"); return "Blob";}
    else if(colType.equals("CLOB")){ setImportClassesList("java.sql.Clob"); return "Clob";}
    else if(colType.equals("BLOB")){ setImportClassesList("java.sql.Blob"); return "Blob";}
    else if(colType.equals("MEDIUMBLOB")){ setImportClassesList("java.sql.Blob"); return "Blob";}
    else if(colType.equals("LONGBLOB")){ setImportClassesList("java.sql.Blob"); return "Blob";}
    
    
    else{ return "String";}*/

export class Validations  {   
  //{this.t_title && (this.e_title && <p className="text-danger">{this.e_title}</p>)} 
  //{this.t_content && (this.e_content && <p className="text-danger">{this.e_content}</p>)} 
  //valType=valType.toLowerCase();
  validate(valType, str){
	  //cases come here to map to the case
	  valType=valType.toLowerCase();
	  let result=true;
	  switch (valType){
		  case 'integer':
			 result=this.isinteger(str);
			break;
		  case 'int':
			 result=this.isinteger(str);
			break;
		  case 'long':
			 result=this.isinteger(str);
			break;
		  case 'float':
			result=this.isfloat(str);
			break;
		  case 'decimal':
			result=this.isfloat(str);
			break;
		  case 'double':
			result=this.isdouble(str);
			break;
		  case 'floatqqq':
			result=this.isinteger(str);
			break;
		  case 'string':
			result=this.isString(str);
			break;
		  case 'email':
			result=this.isemail(str);
			break;
		  default:
			result=true;
	  }
    return result;
  }
  
  /*handleCancel(valType, str){
    this.props.history.push(`./articles`);
  }
  
  handleCancel(valType, str){
    this.props.history.push(`./articles`);
  }
  
  handleCancel(valType, str){
    this.props.history.push(`./articles`);
  }*/
  
  isinteger(str){
	const patt = /^\d+$/; 
	return patt.test(str);  
  }
  isfloat(str){
    const patt = /^\d*\.\d+$/; 
	return patt.test(str);  
  }
  isdouble(str){
    const patt = /^\d*\.\d+$/; 
	return patt.test(str);  
  }
  
  isemail(str){
    const patt = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})$/; 
	return patt.test(str);  
  }
  
  isphone(str){
    const patt = /^\d+$/; 
	return patt.test(str);  
  }
  
  isString(str){
    const patt = /^\d+$/; 
  //return patt.test(str);
  if(str===null){ return false; }
	return (str.length >0);	
  }
  
  string(min, max, str){
    this.props.history.push(`./articles`);
  }
  
  test(txtPattern, str){
    this.props.history.push(`./articles`);
  }
  
 
}

//const mapDispatchToProps = { addArticle };                     

//export default connect(null, mapDispatchToProps)(ArticleAdd);