import React from "react";
import { Link } from "react-router-dom";
import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    NavLink,
    ButtonGroup,
    Button,
    UncontrolledTooltip
    // Table
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faCoins,
    faTachometerAlt,
    faEye,
    faCloudSun, 
    faFireAlt,
    faTags,
    faExternalLink,
    faFilter,
    faLink,
    faGlobeAmericas,
    faPlusCircle,
    faFire,
    faMugHot,
    faSnowman,
    faSnowflake
} from '@fortawesome/free-solid-svg-icons';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";
import moment from "moment";
import _ from 'lodash';
import Table from '../../../utils/MaterialTable';
import TableIcon from '../../../../utils/TableIcon';
import DashboardElement from '../../../../utils/DashboardElement';
import NeoVentesMySWABasicPostActions from "../../../../../components/neoventes/NeoVentesMySWABasicPostActions";
import CenterSectionclose from "./CenterSectionclose";
import AgentDashboard from "./AgentDashboard";
import axios from 'axios';
import { API_DOMAIN } from '../../../../../appConfig'; 
import { axiosDefaults } from '../../../../../appConfigaxios'; 
import { genThermoIcon } from "./PriorityIconsToggle";
import { genAlertIcon } from "./AlertIconsToggle";
import IconButton from "../../../IconButton/IconButton";
import { CalendarIconButton } from "./Tasks";
const BACKEND1 = process.env.REACT_APP_API_URLX;

class CenterSection extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            data:[],
            postErrorMsg: '',
            activeTab: 'leads'
        };
    }

    componentDidMount(){
        axiosDefaults(null);
        const this2 = this;
        axios.get(
            BACKEND1+'/leads',
            {
                params: {
                    //src: "desk",
                    showtrans: 3
                    // page:2,
                    // limit:6,
                    // newlead:1 pour obtenir des un nouveau lead
                },
                //headers: {
                    //'Content-Type': 'application/json',
                    // 'x-jwt-token': localStorage.getItem('token'),
                    //'auth-id': localStorage.getItem('user_id'),
                    //'Authorization': "Bearer " + localStorage.getItem('token')
                //}
            }
        )
        .then((status) => {
            // console.log(status)
            let {data} = status.data;
            if(data && data.neoleads){
                let {neoleads} = data;

                let arr = _.map(
                    neoleads,
                    (e) => {
                        //console.log(neoleads)
                        return {
                            ...e,
                            ...e.definition,
                            id: e.id,
                            ticket_id: e.ticket_id,
                            link: e.ticket_id,
                            domain: e.domaine,
                            updated_at:moment().format('LLL')
                        }
                    }
                )
                // console.log('arr',arr,neoleads)
                this.setState({
                    data:arr
                })
                const leads = JSON.stringify(arr.map(i => i.id));
                sessionStorage.setItem('leads', leads);
                //console.log(leads)
            }
        },(error) =>{
            this.setState({
                redirect:'/login'
            })
        })
    }

    genLang = (text) => {
        const {trad, lang} = this.props;
        return trad['neoleads'][text][lang]
    }

    // ComponentWillUnmount remove sessionStorage

    getLead(){
        const this2=this;
        axios.get(
            BACKEND1+'/leads',
            {
                params: {
                    //src: "desk",
                    // lead:true,
                    // limit:6,
                    newlead:1 //pour obtenir des un nouveau lead
                },
                //headers: {
                    //'Content-Type': 'application/json',
                    // 'x-jwt-token': localStorage.getItem('token'),
                    //'auth-id': localStorage.getItem('user_id'),
                    //'Authorization': "Bearer " + localStorage.getItem('token')
                //}
            }
        )
        .then((status) => {
            let {data} = status.data;

            if(data.neolead){
                this.setState({
                    redirect:'/leads/'+data.neolead.id
                })
            }

            if(!data.length){
                if(this.state.data && this.state.data[0]){  
                    this.setState({noLeadMessage: true});  
                    // this.setState({
                    //     redirect:'/leads/'+this.state.data[0].id
                    // })  
                }
            }

            this.setState({
                disabled:false
            })

            // if(data && data.neoleads){
            //     let {neoleads} = data;

            //     let arr = _.map(
            //         neoleads,
            //         (e) => {
            //             // console.log('========>',JSON.parse(e.definition))
            //             return {
            //                 ...e,
            //                 ...e.definition,
            //                 link:e.ticket_id,
            //                 domain:'civil',
            //                 updated_at:moment().format('LLL')
            //             }
            //         }
            //     )
                
            //     this.setState({
            //         data:arr
            //     })
            // }
        }
        // ,(error) =>{
            
        //     this.setState({
        //         redirect:'/login'
        //     })
        // }
        ).catch(function(error){ 
            console.log("error object:", error.response.data)
            this2.setState({postErrorMsg: error.response.data}) 
        });
    }

    //leadsclose
    gotoLeadsClose = (event) => {
        this.props.history.push(`/leadsclose`);
    }

    render(){
        const leads = this.state.data.map(i => i.id)

        if(this.state.redirect){

            // if(this.state.redirect === 'expiration'){
            //     return(
            //         <Redirect
            //             to={{
            //                 pathname: "/expiration"
            //                 // state: { referrer: props.location.pathname }
            //             }}
            //         />
            //     )    
            // }
            return(
                <Redirect
                to={{
                    pathname: this.state.redirect,
                    // search: window.location.search+"?return="+window.location.pathname,
                    // state: { referrer: props.location.pathname }
                }}
            />
            )
        }

        const columns = [
            {
                Header: 'Lead #',
                accessor: 'id',
            },
            {
                Header: 'Alerte',
                accessor: 'alert',
            },
            // {
            //     Header: this.genLang('origin'),
            //     accessor: 'ticket_tags',
            // },
            {
                Header: this.genLang("domain"),
                accessor: 'domain',
            },
            {
                Header: this.genLang('subject'),
                accessor: 'ticket_subject',
            },
            {
                Header: this.genLang('priority'),
                accessor: 'thermoIcon'
            },
            
            // {
            //     Header: 'Modif',
            //     accessor: 'updated_at',
            // },
            
            {
                Header: this.genLang('created'),
                accessor: 'created',
            },
            
            {
                Header: 'Link',
                accessor: 'linkIcon'
            }
        ];

        return (<>
        <Row onClick={() => console.log(this.state)}>
            {/* <Col md={12} style={{paddingBottom: '1rem'}}>
                <h1 style={{ color: "#0052BB" }} className='text-align-center m-t-15 element-header'>Leads</h1>
            </Col> */}
            {/* <Col>
                <Nav>
                    <NavItem>
                        <NavLink>
                            Active Leads
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink>
                            Closed Leads
                        </NavLink>
                    </NavItem>
                </Nav>
            </Col> */}
        </Row>
        <Row style={{marginTop: '2rem'}}>
            {/* <Row> */}
            <AgentDashboard trad={this.props.trad} lang={this.props.lang}/>
            {/* </Row> */}
             {/* <Row md={12}> */}
                <Col> 
                <Nav fill={true} tabs>
                    <NavItem active={true}>
                        <NavLink 
                            active={this.state.activeTab === 'leads'} 
                            style={{fontSize: '20px'}}
                            onClick={() => this.setState({activeTab: 'leads'})}
                        >
                            Mes Leads
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink 
                            style={{fontSize: '20px'}}
                            active={this.state.activeTab === 'sales'}
                            onClick={() => this.setState({activeTab: 'sales'})}
                            >
                            Ventes à Compléter
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink 
                            style={{fontSize: '20px'}}
                            active={this.state.activeTab === 'closed'}
                            onClick={() => this.setState({activeTab: 'closed'})}
                            >
                            Mes leads fermés
                        </NavLink>
                    </NavItem>
                </Nav>
                </Col>
            {/* </Row>  */}
            <Col md={12} >
                {this.state.activeTab === "leads" && <>
                {/* <a href="#" onClick={this.gotoLeadsClose} style={{float:'right'}}>Mes leads fermés</a> */}
                <DashboardElement
                    col={12}
                    title={<>{`${this.genLang('myNextLeads')}`} <FontAwesomeIcon icon={faTags} open={true} /></>}
                    toggle={false}
                >
                    <Row>
                        <Col md={12}>
                            {this.state.postErrorMsg && <Alert toggle={() => this.setState({postErrorMsg: ""})} style={{marginTop: '1rem'}} color="danger">{this.state.postErrorMsg}</Alert>}
                            <Nav
                                style={{
                                    paddingTop: "0",
                                    marginBottom: "10px"
                                }}
                            >
                                {/* <div>{`${this.genLang('numberOfLeads')}`} {this.state.data.length ? this.state.data.length : this.genLang('downloading')}</div> */}
                                <div>{`${this.genLang('numberOfLeads')} ${this.state.data.length}`}</div>
                                
                                <NavItem className="ml-auto">
                                    <Button 
                                        color={'primary'} outline 
                                        id='getLead'
                                        type={"button"}
                                        disabled={this.state.disabled === true}
                                        onClick={(e) => this.setState({disabled:true},this.getLead)}
                                    >
                                        {"Obtenir un Lead "}<FontAwesomeIcon icon={faPlusCircle} />
                                    </Button>
                                    {/* <UncontrolledTooltip placement="left" target='getLead'>
                                        Obtenir un Lead
                                    </UncontrolledTooltip> */}
                                </NavItem>
                                {/* </Nav> */}
                            </Nav>
                        </Col>
                    </Row>
                    
                    {/* <a href="#" onClick={this.gotoLeadsClose}>Mes leads fermes</a> */}
                    <Table
                        genLang={this.genLang}
                        data={
                            _.map(this.state.data, (elt) => {
                                elt.thermoIcon = genThermoIcon(elt.thermo)
                                elt.alert = genAlertIcon(elt.priority)
                                elt.linkIcon = 
                                <Link 
                                    to={`/leads/${elt.id}`}
                                >
                                    <IconButton 
                                        icon={faExternalLink}
                                        tooltip={this.genLang('open')}
                                        style={{color: "#0076cf"}}
                                        onClick={() => {}}
                                    />
                                </Link>
                                return elt;
                            })
                        }
                        columns={columns}
                    />
                </DashboardElement>
                </>}
                {this.state.activeTab === 'sales' && <NeoVentesMySWABasicPostActions />}
                {this.state.activeTab === 'closed' && <CenterSectionclose lang={this.props.lang} trad={this.props.trad}/>}
            </Col>
        </Row></>
        )
    }
}

export default CenterSection