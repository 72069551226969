import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateZonlineUsuarios, getZonlineUsuarios, getZonlineUsuariosAll, getZonlineUsuariosGoto, deleteZonlineUsuarios } from '../../actions/ZonlineUsuariosAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "../CustomToolbarSelect";

class ZonlineUsuariosList extends Component {
	constructor(props) {
		super(props); 
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 
	}
  
	componentDidMount() { 
		this.props.getZonlineUsuariosAll();
	}
	
	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.ZonlineUsuarios;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteZonlineUsuarios(gridIds[0]);

	}

  updateXRecordsMain(selectedRows){  
    const { t } = this.props;
	const objList  = this.props.ZonlineUsuarios;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
      gridIndexes.push(row.index); 
      idIndex=row.index; 
	return null;
    });
 
    if(gridIndexes.length>1){
      alert(`{t('gridIndexesfound_x')}`);
      return false;
    }
 
    let obj=objList[idIndex]; 
	this.props.getZonlineUsuariosGoto(obj.id); 
	//history.push(`./zonlineusuarios/${obj.id}/edit`); 
  }
  

  render() { 
    const { t } = this.props;
	const columns = [
	 {
	  name: "id_usuario", label: t('id_usuario_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "fecha", label: t('fecha_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "nombre", label: t('nombre_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "apellido", label: t('apellido_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "correo", label: t('correo_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "usuario", label: t('usuario_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "contrasena", label: t('contrasena_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "id_rol_usuario", label: t('id_rol_usuario_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "habilitado", label: t('habilitado_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "logintoken", label: t('logintoken_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "logintime", label: t('logintime_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "zona", label: t('zona_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "funcion", label: t('funcion_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "sucursales", label: t('sucursales_x'),
	  options: { filter: true, sort: true,}
	 },
	];
const textLabelOptions= {
    body: {
      noMatch: t('gridnoMatch_x'),
      toolTip: t('gridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('gridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('gridnext_x'),
      previous: t('gridprevious_x'),
      rowsPerPage: t('gridrowsPerPage_x'),
      displayRows: t('griddisplayRows_x'),
    },
    toolbar: {
      search: t('gridsearch_x'),
      downloadCsv: t('griddownloadCsv_x'),
      print: t('gridprint_x'),
      viewColumns: t('gridviewColumns_x'),
      filterTable: t('gridfilterTable_x'),
    },
    filter: {
      all: t('gridall_x'),
      title: t('gridftitle_x'),
      reset: t('gridreset_x'),
    },
    viewColumns: {
      title: t('gridvctitle_x'),
      titleAria: t('gridvctitleAria_x'),
    }, 
  };	
			
	const options = {
	  filterType: 'checkbox',
	  textLabels: textLabelOptions,
	  onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		//console.log(currentRowsSelected, allRowsSelected);
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log(allRowsSelected);
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} isEdit={true} isDelete={true} />
      ),
	};  
	  
    if(this.props.ZonlineUsuarios.length) {                                
      return (
        <div>
          <h4>{t('ZonlineUsuarios_x')}</h4>		  
		  <MUIDataTable
			  title={""}
			  data={this.props.ZonlineUsuarios}
			  columns={columns}
			  options={options}
			/>
        </div>
      )    
    } else {
      return (<div>{t('gridNoRecordfound_x')}</div>)
    }  }
}


const mapStateToProps = (state) => ({ ZonlineUsuarios: state.ZonlineUsuarios });  
const mapDispatchToProps = { addOrUpdateZonlineUsuarios, getZonlineUsuarios, getZonlineUsuariosAll, getZonlineUsuariosGoto, deleteZonlineUsuarios };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(ZonlineUsuariosList));
