import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faCoins,
    faTachometerAlt,
    faTags,
    faFilter,
    faGlobeAmericas,
    faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import React, {
    Component
} from 'react';
import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    NavLink,
    ButtonGroup
    // Table
} from 'reactstrap';
import DashboardElement from '../../../utils/DashboardElement'
// import Dashbo
import Container from 'reactstrap/lib/Container';
import Button from 'reactstrap/lib/Button';
import Table from '../../../utils/Table';
// import Booking from './Booking'
import TableIcon from '../../../utils/TableIcon';

import UserDetails from './component/UserDetails'
import Tasks from './component/Tasks'
import Widget from './component/Widget'
import AppBar from './component/AppBar'
import UserIcon from './component/UserIcon'
import Contact from './component/Contact'

import TimelineLead from './component/TimelineLead'
import Reminder from './component/Reminder';
const profile = require('./thonsid.png');
class Lead5 extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        // return(<>hallo</>)
        return (
            <div

                className='hasSideBar'
                style={{
                    // color:"#ededed",
                    // background: 'repeating-linear-gradient(-45deg, #454545, #454545 10px, #242424 10px, #242424 20px)'
                    //  background: 'repeating-linear-gradient(-45deg, #efefef, #efefef 20px, #fefefe 20px, #fefefe 30px)'
                }}

            // className="menu-w color-style-bright menu-position-side menu-side-left menu-layout-mini sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link"
            >
                <Row className="topRow">
                    <Col className="centeredNavigation" md={6} sm={12} xs={12} style={{}}>
                        <ButtonGroup>
                            <Button color="primary">
                            Tableau de bord
                            </Button>
                            
                            <Button color="primary">
                                Leads
                            </Button>
                            
                            <Button color="primary">
                                Clients
                            </Button>
                            
                            <Button color="primary">
                                Tâches
                            </Button>
                            
                            <Button color="primary">
                                Calendrier
                            </Button>

                        </ButtonGroup>
                    </Col>
                    <Col sm={12} xs={12} md={6}>
                        <UserIcon />
                    </Col>
                </Row>
                <Row>
                    <Col md={3} sm={3} xs={12} style={{
                        height:'100vh'

                    }}>
                        <UserDetails />
                    </Col>
                    <Col md={6} sm={6} xs={12} className="centerApp">
                        <Contact />
                        <TimelineLead />
                    </Col>
                    <Col 
                        md={3} 
                        sm={4} 
                        xs={12}
                    >
                        <AppBar />
                    </Col>
                    {/* <Col 
                        md={3} 
                        sm={4} 
                        xs={12}
                        style={{
                            overflowY:'hidden',
                            maxHeight:'100vh',
                            
                        }} 
                    >
                        <AppBar />
                    </Col> */}
                </Row>
            </div>
        )
    }
}

export default Lead5;