import axios from "axios";
import history from "../history";
import { API_DOMAIN } from "../appConfig";
import { axiosDefaults } from "../appConfigaxios";
import productInfoMultiple from "../appProductInfoMulti";

export const RECEIVE_NEOVENTES = "GET_NEOVENTES";
export const ADD_NEOVENTES = "ADD_NEOVENTES";
export const RECEIVE_NEOVENTESX = "RECEIVE_NEOVENTESX";
export const REMOVE_NEOVENTES = "REMOVE_NEOVENTES";
export const UPDATE_NEOVENTES = "UPDATE_NEOVENTES";
export const REPLACE_NEOVENTES = "REPLACE_NEOVENTES";
export const RECEIVE_NEOCLIENTSX = "RECEIVE_NEOCLIENTSX";
export const RECEIVE_NEOADDRESSX = "RECEIVE_NEOADDRESSX";
export const RECEIVE_NEOCODEPROMOX = "RECEIVE_NEOCODEPROMOX";
const apiUrl = API_DOMAIN + "/neoventes";
//const apiUrlProduct = API_DOMAIN + '/products';
const apiUrlProduct = API_DOMAIN + "/productlist";
const apiUrlPeoplesales = API_DOMAIN + "/salesplanning/peoplesales";
const apiUrl2 = API_DOMAIN + "/b2b/clients";

const apiUrlBase = API_DOMAIN;

axiosDefaults(null);

export const getNeoVentesAll = () => {
  return (dispatch) => {
    return axios
      .get(`${apiUrl}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesAllPending = () => {
  const status = "sent,partial";
  return (dispatch) => {
    return axios
      .get(`${apiUrl}?status=${status}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesAllXStaus = (statusList) => {
  let status = "payed";
  if (statusList !== "") {
    status = statusList;
  }
  return (dispatch) => {
    return axios
      .get(`${apiUrl}?status=${status}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// /chargedesk/checkstatus
export const getChargeDeskStatus = (chargeId) => {
  //const status="sent,partial";
  return (dispatch) => {
    return axios
      .get(`${API_DOMAIN}/chargedesk/checkstatus?chargeId=${chargeId}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data.chargeInfo,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const addNeoVentes = (NeoVentes) => {
  const auth_id = 38;
  return (dispatch) => {
    return axios
      .post(`${apiUrl}/salesb2b`, NeoVentes)
      .then((response) => {
        let data = response.data.data;
        const mydataAr = [];
        mydataAr.push({
          id: data.id,
          transaction: data.transaction,
        });
        return dispatch({ type: ADD_NEOVENTES, NeoVentes: data });
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          console.log(err);
          console.error(err);
        }
      });
  };
};

export const addNeoVentesSC = (NeoVentes) => {
  const auth_id = 38;
  return (dispatch) => {
    return axios
      .post(`${apiUrl}/salessc`, NeoVentes)
      .then((response) => {
        let data = response.data.data;
        const mydataAr = [];
        mydataAr.push({
          id: data.id,
          transaction: data.transaction,
        });
        return dispatch({ type: ADD_NEOVENTES, NeoVentes: data });
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("error falls here #1");
          console.log(err.response);
          console.log(err);
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          // anything else
          console.error(err);
        }
      });
  };
};

export const addOrUpdateRefunds = (NeoObjects) => {
  return (dispatch) => {
    return axios
      .post(`${apiUrlBase}/refunds/refunds`, NeoObjects)
      .then((response) => {
        let data = response.data;
        return dispatch({ type: ADD_NEOVENTES, NeoVentes: data });
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          // anything else
          console.error(err);
        }
      });
  };
};

export const getNeoVentes = (id) => {
  return (dispatch) => {
    return axios
      .get(`${apiUrl}/${id}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTESX,
          NeoVentesOne: response.data.neoventes,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesByPeople = (obj) => {
  const date = obj.date;
  const date2 = obj.date2;
  const groupByDayWeekMonth = obj.dayWeekMonth;
  return (dispatch) => {
    return axios
      .get(
        `${apiUrlPeoplesales}?date=${date}&date2=${date2}&grpby=${groupByDayWeekMonth}`
      )
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getClientTransactionsFullInfo = (obj) => {
  const id = isNaN(obj.clientId) ? 0 : obj.clientId;
  const id2 = obj.ticketId;
  return (dispatch) => {
    return axios
      .get(`${apiUrlBase}/neofoldersfullinfo?clientId=${id}&ticketId=${id2}`)
      .then((response) => {
        console.log("getClientTransactions", response); //response.data.data.ndifields
        return dispatch({
          type: RECEIVE_NEOVENTESX,
          NeoVentesOne: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getClientPaymentsFullInfo = (obj) => {
  const id = isNaN(obj.clientId) ? 0 : obj.clientId;
  const id2 = obj.ticketId;
  return (dispatch) => {
    return axios
      .get(`${apiUrlBase}/neofolderspaymentlist?clientId=${id}&ticketId=${id2}`)
      .then((response) => {
        console.log("getClientTransactions", response); //response.data.data.ndifields
        return dispatch({
          type: RECEIVE_NEOVENTESX,
          NeoVentesOne: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getClientSearch = (serachObj) => {
  const auth_id = 38;
  const search = serachObj.search;
  const searchEmail = serachObj.searchEmail;
  let searchURL = "";
  const clientId = serachObj.clientId !== undefined ? serachObj.clientId : 0;
  if (searchEmail === "") {
    searchURL = `${apiUrl}/searchclients?search=${search}&clientId=${clientId}`;
  } else {
    searchURL = `${apiUrl}/searchclients?search=${search}&searchEmail=${searchEmail}&clientId=${clientId}`;
  }
  return (dispatch) => {
    return axios
      .get(searchURL)
      .then((response) => {
        if (response.data.data.length == 0) {
          return dispatch({ type: RECEIVE_NEOCLIENTSX, NeoClientsOne: [] });
        }
        return dispatch({
          type: RECEIVE_NEOCLIENTSX,
          NeoClientsOne: response.data.data.neoclients,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getB2BClientSearch = (serachObj) => {
  const auth_id = 38;
  const search = serachObj.search;
  const searchEmail = serachObj.searchEmail;
  let searchURL = "";
  if (searchEmail === "") {
    searchURL = `${apiUrl2}/search?search=${search}`;
  } else {
    searchURL = `${apiUrl2}/search?search=${search}&searchEmail=${searchEmail}`;
  }
  return (dispatch) => {
    return axios
      .get(searchURL)
      .then((response) => {
        if (response.data.data.length == 0) {
          return dispatch({ type: RECEIVE_NEOCLIENTSX, NeoClientsOne: [] });
        }
        return dispatch({
          type: RECEIVE_NEOCLIENTSX,
          NeoClientsOne: response.data.data.neoclients,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const checkClientEligibility = (serachObj) => {
  const search = serachObj.id;
  const searchEmail = serachObj.searchEmail;
  let searchURL = "";
  searchURL = `${apiUrl2}/eligibility?id=${search}`;
  return (dispatch) => {
    return axios
      .get(searchURL)
      .then((response) => {
        if (response.data.data.length == 0) {
          return dispatch({ type: RECEIVE_NEOCLIENTSX, NeoClientsOne: [] });
        }
        return dispatch({
          type: RECEIVE_NEOCLIENTSX,
          NeoClientsOne: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getB2BContractListDetails = (serachObj) => {
  const auth_id = 38;
  const search = serachObj.search;
  const searchEmail = serachObj.searchEmail;
  let searchURL = "";

  searchURL = `${apiUrl2}/contractslist?search=${search}`;
  return (dispatch) => {
    return axios
      .get(searchURL)
      .then((response) => {
        if (response.data.data.length == 0) {
          return dispatch({ type: RECEIVE_NEOCLIENTSX, NeoClientsOne: [] });
        }
        return dispatch({
          type: RECEIVE_NEOCLIENTSX,
          NeoClientsOne: response.data.data.contractslist,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const saveB2BClient = (NeoVentes) => {
  const auth_id = 38;
  return (dispatch) => {
    return axios
      .post(`${apiUrl2}`, NeoVentes)
      .then((response) => {
        let data = response.data.data;
        console.log(response);
        return dispatch({ type: RECEIVE_NEOCLIENTSX, NeoClientsOne: data });
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("error falls here #1");
          console.log(err.response);
          console.log(err);
          console.log("SIMPLE ERROR", err.response.data.data.errorMessage);
          if (err.response.data.data.errorMessage !== undefined) {
            const blokcrr = {
              data: err.response.data.data.errorMessage,
              statusText: "Error",
              status: "ok with error",
            };
            console.error(blokcrr);
          } else {
            console.error(err.response);
          }
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          console.error(err);
        }
      });
  };
};

export const getClientAddress = (clientId) => {
  const auth_id = 38;
  return (dispatch) => {
    return axios
      .get(`${apiUrl}/clientaddress?clientId=${clientId}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOADDRESSX,
          NeoAddressOne: response.data.data,
        }); //response.data.data.neoaddress
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getCodePromo = (code) => {
  const auth_id = 38;
  return (dispatch) => {
    return axios
      .get(`${apiUrl}/voucher?search=${code}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOCODEPROMOX,
          NeoCodePromoOne: response.data.data.neovoucher,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesPromos = () => {
  const auth_id = 38;
  return (dispatch) => {
    return axios
      .get(`${apiUrl}/vouchers`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOCODEPROMOX,
          NeoCodePromoOne: response.data.data.neovouchers,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getProductInfo = (id, lang) => {
  const arrProd = [6, 8, 9];
  if (arrProd.includes(parseInt(id))) {
    const response = productInfoMultiple(lang, id);
    return (dispatch) => {
      return dispatch({
        type: RECEIVE_NEOCODEPROMOX,
        NeoCodePromoOne: response,
      });
    };
  } else {
    return (dispatch) => {
      return axios
        .get(`${apiUrl}/xtraproductinfo?productId=${id}&lang=${lang}`)
        .then((response) => {
          return dispatch({
            type: RECEIVE_NEOCODEPROMOX,
            NeoCodePromoOne: response.data,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    };
  }
};

export const getAllProducts0 = () => {
  return (dispatch) => {
    return axios
      .get(`${apiUrlProduct}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getAllProducts = (objProd) => {
  const objProdx = { lang: "fr", upsell: "yes" };
  if (objProd === undefined || objProd === null) {
    objProd = objProdx;
  }
  const lang = objProd.lang;
  const upsell = objProd.upsell;
  return (dispatch) => {
    return axios
      .get(`${apiUrlProduct}?upsell=${upsell}&lang=${lang}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getPartialInvoicePayments = (id) => {
  return (dispatch) => {
    return axios
      .get(`${apiUrl}/invoicepayments?invoiceId=${id}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOCODEPROMOX,
          NeoCodePromoOne: response.data,
        }); //response.data.data.neoaddress
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesGoto = (id) => {
  return (dispatch) => {
    return history.push(`./NeoVentes/${id}/edit`);
  };
};
export const deleteNeoVentes = (id) => {
  return (dispatch) => {
    return axios
      .delete(`${apiUrl}/delete/${id}`)
      .then((response) => {
        dispatch({ type: REMOVE_NEOVENTES, payload: { id } });
      })
      .then(() => {
        history.push("/NeoVentes");
      })
      .catch((error) => {
        console.error(error);
      });
  };
};
export const updateNeoVentes = (NeoVentes) => {
  const id = NeoVentes.id;
  return (dispatch) => {
    return axios
      .patch(`${apiUrl}/update/${NeoVentes.id}`, {
        name: NeoVentes.name,
        description: NeoVentes.description,
        amount: NeoVentes.amount,
      })
      .then((response) => {
        const data = response.data;
        dispatch({
          type: UPDATE_NEOVENTES,
          payload: {
            id: data.id,
            name: data.name,
            description: data.description,
            amount: data.amount,
          },
        });
        dispatch({
          type: REPLACE_NEOVENTES,
          payload: {
            id: data.id,
            name: data.name,
            description: data.description,
            amount: data.amount,
          },
        });
      })
      .then(() => {
        history.push(`./NeoVentes/${id}`);
      })
      .catch((error) => {
        console.error(error);
      });
  };
};
export const addOrUpdateNeoVentes = (NeoVentes, actionMode) => {
  if (actionMode === 1) {
    return addNeoVentes(NeoVentes);
  } else {
    return updateNeoVentes(NeoVentes);
  }
};
