import React from "react";
import Select from 'react-select'
import {
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Button
} from 'reactstrap'; 
import axios from 'axios';

export default class CreateAgentModal extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            formData: {
                nom: '', 
                prenom: '',
                domaines: [],
                provinces: [],
                languages: []
        }};
    }

    handleChange = (e) => {
        e.persist();
        (async () => {
       await this.setState((prevState) => {
           return {...prevState, formData: {...prevState.formData,
        [e.target.name]: e.target.value}}
       });
   })();  
    }

    handleSelectChange = (e, field) => {
        const arr = e => {
            try {
                return e.map(i => i.value)
            } catch {return []}
        } 
        this.setState(prevState => ({
            ...prevState, formData: {...prevState.formData, [field]: arr(e)}
        }))
    }

    render(){
        return (
            <Modal centered={true} isOpen={this.props.isOpen}>
                <ModalHeader onClick={() => console.log(this.state)}toggle={this.props.toggle}>Create User</ModalHeader>
                <ModalBody>
                    <Label>Nom</Label>
                    <Input name='nom' value={this.state.formData.nom} type='text' onChange={(e) => this.handleChange(e)} />

                    <Label>Prenom</Label>
                    <Input name='prenom' value={this.state.formData.prenom}type='text' onChange={e => this.handleChange(e)}/> 

                    <Label>Domaine(s)</Label>
                    <Select options={this.props.domaineOptions} isMulti onChange={e => this.handleSelectChange(e, 'domaines')}/>

                    <Label>Province(s)</Label>
                    <Select options={this.props.provinceOptions} isMulti onChange={e => this.handleSelectChange(e, 'provinces')} />

                    <Label>Langue(s)</Label>
                    <Select options={this.props.languageOptions} isMulti onChange={e => this.handleSelectChange(e, 'languages')} />
                </ModalBody>

                <ModalFooter>
                <Button onClick={this.props.toggle} outline color='danger'>
                    Annuler
                </Button>
                <Button onClick={() => {}}
                   outline color='primary'>
                    Enregistrer
                </Button>
            </ModalFooter>

            </Modal>
        )
    }


}