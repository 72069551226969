import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircle
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Button,
  } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import Notes from "./Notes/Notes";

const BACKEND1 = process.env.REACT_APP_API_URLX;

class LeadHistory extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            showNotesModal: false,
            notes: [],
            info: {}
        }
    }

    fetchNotes = async (id) => {
        this.setState({showNotesModal: true});
            const { data } = await axios.get(BACKEND1+"/leads", {
              params: {
                //src: "desk",
                id: id,
                leadreadonly: 3
              },
            //   headers: {
            //     "Content-Type": "application/json",
            //     // 'x-jwt-token': localStorage.getItem('token'),
            //     "auth-id": localStorage.getItem("user_id"),
            //     Authorization: "Bearer " + localStorage.getItem("token"),
            //   },
            });
            console.log(data.data)
            this.setState({ notes: data.data.notes, info: data.data.lead });
    }

    genLang = (text) => {
        const {trad, lang} = this.props;
        return trad['neoleads'][text][lang]
    }

    renderLeadHistory = () => {
        if (this.props.customer && this.props.customer.leadHistory){
            const {leadHistory} = this.props.customer;
            return leadHistory.map((i, n) => {
                return (
                <div key={n}>
                    <b onClick={() => this.fetchNotes(i.id)} style={{ color: "#0052bb", fontSize: '15px', cursor: 'pointer' }}>
                        <FontAwesomeIcon style={{width: '8px'}} icon={faCircle}/>{` ${i.lead_subject} `}
                    </b>
                </div>
                )})
        }
    }

    render () {
        console.log(this.props)
        return (
            <>
                <div className="p-15 grayish" 
                    style={{height: 'auto'}}
                >
                    <div style={{height: 'auto'}} className="p-l-10 p-r-10 p-t-10 p-b-10 element-wrapper">
                        {this.renderLeadHistory()}
                    </div>
                </div>

                <Modal centered={true} isOpen={this.state.showNotesModal}>
                    <ModalHeader onClick={() => console.log(this.state)} toggle={() => this.setState({showNotesModal: false, notes: [], info: {}})}>
                        {"Lead"}
                    </ModalHeader>
                    <ModalBody style={{maxHeight: '500px', overflowY: 'scroll'}}>
                        <h6>{this.state.info.id ? `Lead #${this.state.info.id}` : ""}</h6>
                        <h6><b style={{ color: "#0052bb", fontSize: '15px'}}>{this.state.info.lead_subject ? `${this.state.info.lead_subject}` : ""}</b></h6>
                        {this.state.notes.length ? 
                        <Notes notes={this.state.notes}/>
                        : 
                        <div style={{ color: "#0052bb"}}>{`${this.genLang("downloading")}... `}<Spinner style={{}}/></div>}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => this.setState({showNotesModal: false, notes: [], info: {}})} outline color='danger'>
                            {this.genLang('close')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    customer: state.customer,
  });
  
export default connect(mapStateToProps)(LeadHistory);