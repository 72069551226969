import axios from 'axios';
import { encryptText, decryptText } from './appEncryption';
import history from './history';
import { setLoggedUser } from './redux/actions';
export const axiosDefaults = function (config) { 
    // Global axios defaults
	
	//console.log("AUTHORIZATION "); 
   const token = localStorage.token;
   //const rawUser = localStorage.user; //decryptText(localStorage.upass)
   const tokenRid = localStorage.rid;
   let user=null;
   if(localStorage.getItem("user")!==undefined  && localStorage.getItem("user")!==null){
		user=(decryptText(localStorage.getItem("user"))).split(':');
   } 
     
   if(token!==undefined && token!==null){  
		const token2 = decryptText(token);
		// 
	   axios.defaults.headers.common['Authorization'] = "bearer "+token2;
	   if(user!==null){
		axios.defaults.headers.common['auth-id'] = user[4];
	   } 
	   
	   if(tokenRid!==undefined && tokenRid!==null){
		   //axios.defaults.headers.common['rid'] = tokenRid;
	   } 
	   
	   axios.defaults.headers.post['Content-Type'] = 'application/json';
	   axios.defaults.headers.post['Accept'] = 'application/json';
	   ////axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
	   
	   axios.defaults.headers.get['Content-Type'] = 'application/json';
	   axios.defaults.headers.get['Accept'] = 'application/json';
	   //////////axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
	   
	   //axios.defaults.headers.common['withCredentials'] = true;
   }

   if(config!=null){
	   if(config.isMULTIPART!==undefined && config.isMULTIPART===1){
		axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'; 
	   }
	   if(config.isBLOB!==undefined && config.isBLOB===1){
		//axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';  
		axios.defaults.headers.post['Accept'] = 'blob'; //'application/json';
		 
		axios.defaults.headers.get['Accept'] = 'blob'; //'application/json';
	   }
   }
	
	//axios.defaults.baseURL = 'https://api.example.com';
	//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
	//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
	//const instance = axios.create();

	axios.interceptors.response.use(function (response) {
		return response;
	}, function (error) {
		//console.log(error);
		if(error!==undefined && error!==null){
			//console.log("INTERCEPTOR//"); console.log(error); console.log("//interceptor");
			if (error.response!==undefined && error.response.status!==undefined && 401 === error.response.status) {
				//window.location = '/login';
				//console.log("INTERCEPTOR// going to login *********");

				localStorage.removeItem("user"); 
				localStorage.removeItem("token"); 
				localStorage.removeItem("rid"); 
				localStorage.removeItem("profile"); 
				
				//history.push(`./login`);
				const locurl=window.location.href; 
				const patt=/(\/admin\/)/; 
				if(patt.test(locurl)){ 
					history.push(`./login`);
				}else{ 
					history.push(`./`);
				}
	
				return Promise.reject(error);
			} else {
				return Promise.reject(error);
				//return null;
			}
		}
	});

};
