import axios from 'axios';
import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { axiosDefaults } from '../appConfigaxios'; 

export const RECEIVE_ZONLINEUSUARIOS = 'GET_ZONLINEUSUARIOS';
export const ADD_ZONLINEUSUARIOS = 'ADD_ZONLINEUSUARIOS';
export const RECEIVE_ZONLINEUSUARIOSX = 'RECEIVE_ZONLINEUSUARIOSX';
export const REMOVE_ZONLINEUSUARIOS = 'REMOVE_ZONLINEUSUARIOS';
export const UPDATE_ZONLINEUSUARIOS = 'UPDATE_ZONLINEUSUARIOS';
export const REPLACE_ZONLINEUSUARIOS = 'REPLACE_ZONLINEUSUARIOS';
const apiUrl = API_DOMAIN + '/zonlineusuarios'; 

axiosDefaults(null);

export const getZonlineUsuariosAll = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}.php`)
      .then(response => {
        dispatch({type: RECEIVE_ZONLINEUSUARIOS, ZonlineUsuarios: response.data.zonlineusuarios.data})
      })
      .catch(error => { console.error(error); });
  };
};

export const addZonlineUsuarios = (ZonlineUsuarios) => { 
  return (dispatch) => {
    return axios.post(`${apiUrl}/create`, {		id_usuario: ZonlineUsuarios.id_usuario,
		fecha: ZonlineUsuarios.fecha,
		nombre: ZonlineUsuarios.nombre,
		apellido: ZonlineUsuarios.apellido,
		correo: ZonlineUsuarios.correo,
		usuario: ZonlineUsuarios.usuario,
		contrasena: ZonlineUsuarios.contrasena,
		id_rol_usuario: ZonlineUsuarios.id_rol_usuario,
		habilitado: ZonlineUsuarios.habilitado,
		logintoken: ZonlineUsuarios.logintoken,
		logintime: ZonlineUsuarios.logintime,
		zona: ZonlineUsuarios.zona,
		funcion: ZonlineUsuarios.funcion,
		sucursales: ZonlineUsuarios.sucursales,
	})
      .then(response => {
        let data = response.data;
        dispatch({type: ADD_ZONLINEUSUARIOS, payload: {
			id_usuario: data.id_usuario,
			fecha: data.fecha,
			nombre: data.nombre,
			apellido: data.apellido,
			correo: data.correo,
			usuario: data.usuario,
			contrasena: data.contrasena,
			id_rol_usuario: data.id_rol_usuario,
			habilitado: data.habilitado,
			logintoken: data.logintoken,
			logintime: data.logintime,
			zona: data.zona,
			funcion: data.funcion,
			sucursales: data.sucursales,
		}})
      })
      .then(() => {
        history.push("/ZonlineUsuarios")
      })
      .catch(error => { console.error(error) });
  };
};
export const getZonlineUsuarios = (id_usuario) => {
	 console.log("BEFORE response from server .... "+id_usuario); 
	 //return axios.get(`${apiUrl}/${id_usuario}`)
  return (dispatch) => {
    return axios.get(`${apiUrl}/${id_usuario}`)
      .then(response => {
		  console.log("response from server .... ");  console.log(response.data.zonlineusuarios);		  
        return dispatch({type: RECEIVE_ZONLINEUSUARIOSX, ZonlineUsuariosOne: response.data.zonlineusuarios});
      })
      .catch(error => { 
        console.error(error); 
      });
  };
};
export const getZonlineUsuariosGoto = (id_usuario) => {
	 console.log("BEFORE Goto response from server .... "+id_usuario); 
	 //return axios.get(`${apiUrl}/${id_usuario}`)
  return (dispatch) => {
        return history.push(`./ZonlineUsuarios/${id_usuario}/edit`)
  };
};
export const deleteZonlineUsuarios = (id_usuario) => {
  return (dispatch) => {
    return axios.delete(`${apiUrl}/delete/${id_usuario}`)
      .then(response => {
        dispatch({type: REMOVE_ZONLINEUSUARIOS, payload: {id_usuario}})
      })
      .then(() => {
        history.push("/ZonlineUsuarios")
      })
      .catch(error => {
        console.error(error);
      });
  };
};
export const updateZonlineUsuarios = (ZonlineUsuarios) => {
  const id_usuario = ZonlineUsuarios.id_usuario;
  return (dispatch) => {
    return axios.patch(`${apiUrl}/update/${ZonlineUsuarios.id_usuario}`, {
		fecha: ZonlineUsuarios.fecha,
		nombre: ZonlineUsuarios.nombre,
		apellido: ZonlineUsuarios.apellido,
		correo: ZonlineUsuarios.correo,
		usuario: ZonlineUsuarios.usuario,
		contrasena: ZonlineUsuarios.contrasena,
		id_rol_usuario: ZonlineUsuarios.id_rol_usuario,
		habilitado: ZonlineUsuarios.habilitado,
		logintoken: ZonlineUsuarios.logintoken,
		logintime: ZonlineUsuarios.logintime,
		zona: ZonlineUsuarios.zona,
		funcion: ZonlineUsuarios.funcion,
		sucursales: ZonlineUsuarios.sucursales,
		})
      .then(response => {
        const data = response.data;
        dispatch({type: UPDATE_ZONLINEUSUARIOS, payload: {
			id_usuario: data.id_usuario,
			fecha: data.fecha,
			nombre: data.nombre,
			apellido: data.apellido,
			correo: data.correo,
			usuario: data.usuario,
			contrasena: data.contrasena,
			id_rol_usuario: data.id_rol_usuario,
			habilitado: data.habilitado,
			logintoken: data.logintoken,
			logintime: data.logintime,
			zona: data.zona,
			funcion: data.funcion,
			sucursales: data.sucursales,
		}})
        dispatch({type: REPLACE_ZONLINEUSUARIOS, payload: {
			id_usuario: data.id_usuario,
			fecha: data.fecha,
			nombre: data.nombre,
			apellido: data.apellido,
			correo: data.correo,
			usuario: data.usuario,
			contrasena: data.contrasena,
			id_rol_usuario: data.id_rol_usuario,
			habilitado: data.habilitado,
			logintoken: data.logintoken,
			logintime: data.logintime,
			zona: data.zona,
			funcion: data.funcion,
			sucursales: data.sucursales,
		}})
      })
      .then(() => {
        history.push(`./ZonlineUsuarios/${id_usuario}`)
      })
      .catch(error => { console.error(error) });
  };
};
export const addOrUpdateZonlineUsuarios = (ZonlineUsuarios, actionMode) => {
	if(actionMode===1){
        return addZonlineUsuarios(ZonlineUsuarios);
      }else {
        return updateZonlineUsuarios(ZonlineUsuarios);
      }
};