import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faCoins,
    faTachometerAlt,
    faTags,
    faFilter,
    faGlobeAmericas,
    faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import React, {
    Component
} from 'react';
import {
    Alert,
    Row,
    Badge,
    Col,
    Card,
    CardBody,
    CardTitle,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    NavLink,
    ButtonGroup
    // Table
} from 'reactstrap';
import DashboardElement from '../../../utils/DashboardElement'
// import Dashbo
import Container from 'reactstrap/lib/Container';
import Button from 'reactstrap/lib/Button';
import Table from '../../../utils/Table';
// import Booking from './Booking'
import TableIcon from '../../../utils/TableIcon';
import TopMenu from './component/TopMenu';
import UserDetails from './component/UserDetails'
import Tasks from './component/Tasks'
import Widget from './component/Widget'
import AppBar from './component/AppBar'
import UserIcon from './component/UserIcon'
import CenterSection from './component/CenterSection'

import TimelineLead from './component/TimelineLead';
import TimelineLeadTicket from './component/TimelineLeadTicket';
import Reminder from './component/Reminder';
const profile = require('./thonsid.png');
class Lead5 extends Component {
    leadId=0;
    ticketId=0;
    eventId="";
    constructor(props) {
        super(props);
        this.state = {
            showCalendar: false,
            lang: 'fr',
            leadSuivi:{}
        }

        const locurl=window.location.href;
        //const patt=/([0-9]+)(\/edit)$/;
        const patt=/(\/leads\/)([0-9]+)$/g;
        const patt2=/(\/leads\/)([0-9]+)(\/)([0-9]+)$/g;
        const patt2b=/(\/leads\/)([0-9]+)(\/)([0-9]+)(\/)([0-9a-zA-Z]+)$/g;
        //:eventId
        if(patt.test(locurl)){ 
            const foundText = locurl.match(patt);
            const cleanId=(""+foundText[0]).replace("/leads/", "");
            this.leadId=parseInt(cleanId);
        }else if(patt2.test(locurl)){ 
            const foundText = locurl.match(patt2);
            const cleanId=(""+foundText[0]).replace("/leads/", "");
            const car=cleanId.split("/");
            //this.leadId=parseInt(cleanId);
            this.leadId=parseInt(""+car[0]);
            this.ticketId=parseInt(""+car[1]);
            //console.log("QQQQQQQQQQQQQQQQQQQQQ", foundText[1], foundText[3], foundText, car);
        }else if(patt2b.test(locurl)){ 
            const foundText = locurl.match(patt2b);
            const cleanId=(""+foundText[0]).replace("/leads/", "");
            const car=cleanId.split("/");
            //this.leadId=parseInt(cleanId);
            this.leadId=parseInt(""+car[0]);
            this.ticketId=parseInt(""+car[1]);
            this.eventId=""+car[2];
            //console.log("QQQQQQQQQQQQQQQQQQQQQ", foundText[1], foundText[3], foundText, car);
        }

        this.toggleCalendar = this.toggleCalendar.bind(this)
    }
    toggleCalendar() {
        this.setState(prevState => ({
          showCalendar: !prevState.showCalendar
        }));
        // console.log(this.state.showCalendar)
      }

      toggleLang = () => {
        const lang = this.state.lang === 'fr' ? 'en' : 'fr'
        this.setState({lang: lang});
      }

      childToParentState = (objToState) => {
        //const lang = this.state.lang === 'fr' ? 'en' : 'fr'
        if(objToState===null || objToState===undefined ){ return false; }
        this.setState(objToState);
      }
    
    render() {
        let leadId=0;  //this.props.id;
        let ticketId=0; let eventId=""; 
        //const leadIdx = this.props.match.params.id;  
        if(this.props.id!==undefined){ leadId=this.props.id; }
        else if(this.props.match.params.id!==undefined){ leadId=this.props.match.params.id; }
        else if(this.leadId>0){ leadId=this.leadId; }

        if(this.props.ta!==undefined){ ticketId=this.props.ta; }
        else if(this.props.match.params.ta!==undefined){ ticketId=this.props.match.params.ta; }
        else if(this.ticketId>0){ ticketId=this.ticketId; }

        if(this.props.eventId!==undefined){ eventId=this.props.eventId; }
        else if(this.props.match.params.eventId!==undefined){ eventId=this.props.match.params.eventId; }
        else if(this.eventId!==""){ eventId=this.eventId; }
        const leadSuivi=this.state.leadSuivi;

        console.log("PROOOOOOOOOOOOOOOOOOPS", this.props);


        // return(<>hallo</>)
        return (
            <div

                className='hasSideBar'
                style={{
                    // color:"#ededed",
                    // background: 'repeating-linear-gradient(-45deg, #454545, #454545 10px, #242424 10px, #242424 20px)'
                    //  background: 'repeating-linear-gradient(-45deg, #efefef, #efefef 20px, #fefefe 20px, #fefefe 30px)'
                }}

            // className="menu-w color-style-bright menu-position-side menu-side-left menu-layout-mini sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link"
            >


                <Row>
                    <TopMenu
                        active={"leads"}
                        lang={this.state.lang}
                    />
                    {/* <div className="top-bar color-scheme-light">
                        <ul>
                            <li className="active">
                                <a href="#">Home</a>
                            </li>
                            <li>
                                <a href="#">Projects</a>
                            </li>
                            <li>
                                <a href="#">Customers</a>
                            </li>
                            <li>
                                <a href="#">Reports</a>
                            </li>
                        </ul>
                    <div className="top-menu-controls">
                        <div className="element-search autosuggest-search-activator">
                            <input placeholder="Start typing to search..." /></div>
                        <div className="messages-notifications os-dropdown-trigger os-dropdown-position-left">
                            <i className="os-icon os-icon-mail-14"></i><div className="new-messages-count">12</div>
                        <div className="os-dropdown light message-list">
                            <ul>
                                <li>
                                    <a href="#">
                                        <div className="user-avatar-w">
                                            <img alt="" src={"https://www.w3schools.com/howto/img_avatar2.png"} />
                                        </div>
                                        <div className="message-content">
                                            <h6 className="message-from">John Mayers</h6>
                                            <h6 className="message-title">Account Update</h6>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <div className="user-avatar-w">
                                            <img alt="" src={"https://www.w3schools.com/howto/img_avatar2.png"} />
                                        </div>
                                        <div className="message-content">
                                            <h6 className="message-from">Phil Jones</h6>
                                            <h6 className="message-title">Secutiry Updates</h6>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <div className="user-avatar-w">
                                            <img alt="" src={"https://www.w3schools.com/howto/img_avatar2.png"} />
                                        </div><div className="message-content"><h6 className="message-from">Bekky Simpson</h6>
                                        <h6 className="message-title">Vacation Rentals</h6>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <div className="user-avatar-w">
                                            <img alt="" src={"https://www.w3schools.com/howto/img_avatar2.png"} />
                                        </div>
                                        <div className="message-content">
                                            <h6 className="message-from">Alice Priskon</h6>
                                            <h6 className="message-title">Payment Confirmation</h6>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="top-icon top-settings os-dropdown-trigger os-dropdown-position-left">
                        <i className="os-icon os-icon-ui-46"></i>
                        <div className="os-dropdown">
                            <div className="icon-w">
                                <i className="os-icon os-icon-ui-46"></i>
                                </div>
                                <ul>
                                    <li><a href="users_profile_small.html"><i className="os-icon os-icon-ui-49"></i><span>Profile Settings</span></a></li>
                                    <li><a href="users_profile_small.html"><i className="os-icon os-icon-grid-10"></i><span>Billing Info</span></a></li>
                                    <li><a href="users_profile_small.html"><i className="os-icon os-icon-ui-44"></i><span>My Invoices</span></a></li>
                                    <li><a href="users_profile_small.html"><i className="os-icon os-icon-ui-15"></i><span>Cancel Account</span></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="logged-user-w">
                            <div className="logged-user-i">
                                <div className="avatar-w">
                                    <img alt="" src={"https://www.w3schools.com/howto/img_avatar2.png"} />
                                </div>
                                <div className="logged-user-menu color-style-bright">
                                    <div className="logged-user-avatar-info">
                                        <div className="avatar-w">
                                            <img alt="" src={"https://www.w3schools.com/howto/img_avatar2.png"} />
                                            </div><div className="logged-user-info-w"><div className="logged-user-name">Maria Gomez</div>
                                            <div className="logged-user-role">Administrator</div>
                                            </div>
                                        </div>
                                    <div className="bg-icon">
                                        <i className="os-icon os-icon-wallet-loaded"></i>
                                    </div>
                                    <ul>
                                        <li>
                                            <a href="apps_email.html">
                                                <i className="os-icon os-icon-mail-01"></i>
                                                <span>Incoming Mail</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="users_profile_big.html">
                                                <i className="os-icon os-icon-user-male-circle2"></i>
                                                <span>Profile Details</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="users_profile_small.html">
                                                <i className="os-icon os-icon-coins-4"></i>
                                                <span>Billing Details</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="os-icon os-icon-others-43"></i>
                                            <span>Notifications</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="os-icon os-icon-signs-11"></i>
                                                <span>Logout</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                </Row>
                {/* <Row className="topRow">
                    <Col className="centeredNavigation" md={6} sm={12} xs={12} style={{}}>
                        <ButtonGroup>
                            <Button color="primary">
                            Tableau de bord
                            </Button>
                            
                            <Button color="primary">
                                Leads
                            </Button>
                            
                            <Button color="primary">
                                Clients
                            </Button>
                            
                            <Button color="primary">
                                Tâches
                            </Button>
                            
                            <Button color="primary">
                                Calendrier
                            </Button>

                        </ButtonGroup>
                    </Col>
                    <Col sm={12} xs={12} md={6}>
                        <UserIcon />
                    </Col>
                </Row> */}
                
                
                <Row>
                    {/* <Badge 
                        onClick={this.toggleLang}
                        className="top-right"
                        style={{position:'fixed',top:'40px',width:'45px',right:0}}
                        >{this.state.lang === 'fr' ? 'EN' : 'FR'}
                    </Badge> */}
                    {/* <Col md={3} sm={3} xs={12} style={{
                        // height:'100vh'

                    }}>
                        <UserDetails />
                    </Col> */}
                    <Col md={8} sm={8} xs={12} className="centerApp">
                        {(eventId!==null && eventId!=="")?
                        <TimelineLeadTicket id={leadId} ticketId={ticketId}  eventId={eventId} trad={this.props.trad} lang={this.state.lang} showCalendar={this.state.showCalendar} toggleCalendar={this.toggleCalendar}/>:
                            <TimelineLead id={leadId} ticketId={ticketId}  trad={this.props.trad} 
                            lang={this.state.lang} showCalendar={this.state.showCalendar} 
                            toggleCalendar={this.toggleCalendar} setSomeState={this.childToParentState} />}
                    </Col>
                    <Col 
                        md={4} 
                        sm={4} 
                        xs={12}
                    >
                        <AppBar trad={this.props.trad} lang={this.state.lang} leadSuivi={leadSuivi} />
                    </Col>
                    {/* <Col 
                        md={3} 
                        sm={4} 
                        xs={12}
                        style={{
                            overflowY:'hidden',
                            maxHeight:'100vh',
                            
                        }} 
                    >
                        <AppBar />
                    </Col> */}
                </Row>
            </div>
        )
    }
}

export default Lead5;