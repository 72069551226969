import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateZendMacros, getZendMacros, getZendMacrosAll, sendZendMacros } from '../../actions/ZendMacrosAction'; 
import { Validations } from '../../utils/validations'; 

import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 
import Toastr from "../../messages";
import  Loader from "../../loader";
import DatePicker from "react-datepicker";
// import moment from 'moment'; 
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';  

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['name']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['data_raw']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['status']={check:true, chkType:'boolean', message:'incorrect value'};
FIELD_VALIDATION['last_update']={check:true, chkType:'Timestamp', message:'incorrect value'};

class ZendMacrosSendForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000, 
		id:'0', e_id: false,
		name:'', e_name: false,
		data_raw:'', e_data_raw: false,
		status:'', e_status: false,
		last_update:'', e_last_update: false,
		listmacro:[], listmacroid:"", macronameid:"", 
		message:"", messagetype:"", 
		editorState: EditorState.createEmpty(),
		showSubmitBtn: true,
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	 
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

//   https://github.com/jpuri/react-draft-wysiwyg

  handleChangex = (event) => {
	const id=event.target.value;
    this.setState({ [event.target.name]: event.target.value });
	const listmacro=this.state.listmacro;
	let listmacros = listmacro.length > 0
          && listmacro.map((item, i) => { 
			const raw=JSON.parse(item.data_raw);
			// const isPublic=raw.public===undefined?false:raw.public;
			// const pvcomment=isPublic===true?"":" pvcomment";  
			if(id!==item.id){ return null; }
			if(raw.active!==true){
				return null;
			}

			if(id===item.id){ 
				// this.setState({ ["message"]: raw.active }); 
				const actions=raw.actions;
				for (let actItem of actions) {
					console.log(actItem);  
					if(actItem.field==="comment_value_html"){
						this.setState({ ["message"]: actItem.value }); 
						const contentBlock = htmlToDraft(actItem.value); 
						// const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
						// const editorState = EditorState.createWithContent(contentState);
						if (contentBlock) {
							const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
							const editorState = EditorState.createWithContent(contentState);
							this.setState({ ["editorState"]: editorState, ["macronameid"]:item.name, });
							console.log("aaaaaaaaaading content ", item.id, item.name, actItem.value); 
						}

						// this.setState({ ["editorState"]: EditorState.createWithContent(actItem.value) }); 
						// editorState: EditorState.createEmpty(),
					}
					if(actItem.field==="comment_mode_is_public"){
						this.setState({ ["messagetype"]: actItem.value }); 
					}
				}
			} 

			// return (<option value={item.id} key={"us"+i}>{raw.title}</option>); 
			return null;   
          }, this); 
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	if(this.actionMode==='edit'){ 
		console.log('edit  test  '+this.props.ZendMacros.id);
		if(this.props.ZendMacros.id===undefined){ 
			//means some refresh happens  id
			const idx = this.props.match.params.id; 
			//getZendMacros(idx);
			Promise.resolve(this.props.getZendMacros(idx))
			.then((response)=>{
				this.setState(response.ZendMacrosOne);
				return response;
			});
		}	
	}
		// backTicketId={backTicketId} neoemail={neoemail} 
		// client_id={""+client_id} client_name={""+client_name}

		Promise.resolve(this.props.getZendMacrosAll())
		.then((response)=>{
			const macros=response.ZendMacros; 
			console.log("Gooooooooooood macroooooooooooooos"); console.log(response); 
			// this.setState(response.ZendMacrosOne);
			this.setState({["listmacro"]:macros, ["isLoaderOpen"]: false, });
			return response;
		});
  }

  showToast = (arg, toastType) => {
	//toastType: success or error or warning or info
	const toastrDuration=this.state.toastrDuration; //5500	 
	this.setState({  
		toastrMessage: arg, 
	toastrType: toastType
	});
	setTimeout(() => { 
	this.setState({ 
		toastrMessage: "", 
		toastrType: 'success'
	}); 
	}, toastrDuration);
}

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const data_raw = this.state.data_raw;
    const status = this.state.status;
    const last_update = this.state.last_update;
    // const ZendMacros = {id: id, name: name, data_raw: data_raw, status: status, last_update: last_update, }
    const listmacroid=this.state.listmacroid;
	const messagetype=this.state.messagetype;
	const macronameid=this.state.macronameid;
	const macroMessage=draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
	const ticket=this.props.backTicketId===undefined?0:this.props.backTicketId;
	const client_id=this.props.client_id===undefined?0:this.props.client_id;
	const invoiceId=this.props.invoiceId===undefined?0:this.props.invoiceId;
	 
	let errorFound=0;
	if(listmacroid===null || listmacroid==="" || macronameid===null || macronameid==="" 
	|| macroMessage===null || macroMessage==="" || macroMessage==="<p></p>"){
		errorFound++;
	}

	/*for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.ZendMacros[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('zqwerrorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateZendMacros(ZendMacros, 1); 
		//this.props.addZendMacros(ZendMacros); 
	}else{ 
		this.props.addOrUpdateZendMacros(ZendMacros, 2);
		//this.props.updateZendMacros(ZendMacros);
		
	}*/

	console.log("SUUUUUBMIT",listmacroid,messagetype,macronameid,macroMessage);
	if(errorFound > 0){ alert(`${t('zqwerrorFoundonform_x')}`); return false; }
	// return false;
	const ZendMacros = {id: listmacroid, nameid: macronameid, invoiceid: invoiceId, ticket: ticket,  
		macroMessage: macroMessage, client_id:client_id, };

		console.log("SUUUUUBMIT2222 ",ZendMacros);
    
	 
	const this2=this; 
	this.setState({ ["isLoaderOpen"]: true, ["showSubmitBtn"]: false});  
	Promise.resolve(this.props.sendZendMacros(ZendMacros))
	.then((response)=>{
		//this.setState(response.NeoB2bleadProspectsOne);
		const dataResp = response.ZendMacrosOne; 
		// this.setState({["openTransactionPopup"]: true, ["isLoaderOpen"]: false }); 
		this.setState({["isLoaderOpen"]: false }); 
		// if(this.props.frminputClose !== undefined ){ 
		// 	//this.setState({ [event.target.name]: onOffval }); 
		// 	//console.log("componentDidMount rndidpros= "+this.props.frminput.rndidpros+" firstname="+this.props.frminput.firstname);  
		// 	this.props.frminputClose(); 
		// }
		console.log("laaaaaaaaaaast", response, dataResp); 
		return response;
	}).catch(function(error){ 
		// this2.setState({ ["isLoaderOpen"]: false});   
		// this2.showToast(error, "error");  
		const errObj=error;
		this2.setState({ ["isLoaderOpen"]: false, ["showSubmitBtn"]: true});  
		///let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
		let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
		//let errMessage="Error/Erreur ";
		const statusCode=errObj.statusCode;
		if(errObj.status===undefined && statusCode===undefined){
			errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//this2.saveUpdateTicketDataToZendesks(zendeskList); 
			if (typeof errObj === 'string' || errObj instanceof String){
				console.log("inside undefined");  
				this2.showToast(errObj, "error");  
			}else{
				console.log("inside else undefined");  
				this2.showToast(errMessage, "error");  
			} 
			//this2.showToast(errMessage, "error");  
			console.log("FAKE ERRORS SOLVED MINIMIZED");  
			console.log(errObj);
		}
		else if(statusCode===500){   //else if(errObj.status===500){
			//errMessage +="\n There was some error from the expected data from the server. Please try again later.";
			//errMessage +="\n 9999999 ".errObj.data.error.description;
			//errMessage +="\n 9999999 ".errObj.error.description;
			let mesx=errObj.error.description;
			if(mesx===undefined){ mesx=""; }
			errMessage ="SERVER ERROR INTERNAL  "+mesx;
			this2.showToast(errMessage, "error");  
			console.log("500 ERROR");  
			console.log(errObj);  
		}else{
			//this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
			//this00.showToastError(errMessage);
			//alert(errMessage); //error
			this2.showToast(errMessage, "error");
			console.log("UNDEFINED ERROR");  
			console.log(errObj);  
		}
	});


  };

  handleCancel = () => { 
	if(this.actionMode==='new'){ 
		this.props.history.push(`/ZendMacros`);
	}else{ 
		const idx = this.props.match.params.id; 
		this.props.history.push(`/ZendMacros/${idx}`);
		//this.props.history.push(`/ZendMacros/${this.props.ZendMacros.id}`);
	}
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  Alert  = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  render() {
    const { t } = this.props;
	const listmacro=this.state.listmacro;
	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType;

	let listmacros = listmacro.length > 0
          && listmacro.map((item, i) => { 
			const raw=JSON.parse(item.data_raw);
			// const isPublic=raw.public===undefined?false:raw.public;
			// const pvcomment=isPublic===true?"":" pvcomment"; 
			// const firstname=item.firstname;
			// const lastname=item.lastname;
			// const email=item.email;
			//const addObj=item.addFormatted;
			if(raw.active!==true){
				return null;
			}

			return (<option value={item.id} key={"us"+i}>{raw.title}</option>);   

               
          }, this);

		  const message=this.state.message;
		  const editorState= this.state.editorState;
		  const clientName=this.props.client_name;
		  // backTicketId={backTicketId} neoemail={neoemail} 
		// client_id={""+client_id} client_name={""+client_name}
		const Alert=this.Alert;
		const showSubmitBtn=this.state.showSubmitBtn;

    return (
      <div>
        <h3>{t('zqwacAddZendMacros002_x')}</h3>
        <form onSubmit={this.handleSubmit}>
          		  
		  <div className="form-group">
			<label htmlFor="data_raw">Client: {clientName}</label>
			<Alert severity="info">{t("zqwNVFrmEl43_x")}: <b>{clientName}</b></Alert> 
          </div>

		  {/* <div className="form-group">
			<label htmlFor="id">{t('zqwid_x')}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwid_x')} /> 
			{this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
          </div> */}

		  <div className="form-group">
			<label htmlFor="listmacroid">{t('zqwFlowvtx3_x')}</label>
            <select name="listmacroid" id="listmacroid" defaultValue={this.state.listmacroid} onChange={this.handleChangex} onBlur={this.handleFocusout}
              className={this.state.e_listmacro ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwstatus_x')}> 
                        <option value="">Please Select</option>
                        {listmacros}
                    </select>
			{this.state.e_listmacro && (<span className="text-danger">{FIELD_VALIDATION['listmacro'].message}</span>)} 
          </div>

		  <div className="form-group">
			{/* <label htmlFor="message">{t('zqwname_x')}</label>
			<textarea id="message" name="message" rows="4" cols="50"  defaultValue={message}></textarea> */}
			<Editor
				editorState={editorState}
				// toolbarClassName="toolbarClassName"
				// wrapperClassName="wrapperClassName"
				// editorClassName="editorClassName"
				wrapperClassName="wrapper-class"
				editorClassName="editor-class"
				toolbarClassName="toolbar-class"
				onEditorStateChange={this.onEditorStateChange}
				/>
            {/* <input type="text" name="name" id="name" defaultValue={this.state.name} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_name ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwname_x')} /> 
			{this.state.e_name && (<span className="text-danger">{FIELD_VALIDATION['name'].message}</span>)}  */}
          </div>


		  {/* <div className="form-group">
			<label htmlFor="name">{t('zqwname_x')}</label>
            <input type="text" name="name" id="name" defaultValue={this.state.name} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_name ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwname_x')} /> 
			{this.state.e_name && (<span className="text-danger">{FIELD_VALIDATION['name'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="data_raw">{t('zqwdata_raw_x')}</label>
            <input type="text" name="data_raw" id="data_raw" defaultValue={this.state.data_raw} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_data_raw ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwdata_raw_x')} /> 
			{this.state.e_data_raw && (<span className="text-danger">{FIELD_VALIDATION['data_raw'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="status">{t('zqwstatus_x')}</label>
            <select name="status" id="status" defaultValue={this.state.status} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_status ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwstatus_x')}> 
                        <option value="">Please Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
			{this.state.e_status && (<span className="text-danger">{FIELD_VALIDATION['status'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="last_update">{t('zqwlast_update_x')}</label>
            <input type="text" name="last_update" id="last_update" defaultValue={this.state.last_update} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_last_update ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwlast_update_x')} /> 
			{this.state.e_last_update && (<span className="text-danger">{FIELD_VALIDATION['last_update'].message}</span>)} 
          </div> */}

          {showSubmitBtn===true?
			<div className="form-group">
            <button type="submit" className="btn btn-primary">{t('send_x')}</button> &nbsp; 
			{/* <button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button> */}
          </div>:<div className="form-group">
              &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  
          </div>
		  }
		  <Loader  openx={this.state.isLoaderOpen}  />
		  <Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
        </form>
      </div>
    );
  }
}

//const TransZendMacrosForm = withTranslation()(ZendMacrosForm); 
const mapStateToProps = (state) => ({ ZendMacros: state.ZendMacros });

const mapDispatchToProps = { addOrUpdateZendMacros, getZendMacros, getZendMacrosAll, sendZendMacros };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(ZendMacrosSendForm));
