import { LOGIN } from '../_actions/users-actions';

export default function usersReducer(
  state = '',
  {type,params}
){
  switch(type){
    case LOGIN:
      if(params.status === 200){
        // localStorage.setItem('token',params.token);
        // localStorage.setItem('user_id',params.id_users);
        // localStorage.setItem('email',params.email);
        // localStorage.setItem('firstName',params.userName);
        // delete params.status;
        return {
          logged:params.logged,
          connectedUser:params
        }
      }
      else{
        localStorage.clear();
        return state;
      }
    default:
      return state;
  }
}