import { combineReducers } from 'redux';
 
import LogingReducer from '../redux/reducer';
//import ZxTeams from './ZxTeamsReducer';
//import ZxTeamsOne from './singleZxTeamsReducer';
import ZxCompanies from './ZxCompaniesReducer';
import ZxCompaniesOne from './singleZxCompaniesReducer';
 
import ZonlineUsuarios from './ZonlineUsuariosReducer';
import ZonlineUsuariosOne from './singleZonlineUsuariosReducer'; 
import VwFacturas from './VwFacturasReducer';
import VwFacturasOne from './singleVwFacturasReducer';   

import VwBoardReports from './VwBoardReducer';

import NeoVentes from './NeoVentesReducer';
import NeoVentesOne from './singleNeoVentesReducer';

import NeoSalesPlanningDaily from './NeoSalesPlanningDailyReducer';
import NeoSalesPlanningDailyOne from './singleNeoSalesPlanningDailyReducer';
import NeoSalesPlanningWeekly from './NeoSalesPlanningWeeklyReducer';
import NeoSalesPlanningWeeklyOne from './singleNeoSalesPlanningWeeklyReducer';

import NeoB2bleadProspects from './NeoB2bleadProspectsReducer';
import NeoSearch from './NeoSearchReducer';

import NeoProspectsSms from './NeoProspectsSmsReducer';
import NeoProspectsSmsOne from './singleNeoProspectsSmsReducer';

import {
  usersReducer,
  formsReducer,
  loadingReducer,
  resquestReducer,
  customersReducer,
  menuReducer
} from '../srcincorpo/_reducers/root-reducer';

 

export default combineReducers({  
  LoggedUser: LogingReducer,
  //ZxTeams: ZxTeams,
  //ZxTeamsOne: ZxTeamsOne,
  
  
  ZxCompanies: ZxCompanies,
  ZxCompaniesOne: ZxCompaniesOne,
  
  ZonlineUsuarios: ZonlineUsuarios,
  ZonlineUsuariosOne: ZonlineUsuariosOne,
  
  VwFacturas: VwFacturas,
  VwFacturasOne: VwFacturasOne,
  
  VwBoardReports: VwBoardReports,

  NeoVentes: NeoVentes,
  NeoVentesOne: NeoVentesOne,

  NeoSalesPlanningDaily: NeoSalesPlanningDaily,
  NeoSalesPlanningDailyOne: NeoSalesPlanningDailyOne,
  NeoSalesPlanningWeekly: NeoSalesPlanningWeekly,
  NeoSalesPlanningWeeklyOne: NeoSalesPlanningWeeklyOne,

  NeoB2bleadProspects: NeoB2bleadProspects, 

  NeoSearch: NeoSearch,
  
  NeoProspectsSms: NeoProspectsSms,
  NeoProspectsSmsOne: NeoProspectsSmsOne, 

  // from neodoc for leads 
  forms:formsReducer,
  loading:loadingReducer,
  users:usersReducer,
  request:resquestReducer,
  customer:customersReducer,
  menu:menuReducer, 

  // forms:{
  //     will:{
  //     ...defaultWill
  //     }
  // },
  // request:{
  //   fields:{},
  //   documents:{},
  //   listAttach:[]
  // },
  // users:{
  //   // ...local.users
  // },
  // loading:{
  //   "loading":false,
  //   'waitingList':{}
  // },
  // menu:[
  //   {
  //     name:{en:'Home',fr:'Accueil'},
  //     url:'/',
  //     title:'Lien vers le dashbord',
  //     activeName:'home'
  //   },
  //   {
  //     name:{en:'Leads',fr:'Leads'},
  //     url:'/leads',
  //     title:'Lien vers les leads',
  //     activeName:'leads'
  //   },
  //   {
  //     name:{en:'Contact',fr:'Contact'},
  //     url:'/contact',
  //     title:'Lien vers les contacts',
  //     activeName:'contact'
  //   },
  //   // {
  //   //   name:{en:'customer',fr:'client'},
  //   //   url:'/client',
  //   //   title:'Lien vers les Clients',
  //   //   activeName:'client'
  //   // },

  //   // {
  //   //   name:{en:'Calendar',fr:'Calendrier'},
  //   //   url:'/calendar',
  //   //   title:'Lien vers le Calendrier',
  //   //   activeName:'calendar'
  //   // },
    
  // ],
  // customer:{

  // },
  // conflicts:{
  //   zendesk:[],
  //   neolegal:[]
  // },
  //end from nedoc  
  
  
});