//export const API_DOMAIN = 'http://localhost:8080/v1'; 
//export const API_DOMAIN = 'http://localhost:8000/api';

//export const STRIPE_PROMISE = "pk_live_QqtM2wtY9N8yrbv5AEzm1Swm";
//export const STRIPE_PROMISE = "pk_test_51HEQYwBCqOPPc1MwfpUEcue9GeNMjfLLsO4sjhAJ2lOF5mL2iuqaEGx9pNQsGjrWN3sR3ae7akg2ApUXdAavWbSo008mevIfUK";
// export const API_DOMAIN = 'https://api.neolegal.ca'; 
// export const STRIPE_PROMISE = "pk_live_QqtM2wtY9N8yrbv5AEzm1Swm";

//export const APP_PATHNAME = '/';  
//export const APP_PATHNAME = '/neosales/';

export const FLEXITI_MIN = 350;

export const API_DOMAIN = process.env.REACT_APP_API_URLX; //'http://127.0.0.1:8055/api';  
export const STRIPE_PROMISE = process.env.REACT_APP_STRIPE_PROMISE;
///export const API_DOMAIN = 'http://50.100.168.99:805:8055/api';  


export const APP_PATHNAME = process.env.REACT_APP_PUBLIC_URL; 

export const NDIWebsite = "https://crm2.neolegal.ca";



//export const API_DOMAIN = 'https://api.neolegal.ca';  
//export const STRIPE_PROMISE = "pk_live_QqtM2wtY9N8yrbv5AEzm1Swm";