import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNeoB2bleadProspects, getNeoB2bleadProspects, getNeoB2bleadProspectsAll, getNeoB2bleadProspectsGoto, deleteNeoB2bleadProspects } from '../../actions/NeoB2bleadProspectsAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "../CustomToolbarSelect";

class NeoB2bleadProspectsList extends Component {
	constructor(props) {
		super(props); 
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 
	}
  
	componentDidMount() { 
		this.props.getNeoB2bleadProspectsAll();
	}
	
	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.NeoB2bleadProspects;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteNeoB2bleadProspects(gridIds[0]);

	}

  updateXRecordsMain(selectedRows){  
    const { t } = this.props;
	const objList  = this.props.NeoB2bleadProspects;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
      gridIndexes.push(row.index); 
      idIndex=row.index; 
	return null;
    });
 
    if(gridIndexes.length>1){
      alert(`{t('zqwgridIndexesfound_x')}`);
      return false;
    }
 
    let obj=objList[idIndex]; 
	this.props.getNeoB2bleadProspectsGoto(obj.id); 
	//history.push(`/neob2bleadprospects/${obj.id}/edit`); 
  }
  

  render() { 
    const { t } = this.props;
	const columns = [
	 {
	  name: "id", label: t('zqwid_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "firstname", label: t('zqwfirstname_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "lastname", label: t('zqwlastname_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "cell", label: t('zqwcell_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "email", label: t('zqwemail_x'),
	  options: { filter: true, sort: true, display:false,}
	 },
	 {
	  name: "gender", label: t('zqwgender_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "language", label: t('zqwlanguage_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "dob", label: t('zqwdob_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
		name: "age", label: t("zqwage_x"),
		options: { filter: true, sort: true,}
	   },
	 {
	  name: "source", label: t('zqwsource_x'),
	  options: { filter: true, sort: true, display:false,}
	 },
	 {
	  name: "current_insurances", label: t('zqwcurrent_insurances_x'),
	  options: { filter: true, sort: true, display:false,}
	 },
	 {
	  name: "sent", label: t('zqwsent_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
		name: "send_date", label: t('zqwsend_date_x'),
		options: { filter: true, sort: true,}
	   },
	 {
		name: "observation", label: t('zqwobservation_x'),
		options: { filter: true, sort: true, display:false,}
	   },
	 {
	  name: "id_invoices", label: t('zqwid_invoices_x'),
	  options: { filter: true, sort: true, display:false,}
	 },
	 {
	  name: "user_creation", label: t('zqwNDICItxl5_x'), //zqwuser_creation_x 
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "created", label: t('zqwcreated_x'),
	  options: { filter: true, sort: true,}
	 },
	];
const textLabelOptions= {
    body: {
      noMatch: t('zqwgridnoMatch_x'),
      toolTip: t('zqwgridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('zqwgridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('zqwgridnext_x'),
      previous: t('zqwgridprevious_x'),
      rowsPerPage: t('zqwgridrowsPerPage_x'),
      displayRows: t('zqwgriddisplayRows_x'),
    },
    toolbar: {
      search: t('zqwgridsearch_x'),
      downloadCsv: t('zqwgriddownloadCsv_x'),
      print: t('zqwgridprint_x'),
      viewColumns: t('zqwgridviewColumns_x'),
      filterTable: t('zqwgridfilterTable_x'),
    },
    filter: {
      all: t('zqwgridall_x'),
      title: t('zqwgridftitle_x'),
      reset: t('zqwgridreset_x'),
    },
    viewColumns: {
      title: t('zqwgridvctitle_x'),
      titleAria: t('zqwgridvctitleAria_x'),
    }, 
  };	
			
	const options = {
	  filterType: 'checkbox',
	  textLabels: textLabelOptions,
	  onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		//console.log(currentRowsSelected, allRowsSelected);
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log(allRowsSelected);
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} isEdit={true} isDelete={true} />
      ),
	};  
	  
    if(this.props.NeoB2bleadProspects.length) {                                
      return (
        <div>
          <h4>{t('zqwNDICItxl4_x')}</h4>		  
		  <MUIDataTable
			  title={""}
			  data={this.props.NeoB2bleadProspects}
			  columns={columns}
			  options={options}
			/>
        </div>
      )    
    } else {
      return (<div>{t('zqwgridNoRecordfound_x')}</div>)
    }  }
}


const mapStateToProps = (state) => ({ NeoB2bleadProspects: state.NeoB2bleadProspects });  
const mapDispatchToProps = { addOrUpdateNeoB2bleadProspects, getNeoB2bleadProspects, getNeoB2bleadProspectsAll, getNeoB2bleadProspectsGoto, deleteNeoB2bleadProspects };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoB2bleadProspectsList));
