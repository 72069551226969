import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { addOrUpdateZxCompanies, getZxCompanies } from '../../actions/ZxCompaniesAction'; 
import { Validations } from '../../utils/validations'; 

let FIELD_VALIDATION={};
FIELD_VALIDATION['id']={check:true, chkType:'long', message:'incorrect value'};
FIELD_VALIDATION['name']={check:true, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['joining_date']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['address']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['phone']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['dob']={check:false, chkType:'Date', message:'incorrect value'};
FIELD_VALIDATION['zipcode']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['suite']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['city']={check:false, chkType:'String', message:'incorrect value'};
FIELD_VALIDATION['state']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['country']={check:false, chkType:'int', message:'incorrect value'};
FIELD_VALIDATION['company_code']={check:false, chkType:'String', message:'incorrect value'};

class ZxCompaniesForm extends React.Component {
  validation=new Validations();
  actionMode='new';
  
  constructor(props) {
    super(props);
    this.state = {
		id:0,
		name:'', e_name: false,
		joining_date:'', e_joining_date: false,
		address:'', e_address: false,
		phone:'', e_phone: false,
		dob:'', e_dob: false,
		zipcode:'', e_zipcode: false,
		suite:'', e_suite: false,
		city:'', e_city: false,
		state:'', e_state: false,
		country:'', e_country: false,
		company_code:'', e_company_code: false,
    }
	const locurl=window.location.href;
	const patt=/([0-9]+)(\/edit)$/;
	if(patt.test(locurl)){ 
		this.actionMode='edit'; 
	}else{ 
		this.actionMode='new'; 
	}  
	//console.log(' action mode '+this.actionMode);
  }
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleFocusout = (event) => {
	  if(FIELD_VALIDATION[event.target.name]!==undefined && FIELD_VALIDATION[event.target.name].check===true){
		  this.setState({ ["e_"+event.target.name]: false });
		  if(!this.validation.validate(FIELD_VALIDATION[event.target.name].chkType, event.target.value)){
			  this.setState({ ["e_"+event.target.name]: true }); 	
		  }
	  } 	
  };
   

  componentDidMount() { 
	if(this.actionMode==='edit'){ 
		console.log('edit  test  '+this.props.ZxCompanies.id);
		if(this.props.ZxCompanies.id===undefined){ 
			//means some refresh happens  id
			const idx = this.props.match.params.id; 
			//getZxCompanies(idx);
			Promise.resolve(this.props.getZxCompanies(idx))
			.then((response)=>{
				this.setState(response.ZxCompaniesOne);
				return response;
			});
		}	
	}	
  }

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const { t } = this.props;
    const id = this.state.id;
    const name = this.state.name;
    const joining_date = this.state.joining_date;
    const address = this.state.address;
    const phone = this.state.phone;
    const dob = this.state.dob;
    const zipcode = this.state.zipcode;
    const suite = this.state.suite;
    const city = this.state.city;
    const state = this.state.state;
    const country = this.state.country;
    const company_code = this.state.company_code;
    const ZxCompanies = {id: id, name: name, joining_date: joining_date, address: address, phone: phone, dob: dob, zipcode: zipcode, suite: suite, city: city, state: state, country: country, company_code: company_code, }
    
	let errorFound=0;
	for (let [fieldName, obj] of Object.entries(FIELD_VALIDATION)) {
	  //console.log(`${fieldName}: ${obj}`);
	  //event.target[0].value/name
	  if(FIELD_VALIDATION[fieldName]!==undefined && obj.check===true){
		  this.setState({ ["e_"+fieldName]: false });
		  let fieldValue= this.state[fieldName]; // ? this.state[fieldName] : this.props.ZxCompanies[fieldName];
		  //console.log(`${fieldName}: ${fieldValue}`);
		  if(!this.validation.validate(obj.chkType, fieldValue)){
			  errorFound++;
			  this.setState({ ["e_"+fieldName]: true }); 	
		  }
	  } 
	}
	if(errorFound > 0){ alert(`{t('errorFoundonform_x')}`); return false; }
	if(this.actionMode==='new'){ 
		this.props.addOrUpdateZxCompanies(ZxCompanies, 1); 
		//this.props.addZxCompanies(ZxCompanies); 
	}else{ 
		this.props.addOrUpdateZxCompanies(ZxCompanies, 2);
		//this.props.updateZxCompanies(ZxCompanies);
		
	}
  };

  handleCancel = () => { 
	if(this.actionMode==='new'){ 
		this.props.history.push(`./ZxCompanies`);
	}else{ 
		const idx = this.props.match.params.id; 		this.props.history.push(`./ZxCompanies/${idx}`);
		//this.props.history.push(`./ZxCompanies/${this.props.ZxCompanies.id}`);
	}
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <h3>{this.actionMode==='new'? t('acAddZxCompanies_x'): t('acEditZxCompanies_x')}</h3>
        <form onSubmit={this.handleSubmit}>
          		  
		  
		  <div className="form-group" style={{display:'none'}} >
			<label htmlFor="id">{t('id_x')}</label>
            <input type="text" name="id" id="id" defaultValue={this.state.id} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_id ? 'form-control fieldErr' : 'form-control'} placeholder={t('id_x')} /> 
			{this.state.e_id && (<span className="text-danger">{FIELD_VALIDATION['id'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="name">{t('name_x')}</label>
            <input type="text" name="name" id="name" defaultValue={this.state.name} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_name ? 'form-control fieldErr' : 'form-control'} placeholder={t('name_x')} /> 
			{this.state.e_name && (<span className="text-danger">{FIELD_VALIDATION['name'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="joining_date">{t('joining_date_x')}</label>
            <input type="text" name="joining_date" id="joining_date" defaultValue={this.state.joining_date} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_joining_date ? 'form-control fieldErr' : 'form-control'} placeholder={t('joining_date_x')} /> 
			{this.state.e_joining_date && (<span className="text-danger">{FIELD_VALIDATION['joining_date'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="address">{t('address_x')}</label>
            <input type="text" name="address" id="address" defaultValue={this.state.address} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_address ? 'form-control fieldErr' : 'form-control'} placeholder={t('address_x')} /> 
			{this.state.e_address && (<span className="text-danger">{FIELD_VALIDATION['address'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="phone">{t('phone_x')}</label>
            <input type="text" name="phone" id="phone" defaultValue={this.state.phone} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_phone ? 'form-control fieldErr' : 'form-control'} placeholder={t('phone_x')} /> 
			{this.state.e_phone && (<span className="text-danger">{FIELD_VALIDATION['phone'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="dob">{t('dob_x')}</label>
            <input type="text" name="dob" id="dob" defaultValue={this.state.dob} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_dob ? 'form-control fieldErr' : 'form-control'} placeholder={t('dob_x')} /> 
			{this.state.e_dob && (<span className="text-danger">{FIELD_VALIDATION['dob'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="zipcode">{t('zipcode_x')}</label>
            <input type="text" name="zipcode" id="zipcode" defaultValue={this.state.zipcode} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_zipcode ? 'form-control fieldErr' : 'form-control'} placeholder={t('zipcode_x')} /> 
			{this.state.e_zipcode && (<span className="text-danger">{FIELD_VALIDATION['zipcode'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="suite">{t('suite_x')}</label>
            <input type="text" name="suite" id="suite" defaultValue={this.state.suite} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_suite ? 'form-control fieldErr' : 'form-control'} placeholder={t('suite_x')} /> 
			{this.state.e_suite && (<span className="text-danger">{FIELD_VALIDATION['suite'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="city">{t('city_x')}</label>
            <input type="text" name="city" id="city" defaultValue={this.state.city} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_city ? 'form-control fieldErr' : 'form-control'} placeholder={t('city_x')} /> 
			{this.state.e_city && (<span className="text-danger">{FIELD_VALIDATION['city'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="state">{t('state_x')}</label>
            <input type="text" name="state" id="state" defaultValue={this.state.state} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_state ? 'form-control fieldErr' : 'form-control'} placeholder={t('state_x')} /> 
			{this.state.e_state && (<span className="text-danger">{FIELD_VALIDATION['state'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="country">{t('country_x')}</label>
            <input type="text" name="country" id="country" defaultValue={this.state.country} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_country ? 'form-control fieldErr' : 'form-control'} placeholder={t('country_x')} /> 
			{this.state.e_country && (<span className="text-danger">{FIELD_VALIDATION['country'].message}</span>)} 
          </div>

		  <div className="form-group">
			<label htmlFor="company_code">{t('company_code_x')}</label>
            <input type="text" name="company_code" id="company_code" defaultValue={this.state.company_code} onChange={this.handleChange} onBlur={this.handleFocusout}
              className={this.state.e_company_code ? 'form-control fieldErr' : 'form-control'} placeholder={t('company_code_x')} /> 
			{this.state.e_company_code && (<span className="text-danger">{FIELD_VALIDATION['company_code'].message}</span>)} 
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-primary">{this.actionMode==='new'? t('Create'): t('Update')}</button> &nbsp; 
			<button type="button" onClick={this.handleCancel} className="btn btn-warning">{t('Cancel')}</button>
          </div>
        </form>
      </div>
    );
  }
}

//const TransZxCompaniesForm = withTranslation()(ZxCompaniesForm); 
const mapStateToProps = (state) => ({ ZxCompanies: state.ZxCompanies });

const mapDispatchToProps = { addOrUpdateZxCompanies, getZxCompanies };   

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(ZxCompaniesForm));
