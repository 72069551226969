import axios from "axios";
import history from "../history";
import { API_DOMAIN } from "../appConfig";
import { axiosDefaults } from "../appConfigaxios";

import { encryptText, decryptText } from "../appEncryption";

export const RECEIVE_NDIFIELDS = "GET_NDIFIELDS";
export const ADD_NDIFIELDS = "ADD_NDIFIELDS";
export const RECEIVE_NDIFIELDSX = "RECEIVE_NDIFIELDSX";
export const REMOVE_NDIFIELDS = "REMOVE_NDIFIELDS";
export const UPDATE_NDIFIELDS = "UPDATE_NDIFIELDS";
export const REPLACE_NDIFIELDS = "REPLACE_NDIFIELDS";
const apiUrl = API_DOMAIN + "/ndifields";
const apiUrlPField = API_DOMAIN + "/ndiproductfields";
const apiUrlBase = API_DOMAIN;

axiosDefaults(null);

export const getNdiFieldsAll = () => {
  return (dispatch) => {
    return axios
      .get(`${apiUrl}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NDIFIELDS,
          NdiFields: response.data.data.ndifields,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const addOrUpdateTicketData = (TicketData) => {
  if (TicketData.isMULTIPART !== undefined) {
    const myAxios = { isMULTIPART: 1 };
    axiosDefaults(myAxios);
  }
  return (dispatch) => {
    return axios
      .post(`${apiUrlBase}/ticketdata`, TicketData)
      .then((response) => {
        let data = response.data.data; //response.data.data;
        return dispatch({ type: ADD_NDIFIELDS, NdiFields: data });
      })
      .catch((err) => {
        if (err.response) {
          console.error(err);
        } else if (err.request) {
          // client never received a response, or request never left
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          console.error(err);
        }
      });
  };
};

export const downloadFileTicketData001 = (TicketData) => {
  const myAxios = { isBLOB: 1 };
  axiosDefaults(myAxios);
  return (dispatch) => {
    return axios
      .post(`${apiUrlBase}/ticketdata`, TicketData)
      .then((response) => {
        let data = response.data; //response.data.data;
        const file = new Blob([data], { type: "application/pdf" });
        const downloadUrl = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", TicketData.shortFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        return dispatch({ type: ADD_NDIFIELDS, NdiFields: null });
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          console.error(err);
        }
      });
  };
};

export const downloadFileTicketData = (TicketData) => {
  return (dispatch) => {
    const token = decryptText(localStorage.token);
    const tokenRid = localStorage.rid;
    const profile = decryptText(localStorage.profile);
    let user = null;
    if (
      localStorage.getItem("user") !== undefined &&
      localStorage.getItem("user") !== null
    ) {
      user = decryptText(localStorage.getItem("user")).split(":");
    }

    const profileLoc = JSON.parse(profile);
    const userId = user[4];

    return fetch(`${apiUrlBase}/ticketdata`, {
      method: "POST",
      headers: {
        "Content-Type": "blob",
        //'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "auth-id": `${userId}`,
      },
      body: JSON.stringify(TicketData),
    })
      .then(function (response) {
        if (response.ok) {
          return response.blob().then(function (myBlob) {
            const downloadUrl = window.URL.createObjectURL(myBlob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", TicketData.shortFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return true;
          });
        } else {
          return response.json().then(function (jsonError) {
            window.alert("Sorry, while trying to get the file");
            console.error("Sorry, while trying to get the file");
          });
        }
      })
      .catch(function (error) {
        console.log(
          "There has been a problem with your fetch operation: ",
          error.message
        );
        window.alert(
          "There has been a problem with your fetch operation: ",
          error.message
        );
      });
    //};
  };
};

export const downloadFileInvoiceTicketData = (TicketData) => {
  return (dispatch) => {
    const token = decryptText(localStorage.token);
    const tokenRid = localStorage.rid;
    const profile = decryptText(localStorage.profile);
    let user = null;
    if (
      localStorage.getItem("user") !== undefined &&
      localStorage.getItem("user") !== null
    ) {
      user = decryptText(localStorage.getItem("user")).split(":");
    }
    let customfilevars = "";

    const profileLoc = JSON.parse(profile);
    const userId = user[4];
    const invoiceId = TicketData.invoiceId;
    return fetch(
      `${apiUrlBase}/ndi/client/invoice?invoiceId=${invoiceId}&fileaddress=${invoiceId}.pdf`,
      {
        method: "GET",
        headers: {
          "Content-Type": "blob",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "auth-id": `${userId}`,
        },
      }
    )
      .then(function (response) {
        if (response.ok) {
          return response.blob().then(function (myBlob) {
            const downloadUrl = window.URL.createObjectURL(myBlob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", TicketData.shortFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return true;
          });
        } else {
          return response.json().then(function (jsonError) {
            window.alert("Sorry, while trying to get the file");
            console.error("Sorry, while trying to get the file");
          });
        }
      })
      .catch(function (error) {
        console.log(
          "There has been a problem with your fetch operation: ",
          error.message
        );
        window.alert(
          "There has been a problem with your fetch operation: ",
          error.message
        );
      });
  };
};

export const downloadFileTicketData000 = (TicketData) => {
  const myAxios = { isBLOB: 1 };
  axiosDefaults(myAxios);
  return (dispatch) => {
    return axios
      .post(`${apiUrlBase}/ticketdata`, TicketData)
      .then((response) => {
        let data = response.data; //response.data.data;
        const file = new Blob([data], { type: "application/pdf" });
        const downloadUrl = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = downloadUrl;
        //link.setAttribute('download', 'file.pdf'); //any other extension
        link.setAttribute("download", TicketData.shortFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return dispatch({ type: ADD_NDIFIELDS, NdiFields: null });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          // anything else
          console.error(err);
        }
      });
  };
};

export const downloadFileJudiciaryInvoice = (TicketData) => {
  return (dispatch) => {
    const token = decryptText(localStorage.token);
    const tokenRid = localStorage.rid;
    const profile = decryptText(localStorage.profile);
    let user = null;
    if (
      localStorage.getItem("user") !== undefined &&
      localStorage.getItem("user") !== null
    ) {
      user = decryptText(localStorage.getItem("user")).split(":");
    }

    const profileLoc = JSON.parse(profile);
    const userId = user[4];
    return fetch(`${apiUrlBase}/ndi/client/invoice`, {
      method: "POST",
      headers: {
        "Content-Type": "blob",
        //'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "auth-id": `${userId}`,
      },
      body: JSON.stringify(TicketData),
    })
      .then(function (response) {
        if (response.ok) {
          return response.blob().then(function (myBlob) {
            const downloadUrl = window.URL.createObjectURL(myBlob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", TicketData.shortFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return true;
          });
        } else {
          return response.json().then(function (jsonError) {
            window.alert("Sorry, while trying to get the file");
            console.error("Sorry, while trying to get the file");
          });
        }
      })
      .catch(function (error) {
        console.log(
          "There has been a problem with your fetch operation: ",
          error.message
        );
        window.alert(
          "There has been a problem with your fetch operation: ",
          error.message
        );
      });
    //};
  };
};

export const addFoldersProducts = (foldersProductsData) => {
  // if(TicketData.isMULTIPART!==undefined){
  // 	const myAxios={isMULTIPART:1}
  //    axiosDefaults(myAxios);
  // }
  return (dispatch) => {
    return axios
      .post(`${apiUrlBase}/ndifoldersproducts`, foldersProductsData)
      .then((response) => {
        let data = response.data.data; //response.data.data;
        return dispatch({ type: ADD_NDIFIELDS, NdiFields: data });
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log(err);
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          // anything else
          console.error(err);
        }
      });
  };
};

export const addNdiFields = (NdiFields) => {
  return (dispatch) => {
    return axios
      .post(`${apiUrl}`, NdiFields)
      .then((response) => {
        let data = response.data;

        return dispatch({ type: ADD_NDIFIELDS, NdiFields: data });
      })
      .then(() => {
        history.push("/NdiFields");
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNdiFields = (id) => {
  return (dispatch) => {
    return axios
      .get(`${apiUrl}?id=${id}`)
      .then((response) => {
        console.log("response from server .... ");
        console.log(response.data.data);
        return dispatch({
          type: RECEIVE_NDIFIELDSX,
          NdiFieldsOne: response.data.data,
        }); //.ndifields
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getProductFields = (obj) => {
  const id = obj.id;
  const ticketId = obj.ticketId;
  let lang = "";
  let zendsk = "";
  if (obj.lang !== undefined) {
    lang = obj.lang;
  } else {
    lang =
      localStorage.i18nextLng !== undefined ? localStorage.i18nextLng : "fr";
  }
  if (obj.zendeskapi !== undefined) {
    zendsk = "&zendeskapi=" + obj.zendeskapi;
  }

  console.log("BEFORE response from server .... " + id, lang);
  //return axios.get(`${apiUrl}/${id}`)
  return (dispatch) => {
    return axios
      .get(
        `${apiUrlPField}?id=${id}&ticketId=${ticketId}&lang=${lang}${zendsk}`
      )
      .then((response) => {
        console.log("response from server .... ");
        console.log(response.data);
        return dispatch({
          type: RECEIVE_NDIFIELDSX,
          NdiFieldsOne: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getProductFieldsAndTrans = (obj) => {
  const id = obj.id;
  const ticketId = obj.ticketId;
  let lang = "";
  let zendsk = "";
  if (obj.lang !== undefined) {
    lang = obj.lang;
  } else {
    lang =
      localStorage.i18nextLng !== undefined ? localStorage.i18nextLng : "fr";
  }
  if (obj.zendeskapi !== undefined) {
    zendsk = "&zendeskapi=" + obj.zendeskapi;
  }

  return (dispatch) => {
    return axios
      .get(
        `${apiUrlPField}?id=${id}&ticketId=${ticketId}&lang=${lang}${zendsk}`
      )
      .then((response) => {
        return dispatch({
          type: RECEIVE_NDIFIELDSX,
          NdiFieldsOne: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getSearchConflict = (obj) => {
  const id = obj.id;
  const ticketId = obj.ticketId;
  let lang = "";
  let zendsk = "";
  let search = "";
  let byfield = "";
  if (obj.search !== undefined) {
    search = obj.search;
  }
  if (obj.search !== undefined) {
    search = obj.search;
  }

  return (dispatch) => {
    return axios
      .get(
        `${apiUrlBase}/clients/searchconflicts?search=${search}&byfield=${byfield}`
      )
      .then((response) => {
        return dispatch({
          type: RECEIVE_NDIFIELDSX,
          NdiFieldsOne: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getTicketSummary = (obj) => {
  const id = obj.id;
  const ticketId = obj.ticketId;
  let lang = "";
  let xtraParams = "";
  if (obj.lang !== undefined) {
    lang = obj.lang;
  } else {
    lang =
      localStorage.i18nextLng !== undefined ? localStorage.i18nextLng : "fr";
  }
  if (obj.neoid !== undefined) {
    xtraParams += "&neoid=" + obj.neoid;
  }
  if (obj.email !== undefined) {
    xtraParams += "&neoemail=" + obj.email;
  }
  return (dispatch) => {
    return axios
      .get(
        `${apiUrlPField}/ticketsummary?id=${id}&ticketId=${ticketId}&lang=${lang}${xtraParams}`
      )
      .then((response) => {
        return dispatch({
          type: RECEIVE_NDIFIELDSX,
          NdiFieldsOne: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

//sendTicketLead
export const sendTicketLead = (obj) => {
  const id = obj.id;
  const ticketId = obj.ticketId;
  let lang = "";
  const idb2bclient = obj.idb2bclient;
  const id_clients = obj.id_clients;
  const email = obj.email;
  const tel = obj.tel;
  if (obj.lang !== undefined) {
    lang = obj.lang;
  } else {
    lang =
      localStorage.i18nextLng !== undefined ? localStorage.i18nextLng : "fr";
  }
  return (dispatch) => {
    return axios
      .get(
        `${apiUrlPField}/ticketsummary?idb2bclient=${idb2bclient}&id_clients=${id_clients}&lang=${lang}&ticketLead=1&tel=${tel}&email=${email}`
      )
      .then((response) => {
        return dispatch({
          type: RECEIVE_NDIFIELDSX,
          NdiFieldsOne: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getClientTransactions = (obj) => {
  const id = obj.clientId;
  return (dispatch) => {
    return axios
      .get(`${apiUrlBase}/ndiclientransactions?client_id=${id}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NDIFIELDS,
          NdiFields: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNdiFieldsGoto = (id) => {
  return (dispatch) => {
    return history.push(`/NdiFields/${id}/edit`);
  };
};
export const deleteNdiFields = (id) => {
  return (dispatch) => {
    return axios
      .delete(`${apiUrl}/delete/${id}`)
      .then((response) => {
        dispatch({ type: REMOVE_NDIFIELDS, payload: { id } });
      })
      .then(() => {
        history.push("/NdiFields");
      })
      .catch((error) => {
        console.error(error);
      });
  };
};
export const updateNdiFields = (NdiFields) => {
  const id = NdiFields.id;
  return (dispatch) => {
    return axios
      .patch(`${apiUrl}/update/${NdiFields.id}`, {
        name: NdiFields.name,
        text_label: NdiFields.text_label,
        placeholder: NdiFields.placeholder,
        field: NdiFields.field,
        field_type: NdiFields.field_type,
        field_default_value: NdiFields.field_default_value,
        mask: NdiFields.mask,
        field_service: NdiFields.field_service,
        field_dataset: NdiFields.field_dataset,
        tag_id: NdiFields.tag_id,
      })
      .then((response) => {
        const data = response.data;
        dispatch({
          type: UPDATE_NDIFIELDS,
          payload: {
            id: data.id,
            name: data.name,
            text_label: data.text_label,
            placeholder: data.placeholder,
            field: data.field,
            field_type: data.field_type,
            field_default_value: data.field_default_value,
            mask: data.mask,
            field_service: data.field_service,
            field_dataset: data.field_dataset,
            tag_id: data.tag_id,
          },
        });
        dispatch({
          type: REPLACE_NDIFIELDS,
          payload: {
            id: data.id,
            name: data.name,
            text_label: data.text_label,
            placeholder: data.placeholder,
            field: data.field,
            field_type: data.field_type,
            field_default_value: data.field_default_value,
            mask: data.mask,
            field_service: data.field_service,
            field_dataset: data.field_dataset,
            tag_id: data.tag_id,
          },
        });
      })
      .then(() => {
        history.push(`/NdiFields/${id}`);
      })
      .catch((error) => {
        console.error(error);
      });
  };
};
export const addOrUpdateNdiFields = (NdiFields, actionMode) => {
  if (actionMode === 1) {
    return addNdiFields(NdiFields);
  } else {
    return updateNdiFields(NdiFields);
  }
};

// getDocLinkType sendDocLink
export const getDocLinkType = () => {
  return (dispatch) => {
    return axios
      .get(`${apiUrlBase}/listformpublic`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NDIFIELDSX,
          NdiFieldsOne: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const sendDocLink = (docLink) => {
  const form = docLink.form_name;
  const target = docLink.target;
  const ticketid = docLink.ticketid;
  const action = docLink.action;
  const formurl = docLink.formurl;
  const emailphone = docLink.emailphone;
  const sendfilelink = docLink.sendfilelink;
  const docForm = {
    ticketid: ticketid,
    target: target,
    form_name: form,
    action: action,
    formurl: formurl,
    emailphone: emailphone,
    sendfilelink: sendfilelink,
  };

  if (docLink.otherEmails !== undefined) {
    docForm.otherEmails = docLink.otherEmails;
  }

  return (dispatch) => {
    return axios
      .post(`${apiUrlBase}/ndi/send/publicform`, docForm)
      .then((response) => {
        let data = response.data.data; //response.data.data;
        console.log(data);
        return dispatch({ type: RECEIVE_NDIFIELDSX, NdiFieldsOne: data });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
          console.error(err.response);
        } else if (err.request) {
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          console.error(err);
        }
      });
  };
};

export const getDocLink = (docLink) => {
  //   const docLink={form_name:form_name, target:target, ticketid:ticket};
  const form = docLink.form_name;
  const target = docLink.target;
  const ticketid = docLink.ticketid;
  const emailphone = docLink?.emailphone
  let url = docLink.form_oldurl;
  if (url !== undefined && url !== null) {
  } else {
    url = "";
  }
  url = "" + encodeURIComponent(url);
  const id = "" + docLink.id;
  return (dispatch) => {
    return axios
      .get(
        `${apiUrlBase}/ndi/send/publicform?ticketid=${ticketid}&target=${target}&form_name=${form}&url=${url}&id=${id}&emailphone=${emailphone}`
      )
      .then((response) => {
        let data = response.data.data; //response.data.data;
        return dispatch({ type: RECEIVE_NDIFIELDSX, NdiFieldsOne: data });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          // anything else
          console.error(err);
        }
      });
  };
};

export const getDownloadFileLink = (docLink) => {
  const form = docLink.form_name;
  const target = docLink.target;
  const ticketid = docLink.ticketid;
  const folder = true;
  const url = docLink.url;
  const exp = 172800; //48hrs
  return (dispatch) => {
    return axios
      .get(
        `${apiUrlBase}/getUrltoGetFile?url=${url}&folder=${folder}&expirationTime=${exp}`
      )
      .then((response) => {
        let data = response.data.data; //response.data.data;
        return dispatch({ type: RECEIVE_NDIFIELDSX, NdiFieldsOne: data });
      })
      .catch((err) => {
        if (err.response) {
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          // anything else
          console.log("error falls here #3");
          console.log(err);
          console.error(err);
        }
      });
  };
};

export const sendDocLinkBackup = (docLink) => {
  const form = docLink.form_name;
  const target = docLink.target;
  const ticketid = docLink.ticketid;
  return (dispatch) => {
    return axios
      .get(
        `${apiUrlBase}/ndi/send/publicform?ticketid=${ticketid}&target=${target}&form_name=${form}`
      )
      .then((response) => {
        let data = response.data.data; //response.data.data;
        return dispatch({ type: RECEIVE_NDIFIELDSX, NdiFieldsOne: data });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
          console.error(err.response);
        } else if (err.request) {
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          // anything else
          console.error(err);
        }
      });
  };
};

export const getNeoDocNotes = (docObj) => {
  const form = docObj.form_name;
  const ticketid = docObj.ticket;
  return (dispatch) => {
    return axios
      .get(`${apiUrlBase}/notesfields?ticket=${ticketid}`)
      .then((response) => {
        let data = response.data.data; //response.data.data;
        return dispatch({ type: RECEIVE_NDIFIELDSX, NdiFieldsOne: data });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          console.error(err);
        }
      });
  };
};

export const getGroupsAndUsers = (docLink) => {
  //   const docLink={form_name:form_name, target:target, ticketid:ticket};
  const form = docLink.form_name;
  const target = docLink.target;
  const ticketid = docLink.ticketid;
  const folder = true;
  const url = docLink.url;
  const exp = 172800; //48hrs
  return (dispatch) => {
    return axios
      .get(`${apiUrlBase}/clients/ticketgroupsandusers`)
      .then((response) => {
        let data = response.data.data; //response.data.data;
        return dispatch({ type: RECEIVE_NDIFIELDSX, NdiFieldsOne: data });
      })
      .catch((err) => {
        if (err.response) {
          console.log("error falls here #1");
          console.log(err.response);
          console.log(err);
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          console.error(err);
        }
      });
  };
};

export const sendAssignedGroupUser = (docForm) => {
  return (dispatch) => {
    return axios
      .post(`${apiUrlBase}/clients/ticketgroupsandusers`, docForm)
      .then((response) => {
        let data = response.data.data; //response.data.data;
        return dispatch({ type: RECEIVE_NDIFIELDSX, NdiFieldsOne: data });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
          console.error(err.response);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("error falls here #2");
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          // anything else
          console.error(err);
        }
      });
  };
};
