import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNeoVentes, addNeoVentesSC, getNeoVentes, getClientSearch, getB2BClientSearch, saveB2BClient, checkClientEligibility, getB2BContractListDetails, getClientAddress, getCodePromo, getNeoVentesPromos, getProductInfo, getPartialInvoicePayments, getAllProducts, getChargeDeskStatus, getClientTransactionsFullInfo } from '../../actions/NeoVentesAction'; 
//import { addOrUpdateNeoProducts, getNeoProducts, getNeoProductsAll, getNeoProductsGoto, deleteNeoProducts } from '../../actions/NeoProductsAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
//import CustomToolbarSelect from "../CustomToolbarSelect";


import Select from 'react-select';
import Autocomplete from '@material-ui/lab/Autocomplete';    
import InputMask from "react-input-mask";
import Switch from '@material-ui/core/Switch';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';

import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormGroup from '@material-ui/core/FormGroup'; 
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Toastr from "../../messages";
import  Loader from "../../loader";
import MuiAlert from '@material-ui/lab/Alert';

import SaveIcon from '@material-ui/icons/Save';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import HelpIcon from '@material-ui/icons/Help';
import EmailIcon from '@material-ui/icons/Email';
//import SaveSharpIcon from '@material-ui/icons/SaveSharp';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'; 

import ZAFClient from 'zendesk_app_framework_sdk';
import { RotateRightSharp } from '@material-ui/icons';
import StripePayment from '../../StripePayment'; 
import moment from 'moment';
import  getProvinceTaxInfo from "../../appTaxes"; 

/////////////////////////////
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';  
import Grid from '@material-ui/core/Grid'; 
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete'; 
import DownloadIcon from '@material-ui/icons/CloudDownload';
//import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import SpellcheckIcon from '@material-ui/icons/Edit';
//import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import AddIcon from '@material-ui/icons/Add';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails'; 
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import  { dateFormat } from "../../appDateFormat";
import history from '../../history'; 



class NeoMiniPayment extends Component {
	//validation=new Validations();
	actionMode='new';
	requestTimer = null;
	backInvoiceId=0;
	stripeAvoidManyTokens=0;
	stripeTryTimes=0;
	stripeNeoTokenTrack=0;
	paymentBtnTryTimes2=0;
	paymentBtnTryTimes3=0;
	
	constructor(props) {
		super(props);
		this.stateInit = {
			id:0,
			name:'', e_name: false,
			description:'', e_description: false,
			amount:'', e_amount: false,
			
			productCart:  [], 
			productId:"", productTitre:'', product:{}, productPrice:'', productPriceTax:'', productPriceTaxOnly:0, productExtraOptions:[], 
			productExtraOptionCtrl:null, productExtraOptionCtrl2:[], showProductOption:false, 
			prodTagPrice:  [], prodTagPriceTax:  [], prodTagPriceTaxOnly:  [], prodTagTotOptions:  [], grandTotal: 0, 
		
			idClient:'0',
			firstname:'', e_firstname: false,
			lastname:'', e_lastname: false,
			cell:'', e_cell: false,
			terms:'', e_terms: false,
			active:'', e_active: false,
			created:'', e_created: false,
			email:'', e_email: false,
			id_users:'0', e_id_users: false,
			language:'fr', e_language: false,
			push:'', e_push: false,
			id_zendesk:'', e_id_zendesk: false,
			sms:1, e_sms: false,
			stripe_id:'', e_stripe_id: false,
		
		
			idAddress:0, 
			address:'', e_address: false,
			unitsuite:'', e_unitsuite: false,
			city:'', e_city: false,
			province:'QC', e_province: false, province2:'', 
			cp:'', e_cp: false,
			country:'Canada', e_country: false,
			company:'', e_company: false, 
			existedClientRO: false, openPossibleZipCode:false, listAddressOnCode: null, listZipCodeAddresses:[], countryTuner:'',  
			listPromotionCodes:[], codepromoOptions:[], openInfoStepsPopup:false, infoStepsPopupno:0,
			infoProduct:{data:[]}, infoProduct_title:"", infoProduct_excerpt:"", partialCanClose:0,
			openTransactionPopup: false, twoStepSale:"",
		
			 
			listOfSuggestion:[],
			valueClientSearch:'', 
			inputValueClientSearch:'',
			selectedIdSuggestion:'',
	  
			listOfSuggestion2:[],
			valueClientSearch2:'', 
			inputValueClientSearch2:'',
			selectedIdSuggestion2:'',
	  
			clientInfo:{ }, 
			clientAddress:{ },
			globalStepWorkflow: 1, // 1 init 2:start with product 3:start with customerInfo 4:payment 5: case close 
			workflowType: 1, //1 begin, 2 with clientInfo, 3 with Prod
	  
			stripePaymentOpen: false, isLoaderOpen:false, 
			toastrMessage: '', toastrType: '', toastrDuration: 6000,
			cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
			orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0, 
		
			stripeToken:'',
			dossier:'', //421
			isRecurrentPayment: 0,  nowPayment: 0, qtyPayment: 1, laterpayment:4, fromDatePayment:'', 
			paymentRound:0, paymentWay: 'full', nowPaymentRO: true, recurPaymentAmount: 0,
			paymentsReceived: [0], totalPaymentReceived: 0, paymentWayRO: false, beforePaymentTotal:0, 
			id_invoice: 0, pMethod:1, status_invoice:'', 
			showProdMulti:0, productsList:{"products": []}, openPListAsOnTheSite: false,
			errorTransacMessage: "", emailDoesNotExist: 0, openPossibleListOfClient: false,
			listClientOnCode:[], possibleListOfClient:[],
			statusChargeDesk: 0, chargeDeskIdStr:"",
			openPossibleCDStatusInfo:false, possibleCDStatusInfo:{}, possibleListOfCDStatusInfo:[],  
			taxInfo:{}, isupsale:false, upsaleTicket:"", isAmerindian:false, no_amerindien:"", 
			listOfTickets:[], goProd:0, 
			newTicket:false, currentTicket:'', 
		};
	  
			this.state = JSON.parse(JSON.stringify(this.stateInit)); // Object.assign(this.stateInit);

		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 
	}

	//productsList=productList();
	productsList={"products": []}; // this.state.productsList; // productList();
  
	componentDidMount() { 
		// const { t } = this.props;
		// const taxInfo=getProvinceTaxInfo("QC");
		// this.setState({ ["taxInfo"]: taxInfo,"language":"" });  
		this.setState({ ["productCart"]: [],"productId":"",	 });  
	 	this.setState({ ["grandTotal"]:  0,["beforePaymentTotal"]:  0 });
		this.setState({["orderBruteSubTotal"]: 0, ["orderNetSubTotal"]: 0, ["orderTVQ"]: 0,["orderTPS"]: 0,["orderTotal"]: 0,});
    	this.setState({ ["nowPayment"]:  0 });


		//this.props.getNeoProductsAll();
		//this.productsList=this.props.
		const clientProdTransInfo=this.props.clientProdTransInfo; 
		const client=clientProdTransInfo.client; 
		//const transactions=clientProdTransInfo.transactions;   

		//const Alert=this.Alert;
		//const clientProdTransInfo=
		let stripeId="";
		console.log("woooooooooooooo ", client);
		if(client!==undefined && client!==null){  
			this.setState(client);  
			this.setState({["clientInfo"]: client, ["idClient"]: parseInt(client.id)});  
		}

		if(this.props.productsList.products.length > 0){
			this.productsList=this.props.productsList;
		}
		if(this.props.productsList.products.length > 0){
			this.productsList=this.props.productsList;
		}
		if(this.props.currentTicket!==undefined && this.props.currentTicket!==null && this.props.currentTicket!==""){ 
			this.setState({ ["currentTicket"]: this.props.currentTicket});  
		}

		 
		if(this.props.clientAddress!==undefined && this.props.clientAddress!==null){ 
			const taxInfo=getProvinceTaxInfo(this.props.clientAddress.province); //QC
			this.setState({ ["clientAddress"]: this.props.clientAddress, 
			["province"]: this.props.clientAddress.province, ["taxInfo"]: taxInfo, });  
		}

		 
	}

	componentWillMount(){
		//console.log("WEEEEEEEEEEEE MINIPAYMENT componentWillMount ", new Date());
	}

	componentDidUpdate(prevProps) {
		if (prevProps.openx !== this.props.openx) {
		  //this.updateAndNotify();
		  if(this.props.openx){
			//console.log("WEEEEEEEEEEEE MINIPAYMENT componentWillMount OPEN ", new Date());
			this.setState({ ["productCart"]: [],"productId":"",	 });  
			this.setState({ ["grandTotal"]:  0,["beforePaymentTotal"]:  0 });
			this.setState({["orderBruteSubTotal"]: 0, ["orderNetSubTotal"]: 0, ["orderTVQ"]: 0,["orderTPS"]: 0,["orderTotal"]: 0,});
			this.setState({ ["nowPayment"]:  0, ["paymentRound"]:  0 });
		  }
		}
	}

	handleCheckBoxCheck = (event) => {
		let isChecked=false; 
		if(event.target.checked){ isChecked=true; }
		this.setState({ [event.target.name]: isChecked }); 
	
	};

	getStripStatus  = (stripobject) => {  
		//stripePaymentOpen to close   
		//result.token.id  result.error.message
		const result=stripobject;  
		const stripeNow= Date.now(); 
		const timesbetween=((stripeNow*1)-(this.stripeTryTimes*1));
		const stripeNeoToken=result.stripeNeoToken;
		// console.log("OLD STYLE SUCCESS ");
		// console.log(result.token);
		// console.log("stripeToken= "+result.token.id+" ");
		// console.log("//SUCCESS stripeToken="+result.token.id); //return false;
		// console.log("//SUCCESS times="+timesbetween+" track="+stripeNeoToken);
	
		//this.stripeTryTimes=0; && this.stripeNeoTokenTrack===stripeNeoToken
		if(timesbetween <60000){
		  //this.stripeTryTimes=stripeNow;
		  console.log("ooooooh wont go --- stripeToken= "+result.token.id+" "); 
		  window.alert("Désolé, on ne processe pas une carte plus qu'une fois en moins d'une minute. Svp essayez encore.");
		  return false;
		}else{ this.stripeTryTimes=stripeNow; this.stripeNeoTokenTrack=stripeNeoToken; }
	
		//window.alert("FINAAAAAAAAAAAAAAAAL SUCCESS stripeToken= "+result.token.id+" "); return false;
		//if(this.stripeAvoidManyTokens >0){ console.log("wont go --- stripeToken= "+result.token.id+" "); return false;}
		
		this.setState({["stripePaymentOpen"]: false, ["stripeToken"]: result.token.id, ["isLoaderOpen"]: true});
		this.showToast("Premiere etape, veuillez patieter un instant", "success");
		//this.handleGetCustomerInfoPayment2(null);
		// if(this.actionMode==='partialInvoice') {
		//   this.stripeAvoidManyTokens=1;
		//   this.handleMakePartialPayment(null);
		// }else{ 
		//   this.stripeAvoidManyTokens=1; 
		//   this.handleGetCustomerInfoPayment2(null); 
		// }

		console.log("//#2SUCCESS stripeToken="+result.token.id+" track="+stripeNeoToken);
		console.log(result);  

		this.stripeAvoidManyTokens=1;
		//this.handleMakePartialPayment(null);
		//this.handleMakePaymentSC(null);
		setTimeout(() => { 
			this.handleMakePaymentSC(null);
		  }, 100);
	
		//console.log("//#2SUCCESS stripeToken="+result.token.id+" track="+stripeNeoToken); return false;
	
	  }


  
  payWithtInterac= (event) => {
      const { t } = this.props;
      event.preventDefault();
      //this.setState({ ["isLoaderOpen"]: true}); return false;	
      this.setState({ ["pMethod"]: 3 }); 	
      const productPriceTotal= this.state.grandTotal; //orderTotal
      const nowPayment= this.state.nowPayment;
      const paymentWay= this.state.paymentWay; 
      /*if(paymentWay!=="full"){
        this.paymentWayRef.current.focus();
        this.showToast(t("zqwNVFrmEl17_x"), "error");
        return false;
      }*/

      const currentTicket = this.state.currentTicket;
      //const productIdone=parseInt(this.state.productId); 
      const productIdone=(this.state.productId); 
      if(productIdone===null || productIdone===""){  // productIdone===0 ||  
        //if(productIdone===0){
        console.log("//EEEEEEEEEEROR 333333333333, NADA MAL  00000 z=", this.state.idClient, this.state.stripe_id); 
        this.showToast(t("zqwNVFrmEl7_x"), "error");
        return false;
      }
      
      if(currentTicket===""){
        console.log("//EEEEEEEEEEROR2 333333333333, NADA MAL  00000 z=", this.state.idClient, this.state.stripe_id); 
        this.showToast(t("zqwMniPEl_x"), "error");
        return false;
      }
  
      //const result=stripobject;  
      const paytryNow= Date.now(); 
      const timesbetween=((paytryNow*1)-(this.paymentBtnTryTimes2*1)); 
      if(timesbetween <60000){
        //this.stripeTryTimes=paytryNow;
        const timerest=(60-(timesbetween/1000));
        console.log("ooooooh wont go ---   "+timesbetween+" Svp essayez encore dans "+timerest+" secondes"); 
        this.showToast(t("zqwNVFrmEl16_x", {timerest:timerest}), "error");
        return false;
      }else{ this.paymentBtnTryTimes2=paytryNow;  }
  
      console.log("//SUCCESS bueno paso los filtros, NADA MAL"); //return false;
  
      
      //this.handleGetCustomerInfoPayment2(event);
      /*setTimeout(() => { 
        if(this.actionMode==='partialInvoice') {
          this.handleMakePartialPayment(event);
        }else{ this.handleGetCustomerInfoPayment2(event); }
        //this.handleGetCustomerInfoPayment2(event);
      }, 100);*/
      
     
    setTimeout(() => { 
      this.handleMakePaymentSC(event);
    }, 100);
  
  
  };
    
	  
  payWithCardOnFile= (event) => {
    const { t } = this.props;
    event.preventDefault();
    //this.setState({ ["isLoaderOpen"]: true}); return false;	
	console.log("//SUCCESS 333333333333, NADA MAL  00000 z=", this.state.idClient, this.state.stripe_id); 
    // if(this.state.stripe_id===null || this.state.stripe_id===""){
    //   this.showToast(t("zqwNVFrmEl18_x"), "error");
    //   return false;
    // }

	const currentTicket = this.state.currentTicket;
	//const productIdone=parseInt(this.state.productId); 
	const productIdone=(this.state.productId); 
	if(productIdone===null || productIdone===""){  // productIdone===0 ||  
		//if(productIdone===0){
		console.log("//EEEEEEEEEEROR 333333333333, NADA MAL  00000 z=", this.state.idClient, this.state.stripe_id); 
		this.showToast(t("zqwNVFrmEl7_x"), "error");
		return false;
	}
	 
	if(currentTicket===""){
		console.log("//EEEEEEEEEEROR2 333333333333, NADA MAL  00000 z=", this.state.idClient, this.state.stripe_id); 
		this.showToast(t("zqwMniPEl_x"), "error");
		return false;
	}

	const clientProdTransInfo=this.props.clientProdTransInfo; 
	const client=clientProdTransInfo.client; 
	//const transactions=clientProdTransInfo.transactions;   
	console.log("woooooooooooooo cardonfile ", client);

	console.log("//SUCCESS 333333337777, NADA MAL  00000 z=", this.state.idClient, this.state.stripe_id); 
    //return false;

    //this.setState({ ["pMethod"]: 3, ["stripeToken"]: "CARD_ON_FILE" }); 	
    /*
	const productPriceTotal= this.state.grandTotal; //orderTotal
    const nowPayment= this.state.nowPayment;
    const paymentWay= this.state.paymentWay; 
    if(paymentWay!=="full"){
      this.paymentWayRef.current.focus();
      this.showToast(t("zqwNVFrmEl19_x"), "error");
      return false;
    }*/

	console.log("//SUCCESS bueno paso los filtros, NADA MAL  00000"); 

    //const result=stripobject;  
    const paytryNow= Date.now(); 
    const timesbetween=((paytryNow*1)-(this.paymentBtnTryTimes2*1)); 
    if(timesbetween <60000){
      //this.stripeTryTimes=paytryNow;
      const timerest=(60-(timesbetween/1000));
      console.log("ooooooh wont go ---   "+timesbetween+" Svp essayez encore dans "+timerest+" secondes"); 
      //this.showToast("Désolé, on ne processe pas plus qu'une fois en moins d'une minute. Svp essayez encore dans "+timerest+" secondes");
      this.showToast(t("zqwNVFrmEl16_x", {timerest:timerest}), "error");
      return false;
    }else{ this.paymentBtnTryTimes2=paytryNow;  }

    //console.log("//SUCCESS bueno paso los filtros, NADA MAL"); //return false;

    this.setState({ ["pMethod"]: 1, ["stripeToken"]: "CARD_ON_FILE" }); 	
    //this.handleGetCustomerInfoPayment2(event);
    /*setTimeout(() => { 
      if(this.actionMode==='partialInvoice') {
        this.handleMakePartialPayment(event);
      }else{ this.handleGetCustomerInfoPayment2(event); }
      //this.handleGetCustomerInfoPayment2(event);
    }, 100);*/ 

	console.log("//SUCCESS bueno paso los filtros, NADA MAL"); 

	//this.handleMakePaymentSC(event);
	setTimeout(() => { 
		this.handleMakePaymentSC(event);
	  }, 100);
	
	 


  };

  
  handleMakePaymentSC= (event) => {                                                             
    //event.preventDefault();
    const { t } = this.props;
    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    //const created = this.state.created;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; //this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation = this.state.occupation;
    const neoClients = {idClient: idClient, firstname: firstname, lastname: lastname, cell: cell, terms: terms, active: active, email: email, id_users: id_users, language: language, push: push, id_zendesk: id_zendesk, sms: sms, stripe_id: stripe_id, occupation:occupation}
  
    
    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address0 = this.state.address;
    const unitsuite = this.state.unitsuite;
    const city = this.state.city;
    const province = this.state.province;
    const cp = this.state.cp;
    const country = this.state.country;

    //const created = this.state.created; 
    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    //const type = this.state.type;
    let address = address0;
    if(unitsuite!==""){ address = unitsuite +"-"+ address; }
    const neoAddress = {idAddress: idAddress, id_clients: id_clients, address: address, city: city, province: province, cp: cp, country: country, active: active2, tel: tel, ext: ext, company: company, name: nameNx2,}
    

    //productId:0, productTitre:'', product:{}, productPrice:'', productPriceTax:'', productPriceTaxOnly:0, productExtraOptions:[], productExtraOptionCtrl:null, showProductOption:false, 

    const productId=this.state.productCart.length; //const productId=this.state.productId;
    const productPriceTotal= this.state.grandTotal; 
    
    const id = this.state.id;
    const name = this.state.name;
    const description = this.state.description;
    const amount = this.state.amount;
    //const NeoVentes = {id: id, name: name, description: description, amount: amount, };
    const neoVentes = [];

    const taxInfo=this.state.taxInfo;
    const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 

    const downPayment=this.state.nowPayment;

    //const productCart=this.state.productCart;
    //const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [] }
    // TAX1=0.9975;  TAX2=0.05;  TAX_TOTAL=0.15;
    //let subTotal=0;  let totTagTotOptions=0;
    
    const subNeoVentes = {isSub: true, id: 0, tax1: 0, tax2: 0, sub_total: 0, 
      tax_total: 0, total: downPayment, amount: downPayment, options: [], 
      tarifBase: downPayment, createTicket: true, pages:0, perPage:0,
      productPrice:0, productPrice2:downPayment };
    neoVentes.push(subNeoVentes);

    const tarifAmountx=downPayment; //subTotal+totTagTotOptions;
    const tax1x= 0;
    const tax2x= 0;
    const tax_totalx= 0;
    const totalForItemx= (((tarifAmountx*1)+(tax_totalx*1))).toFixed(2); //const totalForItem= ((tarifAmountx+tax_total)).toFixed(2);
    const mainNeoVentesx = {isSub: false,  tax1: tax1x, tax2: tax2x, sub_total: tarifAmountx, tax_total: tax_totalx, total: totalForItemx };
    neoVentes.push(mainNeoVentesx);

    //let neoPromocode= {id: 0, percentage: 0, name: name, amount: 0, tax1: 0, tax2: 0 };
    //cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
    //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0, 
    /*const cuponcodeId=this.state.cuponcodeId;
    const cuponcodeName=this.state.cuponcodeName;
    const promoAmount=this.state.promoAmount;
    const promoPercentage=this.state.promoPercentage;
    const promoTax1=this.state.promoTax1;
    const promoTax2=this.state.promoTax2;*/
 
    const stripeToken=this.state.stripeToken;
    let pMethod=1;
    
    const dossier=this.state.dossier;
    pMethod=this.state.pMethod;  
     
    //const downPayment=this.state.nowPayment;
    const paymentRound=this.state.paymentRound;
    const isRecurrent=this.state.isRecurrentPayment;
    const qtyPayment=this.state.qtyPayment;
    const laterpayment=this.state.laterpayment; 
    const fromDatePayment=this.state.fromDatePayment;
    const paymentWay=this.state.paymentWay; //full, partial, recurrent
    const neoPaymentsx= {id: id,  
    downPayment:downPayment, paymentRound: paymentRound, paymentWay: paymentWay,
    isRecurrent: isRecurrent, qtyPayment: qtyPayment, recPaymentDay: laterpayment,
    recPaymentDate: fromDatePayment, };

    const id_invoice=this.state.id_invoice;

	//********************************************************************************
	// const idClient = this.state.idClient;
    // const firstname = this.state.firstname;
    // const lastname = this.state.lastname;
    // const cell = this.state.cell;

	const currentTicket = this.state.currentTicket;
	const isNewTicket = this.state.newTicket;
	//const productIdone=parseInt(this.state.productId); 
	const productIdone=(this.state.productId); 
	if(productIdone===null || productIdone===""){  // productIdone===0 ||  
		this.showToast(t("zqwNVFrmEl7_x"), "error");
		return false;
	}
	 
	if(currentTicket===""){
		this.showToast(t("zqwMniPEl_x"), "error");
		return false;
	}

	//this.showToast("Teeeeeeeeest, just that p="+productIdone, "warning"); return false;  

	const clientProdTransInfo=this.props.clientProdTransInfo; 
	const client=clientProdTransInfo.client; 
	//const transactions=clientProdTransInfo.transactions; 
	if(client===undefined || client.id===undefined || client.id===null || client.id=="0"){
		//neoClients = {idClient  
		this.showToast(t("zqwMniPEl_x")+" client ID ", "error");
		return false;
	} 
	console.log("woooooooooooooo id, productIdone, currentTicket, isNewTicket ", client.id, productIdone, currentTicket, isNewTicket); 
	console.log("woooooooooooooo cardonfile ", client);
	console.log("//OOOOOOOOOOOOOOOOO 333333337777, NADA MAL  00000 z=", this.state.idClient, this.state.stripe_id); 
    //return false;

	// if(productIdOne <1){
	// 	//errorFound++;
	// 	window.alert(`${t("zqwNVFrmEl7_x")} `); return false; 
	// }

	//const productPriceTotal= this.state.grandTotal; 
	// if(productPriceTotal==0){
	// 	this.showToast(t("zqwNVFrmEl8_x"), "error");
	// 	return false;
	// }

  const neoVentes2 = [];

	const productCart=this.state.productCart;
    //const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [] }
    // TAX1=0.9975;  TAX2=0.05;  TAX_TOTAL=0.15;  // 98989 isdelete 1
    let subTotal=0;  let totTagTotOptions=0; let totProd=0;
    productCart.length > 0
    	&& productCart.map((item, i) => { 
        const isDelete=item.isdelete;
        const myItemtxt=""+item.id; 
        if(myItemtxt.indexOf("98989") <0 && isDelete===undefined){ 
          totProd++;
        const productPrice= item.productPrice2===0?item.productPrice:item.productPrice2;
        const tarifBase=(parseFloat(productPrice)*1); //(parseFloat(item.productPrice)*1);
        const tarifAmount=(tarifBase+parseFloat(item.totOptions));
        subTotal +=parseFloat(productPrice);  //parseFloat(item.productPrice);
        totTagTotOptions +=parseFloat(item.totOptions);
        const tax1= (((tarifAmount*1)*TAX1)).toFixed(2);
        const tax2= (((tarifAmount*1)*TAX2)).toFixed(2);
        const tax_total= (tarifAmount*TAX_TOTAL).toFixed(2);
        const totalForItem= (((tarifAmount*1)+(tax_total*1))).toFixed(2);
        const options=item.options;
        const cticket=item.cticket;
        const pages=item.pages;  const perPage=item.perPage;
        const subNeoVentes = {isSub: true, id: item.id, tax1: tax1, tax2: tax2, sub_total: tarifAmount, 
          tax_total: tax_total, total: totalForItem, amount: tarifAmount, options: options, 
          tarifBase: tarifBase, createTicket: cticket, pages:pages, perPage:perPage,
          productPrice:item.productPrice, productPrice2:item.productPrice2 };
        neoVentes2.push(subNeoVentes);   
        }

    }, this); 

    if(totProd === 0){ 
      this.showToast(t("zqwNVFrmEl9_x"), "error");
      return false;
    }


    /////////////////////////////////////////////////////////////////////////////
     
    const tarifAmount=subTotal+totTagTotOptions;
    const tax1= (((tarifAmount*1)*TAX1)).toFixed(2);
    const tax2= (((tarifAmount*1)*TAX2)).toFixed(2);
    const tax_total= (tarifAmount*TAX_TOTAL).toFixed(2);
    const totalForItem= (((tarifAmount*1)+(tax_total*1))).toFixed(2); //const totalForItem= ((tarifAmount+tax_total)).toFixed(2);
    const mainNeoVentes = {isSub: false,  tax1: tax1, tax2: tax2, sub_total: tarifAmount, tax_total: tax_total, total: totalForItem };
    neoVentes2.push(mainNeoVentes);

    let neoPromocode= {id: 0, percentage: 0, name: name, amount: 0, tax1: 0, tax2: 0 };
    //cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
    //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0, 
    const cuponcodeId=this.state.cuponcodeId;
    const cuponcodeName=this.state.cuponcodeName;
    const promoAmount=this.state.promoAmount;
    const promoPercentage=this.state.promoPercentage;
    const promoTax1=this.state.promoTax1;
    const promoTax2=this.state.promoTax2;
    if(cuponcodeId  > 0){ 
      neoPromocode= {id: cuponcodeId, percentage: promoPercentage, name: cuponcodeName, amount: promoAmount, tax1: promoTax1, tax2: promoTax2 };
    }

    //const stripeToken=this.state.stripeToken;
    //let pMethod=1;
    // if(stripeToken  !==''){ 
    //   pMethod=1; 
    // }b

    //const dossier=this.state.dossier;
    //pMethod=this.state.pMethod;  
    
    /*const downPayment=this.state.nowPayment;
    const paymentRound=this.state.paymentRound;
    const isRecurrent=this.state.isRecurrentPayment;
    const qtyPayment=this.state.qtyPayment;
    const laterpayment=this.state.laterpayment; 
    const fromDatePayment=this.state.fromDatePayment;
    const paymentWay=this.state.paymentWay; //full, partial, recurrent
    */

    const neoPayments= {id: id,  
    downPayment:downPayment, paymentRound: paymentRound, paymentWay: paymentWay,
    isRecurrent: isRecurrent, qtyPayment: qtyPayment, recPaymentDay: laterpayment,
    recPaymentDate: fromDatePayment, };

    //const id_invoice=this.state.id_invoice;
    
   
  
    //isupsale:false, upsaleTicket:"", isAmerindian:false, no_amerindien:"", 
    const isupsale=this.state.isupsale;
    const upsaleTicket=this.state.upsaleTicket;
    const isAmerindian=this.state.isAmerindian;
    const no_amerindien=this.state.no_amerindien;
    if(isupsale===true && (upsaleTicket===null || upsaleTicket==="")){
      this.showToast(t("zqwNVFrmEl11_x"), "error");
      return false;
    }
    if(isAmerindian===true && (no_amerindien===null || no_amerindien==="")){
      this.showToast(t("zqwNVFrmEl12_x"), "error");
      return false;
    }
    const neoVentesBlocksOldschool={"client":neoClients, "address":neoAddress, "sales":neoVentes, 
    "promocode":neoPromocode, "payment":neoPayments, "dossier":dossier, "pMethod":pMethod, 
    "stripeToken":stripeToken, "id_invoice": id_invoice,
    "isupsale":isupsale, "upsaleTicket":upsaleTicket, "isAmerindian":isAmerindian, "no_amerindien":no_amerindien }; 
    //const this00=this;
    //let rawError={};

    ////////////////////////////////////////////////////////////////////////////
    
  

	let errorFound=0;
  const neoVentesBlocks00={"client":neoClients, "address":neoAddress, "sales":neoVentes, 
  "promocode":neoPromocode, "payment":neoPayments, "dossier":dossier, "pMethod":pMethod, 
  "stripeToken":stripeToken, "id_invoice": id_invoice };

  const neoVentesBlocksV1={"clientId":client.id, "productIdone":productIdone, "isNewTicket":isNewTicket, 
  "currentTicket":currentTicket, "dossier":dossier, "pMethod":pMethod, 
  "stripeToken":stripeToken, "id_invoice": id_invoice };

  const neoVentesBlocks={"clientId":client.id, "productIdone":productIdone, "isNewTicket":isNewTicket, 
  "currentTicket":currentTicket,
    "client":neoClients, "address":neoAddress, "sales":neoVentes2, 
    "promocode":neoPromocode, "payment":neoPayments, "dossier":dossier, "pMethod":pMethod, 
    "stripeToken":stripeToken, "id_invoice": id_invoice,
    "isupsale":isupsale, "upsaleTicket":upsaleTicket, "isAmerindian":isAmerindian, "no_amerindien":no_amerindien }; 


  //   if(this.actionMode==='partialInvoice'){
  //     neoVentesBlocks["partialInvoice"]=100;
  //   }
  //   neoVentesBlocks["partialInvoice"]=100;
  
  console.log("PRE-PAY", neoVentesBlocks); 
  //this.showToast("teeeeeeeeeeeeeeest DEV till here", "success");  return false;


  let rawError={};  
  const this00=this;
  
  this.setState({ ["isLoaderOpen"]: true}); 
    //paymentsReceived: [0], totalPaymentReceived: 0,
    const totalPaymentReceived=this.state.totalPaymentReceived;
    let paymentsReceived=this.state.paymentsReceived;

	//this.showToast("000Teeeeeeeeest, just that p="+productIdone, "warning"); return false;  
    //Promise.resolve(this.props.addOrUpdateNeoVentes(neoVentesBlocks, 1))
	Promise.resolve(this.props.addNeoVentesSC(neoVentesBlocks, 1))
    .then((response)=>{ 
      //const resp=response.NeoVentes.transaction;
	  const resp=response.NeoVentes;
      rawError=response;
      console.log('RESPONSE PAAAAAAAAAAAAAYMENT OJOJO ');  
      console.log(resp); //paymentRound errorTransacMessage 
	  //transaction.
      if(resp.closeId===1 && resp.transaction.paymentAmount> 0 && resp.transaction.id_invoice> 0){ 
        // const txtv=((totalPaymentReceived*1)+(downPayment*1));
        // paymentsReceived.push(downPayment);
        // const pround=paymentRound+1;
        // this.setState({ ["idClient"]: resp.id_clients, ["id_clients"]: resp.id_clients,
        // ["id_users"]: resp.id_users, ["idAddress"]: resp.id_address, 
        // ["id_invoice"]: resp.id_invoice, ["totalPaymentReceived"]: txtv,
        // ["paymentsReceived"]: paymentsReceived, ["paymentRound"]: pround, ["paymentWayRO"]: true,}); 
        
		    this.setState({ ["paymentRound"]: 1, ["errorTransacMessage"]:"", });  
        //this.calculateGrandTotal(null);
        if(pMethod!==1){
          this.setState({ ["errorTransacMessage"]:"Attention c'est un paiement en attente qui sera finalisé quand le client fait le viremennt de fonds par service client.", });  
        }

        this.showToast(t("zqwNVFrmEl13_x"), "success");  

      }else{
		this.setState({ ["paymentRound"]: 0, ["errorTransacMessage"]:"Échec de paiement/Payment failed", });  
	  }
      //this.setState({ ["listOfSuggestion"]: response.NeoClientsOne });
      this.setState({ ["isLoaderOpen"]: false});  
      return response;
    }).catch(function(error){ 
      this00.setState({ ["isLoaderOpen"]: false}); 
      const errObj=error;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n "+t("zqwNVFrmEl14_x");
      }
      this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
       
      console.log("ERROR WITH TRANSACTION"); 
      console.log(error);
      console.log("SHORT ERROR MESSAGE: "+errMessage);
      console.log("RWA RESPONSE"); 
      console.log(rawError);

      //alert(error);
    });


  };

  handleMakePaymentSCOld= (event) => {                                                             
    //event.preventDefault();
    const { t } = this.props;
    const idClient = this.state.idClient;
    const firstname = this.state.firstname;
    const lastname = this.state.lastname;
    const cell = this.state.cell;
    const terms = 0; //this.state.terms;
    const active = 1; //this.state.active;
    //const created = this.state.created;
    const email = this.state.email;
    const id_users = this.state.id_users;
    const language = this.state.language;
    const push = 0; //this.state.push;
    const id_zendesk = this.state.id_zendesk;
    const sms = this.state.sms;
    const stripe_id = this.state.stripe_id;
    const occupation = this.state.occupation;
    const neoClients = {idClient: idClient, firstname: firstname, lastname: lastname, cell: cell, terms: terms, active: active, email: email, id_users: id_users, language: language, push: push, id_zendesk: id_zendesk, sms: sms, stripe_id: stripe_id, occupation:occupation}
  
    
    const idAddress = this.state.idAddress;
    const id_clients = this.state.id_clients;
    const address0 = this.state.address;
    const unitsuite = this.state.unitsuite;
    const city = this.state.city;
    const province = this.state.province;
    const cp = this.state.cp;
    const country = this.state.country;

    //const created = this.state.created; 
    const active2 = 1; //this.state.active;
    const tel = ""; //this.state.tel; //this.state.cell;
    const ext = ""; //this.state.ext;
    const company = this.state.company;
    const nameNx2 = ""; //this.state.name;
    //const type = this.state.type;
    let address = address0;
    if(unitsuite!==""){ address = unitsuite +"-"+ address; }
    const neoAddress = {idAddress: idAddress, id_clients: id_clients, address: address, city: city, province: province, cp: cp, country: country, active: active2, tel: tel, ext: ext, company: company, name: nameNx2,}
    

    //productId:0, productTitre:'', product:{}, productPrice:'', productPriceTax:'', productPriceTaxOnly:0, productExtraOptions:[], productExtraOptionCtrl:null, showProductOption:false, 

    const productId=this.state.productCart.length; //const productId=this.state.productId;
    const productPriceTotal= this.state.grandTotal; 
    
    const id = this.state.id;
    const name = this.state.name;
    const description = this.state.description;
    const amount = this.state.amount;
    //const NeoVentes = {id: id, name: name, description: description, amount: amount, };
    const neoVentes = [];

    const downPayment=this.state.nowPayment;

    const productCart=this.state.productCart;
    //const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [] }
    // TAX1=0.9975;  TAX2=0.05;  TAX_TOTAL=0.15;
    //let subTotal=0;  let totTagTotOptions=0;
    
    const subNeoVentes = {isSub: true, id: 0, tax1: 0, tax2: 0, sub_total: 0, 
      tax_total: 0, total: downPayment, amount: downPayment, options: [], 
      tarifBase: downPayment, createTicket: true, pages:0, perPage:0,
      productPrice:0, productPrice2:downPayment };
    neoVentes.push(subNeoVentes);

    const tarifAmount=downPayment; //subTotal+totTagTotOptions;
    const tax1= 0;
    const tax2= 0;
    const tax_total= 0;
    const totalForItem= (((tarifAmount*1)+(tax_total*1))).toFixed(2); //const totalForItem= ((tarifAmount+tax_total)).toFixed(2);
    const mainNeoVentes = {isSub: false,  tax1: tax1, tax2: tax2, sub_total: tarifAmount, tax_total: tax_total, total: totalForItem };
    neoVentes.push(mainNeoVentes);

    let neoPromocode= {id: 0, percentage: 0, name: name, amount: 0, tax1: 0, tax2: 0 };
    //cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
    //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0, 
    const cuponcodeId=this.state.cuponcodeId;
    const cuponcodeName=this.state.cuponcodeName;
    const promoAmount=this.state.promoAmount;
    const promoPercentage=this.state.promoPercentage;
    const promoTax1=this.state.promoTax1;
    const promoTax2=this.state.promoTax2;
 
    const stripeToken=this.state.stripeToken;
    let pMethod=1;
    
    const dossier=this.state.dossier;
    pMethod=this.state.pMethod;  
     
    //const downPayment=this.state.nowPayment;
    const paymentRound=this.state.paymentRound;
    const isRecurrent=this.state.isRecurrentPayment;
    const qtyPayment=this.state.qtyPayment;
    const laterpayment=this.state.laterpayment; 
    const fromDatePayment=this.state.fromDatePayment;
    const paymentWay=this.state.paymentWay; //full, partial, recurrent
    const neoPayments= {id: id,  
    downPayment:downPayment, paymentRound: paymentRound, paymentWay: paymentWay,
    isRecurrent: isRecurrent, qtyPayment: qtyPayment, recPaymentDay: laterpayment,
    recPaymentDate: fromDatePayment, };

    const id_invoice=this.state.id_invoice;

	//********************************************************************************
	// const idClient = this.state.idClient;
    // const firstname = this.state.firstname;
    // const lastname = this.state.lastname;
    // const cell = this.state.cell;

	const currentTicket = this.state.currentTicket;
	const isNewTicket = this.state.newTicket;
	//const productIdone=parseInt(this.state.productId); 
	const productIdone=(this.state.productId); 
	if(productIdone===null || productIdone===""){  // productIdone===0 ||  
		this.showToast(t("zqwNVFrmEl7_x"), "error");
		return false;
	}
	 
	if(currentTicket===""){
		this.showToast(t("zqwMniPEl_x"), "error");
		return false;
	}

	//this.showToast("Teeeeeeeeest, just that p="+productIdone, "warning"); return false;  

	const clientProdTransInfo=this.props.clientProdTransInfo; 
	const client=clientProdTransInfo.client; 
	//const transactions=clientProdTransInfo.transactions; 
	if(client===undefined || client.id===undefined || client.id===null || client.id=="0"){
		//neoClients = {idClient  
		this.showToast(t("zqwMniPEl_x")+" client ID ", "error");
		return false;
	} 
	console.log("woooooooooooooo id, productIdone, currentTicket, isNewTicket ", client.id, productIdone, currentTicket, isNewTicket); 
	console.log("woooooooooooooo cardonfile ", client);
	console.log("//OOOOOOOOOOOOOOOOO 333333337777, NADA MAL  00000 z=", this.state.idClient, this.state.stripe_id); 
    //return false;

	// if(productIdOne <1){
	// 	//errorFound++;
	// 	window.alert(`${t("zqwNVFrmEl7_x")} `); return false; 
	// }

	//const productPriceTotal= this.state.grandTotal; 
	// if(productPriceTotal==0){
	// 	this.showToast(t("zqwNVFrmEl8_x"), "error");
	// 	return false;
	// }

	//const productCart=this.state.productCart;
    //const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [] }
    // TAX1=0.9975;  TAX2=0.05;  TAX_TOTAL=0.15;  // 98989 isdelete 1
    let subTotal=0;  let totTagTotOptions=0; let totProd=0;
    /*productCart.length > 0
    	&& productCart.map((item, i) => { 
        const isDelete=item.isdelete;
        const myItemtxt=""+item.id; 
        if(myItemtxt.indexOf("98989") <0 && isDelete===undefined){ 
          totProd++;
        const productPrice= item.productPrice2===0?item.productPrice:item.productPrice2;
        const tarifBase=(parseFloat(productPrice)*1); //(parseFloat(item.productPrice)*1);
        const tarifAmount=(tarifBase+parseFloat(item.totOptions));
        subTotal +=parseFloat(productPrice);  //parseFloat(item.productPrice);
        totTagTotOptions +=parseFloat(item.totOptions);
        const tax1= (((tarifAmount*1)*TAX1)).toFixed(2);
        const tax2= (((tarifAmount*1)*TAX2)).toFixed(2);
        const tax_total= (tarifAmount*TAX_TOTAL).toFixed(2);
        const totalForItem= (((tarifAmount*1)+(tax_total*1))).toFixed(2);
        const options=item.options;
        const cticket=item.cticket;
        const pages=item.pages;  const perPage=item.perPage;
        const subNeoVentes = {isSub: true, id: item.id, tax1: tax1, tax2: tax2, sub_total: tarifAmount, 
          tax_total: tax_total, total: totalForItem, amount: tarifAmount, options: options, 
          tarifBase: tarifBase, createTicket: cticket, pages:pages, perPage:perPage,
          productPrice:item.productPrice, productPrice2:item.productPrice2 };
        neoVentes.push(subNeoVentes);   
        }

    }, this); */

    // if(totProd === 0){ 
    //   this.showToast(t("zqwNVFrmEl9_x"), "error");
    //   return false;
    // }
    
  

	let errorFound=0;
  const neoVentesBlocks00={"client":neoClients, "address":neoAddress, "sales":neoVentes, 
  "promocode":neoPromocode, "payment":neoPayments, "dossier":dossier, "pMethod":pMethod, 
  "stripeToken":stripeToken, "id_invoice": id_invoice };

  const neoVentesBlocks={"clientId":client.id, "productIdone":productIdone, "isNewTicket":isNewTicket, 
  "currentTicket":currentTicket, "dossier":dossier, "pMethod":pMethod, 
  "stripeToken":stripeToken, "id_invoice": id_invoice };


  //   if(this.actionMode==='partialInvoice'){
  //     neoVentesBlocks["partialInvoice"]=100;
  //   }
  //   neoVentesBlocks["partialInvoice"]=100;
  
  console.log("PRE-PAY", neoVentesBlocks); 
  this.showToast("teeeeeeeeeeeeeeest DEV till here", "success");  return false;


  let rawError={};  
  const this00=this;
  
  this.setState({ ["isLoaderOpen"]: true}); 
    //paymentsReceived: [0], totalPaymentReceived: 0,
    const totalPaymentReceived=this.state.totalPaymentReceived;
    let paymentsReceived=this.state.paymentsReceived;

	//this.showToast("000Teeeeeeeeest, just that p="+productIdone, "warning"); return false;  
    //Promise.resolve(this.props.addOrUpdateNeoVentes(neoVentesBlocks, 1))
	Promise.resolve(this.props.addNeoVentesSC(neoVentesBlocks, 1))
    .then((response)=>{ 
      //const resp=response.NeoVentes.transaction;
	  const resp=response.NeoVentes;
      rawError=response;
      console.log('RESPONSE PAAAAAAAAAAAAAYMENT OJOJO ');  
      console.log(resp); //paymentRound errorTransacMessage 
	  //transaction.
      if(resp.closeId===1 && resp.transaction.paymentAmount> 0 && resp.transaction.id_invoice> 0){ 
        // const txtv=((totalPaymentReceived*1)+(downPayment*1));
        // paymentsReceived.push(downPayment);
        // const pround=paymentRound+1;
        // this.setState({ ["idClient"]: resp.id_clients, ["id_clients"]: resp.id_clients,
        // ["id_users"]: resp.id_users, ["idAddress"]: resp.id_address, 
        // ["id_invoice"]: resp.id_invoice, ["totalPaymentReceived"]: txtv,
        // ["paymentsReceived"]: paymentsReceived, ["paymentRound"]: pround, ["paymentWayRO"]: true,}); 
        
		    this.setState({ ["paymentRound"]: 1, ["errorTransacMessage"]:"", });  
        //this.calculateGrandTotal(null);
        if(pMethod!==1){
          this.setState({ ["errorTransacMessage"]:"Attention c'est un paiement en attente qui sera finaliser quand le client fait le viremennt de fonds.", });  
        }

        this.showToast(t("zqwNVFrmEl13_x"), "success");  

      }else{
		this.setState({ ["paymentRound"]: 0, ["errorTransacMessage"]:"Échec de paiement/Payment failed", });  
	  }
      //this.setState({ ["listOfSuggestion"]: response.NeoClientsOne });
      this.setState({ ["isLoaderOpen"]: false});  
      return response;
    }).catch(function(error){ 
      this00.setState({ ["isLoaderOpen"]: false}); 
      const errObj=error;
      //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
      let errMessage=(errObj.data!=="")? "Erreur "+" \n"+errObj.data:"Erreur "+" \n"+errObj.statusText;
      if(errObj.status===undefined){
        errMessage +="\n "+t("zqwNVFrmEl14_x");
      }
      this00.setState({ ["isLoaderOpen"]: false, ["errorTransacMessage"]: errMessage});
       
      console.log("ERROR WITH TRANSACTION"); 
      console.log(error);
      console.log("SHORT ERROR MESSAGE: "+errMessage);
      console.log("RWA RESPONSE"); 
      console.log(rawError);

      //alert(error);
    });


  };

  handleProductChange = (event) => { 
    const { t } = this.props;                                                            
    //event.preventDefault();  
    //const stepTrack=event.target.dataset.prodcontrol;
    const selVal= event.value;  //productId
    this.setState({ ["productId"]: selVal }); 
    //if(stepTrack==1){ this.setState({ ["globalStepWorkflow"]: 2 });  }
    this.setState({ ["globalStepWorkflow"]: 2, ["workflowType"]:2 }); 
    this.workflowProductList=true;
    const product=this.getProductChange(selVal);
    this.applyProductChange(selVal);    	
    //this.showToast(t("zqwNVFrmEl28_x")+" #"+product.titre, "success");	
    ////console.log('well hellooooooooooooo '+selVal);
    ////console.log(event);

  };

  handleProductChange2 = (event) => { 
    const { t } = this.props;                                                            
    //event.preventDefault();  
    //const stepTrack=event.target.dataset.prodcontrol;
    const selVal= event.value;  //productId
    this.setState({ ["productId"]: selVal }); 
    //if(stepTrack==1){ this.setState({ ["globalStepWorkflow"]: 2 });  }
    const product=this.getProductChange(selVal);
    this.applyProductChange(selVal);
    this.showToast(t("zqwNVFrmEl28_x")+" #"+product.titre, "success"); 
  };

  getProductChange =(inputValue) => {   
    let productItem = {};
    const products=this.props.productsList.products;
    //this.props.productsList.products.length 
    // VS   this.productsList.products.length
    /*this.productsList.products.length > 0
    	&& this.productsList.products.map((item, i) => { 
        if(item.type.indexOf('hidden') <0 && item.titre!=='' && item.id==inputValue){
          //productOptions.push({ value: item.id, label: item.titre }); 
          productItem=item;
          return item;
        } 
      });*/

    products.length > 0
    	&& products.map((item, i) => { 
        if(item.type.indexOf('hidden') <0 && item.titre!=='' && item.id==inputValue){
          //productOptions.push({ value: item.id, label: item.titre }); 
          productItem=item;
          return item;
        } 
      });  

      console.log("TOT-PROD", products.length);

      return productItem; //return false;
  }


	  
	setMultiactions = (eventargs) => {
		const closeme=eventargs.closeme;
		// let isChecked=false; 
		// if(event.target.checked){ isChecked=true; }
		// this.setState({ [event.target.name]: isChecked });
		if(closeme!==null && closeme!==undefined && closeme==="close"){
			const obj={};
		} 
	
	};

	
	closeFolderProductPopup  = (anyEvent) => {     
		this.setState({["openProductFolder"]: false }); 
		//this.showToast("Premiere etape, veuillez patieter un instant", "success"); 
		const obj={closeme:'close'};
		if(this.state.paymentRound >0){
			obj.refreshafterPayment=1;
		} 
		// const taxInfo=this.state.taxInfo; 
		// const clientAddress=this.state.clientAddress;
		// const province=this.state.province;

		// const taxInfo=JSON.parse(JSON.stringify(this.state.taxInfo));
		// const clientAddress=JSON.parse(JSON.stringify(this.state.clientAddress));
		// const province=JSON.parse(JSON.stringify(this.state.province));
		// const currentTicket=JSON.parse(JSON.stringify(this.state.currentTicket));

		// this.setState(JSON.parse(JSON.stringify(this.stateInit)));
		// this.setState({ ["clientAddress"]: clientAddress, 
		// 	["province"]: province, ["taxInfo"]: taxInfo, ["currentTicket"]: currentTicket});  

		this.props.multiactions(obj);
	}

	handleTransactionChange = (event) => {                                                             
		//event.preventDefault();  
		//const stepTrack=event.target.dataset.prodcontrol;
		const selVal= event.value;  //productId
		this.setState({ ["transactionId"]: selVal });    
	};

	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info	  
		//console.log("showToast showToast "); //console.log(arg);
		//alert("hello it is an eventDrop here "+arg.date);
		//Toastr("Hello I am a toast message right here, for testing purpose...");
		//toastrMessage: '', toastrType: '', toastrDuration: 6000, 
		this.setState({ // update a property
			toastrMessage: arg, 
		  toastrType: toastType
		  });
		setTimeout(() => { 
		  this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		  }); 
		}, 5500);
	}
	
	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.NeoProducts;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteNeoProducts(gridIds[0]);

	}

  updateXRecordsMain(selectedRows){  
    const { t } = this.props;
	const objList  = this.props.NeoProducts;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
      gridIndexes.push(row.index); 
      idIndex=row.index; 
	return null;
    });
 
    if(gridIndexes.length>1){
      alert(`{t('zqwgridIndexesfound_x')}`);
      return false;
    }
 
    let obj=objList[idIndex]; 
	this.props.getNeoProductsGoto(obj.id); 
	//history.push(`./neoproducts/${obj.id}/edit`); 
  }

  Alert  = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  

  render() { 
    //const { t } = this.props;
	 
	  
    // if(this.props.NeoProducts.length) {                                
    //   return (
    //     <div>
    //       <h4>{t('zqwNeoProducts_x')}</h4>		  
	// 	  <MUIDataTable
	// 		  title={""}
	// 		  data={this.props.NeoProducts}
	// 		  columns={columns}
	// 		  options={options}
	// 		/>
    //     </div>
    //   )    
    // } else {
    //   return (<div>{t('zqwgridNoRecordfound_x')}</div>)
    // }
	
	//const open = this.state.openProductFolder; //lg sm md  fullWidth={"sm"} maxWidth={"lg"} 
	const open = this.props.openx; 
    const { t } = this.props;
	//const { t, i18n } = useTranslation("common"); 
	let productOptions = [];

    let productList = this.props.productsList.products.length > 0
    	&& this.props.productsList.products.map((item, i) => {
        //productOptions.push({ value: item.id, label: item.titre }); 
        if(item.type.indexOf('hidden') <0 && item.titre!==''){
          productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
          //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' + ' '+item.id+' ' });
        }
      return (
        null
      );
	}, this);
	
	//const AutocompleteClient=this.AutocompleteClient;

	 
	let transactionOptions = [];

    /*let transList = this.state.listOfTransactions.length > 0
    	&& this.state.listOfTransactions.map((item, i) => {
		//productOptions.push({ value: item.id, label: item.titre }); 
		transactionOptions.push({ value: item.id, label: item.description+ ' (prod'+item.id_products+') #inv'+item.id_invoices });
        // if(item.type.indexOf('hidden') <0 && item.titre!==''){
		// 	transactionOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
        //   //productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' + ' '+item.id+' ' });
        // }
      return (
        null
      );
	}, this);*/

	// transactions={transactionOptions} transactionOption={transactionOption} 
	// 	productsList={this.state.productsList} 

	let id_products=""; let transactions_id=""; let transactionOption = {}; let productOption={};
	//const clientProdTransInfo=this.state.clientProdTransInfo; 
	const clientProdTransInfo=this.props.clientProdTransInfo; 
	const client=clientProdTransInfo.client; 
	const transactions=clientProdTransInfo.transactions; 
	if(transactions!==null && transactions!==undefined){
		if(transactions!==null && transactions.id_products!==undefined){
			id_products=transactions.id_products;
			productOption={value: transactions.id_products, label: transactions.description+ ' (prod#'+transactions.id_products+' inv#'+transactions.id_invoices+')'};
			transactionOptions.push({value: transactions.id_products, label: transactions.description+ ' (prod#'+transactions.id_products+' inv#'+transactions.id_invoices+')'});
			 
		}else if(transactions[0].id!==null && transactions[0].id!==undefined){
			id_products=transactions[0].id_products;
			productOption={value: transactions[0].id_products, label: transactions[0].description+ ' (prod#'+transactions[0].id_products+' inv#'+transactions[0].id_invoices+')'};
			transactionOptions.push({value: transactions[0].id_products, label: transactions[0].description+ ' (prod#'+transactions[0].id_products+' inv#'+transactions[0].id_invoices+')'});
		}
		if(transactions!==null && transactions.id!==undefined){
			transactions_id=transactions.id;
			transactionOptions.push({ value: transactions.id, label: transactions.description+ ' (prod#'+transactions.id_products+' inv#'+transactions.id_invoices+')' });
			transactionOption={ value: transactions.id, label: transactions.description+ ' (prod#'+transactions.id_products+' inv#'+transactions.id_invoices+')' };
		}else if(transactions[0].id!==null && transactions[0].id!==undefined){
			transactions_id=transactions[0].id;
			transactionOptions.push({ value: transactions[0].id, label: transactions[0].description+ ' (prod#'+transactions[0].id_products+' inv#'+transactions[0].id_invoices+')' });
			transactionOption={ value: transactions[0].id, label: transactions[0].description+ ' (prod#'+transactions[0].id_products+' inv#'+transactions[0].id_invoices+')' };
		}
	}


	/*let transList = this.state.listOfTransactions.length > 0
    	&& this.state.listOfTransactions.map((item, i) => {
		//productOptions.push({ value: item.id, label: item.titre }); 
		transactionOptions.push({ value: item.id, label: item.description+ ' (prod'+item.id_products+') #inv'+item.id_invoices });
        //transactionOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
      return (
        null
      );
	}, this);*/

	// const productPriceTotal= this.state.grandTotal; //orderTotal
    // const nowPayment= this.state.nowPayment;
    // const paymentWay= this.state.paymentWay; 
    // const paymentValid={  total:productPriceTotal, paymentWay:paymentWay, nowPayment:nowPayment,};
    // const paymentRound=this.state.paymentRound;

    //const Alert=this.Alert;
	//const clientProdTransInfo=
	let stripeId="";
	if(client!==undefined && client!==null){
		if(client.stripe_id!==undefined && client.stripe_id!==null){
			stripeId=client.stripe_id; 
		}
	}

	const globalStepWorkflow=this.state.globalStepWorkflow;
	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType;
	const Alert=this.Alert;





	//const { t } = this.props;
    const taxInfo=this.state.taxInfo; 
    let TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
    let TAX1lx=taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx=taxInfo["tax2"]; 
    let TAX12=0; let TAX21=0;
    if(TAX2_NAME ==="0"){             
      TAX1lx=parseFloat(TAX1l)+parseFloat(TAX2l);
      TAX1=TAX1+TAX2;     
      TAX12=TAX1+TAX2;  
      TAX2=0;   
    }  
    if(TAX1_NAME==="0"){                   
      TAX2lx=parseFloat(TAX1l)+parseFloat(TAX2l); 
      TAX2=TAX2+TAX1;  
      TAX21=TAX1+TAX2;  
      TAX1=0;                          
    } 
   
    //cuponcode:'', cuponcodeId:0, cuponcodeName:'', showPromoLine: false, promoAmount: 0, promoPercentage: 0, promoTax1: 0, promoTax2: 0, 
    //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0,

    const promoAmount='-'+this.state.promoAmount;
    const orderBruteSubTotal=this.state.orderBruteSubTotal;
    const orderNetSubTotal=this.state.orderNetSubTotal;
    const orderTVQ=this.state.orderTVQ;
    const orderTPS=this.state.orderTPS;
    const orderTotal=this.state.orderTotal;
    const promoPercentage=this.state.promoPercentage;

    //paymentsReceived: [0], totalPaymentReceived: 0,
    const totalPaymentReceived=this.state.totalPaymentReceived;
    const beforePaymentTotal=this.state.beforePaymentTotal;

    //const listOfTickets=props.listOfTickets; //this.state.listOfTickets;
    
    const productPriceTotal= this.state.grandTotal; //orderTotal
    const nowPayment= this.state.nowPayment;
    const paymentWay= this.state.paymentWay; 
    const paymentValid={  total:productPriceTotal, paymentWay:paymentWay, nowPayment:nowPayment,};
    const paymentRound=this.state.paymentRound;
    const ProductsOnInvoice=this.ProductsOnInvoice;

	 
 
   
    return (
      <div>
        <Dialog maxWidth={"lg"} open={open} onClose={this.handleCloseTransactionPopup} disableBackdropClick disableEscapeKeyDown aria-labelledby="form-dialog-productFolder">
          <DialogContent id="form-dialog-productFolder">
          <div className="text-center"> 
            <h5>{t("zqwPuptxt1_x")}</h5>  
			{/* AJOUTER DES PRODUITS AU TICKET */}
          </div>
   
		  <div className="form-group">
			  <label htmlFor="name">{t('zqwPuptxt2_x')}</label>
				{/* <Select options={productOptions} onChange={this.handleProductChange}  value={productOption}  />  */}
				<Select options={productOptions} onChange={this.handleProductChange}  /> 
		  </div>
		  <div className="form-group minP">
			  {/* <label htmlFor="name">{t('zqwNFFtxtNeo_x')}  */}
			  {/* </label> */}
			  <input type="checkbox" name="newTicket" id="newTicket" className="form-controlx" 
			  	 checked={this.state.newTicket}  
                      onClick={this.handleCheckBoxCheck} placeholder={"nouveau/new ticket"}   
					  onChange={this.handleCheckBoxCheck}
                       />  {t('zqwNFFtxtNeo_x')} 
			 {/* </label>    */}
				{/* <Select options={productOptions} onChange={this.handleProductChange}  />  */}
		  </div>

      <div className="row minP"> 
        <ProductsOnInvoice />
      </div>






		  <div className="row minP"> 
				<div className="col-md-3"></div>
				<div className="col-md-8">
				<div className="row">
					<div className="neoinline50">{t("zqwNVFrmEl57_x")}</div>
					<div className="neoinline50"> <span id="subtotal">{orderBruteSubTotal}</span>$</div>
				</div> 
				{/* {this.state.showPromoLine===true? 
				<div className="row">
					<div className="neoinline50">{t("zqwNVFrmEl57_x")}</div>
					<div className="neoinline50"> <span id="subtotal">{orderNetSubTotal}</span>$</div>
				</div>:''
				} */}

				{TAX1_NAME!=="0"?
					<div className="row" id="tax1_block" style={{display:"block"}}> 
						<div className="neoinline50 text-right"><span id="tax1_name">{TAX1_NAME!=="0"?TAX1_NAME:''}</span> (<span id="tax1_amount">{TAX1lx}</span>%)</div>
						<div className="neoinline50 text-right text-black"> <span id="tax1">{orderTVQ}</span>$</div> 
					</div>:''}

					{TAX2_NAME!=="0"?
					<div className="row" id="tax2_block" style={{display:"block"}}>
						<div className="neoinline50"><span id="tax2_name">{TAX2_NAME!=="0"?TAX2_NAME:''}</span> (<span id="tax2_amount">{TAX2lx}</span>%)</div>
						<div className="neoinline50"> <span id="tax2">{orderTPS}</span>$</div>
					</div>:''}

					<div className="row minP"><div className="neoinline100"><hr/></div></div>
					 
					<div className="row bold minP">
						<div className="neoinline50">{t("zqwNVFrmEl61_x")}</div>
						<div className="neoinline50"> <span id="total">{orderTotal}</span>$</div>
					</div>
				</div>
			</div>


		{/* <div className="form-group">
				<div className="md-12">
					<AutocompleteClient key={91000} propstep={1}  /> 
				</div>      
			</div> */}

		  {/* <div className="form-group">
			  <label htmlFor="name">Transactions</label>
				{/* <Select options={transactionOptions} onChange={this.handleTransactionChange}   value={transactionOption} />  /}
				<Select options={transactionOptions} onChange={this.handleTransactionChange} /> 
		  </div>  */}
		{paymentRound===0?
		  <div className="text-center"> 
				{/* <p> </p> 
				<p> </p>   */}
				<p> </p>  
				<StripePayment openx={this.state.stripePaymentOpen} valid={paymentValid} stripestatus={this.getStripStatus}  />
 


        <button name="interac" type="submit" value="Payer avec " onClick={this.payWithtInterac} 
        className="btn btn-lg btn-rounded btn-cons btn-complete text-white">
              {t("zqwNVFrmEl73_x")}  <img width="32" src="/static/assets/img/cards/4.png" alt="Interac" />
        </button> 
 
        {/* <a href="#" className="btn btn-rounded btn-warning" onClick={this.payWithtChargeDesk}>
        {t("zqwNVFrmEl74_x")} <img height="40" src="/static/assets/img/cards/5.png" alt="Charge Desk" />
        </a>  */}
         
        {(stripeId!=='')?
        <button type="button" name="pcardOnFile" className="btn btn-info" onClick={this.payWithCardOnFile}>{t("zqwNVFrmEl75_x")}</button>:''
           }
			</div>:'' 
		}	

		{paymentRound >0?
        <div className="form-group">  
        <Alert severity="success">Félicitations, paiement reçu avec succès!! Paiement #{paymentRound}</Alert>
        <br/> &nbsp; <br/></div>:''
        }

        {(this.state.errorTransacMessage!=="")?
          <div className="form-group">  
            <Alert severity="error">
              {this.state.errorTransacMessage}
            </Alert>
            <br/> &nbsp; <br/>
          </div>:'' 
        }
		
		     
            {/* <div className="text-center"> 
				<p> </p> 
				<p> </p>  
				<p> </p>  
			</div> */}

			{/* <div className="form-group">
				<br/>
				<input type="button" onClick={this.handleSaveForlderProduct} className="btn btn-primary float-right" value={t('zqwPuptxt3_x')} /> &nbsp; &nbsp; 
            </div>  */}

          <div className="form-row neoxtbl">
		  	{/* <div className="form-group">
			  <label htmlFor="name">{t('zqwname_x')} du Produit</label>
				<Select options={productOptions} onChange={this.handleProductChange}   /> 
			</div>
			<div className="form-group">
				<div className="md-12">
					<AutocompleteClient key={91000} propstep={1} /> 
				</div>      
			</div>      */}
		     
			<div className="form-group col-md-9"> 
            <br/>
            <input type="button" onClick={this.closeFolderProductPopup} className="btn btn-success" value={t('zqwTNVFtxt20_x')}  /> &nbsp; &nbsp; 
            </div> 
          </div>
              
          </DialogContent>
          
        </Dialog>
		<Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
		<Loader  openx={this.state.isLoaderOpen}  />
      </div>
    );
}





applyProductChange  = (inputValue) => { 
    const { t } = this.props;
    //event.preventDefault();  
    //const inputValue = newValue.replace(/\W/g, '');
    //const this00=this;
    const taxInfo=this.state.taxInfo;

    let TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    //const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
    let TAX1lx=taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx=taxInfo["tax2"]; 
    let TAX12=0; let TAX21=0;
    if(TAX2_NAME ==="0"){             
      TAX1lx=parseFloat(TAX1l)+parseFloat(TAX2l);
      TAX1=TAX1+TAX2;     
      TAX12=TAX1+TAX2;  
      TAX2=0;   
    }  
    if(TAX1_NAME==="0"){                   
      TAX2lx=parseFloat(TAX1l)+parseFloat(TAX2l); 
      TAX2=TAX2+TAX1;  
      TAX21=TAX1+TAX2;  
      TAX1=0;                          
    }
	
	console.log('MINIPayment aaaaaaaaaply on TOOOOOOOOOOOOOOOP ');

    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
     
    const arrProd=[6,8,9]; 

	const productsList=this.props.productsList;
	// let productList = this.props.productsList.products.length > 0
    // 	&& this.props.productsList.products.map((item, i) => {

		// this.productsList.products.length > 0
    	// && this.productsList.products.map((item, i) => { 



    productsList.products.length > 0
    	&& productsList.products.map((item, i) => { 
        if(item.type.indexOf('hidden') <0 && item.titre!=='' && item.id==inputValue){
          //productOptions.push({ value: item.id, label: item.titre });
          //productId:0, productTitre:'', product:{}, productPrice:'', productPriceTax:'',
          let tarif = (''+item.tarif).replace(/\W\$/g, ''); 
          tarif=tarif.replace('$', '');
          const productPriceTaxOnly= (((tarif*1)*(TAX_TOTAL))).toFixed(2);
          //const productPriceTotal= (((tarif*1)*0.15)+(tarif*1)).toFixed(2); //(((tarif*1)+(productPriceTaxOnly)));
          const productPriceTotal= ((((tarif*1)*TAX1)+((tarif*1)*TAX2))+(tarif*1)).toFixed(2); 
          const orderTVQ=((tarif*1)*TAX1).toFixed(2);
          const orderTPS=((tarif*1)*TAX2).toFixed(2); 
          const orderServices=0; 
          const orderSubtotals=((tarif*1)+orderServices).toFixed(2);
          
          this.setState({ ["productTitre"]: item.titre }); 	
          this.setState({ ["product"]: item }); 	
          this.setState({ ["productPrice"]: tarif }); 	
          this.setState({ ["productPriceTax"]: productPriceTotal });
          this.setState({ ["productPriceTaxOnly"]: productPriceTaxOnly });

          //const myCart={ "index": item, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "options": [] }
          //this.setState({ ["productCart"]:  [] }); 	



          const optionsEntries = (item.options!=undefined && !arrProd.includes(item.id)) ? Object.entries(item.options): [];
          const cartEntries = Object.entries(item.cart);

          const this00=this;

          let productCart=this.state.productCart;
          const indexProd=productCart.length;
		  //let productCart=[]; //this.state.productCart;
          //const indexProd=0; //productCart.length;

          const apartir= item.apartir; let apartirUsed=false;
          const optionsProd=item.options;

          const productExtraOptionCtrl=optionsEntries.map(function(elem, index) { 
            const [txtLabel, amount] =elem;
            //const this0=this;
            const groupVal=''+indexProd+'-'+item.id+'-'+amount;
            //console.log(`##arraymap There are ${elem} ---- ${index} +++++++++++++ ${txtLabel} -- ${amount} `);
            let titre= '';  
            for (const [txtLabel2, labelValue] of cartEntries) { 
              if(txtLabel===txtLabel2){ 
                titre= labelValue;
                //console.log(`##zazThere are ${txtLabel2} ${labelValue}  `);
                break;
              } 
            }
            if(titre===''){
              return (<span key={index} />);
            }else{
              return (<p key={index} ><input   type="checkbox" value={amount}  onChange={(eventx) => this00.setExtraServiceForProduct(eventx)}  /> {titre} (${amount})</p>); 
            }
          });


          const productExtraOptionCtrl2=optionsEntries.map(function(elem, index) { 
            const [txtLabel, amount] =elem;
            //const this0=this;
            //const groupVal=''+indexProd+'-'+item.id+'-'+amount;
            const groupVal=''+indexProd+'-'+item.id+'-'+txtLabel+'-'+amount;
            //console.log(`##arraymap There are ${elem} ---- ${index} +++++++++++++ ${txtLabel} -- ${amount} `);
            let titre= '';  
            for (const [txtLabel2, labelValue] of cartEntries) { 
              if(txtLabel===txtLabel2){ 
                titre= labelValue;
                //console.log(`##zazThere are ${txtLabel2} //////VS ${labelValue}  `);
                break;
              } 
            }
            if(titre==='' && apartir===false){
              return (<span key={index} />);
            }if(apartir===true){
              //apartirUsed=true;
              if(apartirUsed===false){
                apartirUsed=true;
                return (<p key={index}>{t("zqwNVFrmEl81_x")}:<br/>
                  <input type="number" min="1" step="1" defaultValue={1} 
                data-value={groupVal} data-perpage={optionsProd.perPage} data-maximum={optionsProd.maximum} 
                onChange={(eventx) => this.setPageDocForProduct(eventx)}  /> {t("zqwNVFrmEl82_x")} (n x ${amount})</p>);
              }else{ return (<span key={index} />); }
            }else{
              //console.log(`##INDEEEEEEEEEEEEEEEEEX SET IS ${groupVal}  `);
              return (<p key={index} ><input   type="checkbox" value={groupVal}  onChange={(eventx) => this00.setExtraServiceForProduct(eventx)}  /> {titre} (${amount})</p>);  
            }
          });

		  /*
          //this.prodTaxOnly=[]; this.prodPriceAndTax=[]; this.prodPriceAndTax2=[];  
          this.prodPrice[indexProd]=React.createRef();
          this.prodPriceTVQ[indexProd]=React.createRef();
          this.prodPriceTPS[indexProd]=React.createRef(); 
          this.prodTaxOnly[indexProd]=React.createRef();
          this.prodPriceAndTax[indexProd]=React.createRef();
          this.prodPriceAndTax2[indexProd]=React.createRef();
          this.prodPriceServices[indexProd]=React.createRef();
          this.prodPriceSubtotals[indexProd]=React.createRef();*/
           
          const rId=(Math.floor(Math.random()*16));
 
           


          this.setState({ ["productExtraOptionCtrl"]: productExtraOptionCtrl });
          //if(optionsEntries.length > 0){ this.setState({ ["showProductOption"]: true }); } 
          this.setState({ ["showProductOption"]: true });



          let productExtraOptionCtrl2x=this.state.productExtraOptionCtrl2;
          //productExtraOptionCtrl2x.push(divBlock);
          this.setState({ ["productExtraOptionCtrl2"]: productExtraOptionCtrl2x });

          //const myCart={ "index": item, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "options": [] }
          //let productCart=this.state.productCart;
          //const indexProd=productCart.length;
          const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, 
          "productPrice": tarif, "productPrice2":0, "productPriceTax": productPriceTotal, 
          "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [], 
          "cticket": true, pages:0, perPage:0, randcolor:rId };
		  //productCart.push(myCart);
		  if(indexProd==0){ productCart.push(myCart); }else{productCart[0]=(myCart);}
          this.setState({ ["productCart"]:  productCart });

           
          this.calculateGrandTotal(null) ;  
          //console.log('weeeeeeeeeeeeeeeeeeeeeee aaaaaaaaaply one '+inputValue);
		  //console.log('MINIPayment aaaaaaaaaply one ', productCart);
          
          return false;

        }
      return (
        null
      );
    }, this);

    //this.setState({ inputValue });
    //console.log('aaaaaaaaaply hellooooooooooooo '+inputValue);
    return inputValue;
  };

  setNewPriceForProduct  = (event) => {    
    //event.preventDefault(); 
    const selVal = event.target.value >= "1" ? event.target.value : "1";
    const selVal2= event.target.dataset.value;  
    const arVal=selVal2.split('-');
    const index=arVal[0];  const prodId=arVal[1];  //const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);  
   
    let productCart=this.state.productCart;

    const taxInfo=this.state.taxInfo;
    const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
   
    //productCart[index].options=optionsProd;
    productCart[index].productPrice2=selVal;
    //this.prodPrice[index].current.value=selVal;
 
    //this.setState({ ["productCart"]:  productCart });


    let totTagTotOptions=(productCart[index].totOptions*1); // 0;
    let optionsProd=[]; //productCart[index].options; 
    
    // totTagTotOptions=((selPage-1)*perPage); 
    // optionsProd.push({id: 'perPage', mnt:totTagTotOptions}); 
    
    // productCart[index].totOptions=totTagTotOptions;
    const productPrice= productCart[index].productPrice2===0?productCart[index].productPrice:productCart[index].productPrice2;

    let tarif =((productPrice*1)+(totTagTotOptions)); // (''+item.tarif).replace(/\W\$/g, '');  
    const productPriceTaxOnly= (((tarif*1)*TAX_TOTAL)).toFixed(2) ;
    const productPriceTotal= (((tarif*1)*TAX_TOTAL)+(tarif*1)).toFixed(2) ; 
    //productCart[index].options=optionsProd;
    const orderTVQ=((tarif*1)*TAX1).toFixed(2);
    const orderTPS=((tarif*1)*TAX2).toFixed(2);  
    const orderServices=totTagTotOptions; 
    const orderSubtotals=((tarif*1)).toFixed(2);
  

    productCart[index].productPriceTax=productPriceTotal;
    productCart[index].productPriceTaxOnly=productPriceTaxOnly;
    this.setState({ ["productCart"]:  productCart });

    /*this.prodTaxOnly[index].current.innerHTML=productPriceTaxOnly;
    this.prodPriceAndTax[index].current.innerHTML=productPriceTotal;
    this.prodPriceAndTax2[index].current.innerHTML=productPriceTotal;
    // this.calculateGrandTotal(null) ;
 
    this.prodPriceTVQ[index].current.innerHTML=orderTVQ; 
    this.prodPriceTPS[index].current.innerHTML=orderTPS; 
    this.prodPriceServices[index].current.innerHTML=orderServices;
    this.prodPriceSubtotals[index].current.innerHTML=orderSubtotals;*/

    //productCart[index].options=optionsProd; 
    //productCart[index].pages= selPage >0? selPage : 1;
 
    //this.setState({ ["productCart"]:  productCart });
    this.calculateGrandTotal(null) ;
 

  }

  handleRemoveProductItem = (event) => {
    const { t } = this.props;   
    //event.target.getAttribute('') or event.target.dataset.tzzzzz
    const index= event.target.dataset.prodindex;  //const selVal= event.value;  	
    //console.log('click remooooooooooooooooove well  '+index);
    //console.log(event);
    //console.log(event);
    let productCart=this.state.productCart;
    let productExtraOptionCtrl2x=this.state.productExtraOptionCtrl2;
    const productTitre=productCart[index].productTitre;
    productExtraOptionCtrl2x[index]= <span key={index} />; //  productExtraOptionCtrl2x.splice(index, 1); ////productExtraOptionCtrl2x.push(divBlock);
    this.setState({ ["productExtraOptionCtrl2"]: productExtraOptionCtrl2x });
 
    const myCart={ "index": index, "id": index+'98989', "productTitre": '', "productPrice": 0, "productPrice2": 0, "productPriceTax": 0, "productPriceTaxOnly": 0, "totOptions":0, "options": [], "isdelete":1 }
    productCart[index]= myCart; //productCart.splice(index, 1); //productCart.push(myCart);
    this.setState({ ["productCart"]:  productCart });
    //this.calculateGrandTotal(null) ;
    this.showToast(t("zqwNVFrmEl123_x")+"  "+productTitre, "warning");
    this.calculateGrandTotal(null) ;	

  };

  indexOfElement = (optionList, id, amount) => {  
    let index=-1;
    optionList.length > 0
    	&& optionList.map((item, i) => {  
        if(item.id===id && item.mnt===amount){ 
          index=i;
          return i;
        }
    }, this); 
     

    return index;
  }

  calculateGrandTotal = (event) => { 
    const productCart=this.state.productCart;
    //const myCart={ "index": indexProd, "id": item.id, "productTitre": item.titre, "productPrice": tarif, "productPriceTax": productPriceTotal, "productPriceTaxOnly": productPriceTaxOnly, "totOptions":0, "options": [] }
    let subTotal=0;  let totTagTotOptions=0;
    const taxInfo=this.state.taxInfo;
    // const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    // const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    // const TAX_TOTAL=TAX1+TAX2; //0.14975; 


    let TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    //const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
    let TAX1lx=taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx=taxInfo["tax2"]; 
    let TAX12=0; let TAX21=0;
    if(TAX2_NAME ==="0"){             
      TAX1lx=parseFloat(TAX1l)+parseFloat(TAX2l);
      TAX1=TAX1+TAX2;     
      TAX12=TAX1+TAX2;  
      TAX2=0;   
    }  
    if(TAX1_NAME==="0"){                   
      TAX2lx=parseFloat(TAX1l)+parseFloat(TAX2l); 
      TAX2=TAX2+TAX1;  
      TAX21=TAX1+TAX2;  
      TAX1=0;                          
    }  
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
     
    productCart.length > 0
    	&& productCart.map((item, i) => { 
        if(item.productTitre!==""){  
          const productPrice= item.productPrice2===0?item.productPrice:item.productPrice2;
          subTotal +=parseFloat(productPrice); //item.productPrice
          totTagTotOptions +=parseFloat(item.totOptions);
        }
    }, this); 
    
    let tarif =((subTotal*1)+(totTagTotOptions)); // (''+item.tarif).replace(/\W\$/g, '');  TAX1 TAX2 TAX_TOTAL
    
    const percentage= this.state.promoPercentage;
    let tarifPromo=(((percentage*1)/100)*tarif); 
    tarif=(tarif-tarifPromo);
    
    
    const productPriceTaxOnly= (((tarif*1)*TAX_TOTAL)).toFixed(2) ;
    const productPriceTVQ= (((tarif*1)*TAX1)).toFixed(2) ;
    const productPriceTPS= (((tarif*1)*TAX2)).toFixed(2) ;
    const productPriceTotalx= (((tarif*1)*TAX_TOTAL)+(tarif*1)); //.toFixed(2) ; 

    //paymentsReceived: [0], totalPaymentReceived: 0,
    const totalPaymentReceived=this.state.totalPaymentReceived;

    let productPriceTotal= (productPriceTotalx-totalPaymentReceived).toFixed(2) ; 
    if(productPriceTotal=="-0.00"){ productPriceTotal="0.00" }

    this.setState({ ["grandTotal"]:  productPriceTotal,["beforePaymentTotal"]:  productPriceTotalx.toFixed(2) });

    //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0,  

    this.setState({["orderBruteSubTotal"]: tarif, ["orderNetSubTotal"]: tarif, ["orderTVQ"]: productPriceTVQ,["orderTPS"]: productPriceTPS,["orderTotal"]: productPriceTotal,});
    this.setState({ ["nowPayment"]:  productPriceTotal });

    //console.log("===calculateGrandTotal ", TAX1, TAX2, tarif, productPriceTVQ, productPriceTPS);

    //this.setState({ ["productCart"]:  productCart });
    // this.setState({["orderBruteSubTotal"]: orderBruteSubTotal, ["orderNetSubTotal"]: tarif, 
    //       ["orderTVQ"]: productPriceTVQ,["orderTPS"]: productPriceTPS,
    //       ["orderTotal"]: productPriceTotal, ["grandTotal"]:  productPriceTotal,});
    //       this.setState({ ["nowPayment"]:  productPriceTotal });
  };


  setExtraServiceForProduct = (event) => {     
    //event.preventDefault(); 
    //const inputValue = newValue.replace(/\W/g, '');
    //this.setState({ inputValue });

    const taxInfo=this.state.taxInfo;
    const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 

    const selVal= event.target.value;  //productId
    const arVal=selVal.split('-');
    const index=arVal[0];  const prodId=arVal[1];  const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);  
   
    let productCart=this.state.productCart;
    //console.log("setExtraServiceForProduct ", selVal, "prodOptId", prodOptId, "mnt", amountOpt); 
     
    //console.log(productCart[index]);
    let totTagTotOptions=(productCart[index].totOptions*1);
    let optionsProd=productCart[index].options;
    
    if(event.target.checked){ 
      //totTagTotOptions +=amountOpt; optionsProd.push(amountOpt); 
      totTagTotOptions +=amountOpt; optionsProd.push({id: prodOptId, mnt:amountOpt}); 
    }else { 
      totTagTotOptions -=amountOpt;  
      //const indz=optionsProd.indexOf(amountOpt);
      //const indz=optionsProd.indexOf({id: prodOptId, mnt:amountOpt});
      const indz=this.indexOfElement(optionsProd, prodOptId, amountOpt);
      //console.log('REACT ] indzindzindzindzindzindz  '+indz);
      if(indz >-1){ 
        optionsProd.splice(indz, 1); 
      }
    }

    productCart[index].totOptions=totTagTotOptions;
    let tarif =((productCart[index].productPrice*1)+(totTagTotOptions)); // (''+item.tarif).replace(/\W\$/g, '');  
    const productPriceTaxOnly= (((tarif*1)*TAX_TOTAL)).toFixed(2) ;
    const productPriceTotal= (((tarif*1)*TAX_TOTAL)+(tarif*1)).toFixed(2) ; 
    productCart[index].options=optionsProd;
    const orderServices=totTagTotOptions; 
    const orderSubtotals=((tarif*1)).toFixed(2);
   
    productCart[index].productPriceTax=productPriceTotal;
    productCart[index].productPriceTaxOnly=productPriceTaxOnly;
    this.setState({ ["productCart"]:  productCart });
 
    /*this.prodTaxOnly[index].current.innerHTML=productPriceTaxOnly;
    this.prodPriceAndTax[index].current.innerHTML=productPriceTotal;
    this.prodPriceAndTax2[index].current.innerHTML=productPriceTotal;
    this.prodPriceServices[index].current.innerHTML=orderServices;
    this.prodPriceSubtotals[index].current.innerHTML=orderSubtotals;*/
    this.calculateGrandTotal(null) ;

  }
 

  setTicketForProduct  = (event) => {  //(event) {        
    //event.preventDefault(); 
    const selVal= event.target.value;  //productId
    const arVal=selVal.split('-');
    const index=arVal[0];  const prodId=arVal[1];  //const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);  
    
    let productCart=this.state.productCart;
   
    if(event.target.checked){ 
      productCart[index].cticket=true;
    }else { 
      productCart[index].cticket=false;
    }
    //this.state.productCart[index].cticket
  
    //productCart[index].options=optionsProd;
 
    this.setState({ ["productCart"]:  productCart });

  }

  setPageDocForProduct  = (event) => {   
    const { t } = this.props;
    event.preventDefault(); 
    const selVal= event.target.dataset.value; //event.target.value;   
    let selPage= event.target.value; 
    const arVal=selVal.split('-');
    const index=arVal[0];  const prodId=arVal[1];  const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]); 
    const maximum=(event.target.dataset.maximum*1);  
    //const perPage=(event.target.dataset.perpage); 
    const perPage=amountOpt;  
    const taxInfo=this.state.taxInfo;
    const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
     
    //console.log('dynamic change doc page= '+selPage+' ind='+index);

    if(selPage >maximum){ 
      event.target.value=1; //maximum;
      this.showToast(t("zqwNVFrmEl29_x", {maximum:maximum, selPage:selPage}), "error");	
      //return false;
    }
 
    let productCart=this.state.productCart;
     
    let totTagTotOptions=0; //(productCart[index].totOptions*1);
    let optionsProd=[]; //productCart[index].options;

    //if(selPage==1){  selPage=0; } //to avoid $25$ if 1 page only
    
    totTagTotOptions=((selPage-1)*perPage); 
    //optionsProd.push({id: 'perPage', mnt:totTagTotOptions}); 
    optionsProd.push({id: 'perPage', mnt:totTagTotOptions }); 
    //, pg:selPage, perPage:perPage
    
    productCart[index].totOptions=totTagTotOptions;
    const productPrice= productCart[index].productPrice2===0?productCart[index].productPrice:productCart[index].productPrice2;
    console.log("totOptions totOptions ", totTagTotOptions, selPage, perPage, "selVal", selVal); 

    let tarif =((productPrice*1)+(totTagTotOptions)); // (''+item.tarif).replace(/\W\$/g, '');  
    const productPriceTaxOnly= (((tarif*1)*TAX_TOTAL)).toFixed(2) ;
    const productPriceTotal= (((tarif*1)*TAX_TOTAL)+(tarif*1)).toFixed(2) ; 
    productCart[index].options=optionsProd;
    const orderServices=totTagTotOptions; 
    const orderSubtotals=((tarif*1)).toFixed(2);
  

    //const productPriceTotal= ((((tarif*1)*TAX1)+((tarif*1)*TAX2))+(tarif*1)).toFixed(2); 
    const orderTVQ=((tarif*1)*TAX1).toFixed(2);
    const orderTPS=((tarif*1)*TAX2).toFixed(2);  

    productCart[index].productPriceTax=productPriceTotal;
    productCart[index].productPriceTaxOnly=productPriceTaxOnly;
    this.setState({ ["productCart"]:  productCart });
 
    productCart[index].options=optionsProd; 
    productCart[index].pages= selPage >0? selPage : 1;
    productCart[index].perPage=perPage;
     
 
    this.setState({ ["productCart"]:  productCart });
    this.calculateGrandTotal(null) ;
  }

  setNewPriceForProduct  = (event) => {    
    //event.preventDefault(); 
    const selVal= event.target.value;  //productId
    const selVal2= event.target.dataset.value;  
    const arVal=selVal2.split('-');
    const index=arVal[0];  const prodId=arVal[1];  //const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);  
   
    let productCart=this.state.productCart;

    const taxInfo=this.state.taxInfo;
    const TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    const TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
   
    //productCart[index].options=optionsProd;
    productCart[index].productPrice2=selVal; 

    let totTagTotOptions=(productCart[index].totOptions*1); // 0;
    let optionsProd=[]; //productCart[index].options; 
    
    // totTagTotOptions=((selPage-1)*perPage); 
    // optionsProd.push({id: 'perPage', mnt:totTagTotOptions}); 
    
    // productCart[index].totOptions=totTagTotOptions;
    const productPrice= productCart[index].productPrice2===0?productCart[index].productPrice:productCart[index].productPrice2;

    let tarif =((productPrice*1)+(totTagTotOptions)); // (''+item.tarif).replace(/\W\$/g, '');  
    const productPriceTaxOnly= (((tarif*1)*TAX_TOTAL)).toFixed(2) ;
    const productPriceTotal= (((tarif*1)*TAX_TOTAL)+(tarif*1)).toFixed(2) ; 
    //productCart[index].options=optionsProd;
    const orderTVQ=((tarif*1)*TAX1).toFixed(2);
    const orderTPS=((tarif*1)*TAX2).toFixed(2);  
    const orderServices=totTagTotOptions; 
    const orderSubtotals=((tarif*1)).toFixed(2);
  

    productCart[index].productPriceTax=productPriceTotal;
    productCart[index].productPriceTaxOnly=productPriceTaxOnly;
    this.setState({ ["productCart"]:  productCart }); 
    //productCart[index].options=optionsProd; 
    //productCart[index].pages= selPage >0? selPage : 1; 
    //this.setState({ ["productCart"]:  productCart });
    this.calculateGrandTotal(null) ;
  }

  ProductsOnInvoice = (event) => {
    const { t } = this.props;
    const taxInfo=this.state.taxInfo;
    let TAX1=parseFloat(taxInfo["tax1x"]); //tax1 0.9975
    let TAX2=parseFloat(taxInfo["tax2x"]); //0.05; //tax2 
    //const TAX_TOTAL=TAX1+TAX2; //0.14975; 
    const TAX1_NAME=taxInfo["tax1_name"]; //"TVQ"; //tax1_name
    const TAX2_NAME=taxInfo["tax2_name"]; //"TPS";
    const TAX1l=taxInfo["tax1"]; //tax1 0.9975
    const TAX2l=taxInfo["tax2"]; 
    let TAX1lx=taxInfo["tax1"]; //tax1 0.9975
    let TAX2lx=taxInfo["tax2"]; 
    let TAX12=0; let TAX21=0;
    if(TAX2_NAME ==="0"){             
      TAX1lx=parseFloat(TAX1l)+parseFloat(TAX2l);
      TAX1=TAX1+TAX2;     
      TAX12=TAX1+TAX2;  
      TAX2=0;   
    }  
    if(TAX1_NAME==="0"){                   
      TAX2lx=parseFloat(TAX1l)+parseFloat(TAX2l); 
      TAX2=TAX2+TAX1;  
      TAX21=TAX1+TAX2;  
      TAX1=0;                          
    }  
    const TAX_TOTAL=TAX1+TAX2; //0.14975;         
    const arrProd=[6,8,9]; 
    let productOptions = [];
    let productList = this.productsList.products.length > 0
    	&& this.productsList.products.map((item, i) => {
        //productOptions.push({ value: item.id, label: item.titre }); 
        if(item.type.indexOf('hidden') <0 && item.titre!==''){
          productOptions.push({ value: item.id, label: item.titre+ ' ('+item.tarif+')' });
        }
      return (
        null
      );
    }, this);
 
    //const ProductExtraOptionCtrl=this.state.productExtraOptionCtrl;  
    const ProductExtraOptionCtrl2=this.state.productExtraOptionCtrl2;
    const grandTotal=this.state.grandTotal;
    //orderBruteSubTotal:0, orderNetSubTotal:0, orderTVQ:0, orderTPS:0, orderTotal:0,
    const orderBruteSubTotal=this.state.orderBruteSubTotal; 
    const orderTVQ=this.state.orderTVQ; //shows at the end
    const orderTPS=this.state.orderTPS;  //shows at the end

    ///////////////////////////////////////////////////////////////////////////////////////
    let productCart=this.state.productCart;
    //const taxInfo=this.state.taxInfo;

    console.log("OUR-BASKET", productCart);
        
    let subTotal=0;  let totTagTotOptions=0; let totProd=0;
    const listOfCurrentProducts=productCart.length > 0
    	&& productCart.map((itemState, i) => { 
        const isDelete=itemState.isdelete;
        const myItemtxt=""+itemState.id;
        const index=i; 
        console.log("OUR-BASKET-i-"+i, itemState);
        if(myItemtxt.indexOf("98989") <0 && isDelete===undefined){ 
          
        const item= this.getProductChange(itemState.id); 
        console.log("OUR-BASKET-PRODINFO", item);
        const optionsEntries = (item.options!=undefined && !arrProd.includes(item.id)) ? Object.entries(item.options): [];
        const cartEntries = Object.entries(item.cart);

        const this00=this;

        let optionsProd=productCart[index].options; 
        let selPage=productCart[index].pages;
        let perPage=productCart[index].perPage;
        if(selPage<=1){  selPage=1; } 

        //totTagTotOptions=((selPage-1)*perPage);  
        totTagTotOptions=productCart[index].totOptions;

        // let tarif = (''+item.tarif).replace(/\W\$/g, ''); 
        // tarif=tarif.replace('$', '');
        const productPrice= itemState.productPrice2===0?itemState.productPrice:itemState.productPrice2;
        //let tarif = productPrice;
        //console.log("weeeeeeeeeeeeeeeeeeooooy ", productPrice, totTagTotOptions, "optionsProd", optionsProd); 
        let tarif =((productPrice*1)+(totTagTotOptions));
        let tarifPrix =productPrice; 
        //let selPage= event.target.value; 
        const productPriceTaxOnly= (((tarif*1)*(TAX_TOTAL))).toFixed(2);
        //const productPriceTotal= (((tarif*1)*0.15)+(tarif*1)).toFixed(2); //(((tarif*1)+(productPriceTaxOnly)));
        const productPriceTotal= ((((tarif*1)*TAX1)+((tarif*1)*TAX2))+(tarif*1)).toFixed(2); 
        const orderTVQ=((tarif*1)*TAX1).toFixed(2);
        const orderTPS=((tarif*1)*TAX2).toFixed(2); 
        //const orderServices=0;
        const orderServices=totTagTotOptions;  
        //const orderSubtotals=((tarif*1)+orderServices).toFixed(2);
        const orderSubtotals=((tarif*1)).toFixed(2);

        // let productCart=this.state.productCart;
         const indexProd=index; //productCart.length;
        const apartir= item.apartir; let apartirUsed=false;
        //const optionsProd=item.options;

        //optionsProd.perPage} data-maximum={optionsProd.maximum
        //optionsProd.push({id: prodOptId, mnt:amountOpt}); 
        //const groupVal=''+indexProd+'-'+item.id+'-'+txtLabel+'-'+amount;
        //const index=arVal[0];  const prodId=arVal[1];  const prodOptId=arVal[2];  const amountOpt=parseFloat(arVal[3]);  
       //console.log("MMMMMMMMMMMMMM", itemState.options, " vs ", optionsProd);   

        const productExtraOptionCtrl2=optionsEntries.map(function(elem, index) { 
          const [txtLabel, amount] =elem;
          //const this0=this;
          //const groupVal=''+indexProd+'-'+item.id+'-'+amount;
          const groupVal=''+indexProd+'-'+item.id+'-'+txtLabel+'-'+amount;
          //console.log(`##arraymap There are ${elem} ---- ${index} +++++++++++++ ${txtLabel} -- ${amount} `);
          let titre= '';  
          for (const [txtLabel2, labelValue] of cartEntries) { 
            if(txtLabel===txtLabel2){ 
              titre= labelValue;
              //console.log(`##zazThere are ${txtLabel2} //////VS ${labelValue}  `);
              break;
            } 
          }
          if(titre==='' && apartir===false){
            return (<span key={index} />);
          }if(apartir===true){
            //apartirUsed=true;
            if(apartirUsed===false){
              apartirUsed=true;
              return (<p key={index}>{t("zqwNVFrmEl81_x")}:<br/>
                <input type="number" min="1" step="1" defaultValue={selPage} 
              data-value={groupVal} data-perpage={optionsProd.perPage} data-maximum={optionsProd.maximum} 
              onChange={(eventx) => this00.setPageDocForProduct(eventx)}  /> {t("zqwNVFrmEl82_x")} (n x ${amount})</p>);
            }else{ return (<span key={index} />); }
          }else{
            //console.log(`##INDEEEEEEEEEEEEEEEEEX SET IS ${groupVal} VS ${txtLabel} - ${optionsProd.id} `);
            let isSelected=false; const txtLabelx=txtLabel; const txtLabelzx=optionsProd.id;
            //let indz=-9;
            const indz=this00.indexOfElement(optionsProd, txtLabel, amount);
            //console.log('REACT ] indzindzindzindzindzindz  '+indz);
            if(indz >-1){ 
              isSelected=true; 
            } 

            return (<p key={index} ><input   type="checkbox" value={groupVal} defaultChecked={isSelected}  onChange={(eventx) => this00.setExtraServiceForProduct(eventx)}  /> {titre} (${amount})</p>);  
          }
        });
            
        //const rId=(Math.floor(Math.random()*16));
        const rId=itemState.randcolor;

        
        //<div className="card card-primary card-outline">  <div key={indexProd} className="marginr md-4">
        const divBlock=(
          <div key={myItemtxt} className="marginr md-4">
          <div className="card card-default neocardx2">
            <div className={`card-header neocard${rId}`}>
              <h5 className="m-0">{item.titre}</h5>
            </div>
            <div className="card-body">
 
              <div className="row">
                <div className="col-md-10">
                  <div className="row" style={{display:"block"}}>
                    <div className="neoinline50"><span>{t("zqwNVFrmEl83_x")}</span></div>
                    <div className="neoinline50l" key={"iz"+indexProd}>    
                <input type="number" step="0.1" min="1" name="neoPrice" value={tarifPrix}  
                data-value={indexProd+'-'+item.id}    
              onChange={(eventx) => this00.setNewPriceForProduct(eventx)} className="form-control col-md-8x" size="30" style={{display:'inline', width:'100px'}} /> 
                    <span></span> 
                    </div>
                  </div>
                  <div className="row" style={{display:"block"}}> 
                    <div className="neoinline50 text-right"><span>{t("zqwNVFrmEl84_x")}</span></div>
                    <div className="neoinline50l"> <span>{orderServices}</span>$</div> 
                  </div>

{/* 
                  <div className="row" style={{display:"block"}}> 
                    <div className="neoinline50"><span>{t("zqwNVFrmEl57_x")}</span></div>
                    <div className="neoinline50l"> <span>{orderSubtotals}</span>$</div>
                  </div>
                  
                  {TAX1_NAME!=="0"?
                    <div className="row" id="tax1_block" style={{display:"block"}}> 
                    <div className="neoinline50 text-right"><span>{TAX1_NAME!=="0"?TAX1_NAME:''}</span> 
                    (<span>{TAX1lx}</span>%)</div>
                    <div className="neoinline50l"> <span>{orderTVQ}</span>$</div> 
                  </div>:'' }

                   {TAX2_NAME!=="0"?
                    <div className="row" id="tax2_block" style={{display:"block"}}> 
                    <div className="neoinline50"><span>{TAX2_NAME!=="0"?TAX2_NAME:''}</span> (<span>{TAX2lx}</span>%)</div>
                    <div className="neoinline50l"> <span>{orderTPS}</span>$</div>
                  </div>:'' }

                  <div className="row" id="tax2_block" style={{display:"block"}}> 
                    <div className="neoinline50"><span>{t("zqwNVFrmEl85_x")}</span></div>
                    <div className="neoinline50l"> <b><span>{productPriceTaxOnly}</span></b>$</div>
                  </div>
                  <div className="row"><div className="neoinline30"> </div><div className="neoinline50"><hr/></div></div>
                  <div className="row" id="tax2_block" style={{display:"block"}}> 
                    <div className="neoinline50"><span>{t("zqwNVFrmEl61_x")}</span></div>
                    <div className="neoinline50l"> <span>{productPriceTotal}</span>$</div>
                  </div> */}


                </div>
              </div>


              <br/>
              {optionsEntries.length > 0 && cartEntries.length > 0? <>{t("zqwNVFrmEl86_x")}: <hr/></>:''}
              
              {productExtraOptionCtrl2}
              <br/>
              <div className="ovalItemPrice">  
              <span>{productPriceTotal}</span>$  
              </div> 
              <p style={{display:"none"}}>
                <input type="checkbox" value={indexProd+'-'+item.id} defaultChecked  
              onChange={(eventx) => this00.setTicketForProduct(eventx)}  /> {t("zqwNVFrmEl87_x")}</p>
             
              {/* <a href="#" className="btn btn-danger" data-prodindex={indexProd} onClick={this.handleRemoveProductItem} >{t("zqwNVFrmEl88_x")}</a> */}
            </div>
          </div>
        </div> 
        ); 
 
         return divBlock;
        }

        return null;

    }, this);

    return (<div className="row">
    {listOfCurrentProducts}
  </div>);

    return (
      <>
      {/* <div className="form-group">
          <label htmlFor="name">{t('zqwNVFrmEl41_x')}</label>
          <Select options={productOptions} onChange={this.handleProductChange2} />            
      </div> */}
      <div className="card card-primary card-outline">
        <div className="card-header">
          <h5 className="m-0">{t("zqwNVFrmEl89_x")}</h5>
        </div>
        <div className="card-body">
          {/* <div className="form-group">
              <label htmlFor="name">{t('zqwname_x')} du Produit</label>
              <Select options={productOptions} onChange={this.handleProductChange} />            
          </div>  */}

          {/* <div className="row"> 
              <div className="form-group"><div className="input-group"> 
              <label htmlFor="province">{t("zqwNVFrmEl90_x")} {t('zqwprovince_x')} *</label> &nbsp;  
                    <select name="province" id="province" value={this.state.province} onChange={this.handleChangeProvince} onBlur={this.handleFocusout}
                      className={this.state.e_province ? 'form-control fieldErr' : 'form-control'} placeholder={t('zqwprovince_x')} >
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="NU">Nunavut</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="YT">Yukon</option>
                      </select> 
                      </div></div>
          </div> */}

 
        <div className="row">
          {listOfCurrentProducts}
        </div>

         
        <div className="card-text"> 
         {/* <h5 className="card-title">Total <b>{grandTotal}</b></h5><br/> */}
         {/* <div class="row" style="display: block;"> */}
         <div className="neoinline40">
          <div className="row" id="tax2_blockqqqqq2">
            <div className="neoinline50"><span>{t("zqwNVFrmEl57_x")}</span></div>
            <div className="neoinline50l"> <span>{orderBruteSubTotal}</span>$</div>
          </div>
           
        {TAX1_NAME!=="0"?
          <div className="row" id="tax1_blockqqqq">
            <div className="neoinline50"><span>{TAX1_NAME!=="0"?TAX1_NAME:''}</span> (<span>{TAX1lx}</span>%)</div>
            <div className="neoinline50l"> <span>{orderTVQ}</span>$</div>
          </div>:'' } 

 
          {TAX2_NAME!=="0"?
            <div className="row" id="tax2_blockqqqqq">
            <div className="neoinline50"><span>{TAX2_NAME!=="0"?TAX2_NAME:''}</span> (<span>{TAX2lx}</span>%)</div>
            <div className="neoinline50l"> <span>{orderTPS}</span>$</div>
          </div>:''} 

 
          <div className="row" id="tax2_blockqqqqq3">
            <div className="neoinline50"><span>{t("zqwNVFrmEl61_x")}</span></div>
            <div className="neoinline50l"> <span><b>{grandTotal}</b></span>$</div>
            <div className="ovalTotalPrice"> &nbsp; <span>{t("zqwNVFrmEl91_x")}</span> <span><b>{grandTotal}</b></span>$ &nbsp; </div>
            
          </div>
 

         </div>
          
        </div>
         {/* <button type="button" className="btn btn-default" onClick={this.handlePreviousStep}>{t("zqwNVFrmEl92_x")}</button> &nbsp; 
        <a href="#" className="btn btn-primary"  onClick={this.handleProductNextStep} >{t("zqwNVFrmEl93_x")}</a>
        <a href="#" className="btn btn-warning float-right"  onClick={this.restartOver}>{t("zqwNVFrmEl94_x")}</a> */}
        </div>
      </div></>
    );
  }


 
}


const mapStateToProps = (state) => ({ NeoProducts: state.NeoProducts }); 
//const mapStateToProps = (state) => ({ NeoVentes: state.NeoVentes }); 
//const mapDispatchToProps = { addOrUpdateNeoProducts, getNeoProducts, getNeoProductsAll, getNeoProductsGoto, deleteNeoProducts };   
const mapDispatchToProps = { addOrUpdateNeoVentes, addNeoVentesSC };           
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("commonndi")(NeoMiniPayment));
