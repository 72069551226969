import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import { IconButton, Tooltip } from "@mui/material";
// import {DataGrid} from '@mui/x-data-grid'
import TableSortLabel from "@mui/material/TableSortLabel";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";

// import { visuallyHidden } from '@mui/utils';
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountDown,
  faAngleRight,
  faAngleDoubleRight,
  faArrowRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faSortAmountUp,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";

import { v4 as uuid } from "uuid";
import { FormGroup, Input } from "reactstrap";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <FontAwesomeIcon icon={faAngleDoubleLeft} size="sm" />
        ) : (
          <FontAwesomeIcon icon={faAngleDoubleLeft} size="sm" />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <FontAwesomeIcon icon={faAngleLeft} size="sm" />
        ) : (
          <FontAwesomeIcon icon={faAngleLeft} size="sm" />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <FontAwesomeIcon icon={faAngleRight} size="sm" />
        ) : (
          <FontAwesomeIcon icon={faAngleRight} size="sm" />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FontAwesomeIcon icon={faAngleRight} size="sm" />
        ) : (
          <FontAwesomeIcon icon={faAngleDoubleRight} size="sm" />
        )}
      </IconButton>
    </Box>
  );
}

export default function BasicTable(props) {
  const {
    onSelectAllClick,
    columns,
    data,
    numSelected,
    rowCount,
    onRequestSort,
    searchBarComponent,
  } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [text, setText] = React.useState("");

  React.useEffect(() => {
    setPage(0);
  }, [data.length]);

  const handleRequestSort = (event, property) => {
    if (property === "priority" && order === "asc") {
      setOrder("desc");
      setOrderBy(property);
    } else {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const createSortHandler = (property) => (event) => {
    if (onRequestSort) onRequestSort(event, property);
    else handleRequestSort(event, property);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const createSortHandler = (property) => {
  //     console.log('ukoto',property)
  //     return (event) => {
  //         console.log('🎵whendever whenever🎵')
  //         debugger;
  //         if(onRequestSort)
  //             onRequestSort(event, property);
  //         else
  //             handleRequestSort(event, property);
  //     }
  // }

  let columnsX = _.map(columns, (e) => {
    e.id = e.accessor;
    return e;
  });

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, comparator, text) {
    let stabilizedThis = array.map((el, index) => [el, index]);

    if (text && text.length > 0) {
      // debugger;
      stabilizedThis = _.filter(stabilizedThis, (e) => {
        let found = false;
        for (let f in e[0]) {
          if (
            typeof e[0][f] === "string" &&
            e[0][f].match(new RegExp(text, "i"))
          ) {
            found = true;
          }
        }
        return found;
      });
    }

    // debugger;
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <>
      <FormGroup>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginTop: "0.5rem",
          }}
        >
          <Input
            type={"text"}
            placeholder={"Recherche"}
            value={text}
            size={"sm"}
            onChange={(e) => {
              setText(e.target.value);
            }}
            style={{
              borderRadius: "50px",
              width: searchBarComponent ? "25%" : "",
            }}
          />
          {searchBarComponent && searchBarComponent}
        </div>
      </FormGroup>

      <TableContainer component={Paper} className="scrollbar-visible">
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              {props.showCheckboxes && (
                <TableCell padding="checkbox" style={{ zIndex: "auto" }}>
                  <Checkbox
                    style={{ zIndex: "auto" }}
                    onClick={(e) =>
                      props.handleSelectAllCheckboxes(e, props.data)
                    }
                  />
                </TableCell>
              )}
              {_.map(columnsX, (e) => {
                return (
                  <TableCell
                    id={e.id}
                    key={e.id}
                    sortDirection={orderBy === e.id ? order : false}
                    align={"left"}
                    style={{ zIndex: "auto" }}
                  >
                    <TableSortLabel
                      active={orderBy === e.id}
                      direction={orderBy === e.id ? order : "asc"}
                      onClick={createSortHandler(e.id)}
                    >
                      {e.Header}
                      {/* {orderBy === e.id ? (
                                                <Box 
                                                    component="span"
                                                >
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null} */}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(
              stableSort(data, getComparator(order, orderBy), text).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ),
              (row, index) => {
                // console.log(row)
                let isSelected = "";
                if (props.showCheckboxes) {
                  isSelected = props.selectedCheckboxes.includes(row.id);
                }
                return (
                  <TableRow
                    hover
                    key={index + "rk" + row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {props.showCheckboxes && (
                      <TableCell padding="checkbox" style={{ zIndex: "auto" }}>
                        <Checkbox
                          style={{ zIndex: "auto" }}
                          id={row.id}
                          checked={isSelected}
                          onClick={(e) =>
                            props.handleCheckboxChange(e.target.id)
                          }
                        />
                      </TableCell>
                    )}
                    {_.map(columnsX, (e, i) => {
                      if (i === 0) {
                        return (
                          <TableCell
                            key={index + "tc" + e.accessor + i}
                            component="th"
                            scope="row"
                            style={{ textAlign: "left", whiteSpace: "nowrap" }}
                          >
                            {row[e.accessor]}
                          </TableCell>
                        );
                      } else if (
                        e.accessor === "domaine" ||
                        e.accessor === "languages" ||
                        e.accessor === "provinces"
                      ) {
                        // console.log(row, e.accesor)
                        return (
                          <TableCell
                            key={index + "tc" + e.accessor + i}
                            align="right"
                            style={{ maxWidth: "150px" }}
                          >
                            {row[e.accessor]
                              .map((element) => {
                                return `${element.label}`;
                              })
                              .join(", ")}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={e.accessor + i}
                            style={{ maxWidth: "250px" }}
                            align="right"
                          >
                            {row[e.accessor]}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>
          <TableFooter>
            <TableRow style={{ zIndex: "auto" }}>
              <TablePagination
                rowsPerPage={rowsPerPage}
                count={data.length}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[25, 100, 500]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                ActionsComponent={TablePaginationActions}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    marginBottom: "0px",
                  },
                  "& .MuiTablePagination-selectLabel": {
                    marginBottom: "0px",
                  },
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
