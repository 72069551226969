import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateNeoB2bleadProspects, getNeoB2bleadProspects, getNeoB2bleadProspectsAll, getNeoB2bleadProspectsAllx, getNeoB2bleadProspectsGoto, deleteNeoB2bleadProspects } from '../../actions/NeoB2bleadProspectsAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "../CustomToolbarSelect";
import Toastr from "../../messages";
import  Loader from "../../loader";

class NeoB2bleadProspectsList extends Component {
	constructor(props) {
		super(props); 
		this.state ={weeksatrt:"", weekend:"", groupby:"day", groupbyprovince:"", 
		 	isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000,
			 sumQuantity:0, sumSubtotal:0, sumTax1:0, sumTax2:0, sumTaxtotal:0,  sumTotal:0, 
			 sumQuantityno:0, sumQuantitysu:0, 
			 listOfSales:[], 
		};
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 
	}
  
	// componentDidMount() { 
	// 	this.props.getNeoB2bleadProspectsAll();
	// }

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value }); 
	};

	handleLoadData = (event) => {
		const { t } = this.props;
		//this.setState({ [event.target.name]: event.target.value }); 
		const weeksatrt=this.state.weeksatrt;
		const weekend=this.state.weekend;
		const groupby=this.state.groupby;
		const province=this.state.groupbyprovince;
		console.log("... weeksatrt="+weeksatrt+" "+weekend+" "+groupby);
		if((weeksatrt!=="" && weekend==="") || (weeksatrt==="" && weekend!=="")){
			window.alert(t("zqwCViewEl22_x"));
			return false; 
		}
		const obj={date:weeksatrt, date2:weekend, dayWeekMonth:groupby, province:province};
		 
		//this.props.getNeoVentesByPeople(obj);
		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		Promise.resolve(this.props.getNeoB2bleadProspectsAllx(obj))
        .then((response)=>{ 
		  this.setState({ ["listOfSales"]: response.NeoB2bleadProspects, ["isLoaderOpen"]: false});
		  this.showToast(t("zqwCViewEl11_x"), "success"); 
		  console.log("weeeeeeeeeeeeeee ", response); 
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          alert(error);
        });
	};

  
	componentDidMount() {
		const { t } = this.props; 
		//this.props.getNeoVentesAll();
		const obj={date:"", date2:"", dayWeekMonth:"", province:""}; 
		//this.props.getNeoVentesByPeople(obj);
		this.setState({ ["isLoaderOpen"]: true});  
		const this2=this; 
		Promise.resolve(this.props.getNeoB2bleadProspectsAllx(obj))
        .then((response)=>{ 
		  this.setState({ ["listOfSales"]: response.NeoB2bleadProspects, ["isLoaderOpen"]: false});
		  this.showToast(t("zqwCViewEl11_x"), "success"); 
		  console.log("weeeeeeeeeeeeeee ", response); 
          return response;
        }).catch(function(error){ 
          this2.setState({ ["isLoaderOpen"]: false});  
          alert(error);
        });
	}


	showToast = (arg, toastType) => {
		//toastType: success or error or warning or info
		const toastrDuration=this.state.toastrDuration; //5500	 
		this.setState({  
			toastrMessage: arg, 
		  toastrType: toastType
		  });
		setTimeout(() => { 
		  this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		  }); 
		}, toastrDuration);
	}
	
	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.NeoB2bleadProspects;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteNeoB2bleadProspects(gridIds[0]);

	}

  updateXRecordsMain(selectedRows){  
    const { t } = this.props;
	const objList  = this.props.NeoB2bleadProspects;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
      gridIndexes.push(row.index); 
      idIndex=row.index; 
	return null;
    });
 
    if(gridIndexes.length>1){
      alert(`{t('zqwgridIndexesfound_x')}`);
      return false;
    }
 
    let obj=objList[idIndex]; 
	this.props.getNeoB2bleadProspectsGoto(obj.id); 
	//history.push(`/neob2bleadprospects/${obj.id}/edit`); 
  }
  

  render() { 
    const { t } = this.props;
	const dataSales=this.state.listOfSales;

	const columns = [
	//  {
	//   name: "id", label: t('zqwid_x'),
	//   options: { filter: true, sort: true,}
	//  },
	 {
	  name: "quanity", label: t('zqwquantity_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "total", label: "Total",
	  options: { filter: true, sort: true,}
	 },	 
	 {
	  name: "created", label: t('zqwdate_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
		name: "vendor", label: t('zqwCViewEl29Q_x'),
		options: { filter: true, sort: true,}
	   },	 
	];
const textLabelOptions= {
    body: {
      noMatch: t('zqwgridnoMatch_x'),
      toolTip: t('zqwgridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('zqwgridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('zqwgridnext_x'),
      previous: t('zqwgridprevious_x'),
      rowsPerPage: t('zqwgridrowsPerPage_x'),
      displayRows: t('zqwgriddisplayRows_x'),
    },
    toolbar: {
      search: t('zqwgridsearch_x'),
      downloadCsv: t('zqwgriddownloadCsv_x'),
      print: t('zqwgridprint_x'),
      viewColumns: t('zqwgridviewColumns_x'),
      filterTable: t('zqwgridfilterTable_x'),
    },
    filter: {
      all: t('zqwgridall_x'),
      title: t('zqwgridftitle_x'),
      reset: t('zqwgridreset_x'),
    },
    viewColumns: {
      title: t('zqwgridvctitle_x'),
      titleAria: t('zqwgridvctitleAria_x'),
    }, 
  };	
			
	const options = {
	  filterType: 'checkbox',
	  textLabels: textLabelOptions,
	  onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		//console.log(currentRowsSelected, allRowsSelected);
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log(allRowsSelected);
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} isEdit={true} isDelete={true} />
      ),
	};  

	const myToastMessage=this.state.toastrMessage;
	const toastrType=this.state.toastrType;
	//const listOfSales=this.state.listOfSales;
	  
    if(dataSales.length) {                                
      return (
        <div>
          <h4>{t('zqwNeoB2bleadProspectsrep_x')}</h4>
		  <div className="form-group"> 
		  	<div className="input-group">
				<input type="date" name="weeksatrt"  onChange={this.handleChange} 
				className={'form-control'} placeholder={t("zqwCViewEl34_x")} /> 
				&nbsp; &nbsp;  
				<input type="date" name="weekend"  onChange={this.handleChange} 
				className={'form-control'} placeholder={t("zqwCViewEl35_x")} />
				&nbsp; &nbsp; 
				<select name="groupby" className={'form-control'} 
              	defaultValue={"day"}  onChange={this.handleChange} > 
                <option value="day"> {t("zqwCViewEl36_x")} </option>
                <option value="week">{t("zqwCViewEl37_x")} </option>
                <option value="month"> {t("zqwCViewEl38_x")} </option>  
              </select>
			  {/* &nbsp; &nbsp; 
				<select name="groupbyprovince" className={'form-control'} 
              	defaultValue={""}  onChange={this.handleChange} > 
                <option value=""> {t("zqwCViewEl39_x")} </option>
				<option value="all"> {t("zqwCViewEl40_x")} </option>
				<option value="QC"> {t("zqwCViewEl41_x")} Québec </option>
                <option value="ON">{t("zqwCViewEl41_x")} Ontario </option> 
              </select> */}
			  &nbsp; &nbsp; 
				<button type="button" className="btn btn-primary" onClick={this.handleLoadData}>{t("zqwCViewEl42_x")}</button>   
			</div>	
          </div>		  
		  <MUIDataTable
			  title={""}
			  data={dataSales}
			  columns={columns}
			  options={options}
			/>
			<Loader  openx={this.state.isLoaderOpen}  />
		  	<Toastr messagex={myToastMessage} open={true} toastType={toastrType} />
        </div>
      )    
    } else {
      return (<div>{t('zqwgridNoRecordfound_x')}  <Loader  openx={this.state.isLoaderOpen}  /></div>)
    }  
  }
}


const mapStateToProps = (state) => ({ NeoB2bleadProspects: state.NeoB2bleadProspects });  
const mapDispatchToProps = { addOrUpdateNeoB2bleadProspects, getNeoB2bleadProspects, getNeoB2bleadProspectsAll, getNeoB2bleadProspectsAllx, getNeoB2bleadProspectsGoto, deleteNeoB2bleadProspects };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoB2bleadProspectsList));
