import axios from "axios";
import history from "../history";
import { API_DOMAIN } from "../appConfig";
import { axiosDefaults } from "../appConfigaxios";
import productInfoMultiple from "../appProductInfoMulti";

export const RECEIVE_NEOVENTES = "GET_NEOVENTES";
export const ADD_NEOVENTES = "ADD_NEOVENTES";
export const RECEIVE_NEOVENTESX = "RECEIVE_NEOVENTESX";
export const REMOVE_NEOVENTES = "REMOVE_NEOVENTES";
export const UPDATE_NEOVENTES = "UPDATE_NEOVENTES";
export const REPLACE_NEOVENTES = "REPLACE_NEOVENTES";
export const RECEIVE_NEOCLIENTSX = "RECEIVE_NEOCLIENTSX";
export const RECEIVE_NEOADDRESSX = "RECEIVE_NEOADDRESSX";
export const RECEIVE_NEOCODEPROMOX = "RECEIVE_NEOCODEPROMOX";
const apiUrl = API_DOMAIN + "/neoventes";
//const apiUrlProduct = API_DOMAIN + '/products';
const apiUrlProduct = API_DOMAIN + "/productlist";
const apiUrlPeoplesales = API_DOMAIN + "/salesplanning/peoplesales";
const apiUrlMulti = apiUrl + "/multiactioninvoice";
const apiUrlBase = API_DOMAIN;
const apiUrl2 = API_DOMAIN + "/b2b/clients";

axiosDefaults(null);

export const getNeoVentesAll = () => {
  //const user=(localStorage.getItem("user")).split(':');
  return (dispatch) => {
    return axios
      .get(`${apiUrl}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesAllPending = () => {
  const status = "sent,partial";
  return (dispatch) => {
    return axios
      .get(`${apiUrl}?status=${status}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesAllXStaus = (statusList) => {
  let status = "payed";
  if (statusList !== "") {
    status = statusList;
  }
  return (dispatch) => {
    return axios
      .get(`${apiUrl}?status=${status}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesAllNew = (statusList, filter = []) => {
  let status = "payed";
  if (statusList !== "") {
    status = statusList;
  }
  return (dispatch) => {
    return axios
      .get(`${apiUrl}?status=${status}`,{params: {
        filter: JSON.stringify(filter)
      }})
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesAllXStaus2 = (statusList, invoiceGroup) => {
  let status = "payed";
  if (statusList !== "") {
    status = statusList;
  }
  return (dispatch) => {
    return axios
      .get(`${apiUrl}?status=${status}&upsell=${invoiceGroup}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesAllXStausx = (statusXObj) => {
  let status = "payed";
  const statusList = statusXObj.statusList;
  if (statusList !== "") {
    status = statusList;
  }
  let restParam = "";
  if (statusXObj.invoiceGroup !== undefined) {
    restParam += "&upsell=" + statusXObj.invoiceGroup;
  }
  if (statusXObj.conflict !== undefined) {
    restParam += "&searchConflict=" + statusXObj.conflict;
  }
  return (dispatch) => {
    return axios
      .get(`${apiUrl}?status=${status}${restParam}`)
      .then((response) => {
        //console.log("getNeoVentesAllXStausx-POSTACTION ", response);
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesAllXStausx2 = (statusXObj) => {
  let status = "payed";
  const statusList = statusXObj.statusList;
  if (statusList !== "") {
    status = statusList;
  }
  let restParam = "";
  if (statusXObj.invoiceGroup !== undefined) {
    restParam += "&upsell=" + statusXObj.invoiceGroup;
  }
  if (statusXObj.conflict !== undefined) {
    restParam += "&searchConflict=" + statusXObj.conflict;
  }

  if (statusXObj.page !== undefined) {
    restParam += "&page=" + statusXObj.page;
  }
  if (statusXObj.fieldName !== undefined) {
    restParam += "&fieldName=" + statusXObj.fieldName;
  }
  if (statusXObj.fieldVal !== undefined) {
    restParam += "&fieldVal=" + statusXObj.fieldVal;
  }
  if (statusXObj.specialSWA !== undefined) {
    restParam += "&specialSWA=" + statusXObj.specialSWA;
  }
  if (statusXObj.mySpecialSWA !== undefined) {
    restParam += "&mySpecialSWA=" + statusXObj.mySpecialSWA;
  }

  return (dispatch) => {
    return axios
      .get(`${apiUrl}?status=${status}${restParam}`)
      .then((response) => {
        //console.log("getNeoVentesAllXStausx-POSTACTION ", response);
        //return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data.data});
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// /chargedesk/checkstatus
export const getChargeDeskStatus = (chargeId) => {
  //const status="sent,partial";
  return (dispatch) => {
    return axios
      .get(`${API_DOMAIN}/chargedesk/checkstatus?chargeId=${chargeId}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data.chargeInfo,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getOrSetMultiActionStatus = (objAction) => {
  //const status="sent,partial";  invoiceId  actiongo
  const actiongo = objAction.actiongo;
  const invoiceId = objAction.invoiceId;
  return (dispatch) => {
    return (
      axios
        .get(`${apiUrlMulti}?invoiceId=${invoiceId}&actiongo=${actiongo}`)
        .then((response) => {
          console.log("GOreeeeeeeeeeeeeeeeeeeeeeeesp info");
          console.log(response);
          return dispatch({
            type: RECEIVE_NEOVENTESX,
            NeoVentesOne: response.data.data,
          });
        })
        .catch((err) => {
          if (err.response) {
            // client received an error response (5xx, 4xx)
            console.error(err);
          } else if (err.request) {
            // client never received a response, or request never left
            console.log("error falls here #2");
            const errMessage =
              "client never received a response, or request never left";
            console.error(errMessage);
          } else {
            // anything else
            console.log("error falls here #3");
            console.log(err);
            console.error(err);
          }
        })
    );
  };
};

export const addNeoVentes = (NeoVentes) => {
  const auth_id = 38;
  //return axios.post(`${apiUrl}/sales?auth_id=${auth_id}`, NeoVentes)
  return (dispatch) => {
    return axios
      .post(`${apiUrl}/sales`, NeoVentes)
      .then((response) => {
        let data = response.data.data;
        const mydataAr = [];
        mydataAr.push({
          id: data.id,
          transaction: data.transaction,
          // name: data.name,
          // description: data.description,
          // amount: data.amount,
        });

        return dispatch({ type: ADD_NEOVENTES, NeoVentes: data });
      })
      .then((response) => {
        //history.push("/NeoVentes")
        return response;
      })
      .catch((err) => {
        throw err
      });
  };
};

export const getNeoVentes = (id) => {
  return (dispatch) => {
    return axios
      .get(`${apiUrl}/${id}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOVENTESX,
          NeoVentesOne: response.data.neoventes,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesByPeople = (obj) => {
  //const user=(localStorage.getItem("user")).split(':');
  const date = obj.date;
  const date2 = obj.date2;
  const groupByDayWeekMonth = obj.dayWeekMonth;
  const province = obj.province;
  const params = `date=${date}&date2=${date2}&grpby=${groupByDayWeekMonth}&province=${province}`;
  //return axios.get(`${apiUrlPeoplesales}?date=${date}&date2=${date2}&grpby=${groupByDayWeekMonth}`)
  return (dispatch) => {
    return axios
      .get(`${apiUrlPeoplesales}?${params}`)
      .then((response) => {
        //return dispatch({type: RECEIVE_NEOVENTES, NeoVentes: response.data.data});
        return dispatch({
          type: RECEIVE_NEOVENTESX,
          NeoVentesOne: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getClientTransactionsFullInfo = (obj) => {
  const id = obj.clientId;
  return (dispatch) => {
    return axios
      .get(`${apiUrlBase}/neofoldersfullinfo?clientId=${id}`)
      .then((response) => {
        //console.log("getClientTransactions", response); //response.data.data.ndifields
        //return dispatch({type: RECEIVE_NDIFIELDS, NdiFields: response.data.data});
        return dispatch({
          type: RECEIVE_NEOVENTESX,
          NeoVentesOne: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getClientPaymentsFullInfo = (obj) => {
  //const id=obj.clientId;
  const id = isNaN(obj.clientId) ? 0 : obj.clientId;
  const id2 = obj.ticketId;
  return (dispatch) => {
    return axios
      .get(`${apiUrlBase}/neofolderspaymentlist?clientId=${id}&ticketId=${id2}`)
      .then((response) => {
        console.log("getClientTransactions", response); //response.data.data.ndifields
        //return dispatch({type: RECEIVE_NDIFIELDS, NdiFields: response.data.data});
        return dispatch({
          type: RECEIVE_NEOVENTESX,
          NeoVentesOne: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getGenAndCheckTicket = (obj) => {
  //const id=obj.clientId;
  const id = isNaN(obj.invoiceId) ? 0 : obj.invoiceId;
  const id2 = obj.transactionId;
  const ups = obj.upsellof;
  const stat = "yes";
  return (dispatch) => {
    return axios
      .get(
        `${apiUrl}?genTicket=${stat}&invoiceId=${id}&transactionId=${id2}&upsellof=${ups}`
      )
      .then((response) => {
        console.log("getClientTransactions", response); //response.data.data.ndifields
        //return dispatch({type: RECEIVE_NDIFIELDS, NdiFields: response.data.data});
        return dispatch({
          type: RECEIVE_NEOVENTESX,
          NeoVentesOne: response.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log(err);
          console.error(err);
        } else if (err.request) {
          // client never received a response, or request never left
          const errMessage =
            "client never received a response, or request never left";
          console.error(errMessage);
        } else {
          // anything else
          console.log("error falls here #3");
          console.log(err);
          console.error(err);
        }
      });
  };
};

export const getClientSearch = (serachObj) => {
  const auth_id = 38;
  const search = serachObj.search;
  const searchEmail = serachObj.searchEmail;
  const clientId = serachObj.clientId !== undefined ? serachObj.clientId : 0;
  let searchURL = "";
  if (clientId > 0) {
    searchURL = `${apiUrl}/searchclients?search=${search}&clientId=${clientId}`;
  } else if (searchEmail === "") {
    searchURL = `${apiUrl}/searchclients?search=${search}`;
  } else {
    searchURL = `${apiUrl}/searchclients?search=${search}&searchEmail=${searchEmail}`;
  }

  return (dispatch) => {
    return axios
      .get(searchURL)
      .then((response) => {
        if (response.data.data.length == 0) {
          return dispatch({ type: RECEIVE_NEOCLIENTSX, NeoClientsOne: [] });
        }
        return dispatch({
          type: RECEIVE_NEOCLIENTSX,
          NeoClientsOne: response.data.data.neoclients,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getB2BClientSearch = (serachObj) => {
  const auth_id = 38;
  const search = serachObj.search;
  const searchEmail = serachObj.searchEmail;
  let searchURL = "";
  if (searchEmail === "") {
    searchURL = `${apiUrl2}/search?search=${search}`;
  } else {
    searchURL = `${apiUrl2}/search?search=${search}&searchEmail=${searchEmail}`;
  }
  return (dispatch) => {
    return axios
      .get(searchURL)
      .then((response) => {
        if (response.data.data.length == 0) {
          return dispatch({ type: RECEIVE_NEOCLIENTSX, NeoClientsOne: [] });
        }
        return dispatch({
          type: RECEIVE_NEOCLIENTSX,
          NeoClientsOne: response.data.data.neoclients,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getClientAddress = (clientId) => {
  const auth_id = 38;
  return (dispatch) => {
    return axios
      .get(`${apiUrl}/clientaddress?clientId=${clientId}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOADDRESSX,
          NeoAddressOne: response.data.data,
        }); //response.data.data.neoaddress
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getClientAndAddress = (clientId) => {
  const auth_id = 38;
  return (dispatch) => {
    return axios
      .get(`${apiUrlBase}/clientinfo?clientId=${clientId}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOADDRESSX,
          NeoAddressOne: response.data.data,
        }); //response.data.data.neoaddress
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getClientAndAddressByLead = (clientId, leadId) => {
  const auth_id = 38;
  ///clientinfo?clientId=${clientId}&leadId=${leadId}
  return (dispatch) => {
    return axios
      .get(`${apiUrlBase}/clientinfo?leadId=${leadId}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOADDRESSX,
          NeoAddressOne: response.data.data,
        }); 
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getCodePromo = (code) => {
  const auth_id = 38;
  return (dispatch) => {
    return axios
      .get(`${apiUrl}/voucher?search=${code}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOCODEPROMOX,
          NeoCodePromoOne: response.data.data.neovoucher,
        }); //response.data.data.neoaddress
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesPromos = () => {
  const auth_id = 38;
  return (dispatch) => {
    return axios
      .get(`${apiUrl}/vouchers`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOCODEPROMOX,
          NeoCodePromoOne: response.data.data.neovouchers,
        }); //response.data.data.neoaddress
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getProductInfo = (id, lang) => {
  const arrProd = [6, 8, 9];

  if (arrProd.includes(parseInt(id))) {
    const response = productInfoMultiple(lang, id);
    return (dispatch) => {
      return dispatch({
        type: RECEIVE_NEOCODEPROMOX,
        NeoCodePromoOne: response,
      });
    };
  } else {
    return (dispatch) => {
      return axios
        .get(`${apiUrl}/xtraproductinfo?productId=${id}&lang=${lang}`)
        .then((response) => {
          return dispatch({
            type: RECEIVE_NEOCODEPROMOX,
            NeoCodePromoOne: response.data,
          }); //response.data.data.neoaddress
        })
        .catch((error) => {
          console.error(error);
        });
    };
  }
};

export const getAllProducts = (objProd) => {
  //const user=(localStorage.getItem("user")).split(':');
  const objProdx = { lang: "fr", upsell: "yes" };
  let xtraParams = "";
  if (objProd === undefined || objProd === null) {
    objProd = objProdx;
  }
  if (objProd.reasons !== undefined) {
    xtraParams = "&reasons=" + objProd.reasons;
  }

  const lang = objProd.lang;
  const upsell = objProd.upsell;
  return (dispatch) => {
    return axios
      .get(`${apiUrlProduct}?upsell=${upsell}&lang=${lang}${xtraParams}`)
      .then((response) => {
        //console.log("response getAllProducts from server .... ");  console.log(response);
        return dispatch({
          type: RECEIVE_NEOVENTES,
          NeoVentes: response.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getPartialInvoicePayments = (id) => {
  return (dispatch) => {
    return axios
      .get(`${apiUrl}/invoicepayments?invoiceId=${id}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOCODEPROMOX,
          NeoCodePromoOne: response.data,
        }); //response.data.data.neoaddress
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const checkActionsOnInvoice = (objInvoice) => {
  const id = objInvoice.id;
  const action = objInvoice.action;
  return (dispatch) => {
    return axios
      .get(`${apiUrl}/invoicepayments?invoiceId=${id}&actiongo=${action}`)
      .then((response) => {
        return dispatch({
          type: RECEIVE_NEOCODEPROMOX,
          NeoCodePromoOne: response.data,
        }); //response.data.data.neoaddress
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getNeoVentesGoto = (id) => {
  return (dispatch) => {
    return history.push(`./NeoVentes/${id}/edit`);
  };
};
export const deleteNeoVentes = (id) => {
  return (dispatch) => {
    return axios
      .delete(`${apiUrl}/delete/${id}`)
      .then((response) => {
        dispatch({ type: REMOVE_NEOVENTES, payload: { id } });
      })
      .then(() => {
        history.push("/NeoVentes");
      })
      .catch((error) => {
        console.error(error);
      });
  };
};
export const updateNeoVentes = (NeoVentes) => {
  const id = NeoVentes.id;
  return (dispatch) => {
    return axios
      .patch(`${apiUrl}/update/${NeoVentes.id}`, {
        name: NeoVentes.name,
        description: NeoVentes.description,
        amount: NeoVentes.amount,
      })
      .then((response) => {
        const data = response.data;
        dispatch({
          type: UPDATE_NEOVENTES,
          payload: {
            id: data.id,
            name: data.name,
            description: data.description,
            amount: data.amount,
          },
        });
        dispatch({
          type: REPLACE_NEOVENTES,
          payload: {
            id: data.id,
            name: data.name,
            description: data.description,
            amount: data.amount,
          },
        });
      })
      .then(() => {
        history.push(`./NeoVentes/${id}`);
      })
      .catch((error) => {
        throw error
      });
  };
};
export const addOrUpdateNeoVentes = (NeoVentes, actionMode) => {
  if (actionMode === 1) {
    return addNeoVentes(NeoVentes);
  } else {
    return updateNeoVentes(NeoVentes);
  }
};
