import React from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import {
  Alert,
  Badge,
  Row,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavLink,
  NavItem,
  FormGroup,
  Input,
  UncontrolledTooltip,
  Button,
  Spinner,
  TabContent,
  TabPane,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faBackward,
  faForward,
  faCalendarAlt,
  faInfoCircle,
  faUnlock,
  faUser,
  faCalendar,
  faClock,
  faQuestion
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import DashboardElement from "../../../../utils/DashboardElement";
import DownloadIcon from '@material-ui/icons/CloudDownload';

import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import _ from "lodash";
import axios from "axios";
import { API_DOMAIN } from '../../../../../appConfig'; 
import { axiosDefaults, getTokenAndId } from '../../../../../appConfigaxios'; 
import MuiAlert from '@material-ui/lab/Alert';
import Toastr from "../../../../../messages";
import  Loader from "../../../../../loader";
import Notes from "./Notes/Notes";
import history from '../../../../../history';
import trad from "../../../../trad/trad"; //import trad from './srcincorpo/trad/trad'; 
//import File from '../../portail_avocat/Pages/File/File';
//import FileTimeLineSuivi from '../../portail_avocat/Pages/File/FileTimeLineSuivi';
//File  src\srcincorpo\UPDATED_testament\inputComponents\portail_avocat\Pages\File\File.jsx

import rootsActions from "../../../../_actions/root-actions";
const QL_CURSOR = /\<span class=["']ql-cursor["']>\s*<\/span>/gm;
const DEFAULT_STYLE = {};
const BACKEND1 = process.env.REACT_APP_API_URLX;

var toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  // ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    display: 'inline-block',
    width: '100%',
    height: '32px',
    minHeight: '32px',
    maxHeight: '32px',
    textAlign: 'left',
  }),
  control: (provided) => ({
    ...provided,
    height: '32px',
    minHeight: '32px',
    maxHeight: '32px'
  }),
  input: (provided) => ({
    ...provided,
    minHeight: '5px',
    maxHeight: '5px'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingTop: '0',
    paddingBottom: '0',
    color: '#757575',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    minHeight: '1px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: '25px',
    fontSize: '10px'
    
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'inline-block',
    fontSize: '15px',
    top: '55%'
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'inline-block',
    position: 'absolute',
    fontSize: '15px',
    top: '50%'
  }),
};

//const URL = "https://apidoc.neodesk.app/api/v2"; 
const URL = API_DOMAIN;

class TimelineLeadTARO extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      lang: 'fr',
      activeTab: "1",
      loading: false,
      readOnly: false,
      wrongAgent: false,
      macros: [],
      value: {
        id: 'ca-01',
        value: 'ca-01',
        label: "Note privée",
      },
      notes: [],
      text: "<p></p>",
      raison: "",
      vente: null,
      reasons: [],
      postError: false,
      fetchError: '',
      rappelCreated: false,
      nextLead: '',
      dataAgentsDomains:{}, agentOptions:[], selectedAgent:{},
      isLoaderOpen:false, toastrMessage: '', toastrType: '', toastrDuration: 6000,
      neoZendNotes:[], 
    };

    //this.saveUkraine = this.saveUkraine.bind(this);
    //this.fetchNotes = this.fetchNotes.bind(this);
  }

  componentDidMount() {
    axiosDefaults(null);
    let ticketId=0;
    const this2=this;
    if(this.props.ticketId!=undefined){ ticketId=this.props.ticketId; }
    console.log("heeeeeeeeeeeeeee", this.props);

    
    axios
      .get(BACKEND1 + "/leads", {
        // params: {
        //   //src: "desk",
        //   id: this.props.id,
        //   showtrans: 3,
        // },
        
        params: {
          //src: "desk",
          ticket_id: this.props.ticket_idPopup,
          id: "0",
          taAsLeadSuivi: 3,
          showtrans: 3,
          leadreadonly: 3
        },
        

        // headers: {
        //   "Content-Type": "application/json",
        //   // 'x-jwt-token': localStorage.getItem('token'),
        //   "auth-id": localStorage.getItem("user_id"),
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // },
      })
      .then((status) => {
        let { data } = status.data; 
        if (data) {
          let neoPeopleList=null;
          console.log("data", data);
          if (data.neoPeopleList){ 
            //neoPeopleList=data.neoPeopleList;
            neoPeopleList = data.neoPeopleList.map(i => {
              return {label: i.label, value: i.label, agentId: i.value}
          });
          }
          if (data.lead.status === "3") this.setState({readOnly: true});
          if (data.lead.id_user !== localStorage.getItem("user_id")) this.setState({wrongAgent: true});
          const reasons = data.macros
            .filter((i) => i.type === "leadReason" && i.title !== "Vente")
            .map((i) => {
              return { label: { en: i.title_en, fr: i.title }, value: i.id };
            });
          if (data.lead && data.lead.extraction) {
            let obj = {
              // ...data.lead.extraction,
              ticket: data.lead.ticket_id,
              leadinfo: { ...data.lead },
              leadHistory: data.leadhistory,
            };
            
            // if(data.lead.type_for_lead!==undefined && data.lead.type_for_lead==="suivi"){
            //   obj.leadSuivi = {type_for_lead: data.lead.type_for_lead,
            //     ticket: data.lead.ticket_id};
            //     this.handleGetZendeskNOtes(data.lead.ticket_id);
            // }

            // console.log("heeeeeeeeeeeeeee ticket_idPopup", this.props.ticket_idPopup);
            // this.handleGetZendeskNOtes(this.props.ticket_idPopup);

            if (data.clientinfo !== null) {
              if (data.clientinfo.client) {
                if (!obj.clientEmail && data.clientinfo.client.email) obj.clientEmail = data.clientinfo.client.email;
                if (!obj.fullName && data.clientinfo.client.firstname && data.clientinfo.client.lastname) obj.clientFullName = `${data.clientinfo.client.firstname} ${data.clientinfo.client.lastname}`;
                if (!obj.clientPhone && data.clientinfo.client.cell) obj.clientPhone = data.clientinfo.client.cell;
              }
              obj.type = "client";
              obj.transactions = data.clientinfo.transactions;
              obj.clientId = data.clientinfo.client_id;
              obj.zendesk_id = data.clientinfo.client.id_zendesk;
            if (data.clientinfo.address) {
              let str = "";
              try  {
                str += data.clientinfo.address.address;
              } catch {str += ""}
              try  {
                str += `, ${data.clientinfo.address.city}`;
              } catch {str += ""}
              try {
                str += `, ${data.clientinfo.address.province}`;
              } catch {str += ""}
              obj.clientAddress = str;
            }
            }
            else if (data.clientinfo === null && data.contactinfo !== null) {
              obj.type = "contact";
              obj = { ...obj, ...data.contactinfo };
            } else if (!data.clientinfo) obj.transactions = [];
              else {
              if (data.lead.extraction){
                obj.type = 'client'
                obj.clientFullName = data.lead.extraction.clientFullName;
                obj.clientEmail = data.lead.extraction.clientEmail;
                obj.clientPhone = data.lead.extraction.clientPhone;
              }
            }
            this.props.setCustomer(obj);
          }
          this.genLead();
          this.setState({
            ...data,
            reasons: reasons,
            neoPeopleList:neoPeopleList, 
            // ...JSON.parse(data.definition)
          });
        }
      }).catch(error => {
          if (error && error.response && error.response.data) {
           this.setState({fetchError: error.response.data})
          } 
      });
      

      console.log("heeeeeeeeeeeeeee ticket_idPopup", this.props.ticket_idPopup);
      this.handleGetZendeskNOtes(this.props.ticket_idPopup);

    /*window.addEventListener("message", this.handleCreateCalendarNote);
    this.interval = setInterval(
      this.fetchNotes,
      process.env.FETCH_TIME || 30000
    );*/

    axios.get(
      BACKEND1+'/leads',
      //BACKEND1+'/leads/leads',
      {
          params: {
            agentTransfer: "yes"
              // showtrans: 3
              // page:2,
              // limit:6,
              // newlead:1 pour obtenir des un nouveau lead
          },
          //params:params, 
      }
  )
  .then((status) => {
      let {data} = status.data;
      //const dataAll= this.state.dataRawList; 
      if(data && data.agentList){
          let agentList= data.agentList; 
          const lead=this.state.lead; 
          if(lead && lead.type_for_lead==="suivi" && data.agentList2){
            agentList= data.agentList2; 
          } 

          console.log("leeeeeeeeeeeeeeeeead ", lead);

          /*
          const domaineOptions = data.domainList.map(i => {
              return {label: i.label_fr, value: i.label_fr, id: i.id, name: i.name}});
          // const domaineOptions = data.domainList.map(i => {
          //     return {en: i.label_en, fr: i.label_fr, value: i.name, id: i.id, name: i.name}});
          */
          const agentOptions = agentList.map(i => {
              return {label: i.label, value: i.label, agentId: i.value}
          });
          // agentOptions.unshift({label: 'Aucun Agent/Enlever Agent', value: null, name: null, agentId: null})
          agentOptions.push({label: 'Aucun/None', value: 'Aucun/None', agentId: ''});
          this.setState({
               dataAgentsDomains: data,
              agentOptions: agentOptions
          });
          this.setState({ ["isLoaderOpen"]: false});  
          //thermo:e.thermo,
      }
  }
  ).catch(function(error){ 
    if (error.response){
      console.log("error object:", error.response.data)
      this2.setState({postErrorMsg: error.response.data}) 
    }
  });
  }

  

  componentWillUnmount() {
    //clearInterval(this.interval);
    //window.removeEventListener("message", this.handleCreateCalendarNote);

  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  async fetchNotes() {
    const { data } = await axios.get(BACKEND1+"/leads", {
      params: {
        //src: "desk",
        id: this.props.id,
        leadreadonly: "3"
      },
      // headers: {
      //   "Content-Type": "application/json",
      //   // 'x-jwt-token': localStorage.getItem('token'),
      //   "auth-id": localStorage.getItem("user_id"),
      //   Authorization: "Bearer " + localStorage.getItem("token"),
      // },
    });
    const is_same = this.state.notes.length === data.data.notes.length;
    if (is_same !== true) {
      this.setState({ notes: data.data.notes });
    } 
  }

  handleGetZendeskNOtes = (taId) => {
    console.log("GOOOOOOOOOOOOOOOOOOheeeeeeeeeeeeeee", taId);
    //return false; 
    //console.log("heeeeeeeeeeeeeee", this.props);
    // .get(BACKEND1 + "/GetFilterBy/notesByFolderId/"+taId, 
    // .get("https://api.neolegal.ca/GetFilterBy/notesByFolderId/"+taId,
    axios
      .get("https://neobackendstaging-kagyonahja-nn.a.run.app/GetFilterBy/notesByFolderId/"+taId, {
        params: { 
          //id: this.props.id,
          showtrans: 3,
        },
      })
      .then((status) => {
        let { data } = status.data; 
        if (data) {
          let neoPeopleList=null;
          //console.log("TIIIIIICKEEEEETdata", data);
          console.log("GOOOOOOOOOOOOOOOOOOheeeeeeeeeeeeeee TIIIIIICKEEEEETdata ", taId);
          //console.log("TIIIIIICKEEEEETdata-notes", data.list.notes);
          //console.log("TIIIIIICKEEEEETdata-get", data.get);
          //console.log("TIIIIIICKEEEEETdata-get2", data.data.get);
          //notes  id_zendesk attachments  
          const neoZendNotes= [];
          if (data.list && data.list.notes) {
              console.log("GOOOOOOOOOOOOOOOOOOheeeeeeeeeeeeeee TIIIIIICKEEEEETdata a.1 ", taId);
             for(let note of data.list.notes){
              if(note.id_zendesk && note.id_zendesk>0){

                neoZendNotes.push(note);
              }
              //console.log("GOOOOOOOOOOOOOOOOOOheeeeeeeeeeeeeee TIIIIIICKEEEEETdata a.2 ", note.id, note.id_zendesk);
             }
             if(neoZendNotes.length>0){
              this.setState({
                neoZendNotes:neoZendNotes
              });
              console.log("GOOOOOOOOOOOOOOOOOOheeeeeeeeeeeeeee CONGRATS for zend t=", neoZendNotes.length);
             }
          }

        }
      }).catch(error => {
          // if (error && error.response && error.response.data) {
          //  this.setState({fetchError: error.response.data})
          // } 

          console.log("GOOOOOOOOOOOOOOOOOOheeeeeeeeeeeeeee error", error.response.data);
      });

  };



  onChange(e) {
    this.setState({
      value: e,
      text: e.html
    });
  }

  
 

  genLead = () => {
    const leads = JSON.parse(sessionStorage.getItem('leads'));
    console.log(leads)
    if (leads && leads.length) {
        const curIdx = _.findIndex(leads, (i) => i === this.props.id) + 1;
        if (curIdx < leads.length) {
            this.setState({nextLead: leads[curIdx]})
        } else if (curIdx === leads.length) {
            this.setState({nextLead: leads[0]})
        } 
      }
  }

  genLang = (text) => {
    //const {trad, lang} = this.props;
    const {lang} = this.props;
    return trad['neoleads'][text][lang]
  }

  urlify = (text) => { 
    //var urlRegex = /(https?:\/\/[^\s]+)/g;
    //var urlRegex = /(https?:\/\/[^\s]+[^>]?)/g;
    var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }
  handleSelectChange = (e, field) => {
    // console.log(e);
    //this.setState({ value: e, text: e.html});
    console.log("handleSelectChange ", field,  e);  
    //.setState({ field: e.value,});
    this.setState({ [""+field]: e,}); //this.setState({ field[""+field]: e,});
    //{label: 'Sarah Meunier', value: 'Sarah Meunier', agentId: '12455'}

  }

  showToast = (arg, toastType) => {
		//toastType: success or error or warning or info
		const toastrDuration=this.state.toastrDuration; //5500	 
		this.setState({  
			toastrMessage: arg, 
		  toastrType: toastType
		  });
		setTimeout(() => { 
		  this.setState({ 
			toastrMessage: "", 
			toastrType: 'success'
		  }); 
		}, toastrDuration);
	}

  Alert  = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  genDownloadURL = (url, filename, bucket) => {
    const tokobj=getTokenAndId();
    const token=tokobj.token;
    const userid=tokobj.userid;
    return (
      URL +
      "/files/getfile?url=" +
      url +
      "&token=" + token +
      //localStorage.getItem("token") +
      "&action=download" +
      "&filename=" +
      filename +
      "&bucket=" +
      bucket +
      "&auth_id=" + userid
      //AUTH_ID.replace(/\s+/g, "")
    );
  };

  

  render() {
    let { props } = this;
    let macros = this.state.macros.filter(i => i.type !== 'leadReason');
    let notesx = this.state.notes || [];
    const lead= this.state.lead;
    const neoZendNotes= this.state.neoZendNotes;
    const attachmentsDocs=[];
    let notes = [];

    if(neoZendNotes && neoZendNotes.length>0){ 
      //notes.push.apply(notes, neoZendNotes);
      notes = notesx.concat(neoZendNotes); 
      console.log("GOOOOOOOOOOOOOOOOOOheeeeeeeeeeeeeee CONGRATS APPLY for zend t=", neoZendNotes.length);

      //points.sort(function(a, b){return b-a}); 
      notes.sort(function(a, b){
        //const strStart = moment.utc(start).local().format("YYYY-MM-DD HH:mm");
        //const strEnd = moment.utc(end).local().format("YYYY-MM-DD HH:mm");
        const date1 = moment(a.date_created);
        const date2 = moment(b.date_created);
        return ((date1 < date2) ? 1 : (date1 > date2) ? -1 : 0);
        //return ((date2 < date1) ? 1 : (date2 > date1) ? -1 : 0);
        // date_created
        // return b-a
      }); 

      // let sortedProducts = products.sort(
      //   (p1, p2) => (p1.price < p2.price) ? 1 : (p1.price > p2.price) ? -1 : 0);


      //const { id, note, date, user, noteObj } = this.props;
      //const attachmentsDocs=[]; let keyID=noteObj.id; //0;
      for (let noteObj of notes) {
        if(noteObj &&  noteObj.attachments!==undefined && noteObj.attachments.length>0){
          //keyID=noteObj.id; 
          for (let attachmentsx of noteObj.attachments) { 
              const file_name=attachmentsx.file_name; 
              const content_url=attachmentsx.content_url; 
              // if(file_name!="" && content_url!=""){  
              //     attachmentsDocs.push(<span>{file_name} <a target="_blank" href={content_url}><DownloadIcon style={{ fontSize: 20 }} /></a> &nbsp; </span>);
              // }
              if(file_name!="" && content_url!==null && content_url!==""){
                attachmentsDocs.push(<span>{file_name} <a target="_blank" href={content_url}><DownloadIcon style={{ fontSize: 20 }} /></a> &nbsp; </span>); 
              }else if(attachmentsx.value!="" && attachmentsx.bucket!=""){
                const downloadURL = this.genDownloadURL(attachmentsx.value, attachmentsx.file_name, attachmentsx.bucket);
                attachmentsDocs.push(<span>{file_name} <a target="_blank" href={downloadURL}><DownloadIcon style={{ fontSize: 20 }} /></a> &nbsp; </span>);
              }
          } 
        }
      }
    }else{
      notes = notesx;
      if(this.props.customer.leadSuivi  && this.props.customer.leadSuivi.type_for_lead==="suivi"){
        for (let noteObj of notes) {
          if(noteObj &&  noteObj.attachments!==undefined && noteObj.attachments.length>0){
            //keyID=noteObj.id; 
            for (let attachmentsx of noteObj.attachments) { 
                const file_name=attachmentsx.file_name; 
                const content_url=attachmentsx.content_url; 
                // if(file_name!="" && content_url!=""){  
                //     attachmentsDocs.push(<span>{file_name} <a target="_blank" href={content_url}><DownloadIcon style={{ fontSize: 20 }} /></a> &nbsp; </span>);
                // }
                if(file_name!="" && content_url!==null && content_url!==""){
                  attachmentsDocs.push(<span>{file_name} <a target="_blank" href={content_url}><DownloadIcon style={{ fontSize: 20 }} /></a> &nbsp; </span>); 
                }else if(attachmentsx.value!="" && attachmentsx.bucket!=""){
                  const downloadURL = this.genDownloadURL(attachmentsx.value, attachmentsx.file_name, attachmentsx.bucket);
                  attachmentsDocs.push(<span>{file_name} <a target="_blank" href={downloadURL}><DownloadIcon style={{ fontSize: 20 }} /></a> &nbsp; </span>);
                }
            } 
          }
        }
      }
    }

    //const agentOptions=[]; //this.state.agentOptions;
    const agentOptionsx2=this.state.agentOptions;
    const selectedAgent=this.state.selectedAgent;
    const neoPeopleList=this.state.neoPeopleList;
    let agentOptions={};
    if(neoPeopleList && neoPeopleList.length>0){ agentOptions=neoPeopleList; }else{
      agentOptions=agentOptionsx2;
    }
    //dataAgentsDomains agentOptions
    const handleSelectChange=this.handleSelectChange;
    const myToastMessage=this.state.toastrMessage;
	  const toastrType=this.state.toastrType;
    //agentOptions.push({label: 'Aucun/None', value: 'Aucun/None', agentId: ''});¨
    //const ticket_id=lead.ticket_id; //this.props.id 
    //const ticket_id=this.props.customer.ticket;
    const ticket_id=1066062;

    console.log("LEAAAAAAAAAAAAAAAD ", ticket_id, lead);

    // if(notes.length>0){
    //   //const html = this.urlify(text);
    //   //for(let note of notes){ }
    //   for(let i=0; i<notes.length; i++){
    //     //const html = this.urlify(notes[i].note);
    //     if(notes[i].note!==undefined){
    //       const htmlBlock = this.urlify(notes[i].note);
    //       notes[i].note=htmlBlock;
    //     }
    //   }

    // }

    if(this.state.redirect){
      return(
          <Redirect to={{pathname: this.state.redirect}} />
      )
    }
    if (props.customer && props.customer.conflict) {
      if (props.customer.conflict === "conflict") {
        macros = _.filter(macros, { type: "conflit" });
      }
    }

    return (
      <>
              
        {this.state.postError && <Alert color='danger' toggle={() => this.setState({postError: false})}><FontAwesomeIcon icon={faInfoCircle}/>{ `${this.genLang("fillOutAllFields")}`}</Alert>}
        {this.state.fetchError && <Alert color='danger'><FontAwesomeIcon icon={faInfoCircle}/>{` ${this.state.fetchError}`}</Alert>}
        {this.state.rappelCreated && <Alert color='success' toggle={() => this.setState({rappelCreated: false})}><FontAwesomeIcon icon={faInfoCircle}/>{` ${this.genLang('rappelCreated')}`}</Alert>}
        {this.state.readOnly && <Alert color='primary'><FontAwesomeIcon icon={faInfoCircle}/>{` ${this.genLang('leadClosedMessage')}`}</Alert>}
        {/* {this.state.wrongAgent && <Alert color='primary'><FontAwesomeIcon icon={faInfoCircle}/>{` ${this.genLang("leadNotAssigned")}`}</Alert>} */}
        
        
        
        {attachmentsDocs.length>0 && <DashboardElement
                        // classNameBody="p-0"
                        className="p-0"
                        col={12}
                        height={'auto'}
                        maxHeight={'auto'}
                        title={"RÉSUMÉ DE FICHIERS"}
                        toggle={true}
                        open={false}
                    >
                        <> 
                         {attachmentsDocs.length>0? 
                          <div className="messageArchivexX" key={"ooooooookcccccccc"}>{attachmentsDocs}</div>
                          :""
                        }
                        </>
                    </DashboardElement>}

        <Notes notes={notes}/>
        <Loader  openx={this.state.isLoaderOpen}  />
				<Toastr messagex={myToastMessage} open={true} toastType={toastrType} /> 
        
        
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  rootsActions: rootsActions,
  loading: state.loading.loading,
  request: state.request,
  customer: state.customer,
});

const mapActionsToProps = {
  setCustomer: rootsActions.customersActions.setUser,
  setConflict: rootsActions.customersActions.setConflict,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(TimelineLeadTARO));