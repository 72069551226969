import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCancel, faStar } from "@fortawesome/free-solid-svg-icons";
import { genLang } from "../../../../../composables/genLang";
import MenuIconButton from "../../../IconButton/MenuIconButton";
import { Tooltip } from "@mui/material";

const alertMap = {
  3: {
    title: genLang({
      en: "Marked as important by admin",
      fr: "Marqué comme important par l'administrateur",
    }),
    icon: faStar,
    color: "#FFD700",
    value: "3",
  },
  2: {
    title: genLang({
      en: "Call/Email/SMS inbound from client",
      fr: "Appel/E-mail/SMS entrant du client",
    }),
    icon: faBell,
    color: "#0076cf",
    value: "2",
  },
  default: {
    icon: null,
    title: genLang({ en: "No alert", fr: "Pas d'alerte" }),
    value: "1",
    color: "red",
  },
};

export default function AlertIconsToggle({ priority, handleChangeAlert, id }) {
  const generateMenuLabel = (priority) => (
    <div value={priority}>
      <FontAwesomeIcon
        icon={priority === "1" ? faCancel : alertMap[priority]?.icon}
        style={{
          color: alertMap[priority]?.color || alertMap?.default?.color,
          marginRight: "1rem",
        }}
      />
      {alertMap[priority]?.title || alertMap?.default?.title}
    </div>
  );

  const menuOptions = Object.values(alertMap)
    .filter((x) => x.value)
    .map(({ value }) => ({
      label: generateMenuLabel(value),
      value: value,
      onClick: () => handleChangeAlert(id, value),
    }));

  const alertDetails = alertMap[priority] || alertMap.default;

  return (
    <MenuIconButton
      size="lg"
      style={{ color: alertDetails.color }}
      icon={alertDetails.icon}
      tooltip={alertDetails.title}
      menuOptions={menuOptions}
    />
  );
}

export function genAlertIcon(priority) {
  if (!priority) return "";
  const alertDetails = alertMap[priority] || {};
  if (alertDetails?.icon) {
    return (
      <Tooltip title={alertDetails?.title}>
        <span>
          <FontAwesomeIcon
            size="lg"
            icon={alertDetails?.icon || null}
            style={{
              color: alertDetails?.color,
              marginRight: "0.3rem",
            }}
          />
        </span>
      </Tooltip>
    );
  }
  return "";
}
