import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOrUpdateZxCompanies, getZxCompanies, getZxCompaniesAll, getZxCompaniesGoto, deleteZxCompanies } from '../../actions/ZxCompaniesAction'; 
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "../CustomToolbarSelect";

class ZxCompaniesList extends Component {
	constructor(props) {
		super(props); 
		this.deleteXRecordsMain=this.deleteXRecordsMain.bind(this);
		this.updateXRecordsMain=this.updateXRecordsMain.bind(this);
		 
	}
  
	componentDidMount() { 
		this.props.getZxCompaniesAll();
	}
	
	
	deleteXRecordsMain(selectedRows){  
		const objList  = this.props.ZxCompanies;
		let gridIndexes=[]; let gridIds=[]; 
		selectedRows.data.map(row => {    
		  gridIndexes.push(row.index); 
		  return null;

		});
 
		gridIndexes.reverse(); 
		for (const index of gridIndexes) {
			const obj=objList[index];
			gridIds.push(obj.id); 
		  objList.splice(index, 1);
		} 
		this.props.deleteZxCompanies(gridIds[0]);

	}

  updateXRecordsMain(selectedRows){  
    const { t } = this.props;
	const objList  = this.props.ZxCompanies;
    let gridIndexes=[];  let idIndex=-1;   
    selectedRows.data.map(row => {  
      gridIndexes.push(row.index); 
      idIndex=row.index; 
	return null;
    });
 
    if(gridIndexes.length>1){
      alert(`{t('gridIndexesfound_x')}`);
      return false;
    }
 
    let obj=objList[idIndex]; 
	this.props.getZxCompaniesGoto(obj.id); 
	//history.push(`./zxcompanies/${obj.id}/edit`); 
  }
  

  render() { 
    const { t } = this.props;
	/*const columns = [
	 {
	  name: "id", label: t('id_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "name", label: t('name_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "joining_date", label: t('joining_date_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "address", label: t('address_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "phone", label: t('phone_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "dob", label: t('dob_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "zipcode", label: t('zipcode_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "suite", label: t('suite_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "city", label: t('city_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "state", label: t('state_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "cxc", label: t('Encargado_CxC'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "dv", label: t('company_code_x'),
	  options: { filter: true, sort: true,}
	 },
	];*/
	
	const columns = [
	 /*{
	  name: "cliente", label: t('cliente_x'),
	  options: { filter: true, sort: true,}
	 },*/
	 {
	  name: "name", label: t('nombre_x'),
	  options: { filter: true, sort: true,}
	 },
	 /*{
	  name: "Ruc_Cedula", label: t('Ruc_Cedula_x'),
	  options: { filter: true, sort: true,}
	 },*/
	 {
	  name: "dv", label: t('dv_x'),
	  options: { filter: true, sort: true,}
	 },
	 {
	  name: "cxc", label: t('Encargado_CxC_x'),
	  options: { filter: true, sort: true,}
	 }, 
	 {
	  name: "hab", label: t('habilitado_x'),
	  options: { filter: true, sort: true,}
	 }, 
	 {
	  name: "retax", label: t('Retencion_Impuesto_x'),
	  options: { filter: true, sort: true,}
	 },
	 /*{
	  name: "fecha_registro", label: t('fecha_registro_x'),
	  options: { filter: true, sort: true,}
	 },*/
	];
	
	//$listObj[]=array("id" => $cliente, "name" => $nombre, "dv" => $dv, "cxc" => $Encargado_CxC, 
	//"hab" => $habilitado, "retax" => $Retencion_Impuesto, "fecha" => $fecha_registro);
	
const textLabelOptions= {
    body: {
      noMatch: t('gridnoMatch_x'),
      toolTip: t('gridtoolTiph_x'),
      columnHeaderTooltip: column => `${t('gridSortfor_x')} ${column.label}`
    },
    pagination: {
      next: t('gridnext_x'),
      previous: t('gridprevious_x'),
      rowsPerPage: t('gridrowsPerPage_x'),
      displayRows: t('griddisplayRows_x'),
    },
    toolbar: {
      search: t('gridsearch_x'),
      downloadCsv: t('griddownloadCsv_x'),
      print: t('gridprint_x'),
      viewColumns: t('gridviewColumns_x'),
      filterTable: t('gridfilterTable_x'),
    },
    filter: {
      all: t('gridall_x'),
      title: t('gridftitle_x'),
      reset: t('gridreset_x'),
    },
    viewColumns: {
      title: t('gridvctitle_x'),
      titleAria: t('gridvctitleAria_x'),
    }, 
  };	
			
	const options = {
	  filterType: 'checkbox',
	  textLabels: textLabelOptions,
	  /*onRowsSelect: (currentRowsSelected, allRowsSelected) => {
		//console.log(currentRowsSelected, allRowsSelected);
		console.log(currentRowsSelected);  console.log(currentRowsSelected["id"]);
		console.log(allRowsSelected);
	  },
	  onRowClick: (rowData, rowState) => {
		//What can I add here?
		console.log(rowData, rowState);
	  },
	  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} 
		deleteXRecords={this.deleteXRecordsMain} updateXRecords={this.updateXRecordsMain} isEdit={true} isDelete={true} />
      ),*/
	};  
	  
    if(this.props.ZxCompanies.length) {                                
      return (
        <div>
          <h4>{t('ZxCompanies_x')}</h4>		  
		  <MUIDataTable
			  title={""}
			  data={this.props.ZxCompanies}
			  columns={columns}
			  options={options}
			/>
        </div>
      )    
    } else {
      return (<div>{t('gridNoRecordfound_x')}</div>)
    }  }
}


const mapStateToProps = (state) => ({ ZxCompanies: state.ZxCompanies });  
const mapDispatchToProps = { addOrUpdateZxCompanies, getZxCompanies, getZxCompaniesAll, getZxCompaniesGoto, deleteZxCompanies };       
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(ZxCompaniesList));
