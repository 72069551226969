import React, { Component } from 'react';
import { connect as connectrdx } from 'react-redux';
import { withTranslation } from 'react-i18next';
//import { connect } from "amazon-connect-streams"; 
import connect from "amazon-connect-streams";
//import connect from "./utils/connect-streams-min"; 
//let connect={};

import "amazon-connect-streams";

let connect2 = {};
//let connect;
// if(!connect){
// 	connect={};
// }

// const connect3=function(connect){return connect;};
// const connect=connect3(connect);

var PHONE_ENABLED = 1;
//any falsey value will hide the softphone
var MYPHONE = "+15142639900";
//i recommend to remove it during production for safety concerns
var queueArn = "arn:aws:connect:ca-central-1:519096278605:instance/b11aca54-6307-481e-82ce-3e01661fa0d5/queue/d2caa99c-4782-4a55-af82-60350bd17e2f";
//var containerDiv = document.getElementById("softphone"); 
var logged = false;
//there is a <div> with that #id in index.html
//var CCP_URL = 'https://neodesk.my.connect.aws/connect/ccp-v2/';
var CCP_URL = 'https://neolegal.my.connect.aws/connect/ccp-v2/';

// const
var NONE = 'none';
var BLOCK = 'block';
var CLICK = 'click';
var agent;
var NEOLEGAL_CONTACT = 'NEOLEGAL_contact';

const SOFTPHONE_CONFIG = {
    ccpLoadTimeout: 2500,
    language: 'fr-CA',
    lang: 'fr-CA',
    ccpUrl: CCP_URL, // REQUIRED
    loginPopup: false, // optional, defaults to `true`
    loginPopupAutoClose: true, // optional, defaults to `true`
    loginOptions: { // optional, if provided opens login in new window
        autoClose: false, // optional, defaults to `false`
        height: 400, // optional, defaults to 578
        width: 200, // optional, defaults to 433
        top: 0, // optional, defaults to 0
        left: 0 // optional, defaults to 0
    },
    // region: "eu-central-1",         // REQUIRED for `CHAT`, optional otherwise
    softphone: {
        height: 500, // optional
        allowFramedSoftphone: true, // optional
        disableRingtone: false, // optional
        ringtoneUrl: "./ringtone.mp3" // optional
    },
    pageOptions: { //optional
        enableAudioDeviceSettings: false,
        enablePhoneTypeSettings: true //optional, defaults to 'true' 
    }
};

class NeoAmazonCCP extends React.Component {
    constructor(props) {
        super(props);
        // this.state = {
        //   error: null,
        //   products: [],
        //   response: {}
        // }

        //this.isEdit=this.props.isEdit;
        //this.isDelete=this.props.isDelete;
    }

    componentDidMount() {
        //this._isMounted = true; 

        console.log("NeoAmazonCCP WEEEEEEEEEEEEEEEEEEEEE MOUNT ");
        // if(connect!==undefined){
        // 	//connect={};
        // 	console.log("NeoAmazonCCP connect ", connect);
        // }


        this.init();
    }

    //hidePhoneCCPIcon
    hidePhoneCCPIconx = () => {
        window["hidePhoneCCPIcon"]();
    };

    showPhoneCCPIconx = () => {
        window["showPhoneCCPIcon"]();
    };

    reopenPhoneCCP = () => {
        window["reopenPhoneCCP"]();
    };



    render() {
        //const { classes } = this.props;
        /*
        const { rawhtml } = this.props; 
        const rawhtmlContent=sessionStorage.getItem("tmpcontent");
        //sessionStorage.setItem("tmpcontent", info_otheresp);
        if(rawhtmlContent!==undefined && rawhtmlContent!==null && rawhtmlContent!==""){
        	return rawhtmlContent;  
        	//return { __html: rawhtmlContent   };
        }

        if(rawhtml!==undefined && rawhtml!==null && rawhtml!==""){
        	return rawhtml;
        }*/

        //return `<h1>from class props Hello world. Nothing to show</h1>`;

        //return `<h1>Phone CPP</h1>`;
        return ( < >
            <
            button onClick = { this.hidePhoneCCPIconx }
            type = "button"
            className = 'btn' > Hide < /button>  <
            button onClick = { this.showPhoneCCPIconx }
            type = "button"
            className = 'btn' > Show < /button>   <
            button onClick = { this.reopenPhoneCCP }
            type = "button"
            className = 'btn' > reopen < /button> < / >
        );

        return ( < div > < h1 > Phone CPP < /h1>  <
            button onClick = { this.hidePhoneCCPIconx }
            type = "button"
            className = 'btn' > Hide < /button>  <
            button onClick = { this.showPhoneCCPIconx }
            type = "button"
            className = 'btn' > Show < /button>   <
            button onClick = { this.reopenPhoneCCP }
            type = "button"
            className = 'btn' > reopen < /button> < /
            div >
        );
    }



    /* NEOLEGAL_CONTACT = 'NEOLEGAL_contact'; */

    createLoginUrl = function(params) {
        var redirect = "https://lily.us-east-1.amazonaws.com/taw/auth/code";
        connect.assertNotNull(redirect);

        if (params.loginUrl) {
            return params.loginUrl
        } else if (params.alias) {
            // return LOGIN_URL_PATTERN
            // .replace("{alias}", params.alias)
            // .replace("{client_id}", CLIENT_ID_MAP["us-east-1"])
            // .replace("{redirect}", global.encodeURIComponent(
            // 	redirect));
        } else {
            return params.ccpUrl;
        }
    };

    // const 

    clicktocall(phone) {

            if (!phone)
                return;

            var endpoint = connect.Endpoint.byPhoneNumber(phone);

            agent.connect(endpoint, {
                queueARN: queueArn,
                success: function(test) {

                    connect.contact(function(c) {
                            var attr = c.getAttributes();
                            var c1 = c.getConnections()[1];
                            var c2 = c.getStatus();

                            var display = document.getElementById('softphoneContainer').style.display
                            if (display === NONE)
                                this.toggleCPP(null);

                            if (attr.firstName) {
                                console.log("Here's your value for firstName  + attr.firstName.value");
                            }

                            if (attr.lastName) {
                                console.log("Here's your value for lastName  + attr.lastName.value");
                            }
                        })
                        // console.log("outbound call connected ====>",contacts);
                },
                failure: function(err) {
                    console.log("outbound call connection failed");
                    console.log(err);
                }
            });
        }
        //end click to call

    toggleAvailability(e) {

    }


    toggleCPP(e) {

        var hidden = document.getElementById('softphoneContainer').classList.contains('hidden');

        if (hidden) {
            document.getElementById('softphoneContainer').classList.remove('hidden')
        } else {
            document.getElementById('softphoneContainer').classList.add('hidden');
        }
    }

    init() {
        if (!PHONE_ENABLED)
            return false;

        var containerDiv = document.getElementById("softphone");
        console.log("daaaaaaaaaaaaaaaaaaaaaaata call CLICK  ====>", CLICK);
        document.getElementById('togglePhone').addEventListener(CLICK, this.toggleCPP);
        // contact.onError(function(contact) { console.log('magie magique') } )

        connect.core.initCCP(containerDiv, this.SOFTPHONE_CONFIG);
        // debugger;
        //fonction pour le click to call
        document.addEventListener(CLICK, function(e) {
            if (e.target && e.target.className == 'NEOLEGAL_PHONE') {

                if (!e.target.attributes.phone)
                    return console.error('please provide phone attibute to NEOLEGAL_PHONE className');

                this.clicktocall(e.target.attributes.phone.value);
            }
        });

        connect.contact(function(c) {

            var attr = c.getAttributes();
            var c1 = c.getConnections()[1];
            var c2 = c.getStatus();

            var hidden = document.getElementById('softphoneContainer').classList.contains('hidden');

            if (hidden)
                this.toggleCPP(null);

            // var softInfo = d
            // document.getElementById("softphoneInfo");
            var address = c1.getAddress();

            // softInfo.innerHTML = '';

            // for(var a in address){
            //   if(address[a] && typeof address[a] === 'string')
            //     softInfo.innerHTML += '<li>'+a+': '+address[a]+'</li>';
            // }

            // for(var b in attr){
            //   if(attr[b])
            //     softInfo.innerHTML += '<li>'+attr[b].name+'=>'+attr[b].value+'</li>';
            // }



            // Listen for the event.

            // Dispatch the event.

            let event = new Event(NEOLEGAL_CONTACT);

            event.data = {...attr, ...address };
            /* window.document.body.dispatchEvent(
            	event,{
            	// detail:attr,
            	bubbles: true,
            	cancelable: true,
            	composed: false,
            	}); */

            //document.getElementById('contactBand').dispatchEvent(
            //document.getElementById('contactBand').dispatchEvent(
            //this.element.dispatchEvent(
            window.document.body.dispatchEvent(
                event, {
                    // detail:attr,
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                });

            /* inner.dispatchEvent(new CustomEvent('test', {
            bubbles: true,
            cancelable: true,
            composed: false,
            detail: "not composed"
            })); */




        });
        // fin connect.contact

        var agent = new connect.agent(function(agent) {
            var statusTrack = 0;
            agent.onStateChange(function(agentStateChange) {
                document.getElementById('connected').classList.remove('notConnected');
                document.getElementById('connectLink').classList.add('notConnected');

                console.log("PHOOOOOOOOOOOOOOOOOOOOOOne agentStateChange ", agentStateChange);
                if (agentStateChange.newState === "Disponible") {
                    document.getElementById('togglePhone').classList.add('btn-success');
                    document.getElementById('togglePhone').classList.remove('btn-danger');
                    statusTrack++;
                    agent.onOffline(function(agent) { console.log('BIG MOMENT dispooooooooooooooooooooo ') });
                } else {
                    agent.onOffline(function(agent) { console.log('BIG SAD MOMENT else') });
                    document.getElementById('togglePhone').classList.add('btn-danger');
                    document.getElementById('togglePhone').classList.remove('btn-success');
                    statusTrack++;
                }
            });

            console.log("PHOOOOOOOOOOOOOOOOOOOOOOne ", agent);
            var state = agent.getState(); //agent.getStatus()
            var statusAgent = agent.getStatus();
            console.log("PHOOOOOOOOOOOOOOOOOOOOOOne state ", state);
            console.log("PHOOOOOOOOOOOOOOOOOOOOOOne statusAgent ", statusAgent);

            if (statusTrack === 9000000) {
                console.log("PHOOOOOOOOOOOOOOOOOOOOOOne statusTrack IS 0 ", state.name);
                if (state.name === "Disponible") {
                    console.log("PHOOOOOOOOOOOOOOOOOOOOOOne Disponible ", state.name);
                    document.getElementById('connected').classList.remove('notConnected');
                    document.getElementById('connectLink').classList.add('notConnected');

                    document.getElementById('togglePhone').classList.add('btn-success');
                    document.getElementById('togglePhone').classList.remove('btn-danger');
                    //agent.onOffline(function(agent) { console.log('BIG MOMENT dispooooooooooooooooooooo WEEEEEEEEEEEEEEEEEEEEEE ') });
                }
            }

            // agent.onOffline(function(agent) { console.log('BIG SAD MOMENT') });
            agent.onOffline(function(agent) { console.log('BIG SAD MOMENT 99999999999999999 ') });

        });

        /* var eventBus = connect.core.getEventBus();
        eventBus.subscribe(connect.EventType.TERMINATED, () => {

        document.getElementById('connectLink').classList.remove('notConnected');
		
        document.getElementById('connected').classList.add('notConnected');

        console.log('Logged out');
        this.toggleCPP();
        // Do stuff...
        }); */


    }


    checkIframeLoaded() {
        // Get a handle to the iframe element
        /* window.document.body.addEventListener('NEOLEGAL_contact', function (e) { 
			
			document.getElementById('contact_username').innerHTML = e.data.Name.value
			document.getElementById('contact_language').innerHTML = e.data.Langue.value;
			document.getElementById('contact_phone').innerHTML = e.data.phoneNumber;
			document.getElementById('contactBand').classList.remove('notConnected')
		  }, false);
		} */

        /* setTimeout(function(){
          console.log('anyway')
          var iframe = document.querySelector('[src="https://neodesk.my.connect.aws/connect/ccp-v2/"]');
          var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

        },2*1000); */



        /* setTimeout(function(){
            console.log('anyway teeeeeeeeeeeeeeeeeeeeeeeeeeeeest '); 
			//window.document.body.addEventListener('NEOLEGAL_contact', function (e) { 
			 //this.element.addEventListener('NEOLEGAL_contact', function (e) { 
             document.addEventListener('NEOLEGAL_contact', function (e) { 
				
				document.getElementById('contact_username').innerHTML = e.data.Name.value
				document.getElementById('contact_language').innerHTML = e.data.Langue.value;
				document.getElementById('contact_phone').innerHTML = e.data.phoneNumber;
				document.getElementById('contactBand').classList.remove('notConnected')
			  }, false);
			 

          },3*1000); */



        // iframe.onLoad = function(loaded){
        //   console.log('was loaded',loaded)
        // }
        // console.log('iframe',iframe)
        // console.log('tsuyukunaru')
        // var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

        // // Check if loading is complete

        // console.log('is ready??',iframeDoc.readyState)
        // if (  iframeDoc.readyState  == 'complete' ) {
        //     //iframe.contentWindow.alert("Hello");
        //     iframe.contentWindow.onload = function(){
        //         alert("I am loaded");
        //     };
        //     // The loading is complete, call the function we want executed once the iframe is loaded
        //     afterLoading();
        //     return;
        // } 

        // // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
        // window.setTimeout(checkIframeLoaded, 100);
    }

}


const mapStateToProps = (state) => ({ NeoVentes: state.NeoVentes });
const mapDispatchToProps = {};
export default connectrdx(mapStateToProps, mapDispatchToProps)(withTranslation("common")(NeoAmazonCCP));



/*class CheckoutEmailEmulator extends HTMLElement {
	connectedCallback() {
	  this.innerHTML = `<h1>Hello world</h1>`;
	}
}*/


/*class CheckoutEmailEmulator extends HTMLElement {
	static get observedAttributes() {
	  return ['open'];
	}
	
	constructor() {
	  super();
	  this.attachShadow({ mode: 'open' });
	  this.close = this.close.bind(this);
	}
	
	attributeChangedCallback(attrName, oldValue, newValue) {
	  if (oldValue !== newValue) {
		this[attrName] = this.hasAttribute(attrName);
	  }
	}
	
	connectedCallback() {
	  const { shadowRoot } = this;
	  shadowRoot.innerHTML = `<style>
		.wrapper {
		  opacity: 0;
		  transition: visibility 0s, opacity 0.25s ease-in;
		}
		.wrapper:not(.open) {
		  visibility: hidden;
		}
		.wrapper.open {
		  align-items: center;
		  display: flex;
		  justify-content: center;
		  height: 100vh;
		  position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		  opacity: 1;
		  visibility: visible;
		}
		.overlay {
		  background: rgba(0, 0, 0, 0.8);
		  height: 100%;
		  position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		  width: 100%;
		}
		.dialog {
		  background: #ffffff;
		  max-width: 600px;
		  padding: 1rem;
		  position: fixed;
		}
		button {
		  all: unset;
		  cursor: pointer;
		  font-size: 1.25rem;
		  position: absolute;
			top: 1rem;
			right: 1rem;
		}
		button:focus {
		  border: 2px solid blue;
		}
	  </style>
	  <div class="wrapper">
	  <div class="overlay"></div>
		<div class="dialog" role="dialog" aria-labelledby="title" aria-describedby="content">
		  <button class="close" aria-label="Close">✖️</button>
		  <h1 id="title">Hello world</h1>
		  <div id="content" class="content">
			<p>This is content in the body of our modal</p>
		  </div>
		</div>
	  </div>`;
	  
	  
	  shadowRoot.querySelector('button').addEventListener('click', this.close);
	  shadowRoot.querySelector('.overlay').addEventListener('click', this.close);
	  this.open = this.open;
	}
	
	disconnectedCallback() {
	  document.querySelector('button').removeEventListener('click', this.close);
	  document.querySelector('.overlay').removeEventListener('click', this.close);
	}
	
	get open() {
	  return this.hasAttribute('open');
	}
	
	
	set open(isOpen) {
	  const { shadowRoot } = this;
	  shadowRoot.querySelector('.wrapper').classList.toggle('open', isOpen);
	  shadowRoot.querySelector('.wrapper').setAttribute('aria-hidden', !isOpen);
	  if (isOpen) {
		this._wasFocused = document.activeElement;
		this.setAttribute('open', '');
		document.addEventListener('keydown', this._watchEscape);
		this.focus();
		shadowRoot.querySelector('button').focus();
	  } else {
		this._wasFocused && this._wasFocused.focus && this._wasFocused.focus();
		this.removeAttribute('open');
		document.removeEventListener('keydown', this._watchEscape);
		this.close();
	  }
	}
	
	
	close() {
	  if (this.open !== false) {
		this.open = false;
	  }
	  const closeEvent = new CustomEvent('dialog-closed');
	  this.dispatchEvent(closeEvent);
	}
	
	_watchEscape(event) {
	  if (event.key === 'Escape') {
		  this.close();   
	  }
	}
}*/