export const SET_MENU = 'menu:set';

const listmenu= [
  {
    name:{en:'Home',fr:'Accueil'},
    url:'/',
    title:'Lien vers le dashbord',
    activeName:'home'
  },
  {
    name:{en:'My Dashboard',fr:'Mon Tableau de Bord'},
    url:'/leads',
    title:'Mon Tableau de Bord - Lien vers les leads',
    activeName:'leads'
  },
  {
    name:{en:'Contact',fr:'Contact'},
    url:'/contact',
    title:'Lien vers les contacts',
    activeName:'contact'
  },
  // {
  //   name:{en:'customer',fr:'client'},
  //   url:'/client',
  //   title:'Lien vers les Clients',
  //   activeName:'client'
  // },

  // {
  //   name:{en:'Calendar',fr:'Calendrier'},
  //   url:'/calendar',
  //   title:'Lien vers le Calendrier',
  //   activeName:'calendar'
  // },
  
];

//function customersReducer(state={},{type,params}){

export default function customersReducer(state=listmenu,{type,params}){
    if(type === SET_MENU){

      return state = {...params};
    }
    
    return state
  }
  